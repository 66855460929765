import { Record } from 'immutable'

export class User extends Record({
    id: undefined,
    first_name: undefined,
    last_name: undefined,
    email: undefined,
    phone: undefined,
    rgb_bg: undefined,
    rgb_text: undefined,
    is_blocked: undefined,
    user_role_id: undefined,
    company_cost_center_id: undefined,
}) {
    getFullName = () => {
        const parts = []
        this.last_name && parts.push(this.last_name)
        this.first_name && parts.push(this.first_name)

        return parts.join(' ')
    }

    getShortFullName = () => {
        const parts = []
        this.last_name && parts.push(this.last_name)
        this.first_name && parts.push(`${this.first_name.substring(0, 1)}.`)

        return parts.join(' ')
    }
}
