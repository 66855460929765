import { Observable } from 'rxjs'

import { request, parseAPIError, DownloadRequest } from '../../common/api'

import { resolveModelState } from '../../common/table/helpers'

import * as actionTypes from './actionTypes'
import * as actionCreators from './actionCreators'

// Fetch

const fetchReportCostCenterCostsEpic = action$ =>
    action$.ofType(actionTypes.FETCH).switchMap(action =>
        Observable.concat(
            Observable.of({
                type: actionTypes.FETCH_STARTED,
            }),
            request({
                method: 'GET',
                path: `report-cost-center-costs?year=${action.payload.year}&inclVehicles=${action.payload.inclVehicles}&inclDrivers=${action.payload.inclDrivers}&inclTrailers=${action.payload.inclTrailers}`,
            })
                .switchMap(ajaxResponse => Observable.of(actionCreators.fetchReportCostCenterCostsFulfilled(ajaxResponse.response)))
                .catch(error => Observable.of(actionCreators.fetchReportCostCenterCostsRejected(parseAPIError(error))))
                .takeUntil(action$.ofType(actionTypes.FETCH_CANCELLED, actionTypes.FETCH))
        )
    )

// Export

const exportReportCostCenterCostsEpic = (action$, store) => {
    const tableIdentifier = 'report_cost_center_cost'

    return action$.ofType(actionTypes.EXPORT).switchMap(action => {
        const modelState = resolveModelState(tableIdentifier, store.getState(), action)

        const filters = JSON.stringify(modelState.get('filters').toJS())
        const token = store.getState().auth.get('accessToken')

        new DownloadRequest({
            url: `report-cost-center-costs/export?year=${action.payload.year}&filters=${filters}&token=${token}&inclVehicles=${action.payload.inclVehicles}&inclDrivers=${action.payload.inclDrivers}&inclTrailers=${action.payload.inclTrailers}`,
        }).run()

        return Observable.concat(
            Observable.of({
                type: actionTypes.EXPORT_FULFILLED,
            })
        )
    })
}

// Fetch details

const fetchReportCostCenterCostsDetailsEpic = action$ =>
    action$.ofType(actionTypes.FETCH_DETAILS).switchMap(action =>
        Observable.concat(
            Observable.of({
                type: actionTypes.FETCH_DETAILS_STARTED,
            }),
            request({
                method: 'GET',
                // eslint-disable-next-line prettier/prettier
                path: `report-cost-center-costs/details?id=${action.payload.id}&year=${action.payload.year}&month=${action.payload.month}&inclVehicles=${action.payload.inclVehicles}&inclDrivers=${action.payload.inclDrivers}&inclTrailers=${action.payload.inclTrailers}`,
            })
                .switchMap(ajaxResponse => Observable.of(actionCreators.fetchReportCostCenterCostsDetailsFulfilled(ajaxResponse.response)))
                .catch(error => Observable.of(actionCreators.fetchReportCostCenterCostsDetailsRejected(parseAPIError(error))))
                .takeUntil(action$.ofType(actionTypes.FETCH_DETAILS_CANCELLED))
        )
    )

// Export details

const exportReportCostCenterCostsDetailsEpic = (action$, store) =>
    action$.ofType(actionTypes.EXPORT_DETAILS).switchMap(action => {
        const token = store.getState().auth.get('accessToken')

        new DownloadRequest({
            url: `report-cost-center-costs/details/export?id=${action.payload.id}&year=${action.payload.year}&month=${action.payload.month}&token=${token}&inclVehicles=${action.payload.inclVehicles}&inclDrivers=${action.payload.inclDrivers}&inclTrailers=${action.payload.inclTrailers}`,
        }).run()

        return Observable.concat(
            Observable.of({
                type: actionTypes.EXPORT_DETAILS_FULFILLED,
            })
        )
    })

export default [
    fetchReportCostCenterCostsEpic,
    exportReportCostCenterCostsEpic,
    fetchReportCostCenterCostsDetailsEpic,
    exportReportCostCenterCostsDetailsEpic,
]
