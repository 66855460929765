import React from 'react'
import { Route, Switch } from 'react-router'
import TrailersList from './TrailersList'
import TrailersEdit from './TrailersEdit'
import TrailersHistory from './TrailersHistory'

const Trailers = props => (
    <Switch>
        <Route exact path={props.match.url} component={TrailersList} />
        <Route exact path={`${props.match.url}/new`} component={TrailersEdit} />
        <Route exact path={`${props.match.url}/history`} component={TrailersHistory} />
        <Route exact path={`${props.match.url}/:trailerID`} component={TrailersEdit} />
    </Switch>
)

export default Trailers
