import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import moment from 'moment'
import { Modal } from 'react-bootstrap'

import { DispatchersBox } from '../../../common/colored_box'
import * as formatters from '../../../common/formatters'
import * as helpers from '../../../common/helpers'
import { LoadingOverlay } from '../../../common/loading_overlay'

import * as reportVehiclePerformanceActionCreators from '../actionCreators'
import { getYear, getData, getFetching, getDetailsData, getDetailsFetching } from '../selectors'

import { PeriodPicker } from '../../../common/period_picker'
import * as periodPickerConstants from '../../../common/period_picker/constants'
import { prepareTableActionCreators } from '../../../common/table/actionCreators'
import { TableFilterInputField, TableFilterSelectField } from '../../../common/table/components'
import { getActiveFilters } from '../../../common/table/helpers'
import { TableModelColumnFilteringType } from '../../../common/table/model'
import { createTableModelSelector } from '../../../common/table/selectors'

import { preparePrerequisitesFetchStatusSelectors } from '../../../common/prerequisites/selectors'
import { preparePrerequisitesActionCreators } from '../../../common/prerequisites/actionCreators'
import PermissionsCheck from '../../auth/components/PermissionsCheck'
import { PERMS } from '../permissions'
import {Link} from "react-router-dom";

const tableIdentifier = 'report_vehicle_performance'

const componentIdentifier = 'report_vehicle_performance'
const prerequisites = ['dispatchers', 'company_cost_centers']

class ReportVehiclePerformance extends Component {
    state = {
        modalDetails: {
            show: false,
            vehicleId: null,
        },
    }

    refresh = () => {
        this.props.actions.fetchReportVehiclePerformance(this.props.tableModel.get('filters'))
    }

    resetFilters = () => {
        this.props.table.resetFilters()
    }

    isFilterActive = () => getActiveFilters(this.props.tableModel).size > 0

    // Export
    handleExport = e => {
        e && e.preventDefault()

        this.props.actions.exportReportVehiclePerformance(this.props.tableModel.get('filters').toJS())
    }

    handleFilterChanged = (momentFrom, momentTo) => {
        this.props.table.changeFilters([
            {
                column: 'date_from',
                type: TableModelColumnFilteringType.equal,
                value: momentFrom.format('DD.MM.YYYY'),
            },
            {
                column: 'date_to',
                type: TableModelColumnFilteringType.equal,
                value: momentTo.format('DD.MM.YYYY'),
            },
        ])
    }

    // Detail
    handleShowDetails = (e, vehicleId) => {
        e && e.preventDefault()

        this.setState(
            {
                modalDetails: {
                    show: true,
                    vehicleId,
                },
            },
            () => {
                const filters = this.props.tableModel.get('filters').toJS()
                filters.vehicleId = { value: vehicleId }

                this.props.actions.fetchReportVehiclePerformanceDetails(filters)
            }
        )
    }

    handleHideDetails = e => {
        e && e.preventDefault()

        this.setState(
            {
                modalDetails: {
                    show: false,
                    vehicleId: null,
                },
            },
            () => {
                this.props.actions.clearReportVehiclePerformanceDetails()
            }
        )
    }

    handleExportDetails = e => {
        e && e.preventDefault()

        const filters = this.props.tableModel.get('filters').toJS()
        filters.vehicleId = { value: this.state.modalDetails.vehicleId }

        this.props.actions.exportReportVehiclePerformanceDetails(filters)
    }

    componentDidMount() {
        const dateFrom = moment().startOf('month')
        const dateTo = moment().endOf('month')

        this.props.table.setConfiguration(
            [
                {
                    column: 'date_from',
                    type: TableModelColumnFilteringType.equal,
                    value: dateFrom.format('DD.MM.YYYY'),
                },
                {
                    column: 'date_to',
                    type: TableModelColumnFilteringType.equal,
                    value: dateTo.format('DD.MM.YYYY'),
                },
            ],
            false
        )

        this.props.actions.fetchReportVehiclePerformance(this.props.tableModel.get('filters'))
        this.props.actions.fetchPrerequisites()
    }

    componentWillUnmount() {
        this.props.actions.clearReportVehiclePerformance()
    }

    render() {
        document.title = formatters.titleFormatter(this.props.intl.formatMessage({ id: 'modules.reportVehiclePerformance.heading' }))

        const data = this.props.data
        const filters = this.props.tableModel.get('filters')

        const stringFrom = filters.getIn(['date_from', 'value'])
        const stringTo = filters.getIn(['date_to', 'value'])

        const momentFrom = stringFrom ? helpers.convertCSDateToMoment(stringFrom) : moment().startOf('month')
        const momentTo = stringTo ? helpers.convertCSDateToMoment(stringTo) : moment().endOf('month')

        let totalCZK = 0
        let totalEUR = 0
        let totalKMLoaded = 0
        let totalKMStart = 0
        let totalKMService = 0
        let totalKM = 0

        const detailsData = this.props.detailsData

        let detailsDataTotalKm = 0
        let detailsDataTotalPrice = 0

        /* eslint-disable jsx-a11y/click-events-have-key-events */
        /* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
        return (
            <PermissionsCheck hasAny={Object.values(PERMS)} noPermissionsPage>
                <div className="page-inner">
                    <div id="main-wrapper">
                        <div className="panel panel-white">
                            <div className="panel-body">
                                <div className="wp-100 pull-left m-b-xs">
                                    <h4 className="pull-left">
                                        <span className="pull-left">
                                            <FormattedMessage id="modules.reportVehiclePerformance.heading" />
                                        </span>
                                    </h4>
                                    <div className="btns-list">
                                        <PeriodPicker
                                            className={'m-r-lg'}
                                            momentFrom={momentFrom}
                                            momentTo={momentTo}
                                            defaultPicker={periodPickerConstants.PERIOD_PICKER_TYPE_MONTH}
                                            onChange={(momentFrom, momentTo) => this.handleFilterChanged(momentFrom, momentTo)}
                                        />
                                        {this.isFilterActive() > 0 && (
                                            <button className="btn btn-default btn-addon m-r-xs" onClick={this.resetFilters}>
                                                <i className="far fa-times" /> <FormattedMessage id="buttons.resetFilters" />
                                            </button>
                                        )}
                                        <button className="btn btn-default btn-addon m-r-xs" onClick={this.refresh}>
                                            <i className="far fa-sync-alt" /> <FormattedMessage id="buttons.refresh" />
                                        </button>
                                        <PermissionsCheck has={[PERMS.EXPORT]}>
                                            <button className="btn btn-primary btn-addon" onClick={this.handleExport}>
                                                <i className="far fa-file-excel" /> <FormattedMessage id="buttons.exportToXLS" />
                                            </button>
                                        </PermissionsCheck>
                                    </div>
                                </div>

                                <div className="table-container">
                                    <table className="table table-striped table-hover table-fixed-header">
                                        <thead>
                                            <tr>
                                                <th className="w-max wm-120" />
                                                <th className="w-140" />
                                                <th className="w-140" />
                                                <th className="w-400 th-spanned text-center" colSpan={4}>
                                                    <FormattedMessage id="fields.km" />
                                                </th>
                                                <th className="w-300 th-spanned text-center" colSpan={3}>
                                                    CZK
                                                </th>
                                                <th className="w-380 th-spanned text-center" colSpan={3}>
                                                    EUR
                                                </th>
                                            </tr>
                                            <tr className="th-bordered">
                                                <th className="w-max wm-120">
                                                    <FormattedMessage id="fields.vehicle" />
                                                </th>
                                                <th className="w-140">
                                                    <FormattedMessage id="fields.dispatcher" />
                                                </th>
                                                <th className="w-140">
                                                    <FormattedMessage id="fields.costCenter" />
                                                </th>
                                                <th className="w-100 text-right">
                                                    <FormattedMessage id="fields.total" />
                                                </th>
                                                <th className="w-100 text-right">
                                                    <FormattedMessage id="fields.kmFull" />
                                                </th>
                                                <th className="w-100 text-right">
                                                    <FormattedMessage id="fields.kmApproach" />
                                                </th>
                                                <th className="w-100 text-right">
                                                    <FormattedMessage id="fields.kmService" />
                                                </th>
                                                <th className="w-100 text-right">
                                                    <FormattedMessage id="fields.total" />
                                                </th>
                                                <th className="w-100 text-right">
                                                    <FormattedMessage id="fields.kmFull" />
                                                </th>
                                                <th className="w-100 text-right">
                                                    <FormattedMessage id="fields.km" />
                                                </th>
                                                <th className="w-80 text-right">
                                                    <FormattedMessage id="fields.exchangeRate" />
                                                </th>
                                                <th className="w-100 text-right">
                                                    <FormattedMessage id="fields.total" />
                                                </th>
                                                <th className="w-100 text-right">
                                                    <FormattedMessage id="fields.kmFull" />
                                                </th>
                                                <th className="w-100 text-right">
                                                    <FormattedMessage id="fields.km" />
                                                </th>
                                            </tr>
                                            <tr className="filters">
                                                <th className="w-max wm-120">
                                                    <TableFilterInputField
                                                        identifier="vehicle_name"
                                                        type={TableModelColumnFilteringType.string}
                                                        filters={filters}
                                                        onChange={this.props.table.changeFilter}
                                                    />
                                                </th>
                                                <th className="w-140">
                                                    <TableFilterSelectField
                                                        identifier="dispatcher_id"
                                                        type={TableModelColumnFilteringType.equal}
                                                        filters={filters}
                                                        onChange={this.props.table.changeFilter}
                                                        values={helpers.getPrerequisitesArray(this.props.prerequisites.values.get('dispatchers'))}
                                                    />
                                                </th>
                                                <th className="w-140">
                                                    <TableFilterSelectField
                                                        identifier="company_cost_center_id"
                                                        type={TableModelColumnFilteringType.equal}
                                                        filters={filters}
                                                        onChange={this.props.table.changeFilter}
                                                        values={helpers.getPrerequisitesArray(
                                                            this.props.prerequisites.values.get('company_cost_centers')
                                                        )}
                                                    />
                                                </th>
                                                <th className="w-100" />
                                                <th className="w-100" />
                                                <th className="w-100" />
                                                <th className="w-100" />
                                                <th className="w-100" />
                                                <th className="w-100" />
                                                <th className="w-100" />
                                                <th className="w-80" />
                                                <th className="w-100" />
                                                <th className="w-100" />
                                                <th className="w-100" />
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data &&
                                                data.vehicles &&
                                                data.vehicles.map(vehicle => {
                                                    const czk = vehicle.total_czk
                                                    const eur = vehicle.total_eur
                                                    const kmLoaded = vehicle.km_loaded
                                                    const kmStart = vehicle.km_start
                                                    const kmService = vehicle.km_service
                                                    const km = vehicle.km

                                                    totalCZK += czk
                                                    totalEUR += eur
                                                    totalKMLoaded += kmLoaded
                                                    totalKMStart += kmStart
                                                    totalKMService += kmService
                                                    totalKM += km

                                                    return (
                                                        <tr key={`vehicle-${vehicle.id}`}>
                                                            <td
                                                                className="w-max wm-120 td-clickable"
                                                                onClick={e => {
                                                                    this.handleShowDetails(e, vehicle.id)
                                                                }}
                                                            >
                                                                <DispatchersBox dispatcher={vehicle.dispatcher}>{vehicle.registration}</DispatchersBox>
                                                            </td>
                                                            <td className="w-140">
                                                                {vehicle.dispatcher &&
                                                                    formatters.shortFullName(vehicle.dispatcher.first_name, vehicle.dispatcher.last_name)}
                                                            </td>
                                                            <td className="w-140">{vehicle.company_cost_center && vehicle.company_cost_center.code}</td>
                                                            <td className="w-100 text-right">
                                                                <strong>{formatters.integerFormatter(km)}</strong>
                                                            </td>
                                                            <td className="w-100 text-right">{formatters.integerFormatter(kmLoaded)}</td>
                                                            <td className="w-100 text-right">{formatters.integerFormatter(kmStart)}</td>
                                                            <td className="w-100 text-right">{formatters.integerFormatter(kmService)}</td>
                                                            <td className="w-100 text-right">
                                                                <strong>{formatters.integerFormatter(czk)}</strong>
                                                            </td>
                                                            <td className="w-100 text-right">
                                                                {kmLoaded && formatters.floatFormatter(czk / kmLoaded, 2)}
                                                            </td>
                                                            <td className="w-100 text-right">{km && formatters.floatFormatter(czk / km, 2)}</td>
                                                            <td className="w-80 text-right">{eur && formatters.floatFormatter(czk / eur, 3)}</td>
                                                            <td className="w-100 text-right">
                                                                <strong>{formatters.integerFormatter(eur)}</strong>
                                                            </td>
                                                            <td className="w-100 text-right">
                                                                {kmLoaded && formatters.floatFormatter(eur / kmLoaded, 2)}
                                                            </td>
                                                            <td className="w-100 text-right">{km && formatters.floatFormatter(eur / km, 2)}</td>
                                                        </tr>
                                                    )
                                                })}

                                            <tr className="b-top row-sum">
                                                <td className="w-max wm-120">
                                                    <strong>
                                                        <FormattedMessage id="fields.total" />:
                                                    </strong>
                                                </td>
                                                <td className="w-140" />
                                                <td className="w-140" />
                                                <td className="w-100 text-right">
                                                    <strong>{formatters.integerFormatter(totalKM)}</strong>
                                                </td>
                                                <td className="w-100 text-right">
                                                    <strong>{formatters.integerFormatter(totalKMLoaded)}</strong>
                                                </td>
                                                <td className="w-100 text-right">
                                                    <strong>{formatters.integerFormatter(totalKMStart)}</strong>
                                                </td>
                                                <td className="w-100 text-right">
                                                    <strong>{formatters.integerFormatter(totalKMService)}</strong>
                                                </td>
                                                <td className="w-100 text-right">
                                                    <strong>{formatters.integerFormatter(totalCZK)}</strong>
                                                </td>
                                                <td className="w-100 text-right">
                                                    <strong>{totalKMLoaded && formatters.floatFormatter(totalCZK / totalKMLoaded, 2)}</strong>
                                                </td>
                                                <td className="w-100 text-right">
                                                    <strong>{totalKM && formatters.floatFormatter(totalCZK / totalKM, 2)}</strong>
                                                </td>
                                                <td className="w-80 text-right">
                                                    <strong>{totalEUR && formatters.floatFormatter(totalCZK / totalEUR, 3)}</strong>
                                                </td>
                                                <td className="w-100 text-right">
                                                    <strong>{formatters.integerFormatter(totalEUR)}</strong>
                                                </td>
                                                <td className="w-100 text-right">
                                                    <strong>{totalKMLoaded && formatters.floatFormatter(totalEUR / totalKMLoaded, 2)}</strong>
                                                </td>
                                                <td className="w-100 text-right">
                                                    <strong>{totalKM && formatters.floatFormatter(totalEUR / totalKM, 2)}</strong>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Modal show={Boolean(this.state.modalDetails.show)} onHide={this.handleHideDetails} className="modal-size-xl">
                        <Modal.Header closeButton>
                            <Modal.Title>{detailsData ? detailsData.title : null}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <LoadingOverlay active={this.props.detailsFetching}>
                                <div className="btns-list">
                                    {detailsData &&
                                        detailsData.transports.length > 0 && (
                                            <PermissionsCheck has={[PERMS.EXPORT]}>
                                                <button className="btn btn-primary btn-addon m-r-xs" onClick={this.handleExportDetails}>
                                                    <i className="far fa-file-excel" /> <FormattedMessage id="buttons.exportToXLS" />
                                                </button>
                                            </PermissionsCheck>
                                        )}
                                </div>

                                <div className="table-container">
                                    <table className="table table-striped table-hover table-fixed-header">
                                        <thead>
                                            <tr>
                                                <th className="w-80 text-center">
                                                    <FormattedMessage id="fields.date" />
                                                </th>
                                                <th className="w-80 text-center">
                                                    <FormattedMessage id="fields.number" />
                                                </th>
                                                <th className="w-max wm-140">
                                                    <FormattedMessage id="fields.orderNumber" />
                                                </th>
                                                <th className="w-160">
                                                    <FormattedMessage id="fields.loadingCity" />
                                                </th>
                                                <th className="w-60 text-center">
                                                    <FormattedMessage id="fields.loadingCountry" />
                                                </th>
                                                <th className="w-160">
                                                    <FormattedMessage id="fields.unloadingCity" />
                                                </th>
                                                <th className="w-60 text-center">
                                                    <FormattedMessage id="fields.unloadingCountry" />
                                                </th>
                                                <th className="w-100 text-center">
                                                    <FormattedMessage id="fields.registration" />
                                                </th>
                                                <th className="w-140">
                                                    <FormattedMessage id="fields.dispatcher" />
                                                </th>
                                                <th className="w-100 text-right">
                                                    <FormattedMessage id="fields.km" />
                                                </th>
                                                <th className="w-100 text-right">
                                                    <FormattedMessage id="fields.price" />
                                                </th>
                                                <th className="w-120 text-right">
                                                    <FormattedMessage id="fields.priceCZK" />
                                                </th>
                                                <th className="w-90 text-right">
                                                    <FormattedMessage id="fields.profitKM" />
                                                </th>
                                                <th className="w-90 text-right">
                                                    <FormattedMessage id="fields.profitKMCZK" />
                                                </th>
                                                <th className="w-60 text-right">
                                                    <FormattedMessage id="fields.exchangeRate" />
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {detailsData &&
                                                detailsData.transports.map(transport => {
                                                    detailsDataTotalKm += transport.km
                                                    detailsDataTotalPrice += transport.price_czk

                                                    const datetime = moment(transport.datetime)

                                                    return (
                                                        <tr key={`transport-${transport.number}`}>
                                                            <td className="w-80 text-center">{formatters.dateFormatter(datetime)}</td>
                                                            <td className="w-80 text-center">
                                                                <Link to={`/transports/${transport.number}`}>
                                                                    {transport.number}
                                                                </Link>
                                                            </td>
                                                            <td className="w-max wm-140">{transport.order_number}</td>
                                                            <td className="w-160">{transport.loading_city}</td>
                                                            <td className="w-60 text-center">{transport.loading_country}</td>
                                                            <td className="w-160">{transport.unloading_city}</td>
                                                            <td className="w-60 text-center">{transport.unloading_country}</td>
                                                            <td className="w-100 text-center">{transport.registration}</td>
                                                            <td className="w-140">{transport.dispatcher}</td>
                                                            <td className="w-100 text-right">{formatters.distanceFormatter(transport.km)}</td>
                                                            <td className=" text-right">
                                                                {formatters.priceFormatter(transport.price, '0,0', transport.currency_code)}
                                                            </td>
                                                            <td className="w-120 text-right">{formatters.priceFormatter(transport.price_czk)}</td>
                                                            <td className="w-90 text-right">{formatters.floatFormatter(transport.profit_km, 2)}</td>
                                                            <td className="w-90 text-right">{formatters.floatFormatter(transport.profit_km_czk, 2)}</td>
                                                            <td className="w-60 text-right">{formatters.floatFormatter(transport.currency_rate, 2)}</td>
                                                        </tr>
                                                    )
                                                })}

                                            <tr className="b-top row-sum">
                                                <td className="w-max" colSpan={9}>
                                                    <strong>
                                                        <FormattedMessage id="fields.total" />:
                                                    </strong>
                                                </td>
                                                <td className="w-100 text-right">
                                                    <strong>{formatters.distanceFormatter(detailsDataTotalKm)}</strong>
                                                </td>
                                                <td className="w-100 text-right" />
                                                <td className="w-120 text-right">
                                                    <strong>{formatters.priceFormatter(detailsDataTotalPrice)}</strong>
                                                </td>
                                                <td className="w-90 text-right" />
                                                <td className="w-90 text-right">
                                                    <strong>
                                                        {formatters.floatFormatter(
                                                            detailsDataTotalKm ? detailsDataTotalPrice / detailsDataTotalKm : 0
                                                        )}
                                                    </strong>
                                                </td>
                                                <td className="w-60 text-right" />
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </LoadingOverlay>
                        </Modal.Body>
                        <Modal.Footer>
                            <button className="btn btn-default" onClick={this.handleHideDetails}>
                                <FormattedMessage id="confirmDialog.duplicityFound.cancel" />
                            </button>
                        </Modal.Footer>
                    </Modal>
                </div>
            </PermissionsCheck>
        )
        /* eslint-enable jsx-a11y/no-noninteractive-element-interactions */
        /* eslint-enable jsx-a11y/click-events-have-key-events */
    }
}

function mapStateToProps(state) {
    return {
        tableModel: createTableModelSelector(tableIdentifier)(state),
        year: getYear(state),
        data: getData(state),
        fetching: getFetching(state),
        detailsData: getDetailsData(state),
        detailsFetching: getDetailsFetching(state),
        prerequisites: preparePrerequisitesFetchStatusSelectors(componentIdentifier, prerequisites, state),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            ...bindActionCreators(
                {
                    ...reportVehiclePerformanceActionCreators,
                    ...preparePrerequisitesActionCreators(componentIdentifier, prerequisites),
                },
                dispatch
            ),
        },
        dispatch,
    }
}

function mergeProps(stateProps, dispatchProps, ownProps) {
    return {
        ...stateProps,
        ...dispatchProps,
        ...ownProps,
        table: bindActionCreators(prepareTableActionCreators(tableIdentifier, stateProps.tableModel), dispatchProps.dispatch),
    }
}

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps,
        mergeProps
    )(ReportVehiclePerformance)
)
