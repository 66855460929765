// Fetch list

export const FETCH = 'reportAllowances.FETCH'
export const FETCH_STARTED = 'reportAllowances.FETCH_STARTED'
export const FETCH_FULFILLED = 'reportAllowances.FETCH_FULFILLED'
export const FETCH_REJECTED = 'reportAllowances.FETCH_REJECTED'
export const FETCH_CANCELLED = 'reportAllowances.FETCH_CANCELLED'

// Save allowances

export const SAVE = 'reportAllowances.SAVE'
export const SAVE_STARTED = 'reportAllowances.save_STARTED'
export const SAVE_FULFILLED = 'reportAllowances.save_FULFILLED'
export const SAVE_REJECTED = 'reportAllowances.save_REJECTED'
export const SAVE_CANCELLED = 'reportAllowances.save_CANCELLED'

// Clear

export const CLEAR = 'reportAllowances.CLEAR'

// Export

export const EXPORT = 'reportAllowances.EXPORT'
export const EXPORT_FULFILLED = 'reportAllowances.EXPORT_FULFILLED'

// Fetch details

export const FETCH_DETAILS = 'reportAllowances.FETCH_DETAILS'
export const FETCH_DETAILS_STARTED = 'reportAllowances.FETCH_DETAILS_STARTED'
export const FETCH_DETAILS_FULFILLED = 'reportAllowances.FETCH_DETAILS_FULFILLED'
export const FETCH_DETAILS_REJECTED = 'reportAllowances.FETCH_DETAILS_REJECTED'
export const FETCH_DETAILS_CANCELLED = 'reportAllowances.FETCH_DETAILS_CANCELLED'

// Clear details

export const CLEAR_DETAILS = 'reportAllowances.CLEAR_DETAILS'

// Export details

export const EXPORT_DETAILS = 'reportAllowances.EXPORT_DETAILS'
export const EXPORT_DETAILS_FULFILLED = 'reportAllowances.EXPORT_DETAILS_FULFILLED'

// Delete allowance

export const DELETE_ALLOWANCE = 'reportAllowances.DELETE_ALLOWANCE'
export const DELETE_ALLOWANCE_STARTED = 'reportAllowances.DELETE_ALLOWANCE_STARTED'
export const DELETE_ALLOWANCE_FULFILLED = 'reportAllowances.DELETE_ALLOWANCE_FULFILLED'
export const DELETE_ALLOWANCE_REJECTED = 'reportAllowances.DELETE_ALLOWANCE_REJECTED'
export const DELETE_ALLOWANCE_CANCELLED = 'reportAllowances.DELETE_ALLOWANCE_CANCELLED'

// Reassign allowance

export const REASSIGN_ALLOWANCE = 'reportAllowances.REASSIGN_ALLOWANCE'
export const REASSIGN_ALLOWANCE_STARTED = 'reportAllowances.REASSIGN_ALLOWANCE_STARTED'
export const REASSIGN_ALLOWANCE_FULFILLED = 'reportAllowances.REASSIGN_ALLOWANCE_FULFILLED'
export const REASSIGN_ALLOWANCE_REJECTED = 'reportAllowances.REASSIGN_ALLOWANCE_REJECTED'
export const REASSIGN_ALLOWANCE_CANCELLED = 'reportAllowances.REASSIGN_ALLOWANCE_CANCELLED'
