import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { push } from 'react-router-redux'
import Notifications from 'react-notification-system-redux'
import DatePicker from 'react-datepicker'
import moment from 'moment'

import { ConfirmDialog } from '../../../common/confirm_dialog'
import { Form, InputField, SelectField, SubmitButton } from '../../../common/form'
import * as formatters from '../../../common/formatters'
import * as helpers from '../../../common/helpers'
import { LoadingOverlay } from '../../../common/loading_overlay'

import {
    getCurrentInvoice,
    getCurrentSupportData,
    getCurrentInvoiceFetching,
    getCurrentInvoiceSaving,
    getCurrentInvoiceError,
    getCustomerInfo,
    getCustomerInfoFetching,
    getTransportInfo,
    getTransportInfoFetching,
    getTransportInfoError,
} from '../selectors'
import * as actionCreators from '../actionCreators'

import { preparePrerequisitesFetchStatusSelectors } from '../../../common/prerequisites/selectors'
import { preparePrerequisitesActionCreators } from '../../../common/prerequisites/actionCreators'
import * as constants from '../../invoicing_orders/constants'
import PermissionsCheck from '../../auth/components/PermissionsCheck'
import { PERMS } from '../permissions'

const componentIdentifier = 'invoicing_invoices_edit'
const prerequisites = ['users', 'allowed_customers', 'currencies', 'payment_methods', 'invoice_item_types']

class InvoicingInvoicesEdit extends Component {
    validationRules = {}

    state = {
        data: null,
        customerInfo: null,
        values: {},
        datetimes: {
            date_issued: moment().format('DD.MM.YYYY'),
            date_due: moment().format('DD.MM.YYYY'),
            date_tax: moment().format('DD.MM.YYYY'),
            paid_at: null,
        },
        items: [],
        autoSetDefaultValues: false,
        confirmDialog: {
            show: false,
            title: '',
            message: '',
            labelCancel: '',
            labelAccept: '',
            classNameAccept: 'text-success',
            classNameCancel: 'text-default',
            onCancel: () => {},
            onAccept: () => {},
        },
    }

    transportSearchItemKey = null
    successCallback = null

    initialTaxRatesSet = false

    setDataForInvoicing = (invoice, data) => {
        this.initialTaxRatesSet = false

        const company = data && data.companies && data.companies[0]

        let values = {
            id: null,
            company_id: company && company.id,
            currency_id: data && data.currencies && data.currencies[0] && data.currencies[0].id,
            payment_method_id: data && data.payment_methods && data.payment_methods[0] && data.payment_methods[0].id,
            tax_transaction_type_id: constants.TAX_TRANSACTION_TYPE_DOMESTIC,
            text_before_items_type_id: constants.TEXT_BEFORE_ITEMS_TYPE_ITEMS,
            customer_id: null,
        }

        const datetimes = { ...this.state.datetimes }

        if (invoice) {
            values = {
                id: invoice.id,
                company_id: invoice.company_id,
                company_cost_center_id: invoice.company_cost_center_id,
                company_numbering_id: invoice.company_numbering_id,
                company_bank_account_id: invoice.company_bank_account_id,
                currency_id: invoice.currency_id,
                payment_method_id: invoice.payment_method_id,
                tax_transaction_type_id: invoice.tax_transaction_type_id,
                text_before_items_type_id: invoice.text_before_items_type_id,
                customer_id: invoice.customer_id,
                customer_address_id: invoice.customer_address_id,
                customer_delivery_address_id: invoice.customer_delivery_address_id,
                note: invoice.note,
                paid_amount: invoice.paid_amount,
            }

            datetimes.date_issued = helpers.convertISODateToCS(invoice.date_issued)
            datetimes.date_due = helpers.convertISODateToCS(invoice.date_due)
            datetimes.date_tax = helpers.convertISODateToCS(invoice.date_tax)
            datetimes.paid_at = invoice.paid_at && helpers.convertISODateToCS(invoice.paid_at)
        }

        this.setState({
            data,
            values: this.completeValues(values, data, this.state.customerInfo),
            datetimes,
            items:
                invoice && invoice.items
                    ? invoice.items.map(item => ({
                          id: item.id,
                          transport_id: item.transport_id,
                          units_count: item.units_count || 1,
                          unit: item.unit || this.props.intl.formatMessage({ id: 'units.pcs' }),
                          name: item.name,
                          text: item.text,
                          unit_price: item.unit_price,
                          tax_rate_id: item.tax_rate_id,
                          invoice_item_type_id: item.invoice_item_type_id,
                          loading_is_in_eu: item.loading_is_in_eu,
                          unloading_is_in_eu: item.unloading_is_in_eu,
                      }))
                    : [],
        })
    }

    setValues = values => {
        this.setState({
            values,
        })
    }

    completeValues = (values, data, customer, changeFromTransportInfo) => {
        const customerChanged = values.customer_id && String(values.customer_id) !== String(this.state.values.customer_id)
        const companyChanged = values.company_id && String(values.company_id) !== String(this.state.values.company_id)
        const currencyChanged = values.currency_id && String(values.currency_id) !== String(this.state.values.currency_id)

        const company = data && data.companies && data.companies.find(item => String(item.id) === String(values.company_id))
        const numbering = company && company.numberings && company.numberings.find(item => String(item.id) === String(values.company_numbering_id))

        let costCenter =
            company && company.cost_centers && company.cost_centers.find(item => String(item.id) === String(values.company_cost_center_id))
        if (!costCenter && company && company.cost_centers) {
            costCenter = company.default_company_cost_center_id
                ? company.cost_centers.find(item => String(item.id) === String(company.default_company_cost_center_id))
                : company.cost_centers[0]
        }

        // company changed
        if (companyChanged) {
            values.company_cost_center_id = costCenter ? costCenter.id : null

            values.company_numbering_id = numbering
                ? numbering.id
                : company && company.numberings && company.numberings[0] && company.numberings[0].id
        }

        // customer address changed
        const customerAddressChanged = String(values.customer_address_id) !== String(this.state.values.customer_address_id)

        let customerAddress =
            customer &&
            customer.invoicing_addresses &&
            customer.invoicing_addresses.find(item => String(item.id) === String(values.customer_address_id))
        if (!customerAddress && customer && customer.invoicing_addresses) {
            customerAddress = customer.invoicing_addresses[0]
        }

        if ((customerAddressChanged || companyChanged) && company && customerAddress && this.state.autoSetDefaultValues) {
            if (company.country_id === customerAddress.country_id) {
                values.tax_transaction_type_id = constants.TAX_TRANSACTION_TYPE_DOMESTIC
            } else if (company.country_is_in_eu && customerAddress.country_is_in_eu) {
                values.tax_transaction_type_id = constants.TAX_TRANSACTION_TYPE_EU
            } else {
                values.tax_transaction_type_id = constants.TAX_TRANSACTION_TYPE_OTHER
            }
        }

        const taxTransactionTypeId = String(values.tax_transaction_type_id)
        const taxTransactionTypeChanged =
            taxTransactionTypeId &&
            this.state.values.tax_transaction_type_id &&
            taxTransactionTypeId !== String(this.state.values.tax_transaction_type_id)

        if (taxTransactionTypeChanged || changeFromTransportInfo) {
            this.setTaxRateForItems(company, customerAddress, data, taxTransactionTypeId)
        }

        // currency changed
        if (currencyChanged || companyChanged || customerChanged || customerAddressChanged || changeFromTransportInfo) {
            const bankAccounts = company && company.bank_accounts
            const defaultBankAccounts = company && company.default_bank_accounts

            const possibleAccounts =
                bankAccounts &&
                bankAccounts.filter(
                    item =>
                        String(item.currency_id) === String(values.currency_id) &&
                        (!customer || String(item.is_factoring) === String(customer.factoring))
                )

            const defaultBankAccountCustomerTypeKey = customerAddress && company && customerAddress.country_id === company.country_id ? 1 : 2
            const defaultBankAccountCurrencyKey = values.currency_id
            const defaultBankAccountFactoringKey = customer && customer.factoring ? 1 : 2
            var defaultBankAccountId =
                defaultBankAccounts &&
                defaultBankAccounts[defaultBankAccountCustomerTypeKey] &&
                defaultBankAccounts[defaultBankAccountCustomerTypeKey][defaultBankAccountCurrencyKey] &&
                defaultBankAccounts[defaultBankAccountCustomerTypeKey][defaultBankAccountCurrencyKey][defaultBankAccountFactoringKey]
            var defaultBankAccount = bankAccounts && bankAccounts.find(item => String(item.id) === String(defaultBankAccountId))

            if(customer && customer.company_bank_accounts){
                const keys = Object.keys(customer.company_bank_accounts);
                keys.forEach((key, index) => {
                    if(customer.company_bank_accounts[key].currency_id === values.currency_id){
                        var item = bankAccounts.find(item => String(item.id) === String(customer.company_bank_accounts[key].id))
                        if(item){
                            defaultBankAccountId = item.id
                            defaultBankAccount = item
                        }
                    }
                });
            }

            if (!possibleAccounts || !possibleAccounts.length) {
                values.company_bank_account_id = null
            } else {
                values.company_bank_account_id = defaultBankAccount ? defaultBankAccount.id : defaultBankAccounts[0].id
            }
        }

        values.id = this.props.invoice && this.props.invoice.id

        if (customerChanged) {
            if (values.customer_id) {
                this.loadCustomerInfo(values.customer_id)
            } else {
                this.clearCustomerInfo()
            }
        }

        return values
    }

    handleChangeValues = values => {
        this.setState({
            values: this.completeValues(values, this.state.data, this.state.customerInfo),
        })
    }

    handleDatetimeChange = (key, value) => {
        const datetimes = {
            ...this.state.datetimes,
            [key]: value,
        }

        if (key === 'date_issued' && datetimes.date_due) {
            const originalDateIssue = moment(this.state.datetimes.date_issued, 'DD.MM.YYYY')
            const newDateIssue = moment(datetimes.date_issued, 'DD.MM.YYYY')
            const dateDue = moment(datetimes.date_due, 'DD.MM.YYYY')
            const diff = dateDue.diff(originalDateIssue, 'days')

            datetimes.date_due = newDateIssue.add(diff, 'days').format('DD.MM.YYYY')
        }

        this.setState({
            datetimes,
        })
    }

    handleChangeItemValues = (itemKey, name, value) => {
        const items = [...this.state.items]
        items[itemKey] = {
            ...this.state.items[itemKey],
            [name]: value,
        }

        this.setState({
            items,
        })
    }

    handleChangeItemType = (itemKey, name, value) => {
        const data = this.state.data
        const values = this.state.values
        const customer = this.state.customerInfo

        let customerAddress =
            customer && customer.addresses && customer.addresses.find(item => String(item.id) === String(values.customer_address_id))
        if (!customerAddress && customer && customer.addresses) {
            customerAddress = customer.addresses[0]
        }

        const company = data && data.companies && data.companies.find(item => String(item.id) === String(values.company_id))

        const items = [...this.state.items]
        const taxRates = company && company.country_id && data && data.tax_rates && data.tax_rates[company.country_id]
        const invoiceItemTypesTaxRate = helpers.getPrerequisitesPairs(
            this.props.prerequisites.values.get('invoice_item_types'),
            'id',
            'tax_rate_type_id'
        )

        items[itemKey] = {
            ...this.state.items[itemKey],
            [name]: value,
        }

        items[itemKey] = {
            ...items[itemKey],
            tax_rate_id: this.getItemTaxId(items[itemKey], taxRates, invoiceItemTypesTaxRate, company, customerAddress),
        }

        this.setState({
            items,
        })
    }

    handleAddItem = e => {
        e && e.preventDefault()
        e && e.stopPropagation()

        const values = this.state.values
        const data = this.state.data

        let company = values.company_id && data && data.companies && data.companies.find(item => String(item.id) === String(values.company_id))
        if (!company) {
            company = data && data.companies && data.companies[0]
        }

        const taxRates = company && company.country_id && data && data.tax_rates && data.tax_rates[company.country_id]
        const default_invoice_item_type_id = company && company.default_invoice_item_type_id
        const default_tax_rate_id =
            default_invoice_item_type_id &&
            helpers.getPrerequisitesPairs(this.props.prerequisites.values.get('invoice_item_types'), 'id', 'tax_rate_type_id')[
                default_invoice_item_type_id
            ]
        let taxRate = taxRates && default_tax_rate_id && taxRates.find(item => String(item.id) === String(default_tax_rate_id))

        if (!taxRate || !taxRate.percent) {
            if (String(values.tax_transaction_type_id) !== String(constants.TAX_TRANSACTION_TYPE_DOMESTIC)) {
                taxRate = taxRates && taxRates.find(item => !item.percent)
            } else {
                taxRate = taxRates && taxRates[0]
            }
        }

        const items = [...this.state.items]
        items.push({
            id: null,
            transport_id: null,
            units_count: 1,
            unit: this.props.intl.formatMessage({ id: 'units.pcs' }),
            name: '',
            text: '',
            unit_price: 0,
            tax_rate_id: taxRate && taxRate.id,
            invoice_item_type_id: company && company.default_invoice_item_type_id,
        })

        this.setState({
            items,
        })
    }

    handleRemoveItem = (e, itemKey) => {
        e && e.preventDefault()
        e && e.stopPropagation()

        const items = [...this.state.items]
        items.splice(itemKey, 1)

        this.setState({
            items,
        })
    }

    handleTransportSearchButtonClick = itemKey => {
        this.transportSearchItemKey = itemKey
        const transportId = this.state.items[itemKey] && this.state.items[itemKey].transport_id

        if (transportId) {
            this.props.actions.loadTransportInfo(transportId)
        }
    }

    setItemsValues = (itemKey, values, callback) => {
        const items = [...this.state.items]
        items[itemKey] = {
            ...this.state.items[itemKey],
            name: (values && values.name) || '',
            text: (values && values.text) || '',
            loading_is_in_eu: values.loading_is_in_eu,
            unloading_is_in_eu: values.unloading_is_in_eu,
        }

        if (values.unit_price) {
            items[itemKey].unit_price = values.unit_price
        }

        this.setState(
            {
                items,
            },
            () => {
                callback && callback()
            }
        )
    }

    handleSubmit = () => {
        this.successCallback = nextProps => {
            if (nextProps.invoice && nextProps.invoice.id) {
                this.props.redirect(helpers.url(this.props.match, `invoicing-invoices/${nextProps.invoice.id}`))
            } else {
                this.props.redirect(helpers.url(this.props.match, 'invoicing-invoices'))
            }
        }

        this.save()
    }

    handleSaveAndClose = () => {
        this.successCallback = () => {
            this.props.redirect(helpers.url(this.props.match, 'invoicing-invoices'))
        }

        this.save()
    }

    save = () => {
        this.props.actions.saveInvoicingInvoice({
            ...this.state.values,
            ...this.state.datetimes,
            items: this.state.items,
        })
    }

    loadCustomerInfo = customerId => {
        this.props.actions.loadCustomerInfo(customerId)
    }

    setCustomerInfo = (customerInfo, currencyId, companyCostCenterId, dateTax) => {
        const values = { ...this.state.values }
        const addresses = customerInfo && customerInfo.invoicing_addresses
        const selectedAddress = addresses && addresses.find(address => address.id === values.customer_address_id)

        if (addresses && (!addresses.length || !selectedAddress)) {
            values.customer_address_id = addresses[0] && addresses[0].id
        }

        const deliveryAddresses = customerInfo && customerInfo.delivery_addresses
        const selectedDeliveryAddress = deliveryAddresses && deliveryAddresses.find(address => address.id === values.customer_delivery_address_id)

        if (deliveryAddresses && (!deliveryAddresses.length || !selectedDeliveryAddress)) {
            values.customer_delivery_address_id = deliveryAddresses[0] && deliveryAddresses[0].id
        }

        const datetimes = { ...this.state.datetimes }

        if (this.state.autoSetDefaultValues) {
            const days = customerInfo && customerInfo.days ? parseInt(customerInfo.days) : 30
            datetimes.date_due = moment(this.state.datetimes.date_issued, 'DD.MM.YYYY')
                .add(days, 'days')
                .format('DD.MM.YYYY')
        }

        if (dateTax) {
            datetimes.date_tax = moment(dateTax, 'YYYY-MM-DD').format('DD.MM.YYYY')
        }

        if (customerInfo.id) {
            values.customer_id = customerInfo.id
        }

        if (currencyId) {
            values.currency_id = currencyId
        }

        if (companyCostCenterId) {
            values.company_cost_center_id = companyCostCenterId
        }

        this.setState({
            customerInfo,
            values: this.completeValues(values, this.state.data, customerInfo, true),
            datetimes,
            autoSetDefaultValues: true,
        })
    }

    clearCustomerInfo = () => {
        this.setState({
            customerInfo: null,
        })
    }

    getItemTaxId = (item, taxRates, invoiceItemTypesTaxRate, company, customerInvoicingAddress, taxTransactionTypeId) => {
        const taxRateId = invoiceItemTypesTaxRate && invoiceItemTypesTaxRate[item.invoice_item_type_id]

        if (taxRateId) {
            return taxRateId
        }

        const values = this.state.values

        if (!customerInvoicingAddress) {
            const customer = this.state.customerInfo

            customerInvoicingAddress =
                customer &&
                customer.invoicing_addresses &&
                customer.invoicing_addresses.find(item => String(item.id) === String(values.customer_address_id))

            if (!customerInvoicingAddress && customer && customer.invoicing_addresses) {
                customerInvoicingAddress = customer.invoicing_addresses[0]
            }
        }

        if (!taxTransactionTypeId) {
            taxTransactionTypeId = values.tax_transaction_type_id
        }

        const taxRateHigh = taxRates && taxRates[0]
        const taxRateZero = taxRates && taxRates.find(item => !item.percent)

        if (
            company &&
            customerInvoicingAddress &&
            company.country_is_in_eu &&
            String(taxTransactionTypeId) === String(constants.TAX_TRANSACTION_TYPE_DOMESTIC) &&
            item.loading_is_in_eu === item.unloading_is_in_eu
        ) {
            return taxRateHigh.id
        }

        return taxRateZero.id
    }

    setTaxRateForItems = (company, customerInvoicingAddress, data, taxTransactionTypeId) => {
        if (!this.initialTaxRatesSet) {
            this.initialTaxRatesSet = true

            return
        }

        const taxRates = company && company.country_id && data && data.tax_rates && data.tax_rates[company.country_id]
        const invoiceItemTypesTaxRate = helpers.getPrerequisitesPairs(
            this.props.prerequisites.values.get('invoice_item_types'),
            'id',
            'tax_rate_type_id'
        )

        const newItems = this.state.items.map(item => {
            return {
                ...item,
                tax_rate_id: this.getItemTaxId(item, taxRates, invoiceItemTypesTaxRate, company, customerInvoicingAddress, taxTransactionTypeId),
            }
        })

        this.setState({
            items: newItems,
        })
    }

    // restore
    handleRestore = () => {
        this.showConfirmDialog({
            title: this.props.intl.formatMessage({ id: 'confirmDialog.itemRestore.title' }),
            message: this.props.intl.formatMessage({ id: 'confirmDialog.itemRestore.message' }),
            labelCancel: this.props.intl.formatMessage({ id: 'confirmDialog.itemRestore.cancel' }),
            labelAccept: this.props.intl.formatMessage({ id: 'confirmDialog.itemRestore.accept' }),
            classNameAccept: 'btn-success',
            onAccept: () => {
                this.props.actions.restoreInvoice(this.props.invoice.id)
                this.hideConfirmDialog()
            },
        })
    }

    // confirm dialog
    showConfirmDialog = options => {
        this.setState({
            confirmDialog: {
                show: true,
                title: options.title || this.props.intl.formatMessage({ id: 'confirmDialog.default.title' }),
                message: options.message || this.props.intl.formatMessage({ id: 'confirmDialog.default.message' }),
                labelCancel: options.labelCancel || this.props.intl.formatMessage({ id: 'confirmDialog.default.cancel' }),
                labelAccept: options.labelAccept || this.props.intl.formatMessage({ id: 'confirmDialog.default.accept' }),
                classNameAccept: options.classNameAccept || 'btn-success',
                classNameCancel: options.classNameCancel || 'btn-default',
                onCancel: options.onCancel || this.hideConfirmDialog,
                onAccept: options.onAccept || this.hideConfirmDialog,
            },
        })
    }

    hideConfirmDialog = () => {
        this.setState({
            confirmDialog: {
                show: false,
                onCancel: () => {},
                onAccept: () => {},
            },
        })
    }


    componentDidMount() {
        if (!this.props.match.params.invoiceID) {
            this.setState({
                autoSetDefaultValues: true,
            })
        }

        this.props.actions.fetchInvoicingInvoice(this.props.match.params.invoiceID)
        this.props.actions.fetchPrerequisites()
    }

    componentWillUnmount() {
        this.props.actions.clearCustomerInfo()
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.invoiceFetching && !nextProps.invoiceFetching) {
            this.setDataForInvoicing(nextProps.invoice, nextProps.invoiceSupportData)
        }

        if (this.props.customerInfoFetching && !nextProps.customerInfoFetching) {
            this.setCustomerInfo(nextProps.customerInfo)
        }

        if (this.props.transportInfoFetching && !nextProps.transportInfoFetching && !nextProps.transportInfoError) {
            const addresses = this.props.transportInfo.customer && this.props.transportInfo.customer.addresses
            const selectedAddress = addresses && addresses.find(address => address.id === this.state.values.customer_address_id)
            const countryId = selectedAddress && selectedAddress.country_id

            const nextAddresses = nextProps.transportInfo.customer && nextProps.transportInfo.customer.addresses
            const nextSelectedAddress = nextAddresses && nextAddresses.find(address => address.id === this.state.values.customer_address_id)
            let nextCountryId = nextSelectedAddress && nextSelectedAddress.country_id

            if (nextAddresses && (!nextAddresses.length || !nextSelectedAddress)) {
                nextCountryId = nextAddresses[0] && nextAddresses[0].country_id
            }

            let callback
            if (countryId !== nextCountryId) {
                callback = () =>
                    this.setCustomerInfo(
                        nextProps.transportInfo.customer,
                        nextProps.transportInfo.currency_id,
                        nextProps.transportInfo.company_cost_center_id,
                        nextProps.transportInfo.date_tax
                    )
            }

            this.setItemsValues(this.transportSearchItemKey, nextProps.transportInfo, callback)
        }

        // saving
        if (this.props.invoiceSaving && !nextProps.invoiceSaving) {
            if (nextProps.invoiceError === null) {
                this.props.notify(
                    {
                        title: this.props.intl.formatMessage({ id: `alerts.titles.success` }),
                        message: this.props.intl.formatMessage({ id: `alerts.messages.saveSuccess` }),
                        position: 'tc',
                    },
                    'success'
                )
                this.successCallback && this.successCallback(nextProps)
            } else {
                this.props.notify(
                    {
                        title: this.props.intl.formatMessage({ id: `alerts.titles.error` }),
                        message: nextProps.invoiceError,
                        position: 'tc',
                    },
                    'error'
                )
            }
        }
    }

    /* eslint-disable jsx-a11y/anchor-is-valid */
    render() {
        document.title = formatters.titleFormatter(this.props.intl.formatMessage({ id: 'modules.invoicingInvoices.heading' }))

        const data = this.state.data
        const invoiceItems = this.state.items
        const customerInfo = this.state.customerInfo

        const company =
            this.state.values.company_id &&
            data &&
            data.companies &&
            data.companies.find(item => String(item.id) === String(this.state.values.company_id))

        const currency =
            this.state.values.currency_id &&
            data &&
            data.currencies &&
            data.currencies.find(item => String(item.id) === String(this.state.values.currency_id))

        const companiesOptions =
            data && data.companies
                ? data.companies.map(item => ({
                      id: item.id,
                      name: item.name,
                  }))
                : []

        const costCentersOptions =
            company && company.cost_centers
                ? company.cost_centers.map(item => ({
                      id: item.id,
                      name: `${item.code} - ${item.name}`,
                  }))
                : []

        const bankAccountsOptions =
            company && company.bank_accounts
                ? company.bank_accounts
                      .filter(item => String(item.currency_id) === String(this.state.values.currency_id))
                      .map(item => ({
                          id: item.id,
                          name: item.name,
                      }))
                : []

        const numberingsOptions =
            company && company.numberings
                ? company.numberings.map(item => ({
                      id: item.id,
                      name: `${item.name} [${item.format}]`,
                  }))
                : []

        const taxRatesOptions =
            company && company.country_id && data && data.tax_rates && data.tax_rates[company.country_id]
                ? data.tax_rates[company.country_id].map(item => ({
                      id: item.id,
                      name: item.percent,
                  }))
                : []

        const taxRatesValues = {}
        company &&
            company.country_id &&
            data &&
            data.tax_rates &&
            data.tax_rates[company.country_id] &&
            data.tax_rates[company.country_id].forEach(item => {
                taxRatesValues[item.id] = item.percent
            })

        const currenciesOptions = helpers.getPrerequisitesArray(this.props.prerequisites.values.get('currencies'), 'id', 'iso_code')
        const paymentMethodsOptions = helpers.getPrerequisitesArray(this.props.prerequisites.values.get('payment_methods'))
        const customersOptions = helpers.getPrerequisitesArray(this.props.prerequisites.values.get('allowed_customers'))
        const invoiceItemTypes = helpers.getPrerequisitesArray(this.props.prerequisites.values.get('invoice_item_types'))

        const invoicingAddressesOptions =
            customerInfo && customerInfo.invoicing_addresses
                ? customerInfo.invoicing_addresses.map(item => ({
                      id: item.id,
                      name: item.address,
                  }))
                : []

        const deliveryAddressesOptions =
            customerInfo && customerInfo.delivery_addresses
                ? customerInfo.delivery_addresses.map(item => ({
                      id: item.id,
                      name: item.address,
                  }))
                : []

        const taxTransactionTypesOptions = [
            {
                id: constants.TAX_TRANSACTION_TYPE_DOMESTIC,
                name: this.props.intl.formatMessage({ id: `taxTransactionTypes.${constants.TAX_TRANSACTION_TYPE_DOMESTIC}` }),
            },
            {
                id: constants.TAX_TRANSACTION_TYPE_EU,
                name: this.props.intl.formatMessage({ id: `taxTransactionTypes.${constants.TAX_TRANSACTION_TYPE_EU}` }),
            },
            {
                id: constants.TAX_TRANSACTION_TYPE_OTHER,
                name: this.props.intl.formatMessage({ id: `taxTransactionTypes.${constants.TAX_TRANSACTION_TYPE_OTHER}` }),
            },
            {
                id: constants.TAX_TRANSACTION_TYPE_NOT_SUBJECT_TO_VAT,
                name: this.props.intl.formatMessage({ id: `taxTransactionTypes.${constants.TAX_TRANSACTION_TYPE_NOT_SUBJECT_TO_VAT}` }),
            },
        ]

        const textBeforeItemsTypeOptions = [
            {
                id: constants.TEXT_BEFORE_ITEMS_TYPE_ITEMS,
                name: this.props.intl.formatMessage({ id: `textBeforeItemsTypes.${constants.TEXT_BEFORE_ITEMS_TYPE_ITEMS}` }),
            },
            {
                id: constants.TEXT_BEFORE_ITEMS_TYPE_TRANSPORTS,
                name: this.props.intl.formatMessage({ id: `textBeforeItemsTypes.${constants.TEXT_BEFORE_ITEMS_TYPE_TRANSPORTS}` }),
            }
        ]

        const total = {
            exclVat: 0,
            vat: 0,
            inclVat: 0,
        }
        invoiceItems.forEach(item => {
            const taxRateValue = item.tax_rate_id && taxRatesValues[item.tax_rate_id] ? parseFloat(taxRatesValues[item.tax_rate_id]) : 0
            const exclVat = parseFloat(item.units_count) * parseFloat(item.unit_price)

            if (!isNaN(exclVat)) {
                total.exclVat += exclVat
                total.inclVat += exclVat * (1 + taxRateValue / 100)
            }
        })
        total.vat = total.inclVat - total.exclVat
        const isDisableField = helpers.isDisableField(Boolean(this.props.invoice), [PERMS.READ, PERMS.CREATE, PERMS.UPDATE])

        return (
            <PermissionsCheck hasAny={Object.values(PERMS)} noPermissionsPage>
                <div className="page-inner">
                    <div id="main-wrapper">
                        <div className="row">
                            <div className="col-lg-10 col-lg-offset-1 col-xl-8 col-xl-offset-2">
                                <div className="panel panel-white">
                                    <div className="panel-body panel-padding">
                                        <div className="panel-head">
                                            <h4>
                                                <FormattedMessage id="modules.invoicingInvoices.heading" /> -{' '}
                                                {this.props.invoice ? (
                                                    <FormattedMessage id="fields.itemEdit" />
                                                ) : (
                                                    <FormattedMessage id="fields.itemCreate" />
                                                )}
                                            </h4>
                                        </div>
                                        <LoadingOverlay
                                            active={
                                                this.props.prerequisites.status.get('fetching') ||
                                                this.props.invoiceFetching ||
                                                this.props.invoiceSaving ||
                                                this.props.transportInfoFetching ||
                                                this.props.customerInfoFetching
                                            }
                                        >
                                            <PermissionsCheck hasAny={Object.values(PERMS)}>
                                                <Form
                                                    values={this.state.values}
                                                    validationRules={this.validationRules}
                                                    onChange={this.handleChangeValues}
                                                    onSubmit={this.handleSubmit}
                                                    isEdit={Boolean(this.props.invoice)}
                                                >

                                                    {this.props.invoice && this.props.invoice.deleted_at && (
                                                        <div className="row">
                                                            <div className="col-lg-12">
                                                                <div className="alert alert-danger m-b-lg">
                                                                    <div className="row">
                                                                        <div className="col-sm-8">
                                                                            <p className="no-m">
                                                                                <FormattedMessage id="alerts.messages.warningDeleted" />
                                                                            </p>
                                                                        </div>
                                                                        <PermissionsCheck has={[PERMS.RESTORE]}>
                                                                            <div className="col-sm-4 text-right">
                                                                                <button onClick={this.handleRestore} type="button" className="btn btn-danger">
                                                                                    <FormattedMessage id="buttons.restore" />
                                                                                </button>
                                                                            </div>
                                                                        </PermissionsCheck>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}

                                                    <div className="row">
                                                        <div className="col-md-6 b-r">
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <SelectField
                                                                        id="company_id"
                                                                        label={this.props.intl.formatMessage({ id: 'fields.supplier' })}
                                                                        values={companiesOptions}
                                                                    />
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <SelectField
                                                                        id="company_cost_center_id"
                                                                        label={this.props.intl.formatMessage({ id: 'fields.costCenter' })}
                                                                        values={costCentersOptions}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <SelectField
                                                                        id="company_numbering_id"
                                                                        label={this.props.intl.formatMessage({ id: 'fields.numbering' })}
                                                                        values={numberingsOptions}
                                                                    />
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <SelectField
                                                                        id="currency_id"
                                                                        label={this.props.intl.formatMessage({ id: 'fields.currency' })}
                                                                        values={currenciesOptions}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <SelectField
                                                                        id="payment_method_id"
                                                                        label={this.props.intl.formatMessage({ id: 'fields.paymentMethod' })}
                                                                        values={paymentMethodsOptions}
                                                                    />
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <SelectField
                                                                        id="company_bank_account_id"
                                                                        label={this.props.intl.formatMessage({ id: 'fields.bankAccount' })}
                                                                        values={bankAccountsOptions}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <SelectField
                                                                        id="tax_transaction_type_id"
                                                                        label={this.props.intl.formatMessage({ id: 'fields.taxTransactionType' })}
                                                                        values={taxTransactionTypesOptions}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-md-4">
                                                                    <div className="form-group w-200">
                                                                        <label htmlFor="value_date_issued">
                                                                            <FormattedMessage id="fields.dateIssue" />
                                                                        </label>
                                                                        <DatePicker
                                                                            id="value_date_issued"
                                                                            dateFormat="DD.MM.YYYY"
                                                                            autoComplete="off"
                                                                            selected={helpers.convertCSDateToMoment(this.state.datetimes.date_issued)}
                                                                            value={this.state.datetimes.date_issued}
                                                                            onChange={value => {
                                                                                this.handleDatetimeChange(
                                                                                    'date_issued',
                                                                                    value ? formatters.dateFormatter(value) : ''
                                                                                )
                                                                            }}
                                                                            onChangeRaw={e => {
                                                                                this.handleDatetimeChange('date_issued', e.target.value)
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <div className="form-group w-200">
                                                                        <label htmlFor="value_date_due">
                                                                            <FormattedMessage id="fields.dateDue" />
                                                                        </label>
                                                                        <DatePicker
                                                                            id="value_date_due"
                                                                            dateFormat="DD.MM.YYYY"
                                                                            autoComplete="off"
                                                                            selected={helpers.convertCSDateToMoment(this.state.datetimes.date_due)}
                                                                            value={this.state.datetimes.date_due}
                                                                            onChange={value => {
                                                                                this.handleDatetimeChange(
                                                                                    'date_due',
                                                                                    value ? formatters.dateFormatter(value) : ''
                                                                                )
                                                                            }}
                                                                            onChangeRaw={e => {
                                                                                this.handleDatetimeChange('date_due', e.target.value)
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <div className="form-group w-200">
                                                                        <label htmlFor="value_date_tax">
                                                                            <FormattedMessage id="fields.dateTax" />
                                                                        </label>
                                                                        <DatePicker
                                                                            id="value_date_tax"
                                                                            dateFormat="DD.MM.YYYY"
                                                                            autoComplete="off"
                                                                            selected={helpers.convertCSDateToMoment(this.state.datetimes.date_tax)}
                                                                            value={this.state.datetimes.date_tax}
                                                                            onChange={value => {
                                                                                this.handleDatetimeChange(
                                                                                    'date_tax',
                                                                                    value ? formatters.dateFormatter(value) : ''
                                                                                )
                                                                            }}
                                                                            onChangeRaw={e => {
                                                                                this.handleDatetimeChange('date_tax', e.target.value)
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-md-4">
                                                                    <div className="form-group">
                                                                        <label htmlFor="value_paid_at">
                                                                            <FormattedMessage id="fields.paidAt" />
                                                                        </label>
                                                                        <DatePicker
                                                                            id="value_paid_at"
                                                                            dateFormat="DD.MM.YYYY"
                                                                            autoComplete="off"
                                                                            selected={helpers.convertCSDateToMoment(this.state.datetimes.paid_at)}
                                                                            value={this.state.datetimes.paid_at}
                                                                            onChange={value => {
                                                                                this.handleDatetimeChange(
                                                                                    'paid_at',
                                                                                    value ? formatters.dateFormatter(value) : ''
                                                                                )
                                                                            }}
                                                                            onChangeRaw={e => {
                                                                                this.handleDatetimeChange('paid_at', e.target.value)
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <InputField
                                                                        id="paid_amount"
                                                                        label={this.props.intl.formatMessage({ id: 'fields.paidAmount' })}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <InputField
                                                                id="note"
                                                                className="mh-50"
                                                                label={this.props.intl.formatMessage({ id: 'fields.note' })}
                                                                autosize
                                                            />
                                                        </div>

                                                        <div className="col-md-6">
                                                            <SelectField
                                                                id="customer_id"
                                                                label={this.props.intl.formatMessage({ id: 'fields.customer' })}
                                                                values={customersOptions}
                                                            />
                                                            <SelectField
                                                                id="customer_address_id"
                                                                label={this.props.intl.formatMessage({ id: 'fields.invoicingAddress' })}
                                                                values={invoicingAddressesOptions}
                                                            />
                                                            <SelectField
                                                                id="customer_delivery_address_id"
                                                                label={this.props.intl.formatMessage({ id: 'fields.deliveryAddress' })}
                                                                values={deliveryAddressesOptions}
                                                            />
                                                        </div>
                                                    </div>

                                                    <hr />

                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <SelectField
                                                                id="text_before_items_type_id"
                                                                values={textBeforeItemsTypeOptions}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <table className="table table-striped table-hover">
                                                                <thead>
                                                                    <tr>
                                                                        <th className="w-70">
                                                                            <FormattedMessage id="fields.count" />
                                                                        </th>
                                                                        <th className="w-70 text-center">
                                                                            <FormattedMessage id="fields.unit" />
                                                                        </th>
                                                                        <th className="w-max wm-400">
                                                                            <FormattedMessage id="fields.item" />
                                                                        </th>
                                                                        <th className="w-100 text-right">
                                                                            <FormattedMessage id="fields.unitPrice" />
                                                                        </th>
                                                                        <th className="w-80">
                                                                            <FormattedMessage id="fields.vat" /> %
                                                                        </th>
                                                                        <th className="w-100">
                                                                            <FormattedMessage id="fields.invoiceItemType" />
                                                                        </th>
                                                                        {!isDisableField && <th className="w-40" />}
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {invoiceItems.map((item, key) => (
                                                                        // eslint-disable-next-line react/no-array-index-key
                                                                        <tr key={`invoiceItems-${key}`}>
                                                                            <td className="text-right">
                                                                                <input
                                                                                    type="text"
                                                                                    value={item.units_count}
                                                                                    className="form-control"
                                                                                    onChange={e => {
                                                                                        this.handleChangeItemValues(
                                                                                            key,
                                                                                            'units_count',
                                                                                            e.target.value
                                                                                        )
                                                                                    }}
                                                                                    disabled={isDisableField}
                                                                                />
                                                                            </td>
                                                                            <td className="text-center">
                                                                                <input
                                                                                    type="text"
                                                                                    value={item.unit}
                                                                                    className="form-control text-center"
                                                                                    onChange={e => {
                                                                                        this.handleChangeItemValues(key, 'unit', e.target.value)
                                                                                    }}
                                                                                    disabled={isDisableField}
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <div>
                                                                                    <input
                                                                                        type="text"
                                                                                        value={item.name}
                                                                                        className="form-control wp-50 m-b-xxs pull-left"
                                                                                        onChange={e => {
                                                                                            this.handleChangeItemValues(key, 'name', e.target.value)
                                                                                        }}
                                                                                        placeholder={this.props.intl.formatMessage({
                                                                                            id: 'fields.name',
                                                                                        })}
                                                                                        disabled={isDisableField}
                                                                                    />
                                                                                    <div className="input-group wp-20 m-b-xxs pull-right">
                                                                                        <input
                                                                                            type="text"
                                                                                            value={item.transport_id}
                                                                                            className="form-control"
                                                                                            onChange={e => {
                                                                                                this.handleChangeItemValues(
                                                                                                    key,
                                                                                                    'transport_id',
                                                                                                    e.target.value
                                                                                                )
                                                                                            }}
                                                                                            placeholder={this.props.intl.formatMessage({
                                                                                                id: 'fields.transportNo',
                                                                                            })}
                                                                                            disabled={isDisableField}
                                                                                        />
                                                                                        <span className="input-group-btn">
                                                                                            <button
                                                                                                onClick={() => {
                                                                                                    this.handleTransportSearchButtonClick(key)
                                                                                                }}
                                                                                                className="btn btn-default"
                                                                                                type="button"
                                                                                                disabled={isDisableField}
                                                                                            >
                                                                                                <span className="far fa-search" />
                                                                                            </button>
                                                                                        </span>
                                                                                    </div>
                                                                                </div>
                                                                                <input
                                                                                    type="text"
                                                                                    value={item.text}
                                                                                    className="form-control wp-100"
                                                                                    onChange={e => {
                                                                                        this.handleChangeItemValues(key, 'text', e.target.value)
                                                                                    }}
                                                                                    placeholder={this.props.intl.formatMessage({
                                                                                        id: 'fields.description',
                                                                                    })}
                                                                                    disabled={isDisableField}
                                                                                />
                                                                            </td>
                                                                            <td className="text-right">
                                                                                <input
                                                                                    type="text"
                                                                                    value={item.unit_price}
                                                                                    className="form-control text-right"
                                                                                    onChange={e => {
                                                                                        this.handleChangeItemValues(key, 'unit_price', e.target.value)
                                                                                    }}
                                                                                    disabled={isDisableField}
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <select
                                                                                    value={item.tax_rate_id}
                                                                                    className="form-control"
                                                                                    onChange={e => {
                                                                                        this.handleChangeItemValues(
                                                                                            key,
                                                                                            'tax_rate_id',
                                                                                            e.target.value
                                                                                        )
                                                                                    }}
                                                                                    disabled={isDisableField}
                                                                                >
                                                                                    {taxRatesOptions.map(option => (
                                                                                        // eslint-disable-next-line react/no-array-index-key
                                                                                        <option
                                                                                            key={`taxRatesOptions-${key}-${option.id}`}
                                                                                            value={option.id}
                                                                                        >
                                                                                            {option.name}
                                                                                        </option>
                                                                                    ))}
                                                                                </select>
                                                                            </td>
                                                                            <td>
                                                                                <select
                                                                                    value={item.invoice_item_type_id}
                                                                                    className="form-control"
                                                                                    onChange={e => {
                                                                                        this.handleChangeItemType(
                                                                                            key,
                                                                                            'invoice_item_type_id',
                                                                                            e.target.value
                                                                                        )
                                                                                    }}
                                                                                    disabled={isDisableField}
                                                                                >
                                                                                    {invoiceItemTypes.map(option => (
                                                                                        <option
                                                                                            // eslint-disable-next-line react/no-array-index-key
                                                                                            key={`invoiceItemTypes-${key}-${option.id}`}
                                                                                            value={option.id}
                                                                                        >
                                                                                            {option.name}
                                                                                        </option>
                                                                                    ))}
                                                                                </select>
                                                                            </td>
                                                                            {!isDisableField && (
                                                                                <td className="text-center">
                                                                                    <a
                                                                                        href="#"
                                                                                        className="display-inline m-t-xxs text-gray-light text-md"
                                                                                        onClick={e => {
                                                                                            this.handleRemoveItem(e, key)
                                                                                        }}
                                                                                    >
                                                                                        <i className="far fa-trash" />
                                                                                    </a>
                                                                                </td>
                                                                            )}
                                                                        </tr>
                                                                    ))}

                                                                    <tr className="transparent">
                                                                        <td colSpan="3" className="text-right">
                                                                            <strong>
                                                                                <FormattedMessage id="fields.totalExclVat" />:
                                                                            </strong>
                                                                        </td>
                                                                        <td colSpan="2" className="text-right">
                                                                            <strong>
                                                                                {formatters.priceFormatter(
                                                                                    total.exclVat,
                                                                                    '0,0.00',
                                                                                    currency ? currency.iso_code : ''
                                                                                )}
                                                                            </strong>
                                                                        </td>
                                                                        <td />
                                                                        {!isDisableField && <td />}
                                                                    </tr>
                                                                    <tr className="transparent">
                                                                        <td colSpan="3" className="text-right">
                                                                            <strong>
                                                                                <FormattedMessage id="fields.vat" />:
                                                                            </strong>
                                                                        </td>
                                                                        <td colSpan="2" className="text-right">
                                                                            <strong>
                                                                                {formatters.priceFormatter(
                                                                                    total.vat,
                                                                                    '0,0.00',
                                                                                    currency ? currency.iso_code : ''
                                                                                )}
                                                                            </strong>
                                                                        </td>
                                                                        <td />
                                                                        {!isDisableField && <td />}
                                                                    </tr>
                                                                    <tr className="transparent">
                                                                        <td colSpan="3" className="text-right">
                                                                            <strong>
                                                                                <FormattedMessage id="fields.totalInclVat" />:
                                                                            </strong>
                                                                        </td>
                                                                        <td colSpan="2" className="text-right">
                                                                            <strong>
                                                                                {formatters.priceFormatter(
                                                                                    total.inclVat,
                                                                                    '0,0.00',
                                                                                    currency ? currency.iso_code : ''
                                                                                )}
                                                                            </strong>
                                                                        </td>
                                                                        <td />
                                                                        {!isDisableField && <td />}
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                            {!isDisableField && (
                                                                <div className="row m-b-md">
                                                                    <div className="col-md-6">
                                                                        <button
                                                                            className="btn btn-default btn-addon"
                                                                            onClick={e => {
                                                                                this.handleAddItem(e)
                                                                            }}
                                                                        >
                                                                            <i className="far fa-plus" /> <FormattedMessage id="buttons.addItem" />
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>

                                                    <div className="btns-form">
                                                        <Link
                                                            to={helpers.url(this.props.match, 'invoicing-invoices')}
                                                            className="btn btn-addon btn-default"
                                                        >
                                                            <i className="far fa-chevron-left" /> <FormattedMessage id="buttons.back" />
                                                        </Link>
                                                        {this.props.invoice && (
                                                            <SubmitButton
                                                                saveAndClose
                                                                onClick={this.handleSaveAndClose}
                                                                isEdit={Boolean(this.props.invoice)}
                                                                perms={[PERMS.CREATE, PERMS.UPDATE]}
                                                                className="btn-addon pull-right m-l-sm"
                                                            />
                                                        )}
                                                        <SubmitButton
                                                            isEdit={Boolean(this.props.invoice)}
                                                            perms={[PERMS.CREATE, PERMS.UPDATE]}
                                                            className="btn-addon pull-right"
                                                        />
                                                    </div>
                                                </Form>
                                            </PermissionsCheck>
                                        </LoadingOverlay>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ConfirmDialog options={this.state.confirmDialog} />
            </PermissionsCheck>
        )
    }
}

function mapStateToProps(state) {
    return {
        invoice: getCurrentInvoice(state),
        invoiceSupportData: getCurrentSupportData(state),
        invoiceFetching: getCurrentInvoiceFetching(state),
        invoiceSaving: getCurrentInvoiceSaving(state),
        invoiceError: getCurrentInvoiceError(state),
        transportInfo: getTransportInfo(state),
        transportInfoFetching: getTransportInfoFetching(state),
        transportInfoError: getTransportInfoError(state),
        customerInfo: getCustomerInfo(state),
        customerInfoFetching: getCustomerInfoFetching(state),
        prerequisites: preparePrerequisitesFetchStatusSelectors(componentIdentifier, prerequisites, state),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            ...bindActionCreators(
                {
                    ...actionCreators,
                    ...preparePrerequisitesActionCreators(componentIdentifier, prerequisites),
                },
                dispatch
            ),
        },
        redirect: toURL => dispatch(push(toURL)),
        notify: (notification, type) => dispatch(Notifications.show(notification, type)),
    }
}

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(InvoicingInvoicesEdit)
)
