import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { push } from 'react-router-redux'
import Notifications from 'react-notification-system-redux'
import validator from 'validator'

import { url, handleCommonEditActions, validationMessage } from '../../../common/helpers'
import * as formatters from '../../../common/formatters'
import { Form, InputField, SubmitButton } from '../../../common/form'
import { createGetPackagingTypeByIDSelector, createGetCurrentPackagingTypeStatusSelector } from '../selectors'
import * as packagingTypesActionCreators from '../actionCreators'
import { LoadingOverlay } from '../../../common/loading_overlay'
import { preparePrerequisitesFetchStatusSelectors } from '../../../common/prerequisites/selectors'
import { preparePrerequisitesActionCreators } from '../../../common/prerequisites/actionCreators'
import PermissionsCheck from '../../auth/components/PermissionsCheck'
import { PERMS } from '../permissions'

const componentIdentifier = 'packaging_types_edit'
const prerequisites = []

class PackagingTypesEdit extends Component {
    defaultValues = {
        width: 0,
        height: 0,
        length: 0,
        weight: 0,
    }

    validationRules = {
        name: value => validator.isEmpty(String(value)) && validationMessage('isEmpty'),
        width: value => !validator.isFloat(String(value)) && validationMessage('isFloat'),
        height: value => !validator.isFloat(String(value)) && validationMessage('isFloat'),
        length: value => !validator.isFloat(String(value)) && validationMessage('isFloat'),
        weight: value => !validator.isInt(String(value)) && validationMessage('isInt'),
    }

    state = {
        values: this.defaultValues,
    }

    setValues = (values, callback) => {
        this.setState(
            {
                values,
            },
            callback
        )
    }

    handleChangeValues = values => {
        this.setValues(values)
    }

    handleSubmit = values => {
        this.setValues(values, () => {
            this.handleSave()
        })
    }

    handleSave = () => {
        this.props.actions.savePackagingType(this.state.values)
    }

    componentDidMount() {
        this.props.match.params.packagingTypeID && this.props.actions.fetchPackagingType(this.props.match.params.packagingTypeID)
    }

    componentWillReceiveProps(nextProps) {
        handleCommonEditActions(this.props, nextProps, () => {
            this.props.redirect(url(this.props.match, 'packaging-types'))
        })

        if (
            JSON.stringify(nextProps.packagingType) !== JSON.stringify(this.props.packagingType) ||
            (nextProps.packagingType && !this.state.values.id)
        ) {
            this.setValues(nextProps.packagingType.toJS())
        }
    }

    render() {
        document.title = formatters.titleFormatter(this.props.intl.formatMessage({ id: 'modules.packagingTypes.heading' }))

        return (
            <PermissionsCheck hasAny={Object.values(PERMS)} noPermissionsPage>
                <div className="page-inner">
                    <div id="main-wrapper">
                        <div className="row">
                            <div className="col-md-8 col-md-offset-2 col-lg-6 col-lg-offset-3 col-xl-4 col-xl-offset-4">
                                <div className="panel panel-white">
                                    <div className="panel-body panel-padding">
                                        <div className="panel-head">
                                            <h4>
                                                <FormattedMessage id="modules.packagingTypes.heading" /> -{' '}
                                                {this.props.packagingType ? (
                                                    <FormattedMessage id="fields.itemEdit" />
                                                ) : (
                                                    <FormattedMessage id="fields.itemCreate" />
                                                )}
                                            </h4>
                                        </div>
                                        <LoadingOverlay active={this.props.prerequisites.status.get('fetching') || this.props.status.get('fetching')}>
                                            <PermissionsCheck hasAny={Object.values(PERMS)}>
                                                <Form
                                                    values={this.state.values}
                                                    validationRules={this.validationRules}
                                                    onChange={this.handleChangeValues}
                                                    onSubmit={this.handleSubmit}
                                                    isEdit={Boolean(this.props.packagingType)}
                                                >
                                                    <InputField id="name" label={this.props.intl.formatMessage({ id: 'fields.name' })} />
                                                    <InputField
                                                        id="length"
                                                        label={this.props.intl.formatMessage({ id: 'fields.lenght' })}
                                                        className="wp-50"
                                                    />
                                                    <InputField
                                                        id="width"
                                                        label={this.props.intl.formatMessage({ id: 'fields.width' })}
                                                        className="wp-50"
                                                    />
                                                    <InputField
                                                        id="height"
                                                        label={this.props.intl.formatMessage({ id: 'fields.height' })}
                                                        className="wp-50"
                                                    />
                                                    <InputField
                                                        id="weight"
                                                        label={this.props.intl.formatMessage({ id: 'fields.weight' })}
                                                        className="wp-50"
                                                    />

                                                    <div className="btns-form">
                                                        <Link to={url(this.props.match, 'packaging-types')} className="btn btn-addon btn-default">
                                                            <i className="far fa-chevron-left" /> <FormattedMessage id="buttons.back" />
                                                        </Link>
                                                        <SubmitButton
                                                            perms={Object.values(PERMS)}
                                                            isEdit={Boolean(this.props.packagingType)}
                                                            className="btn-addon pull-right"
                                                        />
                                                    </div>
                                                </Form>
                                            </PermissionsCheck>
                                        </LoadingOverlay>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </PermissionsCheck>
        )
    }
}

function mapStateToProps(state, ownProps) {
    return {
        packagingType: createGetPackagingTypeByIDSelector(ownProps.match.params.packagingTypeID)(state),
        status: createGetCurrentPackagingTypeStatusSelector()(state),
        prerequisites: preparePrerequisitesFetchStatusSelectors(componentIdentifier, prerequisites, state),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            ...bindActionCreators(
                {
                    ...packagingTypesActionCreators,
                    ...preparePrerequisitesActionCreators(componentIdentifier, prerequisites),
                },
                dispatch
            ),
        },
        redirect: toURL => dispatch(push(toURL)),
        notify: (notification, type) => dispatch(Notifications.show(notification, type)),
    }
}

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(PackagingTypesEdit)
)
