import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import moment from 'moment'
import { Modal } from 'react-bootstrap'
import classNames from 'classnames'

import { PeriodPicker } from '../../../common/period_picker'
import * as periodPickerConstants from '../../../common/period_picker/constants'
import { ConfirmDialog } from '../../../common/confirm_dialog'
import * as formatters from '../../../common/formatters'
import { LoadingOverlay } from '../../../common/loading_overlay'

import * as reportAllowancesActionCreators from '../actionCreators'
import { getYear, getData, getFetching, getDetailsData, getDetailsFetching, getDetailsSaving, getDetailsDeleting } from '../selectors'

import { prepareTableActionCreators } from '../../../common/table/actionCreators'
import { TableFilterInputField } from '../../../common/table/components'
import { getActiveFilters } from '../../../common/table/helpers'
import { TableModelColumnFilteringType } from '../../../common/table/model'
import { createTableModelSelector } from '../../../common/table/selectors'
import { getPrerequisitesArray, convertISODatetimeToMoment } from '../../../common/helpers'
import { InputField, SelectField, SubmitButton } from '../../../common/form'
import Form from '../../../common/form/components/Form'

import { preparePrerequisitesActionCreators } from '../../../common/prerequisites/actionCreators'
import { preparePrerequisitesFetchStatusSelectors } from '../../../common/prerequisites/selectors'
import PermissionsCheck from '../../auth/components/PermissionsCheck'
import { PERMS } from '../permissions'

import * as constants from '../constants'

const componentIdentifier = 'report_driver_allowances'
const prerequisites = ['currencies', 'allowance_types']

const tableIdentifier = 'report_driver_allowances'

class ReportDriverAllowances extends Component {
    state = {
        confirmDialog: {
            show: false,
            title: '',
            message: '',
            labelCancel: '',
            labelAccept: '',
            classNameAccept: 'text-success',
            classNameCancel: 'text-default',
            onCancel: () => {},
            onAccept: () => {},
        },
        modalDetails: {
            show: false,
            driverId: null,
            month: null,
        },
        modalEditAllowance: {
            show: false,
            values: {},
        },
    }

    // confirm dialog
    showConfirmDialog = options => {
        this.setState({
            confirmDialog: {
                show: true,
                title: options.title || this.props.intl.formatMessage({ id: 'confirmDialog.default.title' }),
                message: options.message || this.props.intl.formatMessage({ id: 'confirmDialog.default.message' }),
                labelCancel: options.labelCancel || this.props.intl.formatMessage({ id: 'confirmDialog.default.cancel' }),
                labelAccept: options.labelAccept || this.props.intl.formatMessage({ id: 'confirmDialog.default.accept' }),
                classNameAccept: options.classNameAccept || 'btn-success',
                classNameCancel: options.classNameCancel || 'btn-default',
                onCancel: options.onCancel || this.hideConfirmDialog,
                onAccept: options.onAccept || this.hideConfirmDialog,
            },
        })
    }

    hideConfirmDialog = () => {
        this.setState({
            confirmDialog: {
                show: false,
                onCancel: () => {},
                onAccept: () => {},
            },
        })
    }

    // List
    getYear = () => {
        if (this.props.year) {
            return parseInt(this.props.year)
        }

        return parseInt(this.props.match.params.year ? this.props.match.params.year : moment().format('YYYY'))
    }

    refresh = () => {
        this.props.actions.fetchReportAllowances(this.getYear())
    }

    resetFilters = () => {
        this.props.table.resetFilters()
    }

    isFilterActive = () => getActiveFilters(this.props.tableModel).size > 0

    handleChangeYearMonth = format => {
        const year = format.year.toString()
        const path = `/report-driver-allowances/${year}`
        this.props.history.push(path)
    }

    // Export
    handleExport = e => {
        e && e.preventDefault()

        // this.props.actions.exportReportAllowances(this.props.tableModel.get('filters').toJS())
    }

    // Detail
    handleShowDetails = (e, driverId, month) => {
        e && e.preventDefault()

        this.setState(
            {
                modalDetails: {
                    show: true,
                    driverId,
                    month,
                },
            },
            () => {
                this.props.actions.fetchReportAllowancesDetails(this.getYear(), driverId, month, this.props.tableModel.get('filters').toJS())
            }
        )
    }

    handleHideDetails = e => {
        e && e.preventDefault()

        this.setState(
            {
                modalDetails: {
                    show: false,
                    driverId: null,
                    month: null,
                },
            },
            () => {
                this.refresh()
                this.props.actions.clearReportAllowancesDetails()
            }
        )
    }

    refreshDetails = () => {
        this.props.actions.fetchReportAllowancesDetails(
            this.getYear(),
            this.state.modalDetails.driverId,
            this.state.modalDetails.month,
            this.props.tableModel.get('filters').toJS()
        )
    }

    handleExportDetails = e => {
        e && e.preventDefault()

        this.props.actions.exportReportAllowancesDetails(
            this.getYear(),
            this.state.modalDetails.driverId,
            this.state.modalDetails.month,
            this.props.tableModel.get('filters').toJS()
        )
    }

    handleChangeDetailMonth = month => {
        this.setState(
            {
                modalDetails: {
                    ...this.state.modalDetails,
                    month,
                },
            },
            () => {
                this.refreshDetails()
            }
        )
    }

    handlePrevDetailMonth = () => {
        this.handleChangeDetailMonth(this.state.modalDetails.month - 1)
    }

    handleNextDetailMonth = () => {
        this.handleChangeDetailMonth(this.state.modalDetails.month + 1)
    }

    handleCreateAllowance = transportId => {
        this.setState({
            modalEditAllowance: {
                show: true,
                values: {
                    transport_id: transportId,
                    driver_id: this.state.modalDetails.driverId,
                    year: transportId ? null : this.getYear(),
                    month: transportId ? null : this.state.modalDetails.month,
                    allowance_type: transportId ? constants.ALLOWANCE : constants.ADDTIONAL,
                    calculation_method: transportId ? constants.PER_FLAT : constants.PER_MONTH,
                    units_count: 1,
                },
            },
        })
    }

    handleEditAllowance = (e, allowance) => {
        e && e.preventDefault()
        e && e.stopPropagation()

        this.setState({
            modalEditAllowance: {
                show: true,
                values: {
                    ...allowance,
                    allowance_type: allowance.allowance_type && allowance.allowance_type.id,
                    currency_id: allowance.currency && allowance.currency.id,
                    driver_id: allowance.driver && allowance.driver.id,
                    transport_id: allowance.transport && allowance.transport.id,
                },
            },
        })
    }

    handleHideAllowanceModal = e => {
        e && e.preventDefault()

        this.setState({
            modalEditAllowance: {
                show: false,
                values: {},
            },
        })
    }

    handleDeleteAllowance = (e, allowance) => {
        e && e.preventDefault()
        e && e.stopPropagation()

        this.showConfirmDialog({
            title: this.props.intl.formatMessage({ id: 'confirmDialog.delete.title' }),
            message: this.props.intl.formatMessage({ id: 'confirmDialog.delete.message' }),
            labelCancel: this.props.intl.formatMessage({ id: 'confirmDialog.delete.cancel' }),
            labelAccept: this.props.intl.formatMessage({ id: 'confirmDialog.delete.accept' }),
            classNameAccept: 'btn-danger',
            onAccept: () => {
                this.props.actions.deleteAllowance(
                    allowance.id,
                    this.getYear(),
                    this.state.modalDetails.driverId,
                    this.state.modalDetails.month,
                    this.props.tableModel.get('filters').toJS()
                )
                this.hideConfirmDialog()
            },
        })
    }

    handleChangeAllowanceFormValues = values => {
        this.setState({
            modalEditAllowance: {
                ...this.state.modalEditAllowance,
                values,
            },
        })
    }

    handleSubmitAllowanceForm = () => {
        this.props.actions.saveAllowance(
            this.state.modalEditAllowance.values,
            this.getYear(),
            this.state.modalDetails.driverId,
            this.state.modalDetails.month,
            this.props.tableModel.get('filters').toJS()
        )
        this.handleHideAllowanceModal()
    }

    reassignAllowances = transportIds => {
        this.props.actions.reassignAllowances(
            this.getYear(),
            transportIds,
            this.state.modalDetails.driverId,
            this.state.modalDetails.month,
            this.props.tableModel.get('filters').toJS()
        )
    }

    handleReassignAllowances = e => {
        e && e.preventDefault()
        e && e.stopPropagation()

        this.showConfirmDialog({
            title: this.props.intl.formatMessage({ id: 'confirmDialog.reAssignAllowances.title' }),
            message: this.props.intl.formatMessage({ id: 'confirmDialog.reAssignAllowances.message' }),
            labelCancel: this.props.intl.formatMessage({ id: 'confirmDialog.reAssignAllowances.cancel' }),
            labelAccept: this.props.intl.formatMessage({ id: 'confirmDialog.reAssignAllowances.accept' }),
            classNameAccept: 'btn-success',
            onAccept: () => {
                this.reassignAllowances(null)
                this.hideConfirmDialog()
            },
        })
    }

    handleReassignTransportAllowances = transportId => {
        this.showConfirmDialog({
            title: this.props.intl.formatMessage({ id: 'confirmDialog.reAssignAllowances.title' }),
            message: this.props.intl.formatMessage({ id: 'confirmDialog.reAssignAllowances.message' }),
            labelCancel: this.props.intl.formatMessage({ id: 'confirmDialog.reAssignAllowances.cancel' }),
            labelAccept: this.props.intl.formatMessage({ id: 'confirmDialog.reAssignAllowances.accept' }),
            classNameAccept: 'btn-success',
            onAccept: () => {
                this.reassignAllowances([transportId])
                this.hideConfirmDialog()
            },
        })
    }

    handleReassignAllTransportAllowances = e => {
        e && e.preventDefault()
        e && e.stopPropagation()

        const transportIds = []
        const detailsData = this.props.detailsData

        if (detailsData && detailsData.transports && detailsData.transports.length) {
            detailsData.transports.forEach(transport => {
                if (transport.template_id) {
                    transportIds.push(transport.id)
                }
            })
        }

        this.showConfirmDialog({
            title: this.props.intl.formatMessage({ id: 'confirmDialog.reAssignAllowances.title' }),
            message: this.props.intl.formatMessage({ id: 'confirmDialog.reAssignAllowances.message' }),
            labelCancel: this.props.intl.formatMessage({ id: 'confirmDialog.reAssignAllowances.cancel' }),
            labelAccept: this.props.intl.formatMessage({ id: 'confirmDialog.reAssignAllowances.accept' }),
            classNameAccept: 'btn-success',
            onAccept: () => {
                this.reassignAllowances(transportIds)
                this.hideConfirmDialog()
            },
        })
    }

    componentDidMount() {
        this.props.actions.fetchReportAllowances(this.getYear())
        this.props.actions.fetchPrerequisites()
    }

    componentWillUnmount() {
        this.props.actions.clearReportAllowances()
    }

    componentWillReceiveProps(nextProps) {
        const parsedYear = parseInt(nextProps.match.params.year ? nextProps.match.params.year : moment().format('YYYY'))

        if (parsedYear !== this.getYear()) {
            this.props.actions.clearReportAllowances()
            this.props.actions.fetchReportAllowances(parsedYear)
        }
    }

    render() {
        document.title = formatters.titleFormatter(this.props.intl.formatMessage({ id: 'modules.reportAllowances.heading' }))

        const data = this.props.data

        const momentFrom = moment(`${this.getYear()}-01-01`, 'YYYY-MM-DD')
        const momentTo = moment(momentFrom).endOf('year')

        const monthKeys = [...Array(12).keys()]
        const monthTotals = {}
        const totals = {}

        monthKeys.forEach(monthKey => {
            monthTotals[monthKey] = {}
        })

        const filters = this.props.tableModel.get('filters')

        const detailsData = this.props.detailsData

        // generates array with values for allowance calculation methods
        const calculationMethods = []
        for (let i = 0; i <= 5; i++) {
            calculationMethods.push({
                id: String(i),
                name: this.props.intl.formatMessage({ id: `modules.reportAllowances.calculationMethods.${i}` }),
            })
        }

        // summary
        const allowanceTypes = getPrerequisitesArray(this.props.prerequisites.values.get('allowance_types'))
        const currencies = getPrerequisitesArray(this.props.prerequisites.values.get('currencies'), 'id', 'iso_code')

        const summary = {}
        const summaryCurrencies = {}
        allowanceTypes.forEach(allowanceType => {
            summary[allowanceType.id] = {
                name: allowanceType.name,
                currencies: {},
            }

            currencies.forEach(currency => {
                summary[allowanceType.id].currencies[currency.id] = {
                    iso_code: currency.name,
                    total: 0,
                }
            })
        })

        currencies.forEach(currency => {
            summaryCurrencies[currency.id] = {
                iso_code: currency.name,
                total: 0,
            }
        })

        let totalKilometers = 0
        let totalSeconds = 0

        return (
            <PermissionsCheck hasAny={Object.values(PERMS)} noPermissionsPage>
                <div className="page-inner">
                    <div id="main-wrapper">
                        <div className="panel panel-white">
                            <div className="panel-body">
                                <div className="wp-100 pull-left m-b-xs">
                                    <h4 className="pull-left">
                                        <span className="pull-left">
                                            <FormattedMessage id="modules.reportAllowances.heading" />
                                        </span>
                                    </h4>
                                    <div className="btns-list">
                                        <PeriodPicker
                                            className={'m-r-sm'}
                                            momentFrom={momentFrom}
                                            momentTo={momentTo}
                                            onChange={(momentFrom, momentTo, format) => this.handleChangeYearMonth(format)}
                                            allowedPickers={[periodPickerConstants.PERIOD_PICKER_TYPE_YEAR]}
                                            forbidPickType
                                        />
                                        {this.isFilterActive() > 0 && (
                                            <button className="btn btn-default btn-addon m-r-xs" onClick={this.resetFilters}>
                                                <i className="far fa-times" /> <FormattedMessage id="buttons.resetFilters" />
                                            </button>
                                        )}
                                        <button className="btn btn-default btn-addon m-r-xs" onClick={this.refresh}>
                                            <i className="far fa-sync-alt" /> <FormattedMessage id="buttons.refresh" />
                                        </button>
                                        <PermissionsCheck has={[PERMS.EXPORT]}>
                                            <button className="btn btn-primary btn-addon" onClick={this.handleExport} disabled>
                                                <i className="far fa-file-excel" /> <FormattedMessage id="buttons.exportToXLS" />
                                            </button>
                                        </PermissionsCheck>
                                    </div>
                                </div>

                                <div className="table-container">
                                    <table className="table table-striped table-hover table-fixed-header">
                                        <thead>
                                            <tr>
                                                <th className="w-max wm-300">
                                                    <FormattedMessage id="fields.driver" />
                                                </th>
                                                {monthKeys.map(key => {
                                                    const month = moment().set('month', key)

                                                    return (
                                                        <th key={`month-${key}`} className="w-100 text-right">
                                                            {this.props.intl.formatMessage({ id: `monthNames.${month.format('MM')}` })}
                                                        </th>
                                                    )
                                                })}
                                                <th className="w-140 text-right">
                                                    <FormattedMessage id="fields.total" />
                                                </th>
                                            </tr>
                                            <tr className="filters">
                                                <th className="w-max wm-300">
                                                    <TableFilterInputField
                                                        identifier="driver_name"
                                                        type={TableModelColumnFilteringType.string}
                                                        filters={filters}
                                                        onChange={this.props.table.changeFilter}
                                                    />
                                                </th>
                                                {monthKeys.map(key => (
                                                    <th key={`month-${key}`} className="w-100" />
                                                ))}
                                                <th className="w-140 text-right" />
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data &&
                                                data.drivers &&
                                                data.drivers.map(driver => {
                                                    if (
                                                        filters.getIn(['driver_name', 'value']) &&
                                                        driver.name.toLowerCase().indexOf(filters.getIn(['driver_name', 'value']).toLowerCase()) < 0
                                                    ) {
                                                        return null
                                                    }

                                                    const rowTotals = {}
                                                    monthKeys.forEach(monthKey => {
                                                        const driverMonth = driver.months[monthKey + 1]

                                                        if (driverMonth) {
                                                            Object.keys(driverMonth).forEach(currencyCode => {
                                                                const allowance = driverMonth[currencyCode]

                                                                if (!monthTotals[monthKey][currencyCode]) {
                                                                    monthTotals[monthKey][currencyCode] = 0
                                                                }

                                                                if (!rowTotals[currencyCode]) {
                                                                    rowTotals[currencyCode] = 0
                                                                }

                                                                if (!totals[currencyCode]) {
                                                                    totals[currencyCode] = 0
                                                                }

                                                                monthTotals[monthKey][currencyCode] += allowance
                                                                rowTotals[currencyCode] += allowance
                                                                totals[currencyCode] += allowance
                                                            })
                                                        }
                                                    })

                                                    return (
                                                        <tr key={`row-${driver.id}`}>
                                                            <td className="w-240">{driver.name}</td>
                                                            {monthKeys.map(monthKey => {
                                                                const driverMonth = driver.months[monthKey + 1]

                                                                return (
                                                                    <td
                                                                        key={`month-${monthKey}`}
                                                                        className="w-100 text-right td-clickable"
                                                                        onClick={e => {
                                                                            this.handleShowDetails(e, driver.id, monthKey + 1)
                                                                        }}
                                                                    >
                                                                        {driverMonth &&
                                                                            Object.keys(driverMonth).map(currencyCode => {
                                                                                const allowance = driverMonth[currencyCode]

                                                                                return (
                                                                                    <div key={`currency-${currencyCode}`}>
                                                                                        {formatters.priceFormatter(allowance, '0,0', currencyCode)}
                                                                                    </div>
                                                                                )
                                                                            })}
                                                                    </td>
                                                                )
                                                            })}
                                                            <td className="w-140 text-right">
                                                                <strong>
                                                                    {rowTotals &&
                                                                        Object.keys(rowTotals).map(currencyCode => {
                                                                            const allowance = rowTotals[currencyCode]

                                                                            return (
                                                                                <div key={`currency-${currencyCode}`}>
                                                                                    {formatters.priceFormatter(allowance, '0,0', currencyCode)}
                                                                                </div>
                                                                            )
                                                                        })}
                                                                </strong>
                                                            </td>
                                                        </tr>
                                                    )
                                                })}

                                            <tr className="b-top row-sum">
                                                <td className="w-max wm-300">
                                                    <strong>
                                                        <FormattedMessage id="fields.total" />:
                                                    </strong>
                                                </td>
                                                {monthKeys.map(monthKey => (
                                                    <td key={`total-${monthKey}`} className="w-100 text-right">
                                                        <strong>
                                                            {monthTotals[monthKey] &&
                                                                Object.keys(monthTotals[monthKey]).map(currencyCode => {
                                                                    const allowance = monthTotals[monthKey][currencyCode]

                                                                    return (
                                                                        <div key={`currency-${currencyCode}`}>
                                                                            {formatters.priceFormatter(allowance, '0,0', currencyCode)}
                                                                        </div>
                                                                    )
                                                                })}
                                                        </strong>
                                                    </td>
                                                ))}
                                                <td className="w-140 text-right">
                                                    <strong>
                                                        {totals &&
                                                            Object.keys(totals).map(currencyCode => {
                                                                const allowance = totals[currencyCode]

                                                                return (
                                                                    <div key={`currency-${currencyCode}`}>
                                                                        {formatters.priceFormatter(allowance, '0,0', currencyCode)}
                                                                    </div>
                                                                )
                                                            })}
                                                    </strong>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Modal show={Boolean(this.state.modalDetails.show)} onHide={this.handleHideDetails} className="modal-size-l">
                        <Modal.Header closeButton>
                            <Modal.Title>{detailsData ? detailsData.title : null}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="clearfix">
                            <LoadingOverlay active={this.props.detailsFetching || this.props.detailsSaving || this.props.detailsDeleting}>
                                <div className="btns-list">
                                    <span className="pull-left text-thin period-selection">
                                        <button
                                            onClick={this.handlePrevDetailMonth}
                                            className="btn btn-default btn-rounded"
                                            disabled={this.state.modalDetails.month === 1}
                                        >
                                            <i className="far fa-angle-left" />
                                        </button>
                                        <span>
                                            {this.state.modalDetails.month} / {this.getYear()}
                                        </span>
                                        <button
                                            onClick={this.handleNextDetailMonth}
                                            className="btn btn-default btn-rounded"
                                            disabled={this.state.modalDetails.month === 12}
                                        >
                                            <i className="far fa-angle-right" />
                                        </button>
                                    </span>
                                    <button className="btn btn-default btn-addon m-r-xs" onClick={this.refreshDetails}>
                                        <i className="far fa-sync-alt" /> <FormattedMessage id="buttons.refresh" />
                                    </button>
                                    <PermissionsCheck has={[PERMS.EXPORT]}>
                                        <button className="btn btn-primary btn-addon" onClick={this.handleExportDetails}>
                                            <i className="far fa-file-excel" /> <FormattedMessage id="buttons.exportToXLS" />
                                        </button>
                                    </PermissionsCheck>
                                </div>

                                <div className="pull-left wp-100 mh-500">
                                    <div className="pull-left wp-100 m-b-sm">
                                        <div className="panel-head">
                                            <h2 className="pull-left wp-100">
                                                <div className="pull-left">Přepravy</div>
                                                <div className="pull-right text-md">
                                                    {detailsData && detailsData.transports && detailsData.transports.length > 0 && (
                                                        <PermissionsCheck has={[PERMS.CREATE, PERMS.UPDATE, PERMS.DELETE]}>
                                                            <button onClick={this.handleReassignAllTransportAllowances}>
                                                                <i className="far fa-undo" />
                                                            </button>
                                                        </PermissionsCheck>
                                                    )}
                                                </div>
                                            </h2>
                                        </div>
                                        {detailsData && detailsData.transports && detailsData.transports.length ? (
                                            <div className="pull-left wp-100">
                                                {detailsData.transports.map(transport => {
                                                    const rowClassNames = classNames('pull-left wp-100 p-v-xs p-h-xs m-b-sm b', {
                                                        'alert-default': !transport.allowances || !transport.allowances.length,
                                                        'alert-success': transport.allowances && transport.allowances.length,
                                                    })

                                                    return (
                                                        <div key={`transport-${transport.id}`} className={rowClassNames}>
                                                            <div className="row">
                                                                <div className="col-md-2 b-r">
                                                                    <strong>{transport.number}</strong>
                                                                    <br />
                                                                    <small>{formatters.datetimeFormatter(transport.created_at)}</small>
                                                                </div>
                                                                <div className="col-md-3 b-r">
                                                                    <strong>{transport.customer && transport.customer.name}</strong>
                                                                    <br />
                                                                    <small>{transport.customer && transport.customer.order_number}</small>
                                                                </div>
                                                                <div className="col-md-3 b-r">
                                                                    {transport.loading && transport.loading.address}
                                                                    <br />
                                                                    <small>
                                                                        {transport.loading &&
                                                                            transport.loading.datetime &&
                                                                            formatters.datetimeFormatter(transport.loading.datetime)}
                                                                    </small>
                                                                </div>
                                                                <div className="col-md-3">
                                                                    {transport.unloading && transport.unloading.address}
                                                                    <br />
                                                                    <small>
                                                                        {transport.unloading &&
                                                                            transport.unloading.datetime &&
                                                                            formatters.datetimeFormatter(transport.unloading.datetime)}
                                                                    </small>
                                                                </div>
                                                                <div className="col-md-1 text-right">
                                                                    {(transport.template_id || transport.is_cabotage) && (
                                                                        <PermissionsCheck has={[PERMS.CREATE, PERMS.UPDATE, PERMS.DELETE]}>
                                                                            <button
                                                                                onClick={() => this.handleReassignTransportAllowances(transport.id)}
                                                                            >
                                                                                <i className="far fa-undo" />
                                                                            </button>
                                                                        </PermissionsCheck>
                                                                    )}
                                                                    <PermissionsCheck has={[PERMS.CREATE]}>
                                                                        <button onClick={() => this.handleCreateAllowance(transport.id)}>
                                                                            <i className="far fa-plus-circle" />
                                                                        </button>
                                                                    </PermissionsCheck>
                                                                </div>
                                                            </div>

                                                            {transport.allowances && transport.allowances.length ? (
                                                                <div className="table-container pull-left wp-100 m-t-sm">
                                                                    <table className="table table-no-border no-m">
                                                                        <tbody>
                                                                            {transport.allowances.map((allowance, index) => {
                                                                                const typeId = allowance.allowance_type && allowance.allowance_type.id
                                                                                const currencyId = allowance.currency && allowance.currency.id
                                                                                const totalAllowance = allowance.allowance * allowance.units_count

                                                                                if (typeId && currencyId) {
                                                                                    summary[typeId].currencies[currencyId].total += totalAllowance
                                                                                }

                                                                                return (
                                                                                    <tr
                                                                                        onClick={e => this.handleEditAllowance(e, allowance)}
                                                                                        className="row-selectable"
                                                                                        key={`row-${index}`}
                                                                                    >
                                                                                        <td className="w-60 table-buttons">
                                                                                            <PermissionsCheck has={[PERMS.UPDATE]}>
                                                                                                <button className="far fa-pencil text-gray m-r-xxs" />
                                                                                            </PermissionsCheck>
                                                                                            <PermissionsCheck has={[PERMS.DELETE]}>
                                                                                                <button
                                                                                                    onClick={e =>
                                                                                                        this.handleDeleteAllowance(e, allowance)
                                                                                                    }
                                                                                                    className="far fa-trash text-gray"
                                                                                                />
                                                                                            </PermissionsCheck>
                                                                                        </td>
                                                                                        <td className="w-160">
                                                                                            {allowance.driver && allowance.driver.name}
                                                                                        </td>
                                                                                        <td className="w-120">
                                                                                            {allowance.allowance_type &&
                                                                                                allowance.allowance_type.name}
                                                                                        </td>
                                                                                        <td className="w-140">
                                                                                            <FormattedMessage
                                                                                                id={`modules.reportAllowances.calculationMethods.${
                                                                                                    allowance.calculation_method
                                                                                                }`}
                                                                                            />
                                                                                        </td>
                                                                                        <td className="w-120 text-right">
                                                                                            {formatters.priceFormatter(
                                                                                                allowance.allowance,
                                                                                                '0,0',
                                                                                                allowance.currency ? allowance.currency.iso_code : ''
                                                                                            )}
                                                                                        </td>
                                                                                        <td className="w-80 text-right">
                                                                                            {formatters.integerFormatter(allowance.units_count)} x
                                                                                        </td>
                                                                                        <td className="w-120 text-right">
                                                                                            <strong>
                                                                                                {formatters.priceFormatter(
                                                                                                    totalAllowance,
                                                                                                    '0,0',
                                                                                                    allowance.currency
                                                                                                        ? allowance.currency.iso_code
                                                                                                        : ''
                                                                                                )}
                                                                                            </strong>
                                                                                        </td>
                                                                                        <td className="w-max wm-200">{allowance.notice}</td>
                                                                                    </tr>
                                                                                )
                                                                            })}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        ) : (
                                            <div className="alert alert-warning pull-left wp-100">
                                                <p>
                                                    <FormattedMessage id="fields.noTransportsFound" />
                                                </p>
                                            </div>
                                        )}
                                    </div>

                                    <div className="pull-left wp-100">
                                        <div className="panel-head">
                                            <h2 className="pull-left wp-100">
                                                <div className="pull-left">Další odměny</div>
                                                <div className="pull-right text-md">
                                                    <PermissionsCheck has={[PERMS.CREATE, PERMS.UPDATE, PERMS.DELETE]}>
                                                        <button onClick={this.handleReassignAllowances}>
                                                            <i className="far fa-undo" />
                                                        </button>
                                                    </PermissionsCheck>
                                                    <PermissionsCheck has={[PERMS.CREATE]}>
                                                        <button onClick={() => this.handleCreateAllowance(null)}>
                                                            <i className="far fa-plus-circle" />
                                                        </button>
                                                    </PermissionsCheck>
                                                </div>
                                            </h2>
                                        </div>
                                        {detailsData && detailsData.allowances && detailsData.allowances.length ? (
                                            <div className="pull-left wp-100 p-v-xs p-h-xs m-b-sm b alert-default">
                                                <div className="table-container">
                                                    <table className="table table-striped table-no-border no-m">
                                                        <thead>
                                                            <tr>
                                                                <th className="w-60" />
                                                                <th className="w-160">
                                                                    <FormattedMessage id="fields.driver" />
                                                                </th>
                                                                <th className="w-120">
                                                                    <FormattedMessage id="fields.allowanceType" />
                                                                </th>
                                                                <th className="w-140">
                                                                    <FormattedMessage id="fields.allowanceCalculationMethod" />
                                                                </th>
                                                                <th className="w-120 text-right">
                                                                    <FormattedMessage id="fields.allowanceAmount" />
                                                                </th>
                                                                <th className="w-80 text-right">
                                                                    <FormattedMessage id="fields.count" />
                                                                </th>
                                                                <th className="w-120 text-right">
                                                                    <FormattedMessage id="fields.total" />
                                                                </th>
                                                                <th className="w-max wm-200">
                                                                    <FormattedMessage id="fields.note" />
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {detailsData.allowances.map((allowance, index) => {
                                                                const typeId = allowance.allowance_type && allowance.allowance_type.id
                                                                const currencyId = allowance.currency && allowance.currency.id
                                                                const totalAllowance = allowance.allowance * allowance.units_count

                                                                if (typeId && currencyId) {
                                                                    summary[typeId].currencies[currencyId].total += totalAllowance
                                                                }

                                                                return (
                                                                    <tr
                                                                        onClick={e => this.handleEditAllowance(e, allowance)}
                                                                        className="row-selectable"
                                                                        key={`row-${index}`}
                                                                    >
                                                                        <td className="w-60 table-buttons">
                                                                            <PermissionsCheck has={[PERMS.UPDATE]}>
                                                                                <button className="far fa-pencil text-gray m-r-xxs" />
                                                                            </PermissionsCheck>
                                                                            <PermissionsCheck has={[PERMS.DELETE]}>
                                                                                <button
                                                                                    onClick={e => this.handleDeleteAllowance(e, allowance)}
                                                                                    className="far fa-trash text-gray"
                                                                                />
                                                                            </PermissionsCheck>
                                                                        </td>
                                                                        <td className="w-160">{allowance.driver && allowance.driver.name}</td>
                                                                        <td className="w-120">
                                                                            {allowance.allowance_type && allowance.allowance_type.name}
                                                                        </td>
                                                                        <td className="w-140">
                                                                            <FormattedMessage
                                                                                id={`modules.reportAllowances.calculationMethods.${
                                                                                    allowance.calculation_method
                                                                                }`}
                                                                            />
                                                                        </td>
                                                                        <td className="w-120 text-right">
                                                                            {formatters.priceFormatter(
                                                                                allowance.allowance,
                                                                                '0,0',
                                                                                allowance.currency ? allowance.currency.iso_code : ''
                                                                            )}
                                                                        </td>
                                                                        <td className="w-80 text-right">
                                                                            {formatters.integerFormatter(allowance.units_count)} x
                                                                        </td>
                                                                        <td className="w-120 text-right">
                                                                            <strong>
                                                                                {formatters.priceFormatter(
                                                                                    totalAllowance,
                                                                                    '0,0',
                                                                                    allowance.currency ? allowance.currency.iso_code : ''
                                                                                )}
                                                                            </strong>
                                                                        </td>
                                                                        <td className="w-max wm-200">{allowance.notice}</td>
                                                                    </tr>
                                                                )
                                                            })}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="alert alert-warning pull-left wp-100">
                                                <p>
                                                    <FormattedMessage id="fields.noAllowancesFound" />
                                                </p>
                                            </div>
                                        )}
                                    </div>

                                    <div className="pull-left wp-100">
                                        <div className="panel-head">
                                            <h2 className="pull-left wp-100">
                                                <div className="pull-left">Denní přehled jízd</div>
                                            </h2>
                                        </div>
                                        {detailsData && detailsData.days && detailsData.days.length > 0 && (
                                            <div className="pull-left wp-100 p-v-xs p-h-xs m-b-sm b alert-default">
                                                <div className="table-container">
                                                    <table className="table table-compact table-striped table-no-border no-m">
                                                        <thead>
                                                            <tr>
                                                                {detailsData.days.map(day => {
                                                                    const date = convertISODatetimeToMoment(day.date)
                                                                    const dayNumber = date.format('DD')

                                                                    return (
                                                                        <th key={`days-${dayNumber}`} className="w-33 text-center">
                                                                            {dayNumber}
                                                                        </th>
                                                                    )
                                                                })}
                                                                <th className="w-max wm-100 text-right">Celkem</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                {detailsData.days.map(day => {
                                                                    const date = convertISODatetimeToMoment(day.date)
                                                                    const dayNumber = date.format('DD')
                                                                    const cellClassNames = classNames('text-center', {
                                                                        'is-weekend': date.isoWeekday() > 5,
                                                                    })

                                                                    totalKilometers += Math.round(day.kilometers)

                                                                    return (
                                                                        <td key={`days-${dayNumber}`} className={cellClassNames}>
                                                                            {day.kilometers > 0 &&
                                                                                formatters.integerFormatter(Math.round(day.kilometers))}
                                                                        </td>
                                                                    )
                                                                })}
                                                                <td className="text-right">
                                                                    <strong>{formatters.distanceFormatter(totalKilometers)}</strong>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                {detailsData.days.map(day => {
                                                                    const date = convertISODatetimeToMoment(day.date)
                                                                    const dayNumber = date.format('DD')
                                                                    const cellClassNames = classNames('text-center text-small', {
                                                                        'is-weekend': date.isoWeekday() > 5,
                                                                    })

                                                                    totalSeconds += day.seconds

                                                                    return (
                                                                        <td key={`days-${dayNumber}`} className={cellClassNames}>
                                                                            {day.seconds > 0 && formatters.timeFromSecondsFormatter(day.seconds)}
                                                                        </td>
                                                                    )
                                                                })}
                                                                <td className="text-right">
                                                                    <strong>
                                                                        {formatters.hoursFromMinutesFormatter(Math.round(totalSeconds / 60))}
                                                                    </strong>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        )}
                                    </div>

                                    <div className="pull-left wp-100">
                                        <div className="panel-head">
                                            <h2 className="pull-left wp-100">
                                                <div className="pull-left">Souhrn</div>
                                            </h2>
                                        </div>
                                        <div className="pull-left wp-100 p-v-xs p-h-xs m-b-sm b alert-default">
                                            <div className="table-container">
                                                <table className="table table-striped table-no-border no-m">
                                                    <thead>
                                                        <tr>
                                                            <th className="w-max wm-300">
                                                                <FormattedMessage id="fields.allowanceType" />
                                                            </th>
                                                            {currencies.map(currency => (
                                                                <th key={`currency-${currency.id}`} className="w-140 text-right">
                                                                    {currency.name}
                                                                </th>
                                                            ))}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {allowanceTypes.map(allowanceType => (
                                                            <tr key={`allowanceType-${allowanceType.id}`}>
                                                                <td>{allowanceType.name}</td>
                                                                {currencies.map(currency => {
                                                                    const total = summary[allowanceType.id].currencies[currency.id].total
                                                                    summaryCurrencies[currency.id].total += total

                                                                    return (
                                                                        <td key={`currency-${currency.id}`} className="text-right">
                                                                            {formatters.priceFormatter(total, '0,0', currency.name)}
                                                                        </td>
                                                                    )
                                                                })}
                                                            </tr>
                                                        ))}
                                                        <tr className="b-top row-sum">
                                                            <td className="w-max wm-300">
                                                                <strong>
                                                                    <FormattedMessage id="fields.total" />:
                                                                </strong>
                                                            </td>
                                                            {Object.keys(summaryCurrencies).map(currencyId => {
                                                                const currency = summaryCurrencies[currencyId]

                                                                return (
                                                                    <td key={`currency-${currencyId}`} className="text-right">
                                                                        <strong>
                                                                            {formatters.priceFormatter(currency.total, '0,0', currency.iso_code)}
                                                                        </strong>
                                                                    </td>
                                                                )
                                                            })}
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </LoadingOverlay>
                        </Modal.Body>
                        <Modal.Footer>
                            <button className="btn btn-default" onClick={this.handleHideDetails}>
                                <FormattedMessage id="confirmDialog.duplicityFound.cancel" />
                            </button>
                        </Modal.Footer>
                    </Modal>

                    <Modal show={this.state.modalEditAllowance.show} className="modal-size-sm" onHide={this.handleHideAllowanceModal}>
                        <Modal.Header closeButton>
                            <Modal.Title>
                                {this.state.modalEditAllowance.values.id ? (
                                    <FormattedMessage id="fields.itemEdit" />
                                ) : (
                                    <FormattedMessage id="fields.itemCreate" />
                                )}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <PermissionsCheck hasAny={Object.values(PERMS)}>
                                <Form
                                    validationRules={{}}
                                    values={this.state.modalEditAllowance.values}
                                    onChange={this.handleChangeAllowanceFormValues}
                                    onSubmit={this.handleSubmitAllowanceForm}
                                    isEdit={Boolean(this.state.modalEditAllowance.values.id)}
                                >
                                    <label htmlFor="nothing">
                                        <FormattedMessage id="modules.transports.driver" />:
                                    </label>

                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label htmlFor="nothing">
                                                    <FormattedMessage id={'fields.allowanceType'} />:
                                                </label>
                                                <div>
                                                    <SelectField
                                                        id="allowance_type"
                                                        values={getPrerequisitesArray(
                                                            this.props.prerequisites.values.get('allowance_types'),
                                                            'id',
                                                            'name'
                                                        )}
                                                        prompt={false}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label htmlFor="nothing">
                                                    <FormattedMessage id={'fields.allowanceCalculationMethod'} />:
                                                </label>
                                                <SelectField id="calculation_method" values={calculationMethods} prompt={false} />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label htmlFor="nothing">
                                                    <FormattedMessage id={'fields.allowanceAmount'} />:
                                                </label>
                                                <div>
                                                    <InputField id="allowance" type="numeric" className="text-right" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-3">
                                            <div className="form-group">
                                                <label htmlFor="nothing">
                                                    <FormattedMessage id={'fields.currency'} />:
                                                </label>
                                                <div>
                                                    <SelectField
                                                        id="currency_id"
                                                        values={getPrerequisitesArray(
                                                            this.props.prerequisites.values.get('currencies'),
                                                            'id',
                                                            'iso_code'
                                                        )}
                                                        prompt={false}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-3">
                                            <div className="form-group">
                                                <label htmlFor="nothing">
                                                    <FormattedMessage id={'fields.count'} />:
                                                </label>
                                                <div>
                                                    <InputField id="units_count" type="numeric" className="text-right" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <InputField id="notice" label={this.props.intl.formatMessage({ id: 'fields.note' })} autosize />
                                    </div>

                                    <div className="btns-form clearfix">
                                        <button type="button" className="btn btn-addon btn-default pull-left" onClick={this.handleHideAllowanceModal}>
                                            <i className="far fa-chevron-left" /> <FormattedMessage id="buttons.back" />
                                        </button>
                                        <SubmitButton
                                            perms={Object.values(PERMS)}
                                            isEdit={Boolean(this.state.modalEditAllowance.values.id)}
                                            className="btn-addon pull-right"
                                        />
                                    </div>
                                </Form>
                            </PermissionsCheck>
                        </Modal.Body>
                    </Modal>

                    <ConfirmDialog options={this.state.confirmDialog} />
                </div>
            </PermissionsCheck>
        )
    }
}

function mapStateToProps(state) {
    return {
        tableModel: createTableModelSelector(tableIdentifier)(state),
        year: getYear(state),
        data: getData(state),
        fetching: getFetching(state),
        detailsData: getDetailsData(state),
        detailsFetching: getDetailsFetching(state),
        detailsSaving: getDetailsSaving(state),
        detailsDeleting: getDetailsDeleting(state),
        prerequisites: preparePrerequisitesFetchStatusSelectors(componentIdentifier, prerequisites, state),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            ...bindActionCreators(
                {
                    ...reportAllowancesActionCreators,
                    ...preparePrerequisitesActionCreators(componentIdentifier, prerequisites),
                },
                dispatch
            ),
        },
        dispatch,
    }
}

function mergeProps(stateProps, dispatchProps, ownProps) {
    return {
        ...stateProps,
        ...dispatchProps,
        ...ownProps,
        table: bindActionCreators(prepareTableActionCreators(tableIdentifier, stateProps.tableModel), dispatchProps.dispatch),
    }
}

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps,
        mergeProps
    )(withRouter(ReportDriverAllowances))
)
