import { Observable } from 'rxjs'

import { request, parseAPIError, DownloadRequest } from '../../common/api'

import * as actionTypes from './actionTypes'
import * as actionCreators from './actionCreators'

// Fetch

const fetchReportVehicleResultsEpic = action$ =>
    action$.ofType(actionTypes.FETCH).switchMap(action =>
        Observable.concat(
            Observable.of({
                type: actionTypes.FETCH_STARTED,
            }),
            request({
                method: 'GET',
                path: `report-vehicle-results?year=${action.payload}`,
            })
                .switchMap(ajaxResponse => Observable.of(actionCreators.fetchReportVehicleResultsFulfilled(ajaxResponse.response)))
                .catch(error => Observable.of(actionCreators.fetchReportVehicleResultsRejected(parseAPIError(error))))
                .takeUntil(action$.ofType(actionTypes.FETCH_CANCELLED, actionTypes.FETCH))
        )
    )

// Export

const exportReportVehicleResultsEpic = (action$, store) =>
    action$.ofType(actionTypes.EXPORT).switchMap(action => {
        const year = action.payload.year
        const filters = JSON.stringify(action.payload.filters)
        const token = store.getState().auth.get('accessToken')

        new DownloadRequest({
            url: `report-vehicle-results/export?year=${year}&filters=${filters}&token=${token}`,
        }).run()

        return Observable.concat(
            Observable.of({
                type: actionTypes.EXPORT_FULFILLED,
            })
        )
    })

// Fetch details

const fetchReportVehicleResultsDetailsEpic = action$ =>
    action$.ofType(actionTypes.FETCH_DETAILS).switchMap(action =>
        Observable.concat(
            Observable.of({
                type: actionTypes.FETCH_DETAILS_STARTED,
            }),
            request({
                method: 'GET',
                path: `report-vehicle-results/details?filters=${JSON.stringify({ ...action.payload })}`,
            })
                .switchMap(ajaxResponse => Observable.of(actionCreators.fetchReportVehicleResultsDetailsFulfilled(ajaxResponse.response)))
                .catch(error => Observable.of(actionCreators.fetchReportVehicleResultsDetailsRejected(parseAPIError(error))))
                .takeUntil(action$.ofType(actionTypes.FETCH_DETAILS_CANCELLED))
        )
    )

// Export details

const exportReportVehicleResultsDetailsEpic = (action$, store) =>
    action$.ofType(actionTypes.EXPORT_DETAILS).switchMap(action => {
        const token = store.getState().auth.get('accessToken')

        new DownloadRequest({
            url: `report-vehicle-results/details/export?filters=${JSON.stringify({ ...action.payload })}&token=${token}`,
        }).run()

        return Observable.concat(
            Observable.of({
                type: actionTypes.EXPORT_DETAILS_FULFILLED,
            })
        )
    })

export default [
    fetchReportVehicleResultsEpic,
    exportReportVehicleResultsEpic,
    fetchReportVehicleResultsDetailsEpic,
    exportReportVehicleResultsDetailsEpic,
]
