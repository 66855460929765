import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import Notifications from 'react-notification-system-redux'
import Immutable from 'immutable'
import { injectIntl } from 'react-intl'
import { Modal } from 'react-bootstrap'

import moment from 'moment/moment'
import * as config from '../../../common/config'
import { getPrerequisitesArray, getTransportPointPlannedDatetimeFrom, getTransportPointPlannedDatetimeTo } from '../../../common/helpers'
import * as vehicleEventsActionCreators from '../../vehicle_events/actionCreators'
import * as vehiclePositionsActionCreators from '../../vehicle_positions/actionCreators'
import { getVehicleEvents, getVehicleEventsFetching } from '../../vehicle_events/selectors'
import { createGetVehiclePositionByIDSelector, getVehiclePositionsFetching } from '../../vehicle_positions/selectors'
import { createGetVehicleTypeByIDSelector } from '../../vehicle_types/selectors'
import { createGetVehicleByIDSelector } from '../../vehicles/selectors'
import { createGetTrailerByIDSelector } from '../../trailers/selectors'
import { createGetTrailerTypeByIDSelector } from '../../trailer_types/selectors'
import { createGetUserByIDSelector } from '../../users/selectors'
import { createGetDriverByIDSelector } from '../../drivers/selectors'
import { createGetDriverTimeByIDSelector } from '../../driver_times/selectors'
import { createGetTransportByIDSelector, getTransportMapPreview } from '../../transports/selectors'
import { preparePrerequisitesFetchStatusSelectors } from '../../../common/prerequisites/selectors'
import { preparePrerequisitesActionCreators } from '../../../common/prerequisites/actionCreators'
import { prepareTableActionCreators } from '../../../common/table/actionCreators'
import { createTableDataSelector } from '../../../common/table/selectors'
import { TableModelColumn } from '../../../common/table/model'
import { LoadingOverlay } from '../../../common/loading_overlay'
import * as formatters from '../../../common/formatters'

import { TRANSPORT_POINT_TYPES_DEFINITION } from '../../transports/constants'

import TransportsPreview from '../../../pages/transports/components/TransportsPreview'
import { HEREMap } from '../../../common/here_maps'
import MapPageVehiclesList from './MapPageVehiclesList'
import PermissionsCheck from '../../auth/components/PermissionsCheck'
import { PERMS } from '../permissions'

const tableIdentifier = 'vehicle_events_list'
const clientSideItemsPerPage = undefined

const componentIdentifier = 'map_page'
const prerequisites = ['dispatchers', 'drivers']

const columns = {
    datetime_start: new TableModelColumn({}),
    datetime_end: new TableModelColumn({}),
}

const defaultMapCenter = {
    lat: 49.858924,
    lng: 15.196382,
}

const defaultMapZoom = 5
const focusedMapZoom = 8

class MapPage extends Component {
    state = {
        vehicleEventsFilterValues: {},
        selectedVehicleEvent: null,
        mapCenter: defaultMapCenter,
        mapZoom: defaultMapZoom,
        showTransportPreview: false,
        showMarkers: false,
        transportPreview: null,
        modalTransportPreview: {
            show: false,
            transportId: null,
            transportPointId: null,
        },
    }

    setTransportPreview = transportPreview => {
        this.setState({
            transportPreview,
        })
    }

    showTransportPreview = boolean => {
        this.setState({
            showTransportPreview: boolean,
        })
    }

    handleShowMarkers = boolean => {
        this.setState({
            showMarkers: boolean,
        })
    }

    // Transport preview
    handleModalTransportPreviewOpen = (transportId, transportPointId) => {
        this.setState({
            modalTransportPreview: {
                show: true,
                transportId,
                transportPointId,
            },
        })
    }

    handleModalTransportPreviewClose = () => {
        this.setState({
            modalTransportPreview: {
                show: false,
                transportId: null,
                transportPointId: null,
            },
        })
    }

    handleVehicleEventsFilterChange = (field, event, value) => {
        this.setState({
            vehicleEventsFilterValues: {
                ...this.state.vehicleEventsFilterValues,
                [field]: event ? event.target.value : value,
            },
        })
    }

    handleVehicleEventsRowClick = vehicleEvent => {
        const newState = {
            selectedVehicleEvent: this.state.selectedVehicleEvent && this.state.selectedVehicleEvent.id === vehicleEvent.id ? null : vehicleEvent,
        }
        const selectedVehicleId = newState.selectedVehicleEvent && newState.selectedVehicleEvent.vehicle_id
        const transportPreviewVehicleId = this.state.transportPreview && this.state.transportPreview.vehicle_id

        if (selectedVehicleId === transportPreviewVehicleId) {
            this.showTransportPreview(true)
        } else if (selectedVehicleId) {
            this.showTransportPreview(true)
            this.setTransportPreview(null)
            this.props.actions.fetchVehicleEventForMap(newState.selectedVehicleEvent.vehicle_id)
        } else if (!newState.selectedVehicleEvent || !vehicleEvent.vehicle_id) {
            this.showTransportPreview(false)
        }

        const position = newState.selectedVehicleEvent && newState.selectedVehicleEvent.position
        if (position) {
            newState.mapCenter = {
                lat: position.gps_lat,
                lng: position.gps_lng,
            }
            newState.mapZoom = focusedMapZoom
        } else {
            newState.mapCenter = defaultMapCenter
            newState.mapZoom = defaultMapZoom
        }

        this.setState(newState)
    }

    /* eslint-disable max-len */

    getIconSVG = (vehicle, trailer, bgColor = '#ffffff', textColor = '#000000', size = 60) => (
        <svg
            version="1.2"
            baseProfile="tiny"
            width={size}
            height={size}
            viewBox="0 0 100.31094360351562 61.11110305786133"
            overflow="inherit"
            xmlns="http://www.w3.org/2000/svg"
        >
            ` +
            <path
                className="cls-1"
                d="M 98.414 0 L 1.404 0 C 0.631 0.004 0.002 0.955 0 2.127 L 0 46.381 C 0.002 47.555 0.631 48.504 1.404 48.507 L 37.867 48.594 L 49.912 59.991 L 61.128 48.505 L 98.419 48.507 C 99.194 48.504 99.819 47.555 99.824 46.381 L 99.824 2.135 C 99.824 0.955 99.194 0 98.414 0 Z"
                style={{
                    fill: bgColor,
                }}
                stroke="white"
            />
            ` +
            <text x="50" y="20" style={{ fontFamily: 'sans-serif', fontSize: '18px', textAnchor: 'middle', whiteSpace: 'pre', fill: textColor }}>
                {vehicle}
            </text>
            <text x="50" y="40" style={{ fontFamily: 'sans-serif', fontSize: '18px', textAnchor: 'middle', whiteSpace: 'pre', fill: textColor }}>
                {trailer}
            </text>
        </svg>
    )

    getIconSVGPoint = (text, bgColor = '#ffffff', textColor = '#000000', fontSize = '14px') => (
        <svg xmlns="http://www.w3.org/2000/svg" width="28px" height="30px">
            <path
                d="M 13 0 C 9.5 0 6.3 1.3 3.8 3.8 C 1.4 7.8 0 9.4 0 12.8 C 0 16.3 1.4 19.5 3.8 21.9 L 13 31 L 22.2 21.9 C 24.6 19.5 25.9 16.3 25.9 12.8 C 25.9 9.4 24.6 6.1 22.1 3.8 C 19.7 1.3 16.5 0 13 0 Z"
                fill="#ffffff"
            />
            <path
                d="M 13 2.2 C 6 2.2 2.3 7.2 2.1 12.8 C 2.1 16.1 3.1 18.4 5.2 20.5 L 13 28.2 L 20.8 20.5 C 22.9 18.4 23.8 16.2 23.8 12.8 C 23.6 7.07 20 2.2 13 2.2 Z"
                fill={bgColor}
            />
            <text x="13" y="19" fontSize={fontSize} fontWeight="bold" textAnchor="middle" fill={textColor} style={{ fontFamily: 'sans-serif' }}>
                {text}
            </text>
        </svg>
    )

    getIconSVGCircle = (color = '#000000') => (
        <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px">
            <circle cx="8" cy="8" r="7" stroke="#000000" strokeWidth="1" fill={color} />
        </svg>
    )

    /* eslint-enable max-len */

    getContentInfoBublePoint = data => {
        const plannedDatetimeFrom = getTransportPointPlannedDatetimeFrom(data, true)
        const plannedDatetimeTo = getTransportPointPlannedDatetimeTo(data, true)
        const eventDatetimeArrival = data.datetime_arrival && moment(data.datetime_arrival)
        const eventDatetimeFinished = data.datetime_finished && moment(data.datetime_finished)
        const eventDatetimePeriod = formatters.datetimePeriodFormatter(plannedDatetimeFrom, plannedDatetimeTo)

        return `
            <p class="text-center">
                <b>
                    ${this.props.intl.formatMessage({ id: 'fields.transportNo' })} ${data.transport_id}
                </b>
                <br />
                ${this.props.intl.formatMessage({ id: `transportStates.${data.transport_state}` })}
                <br />
                <b>
                    ${data.country} ${data.zipcode} ${data.city}
                </b>
                <br />
                ${this.props.intl.formatMessage({ id: TRANSPORT_POINT_TYPES_DEFINITION[data.type_id].name })}
                <br />
                ${eventDatetimePeriod ? eventDatetimePeriod : ''}
                <br />
                ${
                    eventDatetimeArrival
                        ? `<span>${this.props.intl.formatMessage({ id: 'fields.arrival' })}: ${formatters.datetimeFormatter(
                              eventDatetimeArrival
                          )}</span>`
                        : ''
                }
                <br />
                ${
                    eventDatetimeFinished
                        ? `<span>${this.props.intl.formatMessage({ id: 'fields.finish' })}: ${formatters.datetimeFormatter(
                              eventDatetimeFinished
                          )}</span>`
                        : ''
                }
                <br />
                ${data.transport_point_state ? this.props.intl.formatMessage({ id: `transportPointStates.${data.transport_point_state}` }) : '-'}
            </p>
            <div class="text-right" style="margin-right: -1em;">
                <button class="btn btn-default" data-transport="${data.transport_id}" data-point="${data.id}">
                    ${this.props.intl.formatMessage({ id: 'buttons.moreInformation' })}
                </button>
            </div>
        `
    }

    getContentInfoBubleDay = data => {
        return `
        <p><b>${formatters.dateFormatter(data.date)}</b></b>
        <p>
            ${this.props.intl.formatMessage({ id: 'vehicleTripRecordType.1' })}<br />
            ${formatters.distanceFormatter(data[1].km)}<br />
            ${formatters.durationFromSecondsFormatter(data[1].duration)}<br />
        </p>
        <p>
            ${this.props.intl.formatMessage({ id: 'vehicleTripRecordType.0' })}<br />
            ${formatters.durationFromSecondsFormatter(data[0].duration)}<br />
        </p>
        `
    }

    componentDidMount() {
        this.props.actions.clearVehicleEvents()
        this.props.actions.fetchVehicleEventsForMap()
        this.props.actions.fetchPrerequisites()
    }

    componentWillUnmount() {
        this.props.actions.clearVehicleEvents()
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.transportPreview && JSON.stringify(nextProps.transportPreview) !== JSON.stringify(this.props.transportPreview)) {
            this.setTransportPreview(nextProps.transportPreview)
        }
    }

    render() {
        document.title = formatters.titleFormatter(this.props.intl.formatMessage({ id: 'modules.map.heading' }))

        const iconSize = { w: 60, h: 60 }
        const iconAnchor = { x: 30, y: 48 }

        const iconSizeBigger = { w: 100, h: 100 }
        const iconAnchorBigger = { x: 50, y: 80 }

        let vehicleEvents = this.props.vehicleEvents.data.valueSeq().map(vehicleEvent => {
            vehicleEvent.vehicle = this.props.createGetVehicleByIDSelector(vehicleEvent.vehicle_id)
            vehicleEvent.vehiclePosition = vehicleEvent.vehicle && this.props.createGetVehiclePositionByIDSelector(vehicleEvent.vehicle.last_position)
            vehicleEvent.trailer = this.props.createGetTrailerByIDSelector(vehicleEvent.trailer_id)
            vehicleEvent.driver = this.props.createGetDriverByIDSelector(
                vehicleEvent.vehiclePosition ? vehicleEvent.vehiclePosition.driver_id : vehicleEvent.driver_id
            )
            vehicleEvent.driverTimes = vehicleEvent.driver && this.props.createGetDriverTimeByIDSelector(vehicleEvent.driver.times)
            vehicleEvent.dispatcher = vehicleEvent.vehicle && this.props.createGetUserByIDSelector(vehicleEvent.vehicle.dispatcher_id)
            vehicleEvent.transport = this.props.createGetTransportByIDSelector(vehicleEvent.transport_id)

            if (vehicleEvent.trailer) {
                vehicleEvent.trailer.trailer_type = this.props.createGetTrailerTypeByIDSelector(vehicleEvent.trailer.trailer_type_id)
            }

            vehicleEvent.position = null
            if (vehicleEvent.gps_lat && vehicleEvent.gps_lng) {
                vehicleEvent.position = {
                    gps_lat: vehicleEvent.gps_lat,
                    gps_lng: vehicleEvent.gps_lng,
                }
            }
            if (vehicleEvent.vehiclePosition) {
                vehicleEvent.position = {
                    gps_lat: vehicleEvent.vehiclePosition.gps_lat,
                    gps_lng: vehicleEvent.vehiclePosition.gps_lng,
                }
            }

            return vehicleEvent
        })

        if (this.state.vehicleEventsFilterValues) {
            const filter = this.state.vehicleEventsFilterValues

            vehicleEvents = vehicleEvents.filter(vehicleEvent => {
                if (filter.dispatcher_id && (!vehicleEvent.vehicle || vehicleEvent.vehicle.dispatcher_id !== parseInt(filter.dispatcher_id, 10))) {
                    return false
                }

                if (filter.driver_id && (!vehicleEvent.driver || vehicleEvent.driver.id !== parseInt(filter.driver_id, 10))) {
                    return false
                }

                if (filter.vehicle) {
                    if (filter.vehicle === '-' && vehicleEvent.vehicle) {
                        return false
                    }

                    if (
                        filter.vehicle !== '-' &&
                        (!vehicleEvent.vehicle || vehicleEvent.vehicle.name.toLowerCase().indexOf(filter.vehicle.toLowerCase()) === -1)
                    ) {
                        return false
                    }
                }

                if (filter.trailer) {
                    if (filter.trailer === '-' && vehicleEvent.trailer) {
                        return false
                    }

                    if (
                        filter.trailer !== '-' &&
                        (!vehicleEvent.trailer || vehicleEvent.trailer.name.toLowerCase().indexOf(filter.trailer.toLowerCase()) === -1)
                    ) {
                        return false
                    }
                }

                if (filter.vehicle_state === 1 && (!vehicleEvent.vehiclePosition || vehicleEvent.vehiclePosition.speed === 0)) {
                    return null
                }

                if (filter.vehicle_state === 2 && (!vehicleEvent.vehiclePosition || vehicleEvent.vehiclePosition.speed > 0)) {
                    return null
                }

                if (
                    filter.vehicle_update === 1 &&
                    (!vehicleEvent.vehiclePosition || moment().diff(moment(vehicleEvent.vehiclePosition.datetime), 'minutes') > 120)
                ) {
                    return null
                }

                if (
                    filter.vehicle_update === 2 &&
                    (!vehicleEvent.vehiclePosition || moment().diff(moment(vehicleEvent.vehiclePosition.datetime), 'minutes') <= 120)
                ) {
                    return null
                }

                if (filter.trailer_loaded === 1 && (!vehicleEvent.trailer || vehicleEvent.is_trailer_loaded === 1)) {
                    return null
                }

                if (filter.trailer_loaded === 2 && (!vehicleEvent.trailer || vehicleEvent.is_trailer_loaded !== 1)) {
                    return null
                }

                if (filter.trailer_damaged === 1 && (!vehicleEvent.trailer || vehicleEvent.trailer.is_damage === 1)) {
                    return null
                }

                if (filter.trailer_damaged === 2 && (!vehicleEvent.trailer || vehicleEvent.trailer.is_damage !== 1)) {
                    return null
                }

                if (
                    filter.trailer_damaged_security === 1 &&
                    (!vehicleEvent.trailer || !vehicleEvent.trailer.trailer_type || !vehicleEvent.trailer.trailer_type.is_secured)
                ) {
                    return null
                }

                if (
                    filter.trailer_damaged_security === 2 &&
                    (!vehicleEvent.trailer ||
                        !vehicleEvent.trailer.trailer_type ||
                        !vehicleEvent.trailer.trailer_type.is_secured ||
                        vehicleEvent.trailer.is_security_damage === 1)
                ) {
                    return null
                }

                if (
                    filter.trailer_damaged_security === 3 &&
                    (!vehicleEvent.trailer ||
                        !vehicleEvent.trailer.trailer_type ||
                        !vehicleEvent.trailer.trailer_type.is_secured ||
                        vehicleEvent.trailer.is_security_damage !== 1)
                ) {
                    return null
                }

                if (
                    filter.trailer_damaged_security === 4 &&
                    (!vehicleEvent.trailer || !vehicleEvent.trailer.trailer_type || vehicleEvent.trailer.trailer_type.is_secured)
                ) {
                    return null
                }

                if (
                    filter.trailer_damaged_cooling === 1 &&
                    (!vehicleEvent.trailer || !vehicleEvent.trailer.trailer_type || !vehicleEvent.trailer.trailer_type.is_cooled)
                ) {
                    return null
                }

                if (
                    filter.trailer_damaged_cooling === 2 &&
                    (!vehicleEvent.trailer ||
                        !vehicleEvent.trailer.trailer_type ||
                        !vehicleEvent.trailer.trailer_type.is_cooled ||
                        vehicleEvent.trailer.is_cooling_damage === 1)
                ) {
                    return null
                }

                if (
                    filter.trailer_damaged_cooling === 3 &&
                    (!vehicleEvent.trailer ||
                        !vehicleEvent.trailer.trailer_type ||
                        !vehicleEvent.trailer.trailer_type.is_cooled ||
                        vehicleEvent.trailer.is_cooling_damage !== 1)
                ) {
                    return null
                }

                if (
                    filter.trailer_damaged_cooling === 4 &&
                    (!vehicleEvent.trailer || !vehicleEvent.trailer.trailer_type || vehicleEvent.trailer.trailer_type.is_cooled)
                ) {
                    return null
                }

                return true
            })
        }

        let markers = {}
        vehicleEvents.forEach(vehicleEvent => {
            const position = vehicleEvent.position
            const speed = vehicleEvent.vehiclePosition ? vehicleEvent.vehiclePosition.speed : 0

            if (position) {
                const dispatcher = vehicleEvent.vehicle && vehicleEvent.dispatcher
                const isSelected = this.state.selectedVehicleEvent && vehicleEvent.id === this.state.selectedVehicleEvent.id

                if (this.state.showMarkers || !this.state.selectedVehicleEvent || isSelected) {
                    markers[vehicleEvent.id] = {
                        center: {
                            lat: position.gps_lat,
                            lng: position.gps_lng,
                        },
                        iconSvg: this.getIconSVG(
                            vehicleEvent.vehicle ? vehicleEvent.vehicle.name : '-',
                            vehicleEvent.trailer ? vehicleEvent.trailer.name : '-',
                            speed > 0 ? '#22baa0' : '#f25656',
                            dispatcher && dispatcher.rgb_bg ? dispatcher.rgb_text : '#ffffff',
                            isSelected ? 100 : 60
                        ),
                        zIndex: isSelected ? 2 : 1,
                        size: isSelected ? iconSizeBigger : iconSize,
                        anchor: isSelected ? iconAnchorBigger : iconAnchor,
                    }
                }
            }
        })
        markers = Immutable.Map(markers)

        const showTransportPreview = this.state.showTransportPreview
        const transport = this.state.transportPreview
        const routes = []

        // points
        const points = []
        if (showTransportPreview && transport && transport.points) {
            transport.points.forEach(point => {
                const position = point.position
                const pointType = point.type_id && TRANSPORT_POINT_TYPES_DEFINITION[point.type_id]
                if (position) {
                    points.push({
                        center: {
                            lat: position.gps_lat,
                            lng: position.gps_lng,
                        },
                        getIconSvg: () => this.getIconSVGPoint('', pointType && pointType.rgb_bg, pointType && pointType.rgb_text),
                        zIndex: 0,
                        size: { w: 30, h: 30 },
                        anchor: { x: 15, y: 30 },
                        infoBubbleContent: this.getContentInfoBublePoint(point),
                        infoBubbleButtonOnClick: event =>
                            this.handleModalTransportPreviewOpen(event.target.dataset.transport, event.target.dataset.point),
                    })
                }
            })
        }

        if (showTransportPreview && transport && transport.days) {
            const element = document.createElement('div')
            const icon_element = document.createElement('i')

            icon_element.className = 'fas fa-triangle'
            icon_element.style.fontSize = '28px'
            icon_element.style.lineHeight = '28px'
            icon_element.style.transform = 'scaleY(-1)'
            icon_element.style.color = '#000000'

            icon_element.style.padding = '1px'
            icon_element.style.marginTop = '-15px'
            icon_element.style.marginLeft = '-15px'

            element.appendChild(icon_element)

            transport.days.forEach(day => {
                const position = day.position
                if (position) {
                    points.push({
                        center: {
                            lat: position.gps_lat,
                            lng: position.gps_lng,
                        },
                        getIconSvg: () => element,
                        zIndex: 0,
                        size: { w: 30, h: 30 },
                        anchor: { x: 15, y: 15 },
                        infoBubbleContent: this.getContentInfoBubleDay(day),
                        domMarker: true,
                    })
                }
            })
        }

        if (showTransportPreview && transport && transport.routes) {
            if (transport.routes.planned) {
                routes.push({
                    waypoints: transport.routes.planned.waypoints,
                    color: '#7a6fbe',
                    showRoute: true,
                    showWaypoints: false,
                    showArrows: false,
                })
            }
            if (transport.routes.specified) {
                routes.push({
                    waypoints: transport.routes.specified.waypoints,
                    color: '#22BAA0',
                    showRoute: true,
                    showWaypoints: false,
                    showArrows: false,
                })
            }
            if (transport.routes.real) {
                routes.push({
                    waypoints: transport.routes.real.waypoints,
                    color: '#000000',
                    arrowsColor: '#000000',
                    showRoute: true,
                    showWaypoints: true,
                    showArrows: false,
                })
            }
        }

        return (
            <PermissionsCheck hasAny={Object.values(PERMS)} noPermissionsPage>
                <div className="page-inner">
                    <div id="main-wrapper">
                        <div className="row hp-100">
                            <div className="col-md-3 hp-100" style={{ paddingRight: '5px' }}>
                                <div className="panel panel-white hp-100 m-b-no">
                                    <LoadingOverlay
                                        className="hp-100"
                                        active={
                                            this.props.prerequisites.status.get('fetching') ||
                                            this.props.fetchingPositions ||
                                            this.props.fetchingEvents
                                        }
                                    >
                                        <div className="panel-body hp-100">
                                            {vehicleEvents && (
                                                <MapPageVehiclesList
                                                    vehicleEvents={vehicleEvents}
                                                    dispatchers={getPrerequisitesArray(this.props.prerequisites.values.get('dispatchers'))}
                                                    drivers={getPrerequisitesArray(this.props.prerequisites.values.get('drivers'))}
                                                    vehicleEventsFilterValues={this.state.vehicleEventsFilterValues}
                                                    selectedVehicleEvent={this.state.selectedVehicleEvent}
                                                    handleVehicleEventsFilterChange={this.handleVehicleEventsFilterChange}
                                                    handleVehicleEventsRowClick={this.handleVehicleEventsRowClick}
                                                />
                                            )}
                                        </div>
                                    </LoadingOverlay>
                                </div>
                            </div>

                            <div className="col-md-9 hp-100" style={{ paddingLeft: '0px' }}>
                                <div className="panel panel-white hp-100 m-b-no">
                                    <div className="panel-body hp-100">
                                        <HEREMap
                                            appId={config.CFG_HERE_MAPS_APP_ID}
                                            appCode={config.CFG_HERE_MAPS_APP_CODE}
                                            center={this.state.mapCenter}
                                            zoom={this.state.mapZoom}
                                            routes={routes}
                                            points={points}
                                            markers={markers}
                                            showMarkers={this.state.showMarkers}
                                            onShowMarkers={this.handleShowMarkers}
                                            showPOIs
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Modal show={Boolean(this.state.modalTransportPreview.show)} onHide={this.handleModalTransportPreviewClose}>
                    <React.Fragment>
                        <Modal.Header closeButton />
                        <Modal.Body style={{ marginTop: '-30px' }}>
                            <TransportsPreview
                                transportId={this.state.modalTransportPreview.transportId}
                                transportPointId={this.state.modalTransportPreview.transportPointId}
                            />
                        </Modal.Body>
                    </React.Fragment>
                </Modal>
            </PermissionsCheck>
        )
    }
}

function mapStateToProps(state) {
    return {
        vehicleEvents: createTableDataSelector({
            tableIdentifier,
            columns,
            dataSelector: getVehicleEvents,
            clientSideItemsPerPage,
        })(state),
        createGetVehiclePositionByIDSelector: id => createGetVehiclePositionByIDSelector(id)(state),
        createGetVehicleTypeByIDSelector: id => createGetVehicleTypeByIDSelector(id)(state),
        createGetUserByIDSelector: id => createGetUserByIDSelector(id)(state),
        createGetDriverByIDSelector: id => createGetDriverByIDSelector(id)(state),
        createGetDriverTimeByIDSelector: id => createGetDriverTimeByIDSelector(id)(state),
        createGetVehicleByIDSelector: id => createGetVehicleByIDSelector(id)(state),
        createGetTrailerByIDSelector: id => createGetTrailerByIDSelector(id)(state),
        createGetTrailerTypeByIDSelector: id => createGetTrailerTypeByIDSelector(id)(state),
        createGetTransportByIDSelector: id => createGetTransportByIDSelector(id)(state),
        prerequisites: preparePrerequisitesFetchStatusSelectors(componentIdentifier, prerequisites, state),
        transportPreview: getTransportMapPreview(state),
        fetchingPositions: getVehiclePositionsFetching(state),
        fetchingEvents: getVehicleEventsFetching(state),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            ...bindActionCreators(
                {
                    ...vehicleEventsActionCreators,
                    ...vehiclePositionsActionCreators,
                    ...preparePrerequisitesActionCreators(componentIdentifier, prerequisites),
                },
                dispatch
            ),
        },
        notify: (notification, type) => dispatch(Notifications.show(notification, type)),
        dispatch,
    }
}

function mergeProps(stateProps, dispatchProps, ownProps) {
    return {
        ...stateProps,
        ...dispatchProps,
        ...ownProps,
        table: bindActionCreators(prepareTableActionCreators(tableIdentifier, stateProps.tableModel), dispatchProps.dispatch),
    }
}

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps,
        mergeProps
    )(MapPage)
)
