import { Observable } from 'rxjs'
import { normalize, schema } from 'normalizr'

import { request, parseAPIError, DownloadRequest } from '../../common/api'
import { companySchema } from '../../common/normalizrSchemas'
import { normalizedEntitiesToRecordMap } from '../../common/helpers'

import * as tableModelActionTypes from '../../common/table/actionTypes'
import * as tableModelActionCreators from '../../common/table/actionCreators'
import * as countriesActionCreators from '../countries/actionCreators'
import { resolveModelState } from '../../common/table/helpers'
import { Country } from '../countries/model'
import { Company } from './model'
import * as actionCreators from './actionCreators'
import * as actionTypes from './actionTypes'

// Fetch

const fetchCompaniesEpic = (action$, store) => {
    const tableIdentifier = 'companies_list'
    const serverSide = true

    const triggeringAction = serverSide
        ? action$.ofType(actionTypes.FETCH, tableModelActionTypes.CONFIGURATION_CHANGED)
        : action$.ofType(actionTypes.FETCH)

    return triggeringAction
        .filter(action => action.type !== tableModelActionTypes.CONFIGURATION_CHANGED || action.payload.tableIdentifier === tableIdentifier)
        .switchMap(action => {
            const modelState = resolveModelState(tableIdentifier, store.getState(), action)

            const requestParams = {
                method: 'GET',
                path: `companies/list`,
            }

            if (serverSide) {
                requestParams.method = 'POST'
                requestParams.path += `?page=${modelState.getIn(['pagination', 'current']) + 1}`
                requestParams.body = {
                    sorting: modelState.get('sorting').toJS(),
                    filters: modelState.get('filters').toJS(),
                }
            }

            return Observable.concat(
                Observable.of({
                    type: actionTypes.FETCH_STARTED,
                }),
                request(requestParams)
                    .switchMap(ajaxResponse => {
                        const normalizedData = normalize(
                            serverSide ? ajaxResponse.response.data : ajaxResponse.response,
                            new schema.Array(companySchema)
                        )

                        const companies = normalizedEntitiesToRecordMap(normalizedData.entities.companies, Company, normalizedData.result)
                        const countries = normalizedEntitiesToRecordMap(normalizedData.entities.countries, Country)

                        const observables = [
                            Observable.of(actionCreators.fetchCompaniesFulfilled(companies)),
                            Observable.of(countriesActionCreators.fetchCountriesFulfilled(countries)),
                        ]

                        serverSide &&
                            observables.push(
                                Observable.of(
                                    tableModelActionCreators.updatePagination(
                                        tableIdentifier,
                                        ajaxResponse.response.last_page,
                                        ajaxResponse.response.current_page - 1,
                                        ajaxResponse.response.total
                                    )
                                )
                            )

                        return Observable.concat(...observables)
                    })
                    .catch(error => Observable.of(actionCreators.fetchCompaniesRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.FETCH_CANCELLED, actionTypes.FETCH, tableModelActionTypes.CONFIGURATION_CHANGED))
            )
        })
}

// Fetch one

const fetchCompanyEpic = (action$, store) =>
    action$
        .ofType(actionTypes.FETCH_ONE)
        .filter(() => !store.getState().companies.getIn(['current', 'fetching']))
        .switchMap(action =>
            Observable.concat(
                Observable.of({
                    type: actionTypes.FETCH_ONE_STARTED,
                }),
                request({
                    path: `companies/${action.payload}`,
                    method: 'GET',
                })
                    .switchMap(ajaxResponse => {
                        const normalizedData = normalize(ajaxResponse.response, companySchema)

                        const companies = normalizedEntitiesToRecordMap(normalizedData.entities.companies, Company)
                        const countries = normalizedEntitiesToRecordMap(normalizedData.entities.countries, Country)

                        return Observable.concat(
                            Observable.of(actionCreators.fetchCompaniesFulfilled(companies)),
                            Observable.of(actionCreators.fetchCompanyFulfilled()),
                            Observable.of(countriesActionCreators.fetchCountriesFulfilled(countries))
                        )
                    })
                    .catch(error => Observable.of(actionCreators.fetchCompaniesRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.FETCH_ONE_CANCELLED))
            )
        )

// Save

const saveCompanyEpic = (action$, store) =>
    action$
        .ofType(actionTypes.SAVE)
        .filter(() => !store.getState().companies.getIn(['current', 'saving']))
        .switchMap(action => {
            const values = { ...action.payload }

            let path = `companies`
            let method = 'POST'

            if (values.id) {
                path = `companies/${values.id}`
                method = 'PUT'

                delete values.id
            }

            return Observable.concat(
                Observable.of({
                    type: actionTypes.SAVE_STARTED,
                }),
                request({
                    path,
                    method,
                    body: values,
                })
                    .switchMap(ajaxResponse => {
                        const normalizedData = normalize(ajaxResponse.response, companySchema)

                        const companies = normalizedEntitiesToRecordMap(normalizedData.entities.companies, Company)
                        const company = companies.valueSeq().first()

                        return Observable.concat(Observable.of(actionCreators.saveCompanyFulfilled(company)))
                    })
                    .catch(error => Observable.of(actionCreators.saveCompanyRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.SAVE_CANCELLED))
            )
        })

// Delete

const deleteCompanyEpic = (action$, store) =>
    action$
        .ofType(actionTypes.DELETE)
        .filter(() => !store.getState().companies.getIn(['deletion', 'inProgress']))
        .switchMap(action =>
            Observable.concat(
                Observable.of({
                    type: actionTypes.DELETE_STARTED,
                }),
                request({
                    path: `companies/${action.payload}`,
                    method: 'DELETE',
                })
                    .switchMap(() => Observable.of(actionCreators.deleteCompanyFulfilled(action.payload)))
                    .catch(error => Observable.of(actionCreators.deleteCompanyRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.DELETE_CANCELLED))
            )
        )

// Export

const exportCompaniesEpic = (action$, store) =>
    action$.ofType(actionTypes.EXPORT).switchMap(action => {
        const filters = JSON.stringify(action.payload.filters)
        const sorting = JSON.stringify(action.payload.sorting)
        const token = store.getState().auth.get('accessToken')

        new DownloadRequest({
            url: `companies/export?filters=${filters}&sorting=${sorting}&token=${token}`,
        }).run()

        return Observable.concat(
            Observable.of({
                type: actionTypes.EXPORT_FULFILLED,
            })
        )
    })

// Cost centers

const fetchCompanyCostCentersEpic = (action$, store) =>
    action$
        .ofType(actionTypes.FETCH_COST_CENTERS)
        .filter(() => !store.getState().companies.getIn(['costCenters', 'fetching']))
        .switchMap(action =>
            Observable.concat(
                Observable.of({
                    type: actionTypes.FETCH_COST_CENTERS_STARTED,
                }),
                request({
                    path: `companies/${action.payload.id}/cost-centers${action.payload.page ? `?page=${action.payload.page}` : ''}`,
                    method: 'GET',
                })
                    .switchMap(ajaxResponse => Observable.of(actionCreators.fetchCompanyCostCentersFulfilled(ajaxResponse.response)))
                    .catch(error => Observable.of(actionCreators.fetchCompanyCostCentersRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.FETCH_COST_CENTERS_CANCELLED))
            )
        )

const exportCompanyCostCentersEpic = (action$, store) =>
    action$.ofType(actionTypes.EXPORT_COST_CENTERS).switchMap(action => {
        const token = store.getState().auth.get('accessToken')

        new DownloadRequest({
            url: `companies/${action.payload}/cost-centers/export?token=${token}`,
        }).run()

        return Observable.concat(
            Observable.of({
                type: actionTypes.EXPORT_COST_CENTERS_FULFILLED,
            })
        )
    })

// Bank accounts

const fetchCompanyBankAccountsEpic = (action$, store) =>
    action$
        .ofType(actionTypes.FETCH_BANK_ACCOUNTS)
        .filter(() => !store.getState().companies.getIn(['bankAccounts', 'fetching']))
        .switchMap(action =>
            Observable.concat(
                Observable.of({
                    type: actionTypes.FETCH_BANK_ACCOUNTS_STARTED,
                }),
                request({
                    path: `companies/${action.payload.id}/bank-accounts${action.payload.page ? `?page=${action.payload.page}` : ''}`,
                    method: 'GET',
                })
                    .switchMap(ajaxResponse => Observable.of(actionCreators.fetchCompanyBankAccountsFulfilled(ajaxResponse.response)))
                    .catch(error => Observable.of(actionCreators.fetchCompanyBankAccountsRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.FETCH_BANK_ACCOUNTS_CANCELLED))
            )
        )

const exportCompanyBankAccountsEpic = (action$, store) =>
    action$.ofType(actionTypes.EXPORT_BANK_ACCOUNTS).switchMap(action => {
        const token = store.getState().auth.get('accessToken')

        new DownloadRequest({
            url: `companies/${action.payload}/bank-accounts/export?token=${token}`,
        }).run()

        return Observable.concat(
            Observable.of({
                type: actionTypes.EXPORT_BANK_ACCOUNTS_FULFILLED,
            })
        )
    })

const saveDefaultBankAccount = (action$, store) =>
    action$
        .ofType(actionTypes.SAVE_DEFAULT_BANK_ACCOUNT)
        .filter(() => !store.getState().companies.getIn(['defaultBankAccounts', 'saving']))
        .switchMap(action => {
            return Observable.concat(
                Observable.of({
                    type: actionTypes.SAVE_DEFAULT_BANK_ACCOUNT_STARTED,
                }),
                request({
                    path: `companies/${action.payload.company_id}/default-bank-accounts`,
                    method: 'POST',
                    body: action.payload.values,
                })
                    .switchMap(ajaxResponse => Observable.of(actionCreators.saveDefaultBankAccountFulfilled(ajaxResponse.response)))
                    .catch(error => Observable.of(actionCreators.saveDefaultBankAccountRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.SAVE_DEFAULT_BANK_ACCOUNT_CANCELLED))
            )
        })

// Numberings

const fetchCompanyNumberingsEpic = (action$, store) =>
    action$
        .ofType(actionTypes.FETCH_NUMBERINGS)
        .filter(() => !store.getState().companies.getIn(['numberings', 'fetching']))
        .switchMap(action =>
            Observable.concat(
                Observable.of({
                    type: actionTypes.FETCH_NUMBERINGS_STARTED,
                }),
                request({
                    path: `companies/${action.payload.id}/numberings${action.payload.page ? `?page=${action.payload.page}` : ''}`,
                    method: 'GET',
                })
                    .switchMap(ajaxResponse => Observable.of(actionCreators.fetchCompanyNumberingsFulfilled(ajaxResponse.response)))
                    .catch(error => Observable.of(actionCreators.fetchCompanyNumberingsRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.FETCH_NUMBERINGS_CANCELLED))
            )
        )

const exportCompanyNumberingsEpic = (action$, store) =>
    action$.ofType(actionTypes.EXPORT_NUMBERINGS).switchMap(action => {
        const token = store.getState().auth.get('accessToken')

        new DownloadRequest({
            url: `companies/${action.payload}/numberings/export?token=${token}`,
        }).run()

        return Observable.concat(
            Observable.of({
                type: actionTypes.EXPORT_NUMBERINGS_FULFILLED,
            })
        )
    })

export default [
    fetchCompaniesEpic,
    fetchCompanyEpic,
    saveCompanyEpic,
    deleteCompanyEpic,
    exportCompaniesEpic,
    fetchCompanyCostCentersEpic,
    exportCompanyCostCentersEpic,
    fetchCompanyBankAccountsEpic,
    exportCompanyBankAccountsEpic,
    saveDefaultBankAccount,
    fetchCompanyNumberingsEpic,
    exportCompanyNumberingsEpic,
]
