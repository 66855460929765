import * as actionTypes from './actionTypes'

// Fetch

export const fetchReportAllowances = year => ({
    type: actionTypes.FETCH,
    payload: year,
})

export const fetchReportAllowancesFulfilled = data => ({
    type: actionTypes.FETCH_FULFILLED,
    payload: data,
})

export const fetchReportAllowancesRejected = message => ({
    type: actionTypes.FETCH_REJECTED,
    payload: message,
})

export const fetchReportAllowancesCancelled = () => ({
    type: actionTypes.FETCH_CANCELLED,
})

// Clear

export const clearReportAllowances = () => ({
    type: actionTypes.CLEAR,
})

// Export

export const exportReportAllowances = filters => ({
    type: actionTypes.EXPORT,
    payload: filters,
})

// Fetch details

export const fetchReportAllowancesDetails = (year, driverId, month, filters) => ({
    type: actionTypes.FETCH_DETAILS,
    payload: {
        year,
        driverId,
        month,
        filters,
    },
})

export const fetchReportAllowancesDetailsFulfilled = data => ({
    type: actionTypes.FETCH_DETAILS_FULFILLED,
    payload: data,
})

export const fetchReportAllowancesDetailsRejected = message => ({
    type: actionTypes.FETCH_DETAILS_REJECTED,
    payload: message,
})

export const fetchReportAllowancesDetailsCancelled = () => ({
    type: actionTypes.FETCH_DETAILS_CANCELLED,
})

// Clear

export const clearReportAllowancesDetails = () => ({
    type: actionTypes.CLEAR_DETAILS,
})

// Export

export const exportReportAllowancesDetails = (year, driverId, month, filters) => ({
    type: actionTypes.EXPORT_DETAILS,
    payload: {
        year,
        driverId,
        month,
        filters,
    },
})

// Save allowance

export const saveAllowance = (values, year, driverId, month, filters) => ({
    type: actionTypes.SAVE,
    payload: {
        values,
        year,
        driverId,
        month,
        filters,
    },
})

export const saveAllowanceRejected = message => ({
    type: actionTypes.SAVE_REJECTED,
    payload: message,
})
export const saveAllowanceCancelled = () => ({
    type: actionTypes.SAVE_CANCELLED,
})

export const saveAllowanceFulfilled = data => ({
    type: actionTypes.SAVE_FULFILLED,
    payload: data,
})

// Delete allowance

export const deleteAllowance = (allowanceId, year, driverId, month, filters) => ({
    type: actionTypes.DELETE_ALLOWANCE,
    payload: {
        allowanceId,
        year,
        driverId,
        month,
        filters,
    },
})

export const deleteAllowanceFulfilled = data => ({
    type: actionTypes.DELETE_ALLOWANCE_FULFILLED,
    payload: data,
})

export const deleteAllowanceRejected = message => ({
    type: actionTypes.DELETE_ALLOWANCE_REJECTED,
    payload: message,
})

export const deleteAllowanceCancelled = () => ({
    type: actionTypes.DELETE_ALLOWANCE_CANCELLED,
})

// Reassign allowance

export const reassignAllowances = (year, transportIds, driverId, month, filters) => ({
    type: actionTypes.REASSIGN_ALLOWANCE,
    payload: {
        year,
        transportIds,
        driverId,
        month,
        filters,
    },
})

export const reassignAllowancesFulfilled = data => ({
    type: actionTypes.REASSIGN_ALLOWANCE_FULFILLED,
    payload: data,
})

export const reassignAllowancesRejected = message => ({
    type: actionTypes.REASSIGN_ALLOWANCE_REJECTED,
    payload: message,
})

export const reassignAllowancesCancelled = () => ({
    type: actionTypes.REASSIGN_ALLOWANCE_CANCELLED,
})
