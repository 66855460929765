import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import moment from 'moment'
import classnames from 'classnames'
import { Modal } from 'react-bootstrap'

import { PeriodPicker } from '../../../common/period_picker'
import * as periodPickerConstants from '../../../common/period_picker/constants'
import * as formatters from '../../../common/formatters'
import * as helpers from '../../../common/helpers'
import { LoadingOverlay } from '../../../common/loading_overlay'

import * as reportDriverPerformanceAnalysisActionCreators from '../actionCreators'
import { getData, getFetching, getDetailsData, getDetailsFetching } from '../selectors'

import { prepareTableActionCreators } from '../../../common/table/actionCreators'
import { TableFilterInputField } from '../../../common/table/components'
import { getActiveFilters } from '../../../common/table/helpers'
import { TableModelColumnFilteringType } from '../../../common/table/model'
import { createTableModelSelector } from '../../../common/table/selectors'
import PermissionsCheck from '../../auth/components/PermissionsCheck'
import { PERMS } from '../permissions'

const tableIdentifier = 'report_driver_performance_analysis'

class ReportDriverPerformanceAnalysis extends Component {
    state = {
        modalDetails: {
            show: false,
            driverId: null,
            dateFrom: null,
            dateTo: null,
        },
    }

    refresh = () => {
        this.props.actions.fetchReportDriverPerformanceAnalysis(this.props.tableModel.get('filters'))
    }

    resetFilters = () => {
        const dateFrom = moment().startOf('month')
        const dateTo = moment().endOf('month')

        this.props.table.resetFilters(
            [],
            [
                {
                    column: 'date_from',
                    type: TableModelColumnFilteringType.equal,
                    value: dateFrom.format('DD.MM.YYYY'),
                },
                {
                    column: 'date_to',
                    type: TableModelColumnFilteringType.equal,
                    value: dateTo.format('DD.MM.YYYY'),
                },
            ]
        )
    }

    isFilterActive = () => getActiveFilters(this.props.tableModel).size > 0

    handleExport = e => {
        e && e.preventDefault()

        this.props.actions.exportReportDriverPerformanceAnalysis(this.props.tableModel.get('filters').toJS())
    }

    handleFilterChanged = (key, value) => {
        const filters = this.props.tableModel.get('filters')
        let stringFrom = filters.getIn(['date_from', 'value'])
        let stringTo = filters.getIn(['date_to', 'value'])

        if (key === 'period') {
            stringFrom = value.momentFrom.format('DD.MM.YYYY')
            stringTo = value.momentTo.format('DD.MM.YYYY')
        }

        this.props.table.changeFilters([
            {
                column: 'date_from',
                type: TableModelColumnFilteringType.equal,
                value: stringFrom,
            },
            {
                column: 'date_to',
                type: TableModelColumnFilteringType.equal,
                value: stringTo,
            },
        ])
    }

    // Detail
    handleShowDetails = (e, driverId, dateFrom, dateTo) => {
        e && e.preventDefault()

        this.setState(
            {
                modalDetails: {
                    show: true,
                    driverId,
                    dateFrom,
                    dateTo,
                },
            },
            () => {
                const stringFrom = dateFrom.format('YYYY-MM-DD')
                const strinTo = dateTo.format('YYYY-MM-DD')

                this.props.actions.fetchReportDriverPerformanceAnalysisDetails(driverId, stringFrom, strinTo)
            }
        )
    }

    handleHideDetails = e => {
        e && e.preventDefault()

        this.setState(
            {
                modalDetails: {
                    show: false,
                    driverId: null,
                    dateFrom: null,
                    dateTo: null,
                },
            },
            () => {
                this.props.actions.clearReportDriverPerformanceAnalysisDetails()
            }
        )
    }

    handleExportDetails = e => {
        e && e.preventDefault()

        const stringFrom = this.state.modalDetails.dateFrom.format('YYYY-MM-DD')
        const strinTo = this.state.modalDetails.dateTo.format('YYYY-MM-DD')

        this.props.actions.exportReportDriverPerformanceAnalysisDetails(
            this.state.modalDetails.driverId,
            stringFrom,
            strinTo,
            this.props.tableModel.get('filters').toJS()
        )
    }

    componentDidMount() {
        const dateFrom = moment().startOf('month')
        const dateTo = moment().endOf('month')

        this.props.table.setConfiguration(
            [
                {
                    column: 'date_from',
                    type: TableModelColumnFilteringType.equal,
                    value: dateFrom.format('DD.MM.YYYY'),
                },
                {
                    column: 'date_to',
                    type: TableModelColumnFilteringType.equal,
                    value: dateTo.format('DD.MM.YYYY'),
                },
            ],
            false
        )

        this.props.actions.fetchReportDriverPerformanceAnalysis(this.props.tableModel.get('filters'))
    }

    componentWillUnmount() {
        this.props.actions.clearReportDriverPerformanceAnalysisDetails()
        this.props.actions.clearReportDriverPerformanceAnalysis()
    }

    render() {
        document.title = formatters.titleFormatter(this.props.intl.formatMessage({ id: 'modules.reportDriverPerformanceAnalysis.heading' }))

        const data = this.props.data
        const filters = this.props.tableModel.get('filters')

        const detailsData = this.props.detailsData

        const stringFrom = filters.getIn(['date_from', 'value'])
        const stringTo = filters.getIn(['date_to', 'value'])

        const momentToday = moment()
        const momentFrom = stringFrom ? helpers.convertCSDateToMoment(stringFrom) : moment().startOf('month')
        const momentTo = stringTo ? helpers.convertCSDateToMoment(stringTo) : moment().endOf('month')

        const momentDays = []
        const totalDays = {}
        for (let i = 0; i <= momentTo.diff(momentFrom, 'days'); i++) {
            const momentDay = moment(momentFrom).add(i, 'days')
            momentDays.push(momentDay)
            totalDays[momentDay.format('YYYY-MM-DD')] = {
                driving_style: 0,
                total_distance: 0,
            }
        }

        const total = {
            driving_style: 0,
            total_distance: 0,
        }

        const detailsTotal = {
            driving_style: 0,
            degree_of_difficulty: 0,
            total_distance: 0,
            speed_over_85_distance_perc: 0,
            overall_consumption: 0,
            drive_consumption: 0,
            service_brake_distance: 0,
            hand_brake_usage_count: 0,
        }

        /* eslint-disable jsx-a11y/click-events-have-key-events */
        /* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
        return (
            <PermissionsCheck hasAny={Object.values(PERMS)} noPermissionsPage>
                <div className="page-inner">
                    <div id="main-wrapper">
                        <div className="panel panel-white">
                            <div className="panel-body">
                                <div className="wp-100 pull-left m-b-xs">
                                    <h4 className="pull-left">
                                        <span className="pull-left">
                                            <FormattedMessage id="modules.reportDriverPerformanceAnalysis.heading" />
                                        </span>
                                    </h4>
                                    <div className="btns-list">
                                        <PeriodPicker
                                            className="m-r-lg"
                                            momentFrom={momentFrom}
                                            momentTo={momentTo}
                                            defaultPicker={periodPickerConstants.PERIOD_PICKER_TYPE_MONTH}
                                            onChange={(momentFrom, momentTo) => this.handleFilterChanged('period', { momentFrom, momentTo })}
                                        />
                                        {this.isFilterActive() > 0 && (
                                            <button className="btn btn-default btn-addon m-r-xs" onClick={this.resetFilters}>
                                                <i className="far fa-times" /> <FormattedMessage id="buttons.resetFilters" />
                                            </button>
                                        )}
                                        <button className="btn btn-default btn-addon m-r-xs" onClick={this.refresh}>
                                            <i className="far fa-sync-alt" /> <FormattedMessage id="buttons.refresh" />
                                        </button>
                                        <PermissionsCheck has={[PERMS.EXPORT]}>
                                            <button className="btn btn-primary btn-addon" onClick={this.handleExport}>
                                                <i className="far fa-file-excel" /> <FormattedMessage id="buttons.exportToXLS" />
                                            </button>
                                        </PermissionsCheck>
                                    </div>
                                </div>

                                <div className="table-container">
                                    <table className="table table-striped table-hover table-fixed-header">
                                        <thead>
                                            <tr>
                                                <th className="w-max wm-200">
                                                    <FormattedMessage id="fields.driver" />
                                                </th>
                                                {momentDays.map(momentDay => (
                                                    <th key={`day-th-${momentDay.format('YYYY-MM-DD')}`} className="w-50 text-center">
                                                        {momentDay.format('DD/MM')}
                                                    </th>
                                                ))}
                                                <th className="wm-100 text-right">
                                                    <FormattedMessage id="fields.mean" />
                                                </th>
                                            </tr>
                                            <tr className="filters">
                                                <th className="w-max wm-200">
                                                    <TableFilterInputField
                                                        identifier="driver_name"
                                                        type={TableModelColumnFilteringType.string}
                                                        filters={filters}
                                                        onChange={this.props.table.changeFilter}
                                                    />
                                                </th>
                                                {momentDays.map(momentDay => (
                                                    <th key={`day-th-filter-${momentDay.format('YYYY-MM-DD')}`} className="w-50" />
                                                ))}
                                                <th className="w-100" />
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data &&
                                                data.drivers &&
                                                data.drivers.map(driver => {
                                                    if (
                                                        filters.getIn(['driver_name', 'value']) &&
                                                        driver.name.toLowerCase().indexOf(filters.getIn(['driver_name', 'value']).toLowerCase()) < 0
                                                    ) {
                                                        return null
                                                    }

                                                    const totalRow = {
                                                        driving_style: 0,
                                                        total_distance: 0,
                                                    }

                                                    return (
                                                        <tr key={`vehicle-${driver.id}`}>
                                                            <td className="w-max wm-200">{driver.name}</td>
                                                            {momentDays.map(momentDay => {
                                                                const date = momentDay.format('YYYY-MM-DD')

                                                                let driving_style = null

                                                                if (driver.days[date]) {
                                                                    driving_style = driver.days[date].driving_style
                                                                    const total_distance = driver.days[date].total_distance

                                                                    totalDays[date].driving_style += driving_style * total_distance
                                                                    totalDays[date].total_distance += total_distance

                                                                    totalRow.driving_style += driving_style * total_distance
                                                                    totalRow.total_distance += total_distance
                                                                }

                                                                const classes = classnames('w-50 text-center', {
                                                                    'is-weekend': momentDay.isoWeekday() >= 6,
                                                                    'is-today': momentDay.isSame(momentToday, 'day'),
                                                                })

                                                                const haveData = driving_style !== null
                                                                const value = haveData ? formatters.floatFormatter(driving_style, 2) : '-'
                                                                const role = haveData ? 'button' : undefined
                                                                const onClick = haveData
                                                                    ? e => {
                                                                        this.handleShowDetails(e, driver.id, momentDay, momentDay)
                                                                    }
                                                                    : undefined

                                                                return (
                                                                    <td key={`day-td-${date}`} className={classes} role={role} onClick={onClick}>
                                                                        {value}
                                                                    </td>
                                                                )
                                                            })}

                                                            <td
                                                                className="w-100 text-right"
                                                                role={totalRow.total_distance !== 0 ? 'button' : undefined}
                                                                onClick={
                                                                    totalRow.total_distance !== 0
                                                                        ? e => {
                                                                            this.handleShowDetails(e, driver.id, momentFrom, momentTo)
                                                                        }
                                                                        : undefined
                                                                }
                                                            >
                                                                <strong>
                                                                    {totalRow.total_distance === 0
                                                                        ? '-'
                                                                        : formatters.floatFormatter(totalRow.driving_style / totalRow.total_distance)}
                                                                </strong>
                                                            </td>
                                                        </tr>
                                                    )
                                                })}

                                            <tr className="b-top row-sum">
                                                <td className="w-max wm-200">
                                                    <strong>
                                                        <FormattedMessage id="fields.mean" />:
                                                    </strong>
                                                </td>
                                                {momentDays.map(momentDay => {
                                                    const date = momentDay.format('YYYY-MM-DD')

                                                    total.driving_style += totalDays[date].driving_style
                                                    total.total_distance += totalDays[date].total_distance

                                                    const haveData = totalDays[date].total_distance !== 0
                                                    const value = haveData
                                                        ? formatters.floatFormatter(totalDays[date].driving_style / totalDays[date].total_distance)
                                                        : '-'
                                                    const role = haveData ? 'button' : undefined
                                                    const onClick = haveData
                                                        ? e => {
                                                            this.handleShowDetails(e, null, momentDay, momentDay)
                                                        }
                                                        : undefined

                                                    return (
                                                        <td key={`day-td-total-${date}`} className="w-50 text-center" role={role} onClick={onClick}>
                                                            <strong>{value}</strong>
                                                        </td>
                                                    )
                                                })}

                                                <td
                                                    className="w-100 text-right"
                                                    role={total.total_distance !== 0 ? 'button' : undefined}
                                                    onClick={
                                                        total.total_distance !== 0
                                                            ? e => {
                                                                this.handleShowDetails(e, null, momentFrom, momentTo)
                                                            }
                                                            : undefined
                                                    }
                                                >
                                                    <strong>
                                                        {total.total_distance === 0
                                                            ? '-'
                                                            : formatters.floatFormatter(total.driving_style / total.total_distance)}
                                                    </strong>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Modal show={Boolean(this.state.modalDetails.show)} onHide={this.handleHideDetails} className="modal-size-xl">
                        <Modal.Header closeButton>
                            <Modal.Title>{detailsData ? detailsData.title : null}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <LoadingOverlay active={this.props.detailsFetching}>
                                <div className="btns-list">
                                    {detailsData && detailsData.driver_performances.length > 0 && (
                                        <PermissionsCheck has={[PERMS.EXPORT]}>
                                            <button className="btn btn-primary btn-addon" onClick={this.handleExportDetails}>
                                                <i className="far fa-file-excel" /> <FormattedMessage id="buttons.exportToXLS" />
                                            </button>
                                        </PermissionsCheck>
                                    )}
                                </div>

                                <div className="table-container">
                                    <table className="table table-striped table-hover table-fixed-header">
                                        <thead>
                                            <tr>
                                                <th className="w-160 text-left">
                                                    <FormattedMessage id="modules.driverPerformanceAnalysis.period" />
                                                </th>
                                                <th className="w-max wm-200">
                                                    <FormattedMessage id="modules.driverPerformanceAnalysis.driver" />
                                                </th>
                                                <th className="w-100 text-right">
                                                    <FormattedMessage id="modules.driverPerformanceAnalysis.drivingStyle" />
                                                </th>
                                                <th className="w-100 text-right">
                                                    <FormattedMessage id="modules.driverPerformanceAnalysis.degreeOfDifficulty" />
                                                </th>
                                                <th className="w-120 text-right">
                                                    <FormattedMessage id="modules.driverPerformanceAnalysis.totalDistance" />
                                                </th>
                                                <th className="w-100 text-right">
                                                    <FormattedMessage id="modules.driverPerformanceAnalysis.speedOver85DistancePerc" />
                                                </th>
                                                <th className="w-140 text-right">
                                                    <FormattedMessage id="modules.driverPerformanceAnalysis.overallConsumption" />
                                                </th>
                                                <th className="w-140 text-right">
                                                    <FormattedMessage id="modules.driverPerformanceAnalysis.driveConsumption" />
                                                </th>
                                                <th className="w-120 text-right">
                                                    <FormattedMessage id="modules.driverPerformanceAnalysis.serviceBrakeDistance" />
                                                </th>
                                                <th className="w-120 text-right">
                                                    <FormattedMessage id="modules.driverPerformanceAnalysis.handBrakeUsageCount" />
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {detailsData &&
                                                detailsData.driver_performances.map(driver_performance => {
                                                    detailsTotal.driving_style += driver_performance.driving_style * driver_performance.total_distance
                                                    detailsTotal.degree_of_difficulty +=
                                                        driver_performance.degree_of_difficulty * driver_performance.total_distance
                                                    detailsTotal.total_distance += driver_performance.total_distance
                                                    detailsTotal.speed_over_85_distance_perc +=
                                                        driver_performance.speed_over_85_distance_perc * driver_performance.total_distance
                                                    detailsTotal.overall_consumption +=
                                                        driver_performance.overall_consumption * driver_performance.total_distance
                                                    detailsTotal.drive_consumption +=
                                                        driver_performance.drive_consumption * driver_performance.total_distance
                                                    detailsTotal.service_brake_distance += driver_performance.service_brake_distance
                                                    detailsTotal.hand_brake_usage_count += driver_performance.hand_brake_usage_count

                                                    const periodBegin = moment(driver_performance.period_begin)
                                                    const periodEnd = moment(driver_performance.period_end)

                                                    return (
                                                        <tr>
                                                            <td className="w-160 text-left">
                                                                {formatters.dateFormatter(periodBegin)} - {formatters.dateFormatter(periodEnd)}
                                                            </td>
                                                            <td className="w-max wm-200 text-left">{driver_performance.driver.full_name}</td>
                                                            <td className="w-100 text-right">
                                                                {formatters.floatFormatter(driver_performance.driving_style, 2)}
                                                            </td>
                                                            <td className="w-100 text-right">
                                                                {formatters.floatFormatter(driver_performance.degree_of_difficulty, 2)}
                                                            </td>
                                                            <td className="w-120 text-right">
                                                                {formatters.distanceFormatter(driver_performance.total_distance)}
                                                            </td>
                                                            <td className="w-100 text-right">
                                                                {formatters.percentFormatter(driver_performance.speed_over_85_distance_perc)}
                                                            </td>
                                                            <td className="w-140 text-right">
                                                                {formatters.volumeFormatter(driver_performance.overall_consumption, '0,0.00')}
                                                            </td>
                                                            <td className="w-140 text-right">
                                                                {formatters.volumeFormatter(driver_performance.drive_consumption, '0,0.00')}
                                                            </td>
                                                            <td className="w-120 text-right">
                                                                {formatters.distanceFormatter(driver_performance.service_brake_distance)}
                                                            </td>
                                                            <td className="w-120 text-right">{driver_performance.hand_brake_usage_count}</td>
                                                        </tr>
                                                    )
                                                })}

                                            {detailsData && (
                                                <tr className="b-top row-sum text-right">
                                                    <td className="w-160 text-left" />
                                                    <td className="w-max wm-200 text-left" />
                                                    <td className="w-100 text-right">
                                                        <strong>
                                                            {formatters.floatFormatter(detailsTotal.driving_style / detailsTotal.total_distance, 2)}
                                                        </strong>
                                                    </td>
                                                    <td className="w-100 text-right">
                                                        <strong>
                                                            {formatters.floatFormatter(
                                                                detailsTotal.degree_of_difficulty / detailsTotal.total_distance,
                                                                2
                                                            )}
                                                        </strong>
                                                    </td>
                                                    <td className="w-120 text-right">
                                                        <strong>{formatters.distanceFormatter(detailsTotal.total_distance)}</strong>
                                                    </td>
                                                    <td className="w-100 text-right">
                                                        <strong>
                                                            {formatters.percentFormatter(
                                                                detailsTotal.speed_over_85_distance_perc / detailsTotal.total_distance
                                                            )}
                                                        </strong>
                                                    </td>
                                                    <td className="w-140 text-right">
                                                        <strong>
                                                            {formatters.volumeFormatter(
                                                                detailsTotal.overall_consumption / detailsTotal.total_distance,
                                                                '0,0.00'
                                                            )}
                                                        </strong>
                                                    </td>
                                                    <td className="w-140 text-right">
                                                        <strong>
                                                            {formatters.volumeFormatter(
                                                                detailsTotal.drive_consumption / detailsTotal.total_distance,
                                                                '0,0.00'
                                                            )}
                                                        </strong>
                                                    </td>
                                                    <td className="w-120 text-right">
                                                        <strong>{formatters.distanceFormatter(detailsTotal.service_brake_distance)}</strong>
                                                    </td>
                                                    <td className="w-120 text-right">
                                                        <strong>{detailsTotal.hand_brake_usage_count}</strong>
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </LoadingOverlay>
                        </Modal.Body>
                        <Modal.Footer>
                            <button className="btn btn-default" onClick={this.handleHideDetails}>
                                <FormattedMessage id="confirmDialog.duplicityFound.cancel" />
                            </button>
                        </Modal.Footer>
                    </Modal>
                </div>
            </PermissionsCheck>
        )
        /* eslint-enable jsx-a11y/no-noninteractive-element-interactions */
        /* eslint-enable jsx-a11y/click-events-have-key-events */
    }
}

function mapStateToProps(state) {
    return {
        tableModel: createTableModelSelector(tableIdentifier)(state),
        data: getData(state),
        fetching: getFetching(state),
        detailsData: getDetailsData(state),
        detailsFetching: getDetailsFetching(state),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            ...bindActionCreators(
                {
                    ...reportDriverPerformanceAnalysisActionCreators,
                },
                dispatch
            ),
        },
        dispatch,
    }
}

function mergeProps(stateProps, dispatchProps, ownProps) {
    return {
        ...stateProps,
        ...dispatchProps,
        ...ownProps,
        table: bindActionCreators(prepareTableActionCreators(tableIdentifier, stateProps.tableModel), dispatchProps.dispatch),
    }
}

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps,
        mergeProps
    )(ReportDriverPerformanceAnalysis)
)
