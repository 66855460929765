import * as actionTypes from './actionTypes'

// Fetch

export const fetchDrivers = () => ({
    type: actionTypes.FETCH,
})

export const fetchDriversFulfilled = drivers => ({
    type: actionTypes.FETCH_FULFILLED,
    payload: drivers,
})

export const fetchDriversRejected = message => ({
    type: actionTypes.FETCH_REJECTED,
    payload: message,
})

export const fetchDriversCancelled = () => ({
    type: actionTypes.FETCH_CANCELLED,
})

// Fetch one

export const fetchDriver = id => ({
    type: actionTypes.FETCH_ONE,
    payload: id,
})

export const fetchDriverFulfilled = () => ({
    type: actionTypes.FETCH_ONE_FULFILLED,
})

export const fetchDriverRejected = message => ({
    type: actionTypes.FETCH_ONE_REJECTED,
    payload: message,
})

export const fetchDriverCancelled = () => ({
    type: actionTypes.FETCH_ONE_CANCELLED,
})

// Save

export const saveDriver = (values, ignoreDuplicity) => ({
    type: actionTypes.SAVE,
    payload: {
        values,
        ignoreDuplicity,
    },
})

export const saveDriverFulfilled = driver => ({
    type: actionTypes.SAVE_FULFILLED,
    payload: driver,
})

export const saveDriverRejected = message => ({
    type: actionTypes.SAVE_REJECTED,
    payload: message,
})

export const saveDriverDuplicityFound = duplicity => ({
    type: actionTypes.SAVE_DUPLICITY_FOUND,
    payload: duplicity,
})

// Delete

export const deleteDriver = id => ({
    type: actionTypes.DELETE,
    payload: id,
})

export const deleteDriverFulfilled = id => ({
    type: actionTypes.DELETE_FULFILLED,
    payload: id,
})

export const deleteDriverRejected = message => ({
    type: actionTypes.DELETE_REJECTED,
    payload: message,
})

// Restore

export const restoreDriver = id => ({
    type: actionTypes.RESTORE,
    payload: id,
})

export const restoreDriverFulfilled = item => ({
    type: actionTypes.RESTORE_FULFILLED,
    payload: item,
})

export const restoreDriverRejected = message => ({
    type: actionTypes.RESTORE_REJECTED,
    payload: message,
})

// Export

export const exportDrivers = (filters, sorting) => ({
    type: actionTypes.EXPORT,
    payload: {
        filters,
        sorting,
    },
})

// Fetch driver history

export const fetchDriverHistory = id => ({
    type: actionTypes.FETCH_HISTORY,
    payload: id,
})

export const fetchDriverHistoryFulfilled = data => ({
    type: actionTypes.FETCH_HISTORY_FULFILLED,
    payload: data,
})

export const fetchDriverHistoryRejected = message => ({
    type: actionTypes.FETCH_HISTORY_REJECTED,
    payload: message,
})

export const fetchDriverHistoryCancelled = () => ({
    type: actionTypes.FETCH_HISTORY_CANCELLED,
})

// Export driver history

export const exportDriverHistory = id => ({
    type: actionTypes.EXPORT_HISTORY,
    payload: id,
})

export const exportDriverHistoryFulfilled = data => ({
    type: actionTypes.EXPORT_HISTORY_FULFILLED,
    payload: data,
})

export const exportDriverHistoryRejected = message => ({
    type: actionTypes.EXPORT_HISTORY_REJECTED,
    payload: message,
})

export const exportDriverHistoryCancelled = () => ({
    type: actionTypes.EXPORT_HISTORY_CANCELLED,
})

// Fetch driver documents

export const fetchDriverDocuments = (id, page) => ({
    type: actionTypes.FETCH_DOCUMENTS,
    payload: {
        id,
        page,
    },
})

export const fetchDriverDocumentsFulfilled = data => ({
    type: actionTypes.FETCH_DOCUMENTS_FULFILLED,
    payload: data,
})

export const fetchDriverDocumentsRejected = message => ({
    type: actionTypes.FETCH_DOCUMENTS_REJECTED,
    payload: message,
})

export const fetchDriverDocumentsCancelled = () => ({
    type: actionTypes.FETCH_DOCUMENTS_CANCELLED,
})

// Export driver documents

export const exportDriverDocuments = id => ({
    type: actionTypes.EXPORT_DOCUMENTS,
    payload: id,
})

export const exportDriverDocumentsFulfilled = data => ({
    type: actionTypes.EXPORT_DOCUMENTS_FULFILLED,
    payload: data,
})

export const exportDriverDocumentsRejected = message => ({
    type: actionTypes.EXPORT_DOCUMENTS_REJECTED,
    payload: message,
})

export const exportDriverDocumentsCancelled = () => ({
    type: actionTypes.EXPORT_DOCUMENTS_CANCELLED,
})

// Fetch driver equipments

export const fetchDriverEquipments = (id, page) => ({
    type: actionTypes.FETCH_EQUIPMENTS,
    payload: {
        id,
        page,
    },
})

export const fetchDriverEquipmentsFulfilled = data => ({
    type: actionTypes.FETCH_EQUIPMENTS_FULFILLED,
    payload: data,
})

export const fetchDriverEquipmentsRejected = message => ({
    type: actionTypes.FETCH_EQUIPMENTS_REJECTED,
    payload: message,
})

export const fetchDriverEquipmentsCancelled = () => ({
    type: actionTypes.FETCH_EQUIPMENTS_CANCELLED,
})

// Export driver equipments

export const exportDriverEquipments = id => ({
    type: actionTypes.EXPORT_EQUIPMENTS,
    payload: id,
})

export const exportDriverEquipmentsFulfilled = data => ({
    type: actionTypes.EXPORT_EQUIPMENTS_FULFILLED,
    payload: data,
})

export const exportDriverEquipmentsRejected = message => ({
    type: actionTypes.EXPORT_EQUIPMENTS_REJECTED,
    payload: message,
})

export const exportDriverEquipmentsCancelled = () => ({
    type: actionTypes.EXPORT_EQUIPMENTS_CANCELLED,
})

// Fetch driver events

export const fetchDriverEvents = (id, page) => ({
    type: actionTypes.FETCH_EVENTS,
    payload: {
        id,
        page,
    },
})

export const fetchDriverEventsFulfilled = data => ({
    type: actionTypes.FETCH_EVENTS_FULFILLED,
    payload: data,
})

export const fetchDriverEventsRejected = message => ({
    type: actionTypes.FETCH_EVENTS_REJECTED,
    payload: message,
})

export const fetchDriverEventsCancelled = () => ({
    type: actionTypes.FETCH_EVENTS_CANCELLED,
})

// Export driver events

export const exportDriverEvents = (id, page) => ({
    type: actionTypes.EXPORT_EVENTS,
    payload: {
        id,
        page,
    },
})

export const exportDriverEventsFulfilled = data => ({
    type: actionTypes.EXPORT_EVENTS_FULFILLED,
    payload: data,
})

export const exportDriverEventsRejected = message => ({
    type: actionTypes.EXPORT_EVENTS_REJECTED,
    payload: message,
})

export const exportDriverEventsCancelled = () => ({
    type: actionTypes.EXPORT_EVENTS_CANCELLED,
})

// Fetch driver timesheets

export const fetchDriverTimesheets = (id, page) => ({
    type: actionTypes.FETCH_TIMESHEETS,
    payload: {
        id,
        page,
    },
})

export const fetchDriverTimesheetsFulfilled = data => ({
    type: actionTypes.FETCH_TIMESHEETS_FULFILLED,
    payload: data,
})

export const fetchDriverTimesheetsRejected = message => ({
    type: actionTypes.FETCH_TIMESHEETS_REJECTED,
    payload: message,
})

export const fetchDriverTimesheetsCancelled = () => ({
    type: actionTypes.FETCH_TIMESHEETS_CANCELLED,
})

// Export driver timesheets

export const exportDriverTimesheets = id => ({
    type: actionTypes.EXPORT_TIMESHEETS,
    payload: id,
})

export const exportDriverTimesheetsFulfilled = data => ({
    type: actionTypes.EXPORT_TIMESHEETS_FULFILLED,
    payload: data,
})

export const exportDriverTimesheetsRejected = message => ({
    type: actionTypes.EXPORT_TIMESHEETS_REJECTED,
    payload: message,
})

export const exportDriverTimesheetsCancelled = () => ({
    type: actionTypes.EXPORT_TIMESHEETS_CANCELLED,
})

// Fetch allowances

export const fetchDriverAllowances = id => ({
    type: actionTypes.FETCH_ALLOWANCES,
    payload: {
        id,
    },
})

export const fetchDriverAllowancesFulfilled = data => ({
    type: actionTypes.FETCH_ALLOWANCES_FULFILLED,
    payload: data,
})

export const fetchDriverAllowancesRejected = message => ({
    type: actionTypes.FETCH_ALLOWANCES_REJECTED,
    payload: message,
})

export const fetchDriverAllowancesCancelled = () => ({
    type: actionTypes.FETCH_ALLOWANCES_CANCELLED,
})

// Save allowances

export const saveDriverAllowances = data => ({
    type: actionTypes.SAVE_ALLOWANCES,
    payload: {
        data,
    },
})

export const saveDriverAllowancesFulfilled = data => ({
    type: actionTypes.SAVE_ALLOWANCES_FULFILLED,
    payload: data,
})

export const saveDriverAllowancesRejected = message => ({
    type: actionTypes.SAVE_ALLOWANCES_REJECTED,
    payload: message,
})

export const saveDriverAllowancesCancelled = () => ({
    type: actionTypes.SAVE_ALLOWANCES_CANCELLED,
})

// Delete allowances

export const deleteDriverAllowance = data => ({
    type: actionTypes.DELETE_ALLOWANCES,
    payload: {
        data,
    },
})

export const deleteDriverAllowanceFulfilled = data => ({
    type: actionTypes.DELETE_ALLOWANCES_FULFILLED,
    payload: data,
})

export const deleteDriverAllowanceRejected = message => ({
    type: actionTypes.DELETE_ALLOWANCES_REJECTED,
    payload: message,
})

export const deleteDriverAllowanceCancelled = () => ({
    type: actionTypes.DELETE_ALLOWANCES_CANCELLED,
})
