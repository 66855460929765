import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import Notifications from 'react-notification-system-redux'
import * as formatters from '../../../common/formatters'

import PermissionsCheck from '../../auth/components/PermissionsCheck'

import { PERMS } from '../permissions'
import Dropzone from "react-dropzone"
import * as salesActionCreators from "../actionCreators"
import * as selectors from "../selectors"
import { LoadingOverlay } from "../../../common/loading_overlay"
import { SelectField } from "../../../common/form";
import { convertCSDateToMoment, convertISODateToCS } from "../../../common/helpers";
import DatePicker from "react-datepicker";
import { dateFormatter } from "../../../common/formatters";

import { preparePrerequisitesActionCreators } from "../../../common/prerequisites/actionCreators"
import { preparePrerequisitesFetchStatusSelectors } from "../../../common/prerequisites/selectors";
import * as helpers from "../../../common/helpers";

const componentIdentifier = 'sales_import'
const prerequisites = ['salesTypes']

class SalesImport extends Component {
    stepNames = {
        1: this.props.intl.formatMessage({ id: 'modules.salesImport.stepNames.1' }),
        2: this.props.intl.formatMessage({ id: 'modules.salesImport.stepNames.2' }),
        3: this.props.intl.formatMessage({ id: 'modules.salesImport.stepNames.3' })
    }

    columnTypes = {
        'date': this.props.intl.formatMessage({ id: 'modules.salesImport.columnTypes.date' }),
        'costCenter': this.props.intl.formatMessage({ id: 'modules.salesImport.columnTypes.costCenter' }),
        'vehicle': this.props.intl.formatMessage({ id: 'modules.salesImport.columnTypes.vehicle' }),
        'driver': this.props.intl.formatMessage({ id: 'modules.salesImport.columnTypes.driver' }),
        'trailer': this.props.intl.formatMessage({ id: 'modules.salesImport.columnTypes.trailer' }),
        'price': this.props.intl.formatMessage({ id: 'modules.salesImport.columnTypes.price' }),
        'currency': this.props.intl.formatMessage({ id: 'modules.salesImport.columnTypes.currency' }),
    }

    state = {
        step: 1,
        importHeaders: [],
        importFileId: null,
        importFileName: null,
        importItems: [],
        importItemValues: [],
        columnTypes: {},
        defaultDate: null,
        defaultSalesTypeId: null,
        importAllItemsChecked: false
    }

    importProcessing = false

    onDropFiles = files => {
        files.forEach(file => {
            const reader = new FileReader()
            const fileName = file.name

            reader.onload = e => {
                const loadedFile = {
                    name: fileName,
                    base64: e.target.result,
                }
                this.props.actions.fetchSalesImportHeaders(loadedFile)
            }

            reader.readAsDataURL(file)
        })
    }

    onBackButtonClick = () => {
        this.setState({
            ...this.state,
            step: this.state.step - 1
        })
    }

    onSummaryButtonClick = () => {
        this.props.actions.fetchSalesImportItems(this.state.importFileId, this.state.columnTypes)
    }

    setColumnType = (id, value) => {
        this.setState({
            columnTypes: {
                ...this.state.columnTypes,
                [id]: value
            }
        })
    }

    setDefaultDate = value => {
        this.props.actions.fetchSalesImportItems(this.state.importFileId, this.state.columnTypes, value, this.state.defaultSalesTypeId)
    }

    setDefaultSalesTypeId = value => {
        this.setState({
            ...this.state,
            defaultSalesTypeId: value
        }, () => {
            this.props.actions.fetchSalesImportItems(this.state.importFileId, this.state.columnTypes, this.state.defaultDate, value)
        })
    }

    onImportItemChange = (key, value) => {
        this.setState({
            importItemValues: {
                ...this.state.importItemValues,
                [key]: value
            }
        })
    }

    onImportAllItemsChange = (value) => {
        this.setState({
            importAllItemsChecked: value
        })

        var importItemValues = {};

        for (var key in this.state.importItemValues) {
            importItemValues[key] = value
        }

        this.setState({
            importItemValues: importItemValues
        })
    }

    onImportButtonClick = () => {
        this.importProcessing = true
        this.props.actions.importSales(this.state.importFileId, this.state.columnTypes, this.state.defaultDate, this.state.defaultSalesTypeId, this.state.importItemValues)
    }

    componentWillMount() {

    }

    componentDidMount() {
        this.props.actions.fetchPrerequisites()
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.importInProgress && !nextProps.importInProgress) {
            // Import error
            if (nextProps.importError) {
                this.props.notify({
                    title: this.props.intl.formatMessage({ id: 'alerts.titles.error' }),
                    message: nextProps.importError,
                    position: 'tc',
                }, 'error')

                this.importProcessing = false

                return false;
            }
            else if (this.state.step === 1) {
                const headers = nextProps.importHeaders.valueSeq().toArray()
                const defaultHeaders = nextProps.importDefaultHeaders.toJS()
                const columnTypes = {}

                nextProps.importHeaders.map((headerName, headerId) => {
                    if (defaultHeaders[headerName]) {
                        columnTypes[headerId] = defaultHeaders[headerName]
                    }

                    return false;
                })

                this.setState({
                    ...this.state,
                    step: 2,
                    importHeaders: headers,
                    importFileId: nextProps.importFileId,
                    importFileName: nextProps.importFileName,
                    columnTypes,
                })
            }
            else if (this.state.step >= 2) {
                if (this.importProcessing) {
                    this.props.notify({
                        title: this.props.intl.formatMessage({ id: 'alerts.titles.success' }),
                        message: this.props.intl.formatMessage({ id: 'alerts.messages.importSuccess' }),
                        position: 'tc',
                    }, 'success')

                    this.props.redirect('/sales/')
                }

                const items = nextProps.importItems
                const itemValues = []

                items.map((item, key) => {
                    itemValues[key] = !!item.sales

                    return false;
                })

                this.setState({
                    ...this.state,
                    step: 3,
                    importItems: items,
                    importItemValues: itemValues,
                    defaultDate: convertISODateToCS(nextProps.defaultDate)
                })
            }
        }
    }

    /* eslint-disable jsx-a11y/anchor-is-valid */
    render() {
        document.title = formatters.titleFormatter(this.props.intl.formatMessage({ id: 'modules.salesImport.heading' }))

        const columnTypesOptions = Object.keys(this.columnTypes).map(id => ({
            id: id,
            name: this.columnTypes[id],
        }))

        columnTypesOptions.unshift({
            id: null,
            name: '-'
        })

        return (<PermissionsCheck has={[PERMS.CREATE]} noPermissionsPage>
                <div className="page-inner">
                    <div id="main-wrapper">
                        <div className="row hp-100">
                            <div className={'col-md-12 col-xl-6 col-xl-offset-3'}>
                                <LoadingOverlay active={this.props.importInProgress}>
                                    <div className="panel panel-white">
                                        <div className="panel-body panel-padding">
                                            <h4>
                                                <FormattedMessage id="modules.salesImport.heading" /> - <FormattedMessage id="modules.salesImport.step" /> {this.state.step}/3 ({this.stepNames[this.state.step]})
                                            </h4>

                                            {this.state.step === 1 && (<div className="wp-100 pull-left clearfix">
                                                <p className="m-b-lg">
                                                    Níže prosím nahrajte soubor, ze kterého se mají tržby naimportovat. Podporované formáty jsou XLS, XLSX a CSV.
                                                </p>
                                                <Dropzone onDrop={this.onDropFiles} multiple={false} style={{}} className="dropzone" acceptClassName="dropzone-accept">
                                                    <p className="ready">
                                                        <FormattedMessage id="fields.dropzoneReady" />
                                                    </p>
                                                    <p className="accept">
                                                        <FormattedMessage id="fields.dropzoneAccept" />
                                                    </p>
                                                </Dropzone>
                                            </div>)}

                                            {this.state.step === 2 && (<div className="wp-100 pull-left clearfix">
                                                <p className="m-b-lg">
                                                    Nastavte prosím význam jednotlivých sloupců tak, aby bylo možné náklady správně naimportovat.
                                                </p>

                                                <div className="row">
                                                    <div className="col-sm-6">
                                                        {Object.keys(this.state.importHeaders).map(headerId => {
                                                            let headerName = this.state.importHeaders[headerId]

                                                            return <div key={headerId}>
                                                                <SelectField
                                                                    id={headerId}
                                                                    label={headerName}
                                                                    readonly={() => false}
                                                                    values={columnTypesOptions}
                                                                    value={this.state.columnTypes[headerId]}
                                                                    onChange={e => {
                                                                        this.setColumnType(headerId, e.value)
                                                                    }}
                                                                />
                                                            </div>
                                                        })}
                                                    </div>
                                                </div>
                                            </div>)}

                                            {this.state.step === 3 && (<div className="wp-100 pull-left clearfix">
                                                <p className="m-b-lg">
                                                    Níže je přehled tržeb, které jsme v souboru našli. Vyberte prosím ty, které mají být zpracovány.
                                                </p>

                                                {!this.state.importItems.length && (<div className="alert alert-danger">
                                                    <p>
                                                        Nebyla nalezena žádná položka.
                                                    </p>
                                                </div>)}

                                                {this.state.importItems.length && (<div>
                                                    <div className="row m-b-lg">
                                                        <div className="col-sm-2">
                                                            <div className="form-group">
                                                                <label htmlFor="defaultDate">
                                                                    Výchozí datum:
                                                                </label>
                                                                <DatePicker
                                                                    id="defaultDate"
                                                                    dateFormat="DD.MM.YYYY"
                                                                    shouldCloseOnSelect={true}
                                                                    autoComplete="off"
                                                                    selected={convertCSDateToMoment(this.state.defaultDate)}
                                                                    value={this.state.defaultDate}
                                                                    onChange={value => {
                                                                        this.setDefaultDate(value ? dateFormatter(value) : '')
                                                                    }}
                                                                    onChangeRaw={e => {
                                                                        this.setDefaultDate(e.target.value)
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6">
                                                            <div className="form-group">
                                                                <label htmlFor="defaultSalesTypeId">
                                                                    Typ tržby:
                                                                </label>
                                                                <SelectField
                                                                    id="defaultSalesTypeId"
                                                                    onChange={(e) => {
                                                                        this.setDefaultSalesTypeId(e.value)
                                                                    }}
                                                                    values={helpers.getPrerequisitesArray(this.props.prerequisites.values.get('salesTypes'))}
                                                                    value={this.state.defaultSalesTypeId}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <table className="table table-striped">
                                                        <thead>
                                                        <tr>
                                                            <th className="w-40 text-center">
                                                                <input
                                                                    type="checkbox"
                                                                    id={'item-all'}
                                                                    onChange={(e) => {
                                                                        this.onImportAllItemsChange(e.target.checked)
                                                                    }}
                                                                    checked={this.state.importAllItemsChecked}
                                                                />
                                                            </th>
                                                            <th className="w-120">
                                                                Datum
                                                            </th>
                                                            <th className="w-200">
                                                                Náklad
                                                            </th>
                                                            <th className="w-max">
                                                                Zařazení
                                                            </th>
                                                            <th className="w-140 text-right">
                                                                Hodnota
                                                            </th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {this.state.importItems.map((item, key) => {
                                                            return (<tr key={'importItem-' + key}>
                                                                <td className="text-center">
                                                                    <input
                                                                        type="checkbox"
                                                                        id={'item-' + key}
                                                                        onChange={(e) => {
                                                                            this.onImportItemChange(key, e.target.checked)
                                                                        }}
                                                                        checked={this.state.importItemValues[key]}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    {item.date}
                                                                </td>
                                                                <td>
                                                                    {item.sales}
                                                                </td>
                                                                <td>
                                                                    {item.relatedTo}
                                                                </td>
                                                                <td className="text-right">
                                                                    {item.price ? (<strong>
                                                                        {formatters.priceFormatter(item.price, '0,0.00', item.currency)}
                                                                    </strong>) : null}
                                                                </td>
                                                            </tr>)
                                                        })}
                                                        </tbody>
                                                    </table>
                                                </div>)}
                                            </div>)}

                                            <div className="pull-left wp-100 btns-form">
                                                {this.state.step > 1 && (
                                                    <button onClick={this.onBackButtonClick} className="btn btn-addon btn-default">
                                                        <i className="far fa-chevron-left" /> <FormattedMessage id="buttons.back" />
                                                    </button>)}

                                                {this.state.step === 2 && (
                                                    <button onClick={this.onSummaryButtonClick} className="btn btn-addon btn-success pull-right">
                                                        <i className="far fa-chevron-right" /> <FormattedMessage id="buttons.continue" />
                                                    </button>)}

                                                {this.state.step === 3 && (
                                                    <button onClick={this.onImportButtonClick} className="btn btn-addon btn-success pull-right">
                                                        <i className="far fa-check" /> <FormattedMessage id="buttons.import" />
                                                    </button>)}
                                            </div>
                                        </div>
                                    </div>
                                </LoadingOverlay>
                            </div>
                        </div>
                    </div>
                </div>
            </PermissionsCheck>)
    }
}

function mapStateToProps(state) {
    return {
        importInProgress: selectors.getImportInProgress(state),
        importError: selectors.getImportError(state),
        importFileId: selectors.getImportFileId(state),
        importHeaders: selectors.getImportHeaders(state),
        importDefaultHeaders: selectors.getImportDefaultHeaders(state),
        importItems: selectors.getImportItems(state),
        defaultDate: selectors.getDefaultDate(state),
        defaultSalesTypeId: selectors.getDefaultSalesTypeId(state),
        prerequisites: preparePrerequisitesFetchStatusSelectors(componentIdentifier, prerequisites, state),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            ...bindActionCreators({
                ...salesActionCreators, ...preparePrerequisitesActionCreators(componentIdentifier, prerequisites)
            }, dispatch),
        },
        notify: (notification, type) => dispatch(Notifications.show(notification, type)),
        redirect: toURL => dispatch(push(toURL)),
        dispatch,
    }
}

function mergeProps(stateProps, dispatchProps, ownProps) {
    return {
        ...stateProps, ...dispatchProps, ...ownProps
    }
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps, mergeProps)(SalesImport))
