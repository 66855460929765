import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import moment from 'moment'
import Notifications from 'react-notification-system-redux'
import { Modal } from 'react-bootstrap'
import { FormattedMessage, injectIntl } from 'react-intl'
import TextareaAutosize from 'react-autosize-textarea'

import { LoadingOverlay } from '../../../common/loading_overlay'
import * as formatters from '../../../common/formatters'
import TransportsPreview from '../../transports/components/TransportsPreview'

import { TableModelColumnFilteringType } from '../../../common/table/model'
import { TableFilterSelectField, TableFilterInputField, TableFilterCheckboxField } from '../../../common/table/components'
import { createTableModelSelector } from '../../../common/table/selectors'

import { preparePrerequisitesFetchStatusSelectors } from '../../../common/prerequisites/selectors'
import { preparePrerequisitesActionCreators } from '../../../common/prerequisites/actionCreators'

import * as boardActionCreators from '../actionCreators'
import { prepareTableActionCreators } from '../../../common/table/actionCreators'
import {
    getDate,
    getBoardData,
    getBoardDataSpeditions,
    getBoardDataTrailers,
    getVehicleNote,
    getTrailerNote,
    getTrailerInfo,
    getDailyNote,
    getDailyInfo,
    getWeeklyInfo,
    getFetching,
    getModalSaving,
    getPreferences,
    getPointNoteSaving,
    getPointNoteError,
} from '../selectors'
import { getCurrentUserId } from '../../auth/selectors'
import { getPrerequisitesArray } from '../../../common/helpers'
import { getActiveFilters } from '../../../common/table/helpers'

import PermissionsCheck from '../../auth/components/PermissionsCheck'
import { PERMS } from '../permissions'

import * as constants from '../../transports/constants'
import VehicleRow from './VehicleRow'
import VehicleRowSpedition from './VehicleRowSpedition'
import VehicleRowTrailer from './VehicleRowTrailer'

const tableIdentifier = 'board'

const componentIdentifier = 'board'
const prerequisites = ['dispatchers', 'drivers', 'transport_states', 'transport_point_states']

class Board extends Component {
    state = {
        modal: {
            identifier: null,
        },
        modalTrailerInfo: {
            show: false,
            trailer: null,
            trailerType: null,
            values: {},
        },
        modalLegend: {
            show: false,
        },
        modalPreferences: {
            show: false,
            values: null,
            isAllTypesSelected: 1,
        },
        modalTransportPreview: {
            show: false,
            transportId: null,
            transportPointId: null,
        },
        modalWeekendAtHome: {
            show: false,
            vehicleId: null,
            date: null,
            atHome: false,
            note: null,
        },
        modalTransportPointNote: {
            show: false,
            transportPointId: null,
            note: null,
        },
    }

    getDate = () => {
        if (this.props.date) {
            return this.props.date
        }

        const parsedDate = moment(this.props.match.params.date, 'YYYY-MM-DD')

        if (parsedDate.isValid()) {
            return parsedDate
        }

        return moment()
    }

    getDayNumbers = () => {
        const days =
            this.props.preferences && this.props.preferences.days && parseInt(this.props.preferences.days) ? parseInt(this.props.preferences.days) : 7

        return [...Array(days).keys()]
    }

    getDayDates = () =>
        this.getDayNumbers()
            .map(i => {
                const date = this.getDate()
                    .clone()
                    .add(i - 1, 'days')

                return date
            })
            .filter(date => date !== null)

    // States
    handleIsSentChange = (vehicleID, state) => {
        this.props.actions.updateDailyInfo(vehicleID, state, undefined)
    }

    handleIsDoneChange = (vehicleID, state) => {
        this.props.actions.updateDailyInfo(vehicleID, undefined, state)
    }

    // Weekend at home
    handleWeekendAtHomeChange = (vehicleID, date, atHome) => {
        this.props.actions.updateWeeklyInfo(vehicleID, date.year(), date.isoWeek(), atHome)
    }

    handleWeekendAtHomeClick = (vehicleId, date, atHome, note) => {
        this.setState({
            modalWeekendAtHome: {
                show: true,
                vehicleId,
                date,
                atHome,
                note,
            },
        })
    }

    handleWeekendAtHomeFormChange = (key, value) => {
        this.setState({
            modalWeekendAtHome: {
                ...this.state.modalWeekendAtHome,
                [key]: value,
            },
        })
    }

    handleWeekendAtHomeFormSubmit = e => {
        e && e.preventDefault()

        const vehicleId = this.state.modalWeekendAtHome.vehicleId
        const date = this.state.modalWeekendAtHome.date
        const atHome = this.state.modalWeekendAtHome.atHome
        const note = this.state.modalWeekendAtHome.note

        this.props.actions.updateWeeklyInfo(vehicleId, date.year(), date.isoWeek(), atHome, note)
    }

    handleWeekendAtHomeClose = () => {
        this.setState({
            modalWeekendAtHome: {
                show: false,
            },
        })
    }

    // Transport point note
    handlePointNoteChange = transportPoint => {
        this.setState({
            modalTransportPointNote: {
                show: true,
                transportPoint,
                note: transportPoint.board_note,
            },
        })
    }

    handlePointNoteFormChange = (key, value) => {
        this.setState({
            modalTransportPointNote: {
                ...this.state.modalTransportPointNote,
                [key]: value,
            },
        })
    }

    handlePointNoteFormSubmit = e => {
        e && e.preventDefault()

        const transportPointId = this.state.modalTransportPointNote.transportPoint.id
        const note = this.state.modalTransportPointNote.note

        this.props.actions.updateTransportPointNote(transportPointId, note)
    }

    handlePointNoteClose = () => {
        this.setState({
            modalTransportPointNote: {
                show: false,
            },
        })
    }

    // Notes
    handleDayNoteChange = (item, date, currentNote) => {
        this.setState({
            modal: {
                identifier: 'day_note',
                title: `${this.props.intl.formatMessage({ id: 'modules.board.vehicleNote' })}: ${item.name} / ${date.format('DD.MM.YYYY')}:`,
                value: currentNote,
                vehicle_id: item.id,
                trailer_id: item.trailerID,
                date,
                onSave: this.handleModalDayNoteSubmit,
            },
        })
    }

    handleVehicleNoteChange = item => {
        this.setState({
            modal: {
                identifier: 'vehicle_note',
                title: `${this.props.intl.formatMessage({ id: 'modules.board.vehicleNote' })}: ${item.name}`,
                value: item.board_note,
                vehicle_id: item.id,
                onSave: this.handleModalVehicleNoteSubmit,
            },
        })
    }

    handleTrailerNoteChange = item => {
        const trailer = item.event && item.event.trailer

        this.setState({
            modal: {
                identifier: 'trailer_note',
                title: `${this.props.intl.formatMessage({ id: 'modules.board.trailerNote' })}: ${trailer.name}`,
                value: trailer ? trailer.board_note : '',
                trailer_id: trailer ? trailer.id : null,
                onSave: this.handleModalTrailerNoteSubmit,
            },
        })
    }

    handleModalInputChange = e => {
        this.setState({
            modal: { ...this.state.modal, value: e.target.value },
        })
    }

    handleModalDayNoteSubmit = e => {
        e.preventDefault()
        this.props.actions.updateDailyNote(this.state.modal.vehicle_id, this.state.modal.trailer_id, this.state.modal.date, this.state.modal.value)
    }

    handleModalVehicleNoteSubmit = e => {
        e.preventDefault()
        this.props.actions.updateVehicleNote(this.state.modal.vehicle_id, this.state.modal.value)
    }

    handleModalTrailerNoteSubmit = e => {
        e.preventDefault()
        this.props.actions.updateTrailerNote(this.state.modal.trailer_id, this.state.modal.value)
    }

    handleModalClose = () => {
        this.setState({
            modal: { identifier: null },
        })
    }

    handleSaveResult = (error, successMessage) => {
        if (error) {
            this.props.notify(
                {
                    title: this.props.intl.formatMessage({ id: `alerts.titles.error` }),
                    message: error,
                    position: 'tc',
                },
                'error'
            )
        } else {
            this.setState({ modal: { identifier: null } })

            this.props.notify(
                {
                    title: this.props.intl.formatMessage({ id: `alerts.titles.success` }),
                    message: successMessage,
                    position: 'tc',
                },
                'success'
            )

            this.props.actions.fetchBoard(this.getDate())
        }
    }

    // Sorting / filters
    handleChangeSorting = e => {
        this.props.table.changeSorting(e.target.value, 'ASC')
    }

    handleChangeThreeWaySwitchFilter = (e, identifier) => {
        const filters = this.props.tableModel.get('filters')
        const currentValue = filters.getIn([identifier, 'value']) || 0
        const switchOptions = {
            0: 1,
            1: 2,
            2: 0,
        }

        this.props.table.changeFilter(identifier, TableModelColumnFilteringType.equal, switchOptions[currentValue])
    }

    handleChangeFiveWaySwitchFilter = (e, identifier) => {
        const filters = this.props.tableModel.get('filters')
        const currentValue = filters.getIn([identifier, 'value']) || 0
        const switchOptions = {
            0: 1,
            1: 2,
            2: 3,
            3: 4,
            4: 0,
        }

        this.props.table.changeFilter(identifier, TableModelColumnFilteringType.equal, switchOptions[currentValue])
    }

    // Export
    handleExport = e => {
        e && e.preventDefault()
        this.props.actions.exportBoard(
            this.getDate(),
            this.props.tableModel.get('filters').toJS(),
            this.props.tableModel.get('sorting').toJS(),
            this.state.modalPreferences.values
        )
    }

    // Trailer info
    handleTrailerClick = item => {
        const trailer = item.event && item.event.trailer

        this.setState(
            {
                modalTrailerInfo: {
                    ...this.state.modalTrailerInfo,
                    trailer,
                    trailerType: trailer && trailer.trailer_type,
                    values: {
                        is_damaged: trailer && trailer.is_damage,
                        is_security_damaged: trailer && trailer.is_security_damage,
                        is_cooling_damaged: trailer && trailer.is_cooling_damage,
                        current_position: trailer && trailer.current_position,
                    },
                },
            },
            () => {
                this.handleModalTrailerInfoOpen()
            }
        )
    }

    handleModalTrailerInfoOpen = () => {
        this.setState({
            modalTrailerInfo: {
                ...this.state.modalTrailerInfo,
                show: true,
            },
        })
    }

    handleModalTrailerInfoClose = () => {
        this.setState({
            modalTrailerInfo: {
                ...this.state.modalTrailerInfo,
                show: false,
            },
        })
    }

    handleModalTrailerInfoChange = (key, value) => {
        this.setState({
            modalTrailerInfo: {
                ...this.state.modalTrailerInfo,
                values: {
                    ...this.state.modalTrailerInfo.values,
                    [key]: value,
                },
            },
        })
    }

    handleModalTrailerInfoSubmit = e => {
        e && e.preventDefault()

        if (this.state.modalTrailerInfo.trailer) {
            this.props.actions.updateTrailerInfo(this.state.modalTrailerInfo.trailer.id, this.state.modalTrailerInfo.values)
        }

        this.handleModalTrailerInfoClose()
    }

    // Legend and preferences
    handleModalLegendOpen = () => {
        this.setState({
            modalLegend: { show: true },
        })
    }

    handleModalLegendClose = () => {
        this.setState({
            modalLegend: { show: false },
        })
    }

    handleModalPreferencesOpen = () => {
        this.setState({
            modalPreferences: {
                ...this.state.modalPreferences,
                show: true,
            },
        })
    }

    handleModalPreferencesClose = () => {
        this.setState({
            modalPreferences: {
                ...this.state.modalPreferences,
                show: false,
            },
        })
    }

    handleModalPreferencesChange = (key, value) => {
        this.setState({
            modalPreferences: {
                ...this.state.modalPreferences,
                values: {
                    ...this.state.modalPreferences.values,
                    [key]: value,
                },
            },
        })
    }

    handleModalPreferencesSelectAllTypes = value => {
        const values = {}
        Object.keys(constants.TRANSPORT_POINT_TYPES_DEFINITION).forEach(typeId => {
            const type = constants.TRANSPORT_POINT_TYPES_DEFINITION[typeId]

            if (!type.notAvailable) {
                values[`show_transport_point_type_${typeId}`] = value
            }
        })

        this.setState({
            modalPreferences: {
                ...this.state.modalPreferences,
                values,
                isAllTypesSelected: value,
            },
        })
    }

    handleModalPreferencesSubmit = e => {
        e && e.preventDefault()

        this.props.actions.updatePreferences(this.state.modalPreferences.values, this.getDate())
        this.handleModalPreferencesClose()
    }

    setPreferences = preferences => {
        this.setState({
            modalPreferences: {
                ...this.state.modalPreferences,
                values: preferences,
            },
        })
    }

    // Transport preview
    handleModalTransportPreviewOpen = (transportId, transportPointId) => {
        this.setState({
            modalTransportPreview: {
                show: true,
                transportId,
                transportPointId,
            },
        })
    }

    handleModalTransportPreviewClose = () => {
        this.setState({
            modalTransportPreview: {
                show: false,
                transportId: null,
                transportPointId: null,
            },
        })
    }

    refresh = () => {
        this.props.actions.fetchBoard(this.getDate())
    }

    resetFilters = () => {
        this.props.table.resetFilters()
    }

    isFilterActive = () => getActiveFilters(this.props.tableModel).size > 0

    componentDidMount() {
        this.props.actions.fetchBoard(this.getDate())
        this.props.actions.fetchPrerequisites()
    }

    componentWillReceiveProps(nextProps) {
        const parsedDate = moment(nextProps.match.params.date, 'YYYY-MM-DD')

        if (!this.getDate().isSame(parsedDate, 'day')) {
            this.props.actions.fetchBoard(parsedDate)
            this.props.table.resetFilters(this.getDayNumbers().map(dayNumber => `day${dayNumber}`))
        }

        // Vehicle note
        if (this.props.vehicleNote.get('saving') && !nextProps.vehicleNote.get('saving')) {
            this.handleSaveResult(nextProps.vehicleNote.get('error'), this.props.intl.formatMessage({ id: 'alerts.messages.noteSaveSuccess' }))
        }

        // Trailer note
        if (this.props.trailerNote.get('saving') && !nextProps.trailerNote.get('saving')) {
            this.handleSaveResult(nextProps.trailerNote.get('error'), this.props.intl.formatMessage({ id: 'alerts.messages.noteSaveSuccess' }))
        }

        // Trailer info
        if (this.props.trailerInfo.get('saving') && !nextProps.trailerInfo.get('saving')) {
            this.handleSaveResult(nextProps.trailerInfo.get('error'), this.props.intl.formatMessage({ id: 'alerts.messages.changesSaveSuccess' }))
        }

        // Daily note
        if (this.props.dailyNote.get('saving') && !nextProps.dailyNote.get('saving')) {
            this.handleSaveResult(nextProps.dailyNote.get('error'), this.props.intl.formatMessage({ id: 'alerts.messages.noteSaveSuccess' }))
        }

        // Daily info
        if (this.props.dailyInfo.get('saving') && !nextProps.dailyInfo.get('saving')) {
            this.handleSaveResult(nextProps.dailyInfo.get('error'), this.props.intl.formatMessage({ id: 'alerts.messages.changesSaveSuccess' }))
        }

        // Weekly info
        if (this.props.weeklyInfo.get('saving') && !nextProps.weeklyInfo.get('saving')) {
            this.handleSaveResult(nextProps.weeklyInfo.get('error'), this.props.intl.formatMessage({ id: 'alerts.messages.changesSaveSuccess' }))

            if (!nextProps.weeklyInfo.get('error')) {
                this.handleWeekendAtHomeClose()
            }
        }

        // Preferences
        if (!this.state.modalPreferences.values || JSON.stringify(this.props.preferences) !== JSON.stringify(nextProps.preferences)) {
            this.setPreferences(nextProps.preferences)
        }

        // Point note
        if (this.props.pointNoteSaving && !nextProps.weeklyInfo.pointNoteSaving) {
            this.handleSaveResult(nextProps.pointNoteError, this.props.intl.formatMessage({ id: 'alerts.messages.changesSaveSuccess' }))

            if (!nextProps.pointNoteError) {
                this.handlePointNoteClose()
            }
        }
    }

    /* eslint-disable jsx-a11y/anchor-is-valid */
    render() {
        document.title = formatters.titleFormatter(this.props.intl.formatMessage({ id: 'modules.board.heading' }))

        const days = this.getDayDates()

        const currentDate = this.getDate()
        const yesterday = currentDate.clone().subtract(1, 'day')
        const lastDate = currentDate.clone().add(days.length - 2, 'days')
        const prevWeek = moment(currentDate).subtract(7, 'day')
        const prevDay = moment(currentDate).subtract(1, 'day')
        const nextWeek = moment(currentDate).add(7, 'day')
        const nextDay = moment(currentDate).add(1, 'day')

        const filters = this.props.tableModel.get('filters')
        const sorting = this.props.tableModel.get('sorting').toJS()

        let data = this.props.data
        if (data) {
            data = data.sort((first, second) => {
                if (sorting.column === 'vehicle_name') {
                    return first.name.localeCompare(second.name)
                } else if (sorting.column === 'waterfall') {
                    const firstLastEventDate = first.last_event_datetime && moment(first.last_event_datetime)
                    const secondLastEventDate = second.last_event_datetime && moment(second.last_event_datetime)

                    if (firstLastEventDate && secondLastEventDate) {
                        if (firstLastEventDate.isBefore(secondLastEventDate)) {
                            return -1
                        }

                        return firstLastEventDate.isAfter(secondLastEventDate) ? 1 : 0
                    } else if (firstLastEventDate || secondLastEventDate) {
                        return firstLastEventDate ? 1 : -1
                    }
                }

                return 0
            })
        }

        const dataSpeditions = this.props.dataSpeditions
        const dataTrailers = this.props.dataTrailers

        const threeWaySwitchOptions = {
            0: 'text-gray-light',
            1: 'text-success',
            2: 'text-danger',
        }

        const fiveWaySwitchOptions = {
            0: 'text-gray-light',
            1: 'text-gray-darkest',
            2: 'text-success',
            3: 'text-danger',
            4: 'text-default',
        }

        const filterIconVehicleStateClassName = threeWaySwitchOptions[filters.getIn(['vehicle_state', 'value']) || 0]
        const filterIconVehicleUpdateClassName = threeWaySwitchOptions[filters.getIn(['vehicle_update', 'value']) || 0]
        const filterIconVehicleEtaUpdateClassName = threeWaySwitchOptions[filters.getIn(['vehicle_eta_update', 'value']) || 0]
        const filterIconTrailerLoadedClassName = threeWaySwitchOptions[filters.getIn(['trailer_loaded', 'value']) || 0]
        const filterIconTrailerDamagedClassName = threeWaySwitchOptions[filters.getIn(['trailer_damaged', 'value']) || 0]
        const filterIconTrailerDamagedSecurityClassName = fiveWaySwitchOptions[filters.getIn(['trailer_damaged_security', 'value']) || 0]
        const filterIconTrailerDamagedCoolingClassName = fiveWaySwitchOptions[filters.getIn(['trailer_damaged_cooling', 'value']) || 0]
        const filterIconIsDoneClassName = threeWaySwitchOptions[filters.getIn(['is_done', 'value']) || 0]

        const preferences = this.props.preferences

        return (
            <PermissionsCheck hasAny={Object.values(PERMS)} noPermissionsPage>
                <div className="page-inner">
                    <div id="main-wrapper">
                        <div className="panel panel-white">
                            <div className="panel-body">
                                <div className="wp-100 pull-left m-b-xs">
                                    <h4 className="pull-left">
                                        <span className="pull-left">
                                            <FormattedMessage id="modules.board.heading" />
                                        </span>
                                        <span className="pull-left text-thin sorting">
                                            <span className="pull-left text-gray">
                                                <FormattedMessage id="sorting.title" />:
                                            </span>
                                            <select value={sorting.column} onChange={this.handleChangeSorting} className="form-control pull-left">
                                                <option value="vehicle_name">{this.props.intl.formatMessage({ id: 'sorting.option.vehicle' })}</option>
                                                <option value="waterfall">{this.props.intl.formatMessage({ id: 'sorting.option.waterfall' })}</option>
                                            </select>
                                        </span>
                                    </h4>

                                    <div className="btns-list">
                                        <span className="pull-left text-thin period-selection">
                                            <Link to={prevWeek.format('Y-MM-DD')} className="btn btn-default btn-rounded">
                                                <i className="far fa-angle-double-left" />
                                            </Link>
                                            <Link to={prevDay.format('Y-MM-DD')} className="btn btn-default btn-rounded">
                                                <i className="far fa-angle-left" />
                                            </Link>
                                            <span>
                                                {`${this.props.intl.formatMessage({ id: `dayNames.${yesterday.format('d')}` })} ${yesterday.format(
                                                    'D.M.'
                                                )} - ${lastDate.format('D.M.')}`}
                                            </span>
                                            <Link to={nextDay.format('Y-MM-DD')} className="btn btn-default btn-rounded">
                                                <i className="far fa-angle-right" />
                                            </Link>
                                            <Link to={nextWeek.format('Y-MM-DD')} className="btn btn-default btn-rounded">
                                                <i className="far fa-angle-double-right" />
                                            </Link>
                                        </span>
                                        {this.isFilterActive() > 0 && (
                                            <button className="btn btn-default btn-addon m-r-xs" onClick={this.resetFilters}>
                                                <i className="far fa-times" /> <FormattedMessage id="buttons.resetFilters" />
                                            </button>
                                        )}
                                        <button className="btn btn-default btn-addon m-r-xs" onClick={this.refresh}>
                                            <i className="far fa-sync-alt" /> <FormattedMessage id="buttons.refresh" />
                                        </button>
                                        <button className="btn btn-default far fa-cog m-r-xs" onClick={this.handleModalPreferencesOpen} />
                                        <button className="btn btn-default far fa-question-circle m-r-xs" onClick={this.handleModalLegendOpen} />
                                        <PermissionsCheck has={[PERMS.EXPORT]}>
                                            <button className="btn btn-primary btn-addon" onClick={this.handleExport}>
                                                <i className="far fa-file-excel" /> <FormattedMessage id="buttons.exportToXLS" />
                                            </button>
                                        </PermissionsCheck>
                                    </div>
                                </div>

                                <div className="table-container table-container-no-pagination">
                                    <table className="table table-striped table-board table-fixed-header">
                                        <thead>
                                            <tr>
                                                <th className="w-350">
                                                    <FormattedMessage id="fields.vehicle" />
                                                    <br />
                                                    <small>
                                                        <FormattedMessage id="fields.trailer" />
                                                    </small>
                                                </th>
                                                <th className="w-140">
                                                    <FormattedMessage id="fields.note" />
                                                </th>
                                                {days.map(date => (
                                                    <React.Fragment key={`table-day-header-${date.format('YYYY-MM-DD')}`}>
                                                        {date.weekday() === 5 && <th className="w-100" />}
                                                        <th className="w-max wm-250">
                                                            {this.props.intl.formatMessage({ id: `dayNames.${date.format('d')}` })}
                                                            <br />
                                                            <small>{date.format('D.M.Y')}</small>
                                                        </th>
                                                    </React.Fragment>
                                                ))}
                                            </tr>
                                            <tr className="filters">
                                                <th className="w-350">
                                                    <div className="pull-left w-100 m-r-xxs">
                                                        <div className="wp-100">
                                                            <TableFilterInputField
                                                                identifier="vehicle_name"
                                                                type={TableModelColumnFilteringType.string}
                                                                filters={filters}
                                                                onChange={this.props.table.changeFilter}
                                                                placeholder={`- ${this.props.intl.formatMessage({ id: 'fields.vehicle' })} -`}
                                                            />
                                                        </div>
                                                        <div className="wp-100 m-t-xxs">
                                                            <TableFilterInputField
                                                                identifier="trailer_name"
                                                                type={TableModelColumnFilteringType.string}
                                                                filters={filters}
                                                                onChange={this.props.table.changeFilter}
                                                                placeholder={`- ${this.props.intl.formatMessage({ id: 'fields.trailer' })} -`}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="pull-left w-100">
                                                        <div className="wp-100">
                                                            <TableFilterSelectField
                                                                identifier="driver_id"
                                                                type={TableModelColumnFilteringType.equal}
                                                                filters={filters}
                                                                onChange={this.props.table.changeFilter}
                                                                values={getPrerequisitesArray(this.props.prerequisites.values.get('drivers'))}
                                                                prompt={`- ${this.props.intl.formatMessage({ id: 'fields.driver' })} -`}
                                                            />
                                                        </div>
                                                        <div className="wp-100 m-t-xxs">
                                                            <TableFilterSelectField
                                                                identifier="dispatcher_id"
                                                                type={TableModelColumnFilteringType.equal}
                                                                filters={filters}
                                                                onChange={this.props.table.changeFilter}
                                                                values={getPrerequisitesArray(this.props.prerequisites.values.get('dispatchers'))}
                                                                prompt={`- ${this.props.intl.formatMessage({ id: 'fields.dispatcher' })} -`}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="pull-right w-80" style={{ paddingRight: '4px' }}>
                                                        <div className="wp-100 text-right m-t-xs">
                                                            <a
                                                                href="#"
                                                                className="m-r-xxs"
                                                                onClick={e => {
                                                                    this.handleChangeThreeWaySwitchFilter(e, 'vehicle_state')
                                                                }}
                                                            >
                                                                <i className={`fas fa-circle ${filterIconVehicleStateClassName}`} />
                                                            </a>
                                                            <a
                                                                href="#"
                                                                className="m-r-xxs"
                                                                onClick={e => {
                                                                    this.handleChangeThreeWaySwitchFilter(e, 'vehicle_update')
                                                                }}
                                                            >
                                                                <i className={`fas fa-clock ${filterIconVehicleUpdateClassName}`} />
                                                            </a>
                                                            <a
                                                                href="#"
                                                                className="m-r-xxs"
                                                                onClick={e => {
                                                                    this.handleChangeThreeWaySwitchFilter(e, 'vehicle_eta_update')
                                                                }}
                                                            >
                                                                <i className={`fas fa-location-arrow ${filterIconVehicleEtaUpdateClassName}`} />
                                                            </a>
                                                            <a
                                                                href="#"
                                                                onClick={e => {
                                                                    this.handleChangeThreeWaySwitchFilter(e, 'is_done')
                                                                }}
                                                            >
                                                                <i className={`fas fa-check ${filterIconIsDoneClassName}`} />
                                                            </a>
                                                        </div>
                                                        <div className="wp-100 text-right m-t-xxs">
                                                            <a
                                                                href="#"
                                                                className="m-r-xxs"
                                                                onClick={e => {
                                                                    this.handleChangeThreeWaySwitchFilter(e, 'trailer_loaded')
                                                                }}
                                                            >
                                                                <i className={`fas fa-truck ${filterIconTrailerLoadedClassName}`} />
                                                            </a>
                                                            <a
                                                                href="#"
                                                                className="m-r-xxs"
                                                                onClick={e => {
                                                                    this.handleChangeThreeWaySwitchFilter(e, 'trailer_damaged')
                                                                }}
                                                            >
                                                                <i className={`fas fa-exclamation-circle ${filterIconTrailerDamagedClassName}`} />
                                                            </a>
                                                            <a
                                                                href="#"
                                                                className="m-r-xxs"
                                                                onClick={e => {
                                                                    this.handleChangeFiveWaySwitchFilter(e, 'trailer_damaged_security')
                                                                }}
                                                            >
                                                                <i className={`fas fa-lock ${filterIconTrailerDamagedSecurityClassName}`} />
                                                            </a>
                                                            <a
                                                                href="#"
                                                                onClick={e => {
                                                                    this.handleChangeFiveWaySwitchFilter(e, 'trailer_damaged_cooling')
                                                                }}
                                                            >
                                                                <i className={`fas fa-snowflake ${filterIconTrailerDamagedCoolingClassName}`} />
                                                            </a>
                                                        </div>
                                                    </div>
                                                </th>
                                                <th className="w-140">
                                                    <TableFilterInputField
                                                        identifier="note"
                                                        type={TableModelColumnFilteringType.string}
                                                        filters={filters}
                                                        onChange={this.props.table.changeFilter}
                                                    />
                                                </th>
                                                {days.map(date => (
                                                    <React.Fragment key={`table-day-header-${date.format('YYYY-MM-DD')}`}>
                                                        {date.weekday() === 5 && <th className="w-100" />}
                                                        <th className="w-max wm-250">
                                                            <TableFilterInputField
                                                                identifier={date.format('YYYY-MM-DD')}
                                                                type={TableModelColumnFilteringType.equal}
                                                                filters={filters}
                                                                onChange={this.props.table.changeFilter}
                                                            />
                                                            <TableFilterCheckboxField
                                                                identifier={`free-${date.format('YYYY-MM-DD')}`}
                                                                type={TableModelColumnFilteringType.equal}
                                                                filters={filters}
                                                                onChange={this.props.table.changeFilter}
                                                                label={this.props.intl.formatMessage({ id: 'modules.board.showFree' })}
                                                            />
                                                        </th>
                                                    </React.Fragment>
                                                ))}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data &&
                                                data.map(item => {
                                                    const lastEvent = item.last_event
                                                    const lastEventDate = item.last_event_datetime && moment(item.last_event_datetime)

                                                    if (
                                                        filters.getIn(['dispatcher_id', 'value']) &&
                                                        (!item.dispatcher ||
                                                            parseInt(item.dispatcher.id) !== parseInt(filters.getIn(['dispatcher_id', 'value'])))
                                                    ) {
                                                        return null
                                                    }

                                                    if (
                                                        filters.getIn(['driver_id', 'value']) &&
                                                        (!item.driver || parseInt(item.driver.id) !== parseInt(filters.getIn(['driver_id', 'value'])))
                                                    ) {
                                                        return null
                                                    }

                                                    if (
                                                        filters.getIn(['vehicle_name', 'value']) &&
                                                        item.name.toLowerCase().indexOf(filters.getIn(['vehicle_name', 'value']).toLowerCase()) < 0
                                                    ) {
                                                        return null
                                                    }

                                                    if (
                                                        filters.getIn(['trailer_name', 'value']) &&
                                                        (!item.event ||
                                                            !item.event.trailer ||
                                                            item.event.trailer.name
                                                                .toLowerCase()
                                                                .indexOf(filters.getIn(['trailer_name', 'value']).toLowerCase()) < 0)
                                                    ) {
                                                        return null
                                                    }

                                                    if (
                                                        filters.getIn(['vehicle_state', 'value']) === 1 &&
                                                        (!item.position || item.position.speed === 0)
                                                    ) {
                                                        return null
                                                    }

                                                    if (filters.getIn(['vehicle_state', 'value']) === 2 && (!item.position || item.position.speed > 0)) {
                                                        return null
                                                    }

                                                    if (
                                                        filters.getIn(['vehicle_update', 'value']) === 1 &&
                                                        (!item.position || moment().diff(moment(item.position.datetime), 'minutes') > 120)
                                                    ) {
                                                        return null
                                                    }

                                                    if (
                                                        filters.getIn(['vehicle_update', 'value']) === 2 &&
                                                        (!item.position || moment().diff(moment(item.position.datetime), 'minutes') <= 120)
                                                    ) {
                                                        return null
                                                    }

                                                    if (
                                                        filters.getIn(['vehicle_eta_update', 'value']) === 1 &&
                                                        (!item.last_eta_update || moment().diff(moment(item.last_eta_update.datetime), 'minutes') > 120)
                                                    ) {
                                                        return null
                                                    }

                                                    if (
                                                        filters.getIn(['vehicle_eta_update', 'value']) === 2 &&
                                                        (!item.last_eta_update || moment().diff(moment(item.last_eta_update.datetime), 'minutes') <= 120)
                                                    ) {
                                                        return null
                                                    }

                                                    if (
                                                        filters.getIn(['trailer_loaded', 'value']) === 1 &&
                                                        (!item.event || !item.event.trailer || item.event.is_trailer_loaded === 1)
                                                    ) {
                                                        return null
                                                    }

                                                    if (
                                                        filters.getIn(['trailer_loaded', 'value']) === 2 &&
                                                        (!item.event || !item.event.trailer || item.event.is_trailer_loaded !== 1)
                                                    ) {
                                                        return null
                                                    }

                                                    if (
                                                        filters.getIn(['trailer_damaged', 'value']) === 1 &&
                                                        (!item.event || !item.event.trailer || item.event.trailer.is_damage === 1)
                                                    ) {
                                                        return null
                                                    }

                                                    if (
                                                        filters.getIn(['trailer_damaged', 'value']) === 2 &&
                                                        (!item.event || !item.event.trailer || item.event.trailer.is_damage !== 1)
                                                    ) {
                                                        return null
                                                    }

                                                    if (
                                                        filters.getIn(['trailer_damaged_security', 'value']) === 1 &&
                                                        (!item.event ||
                                                            !item.event.trailer ||
                                                            !item.event.trailer.trailer_type ||
                                                            !item.event.trailer.trailer_type.is_secured)
                                                    ) {
                                                        return null
                                                    }

                                                    if (
                                                        filters.getIn(['trailer_damaged_security', 'value']) === 2 &&
                                                        (!item.event ||
                                                            !item.event.trailer ||
                                                            !item.event.trailer.trailer_type ||
                                                            !item.event.trailer.trailer_type.is_secured ||
                                                            item.event.trailer.is_security_damage === 1)
                                                    ) {
                                                        return null
                                                    }

                                                    if (
                                                        filters.getIn(['trailer_damaged_security', 'value']) === 3 &&
                                                        (!item.event ||
                                                            !item.event.trailer ||
                                                            !item.event.trailer.trailer_type ||
                                                            !item.event.trailer.trailer_type.is_secured ||
                                                            item.event.trailer.is_security_damage !== 1)
                                                    ) {
                                                        return null
                                                    }

                                                    if (
                                                        filters.getIn(['trailer_damaged_security', 'value']) === 4 &&
                                                        (!item.event ||
                                                            !item.event.trailer ||
                                                            !item.event.trailer.trailer_type ||
                                                            item.event.trailer.trailer_type.is_secured)
                                                    ) {
                                                        return null
                                                    }

                                                    if (
                                                        filters.getIn(['trailer_damaged_cooling', 'value']) === 1 &&
                                                        (!item.event ||
                                                            !item.event.trailer ||
                                                            !item.event.trailer.trailer_type ||
                                                            !item.event.trailer.trailer_type.is_cooled)
                                                    ) {
                                                        return null
                                                    }

                                                    if (
                                                        filters.getIn(['trailer_damaged_cooling', 'value']) === 2 &&
                                                        (!item.event ||
                                                            !item.event.trailer ||
                                                            !item.event.trailer.trailer_type ||
                                                            !item.event.trailer.trailer_type.is_cooled ||
                                                            item.event.trailer.is_cooling_damage === 1)
                                                    ) {
                                                        return null
                                                    }

                                                    if (
                                                        filters.getIn(['trailer_damaged_cooling', 'value']) === 3 &&
                                                        (!item.event ||
                                                            !item.event.trailer ||
                                                            !item.event.trailer.trailer_type ||
                                                            !item.event.trailer.trailer_type.is_cooled ||
                                                            item.event.trailer.is_cooling_damage !== 1)
                                                    ) {
                                                        return null
                                                    }

                                                    if (
                                                        filters.getIn(['trailer_damaged_cooling', 'value']) === 4 &&
                                                        (!item.event ||
                                                            !item.event.trailer ||
                                                            !item.event.trailer.trailer_type ||
                                                            item.event.trailer.trailer_type.is_cooled)
                                                    ) {
                                                        return null
                                                    }

                                                    if (filters.getIn(['is_done', 'value']) === 1 && !(item.daily_info && item.daily_info.is_done)) {
                                                        return null
                                                    }

                                                    if (filters.getIn(['is_done', 'value']) === 2 && (item.daily_info && item.daily_info.is_done)) {
                                                        return null
                                                    }

                                                    if (
                                                        filters.getIn(['note', 'value']) &&
                                                        item.board_note.toLowerCase().indexOf(filters.getIn(['note', 'value']).toLowerCase()) < 0
                                                    ) {
                                                        return null
                                                    }

                                                    for (const date of days) {
                                                        const key = date.format('YYYY-MM-DD')
                                                        const transportPoints = item.transport_points && item.transport_points[key]

                                                        // search
                                                        const search = filters.getIn([key, 'value'])
                                                        if (search) {
                                                            const filtered = transportPoints
                                                                ? Object.keys(transportPoints).filter(transportPointKey => {
                                                                    const transportPoint = transportPoints[transportPointKey]
                                                                    if (!parseInt(preferences[`show_transport_point_type_${transportPoint.type_id}`])) {
                                                                        return false
                                                                    }
                                                                    return transportPoint.name.toLowerCase().indexOf(search.toLowerCase()) >= 0
                                                                })
                                                                : []

                                                            // last event
                                                            if (lastEvent && lastEventDate && lastEventDate.isBefore(date, 'day')) {
                                                                if (lastEvent.name.toLowerCase().indexOf(search.toLowerCase()) >= 0) {
                                                                    filtered.push(lastEvent)
                                                                }
                                                            }

                                                            if (!filtered.length) {
                                                                return null
                                                            }
                                                        }

                                                        // free
                                                        const free = filters.getIn([`free-${key}`, 'value'])
                                                        if (free) {
                                                            if (lastEventDate && lastEventDate.isAfter(date, 'day')) {
                                                                return null
                                                            }
                                                        }
                                                    }

                                                    return (
                                                        <VehicleRow
                                                            key={`vehicle-row-${item.id}`}
                                                            item={item}
                                                            days={days}
                                                            currentUserId={this.props.currentUserId}
                                                            vehicleID={item.id}
                                                            preferences={preferences}
                                                            handleIsSentChange={this.handleIsSentChange}
                                                            handleIsDoneChange={this.handleIsDoneChange}
                                                            handleVehicleNoteChange={this.handleVehicleNoteChange}
                                                            handleTrailerNoteChange={this.handleTrailerNoteChange}
                                                            handleDayNoteChange={this.handleDayNoteChange}
                                                            handleWeekendAtHomeClick={this.handleWeekendAtHomeClick}
                                                            handleWeekendAtHomeChange={this.handleWeekendAtHomeChange}
                                                            handleTrailerClick={this.handleTrailerClick}
                                                            handleTransportClick={this.handleModalTransportPreviewOpen}
                                                            handlePointNoteChange={this.handlePointNoteChange}
                                                        />
                                                    )
                                                })}

                                            {dataSpeditions &&
                                                dataSpeditions.map(item => {
                                                    for (const date of days) {
                                                        const key = date.format('YYYY-MM-DD')
                                                        const transportPoints = item.transport_points && item.transport_points[key]

                                                        // search
                                                        const search = filters.getIn([key, 'value'])
                                                        if (search) {
                                                            const filtered = transportPoints
                                                                ? Object.keys(transportPoints).filter(transportPointKey => {
                                                                    const transportPoint = transportPoints[transportPointKey]

                                                                    if (!parseInt(preferences[`show_transport_point_type_${transportPoint.type_id}`])) {
                                                                        return false
                                                                    }

                                                                    return transportPoint.name.toLowerCase().indexOf(search.toLowerCase()) >= 0
                                                                })
                                                                : []

                                                            if (!filtered.length) {
                                                                return null
                                                            }
                                                        }
                                                    }

                                                    return (
                                                        <VehicleRowSpedition
                                                            key={`spedition-row-${item.id}`}
                                                            item={item}
                                                            days={days}
                                                            currentUserId={this.props.currentUserId}
                                                            preferences={preferences}
                                                            handleTransportClick={this.handleModalTransportPreviewOpen}
                                                            handlePointNoteChange={this.handlePointNoteChange}
                                                        />
                                                    )
                                                })}

                                            {dataTrailers &&
                                                dataTrailers.map(item => {
                                                    for (const date of days) {
                                                        const key = date.format('YYYY-MM-DD')
                                                        const transportPoints = item.transport_points && item.transport_points[key]

                                                        // search
                                                        const search = filters.getIn([key, 'value'])
                                                        if (search) {
                                                            const filtered = transportPoints
                                                                ? Object.keys(transportPoints).filter(transportPointKey => {
                                                                    const transportPoint = transportPoints[transportPointKey]

                                                                    if (!parseInt(preferences[`show_transport_point_type_${transportPoint.type_id}`])) {
                                                                        return false
                                                                    }

                                                                    return transportPoint.name.toLowerCase().indexOf(search.toLowerCase()) >= 0
                                                                })
                                                                : []

                                                            if (!filtered.length) {
                                                                return null
                                                            }
                                                        }
                                                    }

                                                    return (
                                                        <VehicleRowTrailer
                                                            key={`trailer-row-${item.trailerID}`}
                                                            item={item}
                                                            days={days}
                                                            currentUserId={this.props.currentUserId}
                                                            preferences={preferences}
                                                            handleTransportClick={this.handleModalTransportPreviewOpen}
                                                            handleTrailerNoteChange={this.handleTrailerNoteChange}
                                                            handleDayNoteChange={this.handleDayNoteChange}
                                                        />
                                                    )
                                                })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                    {['day_note', 'vehicle_note', 'trailer_note'].includes(this.state.modal.identifier) && (
                        <Modal show onHide={this.handleModalClose} bsSize="sm">
                            <Modal.Header closeButton>
                                <Modal.Title>{this.state.modal.title}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <LoadingOverlay active={this.props.modalSaving}>
                                    <form onSubmit={this.state.modal.onSave}>
                                        <div className="form-group">
                                            <label htmlFor="value_note">
                                                <FormattedMessage id="fields.note" />
                                            </label>
                                            <TextareaAutosize
                                                id="value_note"
                                                className="form-control"
                                                value={this.state.modal.value}
                                                onChange={this.handleModalInputChange}
                                            />
                                        </div>
                                        <div className="btns-form text-right">
                                            <button type="submit" className="btn btn-success">
                                                <FormattedMessage id="buttons.save" />
                                            </button>
                                        </div>
                                    </form>
                                </LoadingOverlay>
                            </Modal.Body>
                        </Modal>
                    )}

                    <Modal show={this.state.modalWeekendAtHome.show} onHide={this.handleWeekendAtHomeClose} bsSize="sm">
                        <Modal.Header closeButton>
                            <Modal.Title>
                                <FormattedMessage id="modules.board.weekendAtHome" />
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <LoadingOverlay active={this.props.weeklyInfo.get('saving')}>
                                <form onSubmit={this.handleWeekendAtHomeFormSubmit}>
                                    <div className="form-group checkbox">
                                        <label htmlFor="value_at_home">
                                            <input
                                                id="value_at_home"
                                                type="checkbox"
                                                checked={this.state.modalWeekendAtHome.atHome}
                                                onChange={e => {
                                                    this.handleWeekendAtHomeFormChange('atHome', e.target.checked)
                                                }}
                                            />
                                            <FormattedMessage id="modules.board.weekendAtHome" />
                                        </label>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="value_note">
                                            <FormattedMessage id="fields.note" />
                                        </label>
                                        <TextareaAutosize
                                            id="value_note"
                                            className="form-control"
                                            value={this.state.modalWeekendAtHome.note}
                                            onChange={e => {
                                                this.handleWeekendAtHomeFormChange('note', e.target.value)
                                            }}
                                        />
                                    </div>
                                    <div className="btns-form text-right">
                                        <button type="submit" className="btn btn-success">
                                            <FormattedMessage id="buttons.save" />
                                        </button>
                                    </div>
                                </form>
                            </LoadingOverlay>
                        </Modal.Body>
                    </Modal>

                    <Modal show={this.state.modalTransportPointNote.show} onHide={this.handlePointNoteClose} bsSize="sm">
                        <Modal.Header closeButton>
                            <Modal.Title>
                                <FormattedMessage id="fields.note" />:{' '}
                                {this.state.modalTransportPointNote.transportPoint && this.state.modalTransportPointNote.transportPoint.name}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <LoadingOverlay active={this.props.pointNoteSaving}>
                                <form onSubmit={this.handlePointNoteFormSubmit}>
                                    <div className="form-group">
                                        <label htmlFor="value_note">
                                            <FormattedMessage id="fields.note" />
                                        </label>
                                        <TextareaAutosize
                                            id="value_note"
                                            className="form-control"
                                            value={this.state.modalTransportPointNote.note}
                                            onChange={e => {
                                                this.handlePointNoteFormChange('note', e.target.value)
                                            }}
                                        />
                                    </div>
                                    <div className="btns-form text-right">
                                        <button type="submit" className="btn btn-success">
                                            <FormattedMessage id="buttons.save" />
                                        </button>
                                    </div>
                                </form>
                            </LoadingOverlay>
                        </Modal.Body>
                    </Modal>

                    <Modal
                        show={Boolean(this.state.modalTrailerInfo.show && this.state.modalTrailerInfo.trailer)}
                        onHide={this.handleModalTrailerInfoClose}
                        bsSize="sm"
                    >
                        {this.state.modalTrailerInfo.trailer && (
                            <React.Fragment>
                                <Modal.Header closeButton>
                                    <Modal.Title>
                                        <FormattedMessage id="fields.trailer" /> {this.state.modalTrailerInfo.trailer.name}
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <LoadingOverlay active={this.props.modalTrailerSaving}>
                                        <form onSubmit={this.handleModalTrailerInfoSubmit}>
                                            <div className="form-group checkbox">
                                                <label htmlFor="modalTrailerInfo-trailerDamaged">
                                                    <input
                                                        id="modalTrailerInfo-trailerDamaged"
                                                        type="checkbox"
                                                        checked={Boolean(this.state.modalTrailerInfo.values.is_damaged)}
                                                        onChange={e => {
                                                            this.handleModalTrailerInfoChange('is_damaged', e.target.checked)
                                                        }}
                                                    />
                                                    <FormattedMessage id="fields.trailerDamaged" />
                                                </label>
                                            </div>
                                            {this.state.modalTrailerInfo.trailerType && this.state.modalTrailerInfo.trailerType.is_secured === 1 && (
                                                <div className="form-group checkbox">
                                                    <label htmlFor="modalTrailerInfo-securityDamaged">
                                                        <input
                                                            id="modalTrailerInfo-securityDamaged"
                                                            type="checkbox"
                                                            checked={Boolean(this.state.modalTrailerInfo.values.is_security_damaged)}
                                                            onChange={e => {
                                                                this.handleModalTrailerInfoChange('is_security_damaged', e.target.checked)
                                                            }}
                                                        />
                                                        <FormattedMessage id="fields.securityDamaged" />
                                                    </label>
                                                </div>
                                            )}
                                            {this.state.modalTrailerInfo.trailerType && this.state.modalTrailerInfo.trailerType.is_cooled === 1 && (
                                                <div className="form-group checkbox">
                                                    <label htmlFor="modalTrailerInfo-coolingDamaged">
                                                        <input
                                                            id="modalTrailerInfo-coolingDamaged"
                                                            type="checkbox"
                                                            checked={Boolean(this.state.modalTrailerInfo.values.is_cooling_damaged)}
                                                            onChange={e => {
                                                                this.handleModalTrailerInfoChange('is_cooling_damaged', e.target.checked)
                                                            }}
                                                        />
                                                        <FormattedMessage id="fields.coolingDamaged" />
                                                    </label>
                                                </div>
                                            )}
                                            <div className="form-group">
                                                <label htmlFor="modalTrailerInfo-current_position">
                                                    <FormattedMessage id="fields.currentPosition" />
                                                </label>
                                                <input
                                                    id="modalTrailerInfo-current_position"
                                                    type="text"
                                                    className="form-control"
                                                    value={this.state.modalTrailerInfo.values.current_position}
                                                    onChange={e => {
                                                        this.handleModalTrailerInfoChange('current_position', e.target.value)
                                                    }}
                                                />
                                            </div>
                                            <div className="btns-form text-right">
                                                <button type="submit" className="btn btn-success">
                                                    <FormattedMessage id="buttons.save" />
                                                </button>
                                            </div>
                                        </form>
                                    </LoadingOverlay>
                                </Modal.Body>
                            </React.Fragment>
                        )}
                    </Modal>

                    <Modal show={Boolean(this.state.modalLegend.show)} onHide={this.handleModalLegendClose}>
                        <React.Fragment>
                            <Modal.Header closeButton>
                                <Modal.Title>
                                    <FormattedMessage id="fields.legend" />
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="row board-legend">
                                    <div className="col-md-6">
                                        <table className="table table-striped table-hover">
                                            <tbody>
                                                <tr>
                                                    <td className="text-center w-40">
                                                        <i className="fas fa-circle text-gray-light" />
                                                    </td>
                                                    <td>
                                                        <FormattedMessage id="fields.vehicleState" /> - <FormattedMessage id="fields.all" />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="text-center">
                                                        <i className="fas fa-circle text-success" />
                                                    </td>
                                                    <td>
                                                        <FormattedMessage id="fields.vehicleState" /> - <FormattedMessage id="fields.vehicleIsMoving" />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="text-center">
                                                        <i className="fas fa-circle text-danger" />
                                                    </td>
                                                    <td>
                                                        <FormattedMessage id="fields.vehicleState" /> - <FormattedMessage id="fields.vehicleIsStanding" />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="text-center">
                                                        <i className="fas fa-circle text-default" />
                                                    </td>
                                                    <td>
                                                        <FormattedMessage id="fields.vehicleState" /> -{' '}
                                                        <FormattedMessage id="fields.informationUnavailable" />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <table className="table table-striped table-hover">
                                            <tbody>
                                                <tr>
                                                    <td className="text-center w-40">
                                                        <i className="fas fa-clock text-gray-light" />
                                                    </td>
                                                    <td>
                                                        <FormattedMessage id="fields.updatedAt" /> - <FormattedMessage id="fields.all" />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="text-center">
                                                        <i className="fas fa-clock text-success" />
                                                    </td>
                                                    <td>
                                                        <FormattedMessage id="fields.updatedAt" /> -{' '}
                                                        <FormattedMessage id="modules.board.updatedInLast2Hours" />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="text-center">
                                                        <i className="fas fa-clock text-danger" />
                                                    </td>
                                                    <td>
                                                        <FormattedMessage id="fields.updatedAt" /> -{' '}
                                                        <FormattedMessage id="modules.board.updated2HoursAgo" />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="text-center">
                                                        <i className="fas fa-clock text-default" />
                                                    </td>
                                                    <td>
                                                        <FormattedMessage id="fields.updatedAt" /> -{' '}
                                                        <FormattedMessage id="fields.informationUnavailable" />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <table className="table table-striped table-hover">
                                            <tbody>
                                                <tr>
                                                    <td className="text-center w-40">
                                                        <i className="fas fa-location-arrow text-gray-light" />
                                                    </td>
                                                    <td>
                                                        <FormattedMessage id="fields.etaUpdatedAt" /> - <FormattedMessage id="fields.all" />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="text-center">
                                                        <i className="fas fa-location-arrow text-success" />
                                                    </td>
                                                    <td>
                                                        <FormattedMessage id="fields.etaUpdatedAt" /> -{' '}
                                                        <FormattedMessage id="modules.board.updatedInLast2Hours" />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="text-center">
                                                        <i className="fas fa-location-arrow text-danger" />
                                                    </td>
                                                    <td>
                                                        <FormattedMessage id="fields.etaUpdatedAt" /> -{' '}
                                                        <FormattedMessage id="modules.board.updated2HoursAgo" />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="text-center">
                                                        <i className="fas fa-location-arrow text-default" />
                                                    </td>
                                                    <td>
                                                        <FormattedMessage id="fields.etaUpdatedAt" /> -{' '}
                                                        <FormattedMessage id="fields.informationUnavailable" />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <table className="table table-striped table-hover">
                                            <tbody>
                                                <tr>
                                                    <td className="text-center w-40">
                                                        <i className="fas fa-check text-gray-light" />
                                                    </td>
                                                    <td>
                                                        <FormattedMessage id="modules.board.planningState" /> - <FormattedMessage id="fields.all" />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="text-center">
                                                        <i className="fas fa-check text-success" />
                                                    </td>
                                                    <td>
                                                        <FormattedMessage id="modules.board.planningState" /> -{' '}
                                                        <FormattedMessage id="modules.board.isDone" />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="text-center">
                                                        <i className="fas fa-check text-danger" />
                                                    </td>
                                                    <td>
                                                        <FormattedMessage id="modules.board.planningState" /> -{' '}
                                                        <FormattedMessage id="modules.board.isNotDone" />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <table className="table table-striped table-hover">
                                            <tbody>
                                                <tr>
                                                    <td className="text-center w-40">
                                                        <i className="fas fa-truck text-gray-light" />
                                                    </td>
                                                    <td>
                                                        <FormattedMessage id="fields.trailerState" /> - <FormattedMessage id="fields.all" />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="text-center">
                                                        <i className="fas fa-truck text-success" />
                                                    </td>
                                                    <td>
                                                        <FormattedMessage id="fields.trailerState" /> - <FormattedMessage id="fields.trailerEmpty" />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="text-center">
                                                        <i className="fas fa-truck text-danger" />
                                                    </td>
                                                    <td>
                                                        <FormattedMessage id="fields.trailerState" /> - <FormattedMessage id="fields.trailerLoaded" />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="text-center">
                                                        <i className="fas fa-truck text-default" />
                                                    </td>
                                                    <td>
                                                        <FormattedMessage id="fields.trailerState" /> -{' '}
                                                        <FormattedMessage id="fields.informationUnavailable" />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <table className="table table-striped table-hover">
                                            <tbody>
                                                <tr>
                                                    <td className="text-center w-40">
                                                        <i className="fas fa-exclamation-circle text-gray-light" />
                                                    </td>
                                                    <td>
                                                        <FormattedMessage id="fields.trailerDamage" /> - <FormattedMessage id="fields.all" />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="text-center">
                                                        <i className="fas fa-exclamation-circle text-success" />
                                                    </td>
                                                    <td>
                                                        <FormattedMessage id="fields.trailerDamage" /> - <FormattedMessage id="fields.trailerOk" />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="text-center">
                                                        <i className="fas fa-exclamation-circle text-danger" />
                                                    </td>
                                                    <td>
                                                        <FormattedMessage id="fields.trailerDamage" /> - <FormattedMessage id="fields.trailerDamaged" />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="text-center">
                                                        <i className="fas fa-exclamation-circle text-default" />
                                                    </td>
                                                    <td>
                                                        <FormattedMessage id="fields.trailerDamage" /> -{' '}
                                                        <FormattedMessage id="fields.informationUnavailable" />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <table className="table table-striped table-hover">
                                            <tbody>
                                                <tr>
                                                    <td className="text-center w-40">
                                                        <i className="fas fa-lock text-gray-light" />
                                                    </td>
                                                    <td>
                                                        <FormattedMessage id="fields.security" /> - <FormattedMessage id="fields.all" />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="text-center">
                                                        <i className="fas fa-lock text-gray-darkest" />
                                                    </td>
                                                    <td>
                                                        <FormattedMessage id="fields.security" /> - <FormattedMessage id="fields.securedTrailers" />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="text-center">
                                                        <i className="fas fa-lock text-success" />
                                                    </td>
                                                    <td>
                                                        <FormattedMessage id="fields.security" /> - <FormattedMessage id="fields.securityOk" />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="text-center">
                                                        <i className="fas fa-lock text-danger" />
                                                    </td>
                                                    <td>
                                                        <FormattedMessage id="fields.security" /> - <FormattedMessage id="fields.securityDamaged" />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="text-center">
                                                        <i className="fas fa-lock text-default" />
                                                    </td>
                                                    <td>
                                                        <FormattedMessage id="fields.security" /> - <FormattedMessage id="fields.unsecuredTrailers" /> /{' '}
                                                        <FormattedMessage id="fields.informationUnavailable" />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <table className="table table-striped table-hover">
                                            <tbody>
                                                <tr>
                                                    <td className="text-center w-40">
                                                        <i className="fas fa-snowflake text-gray-light" />
                                                    </td>
                                                    <td>
                                                        <FormattedMessage id="fields.trailerCooling" /> - <FormattedMessage id="fields.all" />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="text-center">
                                                        <i className="fas fa-snowflake text-gray-darkest" />
                                                    </td>
                                                    <td>
                                                        <FormattedMessage id="fields.trailerCooling" /> - <FormattedMessage id="fields.cooledTrailers" />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="text-center">
                                                        <i className="fas fa-snowflake text-success" />
                                                    </td>
                                                    <td>
                                                        <FormattedMessage id="fields.trailerCooling" /> - <FormattedMessage id="fields.coolingOk" />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="text-center">
                                                        <i className="fas fa-snowflake text-danger" />
                                                    </td>
                                                    <td>
                                                        <FormattedMessage id="fields.trailerCooling" /> - <FormattedMessage id="fields.coolingDamaged" />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="text-center">
                                                        <i className="fas fa-snowflake text-default" />
                                                    </td>
                                                    <td>
                                                        <FormattedMessage id="fields.trailerCooling" /> -{' '}
                                                        <FormattedMessage id="fields.notCooledTrailers" /> /{' '}
                                                        <FormattedMessage id="fields.informationUnavailable" />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                    <div className="col-md-6">
                                        <table className="table table-striped table-hover">
                                            <tbody>
                                                {getPrerequisitesArray(
                                                    this.props.prerequisites.values.get('transport_states'),
                                                    'id',
                                                    'name',
                                                    this.props.intl,
                                                    'transportStates.'
                                                ).map(state => (
                                                    <tr key={`transport-state-${state.id}`}>
                                                        <td className="text-center w-40">
                                                            <i className={`transport-state-icon transport-state-icon-${state.id}`} />
                                                        </td>
                                                        <td>
                                                            <FormattedMessage id="fields.transportState" /> - {state.name}
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>

                                        <table className="table table-striped table-hover">
                                            <tbody>
                                                {getPrerequisitesArray(
                                                    this.props.prerequisites.values.get('transport_point_states'),
                                                    'id',
                                                    'name',
                                                    this.props.intl,
                                                    'transportPointStates.'
                                                ).map(state => (
                                                    <tr key={`transport-point-state-${state.id}`}>
                                                        <td className="text-center w-40">
                                                            <i
                                                                className={
                                                                    constants.TRANSPORT_POINT_STATES_DEFINITION[state.id] &&
                                                                    constants.TRANSPORT_POINT_STATES_DEFINITION[state.id].className
                                                                }
                                                            />
                                                        </td>
                                                        <td>
                                                            <FormattedMessage id="fields.transportPointState" /> - {state.name}
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>

                                        <table className="table table-striped table-hover">
                                            <tbody>
                                                {Object.keys(constants.TRANSPORT_POINT_TYPES_DEFINITION).map(typeId => {
                                                    const type = constants.TRANSPORT_POINT_TYPES_DEFINITION[typeId]

                                                    if (type.notAvailable) {
                                                        return null
                                                    }

                                                    return (
                                                        <tr key={`transport-point-type-${typeId}`}>
                                                            <td className="text-center w-40">
                                                                <i className="fas fa-circle" style={{ color: type.rgb_bg }} />
                                                            </td>
                                                            <td>
                                                                <FormattedMessage id="fields.transportPointType" /> -{' '}
                                                                {this.props.intl.formatMessage({ id: type.name })}
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </Modal.Body>
                        </React.Fragment>
                    </Modal>

                    <Modal show={Boolean(this.state.modalPreferences.show)} onHide={this.handleModalPreferencesClose} bsSize="sm">
                        <React.Fragment>
                            <Modal.Header closeButton>
                                <Modal.Title>
                                    <FormattedMessage id="fields.preferences" />
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <LoadingOverlay active={this.props.preferencesSaving}>
                                    <form onSubmit={this.handleModalPreferencesSubmit}>
                                        <div className="row board-legend">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="modalPreferences-days">
                                                        <FormattedMessage id="fields.show" />
                                                    </label>
                                                    <select
                                                        id="modalPreferences-days"
                                                        className="form-control"
                                                        value={this.state.modalPreferences.values && this.state.modalPreferences.values.days}
                                                        onChange={e => {
                                                            this.handleModalPreferencesChange('days', e.target.value)
                                                        }}
                                                    >
                                                        <option value="7">7 {this.props.intl.formatMessage({ id: 'fields.day4' })}</option>
                                                        <option value="14">14 {this.props.intl.formatMessage({ id: 'fields.day4' })}</option>
                                                        <option value="21">21 {this.props.intl.formatMessage({ id: 'fields.day4' })}</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <input
                                                        id="modalPreferences-showSpeditions"
                                                        type="checkbox"
                                                        checked={
                                                            this.state.modalPreferences.values &&
                                                            parseInt(this.state.modalPreferences.values.show_speditions) === 1
                                                        }
                                                        onChange={e => {
                                                            this.handleModalPreferencesChange('show_speditions', e.target.checked ? 1 : 0)
                                                        }}
                                                    />
                                                    <label htmlFor="modalPreferences-showSpeditions" className="m-l-xs">
                                                        <FormattedMessage id="fields.showSpeditions" />
                                                    </label>
                                                </div>
                                                <div className="form-group">
                                                    <input
                                                        id="modalPreferences-showTrailersOut"
                                                        type="checkbox"
                                                        checked={
                                                            this.state.modalPreferences.values &&
                                                            parseInt(this.state.modalPreferences.values.show_trailers_out) === 1
                                                        }
                                                        onChange={e => {
                                                            this.handleModalPreferencesChange('show_trailers_out', e.target.checked ? 1 : 0)
                                                        }}
                                                    />
                                                    <label htmlFor="modalPreferences-showTrailersOut" className="m-l-xs">
                                                        <FormattedMessage id="fields.showTrailersOut" />
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <table className="table table-striped table-hover">
                                                    <tbody>
                                                        <tr>
                                                            <td colSpan={2} />
                                                            <td className="text-center w-40">
                                                                <input
                                                                    type="checkbox"
                                                                    checked={this.state.modalPreferences.isAllTypesSelected === 1}
                                                                    onChange={e => {
                                                                        this.handleModalPreferencesSelectAllTypes(e.target.checked ? 1 : 0)
                                                                    }}
                                                                />
                                                            </td>
                                                        </tr>

                                                        {Object.keys(constants.TRANSPORT_POINT_TYPES_DEFINITION).map(typeId => {
                                                            const type = constants.TRANSPORT_POINT_TYPES_DEFINITION[typeId]

                                                            if (type.notAvailable) {
                                                                return null
                                                            }

                                                            return (
                                                                <tr key={`preferences-transport-point-type-${typeId}`}>
                                                                    <td className="text-center w-40">
                                                                        <i className="fas fa-circle" style={{ color: type.rgb_bg }} />
                                                                    </td>
                                                                    <td>
                                                                        <FormattedMessage id="fields.transportPointType" /> -{' '}
                                                                        {this.props.intl.formatMessage({ id: type.name })}
                                                                    </td>
                                                                    <td className="text-center w-40">
                                                                        <input
                                                                            type="checkbox"
                                                                            checked={
                                                                                this.state.modalPreferences.values &&
                                                                                parseInt(
                                                                                    this.state.modalPreferences.values[
                                                                                        `show_transport_point_type_${typeId}`
                                                                                    ]
                                                                                ) === 1
                                                                            }
                                                                            onChange={e => {
                                                                                this.handleModalPreferencesChange(
                                                                                    `show_transport_point_type_${typeId}`,
                                                                                    e.target.checked ? 1 : 0
                                                                                )
                                                                            }}
                                                                        />
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className="btns-form text-right">
                                            <button type="submit" className="btn btn-success">
                                                <FormattedMessage id="buttons.save" />
                                            </button>
                                        </div>
                                    </form>
                                </LoadingOverlay>
                            </Modal.Body>
                        </React.Fragment>
                    </Modal>

                    <Modal show={Boolean(this.state.modalTransportPreview.show)} onHide={this.handleModalTransportPreviewClose}>
                        <React.Fragment>
                            <Modal.Header closeButton />
                            <Modal.Body style={{ marginTop: '-30px' }}>
                                <TransportsPreview
                                    transportId={this.state.modalTransportPreview.transportId}
                                    transportPointId={this.state.modalTransportPreview.transportPointId}
                                />
                            </Modal.Body>
                        </React.Fragment>
                    </Modal>
                </div>
            </PermissionsCheck>
        )
    }
}

function mapStateToProps(state) {
    return {
        tableModel: createTableModelSelector(tableIdentifier)(state),
        data: getBoardData(state),
        dataSpeditions: getBoardDataSpeditions(state),
        dataTrailers: getBoardDataTrailers(state),
        date: getDate(state),
        vehicleNote: getVehicleNote(state),
        trailerNote: getTrailerNote(state),
        trailerInfo: getTrailerInfo(state),
        dailyNote: getDailyNote(state),
        dailyInfo: getDailyInfo(state),
        weeklyInfo: getWeeklyInfo(state),
        fetching: getFetching(state),
        modalSaving: getModalSaving(state),
        prerequisites: preparePrerequisitesFetchStatusSelectors(componentIdentifier, prerequisites, state),
        currentUserId: getCurrentUserId(state),
        preferences: getPreferences(state),
        pointNoteSaving: getPointNoteSaving(state),
        pointNoteError: getPointNoteError(state),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            ...bindActionCreators(
                {
                    ...boardActionCreators,
                    ...preparePrerequisitesActionCreators(componentIdentifier, prerequisites),
                },
                dispatch
            ),
        },
        notify: (notification, type) => dispatch(Notifications.show(notification, type)),
        dispatch,
    }
}

function mergeProps(stateProps, dispatchProps, ownProps) {
    return {
        ...stateProps,
        ...dispatchProps,
        ...ownProps,
        table: bindActionCreators(prepareTableActionCreators(tableIdentifier, stateProps.tableModel), dispatchProps.dispatch),
    }
}

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps,
        mergeProps
    )(Board)
)
