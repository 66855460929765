import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { push } from 'react-router-redux'
import Notifications from 'react-notification-system-redux'
import validator from 'validator'
import { Modal, Tabs, Tab } from 'react-bootstrap'

import { ConfirmDialog } from '../../../common/confirm_dialog'
import * as formatters from '../../../common/formatters'
import { Form, InputField, SelectField, CheckboxField, SubmitButton } from '../../../common/form'
import { url, handleCommonEditActions, validationMessage, getPrerequisitesArray } from '../../../common/helpers'
import { LoadingOverlay } from '../../../common/loading_overlay'

import { preparePrerequisitesActionCreators } from '../../../common/prerequisites/actionCreators'
import { preparePrerequisitesFetchStatusSelectors } from '../../../common/prerequisites/selectors'

import * as driversActionCreators from '../actionCreators'
import { createGetDriverByIDSelector, createGetCurrentDriverStatusSelector, createGetDuplicitiesSelector } from '../selectors'

import DriversDocumentsList from './DriversDocumentsList'
import DriversEquipmentsList from './DriversEquipmentsList'
import DriversEventsList from './DriversEventsList'
import DriversTimesheetsList from './DriversTimesheetsList'
import DriversHistoryList from './DriversHistoryList'
import DriversAllowances from './DriversAllowances'
import PermissionsCheck from '../../auth/components/PermissionsCheck'
import { PERMS } from '../permissions'

const componentIdentifier = 'drivers_edit'
const prerequisites = ['users', 'company_cost_centers', 'driverLocales', 'driversTypes']

class DriversEdit extends Component {
    defaultValues = {}

    validationRules = {
        last_name: value => validator.isEmpty(String(value)) && validationMessage('isEmpty'),
        first_name: value => validator.isEmpty(String(value)) && validationMessage('isEmpty'),
    }

    state = {
        values: this.defaultValues,
        modalDuplicity: {
            show: false,
            duplicities: null,
        },
        driverHistory: {
            show: true,
        },
        driverDocuments: {
            show: false,
        },
        driverEquipments: {
            show: false,
        },
        driverEvents: {
            show: false,
        },
        driverTimesheets: {
            show: false,
        },
        driverAllowances: {
            show: false,
        },
        confirmDialog: {
            show: false,
            title: '',
            message: '',
            labelCancel: '',
            labelAccept: '',
            classNameAccept: 'text-success',
            classNameCancel: 'text-default',
            onCancel: () => {},
            onAccept: () => {},
        },
    }

    setValues = (values, callback) => {
        this.setState(
            {
                values,
            },
            callback
        )
    }

    handleChangeValues = values => {
        this.setValues(values)
    }

    handleSubmit = values => {
        this.setValues(values, () => {
            this.save(false)
        })
    }

    save = ignoreDuplicity => {
        this.props.actions.saveDriver(this.state.values, ignoreDuplicity)
    }

    duplicityFoundCallback = duplicities => {
        this.setState({
            modalDuplicity: {
                show: true,
                duplicities,
            },
        })
    }

    handleDuplicityCancel = () => {
        this.setState({
            modalDuplicity: {
                show: false,
                duplicities: null,
            },
        })
    }

    handleDuplicityConfirm = () => {
        this.handleDuplicityCancel()
        this.save(true)
    }

    handleSelectTab = tabKey => {
        if (tabKey === 1) {
            this.loadDriverHistory()
        } else if (tabKey === 2) {
            this.loadDriverDocuments()
        } else if (tabKey === 3) {
            this.loadDriverEquipments()
        } else if (tabKey === 4) {
            this.loadDriverEvents()
        } else if (tabKey === 5) {
            this.loadDriverTimesheets()
        } else if (tabKey === 6) {
            this.loadDriverAllowances()
        }
    }

    loadDriverHistory = () => {
        this.setState({
            driverHistory: {
                show: true,
            },
        })
    }

    loadDriverDocuments = () => {
        this.setState({
            driverDocuments: {
                show: true,
            },
        })
    }

    loadDriverEquipments = () => {
        this.setState({
            driverEquipments: {
                show: true,
            },
        })
    }

    loadDriverEvents = () => {
        this.setState({
            driverEvents: {
                show: true,
            },
        })
    }

    loadDriverTimesheets = () => {
        this.setState({
            driverTimesheets: {
                show: true,
            },
        })
    }

    loadDriverAllowances = () => {
        this.setState({
            driverAllowances: {
                show: true,
            },
        })
    }

    successCallback = null

    handleSave = () => {
        this.successCallback = (props, nextProps) => {
            if (nextProps.status.get('id')) {
                props.redirect(url(props.match, `drivers/${nextProps.status.get('id')}`))
            } else {
                props.redirect(url(props.match, 'drivers'))
            }
        }

        this.save()
    }

    handleSaveAndClose = () => {
        this.successCallback = props => {
            props.redirect(url(props.match, 'drivers'))
        }

        this.save()
    }

    // restore
    handleRestore = () => {
        this.showConfirmDialog({
            title: this.props.intl.formatMessage({ id: 'confirmDialog.itemRestore.title' }),
            message: this.props.intl.formatMessage({ id: 'confirmDialog.itemRestore.message' }),
            labelCancel: this.props.intl.formatMessage({ id: 'confirmDialog.itemRestore.cancel' }),
            labelAccept: this.props.intl.formatMessage({ id: 'confirmDialog.itemRestore.accept' }),
            classNameAccept: 'btn-success',
            onAccept: () => {
                this.props.actions.restoreDriver(this.props.driver.id)
                this.hideConfirmDialog()
            },
        })
    }

    // confirm dialog
    showConfirmDialog = options => {
        this.setState({
            confirmDialog: {
                show: true,
                title: options.title || this.props.intl.formatMessage({ id: 'confirmDialog.default.title' }),
                message: options.message || this.props.intl.formatMessage({ id: 'confirmDialog.default.message' }),
                labelCancel: options.labelCancel || this.props.intl.formatMessage({ id: 'confirmDialog.default.cancel' }),
                labelAccept: options.labelAccept || this.props.intl.formatMessage({ id: 'confirmDialog.default.accept' }),
                classNameAccept: options.classNameAccept || 'btn-success',
                classNameCancel: options.classNameCancel || 'btn-default',
                onCancel: options.onCancel || this.hideConfirmDialog,
                onAccept: options.onAccept || this.hideConfirmDialog,
            },
        })
    }

    hideConfirmDialog = () => {
        this.setState({
            confirmDialog: {
                show: false,
                onCancel: () => {},
                onAccept: () => {},
            },
        })
    }

    componentDidMount() {
        this.props.match.params.driverID && this.props.actions.fetchDriver(this.props.match.params.driverID)
        this.props.actions.fetchPrerequisites()
    }

    componentWillReceiveProps(nextProps) {
        handleCommonEditActions(this.props, nextProps, this.successCallback, this.duplicityFoundCallback)

        if (JSON.stringify(nextProps.driver) !== JSON.stringify(this.props.driver) || (nextProps.driver && !this.state.values.id)) {
            this.setValues(nextProps.driver.toJS())
        }
    }

    render() {
        document.title = formatters.titleFormatter(this.props.intl.formatMessage({ id: 'modules.drivers.heading' }))

        const driver = this.props.driver
        const duplicities = this.props.duplicities

        const mainColClassName = driver ? 'col-lg-4' : 'col-md-8 col-md-offset-2 col-lg-6 col-lg-offset-3 col-xl-4 col-xl-offset-4'

        return (
            <PermissionsCheck hasAny={Object.values(PERMS)} noPermissionsPage>
                <div className="page-inner">
                    <div id="main-wrapper">
                        <div className="row hp-100">
                            <div className={mainColClassName} style={{ paddingRight: '0' }}>
                                <div className="panel panel-white">
                                    <div className="panel-body panel-padding">
                                        <div className="panel-head">
                                            <h4>
                                                <FormattedMessage id="modules.drivers.heading" /> -{' '}
                                                {this.props.driver ? (
                                                    <FormattedMessage id="fields.itemEdit" />
                                                ) : (
                                                    <FormattedMessage id="fields.itemCreate" />
                                                )}
                                            </h4>
                                        </div>
                                        <LoadingOverlay
                                            active={
                                                this.props.prerequisites.status.get('fetching') ||
                                                this.props.status.get('fetching') ||
                                                this.props.status.get('saving')
                                            }
                                        >
                                            <PermissionsCheck hasAny={Object.values(PERMS)}>
                                                <Form
                                                    values={this.state.values}
                                                    validationRules={this.validationRules}
                                                    onChange={this.handleChangeValues}
                                                    onSubmit={this.handleSubmit}
                                                    isEdit={Boolean(this.props.driver)}
                                                >
                                                    {driver && driver.deleted_at && (
                                                        <div className="row">
                                                            <div className="col-lg-12">
                                                                <div className="alert alert-danger m-b-lg">
                                                                    <div className="row">
                                                                        <div className="col-sm-8">
                                                                            <p className="no-m">
                                                                                <FormattedMessage id="alerts.messages.warningDeleted" />
                                                                            </p>
                                                                        </div>
                                                                        <PermissionsCheck has={[PERMS.RESTORE]}>
                                                                            <div className="col-sm-4 text-right">
                                                                                <button onClick={this.handleRestore} type="button" className="btn btn-danger">
                                                                                    <FormattedMessage id="buttons.restore" />
                                                                                </button>
                                                                            </div>
                                                                        </PermissionsCheck>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                    <div className="row">
                                                        <div className="col-sm-6 col-lg-12 col-xl-6">
                                                            <InputField
                                                                id="last_name"
                                                                label={this.props.intl.formatMessage({ id: 'fields.lastName' })}
                                                                formGroupClassName="form-group-bigger"
                                                            />
                                                        </div>
                                                        <div className="col-sm-6 col-lg-12 col-xl-6">
                                                            <InputField
                                                                id="first_name"
                                                                label={this.props.intl.formatMessage({ id: 'fields.firstName' })}
                                                                formGroupClassName="form-group-bigger"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-6 col-lg-12 col-xl-6">
                                                            <InputField
                                                                id="card_number"
                                                                label={this.props.intl.formatMessage({ id: 'fields.cardNumber' })}
                                                            />
                                                        </div>
                                                        <div className="col-sm-6 col-lg-12 col-xl-6">
                                                            <SelectField
                                                                id="dispatcher_id"
                                                                label={this.props.intl.formatMessage({ id: 'fields.dispatcher' })}
                                                                values={getPrerequisitesArray(this.props.prerequisites.values.get('users'))}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-6 col-lg-12 col-xl-6">
                                                            <InputField id="code" label={this.props.intl.formatMessage({ id: 'fields.code' })} />
                                                        </div>
                                                        <div className="col-sm-6 col-lg-12 col-xl-6">
                                                            <InputField id="phone" label={this.props.intl.formatMessage({ id: 'fields.phone' })} />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-6 col-lg-12 col-xl-6">
                                                            <SelectField
                                                                id="drivers_type_id"
                                                                label={this.props.intl.formatMessage({ id: 'fields.type' })}
                                                                values={getPrerequisitesArray(this.props.prerequisites.values.get('driversTypes'))}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-6 col-lg-12 col-xl-6">
                                                            <SelectField
                                                                id="company_cost_center_id"
                                                                label={this.props.intl.formatMessage({ id: 'fields.costCenter' })}
                                                                values={getPrerequisitesArray(
                                                                    this.props.prerequisites.values.get('company_cost_centers')
                                                                )}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-3 col-lg-6 col-xl-3">
                                                            <SelectField
                                                                id="locale"
                                                                label={this.props.intl.formatMessage({ id: 'fields.language' })}
                                                                values={getPrerequisitesArray(
                                                                    this.props.prerequisites.values.get('driverLocales'),
                                                                    'locale',
                                                                    'locale'
                                                                )}
                                                            />
                                                        </div>
                                                    </div>
                                                    <CheckboxField id="is_active" label={this.props.intl.formatMessage({ id: 'fields.active' })} />

                                                    <div className="btns-form">
                                                        <Link to={url(this.props.match, 'drivers')} className="btn btn-addon btn-default">
                                                            <i className="far fa-chevron-left" /> <FormattedMessage id="buttons.back" />
                                                        </Link>
                                                        {driver && (
                                                            <SubmitButton
                                                                saveAndClose
                                                                onClick={this.handleSaveAndClose}
                                                                isEdit={Boolean(this.props.driver)}
                                                                perms={[PERMS.CREATE, PERMS.UPDATE]}
                                                                className="btn-addon pull-right"
                                                            />
                                                        )}
                                                        <SubmitButton
                                                            type="button"
                                                            onClick={this.handleSave}
                                                            isEdit={Boolean(this.props.driver)}
                                                            perms={[PERMS.CREATE, PERMS.UPDATE]}
                                                            className="btn-addon pull-right m-r-xs"
                                                        />
                                                    </div>
                                                </Form>
                                            </PermissionsCheck>
                                        </LoadingOverlay>
                                    </div>
                                </div>
                            </div>

                            {driver && (
                                <div className="col-lg-8 hp-100" style={{ paddingLeft: '5px' }}>
                                    <div className="panel panel-white hp-100 m-b-no">
                                        <div className="panel-body panel-padding hp-100">
                                            <Tabs
                                                defaultActiveKey={1}
                                                animation={false}
                                                id="detail-tabs"
                                                className="detail-tabs"
                                                onSelect={this.handleSelectTab}
                                            >
                                                <Tab
                                                    eventKey={1}
                                                    title={
                                                        <span>
                                                            <i className="far fa-history m-r-xxs" />
                                                            {this.props.intl.formatMessage({ id: 'fields.history' })}
                                                        </span>
                                                    }
                                                >
                                                    {this.state.driverHistory.show && <DriversHistoryList driver={driver} />}
                                                </Tab>
                                                <Tab
                                                    eventKey={2}
                                                    title={
                                                        <span>
                                                            <i className="far fa-file-alt m-r-xxs" />
                                                            {this.props.intl.formatMessage({ id: 'fields.documents' })}
                                                        </span>
                                                    }
                                                >
                                                    {this.state.driverDocuments.show && <DriversDocumentsList driver={driver} />}
                                                </Tab>
                                                <Tab
                                                    eventKey={3}
                                                    title={
                                                        <span>
                                                            <i className="far fa-wrench m-r-xxs" />
                                                            {this.props.intl.formatMessage({ id: 'fields.equipment' })}
                                                        </span>
                                                    }
                                                >
                                                    {this.state.driverEquipments.show && <DriversEquipmentsList driver={driver} />}
                                                </Tab>
                                                <Tab
                                                    eventKey={4}
                                                    title={
                                                        <span>
                                                            <i className="far fa-calendar-alt m-r-xxs" />
                                                            {this.props.intl.formatMessage({ id: 'fields.events' })}
                                                        </span>
                                                    }
                                                >
                                                    {this.state.driverEvents.show && <DriversEventsList driver={driver} />}
                                                </Tab>
                                                <Tab
                                                    eventKey={5}
                                                    title={
                                                        <span>
                                                            <i className="far fa-calendar-alt m-r-xxs" />
                                                            {this.props.intl.formatMessage({ id: 'fields.timesheets' })}
                                                        </span>
                                                    }
                                                >
                                                    {this.state.driverTimesheets.show && <DriversTimesheetsList driver={driver} />}
                                                </Tab>
                                                <Tab
                                                    eventKey={6}
                                                    title={
                                                        <span>
                                                            <i className="far fa-money-bill m-r-xxs" />
                                                            {this.props.intl.formatMessage({ id: 'fields.allowances' })}
                                                        </span>
                                                    }
                                                >
                                                    {this.state.driverAllowances.show && <DriversAllowances driver={driver} />}
                                                </Tab>
                                            </Tabs>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>

                    <Modal show={Boolean(this.state.modalDuplicity.show)} onHide={this.handleDuplicityCancel} className="modal-size-md">
                        <Modal.Header closeButton>
                            <Modal.Title>
                                <FormattedMessage id="confirmDialog.duplicityFound.title" />
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <FormattedMessage id="confirmDialog.duplicityFound.message" />
                            <table className="table table-striped table-hover m-t-lg">
                                <thead>
                                    <tr>
                                        <th className="w-200">
                                            <FormattedMessage id="fields.lastName" />
                                        </th>
                                        <th className="w-200">
                                            <FormattedMessage id="fields.firstName" />
                                        </th>
                                        <th className="w-max">
                                            <FormattedMessage id="fields.phone" />
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {duplicities &&
                                        duplicities.length &&
                                        duplicities.map(duplicity => (
                                            <tr key={`duplicity-${duplicity.id}`}>
                                                <td>{duplicity.last_name}</td>
                                                <td>{duplicity.first_name}</td>
                                                <td>{duplicity.phone}</td>
                                            </tr>
                                        ))}
                                </tbody>
                            </table>
                        </Modal.Body>
                        <Modal.Footer>
                            <button className="btn btn-success m-r-xs" onClick={this.handleDuplicityConfirm}>
                                <FormattedMessage id="confirmDialog.duplicityFound.accept" />
                            </button>
                            <button className="btn btn-default" onClick={this.handleDuplicityCancel}>
                                <FormattedMessage id="confirmDialog.duplicityFound.cancel" />
                            </button>
                        </Modal.Footer>
                    </Modal>
                </div>
                <ConfirmDialog options={this.state.confirmDialog} />
            </PermissionsCheck>
        )
    }
}

function mapStateToProps(state, ownProps) {
    return {
        driver: createGetDriverByIDSelector(ownProps.match.params.driverID)(state),
        status: createGetCurrentDriverStatusSelector()(state),
        duplicities: createGetDuplicitiesSelector()(state),
        prerequisites: preparePrerequisitesFetchStatusSelectors(componentIdentifier, prerequisites, state),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            ...bindActionCreators(
                {
                    ...driversActionCreators,
                    ...preparePrerequisitesActionCreators(componentIdentifier, prerequisites),
                },
                dispatch
            ),
        },
        redirect: toURL => dispatch(push(toURL)),
        notify: (notification, type) => dispatch(Notifications.show(notification, type)),
    }
}

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(DriversEdit)
)
