import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import ReactPaginate from 'react-paginate'
import Notifications from 'react-notification-system-redux'
import { Tooltip, OverlayTrigger, Modal } from 'react-bootstrap'

import * as formatters from '../../../common/formatters'

import { getListItemsCountMessage, getPrerequisitesArray } from '../../../common/helpers'

import { prepareTableActionCreators } from '../../../common/table/actionCreators'
import { TableSortButtons, TableFilterInputField, TableFilterSelectField, TableFilterCheckboxField } from '../../../common/table/components'
import { getActiveFilters, getTotalNumberOfPages } from '../../../common/table/helpers'
import { TableModelColumn, TableModelColumnFilteringType } from '../../../common/table/model'
import { createTableDataSelector, createTableModelSelector } from '../../../common/table/selectors'

import { preparePrerequisitesFetchStatusSelectors } from '../../../common/prerequisites/selectors'
import { preparePrerequisitesActionCreators } from '../../../common/prerequisites/actionCreators'

import * as actionCreators from '../actionCreators'
import { getData, getInvoicingStateSettingError, getInvoicingStateSettingInProgress } from '../selectors'

import InvoicingOrdersRow from './InvoicingOrdersRow'
import CreateInvoiceForm from './CreateInvoiceForm'
import InvoiceDetail from '../../invoicing_invoices/components/InvoiceDetail'
import PermissionsCheck from '../../auth/components/PermissionsCheck'
import { PERMS } from '../permissions'

const tableIdentifier = 'invoicing_orders_list'
const clientSideItemsPerPage = undefined

const componentIdentifier = 'invoicing_orders_list'
const prerequisites = ['cargo_types', 'dispatchers', 'active_drivers']

const columns = {
    transport_id: new TableModelColumn({}),
}

class InvoicingOrders extends Component {
    state = {
        selectedIds: [],
        modalCreateInvoice: {
            show: false,
        },
        modalInvoiceDetail: {
            show: false,
            invoiceId: null,
        },
    }

    handleExport = e => {
        e && e.preventDefault()
        this.props.actions.exportInvoicingOrders(this.props.tableModel.get('filters'), this.props.tableModel.get('sorting').toJS())
    }

    handleChangeThreeWaySwitchFilter = (e, identifier) => {
        const filters = this.props.tableModel.get('filters')
        const currentValue = filters.getIn([identifier, 'value']) || 0
        const switchOptions = {
            0: 1,
            1: 2,
            2: 0,
        }

        this.props.table.changeFilter(identifier, TableModelColumnFilteringType.equal, switchOptions[currentValue])
    }

    onChangeInvoicingState = (transportCustomer, invoicingStateId) => {
        this.props.actions.setInvoicingState(transportCustomer.id, invoicingStateId, this.props.tableModel.get('filters'))
    }

    onSelectionToggle = transportCustomer => {
        const selectedIds = [...this.state.selectedIds]
        const index = selectedIds.indexOf(transportCustomer.id)

        if (index === -1) {
            selectedIds.push(transportCustomer.id)
        } else {
            selectedIds.splice(index, 1)
        }

        this.setState({
            selectedIds,
        })
    }

    onInvoiceClick = invoiceId => {
        this.setState({
            modalInvoiceDetail: {
                show: true,
                invoiceId,
            },
        })
    }

    handleCreateInvoiceFromSelected = () => {
        this.setState({
            modalCreateInvoice: {
                show: true,
            },
        })
    }


    handleSetAsInvoicedFromSelected = e => {
        e && e.preventDefault()
        this.props.actions.setAsInvoiced(this.state.selectedIds, this.props.tableModel.get('filters'), this.props.tableModel.get('sorting').toJS())
    }

    handleCreateInvoiceCancel = () => {
        this.setState({
            modalCreateInvoice: {
                show: false,
            },
        })
    }

    handleInvoiceDetailCancel = () => {
        this.setState({
            modalInvoiceDetail: {
                show: false,
            },
        })
    }

    onInvoiceCreateSuccess = createdInvoiceId => {
        this.setState({
            selectedIds: [],
            modalCreateInvoice: {
                show: false,
            },
            modalInvoiceDetail: {
                invoiceId: createdInvoiceId,
            },
        })
    }

    refresh = () => {
        this.props.actions.fetchInvoicingOrders(this.props.tableModel.get('filters'))
    }

    resetFilters = () => {
        this.props.table.resetFilters()
    }

    isFilterActive = () => getActiveFilters(this.props.tableModel).size > 0

    componentDidMount() {
        this.props.actions.fetchInvoicingOrders(this.props.tableModel.get('filters'))
        this.props.actions.fetchPrerequisites()
    }

    componentWillReceiveProps(nextProps) {
        // Set invoicing state
        if (this.props.invoicingStateSettingInProgress && !nextProps.invoicingStateSettingInProgress) {
            if (nextProps.invoicingStateSettingError === null) {
                this.props.notify(
                    {
                        title: this.props.intl.formatMessage({ id: 'alerts.titles.success' }),
                        message: this.props.intl.formatMessage({ id: 'alerts.messages.changesSaveSuccess' }),
                        position: 'tc',
                    },
                    'success'
                )
            } else {
                this.props.notify(
                    {
                        title: this.props.intl.formatMessage({ id: 'alerts.titles.error' }),
                        message: nextProps.invoicingStateSettingError,
                        position: 'tc',
                    },
                    'error'
                )
            }
        }
    }

    /* eslint-disable jsx-a11y/anchor-is-valid */
    render() {
        document.title = formatters.titleFormatter(this.props.intl.formatMessage({ id: 'modules.invoicingOrders.heading' }))

        const sorting = this.props.tableModel.get('sorting')
        const filters = this.props.tableModel.get('filters')

        const driversOptions = getPrerequisitesArray(this.props.prerequisites.values.get('active_drivers'))
        driversOptions.unshift({
            id: 'no_driver',
            name: this.props.intl.formatMessage({ id: 'fields.noDriver' }),
        })

        const tooltipNoTime = <Tooltip id="tooltipNoTime">{this.props.intl.formatMessage({ id: 'fields.noTime' })}</Tooltip>
        const tooltipNoVehicle = <Tooltip id="tooltipNoVehicle">{this.props.intl.formatMessage({ id: 'fields.noVehicle' })}</Tooltip>
        const tooltipNoTrailer = <Tooltip id="tooltipNoTrailer">{this.props.intl.formatMessage({ id: 'fields.noTrailer' })}</Tooltip>

        const threeWaySwitchOptions = {
            0: 'text-gray-lighter',
            1: 'text-gray-dark',
            2: 'text-success',
        }

        const filterIconFilesClassName = threeWaySwitchOptions[filters.getIn(['files', 'value']) || 0]

        const invoicingStateOptions = [
            { id: 0, name: this.props.intl.formatMessage({ id: 'invoicingStates.0' }) },
            { id: 1, name: this.props.intl.formatMessage({ id: 'invoicingStates.1' }) },
            { id: 2, name: this.props.intl.formatMessage({ id: 'invoicingStates.2' }) },
        ]

        return (
            <PermissionsCheck hasAny={Object.values(PERMS)} noPermissionsPage>
                <div className="page-inner">
                    <div id="main-wrapper">
                        <div className="panel panel-white">
                            <div className="panel-body">
                                <h4>
                                    <FormattedMessage id="modules.invoicingOrders.heading" />
                                </h4>

                                <div className="btns-list">
                                    {this.isFilterActive() > 0 && (
                                        <button className="btn btn-default btn-addon m-r-xs" onClick={this.resetFilters}>
                                            <i className="far fa-times" /> <FormattedMessage id="buttons.resetFilters" />
                                        </button>
                                    )}
                                    <button className="btn btn-default btn-addon m-r-xs" onClick={this.refresh}>
                                        <i className="far fa-sync-alt" /> <FormattedMessage id="buttons.refresh" />
                                    </button>
                                    {this.state.selectedIds.length > 0 && (
                                        <PermissionsCheck has={[PERMS.CREATE]}>
                                            <button className="btn btn-success btn-addon m-r-xs" onClick={this.handleCreateInvoiceFromSelected}>
                                                <i className="far fa-file-alt" /> <FormattedMessage id="buttons.createInvoice" /> (
                                                {this.state.selectedIds.length})
                                            </button>
                                        </PermissionsCheck>
                                    )}
                                    {this.state.selectedIds.length > 0 && (
                                        <PermissionsCheck has={[PERMS.CREATE]}>
                                            <button className="btn btn-success btn-addon m-r-xs" onClick={this.handleSetAsInvoicedFromSelected}>
                                                <i className="far fa-file-alt" /> <FormattedMessage id="buttons.setAsInvoiced" /> (
                                                {this.state.selectedIds.length})
                                            </button>
                                        </PermissionsCheck>
                                    )}
                                    <PermissionsCheck has={[PERMS.EXPORT]}>
                                        <button className="btn btn-primary btn-addon" onClick={this.handleExport}>
                                            <i className="far fa-file-excel" /> <FormattedMessage id="buttons.exportToXLS" />
                                        </button>
                                    </PermissionsCheck>
                                </div>

                                <div className="table-container">
                                    <table className="table table-striped table-hover table-fixed-header table-transports">
                                        <thead>
                                            <tr>
                                                <PermissionsCheck has={[PERMS.CREATE]}>
                                                    <th className="w-60" />
                                                </PermissionsCheck>
                                                <th className="w-100">
                                                    <FormattedMessage id="fields.number" />
                                                    <TableSortButtons
                                                        identifier="transport_id"
                                                        sorting={sorting}
                                                        onChange={this.props.table.changeSorting}
                                                    />
                                                    <br />
                                                    <small>
                                                        <FormattedMessage id="fields.createdAt" />
                                                        <TableSortButtons
                                                            identifier="created_at"
                                                            sorting={sorting}
                                                            onChange={this.props.table.changeSorting}
                                                        />
                                                    </small>
                                                </th>
                                                <th className="w-120">
                                                    <FormattedMessage id="fields.invoicingState" />
                                                </th>
                                                <th className="w-max wm-300">
                                                    <FormattedMessage id="fields.customer" />
                                                    <br />
                                                    <small>
                                                        <FormattedMessage id="fields.orderNumber" />
                                                    </small>
                                                </th>
                                                <th className="w-120 text-right">
                                                    <FormattedMessage id="fields.totalPrice" />
                                                    <TableSortButtons
                                                        identifier="price"
                                                        sorting={sorting}
                                                        onChange={this.props.table.changeSorting}
                                                    />
                                                </th>
                                                <th className="w-30" />
                                                <th className="w-400">
                                                    <FormattedMessage id="fields.loading" />
                                                    <TableSortButtons
                                                        identifier="loading"
                                                        sorting={sorting}
                                                        onChange={this.props.table.changeSorting}
                                                    />
                                                </th>
                                                <th className="w-400">
                                                    <FormattedMessage id="fields.unloading" />
                                                </th>
                                                <th className="w-120">
                                                    <FormattedMessage id="fields.vehicle" />
                                                </th>
                                                <th className="w-120">
                                                    <FormattedMessage id="fields.trailer" />
                                                </th>
                                            </tr>
                                            <tr className="filters">
                                                <PermissionsCheck has={[PERMS.CREATE]}>
                                                    <th className="w-60" />
                                                </PermissionsCheck>
                                                <th className="w-100">
                                                    <TableFilterInputField
                                                        identifier="transport_id"
                                                        type={TableModelColumnFilteringType.string}
                                                        filters={filters}
                                                        onChange={this.props.table.changeFilter}
                                                    />
                                                </th>
                                                <th className="w-120">
                                                    <TableFilterSelectField
                                                        identifier="invoicing_state_id"
                                                        type={TableModelColumnFilteringType.equal}
                                                        filters={filters}
                                                        onChange={this.props.table.changeFilter}
                                                        values={invoicingStateOptions}
                                                    />
                                                </th>
                                                <th className="w-max">
                                                    <div>
                                                        <TableFilterInputField
                                                            identifier="customer"
                                                            type={TableModelColumnFilteringType.string}
                                                            filters={filters}
                                                            onChange={this.props.table.changeFilter}
                                                            placeholder={this.props.intl.formatMessage({ id: 'fields.customer' })}
                                                        />
                                                    </div>
                                                    <div className="w-160 m-t-xxs">
                                                        <TableFilterInputField
                                                            identifier="order_number"
                                                            type={TableModelColumnFilteringType.string}
                                                            filters={filters}
                                                            onChange={this.props.table.changeFilter}
                                                            placeholder={this.props.intl.formatMessage({ id: 'fields.orderNumber' })}
                                                        />
                                                    </div>
                                                </th>
                                                <th className="w-120" />
                                                <th className="w-30 text-center">
                                                    <a
                                                        href="#"
                                                        className="m-t-xxs display-inline p-h-xxs"
                                                        onClick={e => {
                                                            this.handleChangeThreeWaySwitchFilter(e, 'files')
                                                        }}
                                                    >
                                                        <i className={`fas fa-file ${filterIconFilesClassName}`} />
                                                    </a>
                                                </th>
                                                <th className="w-400">
                                                    <TableFilterInputField
                                                        identifier="loading_address"
                                                        type={TableModelColumnFilteringType.string}
                                                        filters={filters}
                                                        onChange={this.props.table.changeFilter}
                                                        placeholder={this.props.intl.formatMessage({ id: 'fields.address' })}
                                                    />
                                                    <div className="m-t-xxs w-90 pull-left m-r-xxs">
                                                        <TableFilterInputField
                                                            identifier="loading_date_from"
                                                            type={TableModelColumnFilteringType.string}
                                                            filters={filters}
                                                            onChange={this.props.table.changeFilter}
                                                            placeholder={this.props.intl.formatMessage({ id: 'fields.from' })}
                                                            datepicker
                                                        />
                                                    </div>
                                                    <div className="m-t-xxs w-90 pull-left">
                                                        <TableFilterInputField
                                                            identifier="loading_date_to"
                                                            type={TableModelColumnFilteringType.string}
                                                            filters={filters}
                                                            onChange={this.props.table.changeFilter}
                                                            placeholder={this.props.intl.formatMessage({ id: 'fields.to' })}
                                                            datepicker
                                                        />
                                                    </div>
                                                    <div className="m-t-xxs w-20 pull-left m-r-xs">
                                                        <OverlayTrigger placement="top" overlay={tooltipNoTime}>
                                                            <div>
                                                                <TableFilterCheckboxField
                                                                    identifier="loading_no_date"
                                                                    type={TableModelColumnFilteringType.equal}
                                                                    filters={filters}
                                                                    onChange={this.props.table.changeFilter}
                                                                    label=""
                                                                />
                                                            </div>
                                                        </OverlayTrigger>
                                                    </div>
                                                    <div className="m-t-xxs pull-left" style={{ width: '99px' }}>
                                                        <TableFilterInputField
                                                            identifier="loading_code"
                                                            type={TableModelColumnFilteringType.string}
                                                            filters={filters}
                                                            onChange={this.props.table.changeFilter}
                                                            placeholder={this.props.intl.formatMessage({ id: 'fields.code' })}
                                                        />
                                                    </div>
                                                </th>
                                                <th className="w-400">
                                                    <TableFilterInputField
                                                        identifier="unloading_address"
                                                        type={TableModelColumnFilteringType.string}
                                                        filters={filters}
                                                        onChange={this.props.table.changeFilter}
                                                        placeholder={this.props.intl.formatMessage({ id: 'fields.address' })}
                                                    />
                                                    <div className="m-t-xxs w-90 pull-left m-r-xxs">
                                                        <TableFilterInputField
                                                            identifier="unloading_date_from"
                                                            type={TableModelColumnFilteringType.string}
                                                            filters={filters}
                                                            onChange={this.props.table.changeFilter}
                                                            placeholder={this.props.intl.formatMessage({ id: 'fields.from' })}
                                                            datepicker
                                                        />
                                                    </div>
                                                    <div className="m-t-xxs w-90 pull-left">
                                                        <TableFilterInputField
                                                            identifier="unloading_date_to"
                                                            type={TableModelColumnFilteringType.string}
                                                            filters={filters}
                                                            onChange={this.props.table.changeFilter}
                                                            placeholder={this.props.intl.formatMessage({ id: 'fields.to' })}
                                                            datepicker
                                                        />
                                                    </div>
                                                    <div className="m-t-xxs w-20 pull-left m-r-xs">
                                                        <OverlayTrigger placement="top" overlay={tooltipNoTime}>
                                                            <div>
                                                                <TableFilterCheckboxField
                                                                    identifier="unloading_no_date"
                                                                    type={TableModelColumnFilteringType.equal}
                                                                    filters={filters}
                                                                    onChange={this.props.table.changeFilter}
                                                                    label=""
                                                                />
                                                            </div>
                                                        </OverlayTrigger>
                                                    </div>
                                                    <div className="m-t-xxs pull-left" style={{ width: '99px' }}>
                                                        <TableFilterInputField
                                                            identifier="unloading_code"
                                                            type={TableModelColumnFilteringType.string}
                                                            filters={filters}
                                                            onChange={this.props.table.changeFilter}
                                                            placeholder={this.props.intl.formatMessage({ id: 'fields.code' })}
                                                        />
                                                    </div>
                                                </th>
                                            <th className="w-240">
                                                <div className="pull-left wp-100">
                                                    <div className="pull-left w-80">
                                                        <TableFilterInputField
                                                            identifier="vehicle"
                                                            type={TableModelColumnFilteringType.string}
                                                            filters={filters}
                                                            onChange={this.props.table.changeFilter}
                                                            placeholder={this.props.intl.formatMessage({ id: 'fields.registration' })}
                                                        />
                                                    </div>
                                                    <div className="pull-left w-20">
                                                        <OverlayTrigger placement="top" overlay={tooltipNoVehicle}>
                                                            <div>
                                                                <TableFilterCheckboxField
                                                                    identifier="no_vehicle"
                                                                    type={TableModelColumnFilteringType.equal}
                                                                    filters={filters}
                                                                    onChange={this.props.table.changeFilter}
                                                                    label=""
                                                                />
                                                            </div>
                                                        </OverlayTrigger>
                                                    </div>
                                                    <div className="pull-left w-20">&nbsp;</div>
                                                    <div className="pull-left w-80">
                                                        <TableFilterInputField
                                                            identifier="trailer"
                                                            type={TableModelColumnFilteringType.string}
                                                            filters={filters}
                                                            onChange={this.props.table.changeFilter}
                                                            placeholder={this.props.intl.formatMessage({ id: 'fields.registration' })}
                                                        />
                                                    </div>
                                                    <div className="pull-left w-20">
                                                        <OverlayTrigger placement="top" overlay={tooltipNoTrailer}>
                                                            <div>
                                                                <TableFilterCheckboxField
                                                                    identifier="no_trailer"
                                                                    type={TableModelColumnFilteringType.equal}
                                                                    filters={filters}
                                                                    onChange={this.props.table.changeFilter}
                                                                    label=""
                                                                />
                                                            </div>
                                                        </OverlayTrigger>
                                                    </div>
                                                </div>
                                                <div className="pull-left wp-100 m-t-xxs">
                                                    <div className="pull-left w-220 m-t-xxs">
                                                        <TableFilterSelectField
                                                            identifier="dispatcher_id"
                                                            type={TableModelColumnFilteringType.equal}
                                                            filters={filters}
                                                            onChange={this.props.table.changeFilter}
                                                            values={getPrerequisitesArray(this.props.prerequisites.values.get('dispatchers'))}
                                                            prompt={this.props.intl.formatMessage({ id: 'fields.dispatcher' })}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="pull-left wp-100">
                                                    <div className="pull-left w-220 m-t-xxs">
                                                        <TableFilterInputField
                                                            identifier="spedition"
                                                            type={TableModelColumnFilteringType.string}
                                                            filters={filters}
                                                            onChange={this.props.table.changeFilter}
                                                            placeholder={this.props.intl.formatMessage({ id: 'fields.spedition' })}
                                                        />
                                                    </div>
                                                </div>
                                            </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.props.data &&
                                                this.props.data.data.map(item => (
                                                    <InvoicingOrdersRow
                                                        key={`InvoicingOrdersRow-${item.id}`}
                                                        item={item}
                                                        isSelected={this.state.selectedIds.indexOf(item.id) !== -1}
                                                        onClick={null}
                                                        onInvoiceClick={this.onInvoiceClick}
                                                        onSelectionToggle={this.onSelectionToggle}
                                                        onChangeInvoicingState={this.onChangeInvoicingState}
                                                    />
                                                ))}
                                        </tbody>
                                    </table>
                                </div>

                                <div className="pull-left m-l-xxs m-t-md">
                                    <FormattedMessage id="pagination.totalRecords" />:{' '}
                                    {getListItemsCountMessage(
                                        clientSideItemsPerPage,
                                        this.props.data.count,
                                        this.props.tableModel.getIn(['pagination', 'totalCount'])
                                    )}
                                </div>

                                <ReactPaginate
                                    containerClassName="pagination"
                                    breakLabel={<span className="disabled">...</span>}
                                    activeClassName="active"
                                    pageCount={getTotalNumberOfPages(this.props.tableModel, this.props.data.count, clientSideItemsPerPage)}
                                    pageRangeDisplayed={10}
                                    marginPagesDisplayed={2}
                                    forcePage={this.props.tableModel.getIn(['pagination', 'current'])}
                                    onPageChange={this.props.table.changePage}
                                    previousLabel={this.props.intl.formatMessage({ id: 'pagination.previous' })}
                                    nextLabel={this.props.intl.formatMessage({ id: 'pagination.next' })}
                                />
                            </div>
                        </div>
                    </div>

                    <Modal show={Boolean(this.state.modalCreateInvoice.show)} onHide={this.handleCreateInvoiceCancel}>
                        <Modal.Header closeButton>
                            <Modal.Title>
                                <FormattedMessage id="buttons.createInvoice" />
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {Boolean(this.state.modalCreateInvoice.show) && (
                                <CreateInvoiceForm
                                    transportCustomersIds={this.state.selectedIds}
                                    onInvoiceCreateSuccess={this.onInvoiceCreateSuccess}
                                />
                            )}
                        </Modal.Body>
                    </Modal>

                    <Modal show={Boolean(this.state.modalInvoiceDetail.show)} onHide={this.handleInvoiceDetailCancel}>
                        <Modal.Header closeButton />
                        <Modal.Body>
                            {Boolean(this.state.modalInvoiceDetail.show) && <InvoiceDetail invoiceId={this.state.modalInvoiceDetail.invoiceId} />}
                        </Modal.Body>
                    </Modal>
                </div>
            </PermissionsCheck>
        )
    }
}

function mapStateToProps(state) {
    return {
        data: createTableDataSelector({
            tableIdentifier,
            columns,
            dataSelector: getData,
            clientSideItemsPerPage,
        })(state),
        tableModel: createTableModelSelector(tableIdentifier)(state),
        prerequisites: preparePrerequisitesFetchStatusSelectors(componentIdentifier, prerequisites, state),
        invoicingStateSettingInProgress: getInvoicingStateSettingInProgress(state),
        invoicingStateSettingError: getInvoicingStateSettingError(state),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            ...bindActionCreators(
                {
                    ...actionCreators,
                    ...preparePrerequisitesActionCreators(componentIdentifier, prerequisites),
                },
                dispatch
            ),
        },
        notify: (notification, type) => dispatch(Notifications.show(notification, type)),
        dispatch,
    }
}

function mergeProps(stateProps, dispatchProps, ownProps) {
    return {
        ...stateProps,
        ...dispatchProps,
        ...ownProps,
        table: bindActionCreators(prepareTableActionCreators(tableIdentifier, stateProps.tableModel), dispatchProps.dispatch),
    }
}

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps,
        mergeProps
    )(InvoicingOrders)
)
