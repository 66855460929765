import React, { Component } from 'react'
import Immutable from 'immutable'
import { FormattedMessage, injectIntl } from 'react-intl'

import * as config from '../../../common/config'
import * as constants from '../constants'
import * as formatters from '../../../common/formatters'

import { HEREMapRoutes } from '../../../common/here_maps'

import TransportsRoutesSummaryDetail from './TransportsRoutesSummaryDetail'
import PermissionsCheck from '../../auth/components/PermissionsCheck'
import { PERMS_ROUTES, PERMS_COST } from '../permissions'

class TransportsRoutesSummary extends Component {
    state = {
        routesSet: false,
        routePlanned: null,
        routeSpecified: null,
        routeReal: null,
        realVehiclePositions: {
            show: false,
            loaded: false,
        },
    }

    setRoutes = routes => {
        const newState = {
            routesSet: true,
        }

        routes.valueOf().forEach(route => {
            switch (route.type_id) {
                case constants.TRANSPORT_ROUTE_TYPE_PLANNED: {
                    newState.routePlanned = route
                    break
                }
                case constants.TRANSPORT_ROUTE_TYPE_SPECIFIED: {
                    newState.routeSpecified = route
                    break
                }
                case constants.TRANSPORT_ROUTE_TYPE_REAL: {
                    newState.routeReal = route
                    break
                }
                default: {
                    break
                }
            }
        })

        this.setState(newState)
    }

    /* eslint-disable max-len */

    getIconSVG = (text, bgColor = '#ffffff', textColor = '#000000', fontSize = '14px') => (
        <svg xmlns="http://www.w3.org/2000/svg" width="28px" height="30px">
            <path
                d="M 13 0 C 9.5 0 6.3 1.3 3.8 3.8 C 1.4 7.8 0 9.4 0 12.8 C 0 16.3 1.4 19.5 3.8 21.9 L 13 31 L 22.2 21.9 C 24.6 19.5 25.9 16.3 25.9 12.8 C 25.9 9.4 24.6 6.1 22.1 3.8 C 19.7 1.3 16.5 0 13 0 Z"
                fill="#ffffff"
            />
            <path
                d="M 13 2.2 C 6 2.2 2.3 7.2 2.1 12.8 C 2.1 16.1 3.1 18.4 5.2 20.5 L 13 28.2 L 20.8 20.5 C 22.9 18.4 23.8 16.2 23.8 12.8 C 23.6 7.07 20 2.2 13 2.2 Z"
                fill={bgColor}
            />
            <text x="13" y="19" fontSize={fontSize} fontWeight="bold" textAnchor="middle" fill={textColor} style={{ fontFamily: 'sans-serif' }}>
                {text}
            </text>
        </svg>
    )

    /* eslint-enable max-len */

    handleRecountPlannedRoute = e => {
        e && e.preventDefault()
        this.props.handleRecountPlannedRoute && this.props.handleRecountPlannedRoute()
    }

    handleRecountSpecifiedRoute = e => {
        e && e.preventDefault()
        this.props.handleRecountSpecifiedRoute && this.props.handleRecountSpecifiedRoute()
    }

    handleRecountRealRoute = e => {
        e && e.preventDefault()
        this.props.handleRecountRealRoute && this.props.handleRecountRealRoute()
    }

    handleShowRealVehiclePositions = e => {
        e && e.preventDefault()

        if (!this.state.realVehiclePositions.loaded) {
            this.props.handleLoadRealVehiclePositions && this.props.handleLoadRealVehiclePositions()
        }

        this.setState({
            realVehiclePositions: {
                ...this.state.realVehiclePositions,
                show: true,
                loaded: true,
            },
        })
    }

    handleHideRealVehiclePositions = e => {
        e && e.preventDefault()

        this.setState({
            realVehiclePositions: {
                ...this.state.realVehiclePositions,
                show: false,
            },
        })
    }

    componentWillReceiveProps(nextProps) {
        if (
            nextProps.routes &&
            (!this.state.routesSet || !this.props.routes || JSON.stringify(nextProps.routes.toJS()) !== JSON.stringify(this.props.routes.toJS()))
        ) {
            this.setRoutes(nextProps.routes)
        }
    }

    /* eslint-disable jsx-a11y/anchor-is-valid */
    render() {
        const transport = this.props.transport

        const routePlanned = this.state.routePlanned
        const routeSpecified = this.state.routeSpecified
        const routeReal = this.state.routeReal
        const transportStart = this.props.transportStart
        const transportPoints = this.props.points
        const transportType = this.props.transportType

        const routePlannedClassName =
            transportType && routePlanned && routePlanned.getProfitPerc() < transportType.min_profit_percent ? 'text-danger' : 'text-success'

        const routeSpecifiedClassName =
            transportType && routeSpecified && routeSpecified.getProfitPerc() < transportType.min_profit_percent ? 'text-danger' : 'text-success'

        const routeRealClassName =
            transportType && routeReal && routeReal.getProfitPerc() < transportType.min_profit_percent ? 'text-danger' : 'text-success'

        const hereMapRoutes = []
        if (routePlanned) {
            hereMapRoutes.push({
                waypoints: routePlanned.getWaypoints(),
                color: '#7a6fbe',
                showRoute: true,
                showWaypoints: false,
                showArrows: false,
            })
        }
        if (routeSpecified) {
            hereMapRoutes.push({
                waypoints: routeSpecified.getWaypoints(),
                color: '#22BAA0',
                showRoute: true,
                showWaypoints: false,
                showArrows: false,
            })
        }
        if (routeReal) {
            hereMapRoutes.push({
                waypoints: routeReal.getPositions(),
                color: '#000000',
                arrowsColor: '#000000',
                showRoute: true,
                showWaypoints: false,
                showArrows: false,
            })
        }

        let markers = {}

        if (transportStart) {
            const point = transportStart
            markers[point.id] = {
                center: {
                    lat: point.gps_lat,
                    lng: point.gps_lng,
                },
                iconSvg: this.getIconSVG('N', '#999999', '#ffffff'),
                zIndex: 1,
                size: { w: 30, h: 30 },
                anchor: { x: 15, y: 30 },
            }
        }

        transportPoints &&
            transportPoints.forEach((point, key) => {
                if (point.gps_lat && point.gps_lng) {
                    const pointType = point.type_id && this.props.pointTypes[point.type_id]

                    markers[point.id] = {
                        center: {
                            lat: point.gps_lat,
                            lng: point.gps_lng,
                        },
                        iconSvg: this.getIconSVG(key + 1, pointType && pointType.rgb_bg, pointType && pointType.rgb_text),
                        zIndex: 1,
                        size: { w: 30, h: 30 },
                        anchor: { x: 15, y: 30 },
                    }
                }
            })
        markers = Immutable.Map(markers)

        const realVehiclePositions = this.props.realVehiclePositions

        return (
            <div className="transport-routes">
                <h2 className="text-center">
                    <FormattedMessage id="fields.transportNo" /> <b>{transport.id}</b>
                </h2>
                <div className="row">
                    <div className="col-md-4 p-v-xs text-center" style={{ borderTop: '2px solid #7a6fbe' }}>
                        <span className={!routePlanned ? 'text-gray' : ''}>
                            <FormattedMessage id="fields.plannedRoute" />
                        </span>
                        <PermissionsCheck hasAny={Object.values(PERMS_COST)}>
                            <h1 className={routePlannedClassName}>{routePlanned && formatters.priceFormatter(routePlanned.getProfit())}</h1>
                            <span className={routePlannedClassName}>{routePlanned && formatters.percentFormatter(routePlanned.getProfitPerc())}</span>
                        </PermissionsCheck>
                    </div>
                    <div className="col-md-4 p-v-xs text-center b-r b-l" style={{ borderTop: '2px solid #22BAA0' }}>
                        <span className={!routeSpecified ? 'text-gray' : ''}>
                            <FormattedMessage id="fields.specifiedRoute" />
                        </span>
                        <PermissionsCheck has={Object.values(PERMS_COST)}>
                            <h1 className={routeSpecifiedClassName}>{routeSpecified && formatters.priceFormatter(routeSpecified.getProfit())}</h1>
                            <span className={routeSpecifiedClassName}>
                                {routeSpecified && formatters.percentFormatter(routeSpecified.getProfitPerc())}
                            </span>
                        </PermissionsCheck>
                    </div>
                    <div className="col-md-4 p-v-xs text-center" style={{ borderTop: '2px solid #000000' }}>
                        <span className={!routeReal ? 'text-gray' : ''}>
                            <FormattedMessage id="fields.realRoute" />
                        </span>
                        <PermissionsCheck has={Object.values(PERMS_COST)}>
                            <h1 className={routeRealClassName}>{routeReal && formatters.priceFormatter(routeReal.getProfit())}</h1>
                            <span className={routeRealClassName}>{routeReal && formatters.percentFormatter(routeReal.getProfitPerc())}</span>
                        </PermissionsCheck>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-4 p-h-lg text-center" style={{ borderBottom: '2px solid #7a6fbe' }}>
                        <TransportsRoutesSummaryDetail route={routePlanned} transportType={transportType} />
                        {routePlanned && (
                            <div className="m-b-xs">
                                <PermissionsCheck has={[PERMS_ROUTES.UPDATE]} noPermissionsReturn={<a>&nbsp;</a>}>
                                    <a href="#" className="no-text-decoration" onClick={this.handleRecountPlannedRoute}>
                                        <i className="far fa-sync-alt" /> <FormattedMessage id="buttons.recountRoute" />
                                    </a>
                                </PermissionsCheck>
                            </div>
                        )}
                    </div>
                    <div className="col-md-4 p-h-lg text-center b-r b-l" style={{ borderBottom: '2px solid #22BAA0' }}>
                        <TransportsRoutesSummaryDetail route={routeSpecified} transportType={transportType} />
                        {routeSpecified && (
                            <div className="m-b-xs">
                                <PermissionsCheck has={[PERMS_ROUTES.UPDATE]} noPermissionsReturn={<a>&nbsp;</a>}>
                                    <a href="#" className="no-text-decoration" onClick={this.handleRecountSpecifiedRoute}>
                                        <i className="far fa-sync-alt" /> <FormattedMessage id="buttons.recountRoute" />
                                    </a>
                                </PermissionsCheck>{' '}
                            </div>
                        )}
                    </div>
                    <div className="col-md-4 p-h-lg text-center" style={{ borderBottom: routeReal || transport.real_distance ? '2px solid #000000' : 'none' }}>
                        <TransportsRoutesSummaryDetail route={routeReal} transportType={transportType} distance={transport.real_distance} transportPoints={transportPoints} transportStart={transportStart} />
                        {routeReal || transport.real_distance ? (
                            <div className="m-b-xs">
                                <PermissionsCheck has={[PERMS_ROUTES.UPDATE]}>
                                    <a href="#" className="no-text-decoration" onClick={this.handleRecountRealRoute}>
                                        <i className="far fa-sync-alt" /> <FormattedMessage id="buttons.recountRoute" />
                                    </a>
                                </PermissionsCheck>
                                {this.state.realVehiclePositions.show ? (
                                    <a href="#" className="no-text-decoration m-l-lg" onClick={this.handleHideRealVehiclePositions}>
                                        <FormattedMessage id="buttons.hideGpsPositions" /> <i className="fal fa-angle-up" />
                                    </a>
                                ) : (
                                    <a href="#" className="no-text-decoration m-l-lg" onClick={this.handleShowRealVehiclePositions}>
                                        <FormattedMessage id="buttons.showGpsPositions" /> <i className="fal fa-angle-down" />
                                    </a>
                                )}
                            </div>
                        ) : null}
                    </div>
                </div>

                {this.state.realVehiclePositions.show && (
                    <div className="p-v-sm">
                        <div className="row">
                            <div className="col-sm-12">
                                {realVehiclePositions && realVehiclePositions.length > 0 ? (
                                    <table className="table table-no-border no-m">
                                        <thead>
                                            <tr>
                                                <th className="w-140">
                                                    <FormattedMessage id="fields.time" />
                                                </th>
                                                <th className="w-100">
                                                    <FormattedMessage id="fields.vehicle" />
                                                </th>
                                                <th>
                                                    <FormattedMessage id="fields.driver" />
                                                </th>
                                                <th className="w-180 text-center">
                                                    <FormattedMessage id="fields.gps" />
                                                </th>
                                                <th className="w-100 text-right">
                                                    <FormattedMessage id="fields.speed" />
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {realVehiclePositions.map(position => (
                                                <tr key={position.id}>
                                                    <td>{position.datetime && formatters.datetimeFormatter(position.datetime)}</td>
                                                    <td>{position.vehicle_name}</td>
                                                    <td>{position.driver_name}</td>
                                                    <td className="text-center">{formatters.gpsFormatter(position.gps_lat, position.gps_lng)}</td>
                                                    <td className="text-right">{formatters.speedFormatter(position.speed)}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                ) : (
                                    <p className="text-center text-danger m-t-xs">
                                        <FormattedMessage id="modules.transports.noVehiclePositionsFound" />
                                    </p>
                                )}
                            </div>
                        </div>
                    </div>
                )}

                <div className="p-v-sm">
                    <div className="row">
                        <div className="col-sm-12 text-center">
                            <HEREMapRoutes
                                appId={config.CFG_HERE_MAPS_APP_ID}
                                appCode={config.CFG_HERE_MAPS_APP_CODE}
                                routes={hereMapRoutes}
                                markers={markers}
                                positions={realVehiclePositions}
                                className="h-700"
                                showPOIs
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default injectIntl(TransportsRoutesSummary)
