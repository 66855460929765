import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { push } from 'react-router-redux'
import Notifications from 'react-notification-system-redux'
import moment from 'moment'
import DatePicker from 'react-datepicker'

import 'react-datepicker/dist/react-datepicker.css'

import {
    url,
    handleCommonEditActions,
    getPrerequisitesArray,
    convertCSDatetimeToISO,
    convertISODatetimeToCS,
    convertCSDatetimeToMoment,
} from '../../../common/helpers'
import * as formatters from '../../../common/formatters'
import { Form, SelectField, CheckboxField, SubmitButton } from '../../../common/form'
import { createGetVehicleEventByIDSelector, createGetCurrentVehicleEventStatusSelector } from '../selectors'
import { createGetTrailerByIDSelector } from '../../trailers/selectors'
import * as vehicleEventsActionCreators from '../actionCreators'
import { LoadingOverlay } from '../../../common/loading_overlay'
import { preparePrerequisitesFetchStatusSelectors } from '../../../common/prerequisites/selectors'
import { preparePrerequisitesActionCreators } from '../../../common/prerequisites/actionCreators'
import * as constants from '../constants'
import { HEREMapAddressPicker } from '../../../common/here_maps'
import * as config from '../../../common/config'
import PermissionsCheck from '../../auth/components/PermissionsCheck'
import { PERMS } from '../permissions'

const componentIdentifier = 'vehicle_events_edit'
const prerequisites = ['vehicles', 'trailers']

class VehicleEventsEdit extends Component {
    defaultValues = {}

    validationRules = {}

    state = {
        datetimes: {
            start: moment().format('DD.MM.YYYY HH:mm'),
            end: moment().format('DD.MM.YYYY HH:mm'),
        },
        datetimesSet: false,
        gps: null,
    }

    handleSubmit = values => {
        values.datetime_start = convertCSDatetimeToISO(this.state.datetimes.start)
        values.datetime_end = convertCSDatetimeToISO(this.state.datetimes.end)

        this.props.actions.saveVehicleEvent(Object.assign(values, this.state.gps))
    }

    handleDatetimeChange = (key, value) => {
        const newState = {
            datetimes: {
                ...this.state.datetimes,
                [key]: value,
            },
        }

        this.setState(newState)
    }

    setGPS = coord => {
        this.setState({
            gps: coord,
        })
    }

    handleMarkerPositionChanged = coord => {
        this.setGPS({
            gps_lat: coord.lat,
            gps_lng: coord.lng,
        })
    }

    componentDidMount() {
        this.props.match.params.vehicleEventID && this.props.actions.fetchVehicleEvent(this.props.match.params.vehicleEventID)
        this.props.actions.fetchPrerequisites()
    }

    componentWillReceiveProps(nextProps) {
        handleCommonEditActions(this.props, nextProps, () => {
            this.props.redirect(url(this.props.match, 'vehicle-events'))
        })

        if (nextProps.vehicleEvent && !this.state.datetimesSet) {
            this.setState({
                datetimes: {
                    start: convertISODatetimeToCS(nextProps.vehicleEvent.datetime_start),
                    end: convertISODatetimeToCS(nextProps.vehicleEvent.datetime_end),
                },
                datetimesSet: true,
            })
        }

        if (!this.state.gps && nextProps.vehicleEvent) {
            if (nextProps.vehicleEvent.gps_lat && nextProps.vehicleEvent.gps_lng) {
                this.setGPS({
                    gps_lat: nextProps.vehicleEvent.gps_lat,
                    gps_lng: nextProps.vehicleEvent.gps_lng,
                })
            }
        }
    }

    render() {
        document.title = formatters.titleFormatter(this.props.intl.formatMessage({ id: 'modules.vehicleEvents.heading' }))

        const gps = this.state.gps &&
            this.state.gps.gps_lat &&
            this.state.gps.gps_lat && {
                lat: this.state.gps.gps_lat,
                lng: this.state.gps.gps_lng,
            }

        const vehicleEventTypesValues = constants.VEHICLE_EVENT_TYPES.map(item => ({
            id: item.id,
            name: this.props.intl.formatMessage({ id: item.name }),
        }))

        const values = this.props.vehicleEvent ? this.props.vehicleEvent.toJS() : this.defaultValues
        const trailer = this.props.vehicleEvent && this.props.createGetTrailerByIDSelector(this.props.vehicleEvent.trailer_id)

        if (trailer) {
            values.is_trailer_damaged = trailer.is_damage
            values.is_trailer_security_damaged = trailer.is_security_damage
            values.is_trailer_cooling_damaged = trailer.is_cooling_damage
        }

        return (
            <PermissionsCheck hasAny={Object.values(PERMS)} noPermissionsPage>
                <div className="page-inner">
                    <div id="main-wrapper">
                        <div className="row">
                            <div className="col-lg-8 col-lg-offset-2 col-xl-6 col-xl-offset-3">
                                <div className="panel panel-white">
                                    <div className="panel-body panel-padding">
                                        <div className="panel-head">
                                            <h4>
                                                <FormattedMessage id="modules.vehicleEvents.heading" /> -{' '}
                                                {this.props.vehicleEvent ? (
                                                    <FormattedMessage id="fields.itemEdit" />
                                                ) : (
                                                    <FormattedMessage id="fields.itemCreate" />
                                                )}
                                            </h4>
                                        </div>
                                        <LoadingOverlay active={this.props.prerequisites.status.get('fetching') || this.props.status.get('fetching')}>
                                            <PermissionsCheck hasAny={Object.values(PERMS)}>
                                                <Form
                                                    values={values}
                                                    validationRules={this.validationRules}
                                                    onSubmit={this.handleSubmit}
                                                    isEdit={Boolean(this.props.vehicleEvent)}
                                                >
                                                    <div>
                                                        <div className="form-group w-200">
                                                            <label htmlFor="value_from">
                                                                <FormattedMessage id="fields.from" />
                                                            </label>
                                                            <DatePicker
                                                                id="value_from"
                                                                dateFormat="DD.MM.YYYY HH:mm"
                                                                showTimeSelect
                                                                shouldCloseOnSelect={false}
                                                                timeFormat="HH:mm"
                                                                timeIntervals={5}
                                                                autoComplete="off"
                                                                popperClassName="react-datepicker-with-time"
                                                                selected={convertCSDatetimeToMoment(this.state.datetimes.start)}
                                                                value={this.state.datetimes.start}
                                                                onChange={value => {
                                                                    this.handleDatetimeChange(
                                                                        'start',
                                                                        value ? formatters.datetimeFormatter(value) : ''
                                                                    )
                                                                }}
                                                                onChangeRaw={e => {
                                                                    this.handleDatetimeChange('start', e.target.value)
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className="form-group w-200">
                                                            <label htmlFor="value_to">
                                                                <FormattedMessage id="fields.to" />
                                                            </label>
                                                            <DatePicker
                                                                id="value_to"
                                                                dateFormat="DD.MM.YYYY HH:mm"
                                                                showTimeSelect
                                                                shouldCloseOnSelect={false}
                                                                timeFormat="HH:mm"
                                                                timeIntervals={5}
                                                                autoComplete="off"
                                                                popperClassName="react-datepicker-with-time"
                                                                selected={convertCSDatetimeToMoment(this.state.datetimes.end)}
                                                                value={this.state.datetimes.end}
                                                                onChange={value => {
                                                                    this.handleDatetimeChange('end', value ? formatters.datetimeFormatter(value) : '')
                                                                }}
                                                                onChangeRaw={e => {
                                                                    this.handleDatetimeChange('end', e.target.value)
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <SelectField
                                                        id="type_id"
                                                        label={this.props.intl.formatMessage({ id: 'fields.eventType' })}
                                                        values={vehicleEventTypesValues}
                                                        className="wp-50"
                                                    />
                                                    <SelectField
                                                        id="vehicle_id"
                                                        label={this.props.intl.formatMessage({ id: 'fields.vehicle' })}
                                                        values={getPrerequisitesArray(this.props.prerequisites.values.get('vehicles'))}
                                                        className="wp-50"
                                                    />

                                                    <SelectField
                                                        id="trailer_id"
                                                        label={this.props.intl.formatMessage({ id: 'fields.trailer' })}
                                                        values={getPrerequisitesArray(this.props.prerequisites.values.get('trailers'))}
                                                        className="wp-50"
                                                    />
                                                    <CheckboxField
                                                        id="is_trailer_loaded"
                                                        label={this.props.intl.formatMessage({ id: 'fields.trailerLoaded' })}
                                                    />
                                                    <CheckboxField
                                                        id="is_trailer_damaged"
                                                        label={this.props.intl.formatMessage({ id: 'fields.trailerDamaged' })}
                                                    />
                                                    <CheckboxField
                                                        id="is_trailer_security_damaged"
                                                        label={this.props.intl.formatMessage({ id: 'fields.securityDamaged' })}
                                                    />
                                                    <CheckboxField
                                                        id="is_trailer_cooling_damaged"
                                                        label={this.props.intl.formatMessage({ id: 'fields.coolingDamaged' })}
                                                    />

                                                    <div className="p-v-sm b-t">
                                                        <HEREMapAddressPicker
                                                            appId={config.CFG_HERE_MAPS_APP_ID}
                                                            appCode={config.CFG_HERE_MAPS_APP_CODE}
                                                            markerCenter={gps}
                                                            handleMarkerPositionChanged={this.handleMarkerPositionChanged}
                                                            className="h-400"
                                                            showPOIs
                                                        />
                                                    </div>

                                                    <div className="btns-form">
                                                        <Link to={url(this.props.match, 'vehicle-events')} className="btn btn-addon btn-default">
                                                            <i className="far fa-chevron-left" /> <FormattedMessage id="buttons.back" />
                                                        </Link>
                                                        <SubmitButton
                                                            perms={Object.values(PERMS)}
                                                            isEdit={Boolean(this.props.vehicleEvent)}
                                                            className="btn-addon pull-right"
                                                        />
                                                    </div>
                                                </Form>
                                            </PermissionsCheck>
                                        </LoadingOverlay>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </PermissionsCheck>
        )
    }
}

function mapStateToProps(state, ownProps) {
    return {
        vehicleEvent: createGetVehicleEventByIDSelector(ownProps.match.params.vehicleEventID)(state),
        status: createGetCurrentVehicleEventStatusSelector()(state),
        prerequisites: preparePrerequisitesFetchStatusSelectors(componentIdentifier, prerequisites, state),
        createGetTrailerByIDSelector: id => createGetTrailerByIDSelector(id)(state),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            ...bindActionCreators(
                {
                    ...vehicleEventsActionCreators,
                    ...preparePrerequisitesActionCreators(componentIdentifier, prerequisites),
                },
                dispatch
            ),
        },
        redirect: toURL => dispatch(push(toURL)),
        notify: (notification, type) => dispatch(Notifications.show(notification, type)),
    }
}

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(VehicleEventsEdit)
)
