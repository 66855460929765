import { Map } from 'immutable'
import * as actionTypes from './actionTypes'

const initialState = Map({
    list: Map({
        year: null,
        data: null,
        fetching: false,
        error: null,
    }),
    details: Map({
        data: null,
        fetching: false,
        error: null,
    }),
})

function reportTrailerResults(state = initialState, action) {
    switch (action.type) {
        // Fetch
        case actionTypes.FETCH:
            return state.setIn(['list', 'year'], action.payload).setIn(['list', 'fetching'], true)
        case actionTypes.FETCH_FULFILLED:
            return state.withMutations(map => {
                map.setIn(['list', 'fetching'], false).setIn(['list', 'data'], action.payload)
            })
        case actionTypes.FETCH_REJECTED:
            return state.withMutations(map => {
                map.setIn(['list', 'fetching'], false).setIn(['list', 'error'], action.payload)
            })
        case actionTypes.FETCH_CANCELLED:
            return state.setIn(['list', 'fetching'], false)

        // Clear
        case actionTypes.CLEAR:
            return state.setIn(['list', 'data'], null)

        // Fetch details
        case actionTypes.FETCH_DETAILS:
            return state.setIn(['details', 'fetching'], true)
        case actionTypes.FETCH_DETAILS_FULFILLED:
            return state.withMutations(map => {
                map.setIn(['details', 'fetching'], false).setIn(['details', 'data'], action.payload)
            })
        case actionTypes.FETCH_DETAILS_REJECTED:
            return state.withMutations(map => {
                map.setIn(['details', 'fetching'], false).setIn(['details', 'error'], action.payload)
            })
        case actionTypes.FETCH_DETAILS_CANCELLED:
            return state.setIn(['details', 'fetching'], false)

        // Clear details
        case actionTypes.CLEAR_DETAILS:
            return state.setIn(['details', 'data'], null)

        default:
            return state
    }
}

export default reportTrailerResults
