import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { push } from 'react-router-redux'
import Notifications from 'react-notification-system-redux'
import validator from 'validator'

import { url, handleCommonEditActions, validationMessage } from '../../../common/helpers'
import * as formatters from '../../../common/formatters'
import { Form, InputField, FontawesomePicker, ColorPicker, SubmitButton } from '../../../common/form'
import { createGetCustomerAddressTypeByIDSelector, createGetCurrentCustomerAddressTypeStatusSelector } from '../selectors'
import * as customerAddressTypesActionCreators from '../actionCreators'
import { LoadingOverlay } from '../../../common/loading_overlay'
import { preparePrerequisitesFetchStatusSelectors } from '../../../common/prerequisites/selectors'
import { preparePrerequisitesActionCreators } from '../../../common/prerequisites/actionCreators'
import PermissionsCheck from '../../auth/components/PermissionsCheck'
import { PERMS } from '../permissions'

const componentIdentifier = 'customer_address_types_edit'
const prerequisites = []

class CustomerAddressTypesEdit extends Component {
    defaultValues = {
        color: '#000000',
        bg_color: 'transparent',
        icon_class: 'fas fa-map-marker',
    }

    validationRules = {
        name: value => validator.isEmpty(String(value)) && validationMessage('isEmpty'),
    }

    state = {
        values: this.defaultValues,
    }

    setValues = (values, callback) => {
        this.setState(
            {
                values,
            },
            callback
        )
    }

    handleChangeValues = values => {
        this.setValues(values)
    }

    handleSubmit = values => {
        this.setValues(values, () => {
            this.handleSave()
        })
    }

    handleSave = () => {
        this.props.actions.saveCustomerAddressType(this.state.values)
    }

    componentDidMount() {
        this.props.match.params.customerAddressTypeID && this.props.actions.fetchCustomerAddressType(this.props.match.params.customerAddressTypeID)
    }

    componentWillReceiveProps(nextProps) {
        handleCommonEditActions(this.props, nextProps, () => {
            this.props.redirect(url(this.props.match, 'customer-address-types'))
        })

        if (
            JSON.stringify(nextProps.customerAddressType) !== JSON.stringify(this.props.customerAddressType) ||
            (nextProps.customerAddressType && !this.state.values.id)
        ) {
            this.setValues(nextProps.customerAddressType.toJS())
        }
    }

    render() {
        document.title = formatters.titleFormatter(this.props.intl.formatMessage({ id: 'modules.customerAddressTypes.heading' }))

        return (
            <PermissionsCheck hasAny={Object.values(PERMS)} noPermissionsPage>
                <div className="page-inner">
                    <div id="main-wrapper">
                        <div className="row">
                            <div className="col-md-8 col-md-offset-2 col-lg-6 col-lg-offset-3 col-xl-4 col-xl-offset-4">
                                <div className="panel panel-white">
                                    <div className="panel-body panel-padding">
                                        <div className="panel-head">
                                            <h4>
                                                <FormattedMessage id="modules.customerAddressTypes.heading" /> -{' '}
                                                {this.props.customerAddressType ? (
                                                    <FormattedMessage id="fields.itemEdit" />
                                                ) : (
                                                    <FormattedMessage id="fields.itemCreate" />
                                                )}
                                            </h4>
                                        </div>
                                        <LoadingOverlay active={this.props.prerequisites.status.get('fetching') || this.props.status.get('fetching')}>
                                            <PermissionsCheck hasAny={Object.values(PERMS)}>
                                                <Form
                                                    values={this.state.values}
                                                    validationRules={this.validationRules}
                                                    onChange={this.handleChangeValues}
                                                    onSubmit={this.handleSubmit}
                                                    isEdit={Boolean(this.props.customerAddressType)}
                                                >
                                                    <InputField id="name" label={this.props.intl.formatMessage({ id: 'fields.name' })} />
                                                    <FontawesomePicker
                                                        id="icon_class"
                                                        iconColor={this.state.values.color}
                                                        bgColor={this.state.values.bg_color}
                                                        label={this.props.intl.formatMessage({ id: 'fields.icon' })}
                                                    />
                                                    <ColorPicker id="color" label={this.props.intl.formatMessage({ id: 'fields.color' })} />
                                                    <ColorPicker
                                                        id="bg_color"
                                                        label={this.props.intl.formatMessage({ id: 'fields.bgColor' })}
                                                        colors={[['#FFFFFF'], ['#000000'], ['transparent']]}
                                                    />

                                                    <div className="btns-form">
                                                        <Link
                                                            to={url(this.props.match, 'customer-address-types')}
                                                            className="btn btn-addon btn-default"
                                                        >
                                                            <i className="far fa-chevron-left" /> <FormattedMessage id="buttons.back" />
                                                        </Link>
                                                        <SubmitButton
                                                            perms={Object.values(PERMS)}
                                                            isEdit={Boolean(this.props.customerAddressType)}
                                                            className="btn-addon pull-right"
                                                        />
                                                    </div>
                                                </Form>
                                            </PermissionsCheck>
                                        </LoadingOverlay>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </PermissionsCheck>
        )
    }
}

function mapStateToProps(state, ownProps) {
    return {
        customerAddressType: createGetCustomerAddressTypeByIDSelector(ownProps.match.params.customerAddressTypeID)(state),
        status: createGetCurrentCustomerAddressTypeStatusSelector()(state),
        prerequisites: preparePrerequisitesFetchStatusSelectors(componentIdentifier, prerequisites, state),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            ...bindActionCreators(
                {
                    ...customerAddressTypesActionCreators,
                    ...preparePrerequisitesActionCreators(componentIdentifier, prerequisites),
                },
                dispatch
            ),
        },
        redirect: toURL => dispatch(push(toURL)),
        notify: (notification, type) => dispatch(Notifications.show(notification, type)),
    }
}

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(CustomerAddressTypesEdit)
)
