import React, { Component } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'

import DatePicker from 'react-datepicker'
import validator from 'validator'
import * as config from '../../../common/config'
import { Form, InputField, SelectField, CheckboxField, SubmitButton } from '../../../common/form'
import { HEREMapAddressPicker } from '../../../common/here_maps'

import 'react-datepicker/dist/react-datepicker.css'
import { datetimeFormatter } from '../../../common/formatters'
import { convertCSDatetimeToMoment, validationMessage } from '../../../common/helpers'

import CustomerAddressesPicker from '../../customer_address/components/CustomerAddressesPicker'
import { ConfirmDialog } from '../../../common/confirm_dialog'
import moment from 'moment/moment'
import * as constants from '../../transports/constants'

import PermissionsCheck from '../../auth/components/PermissionsCheck'
import { PERMS_ROUTES } from '../permissions'

class TransportTemplatesEditRoutePointEdit extends Component {
    point = null
    validationRules = {
        country_code: value => {
            if (validator.isEmpty(String(value)) || value === undefined) {
                return validationMessage('isEmpty')
            } else if (!validator.isLength(value, { max: 3, min: 3 })) {
                return validationMessage('isLength', { max: 3, min: 3 })
            }
            return false
        }
    }

    state = {
        datetimes: {
            plannedFrom: '',
            plannedTo: '',
        },
        gps: null,
        values: {},
        showAddressesPicker: false,
        emptyMandatoryFields: [],
        fixedMandatoryFields: [],
        ignoreEmptyMandatoryFields: false,
        confirmDialog: {
            show: false,
            title: '',
            message: '',
            labelCancel: '',
            labelAccept: '',
            classNameAccept: 'text-success',
            classNameCancel: 'text-default',
            onCancel: () => {},
            onAccept: () => {},
        },
    }

    // confirm dialog
    showConfirmDialog = options => {
        this.setState({
            confirmDialog: {
                show: true,
                title: options.title || this.props.intl.formatMessage({ id: 'confirmDialog.default.title' }),
                message: options.message || this.props.intl.formatMessage({ id: 'confirmDialog.default.message' }),
                labelCancel: options.labelCancel || this.props.intl.formatMessage({ id: 'confirmDialog.default.cancel' }),
                labelAccept: options.labelAccept || this.props.intl.formatMessage({ id: 'confirmDialog.default.accept' }),
                classNameAccept: options.classNameAccept || 'btn-success',
                classNameCancel: options.classNameCancel || 'btn-default',
                onCancel: options.onCancel || this.hideConfirmDialog,
                onAccept: options.onAccept || this.hideConfirmDialog,
            },
        })
    }

    hideConfirmDialog = () => {
        this.setState({
            confirmDialog: {
                show: false,
                onCancel: () => {},
                onAccept: () => {},
            },
        })
    }

    setGPS = coord => {
        this.setState(
            {
                gps: coord,
            },
            () => {
                this.getFixedMandatoryFields()
            }
        )
    }

    handleMarkerPositionChanged = coord => {
        this.setGPS({
            gps_lat: coord.lat,
            gps_lng: coord.lng,
        })
    }

    handleAddressChanged = address => {
        this.setState({
            values: {
                ...this.state.values,
                name: address.company,
                street: address.street,
                city: address.city,
                zipcode: address.zipcode,
                country_code: address.country_code,
            },
        })
    }

    handleDatetimeChange = (key, value) => {
        const newState = {
            datetimes: {
                ...this.state.datetimes,
                [key]: value,
            },
        }

        if (key === 'plannedFrom') {
            const momentFromNew = convertCSDatetimeToMoment(value)

            if (momentFromNew) {
                const momentFrom = convertCSDatetimeToMoment(this.state.datetimes.plannedFrom)
                const momentTo = convertCSDatetimeToMoment(this.state.datetimes.plannedTo)
                const diff = momentFrom && momentTo ? momentTo.diff(momentFrom, 'minutes') : 60

                newState.datetimes.plannedTo = datetimeFormatter(moment(momentFromNew).add(diff, 'minutes'))
            } else {
                newState.datetimes.plannedTo = ''
            }
        }

        this.setState(newState)
    }

    handleChangeValues = values => {
        const typePcsCount = constants.TRANSPORT_POINT_DATA_TYPE_PCS_COUNT
        const typePalletExchange = constants.TRANSPORT_POINT_DATA_TYPE_PALLET_EXCHANGE
        const typePalletExchangeCount = constants.TRANSPORT_POINT_DATA_TYPE_PALLET_EXCHANGE_COUNT

        if (values[`data-${typePalletExchange}`] !== this.state.values[`data-${typePalletExchange}`]) {
            if (values[`data-${typePalletExchange}`] === '1') {
                values[`data-${typePalletExchangeCount}`] = values[`data-${typePcsCount}`]
            } else {
                values[`data-${typePalletExchangeCount}`] = ''
            }
        } else if (values[`data-${typePcsCount}`] !== this.state.values[`data-${typePcsCount}`]) {
            if (
                values[`data-${typePalletExchange}`] === '1' &&
                (values[`data-${typePalletExchangeCount}`] === '' ||
                    this.state.values[`data-${typePcsCount}`] === this.state.values[`data-${typePalletExchangeCount}`])
            ) {
                values[`data-${typePalletExchangeCount}`] = values[`data-${typePcsCount}`]
            }
        }

        this.setState(
            {
                values,
            },
            () => {
                this.getFixedMandatoryFields()
            }
        )
    }

    getEmptyMandatoryFields = values => {
        const emptyMandatoryFields = []
        const pointType = this.props.pointType && constants.TRANSPORT_POINT_TYPES_DEFINITION[this.props.pointType]

        if (pointType && !pointType.noDatetimes && !pointType.noDatetimesMandatory) {
            if (!values.plannedFrom && !values.specifiedFrom) {
                emptyMandatoryFields.push('datetimesFrom')
            }

            if (!values.plannedTo && !values.specifiedTo) {
                emptyMandatoryFields.push('datetimesTo')
            }
        }

        if (values.is_booking_required) {
            if (!values.booking_url) {
                emptyMandatoryFields.push('booking_url')
            }
        }

        if (!values.name) {
            emptyMandatoryFields.push('name')
        }

        if (!values.gps_lat || !values.gps_lng) {
            emptyMandatoryFields.push('gps')
        }

        this.setState({
            emptyMandatoryFields,
        })

        return emptyMandatoryFields
    }

    getFixedMandatoryFields = () => {
        const values = this.getAllValuesForSaving()
        const fixedMandatoryFields = []

        if (this.state.emptyMandatoryFields.indexOf('datetimesFrom') !== -1 && (values.plannedFrom || values.specifiedFrom)) {
            fixedMandatoryFields.push('datetimesFrom')
        }

        if (this.state.emptyMandatoryFields.indexOf('datetimesTo') !== -1 && (values.plannedTo || values.specifiedTo)) {
            fixedMandatoryFields.push('datetimesTo')
        }

        if (this.state.emptyMandatoryFields.indexOf('booking_url') !== -1 && values.booking_url) {
            fixedMandatoryFields.push('booking_url')
        }

        if (this.state.emptyMandatoryFields.indexOf('name') !== -1 && values.name) {
            fixedMandatoryFields.push('name')
        }

        if (this.state.emptyMandatoryFields.indexOf('gps') !== -1 && values.gps_lat && values.gps_lng) {
            fixedMandatoryFields.push('gps')
        }

        this.setState({
            fixedMandatoryFields,
        })

        return fixedMandatoryFields
    }

    getAllValuesForSaving = () => {
        const datetimes = {}
        Object.keys(this.state.datetimes).forEach(key => {
            datetimes[key] = convertCSDatetimeToMoment(this.state.datetimes[key])
        })

        const name = this.state.values.name

        return {
            ...this.state.values,
            ...this.state.gps,
            ...datetimes,
            name,
            data: Object.keys(this.props.dataFields).map(key => ({
                ...this.props.dataFields[key],
                type_id: key,
                value: this.state.values[`data-${key}`] ? String(this.state.values[`data-${key}`]) : '',
            })),
        }
    }

    handleSubmit = () => {
        const values = this.getAllValuesForSaving()

        if (!this.state.ignoreEmptyMandatoryFields && this.getEmptyMandatoryFields(values).length > 0) {
            this.showConfirmDialog({
                title: this.props.intl.formatMessage({ id: 'confirmDialog.emptyMandatoryFields.title' }),
                message: this.props.intl.formatMessage({ id: 'confirmDialog.emptyMandatoryFields.message' }),
                labelCancel: this.props.intl.formatMessage({ id: 'confirmDialog.emptyMandatoryFields.cancel' }),
                labelAccept: this.props.intl.formatMessage({ id: 'confirmDialog.emptyMandatoryFields.accept' }),
                classNameAccept: 'btn-success',
                onAccept: () => {
                    this.hideConfirmDialog()
                    this.setState(
                        {
                            ignoreEmptyMandatoryFields: true,
                        },
                        () => {
                            this.handleSubmit()
                        }
                    )
                },
            })
            return false
        }

        this.props.handlePointChanged && this.props.handlePointChanged(values)

        return true
    }

    setPoint = point => {
        const values = { ...point }
        point.data &&
            point.data.forEach(item => {
                values[`data-${item.type_id}`] = String(item.value)
            })

        const newState = {
            datetimes: {
                plannedFrom: point.plannedFrom ? datetimeFormatter(point.plannedFrom) : '',
                plannedTo: point.plannedTo ? datetimeFormatter(point.plannedTo) : '',
            },
            gps: {
                gps_lat: point.gps_lat,
                gps_lng: point.gps_lng,
            },
            values,
        }

        this.setState(newState)
    }

    setDefaultValues = points => {
        let totalLoadedPcsCount = 0
        let totalUnloadedPcsPoint = 0

        let totalLoadedWeight = 0
        let totalUnloadedWeight = 0

        let palletExchange = 0

        let temperatureFrom = ''
        let temperatureTo = ''

        points.forEach(point => {
            if (parseInt(point.type_id) === constants.TRANSPORT_POINT_TYPE_LOADING) {
                point.data.forEach(item => {
                    if (parseInt(item.type_id) === constants.TRANSPORT_POINT_DATA_TYPE_PCS_COUNT && !isNaN(parseInt(item.value))) {
                        totalLoadedPcsCount += parseInt(item.value)
                    } else if (parseInt(item.type_id) === constants.TRANSPORT_POINT_DATA_TYPE_WEIGHT && !isNaN(parseInt(item.value))) {
                        totalLoadedWeight += parseInt(item.value)
                    } else if (parseInt(item.type_id) === constants.TRANSPORT_POINT_DATA_TYPE_PALLET_EXCHANGE) {
                        palletExchange = parseInt(item.value)
                    } else if (parseInt(item.type_id) === constants.TRANSPORT_POINT_DATA_TYPE_TEMPERATURE_FROM && item.value !== '') {
                        temperatureFrom = parseInt(item.value)
                    } else if (parseInt(item.type_id) === constants.TRANSPORT_POINT_DATA_TYPE_TEMPERATURE_TO && item.value !== '') {
                        temperatureTo = parseInt(item.value)
                    }
                })
            } else if (parseInt(point.type_id) === constants.TRANSPORT_POINT_TYPE_UNLOADING) {
                point.data.forEach(item => {
                    if (parseInt(item.type_id) === constants.TRANSPORT_POINT_DATA_TYPE_PCS_COUNT && !isNaN(parseInt(item.value))) {
                        totalUnloadedPcsPoint += parseInt(item.value)
                    } else if (parseInt(item.type_id) === constants.TRANSPORT_POINT_DATA_TYPE_WEIGHT && !isNaN(parseInt(item.value))) {
                        totalUnloadedWeight += parseInt(item.value)
                    } else if (parseInt(item.type_id) === constants.TRANSPORT_POINT_DATA_TYPE_PALLET_EXCHANGE) {
                        palletExchange = parseInt(item.value)
                    }
                })
            }
        })

        const values = this.state.values
        if (parseInt(this.props.pointType) === constants.TRANSPORT_POINT_TYPE_LOADING) {
            if (totalUnloadedPcsPoint) {
                values[`data-${constants.TRANSPORT_POINT_DATA_TYPE_PCS_COUNT}`] = Math.max(totalUnloadedPcsPoint - totalLoadedPcsCount, 0)
            }
            if (totalUnloadedWeight) {
                values[`data-${constants.TRANSPORT_POINT_DATA_TYPE_WEIGHT}`] = Math.max(totalUnloadedWeight - totalLoadedWeight, 0)
            }
            if (palletExchange) {
                values[`data-${constants.TRANSPORT_POINT_DATA_TYPE_PALLET_EXCHANGE}`] = '1'
                values[`data-${constants.TRANSPORT_POINT_DATA_TYPE_PALLET_EXCHANGE_COUNT}`] =
                    values[`data-${constants.TRANSPORT_POINT_DATA_TYPE_PCS_COUNT}`]
            }
            if (temperatureFrom !== '') {
                values[`data-${constants.TRANSPORT_POINT_DATA_TYPE_TEMPERATURE_FROM}`] = temperatureFrom
            }
            if (temperatureTo !== '') {
                values[`data-${constants.TRANSPORT_POINT_DATA_TYPE_TEMPERATURE_TO}`] = temperatureTo
            }
        } else if (parseInt(this.props.pointType) === constants.TRANSPORT_POINT_TYPE_UNLOADING) {
            if (totalLoadedPcsCount) {
                values[`data-${constants.TRANSPORT_POINT_DATA_TYPE_PCS_COUNT}`] = Math.max(totalLoadedPcsCount - totalUnloadedPcsPoint, 0)
            }
            if (totalLoadedWeight) {
                values[`data-${constants.TRANSPORT_POINT_DATA_TYPE_WEIGHT}`] = Math.max(totalLoadedWeight - totalUnloadedWeight, 0)
            }
            if (palletExchange) {
                values[`data-${constants.TRANSPORT_POINT_DATA_TYPE_PALLET_EXCHANGE}`] = '1'
                values[`data-${constants.TRANSPORT_POINT_DATA_TYPE_PALLET_EXCHANGE_COUNT}`] =
                    values[`data-${constants.TRANSPORT_POINT_DATA_TYPE_PCS_COUNT}`]
            }
        }

        this.setState({
            values,
        })
    }

    closeAddressesPicker = e => {
        e && e.preventDefault()
        this.setState({ showAddressesPicker: false })
    }

    openAddressesPicker = e => {
        e && e.preventDefault()
        this.setState({ showAddressesPicker: true })
    }

    handleSelectAddress = address => {
        this.closeAddressesPicker()

        this.setState(
            {
                values: {
                    ...this.state.values,
                    name: address.name,
                    street: address.street,
                    city: address.city,
                    zipcode: address.zipcode,
                    country_code: address.country && address.country.iso_code,
                    is_booking_required: address.is_booking_required,
                    booking_url: address.booking_url,
                    booking_note: address.booking_note,
                },
                gps:
                    address.gps_lat && address.gps_lng
                        ? {
                              gps_lat: address.gps_lat,
                              gps_lng: address.gps_lng,
                          }
                        : null,
            },
            () => {
                this.getFixedMandatoryFields()
            }
        )
    }

    handleSaveAddress = e => {
        e && e.preventDefault()

        const values = {
            name: this.state.values.name,
            street: this.state.values.street,
            city: this.state.values.city,
            zipcode: this.state.values.zipcode,
            country_code: this.state.values.country_code,
            is_booking_required: this.state.values.is_booking_required,
            booking_url: this.state.values.booking_url,
            booking_note: this.state.values.booking_note,
            gps_lat: this.state.gps && this.state.gps.gps_lat,
            gps_lng: this.state.gps && this.state.gps.gps_lng,
        }

        this.props.handleSaveAddress && this.props.handleSaveAddress(values)
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.point && !this.point) {
            this.point = nextProps.point
            this.setPoint(nextProps.point)

            if (nextProps.point.gps_lat && nextProps.point.gps_lng) {
                this.setGPS({
                    gps_lat: nextProps.point.gps_lat,
                    gps_lng: nextProps.point.gps_lng,
                })
            }
        } else if (!this.point) {
            this.setDefaultValues(nextProps.points)
        }
    }

    render() {
        const booleanOptions = [
            {
                id: '0',
                name: this.props.intl.formatMessage({ id: 'fields.no' }),
            },
            {
                id: '1',
                name: this.props.intl.formatMessage({ id: 'fields.yes' }),
            },
        ]

        const gps = this.state.gps &&
            this.state.gps.gps_lat &&
            this.state.gps.gps_lng && {
                lat: this.state.gps.gps_lat,
                lng: this.state.gps.gps_lng,
            }

        const momentPlannedFrom = convertCSDatetimeToMoment(this.state.datetimes.plannedFrom)
        const momentPlannedTo = convertCSDatetimeToMoment(this.state.datetimes.plannedTo)

        const emptyMandatoryFields = this.state.emptyMandatoryFields
        const fixedMandatoryFields = this.state.fixedMandatoryFields

        return (
            <div className="row">
                <div style={{ display: this.state.showAddressesPicker ? 'none' : 'block' }}>
                    <div className="col-md-6 b-r" style={{ minHeight: '70vh' }}>
                        <HEREMapAddressPicker
                            appId={config.CFG_HERE_MAPS_APP_ID}
                            appCode={config.CFG_HERE_MAPS_APP_CODE}
                            markerCenter={gps}
                            className="h-700"
                            handleMarkerPositionChanged={this.handleMarkerPositionChanged}
                            handleAddressChanged={this.handleAddressChanged}
                            showPOIs
                        />
                    </div>
                    <div className="col-md-6">
                        <PermissionsCheck hasAny={Object.values(PERMS_ROUTES)}>
                            <Form
                                values={this.state.values}
                                validationRules={this.validationRules}
                                onChange={this.handleChangeValues}
                                onSubmit={this.handleSubmit}
                                isEdit={Boolean(this.props.point)}
                            >
                                <div className="row">
                                    <div className="col-md-6 b-r">
                                        <div className="row m-b-xs">
                                            <div className="col-md-4">
                                                <strong>
                                                    <i className="far fa-map-marker-alt" /> <FormattedMessage id="fields.address" />
                                                </strong>
                                            </div>
                                            <div className="col-md-8 text-right">
                                                <button
                                                    onClick={this.openAddressesPicker}
                                                    className="btn btn-addon btn-xs btn-default"
                                                    style={{ marginTop: '-7px' }}
                                                >
                                                    <i className="far fa-search" />
                                                    <FormattedMessage id="buttons.findAddress" />
                                                </button>
                                                <button
                                                    onClick={this.handleSaveAddress}
                                                    className="btn btn-addon btn-xs btn-default m-l-xxs"
                                                    style={{ marginTop: '-7px' }}
                                                    disabled={!(this.state.gps && this.state.values.name)}
                                                >
                                                    <i className="far fa-check" />
                                                    <FormattedMessage id="buttons.saveAddress" />
                                                </button>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <InputField
                                                    formGroupClassName={`${emptyMandatoryFields.indexOf('name') !== -1 ? 'has-error' : ''} ${
                                                        fixedMandatoryFields.indexOf('name') !== -1 ? 'has-error-fixed' : ''
                                                    }`}
                                                    id="name"
                                                    label={`${this.props.intl.formatMessage({ id: 'fields.companyName' })}:`}
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <InputField id="street" label={this.props.intl.formatMessage({ id: 'fields.street' })} />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-4">
                                                <InputField id="zipcode" label={this.props.intl.formatMessage({ id: 'fields.zipcode' })} />
                                            </div>
                                            <div className="col-md-8">
                                                <InputField id="city" label={this.props.intl.formatMessage({ id: 'fields.city' })} />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-4">
                                                <InputField id="country_code" label={this.props.intl.formatMessage({ id: 'fields.country' })} />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div
                                                    className={`form-group ${emptyMandatoryFields.indexOf('gps') !== -1 ? 'has-error' : ''} ${
                                                        fixedMandatoryFields.indexOf('gps') !== -1 ? 'has-error-fixed' : ''
                                                    }`}
                                                >
                                                    <label htmlFor="value_gps">
                                                        <FormattedMessage id="fields.gps" />:
                                                    </label>
                                                    <span className="form-control input-readonly">
                                                        {this.state.gps ? `${this.state.gps.gps_lat}, ${this.state.gps.gps_lng}` : ''}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <InputField id="note" label={`${this.props.intl.formatMessage({ id: 'fields.note' })}:`} autosize />

                                        {this.props.showBookingTime && (
                                            <div className="m-t-md">
                                                <div className="m-b-xs">
                                                    <strong>
                                                        <i className="far fa-calendar" /> <FormattedMessage id="fields.booking" />
                                                    </strong>
                                                </div>
                                                <div className="form-part">
                                                    <CheckboxField
                                                        id="is_booking_required"
                                                        label={this.props.intl.formatMessage({ id: 'fields.bookingRequired' })}
                                                    />
                                                    {Boolean(this.state.values.is_booking_required) && (
                                                        <div>
                                                            <InputField
                                                                id="booking_url"
                                                                label={this.props.intl.formatMessage({ id: 'fields.bookingURL' })}
                                                                formGroupClassName={`${
                                                                    emptyMandatoryFields.indexOf('booking_url') !== -1 ? 'has-error' : ''
                                                                } ${fixedMandatoryFields.indexOf('booking_url') !== -1 ? 'has-error-fixed' : ''}`}
                                                            />
                                                            <InputField
                                                                id="booking_note"
                                                                label={this.props.intl.formatMessage({ id: 'fields.bookingNote' })}
                                                                autosize
                                                            />
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                    </div>

                                    <div className="col-md-6">
                                        {!this.props.noDatetimes && (
                                            <div>
                                                <div className="m-b-xs">
                                                    <strong>
                                                        <i className="far fa-clock" /> <FormattedMessage id="fields.times" />
                                                    </strong>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div
                                                            className={`form-group ${
                                                                emptyMandatoryFields.indexOf('datetimesFrom') !== -1 ? 'has-error' : ''
                                                            } ${fixedMandatoryFields.indexOf('datetimesFrom') !== -1 ? 'has-error-fixed' : ''}`}
                                                        >
                                                            <label htmlFor="value_planned_time_from">
                                                                <FormattedMessage id="fields.plannedTimeFrom" />:
                                                            </label>
                                                            <DatePicker
                                                                id="value_planned_time_from"
                                                                dateFormat="DD.MM.YYYY HH:mm"
                                                                showTimeSelect
                                                                shouldCloseOnSelect={false}
                                                                timeFormat="HH:mm"
                                                                timeIntervals={15}
                                                                autoComplete="off"
                                                                popperClassName="react-datepicker-with-time"
                                                                selected={momentPlannedFrom}
                                                                value={this.state.datetimes.plannedFrom}
                                                                onChange={value => {
                                                                    this.handleDatetimeChange('plannedFrom', value ? datetimeFormatter(value) : '')
                                                                }}
                                                                onChangeRaw={e => {
                                                                    this.handleDatetimeChange('plannedFrom', e.target.value)
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div
                                                            className={`form-group ${
                                                                emptyMandatoryFields.indexOf('datetimesTo') !== -1 ? 'has-error' : ''
                                                            } ${fixedMandatoryFields.indexOf('datetimesTo') !== -1 ? 'has-error-fixed' : ''}`}
                                                        >
                                                            <label htmlFor="value_planned_time_to">
                                                                <FormattedMessage id="fields.to" />:
                                                            </label>
                                                            <DatePicker
                                                                id="value_planned_time_to"
                                                                dateFormat="DD.MM.YYYY HH:mm"
                                                                showTimeSelect
                                                                shouldCloseOnSelect={false}
                                                                timeFormat="HH:mm"
                                                                timeIntervals={15}
                                                                autoComplete="off"
                                                                popperClassName="react-datepicker-with-time"
                                                                selected={momentPlannedTo}
                                                                value={this.state.datetimes.plannedTo}
                                                                disabled={!this.state.datetimes.plannedFrom}
                                                                onChange={value => {
                                                                    this.handleDatetimeChange('plannedTo', value ? datetimeFormatter(value) : '')
                                                                }}
                                                                onChangeRaw={e => {
                                                                    this.handleDatetimeChange('plannedTo', e.target.value)
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}

                                        {this.props.dataFields && Object.keys(this.props.dataFields).length > 0 && (
                                            <div className="m-t-md">
                                                <div className="m-b-xs">
                                                    <strong>
                                                        <i className="far fa-info-circle" /> <FormattedMessage id="fields.details" />
                                                    </strong>
                                                </div>
                                                <div className="row">
                                                    {this.props.dataFields &&
                                                        Object.keys(this.props.dataFields).map(key => {
                                                            const field = this.props.dataFields[key]

                                                            if (field.disabled) {
                                                                return null
                                                            }

                                                            const formGroupClassNames = []
                                                            if (emptyMandatoryFields.indexOf(`data-${key}`) !== -1) {
                                                                formGroupClassNames.push('has-error')
                                                            }
                                                            if (fixedMandatoryFields.indexOf(`data-${key}`) !== -1) {
                                                                formGroupClassNames.push('has-error-fixed')
                                                            }

                                                            let input = (
                                                                <InputField
                                                                    id={`data-${key}`}
                                                                    label={`${this.props.intl.formatMessage({ id: field.label })}:`}
                                                                    formGroupClassName={formGroupClassNames.join(' ')}
                                                                />
                                                            )

                                                            if (field.type && field.type === 'bool') {
                                                                input = (
                                                                    <SelectField
                                                                        id={`data-${key}`}
                                                                        label={`${this.props.intl.formatMessage({ id: field.label })}:`}
                                                                        values={booleanOptions}
                                                                        prompt={false}
                                                                        formGroupClassName={formGroupClassNames.join(' ')}
                                                                    />
                                                                )
                                                            } else if (field.type && field.type === 'select') {
                                                                const values = field.values.map(value => ({
                                                                    id: value.id,
                                                                    name: this.props.intl.formatMessage({ id: value.name }),
                                                                }))

                                                                input = (
                                                                    <SelectField
                                                                        id={`data-${key}`}
                                                                        label={`${this.props.intl.formatMessage({ id: field.label })}:`}
                                                                        values={values}
                                                                        prompt={'-'}
                                                                        nullable
                                                                        formGroupClassName={formGroupClassNames.join(' ')}
                                                                    />
                                                                )
                                                            }

                                                            if (field.unit) {
                                                                return (
                                                                    <div key={key} className={field.className || 'col-md-12'}>
                                                                        <div className="pull-left wp-60">{input}</div>
                                                                        <div className="pull-left wp-40 no-label">
                                                                            <span className="input-unit">{field.unit}</span>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            }

                                                            return (
                                                                <div key={key} className={field.className || 'col-md-12'}>
                                                                    {input}
                                                                </div>
                                                            )
                                                        })}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>

                                <div className="btns-form">
                                    <SubmitButton
                                        isEdit={Boolean(this.props.point)}
                                        perms={[PERMS_ROUTES.CREATE, PERMS_ROUTES.UPDATE]}
                                        className="btn-addon pull-right"
                                    />
                                </div>
                            </Form>
                        </PermissionsCheck>
                    </div>
                </div>
                {this.state.showAddressesPicker && (
                    <div>
                        <div className="col-md-12" style={{ minHeight: '70vh' }}>
                            <CustomerAddressesPicker onSelectAddress={this.handleSelectAddress} />
                            <div className="wp-100 pull-left text-right m-t-sm">
                                <button onClick={this.closeAddressesPicker} className="btn btn-default">
                                    <FormattedMessage id="buttons.back" />
                                </button>
                            </div>
                        </div>
                    </div>
                )}

                <ConfirmDialog options={this.state.confirmDialog} level={2} />
            </div>
        )
    }
}

export default injectIntl(TransportTemplatesEditRoutePointEdit)
