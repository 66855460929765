import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import moment from 'moment'

import { PeriodPicker } from '../../../common/period_picker'
import * as periodPickerConstants from '../../../common/period_picker/constants'
import * as formatters from '../../../common/formatters'

import * as reportCustomerMonthlyPlansActionCreators from '../actionCreators'
import { getYear, getData, getSettings, getFetching } from '../selectors'

import { preparePrerequisitesFetchStatusSelectors } from '../../../common/prerequisites/selectors'
import { preparePrerequisitesActionCreators } from '../../../common/prerequisites/actionCreators'
import { getPrerequisitesArray } from '../../../common/helpers'
import { getActiveFilters } from '../../../common/table/helpers'
import { TableModelColumnFilteringType } from '../../../common/table/model'
import { TableFilterInputField } from '../../../common/table/components'
import { prepareTableActionCreators } from '../../../common/table/actionCreators'
import { createTableModelSelector } from '../../../common/table/selectors'
import PermissionsCheck from '../../auth/components/PermissionsCheck'
import { PERMS } from '../permissions'

const tableIdentifier = 'report_customer_monthly_plans'

const componentIdentifier = 'report_customer_monthly_plans'
const prerequisites = ['company_cost_centers']

const GROUP_BY_DATE_CREATED = 1
const GROUP_BY_DATE_UNLOADING = 2
const GROUP_BY_DATE_LOADING = 3

class ReportCustomerMonthlyPlans extends Component {
    getYear = () => {
        if (this.props.year) {
            return parseInt(this.props.year)
        }

        return parseInt(this.props.match.params.year ? this.props.match.params.year : moment().format('YYYY'))
    }

    // settings
    getDefaultSettings = () => ({
        companyCostCenterID: null,
        groupBy: GROUP_BY_DATE_CREATED,
    })

    getSettings = () => this.props.settings || this.getDefaultSettings()

    // settings handles

    handleChangeCompanyCostCenterID = companyCostCenterID => {
        const settings = {
            ...this.getSettings(),
            companyCostCenterID,
        }

        this.loadData(settings)
    }

    handleChangeGroupBy = groupBy => {
        const settings = {
            ...this.getSettings(),
            groupBy,
        }

        this.loadData(settings)
    }

    refresh = () => {
        this.loadData(this.getSettings())
    }

    // data
    loadData = settings => {
        this.props.actions.fetchReportCustomerMonthlyPlans(this.getYear(), settings || this.getSettings())
    }

    getData = () => this.props.data

    clearData = () => {
        this.props.actions.clearReportCustomerMonthlyPlans()
    }

    // prerequisites
    loadPrerequisites = () => {
        this.props.actions.fetchPrerequisites()
    }

    // filters
    resetFilters = () => {
        this.props.table.resetFilters()
    }

    isFilterActive = () => getActiveFilters(this.props.tableModel).size > 0

    handleChangeYearMonth = format => {
        const year = format.year.toString()
        const path = `/report-customer-monthly-plans/${year}`
        this.props.history.push(path)
    }

    // Export
    handleExport = e => {
        e && e.preventDefault()

        this.props.actions.exportReportCustomerMonthlyPlans(this.getSettings())
    }

    componentDidMount() {
        this.loadData()
        this.loadPrerequisites()
    }

    componentWillUnmount() {
        this.clearData()
        clearTimeout(this.timerRefresh)
    }

    componentWillReceiveProps(nextProps) {
        const parsedYear = parseInt(nextProps.match.params.year ? nextProps.match.params.year : moment().format('YYYY'))

        if (parsedYear !== this.getYear()) {
            this.props.actions.clearReportCustomerMonthlyPlans()
            this.props.actions.fetchReportCustomerMonthlyPlans(parsedYear, this.getSettings())
        }
    }

    render() {
        document.title = formatters.titleFormatter(this.props.intl.formatMessage({ id: 'modules.reportCustomerMonthlyPlans.heading' }))

        const momentFrom = moment(`${this.getYear()}-01-01`, 'YYYY-MM-DD')
        const momentTo = moment(momentFrom).endOf('year')

        const data = this.getData()
        const values = data ? data.data : null
        const settings = this.getSettings()
        const filters = this.props.tableModel.get('filters')
        const companyCostCenters = getPrerequisitesArray(this.props.prerequisites.values.get('company_cost_centers'))

        if (!settings) {
            return null
        }

        const monthKeys = [...Array(12).keys()]
        const monthHeader = ['plannedCount', 'plannedSales', 'plannedProfit', 'count', 'sales', 'profit', 'profitPerc']
        const sumHeader = ['plannedCount', 'plannedSales', 'plannedProfit', 'count', 'sales', 'profit']
        const tableColumnsHeaders = monthKeys.flatMap(x => monthHeader)

        const tableMonthsColumns = [...monthKeys.map(x => x + 1), 'Sum']
        const tableMonthsData = ['planeCount', 'planeSales', 'planeProfit', 'totalCount', 'totalSales', 'totalProfit', 'totalProfitPerc']
        const tableColumns = tableMonthsColumns.flatMap(x => tableMonthsData.flatMap(y => y + x))
        let totals = Object.assign({}, ...tableColumns.map(key => ({ [key]: 0 })))

        /* eslint-disable jsx-a11y/click-events-have-key-events */
        /* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
        return (
            <PermissionsCheck hasAny={Object.values(PERMS)} noPermissionsPage>
                <div className="page-inner">
                    <div id="main-wrapper">
                        <div className="panel panel-white">
                            <div className="panel-body">
                                <div className="wp-100 pull-left m-b-md">
                                    <h4 className="pull-left">
                                        <span className="pull-left">
                                            <FormattedMessage id="modules.reportCustomerMonthlyPlans.heading" />
                                        </span>
                                    </h4>
                                    <div className="btns-list">
                                        <div className="pull-left w-200 m-r-sm">
                                            <select
                                                value={settings.companyCostCenterID}
                                                onChange={e => this.handleChangeCompanyCostCenterID(e.target.value)}
                                                className="form-control pull-left"
                                            >
                                                <option value="0">Všechna střediska</option>
                                                {companyCostCenters.map(item => (
                                                    <option key={`company-cost-center-${item.id}`} value={item.id}>
                                                        {item.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>

                                        <div className="pull-left w-200 m-r-sm">
                                            <select
                                                value={settings.groupBy}
                                                onChange={e => this.handleChangeGroupBy(e.target.value)}
                                                className="form-control pull-left"
                                            >
                                                <option value={GROUP_BY_DATE_CREATED}>Dle data vytvoření</option>
                                                <option value={GROUP_BY_DATE_LOADING}>Dle data nakládky</option>
                                                <option value={GROUP_BY_DATE_UNLOADING}>Dle data vykládky</option>
                                            </select>
                                        </div>

                                        <PeriodPicker
                                            className={'m-r-sm'}
                                            momentFrom={momentFrom}
                                            momentTo={momentTo}
                                            onChange={(momentFrom, momentTo, format) => this.handleChangeYearMonth(format)}
                                            allowedPickers={[periodPickerConstants.PERIOD_PICKER_TYPE_YEAR]}
                                            forbidPickType
                                        />

                                        {this.isFilterActive() > 0 && (
                                            <button className="btn btn-default btn-addon m-r-xs" onClick={this.resetFilters}>
                                                <i className="far fa-times" /> <FormattedMessage id="buttons.resetFilters" />
                                            </button>
                                        )}

                                        <button className="btn btn-default btn-addon m-r-xs" onClick={this.refresh}>
                                            <i className="far fa-sync-alt" /> <FormattedMessage id="buttons.refresh" />
                                        </button>
                                        <PermissionsCheck has={[PERMS.EXPORT]}>
                                            <button className="btn btn-primary btn-addon" onClick={this.handleExport}>
                                                <i className="far fa-file-excel" /> <FormattedMessage id="buttons.exportToXLS" />
                                            </button>
                                        </PermissionsCheck>
                                    </div>
                                </div>

                                <div className="table-container">
                                    <table className="table table-striped table-hover table-fixed-header">
                                        <thead>
                                            <tr>
                                                <th className="w-max wm-200" />
                                                <th className="w-160" />
                                                <th className="w-160" />

                                                {monthKeys.map(key => {
                                                    const month = moment().set('month', key)

                                                    return (
                                                        <th key={`month-${key}`} className="w-700 th-spanned text-center" colSpan={7}>
                                                            {this.props.intl.formatMessage({ id: `monthNames.${month.format('MM')}` })}
                                                        </th>
                                                    )
                                                })}

                                                <th className="w-600 th-spanned text-center" colSpan={6}>
                                                    <FormattedMessage id="fields.total" />
                                                </th>
                                            </tr>
                                            <tr className="th-bordered">
                                                <th className="w-max wm-200">
                                                    <FormattedMessage id="fields.customer" />
                                                </th>
                                                <th className="w-160">
                                                    <FormattedMessage id="fields.dispatcher" />
                                                </th>
                                                <th className="w-160">
                                                    <FormattedMessage id="fields.businessManager" />
                                                </th>

                                                {tableColumnsHeaders.map((key, index) => {
                                                    return (
                                                        <th key={`${index}-months-header-${key}`} className="w-100 text-right">
                                                            {this.props.intl.formatMessage({ id: `fields.${key}` })}
                                                        </th>
                                                    )
                                                })}

                                                {sumHeader.map(key => {
                                                    return (
                                                        <th key={`sum-months-header-${key}`} className="w-100 text-right">
                                                            {this.props.intl.formatMessage({ id: `fields.${key}` })}
                                                        </th>
                                                    )
                                                })}
                                            </tr>
                                            <tr className="filters">
                                                <th className="w-max wm-200">
                                                    <TableFilterInputField
                                                        identifier="customer_name"
                                                        type={TableModelColumnFilteringType.string}
                                                        filters={filters}
                                                        onChange={this.props.table.changeFilter}
                                                    />
                                                </th>
                                                <th className="w-160">
                                                    <TableFilterInputField
                                                        identifier="dispatcher_name"
                                                        type={TableModelColumnFilteringType.string}
                                                        filters={filters}
                                                        onChange={this.props.table.changeFilter}
                                                    />
                                                </th>
                                                <th className="w-160">
                                                    <TableFilterInputField
                                                        identifier="business_manager_name"
                                                        type={TableModelColumnFilteringType.string}
                                                        filters={filters}
                                                        onChange={this.props.table.changeFilter}
                                                    />
                                                </th>

                                                {tableColumnsHeaders.map((key, index) => {
                                                    return <th key={`${index}-month-header-${key}`} className="w-100" />
                                                })}
                                                {sumHeader.map(key => {
                                                    return <th key={`$month-header-${key}`} className="w-100" />
                                                })}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {values &&
                                                values.map(row => {
                                                    // filters
                                                    if (
                                                        filters.getIn(['customer_name', 'value']) &&
                                                        row.customersName.toLowerCase().indexOf(filters.getIn(['customer_name', 'value']).toLowerCase()) <
                                                            0
                                                    ) {
                                                        return null
                                                    }

                                                    if (
                                                        filters.getIn(['dispatcher_name', 'value']) &&
                                                        row.dispatchersName
                                                            .toLowerCase()
                                                            .indexOf(filters.getIn(['dispatcher_name', 'value']).toLowerCase()) < 0
                                                    ) {
                                                        return null
                                                    }

                                                    if (
                                                        filters.getIn(['business_manager_name', 'value']) &&
                                                        row.businessManagersName
                                                            .toLowerCase()
                                                            .indexOf(filters.getIn(['business_manager_name', 'value']).toLowerCase()) < 0
                                                    ) {
                                                        return null
                                                    }

                                                    // totals

                                                    for (var key in row.data) {
                                                        totals[key] += row.data[key]
                                                    }

                                                    return (
                                                        <tr key={`customer-${row.customersId}`}>
                                                            <td className="w-max wm-200">{row.customersName}</td>
                                                            <td className="w-160">{row.dispatchersName}</td>
                                                            <td className="w-160">{row.businessManagersName}</td>

                                                            <td className="w-100 text-right">{formatters.numberFormatter(row.data.planeCount1)}</td>
                                                            <td className="w-100 text-right">{formatters.priceFormatter(row.data.planeSales1)}</td>
                                                            <td className="w-100 text-right">{formatters.priceFormatter(row.data.planeProfit1)}</td>
                                                            <td className="w-100 text-right">{formatters.numberFormatter(row.data.totalCount1)}</td>
                                                            <td className="w-100 text-right">{formatters.priceFormatter(row.data.totalSales1)}</td>
                                                            <td className="w-100 text-right">{formatters.priceFormatter(row.data.totalProfit1)}</td>
                                                            <td className="w-100 text-right">{formatters.percentFormatter(row.data.totalProfitPerc1)}</td>

                                                            <td className="w-100 text-right">{formatters.numberFormatter(row.data.planeCount2)}</td>
                                                            <td className="w-100 text-right">{formatters.priceFormatter(row.data.planeSales2)}</td>
                                                            <td className="w-100 text-right">{formatters.priceFormatter(row.data.planeProfit2)}</td>
                                                            <td className="w-100 text-right">{formatters.numberFormatter(row.data.totalCount2)}</td>
                                                            <td className="w-100 text-right">{formatters.priceFormatter(row.data.totalSales2)}</td>
                                                            <td className="w-100 text-right">{formatters.priceFormatter(row.data.totalProfit2)}</td>
                                                            <td className="w-100 text-right">{formatters.percentFormatter(row.data.totalProfitPerc2)}</td>

                                                            <td className="w-100 text-right">{formatters.numberFormatter(row.data.planeCount3)}</td>
                                                            <td className="w-100 text-right">{formatters.priceFormatter(row.data.planeSales3)}</td>
                                                            <td className="w-100 text-right">{formatters.priceFormatter(row.data.planeProfit3)}</td>
                                                            <td className="w-100 text-right">{formatters.numberFormatter(row.data.totalCount3)}</td>
                                                            <td className="w-100 text-right">{formatters.priceFormatter(row.data.totalSales3)}</td>
                                                            <td className="w-100 text-right">{formatters.priceFormatter(row.data.totalProfit3)}</td>
                                                            <td className="w-100 text-right">{formatters.percentFormatter(row.data.totalProfitPerc3)}</td>

                                                            <td className="w-100 text-right">{formatters.numberFormatter(row.data.planeCount4)}</td>
                                                            <td className="w-100 text-right">{formatters.priceFormatter(row.data.planeSales4)}</td>
                                                            <td className="w-100 text-right">{formatters.priceFormatter(row.data.planeProfit4)}</td>
                                                            <td className="w-100 text-right">{formatters.numberFormatter(row.data.totalCount4)}</td>
                                                            <td className="w-100 text-right">{formatters.priceFormatter(row.data.totalSales4)}</td>
                                                            <td className="w-100 text-right">{formatters.priceFormatter(row.data.totalProfit4)}</td>
                                                            <td className="w-100 text-right">{formatters.percentFormatter(row.data.totalProfitPerc4)}</td>

                                                            <td className="w-100 text-right">{formatters.numberFormatter(row.data.planeCount5)}</td>
                                                            <td className="w-100 text-right">{formatters.priceFormatter(row.data.planeSales5)}</td>
                                                            <td className="w-100 text-right">{formatters.priceFormatter(row.data.planeProfit5)}</td>
                                                            <td className="w-100 text-right">{formatters.numberFormatter(row.data.totalCount5)}</td>
                                                            <td className="w-100 text-right">{formatters.priceFormatter(row.data.totalSales5)}</td>
                                                            <td className="w-100 text-right">{formatters.priceFormatter(row.data.totalProfit5)}</td>
                                                            <td className="w-100 text-right">{formatters.percentFormatter(row.data.totalProfitPerc5)}</td>

                                                            <td className="w-100 text-right">{formatters.numberFormatter(row.data.planeCount6)}</td>
                                                            <td className="w-100 text-right">{formatters.priceFormatter(row.data.planeSales6)}</td>
                                                            <td className="w-100 text-right">{formatters.priceFormatter(row.data.planeProfit6)}</td>
                                                            <td className="w-100 text-right">{formatters.numberFormatter(row.data.totalCount6)}</td>
                                                            <td className="w-100 text-right">{formatters.priceFormatter(row.data.totalSales6)}</td>
                                                            <td className="w-100 text-right">{formatters.priceFormatter(row.data.totalProfit6)}</td>
                                                            <td className="w-100 text-right">{formatters.percentFormatter(row.data.totalProfitPerc6)}</td>

                                                            <td className="w-100 text-right">{formatters.numberFormatter(row.data.planeCount7)}</td>
                                                            <td className="w-100 text-right">{formatters.priceFormatter(row.data.planeSales7)}</td>
                                                            <td className="w-100 text-right">{formatters.priceFormatter(row.data.planeProfit7)}</td>
                                                            <td className="w-100 text-right">{formatters.numberFormatter(row.data.totalCount7)}</td>
                                                            <td className="w-100 text-right">{formatters.priceFormatter(row.data.totalSales7)}</td>
                                                            <td className="w-100 text-right">{formatters.priceFormatter(row.data.totalProfit7)}</td>
                                                            <td className="w-100 text-right">{formatters.percentFormatter(row.data.totalProfitPerc7)}</td>

                                                            <td className="w-100 text-right">{formatters.numberFormatter(row.data.planeCount8)}</td>
                                                            <td className="w-100 text-right">{formatters.priceFormatter(row.data.planeSales8)}</td>
                                                            <td className="w-100 text-right">{formatters.priceFormatter(row.data.planeProfit8)}</td>
                                                            <td className="w-100 text-right">{formatters.numberFormatter(row.data.totalCount8)}</td>
                                                            <td className="w-100 text-right">{formatters.priceFormatter(row.data.totalSales8)}</td>
                                                            <td className="w-100 text-right">{formatters.priceFormatter(row.data.totalProfit8)}</td>
                                                            <td className="w-100 text-right">{formatters.percentFormatter(row.data.totalProfitPerc8)}</td>

                                                            <td className="w-100 text-right">{formatters.numberFormatter(row.data.planeCount9)}</td>
                                                            <td className="w-100 text-right">{formatters.priceFormatter(row.data.planeSales9)}</td>
                                                            <td className="w-100 text-right">{formatters.priceFormatter(row.data.planeProfit9)}</td>
                                                            <td className="w-100 text-right">{formatters.numberFormatter(row.data.totalCount9)}</td>
                                                            <td className="w-100 text-right">{formatters.priceFormatter(row.data.totalSales9)}</td>
                                                            <td className="w-100 text-right">{formatters.priceFormatter(row.data.totalProfit9)}</td>
                                                            <td className="w-100 text-right">{formatters.percentFormatter(row.data.totalProfitPerc9)}</td>

                                                            <td className="w-100 text-right">{formatters.numberFormatter(row.data.planeCount10)}</td>
                                                            <td className="w-100 text-right">{formatters.priceFormatter(row.data.planeSales10)}</td>
                                                            <td className="w-100 text-right">{formatters.priceFormatter(row.data.planeProfit10)}</td>
                                                            <td className="w-100 text-right">{formatters.numberFormatter(row.data.totalCount10)}</td>
                                                            <td className="w-100 text-right">{formatters.priceFormatter(row.data.totalSales10)}</td>
                                                            <td className="w-100 text-right">{formatters.priceFormatter(row.data.totalProfit10)}</td>
                                                            <td className="w-100 text-right">{formatters.percentFormatter(row.data.totalProfitPerc10)}</td>

                                                            <td className="w-100 text-right">{formatters.numberFormatter(row.data.planeCount11)}</td>
                                                            <td className="w-100 text-right">{formatters.priceFormatter(row.data.planeSales11)}</td>
                                                            <td className="w-100 text-right">{formatters.priceFormatter(row.data.planeProfit11)}</td>
                                                            <td className="w-100 text-right">{formatters.numberFormatter(row.data.totalCount11)}</td>
                                                            <td className="w-100 text-right">{formatters.priceFormatter(row.data.totalSales11)}</td>
                                                            <td className="w-100 text-right">{formatters.priceFormatter(row.data.totalProfit11)}</td>
                                                            <td className="w-100 text-right">{formatters.percentFormatter(row.data.totalProfitPerc11)}</td>

                                                            <td className="w-100 text-right">{formatters.numberFormatter(row.data.planeCount12)}</td>
                                                            <td className="w-100 text-right">{formatters.priceFormatter(row.data.planeSales12)}</td>
                                                            <td className="w-100 text-right">{formatters.priceFormatter(row.data.planeProfit12)}</td>
                                                            <td className="w-100 text-right">{formatters.numberFormatter(row.data.totalCount12)}</td>
                                                            <td className="w-100 text-right">{formatters.priceFormatter(row.data.totalSales12)}</td>
                                                            <td className="w-100 text-right">{formatters.priceFormatter(row.data.totalProfit12)}</td>
                                                            <td className="w-100 text-right">{formatters.percentFormatter(row.data.totalProfitPerc12)}</td>

                                                            <td className="w-100 text-right">{formatters.numberFormatter(row.data.planeCountSum)}</td>
                                                            <td className="w-100 text-right">{formatters.priceFormatter(row.data.planeSalesSum)}</td>
                                                            <td className="w-100 text-right">{formatters.priceFormatter(row.data.planeProfitSum)}</td>

                                                            <td className="w-100 text-right">{formatters.numberFormatter(row.data.totalCountSum)}</td>
                                                            <td className="w-100 text-right">{formatters.priceFormatter(row.data.totalSalesSum)}</td>
                                                            <td className="w-100 text-right">{formatters.priceFormatter(row.data.totalProfitSum)}</td>
                                                        </tr>
                                                    )
                                                })}

                                            <tr className="b-top row-sum">
                                                <td className="w-max wm-200">
                                                    <strong>
                                                        <FormattedMessage id="fields.total" />:
                                                    </strong>
                                                </td>
                                                <td className="w-160" />
                                                <td className="w-160" />

                                                <td className="w-100 text-right"><b>{formatters.numberFormatter(totals.planeCount1)}</b></td>
                                                <td className="w-100 text-right"><b>{formatters.priceFormatter(totals.planeSales1)}</b></td>
                                                <td className="w-100 text-right"><b>{formatters.priceFormatter(totals.planeProfit1)}</b></td>
                                                <td className="w-100 text-right"><b>{formatters.numberFormatter(totals.totalCount1)}</b></td>
                                                <td className="w-100 text-right"><b>{formatters.priceFormatter(totals.totalSales1)}</b></td>
                                                <td className="w-100 text-right"><b>{formatters.priceFormatter(totals.totalProfit1)}</b></td>
                                                <td className="w-100 text-right"><b>{formatters.percentFormatter(totals.totalSales1 ? (totals.totalProfit1 / totals.totalSales1) * 100 : 0)}</b></td>

                                                <td className="w-100 text-right"><b>{formatters.numberFormatter(totals.planeCount2)}</b></td>
                                                <td className="w-100 text-right"><b>{formatters.priceFormatter(totals.planeSales2)}</b></td>
                                                <td className="w-100 text-right"><b>{formatters.priceFormatter(totals.planeProfit2)}</b></td>
                                                <td className="w-100 text-right"><b>{formatters.numberFormatter(totals.totalCount2)}</b></td>
                                                <td className="w-100 text-right"><b>{formatters.priceFormatter(totals.totalSales2)}</b></td>
                                                <td className="w-100 text-right"><b>{formatters.priceFormatter(totals.totalProfit2)}</b></td>
                                                <td className="w-100 text-right"><b>{formatters.percentFormatter(totals.totalSales2 ? (totals.totalProfit2 / totals.totalSales2) * 100 : 0)}</b></td>

                                                <td className="w-100 text-right"><b>{formatters.numberFormatter(totals.planeCount3)}</b></td>
                                                <td className="w-100 text-right"><b>{formatters.priceFormatter(totals.planeSales3)}</b></td>
                                                <td className="w-100 text-right"><b>{formatters.priceFormatter(totals.planeProfit3)}</b></td>
                                                <td className="w-100 text-right"><b>{formatters.numberFormatter(totals.totalCount3)}</b></td>
                                                <td className="w-100 text-right"><b>{formatters.priceFormatter(totals.totalSales3)}</b></td>
                                                <td className="w-100 text-right"><b>{formatters.priceFormatter(totals.totalProfit3)}</b></td>
                                                <td className="w-100 text-right"><b>{formatters.percentFormatter(totals.totalSales3 ? (totals.totalProfit3 / totals.totalSales3) * 100 : 0)}</b></td>

                                                <td className="w-100 text-right"><b>{formatters.numberFormatter(totals.planeCount4)}</b></td>
                                                <td className="w-100 text-right"><b>{formatters.priceFormatter(totals.planeSales4)}</b></td>
                                                <td className="w-100 text-right"><b>{formatters.priceFormatter(totals.planeProfit4)}</b></td>
                                                <td className="w-100 text-right"><b>{formatters.numberFormatter(totals.totalCount4)}</b></td>
                                                <td className="w-100 text-right"><b>{formatters.priceFormatter(totals.totalSales4)}</b></td>
                                                <td className="w-100 text-right"><b>{formatters.priceFormatter(totals.totalProfit4)}</b></td>
                                                <td className="w-100 text-right"><b>{formatters.percentFormatter(totals.totalSales4 ? (totals.totalProfit4 / totals.totalSales4) * 100 : 0)}</b></td>

                                                <td className="w-100 text-right"><b>{formatters.numberFormatter(totals.planeCount5)}</b></td>
                                                <td className="w-100 text-right"><b>{formatters.priceFormatter(totals.planeSales5)}</b></td>
                                                <td className="w-100 text-right"><b>{formatters.priceFormatter(totals.planeProfit5)}</b></td>
                                                <td className="w-100 text-right"><b>{formatters.numberFormatter(totals.totalCount5)}</b></td>
                                                <td className="w-100 text-right"><b>{formatters.priceFormatter(totals.totalSales5)}</b></td>
                                                <td className="w-100 text-right"><b>{formatters.priceFormatter(totals.totalProfit5)}</b></td>
                                                <td className="w-100 text-right"><b>{formatters.percentFormatter(totals.totalSales5 ? (totals.totalProfit5 / totals.totalSales5) * 100 : 0)}</b></td>

                                                <td className="w-100 text-right"><b>{formatters.numberFormatter(totals.planeCount6)}</b></td>
                                                <td className="w-100 text-right"><b>{formatters.priceFormatter(totals.planeSales6)}</b></td>
                                                <td className="w-100 text-right"><b>{formatters.priceFormatter(totals.planeProfit6)}</b></td>
                                                <td className="w-100 text-right"><b>{formatters.numberFormatter(totals.totalCount6)}</b></td>
                                                <td className="w-100 text-right"><b>{formatters.priceFormatter(totals.totalSales6)}</b></td>
                                                <td className="w-100 text-right"><b>{formatters.priceFormatter(totals.totalProfit6)}</b></td>
                                                <td className="w-100 text-right"><b>{formatters.percentFormatter(totals.totalSales6 ? (totals.totalProfit6 / totals.totalSales6) * 100 : 0)}</b></td>

                                                <td className="w-100 text-right"><b>{formatters.numberFormatter(totals.planeCount7)}</b></td>
                                                <td className="w-100 text-right"><b>{formatters.priceFormatter(totals.planeSales7)}</b></td>
                                                <td className="w-100 text-right"><b>{formatters.priceFormatter(totals.planeProfit7)}</b></td>
                                                <td className="w-100 text-right"><b>{formatters.numberFormatter(totals.totalCount7)}</b></td>
                                                <td className="w-100 text-right"><b>{formatters.priceFormatter(totals.totalSales7)}</b></td>
                                                <td className="w-100 text-right"><b>{formatters.priceFormatter(totals.totalProfit7)}</b></td>
                                                <td className="w-100 text-right"><b>{formatters.percentFormatter(totals.totalSales7 ? (totals.totalProfit7 / totals.totalSales7) * 100 : 0)}</b></td>

                                                <td className="w-100 text-right"><b>{formatters.numberFormatter(totals.planeCount8)}</b></td>
                                                <td className="w-100 text-right"><b>{formatters.priceFormatter(totals.planeSales8)}</b></td>
                                                <td className="w-100 text-right"><b>{formatters.priceFormatter(totals.planeProfit8)}</b></td>
                                                <td className="w-100 text-right"><b>{formatters.numberFormatter(totals.totalCount8)}</b></td>
                                                <td className="w-100 text-right"><b>{formatters.priceFormatter(totals.totalSales8)}</b></td>
                                                <td className="w-100 text-right"><b>{formatters.priceFormatter(totals.totalProfit8)}</b></td>
                                                <td className="w-100 text-right"><b>{formatters.percentFormatter(totals.totalSales8 ? (totals.totalProfit8 / totals.totalSales8) * 100 : 0)}</b></td>

                                                <td className="w-100 text-right"><b>{formatters.numberFormatter(totals.planeCount9)}</b></td>
                                                <td className="w-100 text-right"><b>{formatters.priceFormatter(totals.planeSales9)}</b></td>
                                                <td className="w-100 text-right"><b>{formatters.priceFormatter(totals.planeProfit9)}</b></td>
                                                <td className="w-100 text-right"><b>{formatters.numberFormatter(totals.totalCount9)}</b></td>
                                                <td className="w-100 text-right"><b>{formatters.priceFormatter(totals.totalSales9)}</b></td>
                                                <td className="w-100 text-right"><b>{formatters.priceFormatter(totals.totalProfit9)}</b></td>
                                                <td className="w-100 text-right"><b>{formatters.percentFormatter(totals.totalSales9 ? (totals.totalProfit9 / totals.totalSales9) * 100 : 0)}</b></td>

                                                <td className="w-100 text-right"><b>{formatters.numberFormatter(totals.planeCount10)}</b></td>
                                                <td className="w-100 text-right"><b>{formatters.priceFormatter(totals.planeSales10)}</b></td>
                                                <td className="w-100 text-right"><b>{formatters.priceFormatter(totals.planeProfit10)}</b></td>
                                                <td className="w-100 text-right"><b>{formatters.numberFormatter(totals.totalCount10)}</b></td>
                                                <td className="w-100 text-right"><b>{formatters.priceFormatter(totals.totalSales10)}</b></td>
                                                <td className="w-100 text-right"><b>{formatters.priceFormatter(totals.totalProfit10)}</b></td>
                                                <td className="w-100 text-right"><b>{formatters.percentFormatter(totals.totalSales10 ? (totals.totalProfit10 / totals.totalSales10) * 100 : 0)}</b></td>

                                                <td className="w-100 text-right"><b>{formatters.numberFormatter(totals.planeCount11)}</b></td>
                                                <td className="w-100 text-right"><b>{formatters.priceFormatter(totals.planeSales11)}</b></td>
                                                <td className="w-100 text-right"><b>{formatters.priceFormatter(totals.planeProfit11)}</b></td>
                                                <td className="w-100 text-right"><b>{formatters.numberFormatter(totals.totalCount11)}</b></td>
                                                <td className="w-100 text-right"><b>{formatters.priceFormatter(totals.totalSales11)}</b></td>
                                                <td className="w-100 text-right"><b>{formatters.priceFormatter(totals.totalProfit11)}</b></td>
                                                <td className="w-100 text-right"><b>{formatters.percentFormatter(totals.totalSales11 ? (totals.totalProfit11 / totals.totalSales11) * 100 : 0)}</b></td>

                                                <td className="w-100 text-right"><b>{formatters.numberFormatter(totals.planeCount12)}</b></td>
                                                <td className="w-100 text-right"><b>{formatters.priceFormatter(totals.planeSales12)}</b></td>
                                                <td className="w-100 text-right"><b>{formatters.priceFormatter(totals.planeProfit12)}</b></td>
                                                <td className="w-100 text-right"><b>{formatters.numberFormatter(totals.totalCount12)}</b></td>
                                                <td className="w-100 text-right"><b>{formatters.priceFormatter(totals.totalSales12)}</b></td>
                                                <td className="w-100 text-right"><b>{formatters.priceFormatter(totals.totalProfit12)}</b></td>
                                                <td className="w-100 text-right"><b>{formatters.percentFormatter(totals.totalSales12 ? (totals.totalProfit12 / totals.totalSales12) * 100 : 0)}</b></td>

                                                <td className="w-100 text-right"><b>{formatters.numberFormatter(totals.planeCountSum)}</b></td>
                                                <td className="w-100 text-right"><b>{formatters.priceFormatter(totals.planeSalesSum)}</b></td>
                                                <td className="w-100 text-right"><b>{formatters.priceFormatter(totals.planeProfitSum)}</b></td>

                                                <td className="w-100 text-right"><b>{formatters.numberFormatter(totals.totalCountSum)}</b></td>
                                                <td className="w-100 text-right"><b>{formatters.priceFormatter(totals.totalSalesSum)}</b></td>
                                                <td className="w-100 text-right"><b>{formatters.priceFormatter(totals.totalProfitSum)}</b></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </PermissionsCheck>
        )
        /* eslint-enable jsx-a11y/no-noninteractive-element-interactions */
        /* eslint-enable jsx-a11y/click-events-have-key-events */
    }
}

function mapStateToProps(state) {
    return {
        tableModel: createTableModelSelector(tableIdentifier)(state),
        year: getYear(state),
        data: getData(state),
        settings: getSettings(state),
        fetching: getFetching(state),
        prerequisites: preparePrerequisitesFetchStatusSelectors(componentIdentifier, prerequisites, state),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            ...bindActionCreators(
                {
                    ...reportCustomerMonthlyPlansActionCreators,
                    ...preparePrerequisitesActionCreators(componentIdentifier, prerequisites),
                },
                dispatch
            ),
        },
        dispatch,
    }
}

function mergeProps(stateProps, dispatchProps, ownProps) {
    return {
        ...stateProps,
        ...dispatchProps,
        ...ownProps,
        table: bindActionCreators(prepareTableActionCreators(tableIdentifier, stateProps.tableModel), dispatchProps.dispatch),
    }
}

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps,
        mergeProps
    )(withRouter(ReportCustomerMonthlyPlans))
)
