import { Observable } from 'rxjs'

import { request, parseAPIError, DownloadRequest } from '../../common/api'

import * as actionTypes from './actionTypes'
import * as actionCreators from './actionCreators'

// Fetch

const fetchReportCostTypeCostsEpic = action$ =>
    action$.ofType(actionTypes.FETCH).switchMap(action =>
        Observable.concat(
            Observable.of({
                type: actionTypes.FETCH_STARTED,
            }),
            request({
                method: 'GET',
                path: `report-cost-type-costs?year=${action.payload.year}`,
            })
                .switchMap(ajaxResponse => Observable.of(actionCreators.fetchReportCostTypeCostsFulfilled(ajaxResponse.response)))
                .catch(error => Observable.of(actionCreators.fetchReportCostTypeCostsRejected(parseAPIError(error))))
                .takeUntil(action$.ofType(actionTypes.FETCH_CANCELLED, actionTypes.FETCH))
        )
    )

// Export

const exportReportCostTypeCostsEpic = (action$, store) =>
    action$.ofType(actionTypes.EXPORT).switchMap(action => {
        const token = store.getState().auth.get('accessToken')

        new DownloadRequest({
            url: `report-cost-type-costs/export?year=${action.payload.year}&token=${token}`,
        }).run()

        return Observable.concat(
            Observable.of({
                type: actionTypes.EXPORT_FULFILLED,
            })
        )
    })


// Fetch details

const fetchReportCostTypeCostsDetailsEpic = action$ =>
    action$.ofType(actionTypes.FETCH_DETAILS).switchMap(action =>
        Observable.concat(
            Observable.of({
                type: actionTypes.FETCH_DETAILS_STARTED,
            }),
            request({
                method: 'GET',
                // eslint-disable-next-line prettier/prettier
                path: `report-cost-type-costs/details?id=${action.payload.id}&year=${action.payload.year}&month=${action.payload.month}`,
            })
                .switchMap(ajaxResponse => Observable.of(actionCreators.fetchReportCostTypeCostsDetailsFulfilled(ajaxResponse.response)))
                .catch(error => Observable.of(actionCreators.fetchReportCostTypeCostsDetailsRejected(parseAPIError(error))))
                .takeUntil(action$.ofType(actionTypes.FETCH_DETAILS_CANCELLED))
        )
    )

// Export details

const exportReportCostTypeCostsDetailsEpic = (action$, store) =>
    action$.ofType(actionTypes.EXPORT_DETAILS).switchMap(action => {
        const token = store.getState().auth.get('accessToken')

        new DownloadRequest({
            url: `report-cost-type-costs/details/export?id=${action.payload.id}&year=${action.payload.year}&month=${action.payload.month}&token=${token}`,
        }).run()

        return Observable.concat(
            Observable.of({
                type: actionTypes.EXPORT_DETAILS_FULFILLED,
            })
        )
    })

export default [
    fetchReportCostTypeCostsEpic,
    exportReportCostTypeCostsEpic,
    fetchReportCostTypeCostsDetailsEpic,
    exportReportCostTypeCostsDetailsEpic,
]
