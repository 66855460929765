import React, { Component } from 'react'
import { Route, Switch } from 'react-router'
import * as config from './../../common/config'

import Modal from 'react-modal'
import Header from './Header'
import AuthenticationCheck from '../../pages/auth/components/AuthenticationCheck'
import AppNotifications from '../../common/notifications/components/AppNotifications'
import Locale from '../../pages/locale/components'
import Board from '../../pages/board/components'
import Users from '../../pages/users/components'
import UserRoles from '../../pages/user_roles/components'
import UserLogins from '../../pages/user_logins/components'
import BlockedIps from '../../pages/blocked_ips/components'
import GeneralSettings from '../../pages/general_settings/components'
import Countries from '../../pages/countries/components'
import Currencies from '../../pages/currencies/components'
import TransportTypes from '../../pages/transport_types/components'
import CargoTypes from '../../pages/cargo_types/components'
import VehicleTypes from '../../pages/vehicle_types/components'
import TrailerTypes from '../../pages/trailer_types/components'
import Companies from '../../pages/companies/components'
import CompanyCostCenters from '../../pages/company_cost_centers/components'
import Carriers from '../../pages/carriers/components'
import Customers from '../../pages/customers/components'
import Drivers from '../../pages/drivers/components'
import DriversTypes from '../../pages/drivers_types/components'
import Trailers from '../../pages/trailers/components'
import Vehicles from '../../pages/vehicles/components'
import CustomerAddresses from '../../pages/customer_address/components'
import Map from '../../pages/map/components'
import Transports from '../../pages/transports/components'
import SurchargeTypes from '../../pages/surcharge_types/components'
import CustomerAddressTypes from '../../pages/customer_address_types/components'
import TransportTemplates from '../../pages/transport_templates/components'
import VehicleEvents from '../../pages/vehicle_events/components'
import VehicleFuelings from '../../pages/vehicle_fuelings/components'
import TransporeonOffers from '../../pages/transporeon_offers/components'
import VehicleMessagesSidebar from '../../pages/vehicle_messages/components/SideBar'
import ReportVehicleChecks from '../../pages/report_vehicle_checks/components'
import ReportCustomerTurnovers from '../../pages/report_customer_turnovers/components'
import ReportEmptyKilometers from '../../pages/report_empty_kilometers/components'
import ReportPallets from '../../pages/report_pallets/components'
import ReportInvoicingSummary from '../../pages/report_invoicing_summary/components'
import GoodsTypes from '../../pages/goods_types/components'
import DocumentTypes from '../../pages/document_types/components'
import DriverDocuments from '../../pages/driver_documents/components'
import VehicleDocuments from '../../pages/vehicle_documents/components'
import TrailerDocuments from '../../pages/trailer_documents/components'
import Equipments from '../../pages/equipments/components'
import DriverEquipments from '../../pages/driver_equipments/components'
import VehicleEquipments from '../../pages/vehicle_equipments/components'
import TrailerEquipments from '../../pages/trailer_equipments/components'
import DriverTimesheets from '../../pages/driver_timesheets/components'
import ReportCustomerResults from '../../pages/report_customer_results/components'
import ReportCostCenterResults from '../../pages/report_cost_center_results/components'
import ReportTransportInvoicing from '../../pages/report_transport_invoicing/components'
import ReportTransportChanges from '../../pages/report_transport_changes/components'
import ReportTransports from '../../pages/report_transports/components'
import ReportSpeditionTransports from '../../pages/report_spedition_transports/components'
import ReportVehicleKilometersTotal from '../../pages/report_vehicle_kilometers_total/components'
import ReportCostCenterTraffic from '../../pages/report_cost_center_traffic/components'
import ReportCostCenterPerformance from '../../pages/report_cost_center_performance/components'
import ReportVehicleTraffic from '../../pages/report_vehicle_traffic/components'
import ReportVehiclePerformance from '../../pages/report_vehicle_performance/components'
import ReportVehicleFuelings from '../../pages/report_vehicle_fuelings/components'
import ReportDriverAllowances from '../../pages/report_driver_allowances/components'
import ReportDriverPerformanceAnalysis from '../../pages/report_driver_performance_analysis/components'
import ReportDriverHourlyOverview from '../../pages/report_driver_hourly_overview/components'
import ReportDriverTimesheets from '../../pages/report_driver_timesheets/components'
import ReportDriverCosts from '../../pages/report_driver_costs/components'
import ReportTrailerCosts from '../../pages/report_trailer_costs/components'
import ReportTrailerFuelings from '../../pages/report_trailer_fuelings/components'
import ReportVehicleCosts from '../../pages/report_vehicle_costs/components'
import ReportCostCenterCosts from '../../pages/report_cost_center_costs/components'
import ReportCostCenterCost from '../../pages/report_cost_center_cost/components'
import ReportCostCenterProfits from '../../pages/report_cost_center_profits/components'
import TaxRates from '../../pages/tax_rates/components'
import PaymentMethods from '../../pages/payment_methods/components'
import InvoicingOrders from '../../pages/invoicing_orders/components'
import InvoicingInvoices from '../../pages/invoicing_invoices/components'
import PackagingTypes from '../../pages/packaging_types/components'
import TransportConditions from '../../pages/transport_conditions/components'
import InvoiceItemTypes from '../../pages/invoice_item_types/components'
import InvoicingReceivedInvoices from '../../pages/invoicing_received_invoices/components'
import TransportScoring from '../../pages/transport_scoring/components'
import CarrierScoring from '../../pages/carrier_scoring/components'
import ReportCarrierResults from '../../pages/report_carrier_results/components'
import ReportCarrierScoring from '../../pages/report_carrier_scoring/components'
import ReportDispatcherKPI from '../../pages/report_dispatcher_kpi/components'
import ReportCarrierUsage from '../../pages/report_carrier_usage/components'
import ReportTransportOrders from '../../pages/report_transport_orders/components'
import ReportCustomerPlans from '../../pages/report_customer_plans/components'
import ReportCustomerMonthlyPlans from '../../pages/report_customer_monthly_plans/components'
import ReportCustomerWeeklyPlans from '../../pages/report_customer_weekly_plans/components'
import BorderCrossings from '../../pages/border_crossings/components'
import Tracing from '../../pages/tracing/components'
import UserProfile from '../../pages/user_profile/components'
import WSNotificationsList from '../../common/notifications/components/WSNotificationsList'
import DietRates from '../../pages/diet_rates/components'
import Costs from '../../pages/costs/components'
import CostTypes from '../../pages/cost_types/components'
import ReportCostTypeCosts from '../../pages/report_cost_types_costs/components'
import ReportVehicleResults from '../../pages/report_vehicle_results/components'
import ReportDriverResults from '../../pages/report_driver_results/components'
import ReportTrailerResults from '../../pages/report_trailer_results/components'
import ReportVehicleProfits from '../../pages/report_vehicle_profits/components'
import SalesTypes from '../../pages/sales_types/components'
import Sales from '../../pages/sales/components'

import './assets/css/bootstrap.css'
import './assets/css/fontawesome-all.min.css'
import './assets/css/modern.css'
import './assets/css/themes/white.css'
import './assets/css/App.css'

class App extends Component {
    mainElement = null

    componentDidMount() {
        document.body.classList.add('page-header-fixed', 'page-horizontal-bar')

        Modal.setAppElement(this.mainElement)
    }

    componentWillUnmount() {
        document.body.classList.remove('page-header-fixed', 'page-horizontal-bar')
    }

    render() {
        return (
            <AuthenticationCheck>
                <main className={'page-content content-wrap ' + config.CFG_SUBDOMAIN} ref={ref => (this.mainElement = ref)}>
                    <AppNotifications />
                    <VehicleMessagesSidebar />

                    <Header>
                        <Switch>
                            <Route path={'/locale'} component={Locale} />
                            <Route path={'/board'} component={Board} />
                            <Route path={'/users'} component={Users} />
                            <Route path={'/user-roles'} component={UserRoles} />
                            <Route path={'/user-logins'} component={UserLogins} />
                            <Route path={'/blocked-ips'} component={BlockedIps} />
                            <Route path={'/general-settings'} component={GeneralSettings} />
                            <Route path={'/countries'} component={Countries} />
                            <Route path={'/currencies'} component={Currencies} />
                            <Route path={'/transport-types'} component={TransportTypes} />
                            <Route path={'/cargo-types'} component={CargoTypes} />
                            <Route path={'/vehicle-types'} component={VehicleTypes} />
                            <Route path={'/trailer-types'} component={TrailerTypes} />
                            <Route path={'/companies'} component={Companies} />
                            <Route path={'/company-cost-centers'} component={CompanyCostCenters} />
                            <Route path={'/carriers'} component={Carriers} />
                            <Route path={'/customers'} component={Customers} />
                            <Route path={'/drivers'} component={Drivers} />
                            <Route path={'/drivers-types'} component={DriversTypes} />
                            <Route path={'/trailers'} component={Trailers} />
                            <Route path={'/vehicles'} component={Vehicles} />
                            <Route path={'/customer-addresses'} component={CustomerAddresses} />
                            <Route path={'/map'} component={Map} />
                            <Route path={'/transports'} component={Transports} />
                            <Route path={'/transport-templates'} component={TransportTemplates} />
                            <Route path={'/surcharge-types'} component={SurchargeTypes} />
                            <Route path={'/customer-address-types'} component={CustomerAddressTypes} />
                            <Route path={'/vehicle-events'} component={VehicleEvents} />
                            <Route path={'/vehicle-fuelings'} component={VehicleFuelings} />
                            <Route path={'/transporeon/offers'} component={TransporeonOffers} />
                            <Route path={'/report-vehicle-checks'} component={ReportVehicleChecks} />
                            <Route path={'/report-customer-turnovers'} component={ReportCustomerTurnovers} />
                            <Route path={'/report-empty-kilometers'} component={ReportEmptyKilometers} />
                            <Route path={'/report-pallets'} component={ReportPallets} />
                            <Route path={'/report-invoicing-summary'} component={ReportInvoicingSummary} />
                            <Route path={'/goods-types'} component={GoodsTypes} />
                            <Route path={'/document-types'} component={DocumentTypes} />
                            <Route path={'/driver-documents'} component={DriverDocuments} />
                            <Route path={'/vehicle-documents'} component={VehicleDocuments} />
                            <Route path={'/trailer-documents'} component={TrailerDocuments} />
                            <Route path={'/equipments'} component={Equipments} />
                            <Route path={'/driver-equipments'} component={DriverEquipments} />
                            <Route path={'/vehicle-equipments'} component={VehicleEquipments} />
                            <Route path={'/trailer-equipments'} component={TrailerEquipments} />
                            <Route path={'/driver-timesheets'} component={DriverTimesheets} />
                            <Route path={'/report-customer-results'} component={ReportCustomerResults} />
                            <Route path={'/report-cost-center-results'} component={ReportCostCenterResults} />
                            <Route path={'/report-transport-changes'} component={ReportTransportChanges} />
                            <Route path={'/report-transports'} component={ReportTransports} />
                            <Route path={'/report-spedition-transports'} component={ReportSpeditionTransports} />
                            <Route path={'/report-vehicle-kilometers-total'} component={ReportVehicleKilometersTotal} />
                            <Route path={'/report-cost-center-traffic'} component={ReportCostCenterTraffic} />
                            <Route path={'/report-cost-center-performance'} component={ReportCostCenterPerformance} />
                            <Route path={'/report-cost-center-profits'} component={ReportCostCenterProfits} />
                            <Route path={'/report-vehicle-traffic'} component={ReportVehicleTraffic} />
                            <Route path={'/report-vehicle-performance'} component={ReportVehiclePerformance} />
                            <Route path={'/report-vehicle-fuelings'} component={ReportVehicleFuelings} />
                            <Route path={'/report-driver-allowances'} component={ReportDriverAllowances} />
                            <Route path={'/report-driver-performance-analysis'} component={ReportDriverPerformanceAnalysis} />
                            <Route path={'/report-driver-hourly-overview'} component={ReportDriverHourlyOverview} />
                            <Route path={'/report-driver-timesheets'} component={ReportDriverTimesheets} />
                            <Route path={'/report-driver-costs'} component={ReportDriverCosts} />
                            <Route path={'/report-trailer-costs'} component={ReportTrailerCosts} />
                            <Route path={'/report-trailer-fuelings'} component={ReportTrailerFuelings} />
                            <Route path={'/report-vehicle-costs'} component={ReportVehicleCosts} />
                            <Route path={'/report-cost-center-costs'} component={ReportCostCenterCosts} />
                            <Route path={'/tax-rates'} component={TaxRates} />
                            <Route path={'/payment-methods'} component={PaymentMethods} />
                            <Route path={'/invoicing-orders'} component={InvoicingOrders} />
                            <Route path={'/invoicing-invoices'} component={InvoicingInvoices} />
                            <Route path={'/packaging-types'} component={PackagingTypes} />
                            <Route path={'/transport-conditions'} component={TransportConditions} />
                            <Route path={'/invoice-item-types'} component={InvoiceItemTypes} />
                            <Route path={'/invoicing-received-invoices'} component={InvoicingReceivedInvoices} />
                            <Route path={'/transport-scoring'} component={TransportScoring} />
                            <Route path={'/carrier-scoring'} component={CarrierScoring} />
                            <Route path={'/report-carrier-results'} component={ReportCarrierResults} />
                            <Route path={'/report-carrier-scoring'} component={ReportCarrierScoring} />
                            <Route path={'/report-dispatcher-kpi'} component={ReportDispatcherKPI} />
                            <Route path={'/report-carrier-usage'} component={ReportCarrierUsage} />
                            <Route path={'/report-transport-invoicing'} component={ReportTransportInvoicing} />
                            <Route path={'/report-transport-orders'} component={ReportTransportOrders} />
                            <Route path={'/report-customer-plans'} component={ReportCustomerPlans} />
                            <Route path={'/report-customer-monthly-plans'} component={ReportCustomerMonthlyPlans} />
                            <Route path={'/report-customer-weekly-plans'} component={ReportCustomerWeeklyPlans} />
                            <Route path={'/report-cost-center-cost'} component={ReportCostCenterCost} />
                            <Route path={'/border-crossings'} component={BorderCrossings} />
                            <Route path={'/tracing'} component={Tracing} />
                            <Route path={'/settings/user-profile'} component={UserProfile} />
                            <Route path={'/notifications'} component={WSNotificationsList} />
                            <Route path={'/diet-rates'} component={DietRates} />
                            <Route path={'/costs'} component={Costs} />
                            <Route path={'/cost-types'} component={CostTypes} />
                            <Route path={'/report-vehicle-results'} component={ReportVehicleResults} />
                            <Route path={'/report-driver-results'} component={ReportDriverResults} />
                            <Route path={'/report-trailer-results'} component={ReportTrailerResults} />
                            <Route path={'/report-vehicle-profits'} component={ReportVehicleProfits} />
                            <Route path={'/report-cost-type-costs'} component={ReportCostTypeCosts} />
                            <Route path={'/sales-types'} component={SalesTypes} />
                            <Route path={'/sales'} component={Sales} />

                            <Route component={Transports} />
                        </Switch>
                    </Header>
                </main>
            </AuthenticationCheck>
        )
    }
}

export default App
