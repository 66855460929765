import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import Notifications from 'react-notification-system-redux'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import { Modal } from 'react-bootstrap'

import 'react-datepicker/dist/react-datepicker.css'

import { Form, SelectField, InputField, SubmitButton } from '../../../common/form'
import * as formatters from '../../../common/formatters'
import {
    url,
    handleCommonEditActions,
    getPrerequisitesArray,
    convertCSDateToISO,
    convertISODateToCS,
    convertCSDateToMoment,
} from '../../../common/helpers'
import { LoadingOverlay } from '../../../common/loading_overlay'

import { preparePrerequisitesActionCreators } from '../../../common/prerequisites/actionCreators'
import { preparePrerequisitesFetchStatusSelectors } from '../../../common/prerequisites/selectors'

import * as trailerEquipmentsActionCreators from '../actionCreators'
import { createGetTrailerEquipmentByIDSelector, createGetCurrentTrailerEquipmentStatusSelector, createGetDuplicitySelector } from '../selectors'
import PermissionsCheck from '../../auth/components/PermissionsCheck'
import { PERMS } from '../permissions'

const componentIdentifier = 'trailer_equipments_edit'
const prerequisites = ['trailers', 'equipments', 'equipments_validity']

class TrailerEquipmentsEditForm extends Component {
    defaultValues = {}

    validationRules = {}

    defaultValidity = {}

    state = {
        values: this.defaultValues,
        datetimes: {
            validity_from: moment().format('DD.MM.YYYY'),
            validity_to: moment().format('DD.MM.YYYY'),
        },
        datetimesSet: false,
        modalDuplicity: {
            show: false,
            duplicity: null,
        },
    }

    setValues = (values, callback) => {
        this.setState(
            {
                values,
            },
            callback
        )
    }

    handleChangeValues = values => {
        this.setValues(values)
    }

    handleDatetimeChange = (key, value) => {
        const newState = {
            datetimes: {
                ...this.state.datetimes,
                [key]: value,
            },
        }

        this.setState(newState, () => {
            if (key === 'validity_from') {
                this.updateValidity()
            }
        })
    }

    handleSubmit = values => {
        this.setValues(values, () => {
            this.handleSave(false)
        })
    }

    handleSave = ignoreDuplicity => {
        this.props.actions.saveTrailerEquipment(
            {
                ...this.state.values,
                date_validity_from: convertCSDateToISO(this.state.datetimes.validity_from),
                date_validity_to: convertCSDateToISO(this.state.datetimes.validity_to),
            },
            ignoreDuplicity
        )
    }

    duplicityFoundCallback = duplicity => {
        this.setState({
            modalDuplicity: {
                show: true,
                duplicity,
            },
        })
    }

    handleDuplicityCancel = () => {
        this.setState({
            modalDuplicity: {
                show: false,
                duplicity: null,
            },
        })
    }

    handleDuplicityConfirm = () => {
        this.handleDuplicityCancel()
        this.handleSave(true)
    }

    successCallback = () => {
        this.props.successCallback ? this.props.successCallback() : this.props.redirect(url(this.props.match, 'trailer-equipments'))
    }

    backCallback = () => {
        this.props.backCallback ? this.props.backCallback() : this.props.redirect(url(this.props.match, 'trailer-equipments'))
    }

    handleEquipmentChange = (fieldId, option) => {
        this.setValues(
            {
                ...this.state.values,
                [fieldId]: option.value,
            },
            this.updateValidity
        )
    }

    updateValidity = () => {
        if (this.state.datetimes.validity_from && this.state.values.equipment_id) {
            const equipmentId = parseInt(this.state.values.equipment_id)
            const validity = this.defaultValidity[equipmentId] ? parseInt(this.defaultValidity[equipmentId]) : 0
            const validityToMoment = convertCSDateToMoment(this.state.datetimes.validity_from)
            validityToMoment.add(validity, 'months')

            this.setState({
                datetimes: {
                    ...this.state.datetimes,
                    validity_to: validityToMoment.format('DD.MM.YYYY'),
                },
            })
        }
    }

    componentDidMount() {
        this.props.trailerEquipmentID && this.props.actions.fetchTrailerEquipment(this.props.trailerEquipmentID)
        this.props.actions.fetchPrerequisites()

        if (this.props.defaultValues) {
            this.setValues(this.props.defaultValues)
        }
    }

    componentWillReceiveProps(nextProps) {
        handleCommonEditActions(this.props, nextProps, this.successCallback, this.duplicityFoundCallback)

        if (nextProps.trailerEquipment && !this.state.datetimesSet) {
            this.setState({
                values: nextProps.trailerEquipment.toJS(),
                datetimes: {
                    validity_from: convertISODateToCS(nextProps.trailerEquipment.date_validity_from),
                    validity_to: convertISODateToCS(nextProps.trailerEquipment.date_validity_to),
                },
                datetimesSet: true,
            })
        }

        if (this.props.prerequisites.status.get('fetching') && !nextProps.prerequisites.status.get('fetching')) {
            this.defaultValidity = {}
            nextProps.prerequisites.values
                .get('equipments_validity')
                .valueSeq()
                .forEach(item => {
                    this.defaultValidity[item.get('id')] = item.get('default_validity')
                })
        }
    }

    render() {
        const duplicity = this.props.duplicity
        const perms = this.props.PERMS ? this.props.PERMS : PERMS

        return (
            <div>
                <LoadingOverlay active={this.props.prerequisites.status.get('fetching') || this.props.status.get('fetching')}>
                    <PermissionsCheck hasAny={Object.values(perms)}>
                        <Form
                            values={this.state.values}
                            validationRules={this.validationRules}
                            onChange={this.handleChangeValues}
                            onSubmit={this.handleSubmit}
                            isEdit={Boolean(this.props.trailerEquipment)}
                        >
                            <div className={this.props.hiddenFields && this.props.hiddenFields.indexOf('trailer_id') !== -1 ? 'hidden' : ''}>
                                <SelectField
                                    id="trailer_id"
                                    label={this.props.intl.formatMessage({ id: 'fields.trailer' })}
                                    values={getPrerequisitesArray(this.props.prerequisites.values.get('trailers'))}
                                    className="wp-50"
                                />
                            </div>
                            <SelectField
                                id="equipment_id"
                                label={this.props.intl.formatMessage({ id: 'fields.equipment' })}
                                values={getPrerequisitesArray(this.props.prerequisites.values.get('equipments'))}
                                className="wp-50"
                                onChange={this.handleEquipmentChange}
                            />
                            <div>
                                <div className="form-group w-200">
                                    <label htmlFor="value_date_validity_from">
                                        <FormattedMessage id="fields.passedAt" />
                                    </label>
                                    <DatePicker
                                        id="value_date_validity_from"
                                        dateFormat="DD.MM.YYYY"
                                        autoComplete="off"
                                        selected={convertCSDateToMoment(this.state.datetimes.validity_from)}
                                        value={this.state.datetimes.validity_from}
                                        onChange={value => {
                                            this.handleDatetimeChange('validity_from', value ? formatters.dateFormatter(value) : '')
                                        }}
                                        onChangeRaw={e => {
                                            this.handleDatetimeChange('validity_from', e.target.value)
                                        }}
                                    />
                                </div>
                            </div>
                            <div>
                                <div className="form-group w-200">
                                    <label htmlFor="value_date_validity_to">
                                        <FormattedMessage id="fields.lifetimeTo" />
                                    </label>
                                    <DatePicker
                                        id="value_date_validity_to"
                                        dateFormat="DD.MM.YYYY"
                                        autoComplete="off"
                                        selected={convertCSDateToMoment(this.state.datetimes.validity_to)}
                                        value={this.state.datetimes.validity_to}
                                        onChange={value => {
                                            this.handleDatetimeChange('validity_to', value ? formatters.dateFormatter(value) : '')
                                        }}
                                        onChangeRaw={e => {
                                            this.handleDatetimeChange('validity_to', e.target.value)
                                        }}
                                    />
                                </div>
                            </div>
                            <InputField id="note" label={this.props.intl.formatMessage({ id: 'fields.note' })} autosize />

                            <div className="btns-form">
                                <button onClick={this.backCallback} className="btn btn-addon btn-default">
                                    <i className="far fa-chevron-left" /> <FormattedMessage id="buttons.back" />
                                </button>
                                <SubmitButton
                                    perms={Object.values(perms)}
                                    isEdit={Boolean(this.props.trailerEquipment)}
                                    className="btn-addon pull-right"
                                />
                            </div>
                        </Form>
                    </PermissionsCheck>
                </LoadingOverlay>

                <Modal show={Boolean(this.state.modalDuplicity.show)} onHide={this.handleDuplicityCancel} bsSize="sm">
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <FormattedMessage id="confirmDialog.duplicityFound.title" />
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <FormattedMessage id="confirmDialog.duplicityFound.message" />
                        {duplicity && (
                            <div>
                                <div className="row m-t-lg">
                                    <div className="col-sm-4">
                                        <FormattedMessage id="fields.trailer" />:
                                    </div>
                                    <div className="col-sm-8 text-right">
                                        <strong>{duplicity.trailer}</strong>
                                    </div>
                                </div>
                                <hr className="tiny" />
                                <div className="row">
                                    <div className="col-sm-4">
                                        <FormattedMessage id="fields.equipment" />:
                                    </div>
                                    <div className="col-sm-8 text-right">
                                        <strong>{duplicity.equipment}</strong>
                                    </div>
                                </div>
                                <hr className="tiny" />
                                <div className="row">
                                    <div className="col-sm-4">
                                        <FormattedMessage id="fields.passedAt" />:
                                    </div>
                                    <div className="col-sm-8 text-right">
                                        <strong>{duplicity.date_validity_from}</strong>
                                    </div>
                                </div>
                                <hr className="tiny" />
                                <div className="row">
                                    <div className="col-sm-4">
                                        <FormattedMessage id="fields.lifetimeTo" />:
                                    </div>
                                    <div className="col-sm-8 text-right">
                                        <strong>{duplicity.date_validity_to}</strong>
                                    </div>
                                </div>
                                <hr className="tiny" />
                                <div className="row">
                                    <div className="col-sm-4">
                                        <FormattedMessage id="fields.note" />:
                                    </div>
                                    <div className="col-sm-8 text-right">
                                        <strong>{duplicity.note}</strong>
                                    </div>
                                </div>
                            </div>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-success m-r-xs" onClick={this.handleDuplicityConfirm}>
                            <FormattedMessage id="confirmDialog.duplicityFound.accept" />
                        </button>
                        <button className="btn btn-default" onClick={this.handleDuplicityCancel}>
                            <FormattedMessage id="confirmDialog.duplicityFound.cancel" />
                        </button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}

function mapStateToProps(state, ownProps) {
    return {
        trailerEquipment: createGetTrailerEquipmentByIDSelector(ownProps.trailerEquipmentID)(state),
        status: createGetCurrentTrailerEquipmentStatusSelector()(state),
        duplicity: createGetDuplicitySelector()(state),
        prerequisites: preparePrerequisitesFetchStatusSelectors(componentIdentifier, prerequisites, state),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            ...bindActionCreators(
                {
                    ...trailerEquipmentsActionCreators,
                    ...preparePrerequisitesActionCreators(componentIdentifier, prerequisites),
                },
                dispatch
            ),
        },
        redirect: toURL => dispatch(push(toURL)),
        notify: (notification, type) => dispatch(Notifications.show(notification, type)),
    }
}

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(TrailerEquipmentsEditForm)
)
