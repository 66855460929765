import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import ReactPaginate from 'react-paginate'
import Notifications from 'react-notification-system-redux'
import { Tooltip, OverlayTrigger, Modal } from 'react-bootstrap'
import moment from 'moment'

import { DispatchersBox, TransportStateBox, ColoredBox } from '../../../common/colored_box'
import * as formatters from '../../../common/formatters'
import * as helpers from '../../../common/helpers'

import { prepareTableActionCreators } from '../../../common/table/actionCreators'
import { TableFilterInputField, TableFilterSelectField, TableFilterCheckboxField } from '../../../common/table/components'
import { getActiveFilters, getTotalNumberOfPages } from '../../../common/table/helpers'
import { TableModelColumn, TableModelColumnFilteringType } from '../../../common/table/model'
import { createTableDataSelector, createTableModelSelector } from '../../../common/table/selectors'

import { preparePrerequisitesFetchStatusSelectors } from '../../../common/prerequisites/selectors'
import { preparePrerequisitesActionCreators } from '../../../common/prerequisites/actionCreators'

import * as actionCreators from '../actionCreators'
import { getData, isSetTransportPricesInProgress, getSetTransportPricesError } from '../selectors'
import PermissionsCheck from '../../auth/components/PermissionsCheck'
import { PERMS } from '../permissions'
import {Link} from "react-router-dom";

const tableIdentifier = 'report_transport_invoicing'
const clientSideItemsPerPage = undefined

const componentIdentifier = 'report_transport_invoicing'
const prerequisites = [
    'users',
    'transport_states',
    'transport_types',
    'cargo_types',
    'goods_types',
    'dispatchers',
    'company_cost_centers',
    'active_drivers',
]

const columns = {
    transport_id: new TableModelColumn({}),
}

class ReportTransportInvoicing extends Component {
    state = {
        modalTransportPrices: {
            show: false,
            priceForKm: 0,
            priceFix: 0,
            erasePriceToll: false
        }
    }

    handleDatetimeChange = value => {
        this.props.actions.fetchReportTransportInvoicing(this.props.tableModel.get('filters'), value)
    }

    refresh = () => {
        this.props.actions.fetchReportTransportInvoicing(this.props.tableModel.get('filters'))
    }

    resetFilters = () => {
        const dateFrom = moment().startOf('month')
        const dateTo = moment().endOf('month')

        this.props.table.resetFilters(
            [],
            [
                {
                    column: 'unloading_date_planned_from',
                    type: TableModelColumnFilteringType.equal,
                    value: dateFrom.format('DD.MM.YYYY'),
                },
                {
                    column: 'unloading_date_planned_to',
                    type: TableModelColumnFilteringType.equal,
                    value: dateTo.format('DD.MM.YYYY'),
                },
            ]
        )
    }

    isFilterActive = () => getActiveFilters(this.props.tableModel).size > 0

    openTransportPricesModal = e => {
        e && e.preventDefault()

        this.setState({
            modalTransportPrices: {
                ...this.state.modalTransportPrices,
                show: true
            },
        })
    }

    closeTransportPricesModal = e => {
        e && e.preventDefault()

        this.setState({
            modalTransportPrices: {
                ...this.state.modalTransportPrices,
                show: false,
            },
        })
    }

    setTransportPricesValue = (key, value) => {
        this.setState({
            modalTransportPrices: {
                ...this.state.modalTransportPrices,
                [key]: value
            },
        })
    }

    processTransportPrices = e => {
        this.closeTransportPricesModal()

        this.props.actions.setTransportPrices && this.props.actions.setTransportPrices(
            this.props.data.data.map(item => item.id),
            this.state.modalTransportPrices.priceForKm,
            this.state.modalTransportPrices.priceFix,
            this.state.modalTransportPrices.erasePriceToll
        )
    }

    componentDidMount() {
        const dateFrom = moment().startOf('month')
        const dateTo = moment().endOf('month')

        this.props.table.setConfiguration(
            [
                {
                    column: 'unloading_date_planned_from',
                    type: TableModelColumnFilteringType.equal,
                    value: dateFrom.format('DD.MM.YYYY'),
                },
                {
                    column: 'unloading_date_planned_to',
                    type: TableModelColumnFilteringType.equal,
                    value: dateTo.format('DD.MM.YYYY'),
                },
            ],
            false
        )

        this.props.actions.fetchReportTransportInvoicing(this.props.tableModel.get('filters'))
        this.props.actions.fetchPrerequisites()
    }

    render() {
        document.title = formatters.titleFormatter(this.props.intl.formatMessage({ id: 'modules.reportTransportInvoicing.heading' }))

        const filters = this.props.tableModel.get('filters')

        const tooltipNoTime = <Tooltip id="tooltipNoTime">{this.props.intl.formatMessage({ id: 'fields.noTime' })}</Tooltip>
        const tooltipNoVehicle = <Tooltip id="tooltipNoVehicle">{this.props.intl.formatMessage({ id: 'fields.noVehicle' })}</Tooltip>

        const driversOptions = helpers.getPrerequisitesArray(this.props.prerequisites.values.get('active_drivers'))
        driversOptions.unshift({
            id: 'no_driver',
            name: this.props.intl.formatMessage({ id: 'fields.noDriver' }),
        })

        const invoicingOptions = [
            { id: '1', name: this.props.intl.formatMessage({ id: 'fields.invoiced' }) },
            { id: '0', name: this.props.intl.formatMessage({ id: 'fields.uninvoiced' }) },
        ]

        return (
            <PermissionsCheck hasAny={Object.values(PERMS)} noPermissionsPage>
                <div className="page-inner">
                    <div id="main-wrapper">
                        <div className="panel panel-white">
                            <div className="panel-body">
                                <h4>
                                    <FormattedMessage id="modules.reportTransportInvoicing.heading" />
                                </h4>

                                <div className="pull-left" style={{ marginLeft: '100px' }}>
                                    <TableFilterCheckboxField
                                        identifier="only_speditions"
                                        type={TableModelColumnFilteringType.equal}
                                        filters={filters}
                                        onChange={this.props.table.changeFilter}
                                        label={this.props.intl.formatMessage({ id: 'modules.transports.showOnlySpeditions' })}
                                    />
                                </div>

                                <div className="btns-list">
                                    {this.props.data.data && this.props.data.data.length > 0 ? (
                                        <button className="btn btn-primary btn-addon m-r-xs" onClick={this.openTransportPricesModal}>
                                            <i className="far fa-pencil" /> <FormattedMessage id="buttons.setTransportPrices" />
                                        </button>
                                    ) : null}
                                    {this.isFilterActive() > 0 && (
                                        <button className="btn btn-default btn-addon m-r-xs" onClick={this.resetFilters}>
                                            <i className="far fa-times" /> <FormattedMessage id="buttons.resetFilters" />
                                        </button>
                                    )}
                                    <button className="btn btn-default btn-addon m-r-xs" onClick={this.refresh}>
                                        <i className="far fa-sync-alt" /> <FormattedMessage id="buttons.refresh" />
                                    </button>
                                </div>

                                <div className="table-container">
                                    <table className="table table-striped table-hover table-fixed-header table-transports">
                                        <thead>
                                            <tr>
                                                <th className="w-80">
                                                    <FormattedMessage id="fields.number" />
                                                    <br />
                                                    <small>
                                                        <FormattedMessage id="fields.date" />
                                                    </small>
                                                </th>
                                                <th className="w-100">
                                                    <FormattedMessage id="fields.state" />
                                                    <br />
                                                    <small>
                                                        <FormattedMessage id="fields.disponent" />
                                                    </small>
                                                </th>
                                                <th className="w-max wm-400">
                                                    <FormattedMessage id="fields.customer" />
                                                    <br />
                                                    <small>
                                                        <FormattedMessage id="fields.transportType" /> | <FormattedMessage id="fields.cargoType" /> |{' '}
                                                        <FormattedMessage id="fields.goodsType" />
                                                    </small>
                                                </th>
                                                <th className="w-360">
                                                    <FormattedMessage id="fields.loading" />
                                                    <br />
                                                    <small>
                                                        {' '}
                                                        <FormattedMessage id="fields.plannedTime" /> | <FormattedMessage id="fields.code" />
                                                    </small>
                                                </th>
                                                <th className="w-360">
                                                    <FormattedMessage id="fields.unloading" />
                                                    <br />
                                                    <small>
                                                        <FormattedMessage id="fields.plannedTime" />
                                                    </small>
                                                </th>
                                                <th className="w-110">
                                                    <FormattedMessage id="fields.vehicle" />
                                                    <br />
                                                    <small>
                                                        <FormattedMessage id="fields.spedition" />
                                                    </small>
                                                </th>
                                                <th className="w-110">
                                                    <FormattedMessage id="fields.dispatcher" />
                                                    <br />
                                                    &nbsp;
                                                </th>
                                                <th className="w-110 text-right">
                                                    <FormattedMessage id="fields.price" />
                                                </th>
                                                <th className="w-110 text-right">
                                                    <FormattedMessage id="fields.outgoingInvoices" />
                                                </th>
                                                <th className="w-110 text-right">
                                                    <FormattedMessage id="fields.receivedInvoices" />
                                                </th>
                                            </tr>
                                            <tr className="filters">
                                                <th className="w-80">
                                                    <TableFilterInputField
                                                        identifier="id"
                                                        type={TableModelColumnFilteringType.stringFromStart}
                                                        filters={filters}
                                                        onChange={this.props.table.changeFilter}
                                                        placeholder={this.props.intl.formatMessage({ id: 'fields.number' })}
                                                    />
                                                </th>
                                                <th className="w-100">
                                                    <TableFilterSelectField
                                                        identifier="transport_state_id"
                                                        type={TableModelColumnFilteringType.equal}
                                                        filters={filters}
                                                        onChange={this.props.table.changeFilter}
                                                        values={helpers.getPrerequisitesArray(
                                                            this.props.prerequisites.values.get('transport_states'),
                                                            'id',
                                                            'name',
                                                            this.props.intl,
                                                            'transportStates.'
                                                        )}
                                                        prompt={this.props.intl.formatMessage({ id: 'fields.state' })}
                                                    />
                                                    <div className="m-t-xxs">
                                                        <TableFilterSelectField
                                                            identifier="disponent_id"
                                                            type={TableModelColumnFilteringType.equal}
                                                            filters={filters}
                                                            onChange={this.props.table.changeFilter}
                                                            values={helpers.getPrerequisitesArray(this.props.prerequisites.values.get('users'))}
                                                            prompt={this.props.intl.formatMessage({ id: 'fields.disponent' })}
                                                        />
                                                    </div>
                                                </th>
                                                <th className="w-max wm-400">
                                                    <div className="pull-left wp-100">
                                                        <div className="pull-left m-r-xxs" style={{ width: '225px' }}>
                                                            <TableFilterInputField
                                                                identifier="customer"
                                                                type={TableModelColumnFilteringType.string}
                                                                filters={filters}
                                                                onChange={this.props.table.changeFilter}
                                                                placeholder={this.props.intl.formatMessage({ id: 'fields.customer' })}
                                                            />
                                                        </div>
                                                        <div className="pull-left w-110">
                                                            <TableFilterInputField
                                                                identifier="order_number"
                                                                type={TableModelColumnFilteringType.string}
                                                                filters={filters}
                                                                onChange={this.props.table.changeFilter}
                                                                placeholder={this.props.intl.formatMessage({ id: 'fields.orderNumber' })}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="m-t-xxs w-110 pull-left m-r-xxs">
                                                        <TableFilterSelectField
                                                            identifier="transport_type_id"
                                                            type={TableModelColumnFilteringType.equal}
                                                            filters={filters}
                                                            onChange={this.props.table.changeFilter}
                                                            values={helpers.getPrerequisitesArray(this.props.prerequisites.values.get('transport_types'))}
                                                            prompt={this.props.intl.formatMessage({ id: 'fields.transportType' })}
                                                        />
                                                    </div>
                                                    <div className="m-t-xxs w-110 pull-left m-r-xxs">
                                                        <TableFilterSelectField
                                                            identifier="cargo_type_id"
                                                            type={TableModelColumnFilteringType.equal}
                                                            filters={filters}
                                                            onChange={this.props.table.changeFilter}
                                                            values={helpers.getPrerequisitesArray(this.props.prerequisites.values.get('cargo_types'))}
                                                            prompt={this.props.intl.formatMessage({ id: 'fields.cargoType' })}
                                                        />
                                                    </div>
                                                    <div className="m-t-xxs w-110 pull-left">
                                                        <TableFilterSelectField
                                                            identifier="goods_type_id"
                                                            type={TableModelColumnFilteringType.equal}
                                                            filters={filters}
                                                            onChange={this.props.table.changeFilter}
                                                            values={helpers.getPrerequisitesArray(this.props.prerequisites.values.get('goods_types'))}
                                                            prompt={this.props.intl.formatMessage({ id: 'fields.goodsType' })}
                                                        />
                                                    </div>
                                                </th>
                                                <th className="w-360">
                                                    <TableFilterInputField
                                                        identifier="loading_address"
                                                        type={TableModelColumnFilteringType.string}
                                                        filters={filters}
                                                        onChange={this.props.table.changeFilter}
                                                        placeholder={this.props.intl.formatMessage({ id: 'fields.address' })}
                                                    />
                                                    <div className="m-t-xxs w-90 pull-left m-r-xxs">
                                                        <TableFilterInputField
                                                            identifier="loading_date_planned_from"
                                                            type={TableModelColumnFilteringType.string}
                                                            filters={filters}
                                                            onChange={this.props.table.changeFilter}
                                                            placeholder={this.props.intl.formatMessage({ id: 'fields.from' })}
                                                            datepicker
                                                        />
                                                    </div>
                                                    <div className="m-t-xxs w-90 pull-left">
                                                        <TableFilterInputField
                                                            identifier="loading_date_planned_to"
                                                            type={TableModelColumnFilteringType.string}
                                                            filters={filters}
                                                            onChange={this.props.table.changeFilter}
                                                            placeholder={this.props.intl.formatMessage({ id: 'fields.to' })}
                                                            datepicker
                                                        />
                                                    </div>
                                                    <div className="m-t-xxs w-20 pull-left m-r-xs">
                                                        <OverlayTrigger placement="top" overlay={tooltipNoTime}>
                                                            <div>
                                                                <TableFilterCheckboxField
                                                                    identifier="loading_no_date"
                                                                    type={TableModelColumnFilteringType.equal}
                                                                    filters={filters}
                                                                    onChange={this.props.table.changeFilter}
                                                                    label=""
                                                                />
                                                            </div>
                                                        </OverlayTrigger>
                                                    </div>
                                                    <div className="m-t-xxs pull-left" style={{ width: '99px' }}>
                                                        <TableFilterInputField
                                                            identifier="loading_code"
                                                            type={TableModelColumnFilteringType.string}
                                                            filters={filters}
                                                            onChange={this.props.table.changeFilter}
                                                            placeholder={this.props.intl.formatMessage({ id: 'fields.code' })}
                                                        />
                                                    </div>
                                                </th>
                                                <th className="w-360">
                                                    <TableFilterInputField
                                                        identifier="unloading_address"
                                                        type={TableModelColumnFilteringType.string}
                                                        filters={filters}
                                                        onChange={this.props.table.changeFilter}
                                                        placeholder={this.props.intl.formatMessage({ id: 'fields.address' })}
                                                    />
                                                    <div className="m-t-xxs w-90 pull-left m-r-xxs">
                                                        <TableFilterInputField
                                                            identifier="unloading_date_planned_from"
                                                            type={TableModelColumnFilteringType.string}
                                                            filters={filters}
                                                            onChange={this.props.table.changeFilter}
                                                            placeholder={this.props.intl.formatMessage({ id: 'fields.from' })}
                                                            datepicker
                                                        />
                                                    </div>
                                                    <div className="m-t-xxs w-90 pull-left">
                                                        <TableFilterInputField
                                                            identifier="unloading_date_planned_to"
                                                            type={TableModelColumnFilteringType.string}
                                                            filters={filters}
                                                            onChange={this.props.table.changeFilter}
                                                            placeholder={this.props.intl.formatMessage({ id: 'fields.to' })}
                                                            datepicker
                                                        />
                                                    </div>
                                                    <div className="m-t-xxs w-20 pull-left m-r-xs">
                                                        <OverlayTrigger placement="top" overlay={tooltipNoTime}>
                                                            <div>
                                                                <TableFilterCheckboxField
                                                                    identifier="unloading_no_date"
                                                                    type={TableModelColumnFilteringType.equal}
                                                                    filters={filters}
                                                                    onChange={this.props.table.changeFilter}
                                                                    label=""
                                                                />
                                                            </div>
                                                        </OverlayTrigger>
                                                    </div>
                                                </th>
                                                <th colSpan="2" className="w-220">
                                                    <div className="pull-left wp-100">
                                                        <div className="pull-left w-80">
                                                            <TableFilterInputField
                                                                identifier="vehicle"
                                                                type={TableModelColumnFilteringType.string}
                                                                filters={filters}
                                                                onChange={this.props.table.changeFilter}
                                                                placeholder={this.props.intl.formatMessage({ id: 'fields.registration' })}
                                                            />
                                                        </div>
                                                        <div className="pull-left w-20">
                                                            <OverlayTrigger placement="top" overlay={tooltipNoVehicle}>
                                                                <div>
                                                                    <TableFilterCheckboxField
                                                                        identifier="no_vehicle"
                                                                        type={TableModelColumnFilteringType.equal}
                                                                        filters={filters}
                                                                        onChange={this.props.table.changeFilter}
                                                                        label=""
                                                                    />
                                                                </div>
                                                            </OverlayTrigger>
                                                        </div>
                                                        <div className="pull-left w-10">&nbsp;</div>
                                                        <div className="pull-left w-90">
                                                            <TableFilterSelectField
                                                                identifier="dispatcher_id"
                                                                type={TableModelColumnFilteringType.equal}
                                                                filters={filters}
                                                                onChange={this.props.table.changeFilter}
                                                                values={helpers.getPrerequisitesArray(this.props.prerequisites.values.get('dispatchers'))}
                                                                prompt={this.props.intl.formatMessage({ id: 'fields.dispatcher' })}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="pull-left wp-100">
                                                        <div className="pull-left w-200 m-t-xxs">
                                                            <TableFilterInputField
                                                                identifier="spedition"
                                                                type={TableModelColumnFilteringType.string}
                                                                filters={filters}
                                                                onChange={this.props.table.changeFilter}
                                                                placeholder={this.props.intl.formatMessage({ id: 'fields.spedition' })}
                                                            />
                                                        </div>
                                                    </div>
                                                </th>
                                                <th className="w-110" />
                                                <th className="w-110">
                                                    <TableFilterSelectField
                                                        identifier="invoiced_out"
                                                        type={TableModelColumnFilteringType.equal}
                                                        filters={filters}
                                                        onChange={this.props.table.changeFilter}
                                                        values={invoicingOptions}
                                                        prompt="-"
                                                    />
                                                </th>
                                                <th className="w-110">
                                                    <TableFilterSelectField
                                                        identifier="invoiced_in"
                                                        type={TableModelColumnFilteringType.equal}
                                                        filters={filters}
                                                        onChange={this.props.table.changeFilter}
                                                        values={invoicingOptions}
                                                        prompt="-"
                                                    />
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.props.data &&
                                                this.props.data.data.map(transport => {
                                                    const loading = transport.transport_points.find(
                                                        item => item.id === transport.transport_point_loading_id
                                                    )
                                                    const loadingDatetimeType = 'P'
                                                    const loadingDatetimeFrom = loading && loading.datetime_planned_from
                                                    const loadingDatetimeTo = loading && loading.datetime_planned_to
                                                    const loadingDatetimeArrival = loading && loading.datetime_arrival && moment(loading.datetime_arrival)
                                                    const loadingCode = loading && loading.transport_point_data[0]

                                                    const unloading = transport.transport_points.find(
                                                        item => item.id === transport.transport_point_unloading_id
                                                    )
                                                    const unloadingDatetimeType = 'P'
                                                    const unloadingDatetimeFrom = unloading && unloading.datetime_planned_from
                                                    const unloadingDatetimeTo = unloading && unloading.datetime_planned_to
                                                    const unloadingDatetimeArrival =
                                                        unloading && unloading.datetime_arrival && moment(unloading.datetime_arrival)

                                                    const vehicles = []
                                                    const vehicleIds = {}

                                                    const trailers = []
                                                    const trailerIds = {}

                                                    const drivers = []
                                                    const driverIds = {}

                                                    transport.transport_points &&
                                                        transport.transport_points.forEach(point => {
                                                            if (point.vehicle && !vehicleIds[point.vehicle.id]) {
                                                                vehicleIds[point.vehicle.id] = 1
                                                                vehicles.push(point.vehicle)
                                                            }

                                                            if (point.trailer && !trailerIds[point.trailer.id]) {
                                                                trailerIds[point.trailer.id] = 1
                                                                trailers.push(point.trailer)
                                                            }

                                                            if (point.driver && !driverIds[point.driver.id]) {
                                                                driverIds[point.driver.id] = 1
                                                                drivers.push(point.driver)
                                                            }
                                                        })

                                                    let totalPriceCZK = 0
                                                    let currencyRate = 1
                                                    let currencyCode = 'CZK'
                                                    transport.transport_customers &&
                                                        transport.transport_customers.forEach(transportCustomer => {
                                                            totalPriceCZK += transportCustomer.price * transportCustomer.currency_rate
                                                            if (transportCustomer.currency_rate) {
                                                                currencyRate = transportCustomer.currency_rate
                                                            }
                                                            if (transportCustomer.currency) {
                                                                currencyCode = transportCustomer.currency.iso_code
                                                            }
                                                        })

                                                    let totalInvoicedOutCZK = 0
                                                    transport.invoice_items &&
                                                        transport.invoice_items.forEach(invoiceItem => {
                                                            totalInvoicedOutCZK +=
                                                                invoiceItem.unit_price *
                                                                invoiceItem.units_count *
                                                                (invoiceItem.invoice ? invoiceItem.invoice.currency_rate : 1)
                                                        })
                                                    const totalInvoicedOut = totalInvoicedOutCZK / currencyRate

                                                    let totalInvoicedInCZK = 0
                                                    transport.received_invoice_items &&
                                                        transport.received_invoice_items.forEach(invoiceItem => {
                                                            totalInvoicedInCZK +=
                                                                invoiceItem.unit_price *
                                                                invoiceItem.units_count *
                                                                (invoiceItem.received_invoice ? invoiceItem.received_invoice.currency_rate : 1)
                                                        })
                                                    const totalInvoicedIn = totalInvoicedInCZK / currencyRate

                                                    return (
                                                        <tr key={`item-${transport.id}`}>
                                                            <td className="w-80">
                                                                <strong>
                                                                        <Link to={`/transports/${transport.id}`}>
                                                                            {transport.id}
                                                                        </Link>
                                                                </strong>
                                                                <br />
                                                                <small className="text-gray">{formatters.dateFormatter(transport.date)}</small>
                                                            </td>
                                                            <td className="w-100">
                                                                {transport.transport_state && (
                                                                    <TransportStateBox transportState={transport.transport_state}>
                                                                        {this.props.intl.formatMessage({
                                                                            id: `transportStates.${transport.transport_state.name}`,
                                                                        })}
                                                                    </TransportStateBox>
                                                                )}
                                                                <br />
                                                                <small className="text-gray display-inline wp-100">
                                                                    {transport.disponent &&
                                                                        helpers.getShortFullName(
                                                                            transport.disponent.first_name,
                                                                            transport.disponent.last_name
                                                                        )}
                                                                </small>
                                                            </td>
                                                            <td className="w-max wm-400 relative">
                                                                {transport.transport_customers.map(transportCustomer => (
                                                                    <span key={`transportCustomer-${transportCustomer.id}`}>
                                                                        <strong>{transportCustomer.customer && transportCustomer.customer.name}</strong>
                                                                        {transportCustomer.order_number && ` [${transportCustomer.order_number}]`}
                                                                        <br />
                                                                    </span>
                                                                ))}
                                                                <small className="text-gray">
                                                                    {transport.transport_type && transport.transport_type.name} |{' '}
                                                                    {transport.cargo_type && transport.cargo_type.name} |{' '}
                                                                    {transport.goods_type && transport.goods_type.name}
                                                                </small>
                                                            </td>
                                                            <td className="w-360 relative">
                                                                {loading && (
                                                                    <div>
                                                                        {loading.city && (
                                                                            <strong>
                                                                                {loading.country && loading.country.iso_code}{' '}
                                                                                {`${loading.zipcode} ${loading.city}`}
                                                                            </strong>
                                                                        )}
                                                                        <br />
                                                                        <small className="text-gray">
                                                                            {loadingDatetimeType && `${loadingDatetimeType}: `}
                                                                            {formatters.datetimePeriodFormatter(loadingDatetimeFrom, loadingDatetimeTo)}
                                                                            {' | '}
                                                                            {loadingDatetimeArrival && (
                                                                                <span
                                                                                    className={
                                                                                        loadingDatetimeTo &&
                                                                                        moment(loadingDatetimeArrival).isBefore(moment(loadingDatetimeTo))
                                                                                            ? 'text-success'
                                                                                            : 'text-danger'
                                                                                    }
                                                                                >
                                                                                    A: {formatters.datetimeFormatter(loadingDatetimeArrival)}
                                                                                </span>
                                                                            )}
                                                                            {loadingCode && loadingCode.value && ` | ${loadingCode.value}`}
                                                                        </small>
                                                                    </div>
                                                                )}
                                                            </td>
                                                            <td className="w-360 relative">
                                                                {unloading && (
                                                                    <div>
                                                                        {unloading.city && (
                                                                            <strong>
                                                                                {unloading.country && unloading.country.iso_code}{' '}
                                                                                {`${unloading.zipcode} ${unloading.city}`}
                                                                            </strong>
                                                                        )}
                                                                        <br />
                                                                        <small className="text-gray">
                                                                            {unloadingDatetimeType && `${unloadingDatetimeType}: `}
                                                                            {formatters.datetimePeriodFormatter(
                                                                                unloadingDatetimeFrom,
                                                                                unloadingDatetimeTo
                                                                            )}
                                                                            {' | '}
                                                                            {unloadingDatetimeArrival && (
                                                                                <span
                                                                                    className={
                                                                                        unloadingDatetimeTo &&
                                                                                        moment(unloadingDatetimeArrival).isBefore(
                                                                                            moment(unloadingDatetimeTo)
                                                                                        )
                                                                                            ? 'text-success'
                                                                                            : 'text-danger'
                                                                                    }
                                                                                >
                                                                                    A: {formatters.datetimeFormatter(unloadingDatetimeArrival)}
                                                                                </span>
                                                                            )}
                                                                        </small>
                                                                    </div>
                                                                )}
                                                            </td>
                                                            {transport.is_spedition ? (
                                                                <td colSpan="2" className="w-220">
                                                                    <div>
                                                                        <ColoredBox
                                                                            bgRGB="#cccccc"
                                                                            textRGB="#222222"
                                                                            className="wp-100 display-inline text-center"
                                                                        >
                                                                            <FormattedMessage id="fields.spedition" />
                                                                        </ColoredBox>
                                                                        <br />
                                                                        <small className="text-gray">{transport.carrier && transport.carrier.name}</small>
                                                                    </div>
                                                                </td>
                                                            ) : (
                                                                <td className="w-110">
                                                                    <div>
                                                                        {vehicles.length > 0 &&
                                                                            vehicles.map((v, k) => (
                                                                                <span
                                                                                    key={`vehicles-${v.id}`}
                                                                                    style={{ marginTop: `${k ? 3 : 0}px` }}
                                                                                    className="display-inline"
                                                                                >
                                                                                    <DispatchersBox dispatcher={transport.dispatcher}>
                                                                                        {v.registration}
                                                                                    </DispatchersBox>
                                                                                    <br />
                                                                                </span>
                                                                            ))}
                                                                    </div>
                                                                </td>
                                                            )}
                                                            {!transport.is_spedition && (
                                                                <td className="w-110">
                                                                    <div>
                                                                        {transport.dispatcher && (
                                                                            <span>
                                                                                <DispatchersBox dispatcher={transport.dispatcher}>
                                                                                    {transport.dispatcher &&
                                                                                        helpers.getShortFullName(
                                                                                            transport.dispatcher.first_name,
                                                                                            transport.dispatcher.last_name
                                                                                        )}
                                                                                </DispatchersBox>
                                                                            </span>
                                                                        )}
                                                                    </div>
                                                                </td>
                                                            )}
                                                            <td className="w-110 text-right">
                                                                {transport.transport_customers.map(transportCustomer => (
                                                                    <span className="display-inline wp-100" key={`currency-${transportCustomer.id}`}>
                                                                        {formatters.priceFormatter(
                                                                            transportCustomer.price,
                                                                            '0,0',
                                                                            transportCustomer.currency ? transportCustomer.currency.iso_code : ''
                                                                        )}
                                                                    </span>
                                                                ))}
                                                                <span className="display-inline wp-100">
                                                                    <small className="text-gray">{formatters.priceFormatter(totalPriceCZK)}</small>
                                                                </span>
                                                            </td>
                                                            <td className="w-110 text-right">
                                                                {formatters.priceFormatter(totalInvoicedOut, '0,0', currencyCode)}
                                                                <span className="display-inline wp-100">
                                                                    <small className="text-gray">{formatters.priceFormatter(totalInvoicedOutCZK)}</small>
                                                                </span>
                                                            </td>
                                                            <td className="w-110 text-right">
                                                                {formatters.priceFormatter(totalInvoicedIn, '0,0', currencyCode)}
                                                                <span className="display-inline wp-100">
                                                                    <small className="text-gray">{formatters.priceFormatter(totalInvoicedInCZK)}</small>
                                                                </span>
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                        </tbody>
                                    </table>
                                </div>

                                <div className="pull-left m-l-xxs m-t-md">
                                    <FormattedMessage id="pagination.totalRecords" />:{' '}
                                    {helpers.getListItemsCountMessage(
                                        clientSideItemsPerPage,
                                        this.props.data.count,
                                        this.props.tableModel.getIn(['pagination', 'totalCount'])
                                    )}
                                </div>

                                <ReactPaginate
                                    containerClassName="pagination"
                                    breakLabel={<span className="disabled">...</span>}
                                    activeClassName="active"
                                    pageCount={getTotalNumberOfPages(this.props.tableModel, this.props.data.count, clientSideItemsPerPage)}
                                    pageRangeDisplayed={10}
                                    marginPagesDisplayed={2}
                                    forcePage={this.props.tableModel.getIn(['pagination', 'current'])}
                                    onPageChange={this.props.table.changePage}
                                    previousLabel={this.props.intl.formatMessage({ id: 'pagination.previous' })}
                                    nextLabel={this.props.intl.formatMessage({ id: 'pagination.next' })}
                                />
                            </div>
                        </div>

                        <Modal show={this.state.modalTransportPrices.show} onHide={this.closeTransportPricesModal} bsSize="sm">
                            <Modal.Header closeButton>
                                <Modal.Title>
                                    {' '}
                                    <FormattedMessage id="fields.transportPricesSetup" />
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <PermissionsCheck>
                                    <p>
                                        <FormattedMessage id="fields.totalTransportCount" />: <strong>{this.props.tableModel.getIn(['pagination', 'totalCount'])}</strong>
                                    </p>
                                    <hr />
                                    <form
                                        onSubmit={e => {
                                            e.preventDefault()
                                            this.closePriceModal()
                                        }}
                                    >
                                        <div className="row form-group">
                                            <div className="col-md-6 p-v-xxs">
                                                <b>
                                                    <FormattedMessage id="fields.priceForKm" />:
                                                </b>
                                            </div>
                                            <div className="col-md-6">
                                                <input
                                                    onChange={e => this.setTransportPricesValue('priceForKm', e.target.value)}
                                                    value={this.state.modalTransportPrices.priceForKm}
                                                    type="text"
                                                    className="form-control text-right"
                                                />
                                            </div>
                                        </div>
                                        <div className="row form-group">
                                            <div className="col-md-6 p-v-xxs">
                                                <b>
                                                    <FormattedMessage id="fields.priceFix" />:
                                                </b>
                                            </div>
                                            <div className="col-md-6">
                                                <input
                                                    onChange={e => this.setTransportPricesValue('priceFix', e.target.value)}
                                                    value={this.state.modalTransportPrices.priceFix}
                                                    type="text"
                                                    className="form-control text-right"
                                                />
                                            </div>
                                        </div>
                                        <div className="row form-group">
                                            <div className="col-md-6 p-v-xxs">
                                                <b>
                                                    <FormattedMessage id="fields.erasePriceToll" />:
                                                </b>
                                            </div>
                                            <div className="col-md-6">
                                                <input
                                                    onChange={e => this.setTransportPricesValue('erasePriceToll', e.target.checked)}
                                                    checked={this.state.modalTransportPrices.erasePriceToll ? 'checked' : ''}
                                                    type="checkbox"
                                                    className="form-control w-20"
                                                />
                                            </div>
                                        </div>
                                        <PermissionsCheck>
                                            <div className="btns-form text-right">
                                                <button type="button" className="btn btn-addon btn-success" onClick={this.processTransportPrices}>
                                                    <i className="far fa-check" /> <FormattedMessage id="buttons.done" />
                                                </button>
                                            </div>
                                        </PermissionsCheck>
                                    </form>
                                </PermissionsCheck>
                            </Modal.Body>
                        </Modal>
                    </div>
                </div>
            </PermissionsCheck>
        )
    }
}

function mapStateToProps(state) {
    return {
        data: createTableDataSelector({
            tableIdentifier,
            columns,
            dataSelector: getData,
            clientSideItemsPerPage,
        })(state),
        tableModel: createTableModelSelector(tableIdentifier)(state),
        prerequisites: preparePrerequisitesFetchStatusSelectors(componentIdentifier, prerequisites, state),
        // createGetUserByIDSelector: id => createGetUserByIDSelector(id)(state),
        // createGetVehicleByIDSelector: id => createGetVehicleByIDSelector(id)(state),
        isSetTransportPricesInProgress: isSetTransportPricesInProgress(state),
        setTransportPricesError: getSetTransportPricesError(state),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            ...bindActionCreators(
                {
                    ...actionCreators,
                    ...preparePrerequisitesActionCreators(componentIdentifier, prerequisites),
                },
                dispatch
            ),
        },
        notify: (notification, type) => dispatch(Notifications.show(notification, type)),
        dispatch,
    }
}

function mergeProps(stateProps, dispatchProps, ownProps) {
    return {
        ...stateProps,
        ...dispatchProps,
        ...ownProps,
        table: bindActionCreators(prepareTableActionCreators(tableIdentifier, stateProps.tableModel), dispatchProps.dispatch),
    }
}

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps,
        mergeProps
    )(ReportTransportInvoicing)
)
