import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import moment from 'moment'
import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ReferenceLine } from 'recharts'

import * as formatters from '../../../common/formatters'

import * as reportTransportOrdersActionCreators from '../actionCreators'
import { getData, getSettings, getFetching } from '../selectors'

import { PeriodPicker } from '../../../common/period_picker'
import * as periodPickerConstants from '../../../common/period_picker/constants'
import { preparePrerequisitesFetchStatusSelectors } from '../../../common/prerequisites/selectors'
import { preparePrerequisitesActionCreators } from '../../../common/prerequisites/actionCreators'
import { getPrerequisitesArray } from '../../../common/helpers'
import PermissionsCheck from '../../auth/components/PermissionsCheck'
import { PERMS } from '../permissions'

const componentIdentifier = 'report_transport_orders'
const prerequisites = ['company_cost_centers']

const AGENDA_COUNT = 1
const AGENDA_REVENUE = 2
const AGENDA_PROFIT = 3
const AGENDA_PROFIT_PERC = 4

const GROUP_BY_DATE_CREATED = 1
const GROUP_BY_DATE_UNLOADING = 2

class ReportTransportOrders extends Component {
    // settings
    getDefaultSettings = () => ({
        year: moment().format('YYYY'),
        month: moment().format('MM'),
        agenda: AGENDA_COUNT,
        companyCostCenterID: null,
        groupBy: GROUP_BY_DATE_CREATED,
    })

    timerRefresh = null

    getSettings = () => this.props.settings || this.getDefaultSettings()

    // settings handles
    handleChangeYearMonth = format => {

        const settings = {
            ...this.getSettings(),
            year: format.year.toString(),
            month: ("0" + format.month.toString()).slice(-2),
        }

        this.loadData(settings)
    }

    handleChangeAgenda = agenda => {
        const settings = {
            ...this.getSettings(),
            agenda,
        }

        this.loadData(settings)
    }

    handleChangeCompanyCostCenterID = companyCostCenterID => {
        const settings = {
            ...this.getSettings(),
            companyCostCenterID,
        }

        this.loadData(settings)
    }

    handleChangeGroupBy = groupBy => {
        const settings = {
            ...this.getSettings(),
            groupBy,
        }

        this.loadData(settings)
    }

    refresh = () => {
        this.loadData(this.getSettings())
    }

    // data
    loadData = settings => {
        this.props.actions.fetchReportTransportOrders(settings || this.getSettings())
    }

    getData = () => this.props.data

    clearData = () => {
        this.props.actions.clearReportTransportOrders()
    }

    // prerequisites
    loadPrerequisites = () => {
        this.props.actions.fetchPrerequisites()
    }

    componentDidMount() {
        this.loadData()
        this.loadPrerequisites()
    }

    componentWillUnmount() {
        this.clearData()
        clearTimeout(this.timerRefresh)
    }

    componentWillReceiveProps(nextProps) {
        // auto refresh
        if (!nextProps.fetching && this.props.fetching) {
            clearTimeout(this.timerRefresh)

            this.timerRefresh = setTimeout(() => {
                this.refresh()
            }, 60000)
        }
    }

    render() {
        document.title = formatters.titleFormatter(this.props.intl.formatMessage({ id: 'modules.reportTransportOrders.heading' }))

        const data = this.getData()
        const values = data ? data.data : null
        const costCenter = data ? data.costCenter : null
        const settings = this.getSettings()
        const companyCostCenters = getPrerequisitesArray(this.props.prerequisites.values.get('company_cost_centers'))

        if (!settings) {
            return null
        }

        const momentFrom = moment(`${settings.year}-${settings.month}-01`)
        const momentTo = moment(momentFrom).endOf('month')

        const chartData = []
        let totalValue = 0
        let totalCount = 0

        if (values) {
            Object.keys(values).forEach(key => {
                chartData.push({
                    name: key,
                    value: values[key],
                })

                if (values[key]) {
                    totalValue += values[key]
                    totalCount++
                }
            })
        }

        let valueMinimum = null
        let valueOptimum = null

        if (costCenter) {
            if (settings.agenda === AGENDA_COUNT && costCenter.transport_minimum_count) {
                valueMinimum = costCenter.transport_minimum_count
            }
            if (settings.agenda === AGENDA_REVENUE && costCenter.transport_minimum_sales) {
                valueMinimum = costCenter.transport_minimum_sales
            }
            if (settings.agenda === AGENDA_PROFIT && costCenter.transport_minimum_profit) {
                valueMinimum = costCenter.transport_minimum_profit
            }

            if (settings.agenda === AGENDA_COUNT && costCenter.transport_plan_count) {
                valueOptimum = costCenter.transport_plan_count
            }
            if (settings.agenda === AGENDA_REVENUE && costCenter.transport_plan_sales) {
                valueOptimum = costCenter.transport_plan_sales
            }
            if (settings.agenda === AGENDA_PROFIT && costCenter.transport_plan_profit) {
                valueOptimum = costCenter.transport_plan_profit
            }
        }

        /* eslint-disable jsx-a11y/click-events-have-key-events */
        /* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
        return (
            <PermissionsCheck hasAny={Object.values(PERMS)} noPermissionsPage>
                <div className="page-inner">
                    <div id="main-wrapper">
                        <div className="panel panel-white">
                            <div className="panel-body">
                                <div className="wp-100 pull-left m-b-md">
                                    <h4 className="pull-left">
                                        <span className="pull-left">
                                            <FormattedMessage id="modules.reportTransportOrders.heading" />
                                        </span>
                                    </h4>
                                    <div className="btns-list">
                                        <div className="pull-left w-200 m-r-sm">
                                            <select
                                                value={settings.companyCostCenterID}
                                                onChange={e => this.handleChangeCompanyCostCenterID(e.target.value)}
                                                className="form-control pull-left"
                                            >
                                                <option value="0">Všechna střediska</option>
                                                {companyCostCenters.map(item => (
                                                    <option key={`company-cost-center-${item.id}`} value={item.id}>
                                                        {item.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>

                                        <div className="pull-left w-200 m-r-sm">
                                            <select
                                                value={settings.groupBy}
                                                onChange={e => this.handleChangeGroupBy(e.target.value)}
                                                className="form-control pull-left"
                                            >
                                                <option value={GROUP_BY_DATE_CREATED}>Dle data vytvoření</option>
                                                <option value={GROUP_BY_DATE_UNLOADING}>Dle data vykládky</option>
                                            </select>
                                        </div>

                                        <PeriodPicker
                                            className={'m-r-sm'}
                                            momentFrom={momentFrom}
                                            momentTo={momentTo}
                                            onChange={(momentFrom, momentTo, format) => this.handleChangeYearMonth(format)}
                                            allowedPickers={[periodPickerConstants.PERIOD_PICKER_TYPE_MONTH]}
                                            forbidPickType
                                        />

                                        <div className="pull-left text-thin datetime-selection m-r-sm">
                                            <div className="btn-group">
                                                <button
                                                    onClick={() => this.handleChangeAgenda(AGENDA_COUNT)}
                                                    type="button"
                                                    className={`btn btn-${settings.agenda === AGENDA_COUNT ? 'success' : 'default'}`}
                                                >
                                                    Počet
                                                </button>
                                                <button
                                                    onClick={() => this.handleChangeAgenda(AGENDA_REVENUE)}
                                                    type="button"
                                                    className={`btn btn-${settings.agenda === AGENDA_REVENUE ? 'success' : 'default'}`}
                                                >
                                                    Tržby
                                                </button>
                                                <button
                                                    onClick={() => this.handleChangeAgenda(AGENDA_PROFIT)}
                                                    type="button"
                                                    className={`btn btn-${settings.agenda === AGENDA_PROFIT ? 'success' : 'default'}`}
                                                >
                                                    Zisk
                                                </button>
                                                <button
                                                    onClick={() => this.handleChangeAgenda(AGENDA_PROFIT_PERC)}
                                                    type="button"
                                                    className={`btn btn-${settings.agenda === AGENDA_PROFIT_PERC ? 'success' : 'default'}`}
                                                >
                                                    Zisk %
                                                </button>
                                            </div>
                                        </div>

                                        <button className="btn btn-default btn-addon m-r-xs" onClick={this.refresh}>
                                            <i className="far fa-sync-alt" /> <FormattedMessage id="buttons.refresh" />
                                        </button>
                                    </div>
                                </div>

                                <div className="pull-left wp-100 hp-60 m-b-lg">
                                    <ResponsiveContainer width="100%" height="100%">
                                        <BarChart data={chartData}>
                                            <CartesianGrid strokeDasharray="1 3" />
                                            <XAxis dataKey="name" />
                                            <YAxis type="number" domain={[0, dataMax => Math.ceil(Math.max(dataMax * 1.1, valueOptimum || 0))]} />
                                            <Tooltip />
                                            {valueMinimum ? <ReferenceLine y={valueMinimum} label="Min" stroke="red" /> : null}
                                            {valueOptimum ? <ReferenceLine y={valueOptimum} label="Plán" stroke="red" /> : null}
                                            <Bar dataKey="value" fill="#22baa0" label={{ fill: 'white', fontSize: 11 }} />
                                        </BarChart>
                                    </ResponsiveContainer>
                                </div>

                                <div className="pull-left wp-100 text-center">
                                    <div className="text-lg bg-success display-inline p-v-sm p-h-md">
                                        {settings.agenda === AGENDA_COUNT ? formatters.integerFormatter(totalValue) : null}
                                        {settings.agenda === AGENDA_REVENUE ? formatters.priceFormatter(totalValue) : null}
                                        {settings.agenda === AGENDA_PROFIT ? formatters.priceFormatter(totalValue) : null}
                                        {settings.agenda === AGENDA_PROFIT_PERC
                                            ? formatters.percentFormatter(totalCount ? totalValue / totalCount : 0)
                                            : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </PermissionsCheck>
        )
        /* eslint-enable jsx-a11y/no-noninteractive-element-interactions */
        /* eslint-enable jsx-a11y/click-events-have-key-events */
    }
}

function mapStateToProps(state) {
    return {
        data: getData(state),
        settings: getSettings(state),
        fetching: getFetching(state),
        prerequisites: preparePrerequisitesFetchStatusSelectors(componentIdentifier, prerequisites, state),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            ...bindActionCreators(
                {
                    ...reportTransportOrdersActionCreators,
                    ...preparePrerequisitesActionCreators(componentIdentifier, prerequisites),
                },
                dispatch
            ),
        },
        dispatch,
    }
}

function mergeProps(stateProps, dispatchProps, ownProps) {
    return {
        ...stateProps,
        ...dispatchProps,
        ...ownProps,
    }
}

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps,
        mergeProps
    )(ReportTransportOrders)
)
