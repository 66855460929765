import * as formatters from '../../common/formatters'

export const TRANSPORT_POINT_TYPE_LOADING = 1
export const TRANSPORT_POINT_TYPE_UNLOADING = 2
export const TRANSPORT_POINT_TYPE_WAYPOINT = 3
export const TRANSPORT_POINT_TYPE_SERVICE = 4
export const TRANSPORT_POINT_TYPE_FUELING = 5
export const TRANSPORT_POINT_TYPE_PARKING = 6
export const TRANSPORT_POINT_TYPE_TRAILER_CHANGE = 7
export const TRANSPORT_POINT_TYPE_TRAILER_CATCH = 8
export const TRANSPORT_POINT_TYPE_TRAILER_OUT = 9
export const TRANSPORT_POINT_TYPE_PARKING_AND_CHANGE = 10
export const TRANSPORT_POINT_TYPE_MOVE = 11
export const TRANSPORT_POINT_TYPE_CUSTOM_HOUSE = 12
export const TRANSPORT_POINT_TYPE_VEHICLE_QUIT = 13
export const TRANSPORT_POINT_TYPE_WAYPOINT_REAL = 14

export const TRANSPORT_POINT_DATA_TYPE_CODE = 10
export const TRANSPORT_POINT_DATA_TYPE_PCS_COUNT = 20
export const TRANSPORT_POINT_DATA_TYPE_WEIGHT = 30
export const TRANSPORT_POINT_DATA_TYPE_TEMPERATURE_FROM = 40
export const TRANSPORT_POINT_DATA_TYPE_TEMPERATURE_TO = 50
export const TRANSPORT_POINT_DATA_TYPE_PALLET_EXCHANGE = 60
export const TRANSPORT_POINT_DATA_TYPE_PALLET_EXCHANGE_COUNT = 70
export const TRANSPORT_POINT_DATA_TYPE_PALLET_EXCHANGE_DAMAGED = 80
export const TRANSPORT_POINT_DATA_TYPE_FUELING_TYPE = 100
export const TRANSPORT_POINT_DATA_TYPE_VEHICLE = 105
export const TRANSPORT_POINT_DATA_TYPE_REGISTRATION = 110
export const TRANSPORT_POINT_DATA_TYPE_DRIVER_PHONE = 120
export const TRANSPORT_POINT_DATA_TYPE_DRIVER = 130

export const TRANSPORT_ROUTE_TYPE_PLANNED = 1
export const TRANSPORT_ROUTE_TYPE_SPECIFIED = 2
export const TRANSPORT_ROUTE_TYPE_REAL = 3

export const TRANSPORT_STATE_PREORDER = 1
export const TRANSPORT_STATE_ORDER = 2
export const TRANSPORT_STATE_PLANNED = 3
export const TRANSPORT_STATE_SENT = 4
export const TRANSPORT_STATE_IN_PROCESS = 5
export const TRANSPORT_STATE_FINISHED = 6

export const TRANSPORT_POINT_STATE_ON_WAY = 1
export const TRANSPORT_POINT_STATE_ARRIVED = 2
export const TRANSPORT_POINT_STATE_WAITING = 3
export const TRANSPORT_POINT_STATE_IN_PROCESS = 4
export const TRANSPORT_POINT_STATE_DONE = 5
export const TRANSPORT_POINT_STATE_FINISHED = 6

export const TRANSPORT_EVENT_TYPE_LOADING_ARRIVAL = 1010
export const TRANSPORT_EVENT_TYPE_LOADING_SYSTEM_CHECK = 1020
export const TRANSPORT_EVENT_TYPE_LOADING_WAITING = 1030
export const TRANSPORT_EVENT_TYPE_LOADING_START = 1040
export const TRANSPORT_EVENT_TYPE_LOADING_END = 1050
export const TRANSPORT_EVENT_TYPE_LOADING_LOADED = 1060
export const TRANSPORT_EVENT_TYPE_UNLOADING_ARRIVAL = 2010
export const TRANSPORT_EVENT_TYPE_UNLOADING_WAITING = 2020
export const TRANSPORT_EVENT_TYPE_UNLOADING_START = 2030
export const TRANSPORT_EVENT_TYPE_UNLOADING_END = 2040
export const TRANSPORT_EVENT_TYPE_UNLOADING_UNLOADED = 2050
export const TRANSPORT_EVENT_TYPE_FUELING_ARRIVAL = 3010
export const TRANSPORT_EVENT_TYPE_FUELING_DEPARTURE = 3020
export const TRANSPORT_EVENT_TYPE_SERVICE_ARRIVAL = 4010
export const TRANSPORT_EVENT_TYPE_SERVICE_DEPARTURE = 4020
export const TRANSPORT_EVENT_TYPE_PARKING_ARRIVAL = 5010
export const TRANSPORT_EVENT_TYPE_PARKING_CHECK_BEFORE = 5020
export const TRANSPORT_EVENT_TYPE_PARKING_TIME = 5030
export const TRANSPORT_EVENT_TYPE_PARKING_CHECK_AFTER = 5040
export const TRANSPORT_EVENT_TYPE_PARKING_DEPARTURE = 5050
export const TRANSPORT_EVENT_TYPE_PREDEPARTURE_CHECK = 6010
export const TRANSPORT_EVENT_TYPE_TRAILER_CHANGE_ARRIVAL = 7010
export const TRANSPORT_EVENT_TYPE_TRAILER_CHANGE_CHECK = 7020
export const TRANSPORT_EVENT_TYPE_TRAILER_CHANGE_DEPARTURE = 7030
export const TRANSPORT_EVENT_TYPE_TRAILER_CATCH_ARRIVAL = 8010
export const TRANSPORT_EVENT_TYPE_TRAILER_CATCH_CHECK = 8020
export const TRANSPORT_EVENT_TYPE_TRAILER_CATCH_DEPARTURE = 8030
export const TRANSPORT_EVENT_TYPE_TRAILER_OUT_ARRIVAL = 9010
export const TRANSPORT_EVENT_TYPE_TRAILER_OUT_CHECK = 9020
export const TRANSPORT_EVENT_TYPE_TRAILER_OUT_DEPARTURE = 9030
export const TRANSPORT_EVENT_TYPE_PARKING_AND_CHANGE_ARRIVAL = 10010
export const TRANSPORT_EVENT_TYPE_PARKING_AND_CHANGE_QUIT_CHECK = 10015
export const TRANSPORT_EVENT_TYPE_PARKING_AND_CHANGE_CHECK = 10020
export const TRANSPORT_EVENT_TYPE_MOVE_END = 11010
export const TRANSPORT_EVENT_TYPE_CUSTOM_HOUSE_ARRIVAL = 12010
export const TRANSPORT_EVENT_TYPE_CUSTOM_HOUSE_DEPARTURE = 12020
export const TRANSPORT_EVENT_TYPE_VEHICLE_QUIT_CHECK = 13010
export const TRANSPORT_EVENT_TYPE_VEHICLE_QUIT_FINISH = 13020
export const TRANSPORT_EVENT_TYPE_WAYPOINT_REAL_END = 14010

export const TRANSPORT_EVENT_DATA_TYPE_UNLOADED_WITH_OBJECTION = 10
export const TRANSPORT_EVENT_DATA_TYPE_WAITING_REASON = 20
export const TRANSPORT_EVENT_DATA_TYPE_PCS = 40
export const TRANSPORT_EVENT_DATA_TYPE_WEIGHT = 45
export const TRANSPORT_EVENT_DATA_TYPE_PALLET_EXCHANGE = 50
export const TRANSPORT_EVENT_DATA_TYPE_PALLET_EXCHANGE_ISSUED = 51
export const TRANSPORT_EVENT_DATA_TYPE_PALLET_EXCHANGE_ACCEPTED = 52
export const TRANSPORT_EVENT_DATA_TYPE_FUELED = 60
export const TRANSPORT_EVENT_DATA_TYPE_DEFECT = 70
export const TRANSPORT_EVENT_DATA_TYPE_DEFECT_TEXT = 71
export const TRANSPORT_EVENT_DATA_TYPE_TIME_PLANNED_END = 80
export const TRANSPORT_EVENT_DATA_TYPE_VEHICLE_DEFECT = 90
export const TRANSPORT_EVENT_DATA_TYPE_VEHICLE_DEFECT_TEXT = 91
export const TRANSPORT_EVENT_DATA_TYPE_TRAILER_DEFECT = 92
export const TRANSPORT_EVENT_DATA_TYPE_TRAILER_DEFECT_TEXT = 93
export const TRANSPORT_EVENT_DATA_TYPE_DOCUMENTS_DEFECT = 95
export const TRANSPORT_EVENT_DATA_TYPE_DOCUMENTS_DEFECT_TEXT = 96
export const TRANSPORT_EVENT_DATA_TYPE_NOTE = 100
export const TRANSPORT_EVENT_DATA_TYPE_REGISTRATION = 110

export const LOG_EVENT_TRANSPORT_CREATED = 10100
export const LOG_EVENT_TRANSPORT_CREATED_BY_COPYING = 10110
export const LOG_EVENT_TRANSPORT_CREATED_FROM_TEMPLATE = 10120
export const LOG_EVENT_TRANSPORT_CREATED_FROM_TRANSPOREON_TRANSPORT = 10130
export const LOG_EVENT_TRANSPORT_UPDATED = 10200
export const LOG_EVENT_TRANSPORT_DELETED = 10300
export const LOG_EVENT_TRANSPORT_SENT_TO_VEHICLE = 10400
export const LOG_EVENT_TRANSPORT_UPDATED_IN_VEHICLE = 10500
export const LOG_EVENT_TRANSPORT_DELETED_FROM_VEHICLE = 10600
export const LOG_EVENT_TRANSPORT_RESTORED = 10700
export const LOG_EVENT_TRANSPORT_POINT_CREATED = 20100
export const LOG_EVENT_TRANSPORT_POINT_UPDATED = 20200
export const LOG_EVENT_TRANSPORT_POINT_DELETED = 20300
export const LOG_EVENT_TRANSPORT_POINT_RESTORED = 20400
export const LOG_EVENT_TRANSPORT_POINT_EVENT_CREATED = 30100
export const LOG_EVENT_TRANSPORT_POINT_EVENT_UPDATED = 30200
export const LOG_EVENT_TRANSPORT_POINT_EVENT_DELETED = 30300

export const LOG_CHANGE_TYPE_TRANSPORT_STATE = 100010
export const LOG_CHANGE_TYPE_TRANSPORT_DISPONENT = 100020
export const LOG_CHANGE_TYPE_TRANSPORT_TYPE = 100030
export const LOG_CHANGE_TYPE_TRANSPORT_CARGO_TYPE = 100040
export const LOG_CHANGE_TYPE_TRANSPORT_NOTE = 100050
export const LOG_CHANGE_TYPE_TRANSPORT_CUSTOMER = 100060
export const LOG_CHANGE_TYPE_TRANSPORT_CUSTOMER_ORDER_NUMBER = 100070
export const LOG_CHANGE_TYPE_TRANSPORT_CUSTOMER_PRICE = 100080
export const LOG_CHANGE_TYPE_TRANSPORT_CUSTOMER_CURRENCY = 100090
export const LOG_CHANGE_TYPE_TRANSPORT_CUSTOMER_NOTE = 100100
export const LOG_CHANGE_TYPE_TRANSPORT_VEHICLE = 100110
export const LOG_CHANGE_TYPE_TRANSPORT_VEHICLE_NOTE = 100120
export const LOG_CHANGE_TYPE_TRANSPORT_SPEDITION = 100130
export const LOG_CHANGE_TYPE_TRANSPORT_CARRIER = 100140
export const LOG_CHANGE_TYPE_TRANSPORT_CARRIER_ORDER_NUMBER = 100150
export const LOG_CHANGE_TYPE_TRANSPORT_CARRIER_PRICE = 100160
export const LOG_CHANGE_TYPE_TRANSPORT_CARRIER_CURRENCY = 100170
export const LOG_CHANGE_TYPE_TRANSPORT_CARRIER_NOTE = 100180
export const LOG_CHANGE_TYPE_TRANSPORT_PREDEPARTURE_CHECK = 100190
export const LOG_CHANGE_TYPE_TRANSPORT_POINT_ORDER = 200010
export const LOG_CHANGE_TYPE_TRANSPORT_POINT_STATE = 200020
export const LOG_CHANGE_TYPE_TRANSPORT_POINT_DATETIME_PLANNED_FROM = 200030
export const LOG_CHANGE_TYPE_TRANSPORT_POINT_DATETIME_PLANNED_TO = 200035
export const LOG_CHANGE_TYPE_TRANSPORT_POINT_DATETIME_SPECIFIED_FROM = 200040
export const LOG_CHANGE_TYPE_TRANSPORT_POINT_DATETIME_SPECIFIED_TO = 200045
export const LOG_CHANGE_TYPE_TRANSPORT_POINT_DATETIME_ARRIVAL = 200050
export const LOG_CHANGE_TYPE_TRANSPORT_POINT_DATETIME_FINISHED = 200060
export const LOG_CHANGE_TYPE_TRANSPORT_POINT_NAME = 200070
export const LOG_CHANGE_TYPE_TRANSPORT_POINT_STREET = 200080
export const LOG_CHANGE_TYPE_TRANSPORT_POINT_CITY = 200090
export const LOG_CHANGE_TYPE_TRANSPORT_POINT_ZIPCODE = 200100
export const LOG_CHANGE_TYPE_TRANSPORT_POINT_COUNTRY = 200110
export const LOG_CHANGE_TYPE_TRANSPORT_POINT_GPS_LAT = 200120
export const LOG_CHANGE_TYPE_TRANSPORT_POINT_GPS_LNG = 200130
export const LOG_CHANGE_TYPE_TRANSPORT_POINT_NOTE = 200140
export const LOG_CHANGE_TYPE_TRANSPORT_POINT_CODE = 200150
export const LOG_CHANGE_TYPE_TRANSPORT_POINT_PCS_COUNT = 200160
export const LOG_CHANGE_TYPE_TRANSPORT_POINT_WEIGHT = 200170
export const LOG_CHANGE_TYPE_TRANSPORT_POINT_TEMPERATURE_FROM = 200180
export const LOG_CHANGE_TYPE_TRANSPORT_POINT_TEMPERATURE_TO = 200190
export const LOG_CHANGE_TYPE_TRANSPORT_POINT_PALLET_EXCHANGE = 200200
export const LOG_CHANGE_TYPE_TRANSPORT_POINT_PALLET_EXCHANGE_COUNT = 200210
export const LOG_CHANGE_TYPE_TRANSPORT_POINT_FUELING_TYPE = 200220
export const LOG_CHANGE_TYPE_TRANSPORT_POINT_VEHICLE = 200230
export const LOG_CHANGE_TYPE_TRANSPORT_POINT_REGISTRATION = 200240
export const LOG_CHANGE_TYPE_TRANSPORT_POINT_DRIVER_PHONE = 200250
export const LOG_CHANGE_TYPE_TRANSPORT_POINT_DRIVER = 200260
export const LOG_CHANGE_TYPE_TRANSPORT_POINT_IS_BOOKING_REQUIRED = 200270
export const LOG_CHANGE_TYPE_TRANSPORT_POINT_DATETIME_BOOKING_FROM = 200280
export const LOG_CHANGE_TYPE_TRANSPORT_POINT_DATETIME_BOOKING_TO = 200290
export const LOG_CHANGE_TYPE_TRANSPORT_POINT_BOOKING_URL = 200300
export const LOG_CHANGE_TYPE_TRANSPORT_POINT_BOOKING_NOTE = 200310
export const LOG_CHANGE_TYPE_TRANSPORT_POINT_EVENT_DATETIME = 300010
export const LOG_CHANGE_TYPE_TRANSPORT_POINT_EVENT_VEHICLE = 300020
export const LOG_CHANGE_TYPE_TRANSPORT_POINT_EVENT_DRIVER = 300030
export const LOG_CHANGE_TYPE_TRANSPORT_POINT_EVENT_DRIVER_2 = 300040
export const LOG_CHANGE_TYPE_TRANSPORT_POINT_EVENT_GPS_LAT = 300050
export const LOG_CHANGE_TYPE_TRANSPORT_POINT_EVENT_GPS_LNG = 300060
export const LOG_CHANGE_TYPE_TRANSPORT_POINT_EVENT_KM = 300070
export const LOG_CHANGE_TYPE_TRANSPORT_POINT_EVENT_UNLOADED_WITH_OBJECTION = 300080
export const LOG_CHANGE_TYPE_TRANSPORT_POINT_EVENT_WAITING_REASON = 300090
export const LOG_CHANGE_TYPE_TRANSPORT_POINT_EVENT_PCS = 300100
export const LOG_CHANGE_TYPE_TRANSPORT_POINT_EVENT_WEIGHT = 300110
export const LOG_CHANGE_TYPE_TRANSPORT_POINT_EVENT_PALLET_EXCHANGE = 300120
export const LOG_CHANGE_TYPE_TRANSPORT_POINT_EVENT_PALLET_EXCHANGE_ISSUED = 300130
export const LOG_CHANGE_TYPE_TRANSPORT_POINT_EVENT_PALLET_EXCHANGE_ACCEPTED = 300140
export const LOG_CHANGE_TYPE_TRANSPORT_POINT_EVENT_FUELED = 300150
export const LOG_CHANGE_TYPE_TRANSPORT_POINT_EVENT_DEFECT = 300160
export const LOG_CHANGE_TYPE_TRANSPORT_POINT_EVENT_DEFECT_TEXT = 300170
export const LOG_CHANGE_TYPE_TRANSPORT_POINT_EVENT_TIME_PLANNED_END = 300180
export const LOG_CHANGE_TYPE_TRANSPORT_POINT_EVENT_VEHICLE_DEFECT = 300190
export const LOG_CHANGE_TYPE_TRANSPORT_POINT_EVENT_VEHICLE_DEFECT_TEXT = 300200
export const LOG_CHANGE_TYPE_TRANSPORT_POINT_EVENT_TRAILER_DEFECT = 300210
export const LOG_CHANGE_TYPE_TRANSPORT_POINT_EVENT_TRAILER_DEFECT_TEXT = 300220
export const LOG_CHANGE_TYPE_TRANSPORT_POINT_EVENT_DOCUMENTS_DEFECT = 300230
export const LOG_CHANGE_TYPE_TRANSPORT_POINT_EVENT_DOCUMENTS_DEFECT_TEXT = 300240
export const LOG_CHANGE_TYPE_TRANSPORT_POINT_EVENT_NOTE = 300250
export const LOG_CHANGE_TYPE_TRANSPORT_POINT_EVENT_REGISTRATION = 300260

export const TRANSPORT_VEHICLE_TYPE_VEHICLE = 1
export const TRANSPORT_VEHICLE_TYPE_BOAT = 2
export const TRANSPORT_VEHICLE_TYPE_TRAIN = 3
export const TRANSPORT_VEHICLE_TYPE_PLANE = 4
export const TRANSPORT_VEHICLE_TYPE_OTHER = 5

export const TRANSPORT_ORDER_FOR_SUPPLIER = 1
export const TRANSPORT_ORDER_FOR_CUSTOMER = 2

export const TRANSPORT_ORDER_TYPE_OFFER = 1
export const TRANSPORT_ORDER_TYPE_ORDER = 2
export const TRANSPORT_ORDER_TYPE_ORDER_CONFIRM = 3

export const TRANSPORT_VEHICLE_TYPES = {
    [TRANSPORT_VEHICLE_TYPE_VEHICLE]: {
        name: 'transportVehicleTypes.vehicle',
        numberLabel: 'fields.registration',
    },
    [TRANSPORT_VEHICLE_TYPE_BOAT]: {
        name: 'transportVehicleTypes.boat',
        numberLabel: 'fields.boatNumber',
    },
    [TRANSPORT_VEHICLE_TYPE_TRAIN]: {
        name: 'transportVehicleTypes.train',
        numberLabel: 'fields.trainNumber',
    },
    [TRANSPORT_VEHICLE_TYPE_PLANE]: {
        name: 'transportVehicleTypes.plane',
        numberLabel: 'fields.flightNumber',
    },
    [TRANSPORT_VEHICLE_TYPE_OTHER]: {
        name: 'transportVehicleTypes.other',
        numberLabel: 'fields.description',
    },
}

export const TRANSPORT_POINT_TYPES_DEFINITION = {
    [TRANSPORT_POINT_TYPE_LOADING]: {
        name: 'pointTypes.loading',
        createTitle: 'pointTypes.loadingCreate',
        editTitle: 'pointTypes.loadingEdit',
        className: 'loading',
        showBookingTime: true,
        rgb_bg: '#7a6fbe',
        rgb_text: '#ffffff',
        dataFields: {
            [TRANSPORT_POINT_DATA_TYPE_CODE]: {
                label: 'fields.code',
            },
            [TRANSPORT_POINT_DATA_TYPE_PCS_COUNT]: {
                label: 'fields.pcsToLoad',
                className: 'col-md-6',
                formatter: formatters.integerFormatter,
                unit: 'ks',
                isMandatory: true,
            },
            [TRANSPORT_POINT_DATA_TYPE_WEIGHT]: {
                label: 'fields.weight',
                className: 'col-md-6',
                formatter: formatters.integerFormatter,
                unit: 'kg',
                isMandatory: true,
            },
            [TRANSPORT_POINT_DATA_TYPE_TEMPERATURE_FROM]: {
                label: 'fields.temperatureFrom',
                className: 'col-md-6 cl-left',
                formatter: formatters.integerFormatter,
                unit: '°C',
            },
            [TRANSPORT_POINT_DATA_TYPE_TEMPERATURE_TO]: {
                label: 'fields.temperatureTo',
                className: 'col-md-6',
                formatter: formatters.integerFormatter,
                unit: '°C',
            },
            [TRANSPORT_POINT_DATA_TYPE_PALLET_EXCHANGE]: {
                label: 'fields.palletExchange',
                className: 'col-md-6 cl-left',
                formatter: formatters.boolFormatter,
                type: 'bool',
            },
            [TRANSPORT_POINT_DATA_TYPE_PALLET_EXCHANGE_COUNT]: {
                label: 'fields.palletExchangeIssued',
                className: 'col-md-6 cl-left',
                formatter: formatters.integerFormatter,
                unit: 'ks',
                isMandatory: true,
                isMandatoryIf: [TRANSPORT_POINT_DATA_TYPE_PALLET_EXCHANGE, '1'],
            },
            [TRANSPORT_POINT_DATA_TYPE_PALLET_EXCHANGE_DAMAGED]: {
                label: 'fields.palletExchangeDamaged',
                className: 'col-md-6',
                formatter: formatters.integerFormatter,
                unit: 'ks',
                isMandatory: true,
                isMandatoryIf: [TRANSPORT_POINT_DATA_TYPE_PALLET_EXCHANGE, '1'],
            },
        },
        eventTypes: [
            TRANSPORT_EVENT_TYPE_LOADING_ARRIVAL,
            TRANSPORT_EVENT_TYPE_LOADING_WAITING,
            TRANSPORT_EVENT_TYPE_LOADING_START,
            TRANSPORT_EVENT_TYPE_LOADING_END,
            TRANSPORT_EVENT_TYPE_LOADING_LOADED,
        ],
    },
    [TRANSPORT_POINT_TYPE_UNLOADING]: {
        name: 'pointTypes.unloading',
        createTitle: 'pointTypes.unloadingCreate',
        editTitle: 'pointTypes.unloadingEdit',
        className: 'unloading',
        showBookingTime: true,
        rgb_bg: '#22BAA0',
        rgb_text: '#ffffff',
        dataFields: {
            [TRANSPORT_POINT_DATA_TYPE_CODE]: {
                label: 'fields.code',
                type: 'string',
            },
            [TRANSPORT_POINT_DATA_TYPE_PCS_COUNT]: {
                label: 'fields.pcsToUnload',
                className: 'col-md-6',
                formatter: formatters.integerFormatter,
                unit: 'ks',
                isMandatory: true,
            },
            [TRANSPORT_POINT_DATA_TYPE_WEIGHT]: {
                label: 'fields.weight',
                className: 'col-md-6',
                formatter: formatters.integerFormatter,
                unit: 'kg',
                isMandatory: true,
            },
            [TRANSPORT_POINT_DATA_TYPE_PALLET_EXCHANGE]: {
                label: 'fields.palletExchange',
                className: 'col-md-6 cl-left',
                formatter: formatters.boolFormatter,
                type: 'bool',
            },
            [TRANSPORT_POINT_DATA_TYPE_PALLET_EXCHANGE_COUNT]: {
                label: 'fields.palletExchangeReceived',
                className: 'col-md-6 cl-left',
                formatter: formatters.integerFormatter,
                unit: 'ks',
                isMandatory: true,
                isMandatoryIf: [TRANSPORT_POINT_DATA_TYPE_PALLET_EXCHANGE, '1'],
            },
            [TRANSPORT_POINT_DATA_TYPE_PALLET_EXCHANGE_DAMAGED]: {
                label: 'fields.palletExchangeDamaged',
                className: 'col-md-6',
                formatter: formatters.integerFormatter,
                unit: 'ks',
                isMandatory: true,
                isMandatoryIf: [TRANSPORT_POINT_DATA_TYPE_PALLET_EXCHANGE, '1'],
            },
        },
        eventTypes: [
            TRANSPORT_EVENT_TYPE_UNLOADING_ARRIVAL,
            TRANSPORT_EVENT_TYPE_UNLOADING_WAITING,
            TRANSPORT_EVENT_TYPE_UNLOADING_START,
            TRANSPORT_EVENT_TYPE_UNLOADING_END,
            TRANSPORT_EVENT_TYPE_UNLOADING_UNLOADED,
        ],
    },
    [TRANSPORT_POINT_TYPE_WAYPOINT_REAL]: {
        name: 'pointTypes.waypoint',
        createTitle: 'pointTypes.waypointCreate',
        editTitle: 'pointTypes.waypointEdit',
        className: 'waypoint',
        noDatetimes: true,
        notAvailable: true,
        rgb_bg: '#dddddd',
        rgb_text: '#444444',
        dataFields: {},
        eventTypes: [TRANSPORT_EVENT_TYPE_WAYPOINT_REAL_END],
    },
    [TRANSPORT_POINT_TYPE_WAYPOINT]: {
        name: 'pointTypes.waypoint',
        createTitle: 'pointTypes.waypointCreate',
        editTitle: 'pointTypes.waypointEdit',
        className: 'waypoint',
        noDatetimes: true,
        noDetails: true,
        rgb_bg: '#dddddd',
        rgb_text: '#444444',
        dataFields: {},
        eventTypes: [],
    },
    [TRANSPORT_POINT_TYPE_SERVICE]: {
        name: 'pointTypes.service',
        createTitle: 'pointTypes.serviceCreate',
        editTitle: 'pointTypes.serviceEdit',
        className: 'servis',
        rgb_bg: '#f25656',
        rgb_text: '#ffffff',
        dataFields: {},
        eventTypes: [TRANSPORT_EVENT_TYPE_SERVICE_ARRIVAL, TRANSPORT_EVENT_TYPE_SERVICE_DEPARTURE],
    },
    [TRANSPORT_POINT_TYPE_FUELING]: {
        name: 'pointTypes.fueling',
        createTitle: 'pointTypes.fuelingCreate',
        editTitle: 'pointTypes.fuelingEdit',
        className: 'refueling',
        rgb_bg: '#e0c12e',
        rgb_text: '#ffffff',
        dataFields: {
            [TRANSPORT_POINT_DATA_TYPE_FUELING_TYPE]: {
                label: 'fields.fuelingType',
                type: 'select',
                values: [
                    {
                        id: 'Do plna',
                        name: 'fields.fuelingTypeFull',
                    },
                    {
                        id: 'Na dojezd',
                        name: 'fields.fuelingTypeMinimal',
                    },
                ],
            },
        },
        eventTypes: [TRANSPORT_EVENT_TYPE_FUELING_ARRIVAL, TRANSPORT_EVENT_TYPE_FUELING_DEPARTURE],
    },
    [TRANSPORT_POINT_TYPE_PARKING]: {
        name: 'pointTypes.parking',
        createTitle: 'pointTypes.parkingCreate',
        editTitle: 'pointTypes.parkingEdit',
        className: 'parking',
        noDatetimesMandatory: true,
        rgb_bg: '#12AFCB',
        rgb_text: '#ffffff',
        dataFields: {},
        eventTypes: [
            TRANSPORT_EVENT_TYPE_PARKING_ARRIVAL,
            TRANSPORT_EVENT_TYPE_PARKING_CHECK_BEFORE,
            TRANSPORT_EVENT_TYPE_PARKING_TIME,
            TRANSPORT_EVENT_TYPE_PARKING_CHECK_AFTER,
            TRANSPORT_EVENT_TYPE_PARKING_DEPARTURE,
        ],
    },
    [TRANSPORT_POINT_TYPE_PARKING_AND_CHANGE]: {
        name: 'pointTypes.parkingWithChange',
        createTitle: 'pointTypes.parkingWithChangeCreate',
        editTitle: 'pointTypes.parkingWithChangeEdit',
        className: 'parking',
        noDatetimesMandatory: true,
        rgb_bg: '#12AFCB',
        rgb_text: '#ffffff',
        dataFields: {
            [TRANSPORT_POINT_DATA_TYPE_DRIVER]: {
                label: 'fields.driver',
                type: 'select',
                values: [],
                className: 'col-md-6',
                isMandatory: true,
            },
        },
        eventTypes: [
            TRANSPORT_EVENT_TYPE_PARKING_AND_CHANGE_ARRIVAL,
            TRANSPORT_EVENT_TYPE_PARKING_AND_CHANGE_QUIT_CHECK,
            TRANSPORT_EVENT_TYPE_PARKING_AND_CHANGE_CHECK,
        ],
    },
    [TRANSPORT_POINT_TYPE_TRAILER_CHANGE]: {
        name: 'pointTypes.trailerChange',
        createTitle: 'pointTypes.trailerChangeCreate',
        editTitle: 'pointTypes.trailerChangeEdit',
        className: 'trailer',
        rgb_bg: '#333333',
        rgb_text: '#ffffff',
        dataFields: {
            [TRANSPORT_POINT_DATA_TYPE_VEHICLE]: {
                label: 'fields.vehicle',
                type: 'select',
                values: [],
                className: 'col-md-6',
                isMandatory: true,
            },
            [TRANSPORT_POINT_DATA_TYPE_REGISTRATION]: {
                label: 'fields.trailerRegistration',
                type: 'string',
                className: 'col-md-6',
                disabled: true,
            },
            [TRANSPORT_POINT_DATA_TYPE_DRIVER_PHONE]: {
                label: 'fields.phone',
                type: 'string',
                className: 'col-md-6',
                disabled: true,
            },
        },
        eventTypes: [
            TRANSPORT_EVENT_TYPE_TRAILER_CHANGE_ARRIVAL,
            TRANSPORT_EVENT_TYPE_TRAILER_CHANGE_CHECK,
            TRANSPORT_EVENT_TYPE_TRAILER_CHANGE_DEPARTURE,
        ],
    },
    [TRANSPORT_POINT_TYPE_TRAILER_CATCH]: {
        name: 'pointTypes.trailerCatch',
        createTitle: 'pointTypes.trailerCatchCreate',
        editTitle: 'pointTypes.trailerCatchEdit',
        className: 'trailer',
        rgb_bg: '#333333',
        rgb_text: '#ffffff',
        dataFields: {
            [TRANSPORT_POINT_DATA_TYPE_REGISTRATION]: {
                label: 'fields.trailerRegistration',
                type: 'select',
                values: [],
                className: 'col-md-6',
                isMandatory: true,
            },
        },
        eventTypes: [
            TRANSPORT_EVENT_TYPE_TRAILER_CATCH_ARRIVAL,
            TRANSPORT_EVENT_TYPE_TRAILER_CATCH_CHECK,
            TRANSPORT_EVENT_TYPE_TRAILER_CATCH_DEPARTURE,
        ],
    },
    [TRANSPORT_POINT_TYPE_TRAILER_OUT]: {
        name: 'pointTypes.trailerOut',
        createTitle: 'pointTypes.trailerOutCreate',
        editTitle: 'pointTypes.trailerOutEdit',
        className: 'trailer',
        rgb_bg: '#333333',
        rgb_text: '#ffffff',
        dataFields: {
            [TRANSPORT_POINT_DATA_TYPE_REGISTRATION]: {
                label: 'fields.trailerRegistration',
                type: 'select',
                values: [],
                className: 'col-md-6',
                isMandatory: true,
            },
        },
        eventTypes: [TRANSPORT_EVENT_TYPE_TRAILER_OUT_ARRIVAL, TRANSPORT_EVENT_TYPE_TRAILER_OUT_CHECK, TRANSPORT_EVENT_TYPE_TRAILER_OUT_DEPARTURE],
    },
    [TRANSPORT_POINT_TYPE_MOVE]: {
        name: 'pointTypes.move',
        createTitle: 'pointTypes.moveCreate',
        editTitle: 'pointTypes.moveEdit',
        className: 'waypoint',
        rgb_bg: '#dddddd',
        rgb_text: '#444444',
        dataFields: {},
        eventTypes: [TRANSPORT_EVENT_TYPE_MOVE_END],
    },
    [TRANSPORT_POINT_TYPE_CUSTOM_HOUSE]: {
        name: 'pointTypes.customHouse',
        createTitle: 'pointTypes.customHouseCreate',
        editTitle: 'pointTypes.customHouseEdit',
        className: 'waypoint',
        rgb_bg: '#dddddd',
        rgb_text: '#444444',
        dataFields: {},
        eventTypes: [TRANSPORT_EVENT_TYPE_CUSTOM_HOUSE_ARRIVAL, TRANSPORT_EVENT_TYPE_CUSTOM_HOUSE_DEPARTURE],
    },
    [TRANSPORT_POINT_TYPE_VEHICLE_QUIT]: {
        name: 'pointTypes.vehicleQuit',
        createTitle: 'pointTypes.vehicleQuitCreate',
        editTitle: 'pointTypes.vehicleQuitEdit',
        className: 'waypoint',
        rgb_bg: '#dddddd',
        rgb_text: '#444444',
        dataFields: {},
        eventTypes: [TRANSPORT_EVENT_TYPE_VEHICLE_QUIT_CHECK, TRANSPORT_EVENT_TYPE_VEHICLE_QUIT_FINISH],
    },
}

export const TRANSPORT_EVENT_TYPES_DEFINITION = {
    [TRANSPORT_EVENT_TYPE_PREDEPARTURE_CHECK]: {
        name: 'transportEvents.predepartureCheck',
        possibleNextEvents: [],
        dataFields: [
            TRANSPORT_EVENT_DATA_TYPE_VEHICLE_DEFECT,
            TRANSPORT_EVENT_DATA_TYPE_VEHICLE_DEFECT_TEXT,
            TRANSPORT_EVENT_DATA_TYPE_TRAILER_DEFECT,
            TRANSPORT_EVENT_DATA_TYPE_TRAILER_DEFECT_TEXT,
            TRANSPORT_EVENT_DATA_TYPE_DOCUMENTS_DEFECT,
            TRANSPORT_EVENT_DATA_TYPE_DOCUMENTS_DEFECT_TEXT,
        ],
    },

    [TRANSPORT_EVENT_TYPE_LOADING_ARRIVAL]: {
        name: 'transportEvents.loadingArrival',
        possibleNextEvents: [TRANSPORT_EVENT_TYPE_LOADING_WAITING, TRANSPORT_EVENT_TYPE_LOADING_START, TRANSPORT_EVENT_TYPE_LOADING_END, TRANSPORT_EVENT_TYPE_LOADING_LOADED],
        dataFields: [],
    },
    [TRANSPORT_EVENT_TYPE_LOADING_SYSTEM_CHECK]: {
        name: 'transportEvents.systemCheck',
        possibleNextEvents: [TRANSPORT_EVENT_TYPE_LOADING_WAITING, TRANSPORT_EVENT_TYPE_LOADING_START, TRANSPORT_EVENT_TYPE_LOADING_END, TRANSPORT_EVENT_TYPE_LOADING_LOADED],
        dataFields: [],
    },
    [TRANSPORT_EVENT_TYPE_LOADING_WAITING]: {
        name: 'transportEvents.loadingWaiting',
        possibleNextEvents: [TRANSPORT_EVENT_TYPE_LOADING_START, TRANSPORT_EVENT_TYPE_LOADING_END, TRANSPORT_EVENT_TYPE_LOADING_LOADED],
        dataFields: [TRANSPORT_EVENT_DATA_TYPE_WAITING_REASON],
    },
    [TRANSPORT_EVENT_TYPE_LOADING_START]: {
        name: 'transportEvents.loadingStart',
        possibleNextEvents: [TRANSPORT_EVENT_TYPE_LOADING_END, TRANSPORT_EVENT_TYPE_LOADING_LOADED],
        dataFields: [],
    },
    [TRANSPORT_EVENT_TYPE_LOADING_END]: {
        name: 'transportEvents.loadingEnd',
        possibleNextEvents: [TRANSPORT_EVENT_TYPE_LOADING_LOADED],
        dataFields: [],
    },
    [TRANSPORT_EVENT_TYPE_LOADING_LOADED]: {
        name: 'transportEvents.loadingLoaded',
        possibleNextEvents: [],
        dataFields: [
            TRANSPORT_EVENT_DATA_TYPE_PCS,
            TRANSPORT_EVENT_DATA_TYPE_WEIGHT,
            TRANSPORT_EVENT_DATA_TYPE_PALLET_EXCHANGE,
            TRANSPORT_EVENT_DATA_TYPE_PALLET_EXCHANGE_ACCEPTED,
            TRANSPORT_EVENT_DATA_TYPE_NOTE,
        ],
    },

    [TRANSPORT_EVENT_TYPE_UNLOADING_ARRIVAL]: {
        name: 'transportEvents.unloadingArrival',
        possibleNextEvents: [TRANSPORT_EVENT_TYPE_UNLOADING_WAITING, TRANSPORT_EVENT_TYPE_UNLOADING_START, TRANSPORT_EVENT_TYPE_UNLOADING_END, TRANSPORT_EVENT_TYPE_UNLOADING_UNLOADED],
        dataFields: [],
    },
    [TRANSPORT_EVENT_TYPE_UNLOADING_WAITING]: {
        name: 'transportEvents.unloadingWaiting',
        possibleNextEvents: [TRANSPORT_EVENT_TYPE_UNLOADING_START, TRANSPORT_EVENT_TYPE_UNLOADING_END, TRANSPORT_EVENT_TYPE_UNLOADING_UNLOADED],
        dataFields: [TRANSPORT_EVENT_DATA_TYPE_WAITING_REASON],
    },
    [TRANSPORT_EVENT_TYPE_UNLOADING_START]: {
        name: 'transportEvents.unloadingStart',
        possibleNextEvents: [TRANSPORT_EVENT_TYPE_UNLOADING_END, TRANSPORT_EVENT_TYPE_UNLOADING_UNLOADED],
        dataFields: [],
    },
    [TRANSPORT_EVENT_TYPE_UNLOADING_END]: {
        name: 'transportEvents.unloadingEnd',
        possibleNextEvents: [TRANSPORT_EVENT_TYPE_UNLOADING_UNLOADED],
        dataFields: [],
    },
    [TRANSPORT_EVENT_TYPE_UNLOADING_UNLOADED]: {
        name: 'transportEvents.unloadingUnloaded',
        possibleNextEvents: [],
        dataFields: [
            TRANSPORT_EVENT_DATA_TYPE_UNLOADED_WITH_OBJECTION,
            TRANSPORT_EVENT_DATA_TYPE_PCS,
            TRANSPORT_EVENT_DATA_TYPE_WEIGHT,
            TRANSPORT_EVENT_DATA_TYPE_PALLET_EXCHANGE,
            TRANSPORT_EVENT_DATA_TYPE_PALLET_EXCHANGE_ISSUED,
            TRANSPORT_EVENT_DATA_TYPE_NOTE,
        ],
    },

    [TRANSPORT_EVENT_TYPE_WAYPOINT_REAL_END]: {
        name: 'transportEvents.waypointEnd',
        possibleNextEvents: [],
        dataFields: [],
    },

    [TRANSPORT_EVENT_TYPE_FUELING_ARRIVAL]: {
        name: 'transportEvents.fuelingArrival',
        possibleNextEvents: [TRANSPORT_EVENT_TYPE_FUELING_DEPARTURE],
        dataFields: [],
    },
    [TRANSPORT_EVENT_TYPE_FUELING_DEPARTURE]: {
        name: 'transportEvents.fuelingDeparture',
        possibleNextEvents: [],
        dataFields: [TRANSPORT_EVENT_DATA_TYPE_FUELED, TRANSPORT_EVENT_DATA_TYPE_NOTE],
    },

    [TRANSPORT_EVENT_TYPE_SERVICE_ARRIVAL]: {
        name: 'transportEvents.serviceArrival',
        possibleNextEvents: [TRANSPORT_EVENT_TYPE_SERVICE_DEPARTURE],
        dataFields: [],
    },
    [TRANSPORT_EVENT_TYPE_SERVICE_DEPARTURE]: {
        name: 'transportEvents.serviceDeparture',
        possibleNextEvents: [],
        dataFields: [TRANSPORT_EVENT_DATA_TYPE_NOTE],
    },

    [TRANSPORT_EVENT_TYPE_PARKING_ARRIVAL]: {
        name: 'transportEvents.parkingArrival',
        possibleNextEvents: [TRANSPORT_EVENT_TYPE_PARKING_CHECK_BEFORE],
        dataFields: [],
    },
    [TRANSPORT_EVENT_TYPE_PARKING_CHECK_BEFORE]: {
        name: 'transportEvents.parkingCheckBefore',
        possibleNextEvents: [TRANSPORT_EVENT_TYPE_PARKING_TIME],
        dataFields: [TRANSPORT_EVENT_DATA_TYPE_DEFECT, TRANSPORT_EVENT_DATA_TYPE_DEFECT_TEXT],
    },
    [TRANSPORT_EVENT_TYPE_PARKING_TIME]: {
        name: 'transportEvents.parkingTime',
        possibleNextEvents: [TRANSPORT_EVENT_TYPE_PARKING_CHECK_AFTER],
        dataFields: [TRANSPORT_EVENT_DATA_TYPE_TIME_PLANNED_END],
    },
    [TRANSPORT_EVENT_TYPE_PARKING_CHECK_AFTER]: {
        name: 'transportEvents.parkingCheckAfter',
        possibleNextEvents: [TRANSPORT_EVENT_TYPE_PARKING_DEPARTURE],
        dataFields: [TRANSPORT_EVENT_DATA_TYPE_DEFECT, TRANSPORT_EVENT_DATA_TYPE_DEFECT_TEXT],
    },
    [TRANSPORT_EVENT_TYPE_PARKING_DEPARTURE]: {
        name: 'transportEvents.parkingDeparture',
        possibleNextEvents: [],
        dataFields: [],
    },

    [TRANSPORT_EVENT_TYPE_TRAILER_CHANGE_ARRIVAL]: {
        name: 'transportEvents.trailerChangeArrival',
        possibleNextEvents: [TRANSPORT_EVENT_TYPE_TRAILER_CHANGE_DEPARTURE],
        dataFields: [],
    },
    [TRANSPORT_EVENT_TYPE_TRAILER_CHANGE_CHECK]: {
        name: 'transportEvents.trailerChangeCheck',
        possibleNextEvents: [TRANSPORT_EVENT_TYPE_TRAILER_CHANGE_DEPARTURE],
        dataFields: [TRANSPORT_EVENT_DATA_TYPE_DEFECT, TRANSPORT_EVENT_DATA_TYPE_DEFECT_TEXT],
    },
    [TRANSPORT_EVENT_TYPE_TRAILER_CHANGE_DEPARTURE]: {
        name: 'transportEvents.trailerChangeDeparture',
        possibleNextEvents: [],
        dataFields: [TRANSPORT_EVENT_DATA_TYPE_REGISTRATION, TRANSPORT_EVENT_DATA_TYPE_NOTE],
    },

    [TRANSPORT_EVENT_TYPE_TRAILER_CATCH_ARRIVAL]: {
        name: 'transportEvents.trailerCatchArrival',
        possibleNextEvents: [TRANSPORT_EVENT_TYPE_TRAILER_CATCH_DEPARTURE],
        dataFields: [],
    },
    [TRANSPORT_EVENT_TYPE_TRAILER_CATCH_CHECK]: {
        name: 'transportEvents.trailerCatchCheck',
        possibleNextEvents: [TRANSPORT_EVENT_TYPE_TRAILER_CATCH_DEPARTURE],
        dataFields: [TRANSPORT_EVENT_DATA_TYPE_DEFECT, TRANSPORT_EVENT_DATA_TYPE_DEFECT_TEXT],
    },
    [TRANSPORT_EVENT_TYPE_TRAILER_CATCH_DEPARTURE]: {
        name: 'transportEvents.trailerCatchDeparture',
        possibleNextEvents: [],
        dataFields: [TRANSPORT_EVENT_DATA_TYPE_REGISTRATION, TRANSPORT_EVENT_DATA_TYPE_NOTE],
    },

    [TRANSPORT_EVENT_TYPE_TRAILER_OUT_ARRIVAL]: {
        name: 'transportEvents.trailerOutArrival',
        possibleNextEvents: [TRANSPORT_EVENT_TYPE_TRAILER_OUT_DEPARTURE],
        dataFields: [],
    },
    [TRANSPORT_EVENT_TYPE_TRAILER_OUT_CHECK]: {
        name: 'transportEvents.trailerOutCheck',
        possibleNextEvents: [TRANSPORT_EVENT_TYPE_TRAILER_OUT_DEPARTURE],
        dataFields: [TRANSPORT_EVENT_DATA_TYPE_DEFECT, TRANSPORT_EVENT_DATA_TYPE_DEFECT_TEXT],
    },
    [TRANSPORT_EVENT_TYPE_TRAILER_OUT_DEPARTURE]: {
        name: 'transportEvents.trailerOutDeparture',
        possibleNextEvents: [],
        dataFields: [TRANSPORT_EVENT_DATA_TYPE_REGISTRATION, TRANSPORT_EVENT_DATA_TYPE_NOTE],
    },

    [TRANSPORT_EVENT_TYPE_PARKING_AND_CHANGE_ARRIVAL]: {
        name: 'transportEvents.parkingWithChangeArrival',
        possibleNextEvents: [TRANSPORT_EVENT_TYPE_PARKING_AND_CHANGE_QUIT_CHECK],
        dataFields: [],
    },
    [TRANSPORT_EVENT_TYPE_PARKING_AND_CHANGE_QUIT_CHECK]: {
        name: 'transportEvents.parkingWithChangeQuitCheck',
        possibleNextEvents: [TRANSPORT_EVENT_TYPE_PARKING_AND_CHANGE_CHECK],
        dataFields: [
            TRANSPORT_EVENT_DATA_TYPE_VEHICLE_DEFECT,
            TRANSPORT_EVENT_DATA_TYPE_VEHICLE_DEFECT_TEXT,
            TRANSPORT_EVENT_DATA_TYPE_TRAILER_DEFECT,
            TRANSPORT_EVENT_DATA_TYPE_TRAILER_DEFECT_TEXT,
        ],
    },
    [TRANSPORT_EVENT_TYPE_PARKING_AND_CHANGE_CHECK]: {
        name: 'transportEvents.parkingWithChangeCheck',
        possibleNextEvents: [],
        dataFields: [
            TRANSPORT_EVENT_DATA_TYPE_VEHICLE_DEFECT,
            TRANSPORT_EVENT_DATA_TYPE_VEHICLE_DEFECT_TEXT,
            TRANSPORT_EVENT_DATA_TYPE_TRAILER_DEFECT,
            TRANSPORT_EVENT_DATA_TYPE_TRAILER_DEFECT_TEXT,
            TRANSPORT_EVENT_DATA_TYPE_DOCUMENTS_DEFECT,
            TRANSPORT_EVENT_DATA_TYPE_DOCUMENTS_DEFECT_TEXT,
        ],
    },

    [TRANSPORT_EVENT_TYPE_MOVE_END]: {
        name: 'transportEvents.moveEnd',
        possibleNextEvents: [],
        dataFields: [],
    },

    [TRANSPORT_EVENT_TYPE_CUSTOM_HOUSE_ARRIVAL]: {
        name: 'transportEvents.customHouseArrival',
        possibleNextEvents: [TRANSPORT_EVENT_TYPE_CUSTOM_HOUSE_DEPARTURE],
        dataFields: [],
    },
    [TRANSPORT_EVENT_TYPE_CUSTOM_HOUSE_DEPARTURE]: {
        name: 'transportEvents.customHouseDeparture',
        possibleNextEvents: [],
        dataFields: [TRANSPORT_EVENT_DATA_TYPE_DEFECT, TRANSPORT_EVENT_DATA_TYPE_DEFECT_TEXT],
    },

    [TRANSPORT_EVENT_TYPE_VEHICLE_QUIT_CHECK]: {
        name: 'transportEvents.vehicleQuitCheck',
        possibleNextEvents: [TRANSPORT_EVENT_TYPE_VEHICLE_QUIT_FINISH],
        dataFields: [
            TRANSPORT_EVENT_DATA_TYPE_VEHICLE_DEFECT,
            TRANSPORT_EVENT_DATA_TYPE_VEHICLE_DEFECT_TEXT,
            TRANSPORT_EVENT_DATA_TYPE_TRAILER_DEFECT,
            TRANSPORT_EVENT_DATA_TYPE_TRAILER_DEFECT_TEXT,
        ],
    },
    [TRANSPORT_EVENT_TYPE_VEHICLE_QUIT_FINISH]: {
        name: 'transportEvents.vehicleQuitFinish',
        possibleNextEvents: [],
        dataFields: [],
    },
}

export const TRANSPORT_EVENT_DATA_TYPES_DEFINITION = {
    [TRANSPORT_EVENT_DATA_TYPE_WAITING_REASON]: {
        label: 'fields.reason',
    },
    [TRANSPORT_EVENT_DATA_TYPE_NOTE]: {
        label: 'fields.note',
    },
    [TRANSPORT_EVENT_DATA_TYPE_PCS]: {
        label: 'fields.pcs',
        formatter: formatters.integerFormatter,
        unit: 'ks',
        className: 'col-md-4 cl-left',
    },
    [TRANSPORT_EVENT_DATA_TYPE_WEIGHT]: {
        label: 'fields.weight',
        formatter: formatters.integerFormatter,
        unit: 'kg',
        className: 'col-md-4',
    },
    [TRANSPORT_EVENT_DATA_TYPE_PALLET_EXCHANGE]: {
        label: 'fields.palletExchange',
        formatter: formatters.boolFormatter,
        type: 'bool',
        className: 'col-md-4 cl-left',
    },
    [TRANSPORT_EVENT_DATA_TYPE_PALLET_EXCHANGE_ISSUED]: {
        label: 'fields.palletExchangeIssued',
        formatter: formatters.integerFormatter,
        unit: 'ks',
        className: 'col-md-4',
    },
    [TRANSPORT_EVENT_DATA_TYPE_PALLET_EXCHANGE_ACCEPTED]: {
        label: 'fields.palletExchangeReceived',
        formatter: formatters.integerFormatter,
        unit: 'ks',
        className: 'col-md-4',
    },
    [TRANSPORT_EVENT_DATA_TYPE_FUELED]: {
        label: 'fields.fueled',
        formatter: formatters.boolFormatter,
        type: 'bool',
        className: 'col-md-4 cl-left',
    },
    [TRANSPORT_EVENT_DATA_TYPE_DEFECT]: {
        label: 'fields.defect',
        formatter: formatters.boolFormatterDanger,
        type: 'bool',
        className: 'col-md-4 cl-left',
    },
    [TRANSPORT_EVENT_DATA_TYPE_DEFECT_TEXT]: {
        label: 'fields.defectDescription',
    },
    [TRANSPORT_EVENT_DATA_TYPE_TIME_PLANNED_END]: {
        label: 'fields.plannedEndTime',
        formatter: formatters.datetimeFormatter,
        type: 'time',
        className: 'col-md-6 cl-left',
    },
    [TRANSPORT_EVENT_DATA_TYPE_VEHICLE_DEFECT]: {
        label: 'fields.vehicleDefect',
        formatter: formatters.boolFormatterDanger,
        type: 'bool',
        className: 'col-md-4 cl-left',
    },
    [TRANSPORT_EVENT_DATA_TYPE_VEHICLE_DEFECT_TEXT]: {
        label: 'fields.defectDescription',
    },
    [TRANSPORT_EVENT_DATA_TYPE_TRAILER_DEFECT]: {
        label: 'fields.trailerDefect',
        formatter: formatters.boolFormatterDanger,
        type: 'bool',
        className: 'col-md-4 cl-left',
    },
    [TRANSPORT_EVENT_DATA_TYPE_TRAILER_DEFECT_TEXT]: {
        label: 'fields.defectDescription',
    },
    [TRANSPORT_EVENT_DATA_TYPE_DOCUMENTS_DEFECT]: {
        label: 'fields.documentsDefect',
        formatter: formatters.boolFormatterDanger,
        type: 'bool',
        className: 'col-md-4 cl-left',
    },
    [TRANSPORT_EVENT_DATA_TYPE_DOCUMENTS_DEFECT_TEXT]: {
        label: 'fields.defectDescription',
    },
    [TRANSPORT_EVENT_DATA_TYPE_UNLOADED_WITH_OBJECTION]: {
        label: 'fields.withObjection',
        formatter: formatters.boolFormatterDanger,
        type: 'bool',
        className: 'col-md-4 cl-left',
    },
    [TRANSPORT_EVENT_DATA_TYPE_REGISTRATION]: {
        label: 'fields.registration',
        className: 'col-md-4 cl-left',
    },
}

export const TRANSPORT_POINT_STATES_DEFINITION = {
    [TRANSPORT_POINT_STATE_ON_WAY]: {
        className: 'far fa-arrow-circle-right',
    },
    [TRANSPORT_POINT_STATE_ARRIVED]: {
        className: 'far fa-check-circle',
    },
    [TRANSPORT_POINT_STATE_WAITING]: {
        className: 'far fa-clock',
    },
    [TRANSPORT_POINT_STATE_IN_PROCESS]: {
        className: 'fas fa-cog fa-spin',
    },
    [TRANSPORT_POINT_STATE_DONE]: {
        className: 'fas fa-circle',
    },
    [TRANSPORT_POINT_STATE_FINISHED]: {
        className: 'fas fa-check-circle',
    },
}

export const LOG_EVENTS_DEFINITION = {
    [LOG_EVENT_TRANSPORT_CREATED]: {
        name: 'transportLogChanges.transportCreated',
        className: 'text-success',
    },
    [LOG_EVENT_TRANSPORT_CREATED_BY_COPYING]: {
        name: 'transportLogChanges.transportCreatedByCopying',
        data: {
            source_transport: 'fields.sourceTransport',
        },
        className: 'text-success',
    },
    [LOG_EVENT_TRANSPORT_CREATED_FROM_TEMPLATE]: {
        name: 'transportLogChanges.transportCreatedFromTemplate',
        data: {
            template_name: 'fields.template',
        },
        className: 'text-success',
    },
    [LOG_EVENT_TRANSPORT_CREATED_FROM_TRANSPOREON_TRANSPORT]: {
        name: 'transportLogChanges.transportCreatedFromTransporeon',
        data: {
            transporeon_transport_number: 'fields.transporeonOffersNumber',
        },
        className: 'text-success',
    },
    [LOG_EVENT_TRANSPORT_UPDATED]: {
        name: 'transportLogChanges.transportUpdated',
    },
    [LOG_EVENT_TRANSPORT_DELETED]: {
        name: 'transportLogChanges.transportDeleted',
        className: 'text-danger',
    },
    [LOG_EVENT_TRANSPORT_RESTORED]: {
        name: 'transportLogChanges.transportRestored',
        className: 'text-success',
    },
    [LOG_EVENT_TRANSPORT_SENT_TO_VEHICLE]: {
        name: 'transportLogChanges.transportSentToVehicle',
        data: {
            vehicle_name: 'fields.vehicle',
        },
        className: 'text-primary',
    },
    [LOG_EVENT_TRANSPORT_UPDATED_IN_VEHICLE]: {
        name: 'transportLogChanges.transportUpdatedInVehicle',
        data: {
            vehicle_name: 'fields.vehicle',
        },
    },
    [LOG_EVENT_TRANSPORT_DELETED_FROM_VEHICLE]: {
        name: 'transportLogChanges.transportDeletedFromVehicle',
        data: {
            vehicle_name: 'fields.vehicle',
        },
    },
    [LOG_EVENT_TRANSPORT_POINT_CREATED]: {
        name: 'transportLogChanges.transportPointCreated',
        data: {
            type_id: 'fields.pointType',
            name: 'fields.pointName',
        },
        className: 'text-success',
    },
    [LOG_EVENT_TRANSPORT_POINT_UPDATED]: {
        name: 'transportLogChanges.transportPointUpdated',
        data: {
            type_id: 'fields.pointType',
            name: 'fields.pointName',
        },
    },
    [LOG_EVENT_TRANSPORT_POINT_DELETED]: {
        name: 'transportLogChanges.transportPointDeleted',
        data: {
            type_id: 'fields.pointType',
            name: 'fields.pointName',
        },
        className: 'text-danger',
    },
    [LOG_EVENT_TRANSPORT_POINT_RESTORED]: {
        name: 'transportLogChanges.transportPointRestored',
        data: {
            type_id: 'fields.pointType',
            name: 'fields.pointName',
        },
        className: 'text-success',
    },
    [LOG_EVENT_TRANSPORT_POINT_EVENT_CREATED]: {
        name: 'transportLogChanges.transportEventCreated',
        data: {
            event_type_id: 'fields.eventType',
            type_id: 'fields.pointType',
            point_name: 'fields.pointName',
        },
        className: 'text-success',
    },
    [LOG_EVENT_TRANSPORT_POINT_EVENT_UPDATED]: {
        name: 'transportLogChanges.transportEventUpdated',
        data: {
            event_type_id: 'fields.eventType',
            type_id: 'fields.pointType',
            point_name: 'fields.pointName',
        },
    },
    [LOG_EVENT_TRANSPORT_POINT_EVENT_DELETED]: {
        name: 'transportLogChanges.transportEventDeleted',
        data: {
            event_type_id: 'fields.eventType',
            type_id: 'fields.pointType',
            point_name: 'fields.pointName',
        },
        className: 'text-danger',
    },
}

export const LOG_CHANGES_DEFINITION = {
    [LOG_CHANGE_TYPE_TRANSPORT_STATE]: {
        name: 'fields.transportState',
    },
    [LOG_CHANGE_TYPE_TRANSPORT_DISPONENT]: {
        name: 'fields.disponent',
    },
    [LOG_CHANGE_TYPE_TRANSPORT_TYPE]: {
        name: 'fields.transportType',
    },
    [LOG_CHANGE_TYPE_TRANSPORT_CARGO_TYPE]: {
        name: 'fields.cargoType',
    },
    [LOG_CHANGE_TYPE_TRANSPORT_NOTE]: {
        name: 'fields.note',
    },
    [LOG_CHANGE_TYPE_TRANSPORT_CUSTOMER]: {
        name: 'fields.customer',
    },
    [LOG_CHANGE_TYPE_TRANSPORT_CUSTOMER_ORDER_NUMBER]: {
        name: 'fields.customersOrderNumber',
    },
    [LOG_CHANGE_TYPE_TRANSPORT_CUSTOMER_PRICE]: {
        name: 'fields.customersPrice',
        formatter: formatters.integerFormatter,
    },
    [LOG_CHANGE_TYPE_TRANSPORT_CUSTOMER_CURRENCY]: {
        name: 'fields.customersCurrency',
    },
    [LOG_CHANGE_TYPE_TRANSPORT_CUSTOMER_NOTE]: {
        name: 'fields.customersNote',
    },
    [LOG_CHANGE_TYPE_TRANSPORT_VEHICLE]: {
        name: 'fields.vehicle',
    },
    [LOG_CHANGE_TYPE_TRANSPORT_VEHICLE_NOTE]: {
        name: 'fields.vehicleNote',
    },
    [LOG_CHANGE_TYPE_TRANSPORT_SPEDITION]: {
        name: 'fields.spedition',
        formatter: formatters.boolFormatter,
    },
    [LOG_CHANGE_TYPE_TRANSPORT_CARRIER]: {
        name: 'fields.carrier',
    },
    [LOG_CHANGE_TYPE_TRANSPORT_CARRIER_ORDER_NUMBER]: {
        name: 'fields.carriersOrderNumber',
    },
    [LOG_CHANGE_TYPE_TRANSPORT_CARRIER_PRICE]: {
        name: 'fields.carriersPrice',
        formatter: formatters.integerFormatter,
    },
    [LOG_CHANGE_TYPE_TRANSPORT_CARRIER_CURRENCY]: {
        name: 'fields.carriersCurrency',
    },
    [LOG_CHANGE_TYPE_TRANSPORT_CARRIER_NOTE]: {
        name: 'fields.carriersNote',
    },
    [LOG_CHANGE_TYPE_TRANSPORT_PREDEPARTURE_CHECK]: {
        name: 'fields.predepartureCheck',
        formatter: formatters.boolFormatter,
    },
    [LOG_CHANGE_TYPE_TRANSPORT_POINT_ORDER]: {
        name: 'fields.ordering',
    },
    [LOG_CHANGE_TYPE_TRANSPORT_POINT_STATE]: {
        name: 'fields.state',
    },
    [LOG_CHANGE_TYPE_TRANSPORT_POINT_DATETIME_PLANNED_FROM]: {
        name: 'fields.plannedTimeFrom',
        formatter: formatters.datetimeFormatter,
    },
    [LOG_CHANGE_TYPE_TRANSPORT_POINT_DATETIME_PLANNED_TO]: {
        name: 'fields.plannedTimeTo',
        formatter: formatters.datetimeFormatter,
    },
    [LOG_CHANGE_TYPE_TRANSPORT_POINT_DATETIME_SPECIFIED_FROM]: {
        name: 'fields.specifiedTimeFrom',
        formatter: formatters.datetimeFormatter,
    },
    [LOG_CHANGE_TYPE_TRANSPORT_POINT_DATETIME_SPECIFIED_TO]: {
        name: 'fields.specifiedTimeTo',
        formatter: formatters.datetimeFormatter,
    },
    [LOG_CHANGE_TYPE_TRANSPORT_POINT_DATETIME_ARRIVAL]: {
        name: 'fields.arrivalTime',
        formatter: formatters.datetimeFormatter,
    },
    [LOG_CHANGE_TYPE_TRANSPORT_POINT_DATETIME_FINISHED]: {
        name: 'fields.finishedTime',
        formatter: formatters.datetimeFormatter,
    },
    [LOG_CHANGE_TYPE_TRANSPORT_POINT_NAME]: {
        name: 'fields.companyName',
    },
    [LOG_CHANGE_TYPE_TRANSPORT_POINT_STREET]: {
        name: 'fields.street',
    },
    [LOG_CHANGE_TYPE_TRANSPORT_POINT_CITY]: {
        name: 'fields.city',
    },
    [LOG_CHANGE_TYPE_TRANSPORT_POINT_ZIPCODE]: {
        name: 'fields.zipcode',
    },
    [LOG_CHANGE_TYPE_TRANSPORT_POINT_COUNTRY]: {
        name: 'fields.country',
    },
    [LOG_CHANGE_TYPE_TRANSPORT_POINT_GPS_LAT]: {
        name: 'fields.gpsLat',
    },
    [LOG_CHANGE_TYPE_TRANSPORT_POINT_GPS_LNG]: {
        name: 'fields.gpsLng',
    },
    [LOG_CHANGE_TYPE_TRANSPORT_POINT_NOTE]: {
        name: 'fields.note',
    },
    [LOG_CHANGE_TYPE_TRANSPORT_POINT_CODE]: {
        name: 'fields.code',
    },
    [LOG_CHANGE_TYPE_TRANSPORT_POINT_PCS_COUNT]: {
        name: 'fields.pcs',
        formatter: formatters.integerFormatter,
    },
    [LOG_CHANGE_TYPE_TRANSPORT_POINT_WEIGHT]: {
        name: 'fields.weight',
        formatter: formatters.weightFormatter,
    },
    [LOG_CHANGE_TYPE_TRANSPORT_POINT_TEMPERATURE_FROM]: {
        name: 'fields.temperatureFrom',
        formatter: formatters.integerFormatter,
    },
    [LOG_CHANGE_TYPE_TRANSPORT_POINT_TEMPERATURE_TO]: {
        name: 'fields.temperatureTo',
        formatter: formatters.integerFormatter,
    },
    [LOG_CHANGE_TYPE_TRANSPORT_POINT_PALLET_EXCHANGE]: {
        name: 'fields.palletExchange',
        formatter: formatters.boolFormatter,
    },
    [LOG_CHANGE_TYPE_TRANSPORT_POINT_PALLET_EXCHANGE_COUNT]: {
        name: 'fields.count',
        formatter: formatters.integerFormatter,
    },
    [LOG_CHANGE_TYPE_TRANSPORT_POINT_FUELING_TYPE]: {
        name: 'fields.fuelingType',
    },
    [LOG_CHANGE_TYPE_TRANSPORT_POINT_VEHICLE]: {
        name: 'fields.vehicle',
    },
    [LOG_CHANGE_TYPE_TRANSPORT_POINT_REGISTRATION]: {
        name: 'fields.trailerRegistration',
    },
    [LOG_CHANGE_TYPE_TRANSPORT_POINT_DRIVER_PHONE]: {
        name: 'fields.phone',
    },
    [LOG_CHANGE_TYPE_TRANSPORT_POINT_DRIVER]: {
        name: 'fields.driver',
    },
    [LOG_CHANGE_TYPE_TRANSPORT_POINT_IS_BOOKING_REQUIRED]: {
        name: 'fields.booking',
        formatter: formatters.boolFormatter,
    },
    [LOG_CHANGE_TYPE_TRANSPORT_POINT_DATETIME_BOOKING_FROM]: {
        name: 'fields.bookingTimeFrom',
        formatter: formatters.datetimeFormatter,
    },
    [LOG_CHANGE_TYPE_TRANSPORT_POINT_DATETIME_BOOKING_TO]: {
        name: 'fields.bookingTimeTo',
        formatter: formatters.datetimeFormatter,
    },
    [LOG_CHANGE_TYPE_TRANSPORT_POINT_BOOKING_URL]: {
        name: 'fields.bookingURL',
    },
    [LOG_CHANGE_TYPE_TRANSPORT_POINT_BOOKING_NOTE]: {
        name: 'fields.bookingNote',
    },
    [LOG_CHANGE_TYPE_TRANSPORT_POINT_EVENT_DATETIME]: {
        name: 'fields.time',
        formatter: formatters.datetimeFormatter,
    },
    [LOG_CHANGE_TYPE_TRANSPORT_POINT_EVENT_VEHICLE]: {
        name: 'fields.vehicle',
    },
    [LOG_CHANGE_TYPE_TRANSPORT_POINT_EVENT_DRIVER]: {
        name: 'fields.driver',
    },
    [LOG_CHANGE_TYPE_TRANSPORT_POINT_EVENT_DRIVER_2]: {
        name: 'fields.driver2',
    },
    [LOG_CHANGE_TYPE_TRANSPORT_POINT_EVENT_GPS_LAT]: {
        name: 'fields.gpsLat',
    },
    [LOG_CHANGE_TYPE_TRANSPORT_POINT_EVENT_GPS_LNG]: {
        name: 'fields.gpsLng',
    },
    [LOG_CHANGE_TYPE_TRANSPORT_POINT_EVENT_KM]: {
        name: 'fields.mileage',
        formatter: formatters.distanceFormatter,
    },
    [LOG_CHANGE_TYPE_TRANSPORT_POINT_EVENT_UNLOADED_WITH_OBJECTION]: {
        name: 'fields.withObjection',
        formatter: formatters.boolFormatter,
    },
    [LOG_CHANGE_TYPE_TRANSPORT_POINT_EVENT_WAITING_REASON]: {
        name: 'fields.reason',
    },
    [LOG_CHANGE_TYPE_TRANSPORT_POINT_EVENT_PCS]: {
        name: 'fields.pcs',
        formatter: formatters.integerFormatter,
    },
    [LOG_CHANGE_TYPE_TRANSPORT_POINT_EVENT_WEIGHT]: {
        name: 'fields.weight',
        formatter: formatters.weightFormatter,
    },
    [LOG_CHANGE_TYPE_TRANSPORT_POINT_EVENT_PALLET_EXCHANGE]: {
        name: 'fields.palletExchangeCount',
        formatter: formatters.boolFormatter,
    },
    [LOG_CHANGE_TYPE_TRANSPORT_POINT_EVENT_PALLET_EXCHANGE_ISSUED]: {
        name: 'fields.palletExchangeIssued',
        formatter: formatters.integerFormatter,
    },
    [LOG_CHANGE_TYPE_TRANSPORT_POINT_EVENT_PALLET_EXCHANGE_ACCEPTED]: {
        name: 'fields.palletExchangeReceived',
        formatter: formatters.integerFormatter,
    },
    [LOG_CHANGE_TYPE_TRANSPORT_POINT_EVENT_FUELED]: {
        name: 'fields.fueled',
        formatter: formatters.boolFormatter,
    },
    [LOG_CHANGE_TYPE_TRANSPORT_POINT_EVENT_DEFECT]: {
        name: 'fields.defect',
        formatter: formatters.boolFormatter,
    },
    [LOG_CHANGE_TYPE_TRANSPORT_POINT_EVENT_DEFECT_TEXT]: {
        name: 'fields.defectDescription',
    },
    [LOG_CHANGE_TYPE_TRANSPORT_POINT_EVENT_TIME_PLANNED_END]: {
        name: 'fields.plannedEndTime',
        formatter: formatters.datetimeFormatter,
    },
    [LOG_CHANGE_TYPE_TRANSPORT_POINT_EVENT_VEHICLE_DEFECT]: {
        name: 'fields.vehicleDefect',
        formatter: formatters.boolFormatter,
    },
    [LOG_CHANGE_TYPE_TRANSPORT_POINT_EVENT_VEHICLE_DEFECT_TEXT]: {
        name: 'fields.vehicleDefectDescription',
    },
    [LOG_CHANGE_TYPE_TRANSPORT_POINT_EVENT_TRAILER_DEFECT]: {
        name: 'fields.trailerDefect',
        formatter: formatters.boolFormatter,
    },
    [LOG_CHANGE_TYPE_TRANSPORT_POINT_EVENT_TRAILER_DEFECT_TEXT]: {
        name: 'fields.trailerDefectDescription',
    },
    [LOG_CHANGE_TYPE_TRANSPORT_POINT_EVENT_DOCUMENTS_DEFECT]: {
        name: 'fields.documentsDefect',
        formatter: formatters.boolFormatter,
    },
    [LOG_CHANGE_TYPE_TRANSPORT_POINT_EVENT_DOCUMENTS_DEFECT_TEXT]: {
        name: 'fields.documentsDefectDescription',
    },
    [LOG_CHANGE_TYPE_TRANSPORT_POINT_EVENT_NOTE]: {
        name: 'fields.note',
    },
    [LOG_CHANGE_TYPE_TRANSPORT_POINT_EVENT_REGISTRATION]: {
        name: 'fields.registration',
    },
}
