import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import ReactPaginate from 'react-paginate'
import Notifications from 'react-notification-system-redux'

import { getListItemsCountMessage } from '../../../common/helpers'
import * as formatters from '../../../common/formatters'
import * as reportVehicleChecksActionCreators from '../actionCreators'
import { prepareTableActionCreators } from '../../../common/table/actionCreators'
import { getData } from '../selectors'
import { createTableDataSelector, createTableModelSelector } from '../../../common/table/selectors'
import { TableModelColumn, TableModelColumnFilteringType } from '../../../common/table/model'
import { TableFilterInputField, TableFilterBooleanField, TableFilterSelectField } from '../../../common/table/components'
import { getActiveFilters, getTotalNumberOfPages } from '../../../common/table/helpers'
import { createGetVehicleByIDSelector } from '../../vehicles/selectors'
import { createGetDriverByIDSelector } from '../../drivers/selectors'
import { createGetTrailerByIDSelector } from '../../trailers/selectors'
import { createGetCountryByIDSelector } from '../../countries/selectors'

import PermissionsCheck from '../../auth/components/PermissionsCheck'
import { PERMS } from '../permissions'

import * as constants from '../../transports/constants'

import ReportVehicleChecksRow from './ReportVehicleChecksRow'

const tableIdentifier = 'report_vehicle_checks_list'
const clientSideItemsPerPage = undefined

const columns = {
    datetime: new TableModelColumn({}),
    transport_id: new TableModelColumn({}),
}

class ReportVehicleChecks extends Component {
    refresh = () => {
        this.props.actions.fetchReportVehicleChecks()
    }

    resetFilters = () => {
        this.props.table.resetFilters()
    }

    isFilterActive = () => getActiveFilters(this.props.tableModel).size > 0

    componentDidMount() {
        this.props.actions.fetchReportVehicleChecks()
    }

    render() {
        document.title = formatters.titleFormatter(this.props.intl.formatMessage({ id: 'modules.reportVehicleChecks.heading' }))

        const filters = this.props.tableModel.get('filters')

        const types = [
            constants.TRANSPORT_EVENT_TYPE_PREDEPARTURE_CHECK,
            constants.TRANSPORT_EVENT_TYPE_LOADING_SYSTEM_CHECK,
            constants.TRANSPORT_EVENT_TYPE_PARKING_CHECK_BEFORE,
            constants.TRANSPORT_EVENT_TYPE_PARKING_CHECK_AFTER,
            constants.TRANSPORT_EVENT_TYPE_TRAILER_CHANGE_CHECK,
            constants.TRANSPORT_EVENT_TYPE_TRAILER_CATCH_CHECK,
            constants.TRANSPORT_EVENT_TYPE_TRAILER_OUT_CHECK,
            constants.TRANSPORT_EVENT_TYPE_PARKING_AND_CHANGE_QUIT_CHECK,
            constants.TRANSPORT_EVENT_TYPE_PARKING_AND_CHANGE_CHECK,
            constants.TRANSPORT_EVENT_TYPE_VEHICLE_QUIT_CHECK,
        ]
        const typesPairs = constants.TRANSPORT_EVENT_TYPES_DEFINITION
        const typesValues = types.map(type => ({
            id: type,
            name: this.props.intl.formatMessage({ id: typesPairs[type].name }),
        }))

        return (
            <PermissionsCheck hasAny={Object.values(PERMS)} noPermissionsPage>
                <div className="page-inner">
                    <div id="main-wrapper">
                        <div className="panel panel-white">
                            <div className="panel-body">
                                <h4>
                                    <FormattedMessage id="modules.reportVehicleChecks.heading" />
                                </h4>

                                <div className="btns-list">
                                    {this.isFilterActive() > 0 && (
                                        <button className="btn btn-default btn-addon m-r-xs" onClick={this.resetFilters}>
                                            <i className="far fa-times" /> <FormattedMessage id="buttons.resetFilters" />
                                        </button>
                                    )}
                                    <button className="btn btn-default btn-addon" onClick={this.refresh}>
                                        <i className="far fa-sync-alt" /> <FormattedMessage id="buttons.refresh" />
                                    </button>
                                </div>

                                <table className="table table-striped table-hover table-fixed-header">
                                    <thead>
                                        <tr>
                                            <th className="w-120 text-center">
                                                <FormattedMessage id="fields.dateTime" />
                                            </th>
                                            <th className="w-80 text-center">
                                                <FormattedMessage id="fields.transport" />
                                            </th>
                                            <th className="w-100 text-center">
                                                <FormattedMessage id="fields.vehicle" />
                                            </th>
                                            <th className="w-100 text-center">
                                                <FormattedMessage id="fields.trailer" />
                                            </th>
                                            <th className="w-120">
                                                <FormattedMessage id="fields.driver" />
                                            </th>
                                            <th className="w-220">
                                                <FormattedMessage id="fields.address" />
                                            </th>
                                            <th className="w-220">
                                                <FormattedMessage id="fields.checkType" />
                                            </th>
                                            <th className="w-80 text-center">
                                                <FormattedMessage id="fields.defects" />
                                            </th>
                                            <th className="w-160">
                                                <FormattedMessage id="fields.defectType" />
                                            </th>
                                            <th className="w-max wm-200">
                                                <FormattedMessage id="fields.notes" />
                                            </th>
                                            <th className="w-160 text-center">
                                                <FormattedMessage id="fields.gps" />
                                            </th>
                                            <th className="w-100 text-right">
                                                <FormattedMessage id="fields.attachments" />
                                            </th>
                                        </tr>
                                        <tr className="filters">
                                            <th className="w-120" />
                                            <th className="w-80">
                                                <TableFilterInputField
                                                    identifier="transport_id"
                                                    type={TableModelColumnFilteringType.string}
                                                    filters={filters}
                                                    onChange={this.props.table.changeFilter}
                                                />
                                            </th>
                                            <th className="w-100">
                                                <TableFilterInputField
                                                    identifier="vehicle"
                                                    type={TableModelColumnFilteringType.string}
                                                    filters={filters}
                                                    onChange={this.props.table.changeFilter}
                                                />
                                            </th>
                                            <th className="w-100">
                                                <TableFilterInputField
                                                    identifier="trailer"
                                                    type={TableModelColumnFilteringType.string}
                                                    filters={filters}
                                                    onChange={this.props.table.changeFilter}
                                                />
                                            </th>
                                            <th className="w-120">
                                                <TableFilterInputField
                                                    identifier="driver"
                                                    type={TableModelColumnFilteringType.string}
                                                    filters={filters}
                                                    onChange={this.props.table.changeFilter}
                                                />
                                            </th>
                                            <th className="w-220">
                                                <TableFilterInputField
                                                    identifier="address"
                                                    type={TableModelColumnFilteringType.string}
                                                    filters={filters}
                                                    onChange={this.props.table.changeFilter}
                                                />
                                            </th>
                                            <th className="w-220">
                                                <TableFilterSelectField
                                                    identifier="type_id"
                                                    type={TableModelColumnFilteringType.equal}
                                                    filters={filters}
                                                    onChange={this.props.table.changeFilter}
                                                    values={typesValues}
                                                />
                                            </th>
                                            <th className="w-80">
                                                <TableFilterBooleanField
                                                    identifier="defect"
                                                    type={TableModelColumnFilteringType.equal}
                                                    filters={filters}
                                                    onChange={this.props.table.changeFilter}
                                                />
                                            </th>
                                            <th className="w-160" />
                                            <th className="w-max wm-200" />
                                            <th className="w-160" />
                                            <th className="w-60" />
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.props.vehicleChecks.data
                                            .valueSeq()
                                            .map(row => (
                                                <ReportVehicleChecksRow
                                                    key={`ReportVehicleChecksRow-${row.id}`}
                                                    data={row}
                                                    vehicle={this.props.createGetVehicleByIDSelector(row.vehicle_id)}
                                                    driver={this.props.createGetDriverByIDSelector(row.driver_id)}
                                                    trailer={
                                                        row.transport_point && this.props.createGetTrailerByIDSelector(row.transport_point.trailer_id)
                                                    }
                                                    country={
                                                        row.transport_point && this.props.createGetCountryByIDSelector(row.transport_point.country_id)
                                                    }
                                                    typesPairs={typesPairs}
                                                />
                                            ))}
                                    </tbody>
                                </table>

                                <div className="pull-left m-l-xxs m-t-md">
                                    <FormattedMessage id="pagination.totalRecords" />:{' '}
                                    {getListItemsCountMessage(
                                        clientSideItemsPerPage,
                                        this.props.vehicleChecks.count,
                                        this.props.tableModel.getIn(['pagination', 'totalCount'])
                                    )}
                                </div>

                                <ReactPaginate
                                    containerClassName="pagination"
                                    breakLabel={<span className="disabled">...</span>}
                                    activeClassName="active"
                                    pageCount={getTotalNumberOfPages(this.props.tableModel, this.props.vehicleChecks.count, clientSideItemsPerPage)}
                                    pageRangeDisplayed={10}
                                    marginPagesDisplayed={2}
                                    forcePage={this.props.tableModel.getIn(['pagination', 'current'])}
                                    onPageChange={this.props.table.changePage}
                                    previousLabel={this.props.intl.formatMessage({ id: 'pagination.previous' })}
                                    nextLabel={this.props.intl.formatMessage({ id: 'pagination.next' })}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </PermissionsCheck>
        )
    }
}

function mapStateToProps(state) {
    return {
        vehicleChecks: createTableDataSelector({
            tableIdentifier,
            columns,
            dataSelector: getData,
            clientSideItemsPerPage,
        })(state),
        tableModel: createTableModelSelector(tableIdentifier)(state),
        createGetVehicleByIDSelector: id => createGetVehicleByIDSelector(id)(state),
        createGetDriverByIDSelector: id => createGetDriverByIDSelector(id)(state),
        createGetTrailerByIDSelector: id => createGetTrailerByIDSelector(id)(state),
        createGetCountryByIDSelector: id => createGetCountryByIDSelector(id)(state),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            ...bindActionCreators(
                {
                    ...reportVehicleChecksActionCreators,
                },
                dispatch
            ),
        },
        notify: (notification, type) => dispatch(Notifications.show(notification, type)),
        dispatch,
    }
}

function mergeProps(stateProps, dispatchProps, ownProps) {
    return {
        ...stateProps,
        ...dispatchProps,
        ...ownProps,
        table: bindActionCreators(prepareTableActionCreators(tableIdentifier, stateProps.tableModel), dispatchProps.dispatch),
    }
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps, mergeProps)(ReportVehicleChecks))
