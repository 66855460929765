// Fetch list

export const FETCH = 'companies.FETCH'
export const FETCH_STARTED = 'companies.FETCH_STARTED'
export const FETCH_FULFILLED = 'companies.FETCH_FULFILLED'
export const FETCH_REJECTED = 'companies.FETCH_REJECTED'
export const FETCH_CANCELLED = 'companies.FETCH_CANCELLED'

export const SORTING_CHANGED = 'companies.SORTING_CHANGED'

// Fetch one

export const FETCH_ONE = 'companies.FETCH_ONE'
export const FETCH_ONE_STARTED = 'companies.FETCH_ONE_STARTED'
export const FETCH_ONE_FULFILLED = 'companies.FETCH_ONE_FULFILLED'
export const FETCH_ONE_REJECTED = 'companies.FETCH_ONE_REJECTED'
export const FETCH_ONE_CANCELLED = 'companies.FETCH_ONE_CANCELLED'

// Save

export const SAVE = 'companies.SAVE'
export const SAVE_STARTED = 'companies.SAVE_STARTED'
export const SAVE_FULFILLED = 'companies.SAVE_FULFILLED'
export const SAVE_REJECTED = 'companies.SAVE_REJECTED'
export const SAVE_CANCELLED = 'companies.SAVE_CANCELLED'

// Delete

export const DELETE = 'companies.DELETE'
export const DELETE_STARTED = 'companies.DELETE_STARTED'
export const DELETE_FULFILLED = 'companies.DELETE_FULFILLED'
export const DELETE_REJECTED = 'companies.DELETE_REJECTED'
export const DELETE_CANCELLED = 'companies.DELETE_CANCELLED'

// Export

export const EXPORT = 'companies.EXPORT'
export const EXPORT_FULFILLED = 'companies.EXPORT_FULFILLED'

// Fetch company cost centers

export const FETCH_COST_CENTERS = 'companies.FETCH_COST_CENTERS'
export const FETCH_COST_CENTERS_STARTED = 'companies.FETCH_COST_CENTERS_STARTED'
export const FETCH_COST_CENTERS_FULFILLED = 'companies.FETCH_COST_CENTERS_FULFILLED'
export const FETCH_COST_CENTERS_REJECTED = 'companies.FETCH_COST_CENTERS_REJECTED'
export const FETCH_COST_CENTERS_CANCELLED = 'companies.FETCH_COST_CENTERS_CANCELLED'

// Export company cost centers

export const EXPORT_COST_CENTERS = 'companies.EXPORT_COST_CENTERS'
export const EXPORT_COST_CENTERS_STARTED = 'companies.EXPORT_COST_CENTERS_STARTED'
export const EXPORT_COST_CENTERS_FULFILLED = 'companies.EXPORT_COST_CENTERS_FULFILLED'
export const EXPORT_COST_CENTERS_REJECTED = 'companies.EXPORT_COST_CENTERS_REJECTED'
export const EXPORT_COST_CENTERS_CANCELLED = 'companies.EXPORT_COST_CENTERS_CANCELLED'

// Fetch company bank accounts

export const FETCH_BANK_ACCOUNTS = 'companies.FETCH_BANK_ACCOUNTS'
export const FETCH_BANK_ACCOUNTS_STARTED = 'companies.FETCH_BANK_ACCOUNTS_STARTED'
export const FETCH_BANK_ACCOUNTS_FULFILLED = 'companies.FETCH_BANK_ACCOUNTS_FULFILLED'
export const FETCH_BANK_ACCOUNTS_REJECTED = 'companies.FETCH_BANK_ACCOUNTS_REJECTED'
export const FETCH_BANK_ACCOUNTS_CANCELLED = 'companies.FETCH_BANK_ACCOUNTS_CANCELLED'

// Export company bank accounts

export const EXPORT_BANK_ACCOUNTS = 'companies.EXPORT_BANK_ACCOUNTS'
export const EXPORT_BANK_ACCOUNTS_STARTED = 'companies.EXPORT_BANK_ACCOUNTS_STARTED'
export const EXPORT_BANK_ACCOUNTS_FULFILLED = 'companies.EXPORT_BANK_ACCOUNTS_FULFILLED'
export const EXPORT_BANK_ACCOUNTS_REJECTED = 'companies.EXPORT_BANK_ACCOUNTS_REJECTED'
export const EXPORT_BANK_ACCOUNTS_CANCELLED = 'companies.EXPORT_BANK_ACCOUNTS_CANCELLED'

// Save default bank account

export const SAVE_DEFAULT_BANK_ACCOUNT = 'companies.SAVE_DEFAULT_BANK_ACCOUNT'
export const SAVE_DEFAULT_BANK_ACCOUNT_STARTED = 'companies.SAVE_DEFAULT_BANK_ACCOUNT_STARTED'
export const SAVE_DEFAULT_BANK_ACCOUNT_FULFILLED = 'companies.SAVE_DEFAULT_BANK_ACCOUNT_FULFILLED'
export const SAVE_DEFAULT_BANK_ACCOUNT_REJECTED = 'companies.SAVE_DEFAULT_BANK_ACCOUNT_REJECTED'
export const SAVE_DEFAULT_BANK_ACCOUNT_CANCELLED = 'companies.SAVE_DEFAULT_BANK_ACCOUNT_CANCELLED'

// Fetch company numberings

export const FETCH_NUMBERINGS = 'companies.FETCH_NUMBERINGS'
export const FETCH_NUMBERINGS_STARTED = 'companies.FETCH_NUMBERINGS_STARTED'
export const FETCH_NUMBERINGS_FULFILLED = 'companies.FETCH_NUMBERINGS_FULFILLED'
export const FETCH_NUMBERINGS_REJECTED = 'companies.FETCH_NUMBERINGS_REJECTED'
export const FETCH_NUMBERINGS_CANCELLED = 'companies.FETCH_NUMBERINGS_CANCELLED'

// Export company numberings

export const EXPORT_NUMBERINGS = 'companies.EXPORT_NUMBERINGS'
export const EXPORT_NUMBERINGS_STARTED = 'companies.EXPORT_NUMBERINGS_STARTED'
export const EXPORT_NUMBERINGS_FULFILLED = 'companies.EXPORT_NUMBERINGS_FULFILLED'
export const EXPORT_NUMBERINGS_REJECTED = 'companies.EXPORT_NUMBERINGS_REJECTED'
export const EXPORT_NUMBERINGS_CANCELLED = 'companies.EXPORT_NUMBERINGS_CANCELLED'
