// Fetch list

export const FETCH = 'users.FETCH'
export const FETCH_STARTED = 'users.FETCH_STARTED'
export const FETCH_FULFILLED = 'users.FETCH_FULFILLED'
export const FETCH_REJECTED = 'users.FETCH_REJECTED'
export const FETCH_CANCELLED = 'users.FETCH_CANCELLED'

export const SORTING_CHANGED = 'users.SORTING_CHANGED'

// Fetch one

export const FETCH_ONE = 'users.FETCH_ONE'
export const FETCH_ONE_STARTED = 'users.FETCH_ONE_STARTED'
export const FETCH_ONE_FULFILLED = 'users.FETCH_ONE_FULFILLED'
export const FETCH_ONE_REJECTED = 'users.FETCH_ONE_REJECTED'
export const FETCH_ONE_CANCELLED = 'users.FETCH_ONE_CANCELLED'

// Save

export const SAVE = 'users.SAVE'
export const SAVE_STARTED = 'users.SAVE_STARTED'
export const SAVE_FULFILLED = 'users.SAVE_FULFILLED'
export const SAVE_REJECTED = 'users.SAVE_REJECTED'
export const SAVE_CANCELLED = 'users.SAVE_CANCELLED'

// Delete

export const DELETE = 'users.DELETE'
export const DELETE_STARTED = 'users.DELETE_STARTED'
export const DELETE_FULFILLED = 'users.DELETE_FULFILLED'
export const DELETE_REJECTED = 'users.DELETE_REJECTED'
export const DELETE_CANCELLED = 'users.DELETE_CANCELLED'

// Export

export const EXPORT = 'users.EXPORT'
export const EXPORT_FULFILLED = 'users.EXPORT_FULFILLED'

// Fetch user login logs

export const FETCH_LOGIN_LOG = 'users.FETCH_LOGIN_LOG'
export const FETCH_LOGIN_LOG_STARTED = 'users.FETCH_LOGIN_LOG_STARTED'
export const FETCH_LOGIN_LOG_FULFILLED = 'users.FETCH_LOGIN_LOG_FULFILLED'
export const FETCH_LOGIN_LOG_REJECTED = 'users.FETCH_LOGIN_LOG_REJECTED'
export const FETCH_LOGIN_LOG_CANCELLED = 'users.FETCH_LOGIN_LOG_CANCELLED'

// Export user login logs

export const EXPORT_LOGIN_LOG = 'users.EXPORT_LOGIN_LOG'
export const EXPORT_LOGIN_LOG_STARTED = 'users.EXPORT_LOGIN_LOG_STARTED'
export const EXPORT_LOGIN_LOG_FULFILLED = 'users.EXPORT_LOGIN_LOG_FULFILLED'
export const EXPORT_LOGIN_LOG_REJECTED = 'users.EXPORT_LOGIN_LOG_REJECTED'
export const EXPORT_LOGIN_LOG_CANCELLED = 'users.EXPORT_LOGIN_LOG_CANCELLED'
