import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { push } from 'react-router-redux'
import ReactPaginate from 'react-paginate'
import Notifications from 'react-notification-system-redux'
import { Modal } from 'react-bootstrap'
import moment from 'moment'
import DatePicker from 'react-datepicker'

import { titleFormatter, dateFormatter } from '../../../common/formatters'
import {
    url,
    handleCommonListActions,
    getListItemsCountMessage,
    getPrerequisitesArray,
    convertCSDateToISO,
    convertCSDateToMoment,
} from '../../../common/helpers'

import { prepareTableActionCreators } from '../../../common/table/actionCreators'
import { TableFilterInputField, TableFilterSelectField } from '../../../common/table/components'
import { getActiveFilters, getTotalNumberOfPages } from '../../../common/table/helpers'
import { TableModelColumn, TableModelColumnFilteringType, TableModelSortDirection } from '../../../common/table/model'
import { createTableDataSelector, createTableModelSelector } from '../../../common/table/selectors'

import { preparePrerequisitesActionCreators } from '../../../common/prerequisites/actionCreators'
import { preparePrerequisitesFetchStatusSelectors } from '../../../common/prerequisites/selectors'

import * as constants from '../../transports/constants'
import * as transportsActionCreators from '../../transports/actionCreators'
import {
    getTransports,
    getTransportDeleting,
    getTransportDeleteError,
    getTransportCreatingFromTemplate,
    getTransportCreateFromTemplateError,
} from '../../transports/selectors'
import { createGetCargoTypeByIDSelector } from '../../cargo_types/selectors'
import { createGetCountryByIDSelector } from '../../countries/selectors'
import { createGetCurrencyByIDSelector } from '../../currencies/selectors'
import { createGetCustomerByIDSelector } from '../../customers/selectors'
import { createGetGoodsTypeByIDSelector } from '../../goods_types/selectors'
import { createGetTransportTypeByIDSelector } from '../../transport_types/selectors'

import TransportTemplatesListRow from './TransportTemplatesListRow'

import PermissionsCheck from '../../auth/components/PermissionsCheck'
import PermissionsProps from '../../auth/components/PermissionsProps'
import { PERMS, PERMS_CUSTOMER, PERMS_COST } from '../permissions'
import { PERMS as PERMS_TRANSPORT } from '../../transports/permissions'

const tableIdentifier = 'transports_list'
const clientSideItemsPerPage = undefined

const componentIdentifier = 'transports_list'
const prerequisites = ['transport_types', 'cargo_types', 'goods_types']

const columns = {
    id: new TableModelColumn({}),
    created_at: new TableModelColumn({}),
    transport_state: new TableModelColumn({}),
    transport_type: new TableModelColumn({}),
}

class TransportsList extends Component {
    defaultDay = {
        loadingDate: moment().format('DD.MM.YYYY'),
        unloadingDate: moment().format('DD.MM.YYYY'),
        count: 1,
        code: '',
    }

    state = {
        showCreateTransportModal: false,
        transportToCreate: null,
        transportToCreateDays: {
            1: this.defaultDay,
        },
    }

    // handlers
    onClick = itemId => {
        this.props.redirect(`/transport-templates/${itemId}`)
    }

    closeCreateTransportModal = () => {
        this.setState({ showCreateTransportModal: false })
    }

    openCreateTransportModal = () => {
        this.setState({ showCreateTransportModal: true })
    }

    setTransportToCreate = transport => {
        const loadingDatetimeFrom =
            transport.transport_point_loading && transport.transport_point_loading.datetime_planned_from
                ? moment(transport.transport_point_loading.datetime_planned_from)
                : null

        const unloadingDatetimeFrom =
            transport.transport_point_unloading && transport.transport_point_unloading.datetime_planned_from
                ? moment(transport.transport_point_unloading.datetime_planned_from)
                : null

        const loadingDate = moment().format('DD.MM.YYYY')

        const unloadingDate = moment()
            .add(loadingDatetimeFrom && unloadingDatetimeFrom ? unloadingDatetimeFrom.diff(loadingDatetimeFrom, 'days') : 0, 'days')
            .format('DD.MM.YYYY')

        const loadingCode =
            transport.transport_point_loading && transport.transport_point_loading.getDataItem(constants.TRANSPORT_POINT_DATA_TYPE_CODE)

        this.defaultDay.loadingDate = loadingDate
        this.defaultDay.unloadingDate = unloadingDate
        this.defaultDay.code = loadingCode && loadingCode.value

        if (!this.state.transportToCreate || this.state.transportToCreate.id !== transport.id) {
            this.setState({
                transportToCreateDays: {
                    1: this.defaultDay,
                },
            })
        }

        this.setState({ transportToCreate: transport })
    }

    handleCreateTransport = transport => {
        this.setTransportToCreate(transport)
        this.openCreateTransportModal()
    }

    handleCreateTransportDatetimeChange = (key, name, time) => {
        const newState = {
            transportToCreateDays: {
                ...this.state.transportToCreateDays,
                [key]: {
                    ...this.state.transportToCreateDays[key],
                    [name]: time,
                },
            },
        }

        if (name === 'loadingDate') {
            const momentLoadingDateNew = convertCSDateToMoment(time)

            if (momentLoadingDateNew) {
                const momentLoadingDate = convertCSDateToMoment(this.state.transportToCreateDays[key].loadingDate)
                const momentUnloadingDate = convertCSDateToMoment(this.state.transportToCreateDays[key].unloadingDate)
                const diff = momentLoadingDate && momentUnloadingDate ? momentUnloadingDate.diff(momentLoadingDate, 'days') : 1

                newState.transportToCreateDays[key].unloadingDate = dateFormatter(moment(momentLoadingDateNew).add(diff, 'days'))
            } else {
                newState.transportToCreateDays[key].datetimeTo = ''
            }
        }

        this.setState(newState)
    }

    handleCreateTransportCountChange = (key, count) => {
        this.setState({
            transportToCreateDays: {
                ...this.state.transportToCreateDays,
                [key]: {
                    ...this.state.transportToCreateDays[key],
                    count,
                },
            },
        })
    }

    handleCreateTransportCodeChange = (key, code) => {
        this.setState({
            transportToCreateDays: {
                ...this.state.transportToCreateDays,
                [key]: {
                    ...this.state.transportToCreateDays[key],
                    code,
                },
            },
        })
    }

    handleCreateTransportAddRow = () => {
        let maxKey = 0
        Object.keys(this.state.transportToCreateDays).forEach(key => {
            maxKey = Math.max(key, maxKey)
        })

        this.setState({
            transportToCreateDays: {
                ...this.state.transportToCreateDays,
                [maxKey + 1]: this.defaultDay,
            },
        })
    }

    handleCreateTransportRemoveRow = (e, key) => {
        e && e.preventDefault()

        const newState = {
            ...this.state,
        }

        delete newState.transportToCreateDays[key]

        this.setState(newState)
    }

    handleCreateTransportSubmit = () => {
        const days = Object.keys(this.state.transportToCreateDays).map(key => {
            const day = this.state.transportToCreateDays[key]

            return {
                loading_date: convertCSDateToISO(day.loadingDate),
                unloading_date: convertCSDateToISO(day.unloadingDate),
                count: day.count,
                code: day.code,
            }
        })

        this.props.actions.createFromTemplate(this.state.transportToCreate.id, days)
    }

    handleExport = e => {
        e && e.preventDefault()
        this.props.actions.exportTransports(this.props.tableModel.get('filters').toJS(), this.props.tableModel.get('sorting').toJS())
    }

    refresh = () => {
        this.props.actions.fetchTransports()
    }

    resetFilters = () => {
        this.props.table.resetFilters(
            [],
            [
                {
                    column: 'is_template',
                    type: TableModelColumnFilteringType.equal,
                    value: 1,
                },
            ]
        )
    }

    isFilterActive = () => getActiveFilters(this.props.tableModel, ['is_template']).size > 0

    componentWillMount() {
        this.props.actions.clearTransports()
    }

    componentDidMount() {
        this.props.table.setConfiguration(
            [
                {
                    column: 'is_template',
                    type: TableModelColumnFilteringType.equal,
                    value: 1,
                },
            ],
            'template_name',
            TableModelSortDirection.ascending
        )

        this.props.actions.fetchPrerequisites()
    }

    componentWillReceiveProps(nextProps) {
        handleCommonListActions(this.props, nextProps)

        // Creation from templates
        if (this.props.creatingFromTemplate && !nextProps.creatingFromTemplate) {
            if (nextProps.createFromTemplateError === null) {
                this.props.notify(
                    {
                        title: this.props.intl.formatMessage({ id: 'alerts.titles.success' }),
                        message: this.props.intl.formatMessage({ id: 'alerts.messages.createFromTemplateSuccess' }),
                        position: 'tc',
                    },
                    'success'
                )
                this.props.redirect(url(this.props.match, 'transports'))
            } else {
                this.props.notify(
                    {
                        title: this.props.intl.formatMessage({ id: 'alerts.titles.error' }),
                        message: nextProps.createFromTemplateError,
                        position: 'tc',
                    },
                    'error'
                )
            }
        }
    }

    /* eslint-disable jsx-a11y/anchor-is-valid */
    render() {
        document.title = titleFormatter(this.props.intl.formatMessage({ id: 'modules.transportTemplates.heading' }))

        const filters = this.props.tableModel.get('filters')
        const data = this.props.transports.data.sortBy(item => {
            if (!item.template_name) {
                return 0
            }
            return item.template_name
        })

        return (
            <PermissionsCheck hasAny={Object.values(PERMS)} noPermissionsPage>
                <div className="page-inner">
                    <div id="main-wrapper">
                        <div className="panel panel-white">
                            <div className="panel-body">
                                <h4>
                                    <FormattedMessage id="modules.transportTemplates.heading" />
                                </h4>

                                <div className="btns-list">
                                    {this.isFilterActive() > 0 && (
                                        <button className="btn btn-default btn-addon m-r-xs" onClick={this.resetFilters}>
                                            <i className="far fa-times" /> <FormattedMessage id="buttons.resetFilters" />
                                        </button>
                                    )}
                                    <button className="btn btn-default btn-addon m-r-xs" onClick={this.refresh}>
                                        <i className="far fa-sync-alt" /> <FormattedMessage id="buttons.refresh" />
                                    </button>
                                    <PermissionsCheck has={[PERMS.EXPORT]}>
                                        <button className="btn btn-primary btn-addon m-r-xs" onClick={this.handleExport}>
                                            <i className="far fa-file-excel" /> <FormattedMessage id="buttons.exportToXLS" />
                                        </button>
                                    </PermissionsCheck>
                                    <PermissionsCheck has={[PERMS.CREATE]}>
                                        <Link to={url(this.props.match, `transport-templates/new`)} className="btn btn-addon btn-success">
                                            <i className="far fa-plus" /> <FormattedMessage id="buttons.createItem" />
                                        </Link>
                                    </PermissionsCheck>
                                </div>

                                <div className="table-container">
                                    <table className="table table-striped table-hover table-fixed-header table-transports">
                                        <thead>
                                            <tr>
                                                <PermissionsCheck hasAny={[PERMS.UPDATE, PERMS_TRANSPORT.CREATE]}>
                                                    <th className="w-60" />
                                                </PermissionsCheck>
                                                <PermissionsProps hasAny={Object.values(PERMS_CUSTOMER)} props={{ className: 'w-500' }}>
                                                    <th className="w-max wm-500">
                                                        <FormattedMessage id="fields.templateName" />
                                                    </th>
                                                </PermissionsProps>
                                                <PermissionsCheck hasAny={Object.values(PERMS_CUSTOMER)}>
                                                    <th className="w-max wm-200">
                                                        <FormattedMessage id="fields.customer" />
                                                    </th>
                                                </PermissionsCheck>
                                                <th className="w-220">
                                                    <FormattedMessage id="fields.transportType" />
                                                </th>
                                                <th className="w-200">
                                                    <FormattedMessage id="fields.cargoType" />
                                                </th>
                                                <th className="w-120">
                                                    <FormattedMessage id="fields.goodsType" />
                                                </th>
                                                <th className="w-240">
                                                    <FormattedMessage id="fields.loading" />
                                                </th>
                                                <th className="w-240">
                                                    <FormattedMessage id="fields.unloading" />
                                                </th>
                                                <PermissionsCheck hasAny={Object.values(PERMS_COST)}>
                                                    <th className="w-100 text-right">
                                                        <FormattedMessage id="fields.price" />
                                                    </th>
                                                </PermissionsCheck>
                                            </tr>
                                            <tr className="filters">
                                                <PermissionsCheck hasAny={[PERMS.UPDATE, PERMS_TRANSPORT.CREATE]}>
                                                    <th className="w-60" />
                                                </PermissionsCheck>
                                                <PermissionsProps hasAny={Object.values(PERMS_CUSTOMER)} props={{ className: 'w-500' }}>
                                                    <th className="w-max wm-500">
                                                        <TableFilterInputField
                                                            identifier="template_name"
                                                            type={TableModelColumnFilteringType.string}
                                                            filters={filters}
                                                            onChange={this.props.table.changeFilter}
                                                        />
                                                    </th>
                                                </PermissionsProps>
                                                <PermissionsCheck hasAny={Object.values(PERMS_CUSTOMER)}>
                                                    <th className="w-max wm-300">
                                                        <TableFilterInputField
                                                            identifier="customer"
                                                            type={TableModelColumnFilteringType.string}
                                                            filters={filters}
                                                            onChange={this.props.table.changeFilter}
                                                        />
                                                    </th>
                                                </PermissionsCheck>
                                                <th className="w-220">
                                                    <TableFilterSelectField
                                                        identifier="transport_type_id"
                                                        type={TableModelColumnFilteringType.equal}
                                                        filters={filters}
                                                        onChange={this.props.table.changeFilter}
                                                        values={getPrerequisitesArray(this.props.prerequisites.values.get('transport_types'))}
                                                    />
                                                </th>
                                                <th className="w-200">
                                                    <TableFilterSelectField
                                                        identifier="cargo_type_id"
                                                        type={TableModelColumnFilteringType.equal}
                                                        filters={filters}
                                                        onChange={this.props.table.changeFilter}
                                                        values={getPrerequisitesArray(this.props.prerequisites.values.get('cargo_types'))}
                                                    />
                                                </th>
                                                <th className="w-120">
                                                    <TableFilterSelectField
                                                        identifier="goods_type_id"
                                                        type={TableModelColumnFilteringType.equal}
                                                        filters={filters}
                                                        onChange={this.props.table.changeFilter}
                                                        values={getPrerequisitesArray(this.props.prerequisites.values.get('goods_types'))}
                                                    />
                                                </th>
                                                <th className="w-240">
                                                    <TableFilterInputField
                                                        identifier="loading_address"
                                                        type={TableModelColumnFilteringType.string}
                                                        filters={filters}
                                                        onChange={this.props.table.changeFilter}
                                                    />
                                                </th>
                                                <th className="w-240">
                                                    <TableFilterInputField
                                                        identifier="unloading_address"
                                                        type={TableModelColumnFilteringType.string}
                                                        filters={filters}
                                                        onChange={this.props.table.changeFilter}
                                                    />
                                                </th>
                                                <PermissionsCheck hasAny={Object.values(PERMS_COST)}>
                                                    <th className="w-100 text-right" />
                                                </PermissionsCheck>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data.valueSeq().map(row => (
                                                <TransportTemplatesListRow
                                                    key={`TransportTemplatesListRow-${row.id}`}
                                                    onClick={this.onClick}
                                                    redirect={this.props.redirect}
                                                    transport={row}
                                                    transportType={this.props.createGetTransportTypeByIDSelector(row.transport_type_id)}
                                                    cargoType={this.props.createGetCargoTypeByIDSelector(row.cargo_type_id)}
                                                    goodsType={this.props.createGetGoodsTypeByIDSelector(row.goods_type_id)}
                                                    createGetCustomerByIDSelector={this.props.createGetCustomerByIDSelector}
                                                    createGetCurrencyByIDSelector={this.props.createGetCurrencyByIDSelector}
                                                    createGetCountryByIDSelector={this.props.createGetCountryByIDSelector}
                                                    handleCreateTransport={this.handleCreateTransport}
                                                    moduleId={this.moduleId}
                                                />
                                            ))}
                                        </tbody>
                                    </table>
                                </div>

                                <div className="pull-left m-l-xxs m-t-md">
                                    <FormattedMessage id="pagination.totalRecords" />:{' '}
                                    {getListItemsCountMessage(
                                        clientSideItemsPerPage,
                                        this.props.transports.count,
                                        this.props.tableModel.getIn(['pagination', 'totalCount'])
                                    )}
                                </div>

                                <ReactPaginate
                                    containerClassName="pagination"
                                    breakLabel={<span className="disabled">...</span>}
                                    activeClassName="active"
                                    pageCount={getTotalNumberOfPages(this.props.tableModel, this.props.transports.count, clientSideItemsPerPage)}
                                    pageRangeDisplayed={10}
                                    marginPagesDisplayed={2}
                                    forcePage={this.props.tableModel.getIn(['pagination', 'current'])}
                                    onPageChange={this.props.table.changePage}
                                    previousLabel={this.props.intl.formatMessage({ id: 'pagination.previous' })}
                                    nextLabel={this.props.intl.formatMessage({ id: 'pagination.next' })}
                                />
                            </div>
                        </div>
                    </div>

                    <Modal show={this.state.showCreateTransportModal} onHide={this.closeCreateTransportModal} className="modal-size-md">
                        <Modal.Header closeButton>
                            <Modal.Title>
                                <FormattedMessage id="modules.transportTemplates.createTransportFromTemplate" />:{' '}
                                {this.state.transportToCreate && this.state.transportToCreate.template_name}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {Object.keys(this.state.transportToCreateDays).map(key => {
                                const day = this.state.transportToCreateDays[key]

                                const momentLoadingDate = convertCSDateToMoment(day.loadingDate)
                                const momentUnloadingDate = convertCSDateToMoment(day.unloadingDate)

                                return (
                                    <div key={key} className="row">
                                        <div className="col-md-2">
                                            <div className="form-group">
                                                <label htmlFor="value_count">
                                                    <FormattedMessage id="fields.count" />:
                                                </label>
                                                <input
                                                    id="value_count"
                                                    type="text"
                                                    className="form-control"
                                                    value={day.count}
                                                    onChange={event => {
                                                        this.handleCreateTransportCountChange(key, event.target.value)
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label htmlFor="value_loading_date">
                                                    <FormattedMessage id="fields.loadingDate" />:
                                                </label>
                                                <DatePicker
                                                    id="value_loading_date"
                                                    dateFormat="DD.MM.YYYY"
                                                    autoComplete="off"
                                                    selected={momentLoadingDate}
                                                    value={day.loadingDate}
                                                    onChange={value => {
                                                        this.handleCreateTransportDatetimeChange(
                                                            key,
                                                            'loadingDate',
                                                            value ? dateFormatter(value) : ''
                                                        )
                                                    }}
                                                    onChangeRaw={e => {
                                                        this.handleCreateTransportDatetimeChange(key, 'loadingDate', e.target.value)
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label htmlFor="value_unloading_date">
                                                    <FormattedMessage id="fields.unloadingDate" />:
                                                </label>
                                                <DatePicker
                                                    id="value_unloading_date"
                                                    dateFormat="DD.MM.YYYY"
                                                    autoComplete="off"
                                                    selected={momentUnloadingDate}
                                                    value={day.unloadingDate}
                                                    onChange={value => {
                                                        this.handleCreateTransportDatetimeChange(
                                                            key,
                                                            'unloadingDate',
                                                            value ? dateFormatter(value) : ''
                                                        )
                                                    }}
                                                    onChangeRaw={e => {
                                                        this.handleCreateTransportDatetimeChange(key, 'unloadingDate', e.target.value)
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label htmlFor="value_code">
                                                    <FormattedMessage id="fields.code" />:
                                                </label>
                                                <input
                                                    id="value_code"
                                                    type="text"
                                                    className="form-control"
                                                    value={day.code}
                                                    onChange={event => {
                                                        this.handleCreateTransportCodeChange(key, event.target.value)
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-1">
                                            <div className="form-group no-label">
                                                <a href="#" onClick={e => this.handleCreateTransportRemoveRow(e, key)}>
                                                    <i className="far fa-trash text-gray" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}

                            <div className="row">
                                <div className="col-md-12">
                                    <div className="btns-form">
                                        <button onClick={this.handleCreateTransportAddRow} className="btn btn-addon btn-default">
                                            <i className="far fa-plus-circle" /> <FormattedMessage id="buttons.addRow" />
                                        </button>
                                        <button onClick={this.handleCreateTransportSubmit} className="btn btn-addon btn-success pull-right">
                                            <i className="far fa-check" /> <FormattedMessage id="buttons.createTransports" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>
                </div>
            </PermissionsCheck>
        )
    }
}

function mapStateToProps(state) {
    return {
        transports: createTableDataSelector({
            tableIdentifier,
            columns,
            dataSelector: getTransports,
            clientSideItemsPerPage,
        })(state),
        tableModel: createTableModelSelector(tableIdentifier)(state),
        prerequisites: preparePrerequisitesFetchStatusSelectors(componentIdentifier, prerequisites, state),
        deleting: getTransportDeleting(state),
        deleteError: getTransportDeleteError(state),
        creatingFromTemplate: getTransportCreatingFromTemplate(state),
        createFromTemplateError: getTransportCreateFromTemplateError(state),
        createGetTransportTypeByIDSelector: id => createGetTransportTypeByIDSelector(id)(state),
        createGetCargoTypeByIDSelector: id => createGetCargoTypeByIDSelector(id)(state),
        createGetGoodsTypeByIDSelector: id => createGetGoodsTypeByIDSelector(id)(state),
        createGetCustomerByIDSelector: id => createGetCustomerByIDSelector(id)(state),
        createGetCurrencyByIDSelector: id => createGetCurrencyByIDSelector(id)(state),
        createGetCountryByIDSelector: id => createGetCountryByIDSelector(id)(state),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            ...bindActionCreators(
                {
                    ...transportsActionCreators,
                    ...preparePrerequisitesActionCreators(componentIdentifier, prerequisites),
                },
                dispatch
            ),
        },
        notify: (notification, type) => dispatch(Notifications.show(notification, type)),
        redirect: toURL => dispatch(push(toURL)),
        dispatch,
    }
}

function mergeProps(stateProps, dispatchProps, ownProps) {
    return {
        ...stateProps,
        ...dispatchProps,
        ...ownProps,
        table: bindActionCreators(prepareTableActionCreators(tableIdentifier, stateProps.tableModel), dispatchProps.dispatch),
    }
}

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps,
        mergeProps
    )(TransportsList)
)
