import * as actionTypes from './actionTypes'

// Fetch

export const fetchReportCustomerResults = year => ({
    type: actionTypes.FETCH,
    payload: year,
})

export const fetchReportCustomerResultsFulfilled = data => ({
    type: actionTypes.FETCH_FULFILLED,
    payload: data,
})

export const fetchReportCustomerResultsRejected = message => ({
    type: actionTypes.FETCH_REJECTED,
    payload: message,
})

export const fetchReportCustomerResultsCancelled = () => ({
    type: actionTypes.FETCH_CANCELLED,
})

// Clear

export const clearReportCustomerResults = () => ({
    type: actionTypes.CLEAR,
})

// Export

export const exportReportCustomerResults = (year, filters) => ({
    type: actionTypes.EXPORT,
    payload: {
        year,
        filters,
    },
})

// Fetch details

export const fetchReportCustomerResultsDetails = (year, costCenterId, customerId, month, filters) => ({
    type: actionTypes.FETCH_DETAILS,
    payload: {
        year,
        costCenterId,
        customerId,
        month,
        filters,
    },
})

export const fetchReportCustomerResultsDetailsFulfilled = data => ({
    type: actionTypes.FETCH_DETAILS_FULFILLED,
    payload: data,
})

export const fetchReportCustomerResultsDetailsRejected = message => ({
    type: actionTypes.FETCH_DETAILS_REJECTED,
    payload: message,
})

export const fetchReportCustomerResultsDetailsCancelled = () => ({
    type: actionTypes.FETCH_DETAILS_CANCELLED,
})

// Clear

export const clearReportCustomerResultsDetails = () => ({
    type: actionTypes.CLEAR_DETAILS,
})

// Export

export const exportReportCustomerResultsDetails = (year, costCenterId, customerId, month, filters) => ({
    type: actionTypes.EXPORT_DETAILS,
    payload: {
        year,
        costCenterId,
        customerId,
        month,
        filters,
    },
})
