import { Observable } from 'rxjs'
import { normalize, schema } from 'normalizr'

import { request, parseAPIError, DownloadRequest } from '../../common/api'
import { transportSchema, transportLogSchema } from '../../common/normalizrSchemas'
import { normalizedEntitiesToRecordMap, mapNestedRecordsToRecordMap } from '../../common/helpers'

import * as tableModelActionTypes from '../../common/table/actionTypes'
import * as tableModelActionCreators from '../../common/table/actionCreators'
import { resolveModelState } from '../../common/table/helpers'

import { TransportType } from '../transport_types/model'
import * as transportTypesActionCreators from '../transport_types/actionCreators'

import { TransportState } from '../transport_states/model'
import * as transportStatesActionCreators from '../transport_states/actionCreators'

import { CargoType } from '../cargo_types/model'
import * as cargoTypesActionCreators from '../cargo_types/actionCreators'

import { GoodsType } from '../goods_types/model'
import * as goodsTypesActionCreators from '../goods_types/actionCreators'

import { User } from '../users/model'
import * as usersActionCreators from '../users/actionCreators'

import { Customer } from '../customers/model'
import * as customersActionCreators from '../customers/actionCreators'

import { Vehicle } from '../vehicles/model'
import * as vehiclesActionCreators from '../vehicles/actionCreators'

import { Currency } from '../currencies/model'
import * as currenciesActionCreators from '../currencies/actionCreators'

import { Country } from '../countries/model'
import * as countriesActionCreators from '../countries/actionCreators'

import { TransportPointState } from '../transport_point_states/model'
import * as transportPointStatesActionCreators from '../transport_point_states/actionCreators'

import { Carrier } from '../carriers/model'
import * as carriersActionCreators from '../carriers/actionCreators'

import { Driver } from '../drivers/model'
import * as driversActionCreators from '../drivers/actionCreators'

import { Trailer } from '../trailers/model'
import * as trailersActionCreators from '../trailers/actionCreators'
import * as selectors from './selectors'
import * as actionTypes from './actionTypes'
import * as actionCreators from './actionCreators'
import {
    Transport,
    TransportCustomer,
    TransportCustomerSurcharge,
    TransportPoint,
    TransportPointData,
    TransportRoute,
    TransportEvent,
    TransportNaviEvent,
    TransportEtaUpdate,
    TransportPointFile,
    TransportEventData,
    TransportLog,
    TransportLogChange,
    TransportFile,
    TransportPart,
} from './model'

import { fromJS } from "immutable";

// Fetch

const fetchTransportsEpic = (action$, store) => {
    const tableIdentifier = 'transports_list'
    const serverSide = true

    const triggeringAction = serverSide
        ? action$.ofType(actionTypes.FETCH, tableModelActionTypes.CONFIGURATION_CHANGED)
        : action$.ofType(actionTypes.FETCH)

    return triggeringAction
        .filter(action => action.type !== tableModelActionTypes.CONFIGURATION_CHANGED || action.payload.tableIdentifier === tableIdentifier)
        .switchMap(action => {
            const modelState = resolveModelState(tableIdentifier, store.getState(), action)

            const requestParams = {
                method: 'GET',
                path: `transports/list`,
            }

            if (serverSide) {
                requestParams.method = 'POST'
                requestParams.path += `?page=${modelState.getIn(['pagination', 'current']) + 1}`
                requestParams.body = {
                    sorting: modelState.get('sorting').toJS(),
                    filters: modelState.get('filters').toJS(),
                }
            }

            return Observable.concat(
                Observable.of({
                    type: actionTypes.FETCH_STARTED,
                }),
                request(requestParams)
                    .switchMap(ajaxResponse => {
                        const normalizedData = normalize(
                            serverSide ? ajaxResponse.response.data : ajaxResponse.response,
                            new schema.Array(transportSchema)
                        )

                        let transports = normalizedEntitiesToRecordMap(normalizedData.entities.transports, Transport, normalizedData.result)
                        const transportTypes = normalizedEntitiesToRecordMap(normalizedData.entities.transport_types, TransportType)
                        const cargoTypes = normalizedEntitiesToRecordMap(normalizedData.entities.cargo_types, CargoType)
                        const goodsTypes = normalizedEntitiesToRecordMap(normalizedData.entities.goods_types, GoodsType)
                        const users = normalizedEntitiesToRecordMap(normalizedData.entities.users, User)
                        const transportCustomers = normalizedEntitiesToRecordMap(normalizedData.entities.transport_customers, TransportCustomer)
                        const customers = normalizedEntitiesToRecordMap(normalizedData.entities.customers, Customer)
                        const vehicles = normalizedEntitiesToRecordMap(normalizedData.entities.vehicles, Vehicle)
                        const trailers = normalizedEntitiesToRecordMap(normalizedData.entities.trailers, Trailer)
                        const currencies = normalizedEntitiesToRecordMap(normalizedData.entities.currencies, Currency)
                        const carriers = normalizedEntitiesToRecordMap(normalizedData.entities.carriers, Carrier)
                        const drivers = normalizedEntitiesToRecordMap(normalizedData.entities.drivers, Driver)
                        const transportStates = normalizedEntitiesToRecordMap(normalizedData.entities.transport_states, TransportState)
                        let transportPoints = normalizedEntitiesToRecordMap(normalizedData.entities.transport_points, TransportPoint)
                        const transportEtaUpdates = normalizedEntitiesToRecordMap(normalizedData.entities.transport_eta_updates, TransportEtaUpdate)
                        const transportPointData = normalizedEntitiesToRecordMap(normalizedData.entities.transport_point_data, TransportPointData)
                        const transportPointStates = normalizedEntitiesToRecordMap(
                            normalizedData.entities.transport_point_states,
                            TransportPointState
                        )
                        const countries = normalizedEntitiesToRecordMap(normalizedData.entities.countries, Country)
                        const transportFiles = normalizedEntitiesToRecordMap(normalizedData.entities.transport_files, TransportFile)
                        const transportParts = normalizedEntitiesToRecordMap(normalizedData.entities.transport_parts, TransportPart)

                        transportPoints = mapNestedRecordsToRecordMap(
                            transportPoints,
                            {
                                transport_point_data: { data: transportPointData, recordClass: TransportPointData },
                                last_eta_update: { data: transportEtaUpdates, recordClass: TransportEtaUpdate },
                            },
                            TransportPoint
                        )

                        transports = mapNestedRecordsToRecordMap(
                            transports,
                            {
                                transport_customers: { data: transportCustomers },
                                transport_point_start: { data: transportPoints, recordClass: TransportPoint },
                                transport_point_loading: { data: transportPoints, recordClass: TransportPoint },
                                transport_point_unloading: { data: transportPoints, recordClass: TransportPoint },
                                transport_point_actual: { data: transportPoints, recordClass: TransportPoint },
                                transport_points: { data: transportPoints, recordClass: TransportPoint },
                                transport_files: { data: transportFiles },
                                transport_parts: { data: transportParts },
                            },
                            Transport
                        )

                        const observables = [
                            Observable.of(usersActionCreators.fetchUsersFulfilled(users)),
                            Observable.of(transportTypesActionCreators.fetchTransportTypesFulfilled(transportTypes)),
                            Observable.of(cargoTypesActionCreators.fetchCargoTypesFulfilled(cargoTypes)),
                            Observable.of(goodsTypesActionCreators.fetchGoodsTypesFulfilled(goodsTypes)),
                            Observable.of(customersActionCreators.fetchCustomersFulfilled(customers)),
                            Observable.of(vehiclesActionCreators.fetchVehiclesFulfilled(vehicles)),
                            Observable.of(trailersActionCreators.fetchTrailersFulfilled(trailers)),
                            Observable.of(currenciesActionCreators.fetchCurrenciesFulfilled(currencies)),
                            Observable.of(transportStatesActionCreators.fetchTransportStatesFulfilled(transportStates)),
                            Observable.of(countriesActionCreators.fetchCountriesFulfilled(countries)),
                            Observable.of(transportPointStatesActionCreators.fetchTransportPointStatesFulfilled(transportPointStates)),
                            Observable.of(carriersActionCreators.fetchCarriersFulfilled(carriers)),
                            Observable.of(driversActionCreators.fetchDriversFulfilled(drivers)),
                            Observable.of(actionCreators.fetchTransportsFulfilled(transports)),
                        ]

                        serverSide &&
                            observables.push(
                                Observable.of(
                                    tableModelActionCreators.updatePagination(
                                        tableIdentifier,
                                        ajaxResponse.response.last_page,
                                        ajaxResponse.response.current_page - 1,
                                        ajaxResponse.response.total
                                    )
                                )
                            )

                        return Observable.concat(...observables)
                    })
                    .catch(error => Observable.of(actionCreators.fetchTransportsRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.FETCH_CANCELLED, actionTypes.FETCH, tableModelActionTypes.CONFIGURATION_CHANGED))
            )
        })
}

// Normalize transport detail

const normalizeTransport = response => {
    const normalizedData = normalize(response, transportSchema)

    const data = {
        transports: normalizedEntitiesToRecordMap(normalizedData.entities.transports, Transport),
        transportStates: normalizedEntitiesToRecordMap(normalizedData.entities.transport_states, TransportState),
        transportTypes: normalizedEntitiesToRecordMap(normalizedData.entities.transport_types, TransportType),
        cargoTypes: normalizedEntitiesToRecordMap(normalizedData.entities.cargo_types, CargoType),
        goodsTypes: normalizedEntitiesToRecordMap(normalizedData.entities.goods_types, GoodsType),
        users: normalizedEntitiesToRecordMap(normalizedData.entities.users, User),
        transportCustomers: normalizedEntitiesToRecordMap(normalizedData.entities.transport_customers, TransportCustomer),
        transportPoints: normalizedEntitiesToRecordMap(normalizedData.entities.transport_points, TransportPoint),
        transportPointData: normalizedEntitiesToRecordMap(normalizedData.entities.transport_point_data, TransportPointData),
        transportEvents: normalizedEntitiesToRecordMap(normalizedData.entities.transport_events, TransportEvent),
        transportNaviEvents: normalizedEntitiesToRecordMap(normalizedData.entities.transport_navi_events, TransportNaviEvent),
        transportEtaUpdates: normalizedEntitiesToRecordMap(normalizedData.entities.transport_eta_updates, TransportEtaUpdate),
        transportPointFiles: normalizedEntitiesToRecordMap(normalizedData.entities.transport_point_files, TransportPointFile),
        transportEventData: normalizedEntitiesToRecordMap(normalizedData.entities.transport_event_data, TransportEventData),
        countries: normalizedEntitiesToRecordMap(normalizedData.entities.countries, Country),
        drivers: normalizedEntitiesToRecordMap(normalizedData.entities.drivers, Driver),
        vehicles: normalizedEntitiesToRecordMap(normalizedData.entities.vehicles, Vehicle),
        trailers: normalizedEntitiesToRecordMap(normalizedData.entities.trailers, Trailer),
        transportRoutes: normalizedEntitiesToRecordMap(normalizedData.entities.transport_routes, TransportRoute),
        transportPointStates: normalizedEntitiesToRecordMap(normalizedData.entities.transport_point_states, TransportPointState),
        transportCustomerSurcharges: normalizedEntitiesToRecordMap(normalizedData.entities.transport_customer_surcharges, TransportCustomerSurcharge),
        currencies: normalizedEntitiesToRecordMap(normalizedData.entities.currencies, Currency),
        customers: normalizedEntitiesToRecordMap(normalizedData.entities.customers, Customer),
    }

    data.transportCustomers = mapNestedRecordsToRecordMap(
        data.transportCustomers,
        {
            surcharges: { data: data.transportCustomerSurcharges, recordClass: TransportCustomerSurcharge },
        },
        TransportCustomer
    )

    data.transportEvents = mapNestedRecordsToRecordMap(
        data.transportEvents,
        {
            transport_point_files: { data: data.transportPointFiles, recordClass: TransportPointFile },
            transport_event_data: { data: data.transportEventData, recordClass: TransportEventData },
        },
        TransportEvent
    )

    data.transportPoints = mapNestedRecordsToRecordMap(
        data.transportPoints,
        {
            transport_point_data: { data: data.transportPointData, recordClass: TransportPointData },
            transport_events: { data: data.transportEvents, recordClass: TransportEvent },
            transport_navi_events: { data: data.transportNaviEvents, recordClass: TransportNaviEvent },
            last_eta_update: { data: data.transportEtaUpdates, recordClass: TransportEtaUpdate },
        },
        TransportPoint
    )

    data.transports = mapNestedRecordsToRecordMap(
        data.transports,
        {
            transport_customers: { data: data.transportCustomers },
            transport_points: { data: data.transportPoints, recordClass: TransportPoint },
            transport_routes: { data: data.transportRoutes, recordClass: TransportRoute },
            transport_point_start: { data: data.transportPoints, recordClass: TransportPoint },
            transport_point_loading: { data: data.transportPoints, recordClass: TransportPoint },
            transport_point_unloading: { data: data.transportPoints, recordClass: TransportPoint },
            transport_point_actual: { data: data.transportPoints, recordClass: TransportPoint },
            predeparture_check: { data: data.transportEvents, recordClass: TransportEvent },
        },
        Transport
    )

    data.transport = data.transports.valueSeq().first()
    data.transportStart = data.transport.id && data.transport.transport_point_start

    return data
}

const getTransportDetailActions = data =>
    Observable.concat(
        Observable.of(actionCreators.fetchTransportFulfilled()),
        Observable.of(usersActionCreators.fetchUsersFulfilled(data.users)),
        Observable.of(transportStatesActionCreators.fetchTransportStatesFulfilled(data.transportStates)),
        Observable.of(transportTypesActionCreators.fetchTransportTypesFulfilled(data.transportTypes)),
        Observable.of(cargoTypesActionCreators.fetchCargoTypesFulfilled(data.cargoTypes)),
        Observable.of(goodsTypesActionCreators.fetchGoodsTypesFulfilled(data.goodsTypes)),
        Observable.of(countriesActionCreators.fetchCountriesFulfilled(data.countries)),
        Observable.of(currenciesActionCreators.fetchCurrenciesFulfilled(data.currencies)),
        Observable.of(customersActionCreators.fetchCustomersFulfilled(data.customers)),
        Observable.of(driversActionCreators.fetchDriversFulfilled(data.drivers)),
        Observable.of(vehiclesActionCreators.fetchVehiclesFulfilled(data.vehicles)),
        Observable.of(trailersActionCreators.fetchTrailersFulfilled(data.trailers)),
        Observable.of(transportPointStatesActionCreators.fetchTransportPointStatesFulfilled(data.transportPointStates)),
        Observable.of(vehiclesActionCreators.fetchTransportStartFulfilled(data.transportStart && data.transportStart.id && data.transportStart))
    )

// Fetch one

const fetchTransportEpic = (action$, store) =>
    action$
        .ofType(actionTypes.FETCH_ONE)
        .filter(() => !store.getState().transports.getIn(['current', 'fetching']))
        .switchMap(action =>
            Observable.concat(
                Observable.of({
                    type: actionTypes.FETCH_ONE_STARTED,
                }),
                request({
                    path: `transports/${action.payload}`,
                    method: 'GET',
                })
                    .switchMap(ajaxResponse => {
                        const data = normalizeTransport(ajaxResponse.response)

                        return Observable.concat(
                            getTransportDetailActions(data),
                            Observable.of(actionCreators.fetchTransportsFulfilled(data.transports))
                        )
                    })
                    .catch(error => Observable.of(actionCreators.fetchTransportsRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.FETCH_ONE_CANCELLED))
            )
        )

// Load customer info

const loadCustomerInfoEpic = action$ =>
    action$.ofType(actionTypes.LOAD_CUSTOMER_INFO).switchMap(action =>
        Observable.concat(
            Observable.of({
                type: actionTypes.LOAD_CUSTOMER_INFO_STARTED,
            }),
            request({
                method: 'GET',
                path: `transports/customer-info?customer_id=${action.payload.customerId}`,
            })
                .switchMap(ajaxResponse => Observable.of(actionCreators.loadCustomerInfoFulfilled(action.payload.customerId, ajaxResponse.response.data)))
                .catch(error => Observable.of(actionCreators.loadCustomerInfoRejected(parseAPIError(error))))
                .takeUntil(action$.ofType(actionTypes.LOAD_CUSTOMER_INFO_CANCELLED))
        )
    )

// Save

const saveTransportEpic = (action$, store) =>
    action$
        .ofType(actionTypes.SAVE)
        .filter(() => !store.getState().transports.getIn(['current', 'saving']))
        .switchMap(action => {
            const values = { ...action.payload.values }
            if (action.payload.ignoreDuplicity) {
                values.ignore_duplicity = 1
            }

            let path = `transports`
            let method = 'POST'

            if (values.id) {
                path = `transports/${values.id}`
                method = 'PUT'

                delete values.id
            }

            return Observable.concat(
                Observable.of({
                    type: actionTypes.SAVE_STARTED,
                }),
                request({
                    path,
                    method,
                    body: values,
                })
                    .switchMap(ajaxResponse => {
                        if (ajaxResponse.response.duplicity_found) {
                            return Observable.concat(Observable.of(actionCreators.saveTransportDuplicityFound(ajaxResponse.response.duplicity)))
                        }

                        const data = normalizeTransport(ajaxResponse.response)

                        return Observable.concat(
                            getTransportDetailActions(data),
                            Observable.of(actionCreators.saveTransportFulfilled(data.transports.valueSeq().first()))
                        )
                    })
                    .catch(error => Observable.of(actionCreators.saveTransportRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.SAVE_CANCELLED))
            )
        })

// Delete

const deleteTransportEpic = (action$, store) =>
    action$
        .ofType(actionTypes.DELETE)
        .filter(() => !store.getState().transports.getIn(['deletion', 'inProgress']))
        .switchMap(action =>
            Observable.concat(
                Observable.of({
                    type: actionTypes.DELETE_STARTED,
                }),
                request({
                    path: `transports/${action.payload}`,
                    method: 'DELETE',
                })
                    .switchMap(() => Observable.of(actionCreators.deleteTransportFulfilled(action.payload)))
                    .catch(error => Observable.of(actionCreators.deleteTransportRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.DELETE_CANCELLED))
            )
        )

// Restore

const restoreTransportEpic = (action$, store) =>
    action$
        .ofType(actionTypes.RESTORE)
        .filter(() => !store.getState().transports.getIn(['restoring', 'inProgress']))
        .switchMap(action =>
            Observable.concat(
                Observable.of({
                    type: actionTypes.RESTORE_STARTED,
                }),
                request({
                    path: `transports/${action.payload}/restore`,
                    method: 'GET',
                })
                    .switchMap(ajaxResponse => {
                        const data = normalizeTransport(ajaxResponse.response)

                        return Observable.concat(
                            getTransportDetailActions(data),
                            Observable.of(actionCreators.restoreTransportFulfilled(data.transports.valueSeq().first()))
                        )
                    })
                    .catch(error => Observable.of(actionCreators.restoreTransportRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.RESTORE_CANCELLED))
            )
        )

// Approve

const approveTransportEpic = (action$, store) =>
    action$
        .ofType(actionTypes.APPROVE)
        .filter(() => !store.getState().transports.getIn(['approving', 'inProgress']))
        .switchMap(action =>
            Observable.concat(
                Observable.of({
                    type: actionTypes.APPROVE_STARTED,
                }),
                request({
                    path: `transports/${action.payload}/approve`,
                    method: 'GET',
                })
                    .switchMap(ajaxResponse => {
                        const data = normalizeTransport(ajaxResponse.response)

                        return Observable.concat(
                            getTransportDetailActions(data),
                            Observable.of(actionCreators.approveTransportFulfilled(data.transports.valueSeq().first()))
                        )
                    })
                    .catch(error => Observable.of(actionCreators.approveTransportRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.APPROVE_CANCELLED))
            )
        )

// Copy sms

const copySmsEpic = action$ =>
    action$.ofType(actionTypes.COPY_SMS).switchMap(action =>
        Observable.concat(
            Observable.of({
                type: actionTypes.COPY_SMS_STARTED,
            }),
            request({
                method: 'GET',
                path: `transports/${action.payload}/clipboard`,
            })
                .switchMap(ajaxResponse => Observable.of(actionCreators.fetchTransportSmsFulfilled(ajaxResponse.response.text)))
                .catch(error => Observable.of(actionCreators.fetchTransportSmsRejected(parseAPIError(error))))
                .takeUntil(action$.ofType(actionTypes.COPY_SMS_CANCELLED))
        )
    )

// Send to vehicle

const sendTransportToVehicleEpic = (action$, store) =>
    action$
        .ofType(actionTypes.SEND_TO_VEHICLE)
        .filter(() => !store.getState().transports.getIn(['sendingToVehicle', 'inProgress']))
        .switchMap(action =>
            Observable.concat(
                Observable.of({
                    type: actionTypes.SEND_TO_VEHICLE_STARTED,
                }),
                request({
                    path: `transports/${action.payload}/send-to-vehicle`,
                    method: 'POST',
                })
                    .switchMap(ajaxResponse => {
                        const data = normalizeTransport(ajaxResponse.response)

                        return Observable.concat(
                            getTransportDetailActions(data),
                            Observable.of(actionCreators.sendTransportToVehicleFulfilled(data.transports.valueSeq().first()))
                        )
                    })
                    .catch(error => Observable.of(actionCreators.sendTransportToVehicleRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.SAVE_CANCELLED))
            )
        )

// Delete from vehicle

const deleteTransportFromVehicleEpic = (action$, store) =>
    action$
        .ofType(actionTypes.DELETE_FROM_VEHICLE)
        .filter(() => !store.getState().transports.getIn(['deletionFromVehicle', 'inProgress']))
        .switchMap(action =>
            Observable.concat(
                Observable.of({
                    type: actionTypes.DELETE_FROM_VEHICLE_STARTED,
                }),
                request({
                    path: `transports/${action.payload}/delete-from-vehicle`,
                    method: 'POST',
                })
                    .switchMap(ajaxResponse => {
                        const data = normalizeTransport(ajaxResponse.response)

                        return Observable.concat(
                            getTransportDetailActions(data),
                            Observable.of(actionCreators.deleteTransportFromVehicleFulfilled(data.transports.valueSeq().first()))
                        )
                    })
                    .catch(error => Observable.of(actionCreators.deleteTransportFromVehicleRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.SAVE_CANCELLED))
            )
        )

// Create from template

const createTransportsFromTemplateEpic = (action$, store) =>
    action$
        .ofType(actionTypes.CREATE_FROM_TEMPLATE)
        .filter(() => !store.getState().transports.getIn(['creationFromTemplate', 'inProgress']))
        .switchMap(action =>
            Observable.concat(
                Observable.of({
                    type: actionTypes.CREATE_FROM_TEMPLATE_STARTED,
                }),
                request({
                    path: `transports/${action.payload.id}/create-from-template`,
                    method: 'POST',
                    body: {
                        days: { ...action.payload.params },
                    },
                })
                    .switchMap(() => Observable.of(actionCreators.createFromTemplateFulfilled(action.payload)))
                    .catch(error => Observable.of(actionCreators.createFromTemplateRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.CREATE_FROM_TEMPLATE_CANCELED))
            )
        )

// Create template

const createTransportEpic = (action$, store) =>
    action$
        .ofType(actionTypes.CREATE_TEMPLATE)
        .filter(() => !store.getState().transports.getIn(['creationTemplate', 'inProgress']))
        .switchMap(action => {
            const values = {}
            if (action.payload.ignoreDuplicity) {
                values.ignore_duplicity = 1
            }

            return Observable.concat(
                Observable.of({
                    type: actionTypes.CREATE_TEMPLATE_STARTED,
                }),
                request({
                    path: `transports/${action.payload.id}/create-template`,
                    method: 'POST',
                    body: values,
                })
                    .switchMap(ajaxResponse => {
                        if (ajaxResponse.response.duplicity_found) {
                            return Observable.concat(Observable.of(actionCreators.createTemplateDuplicityFound(ajaxResponse.response.duplicity)))
                        }

                        const data = normalizeTransport(ajaxResponse.response)

                        return Observable.concat(
                            getTransportDetailActions(data),
                            Observable.of(actionCreators.createTemplateFulfilled(data.transports.valueSeq().first()))
                        )
                    })
                    .catch(error => Observable.of(actionCreators.createTemplateRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.CREATE_TEMPLATE_CANCELED))
            )
        })

// Duplicate

const duplicateTransportEpic = (action$, store) =>
    action$
        .ofType(actionTypes.DUPLICATE)
        .filter(() => !store.getState().transports.getIn(['duplication', 'inProgress']))
        .switchMap(action =>
            Observable.concat(
                Observable.of({
                    type: actionTypes.DUPLICATE_STARTED,
                }),
                request({
                    path: `transports/${action.payload}/duplicate`,
                    method: 'POST',
                })
                    .switchMap(ajaxResponse => {
                        const data = normalizeTransport(ajaxResponse.response)

                        return Observable.concat(
                            getTransportDetailActions(data),
                            Observable.of(actionCreators.duplicateTransportFulfilled(data.transports.valueSeq().first()))
                        )
                    })
                    .catch(error => Observable.of(actionCreators.duplicateTransportRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.DUPLICATE_CANCELED))
            )
        )

// Save event

const saveTransportEventEpic = (action$, store) =>
    action$
        .ofType(actionTypes.SAVE_EVENT)
        .filter(() => !store.getState().transports.getIn(['event', 'saving']))
        .switchMap(action => {
            const values = { ...action.payload }
            const path = `transports/${values.transport_id}/event`
            const method = values.id ? 'PUT' : 'POST'

            return Observable.concat(
                Observable.of({
                    type: actionTypes.SAVE_EVENT_STARTED,
                }),
                request({
                    path,
                    method,
                    body: values,
                })
                    .switchMap(ajaxResponse => {
                        const data = normalizeTransport(ajaxResponse.response)

                        return Observable.concat(
                            getTransportDetailActions(data),
                            Observable.of(actionCreators.saveTransportEventFulfilled(data.transports.valueSeq().first()))
                        )
                    })
                    .catch(error => Observable.of(actionCreators.saveTransportEventRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.SAVE_EVENT_CANCELLED))
            )
        })

// Delete

const deleteTransportEventEpic = (action$, store) =>
    action$
        .ofType(actionTypes.DELETE_EVENT)
        .filter(() => !store.getState().transports.getIn(['eventDeletion', 'inProgress']))
        .switchMap(action => {
            const values = { ...action.payload }
            const path = `transports/${values.transport_id}/event`
            const method = 'DELETE'

            return Observable.concat(
                Observable.of({
                    type: actionTypes.DELETE_EVENT_STARTED,
                }),
                request({
                    path,
                    method,
                    body: values,
                })
                    .switchMap(ajaxResponse => {
                        const data = normalizeTransport(ajaxResponse.response)

                        return Observable.concat(
                            getTransportDetailActions(data),
                            Observable.of(actionCreators.deleteTransportEventFulfilled(data.transports.valueSeq().first()))
                        )
                    })
                    .catch(error => Observable.of(actionCreators.deleteTransportEventRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.DELETE_EVENT_CANCELLED))
            )
        })

// Create events

const createTransportEventsEpic = (action$, store) =>
    action$
        .ofType(actionTypes.CREATE_EVENTS)
        .filter(() => !store.getState().transports.getIn(['eventsCreating', 'saving']))
        .switchMap(action =>
            Observable.concat(
                Observable.of({
                    type: actionTypes.CREATE_EVENTS_STARTED,
                }),
                request({
                    path: `transports/${action.payload.transport_id}/events`,
                    method: 'POST',
                    body: { ...action.payload },
                })
                    .switchMap(ajaxResponse => {
                        const data = normalizeTransport(ajaxResponse.response)

                        return Observable.concat(
                            getTransportDetailActions(data),
                            Observable.of(actionCreators.createTransportEventsFulfilled(data.transports.valueSeq().first()))
                        )
                    })
                    .catch(error => Observable.of(actionCreators.createTransportEventsRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.CREATE_EVENTS_CANCELLED))
            )
        )

// Point state change

const changeTransportPointStateEpic = (action$, store) =>
    action$
        .ofType(actionTypes.CHANGE_POINT_STATE)
        .filter(() => !store.getState().transports.getIn(['pointStateChanging', 'inProgress']))
        .switchMap(action => {
            const values = { ...action.payload }
            const path = `transports/${values.transport_id}/point-state`
            const method = 'PUT'

            return Observable.concat(
                Observable.of({
                    type: actionTypes.CHANGE_POINT_STATE_STARTED,
                }),
                request({
                    path,
                    method,
                    body: values,
                })
                    .switchMap(ajaxResponse => {
                        const data = normalizeTransport(ajaxResponse.response)

                        return Observable.concat(
                            getTransportDetailActions(data),
                            Observable.of(actionCreators.changeTransportPointStateFulfilled(data.transports.valueSeq().first()))
                        )
                    })
                    .catch(error => Observable.of(actionCreators.changeTransportPointStateRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.CHANGE_POINT_STATE_CANCELLED))
            )
        })

// Fetch log

const fetchTransportLogEpic = (action$, store) =>
    action$
        .ofType(actionTypes.FETCH_LOG)
        .filter(() => !store.getState().transports.getIn(['log', 'fetching']))
        .switchMap(action =>
            Observable.concat(
                Observable.of({
                    type: actionTypes.FETCH_LOG_STARTED,
                }),
                request({
                    path: `transports/${action.payload}/log`,
                    method: 'GET',
                })
                    .switchMap(ajaxResponse => {
                        const normalizedData = normalize(ajaxResponse.response, [transportLogSchema])

                        let transportLogs = normalizedEntitiesToRecordMap(normalizedData.entities.transport_logs, TransportLog)
                        const users = normalizedEntitiesToRecordMap(normalizedData.entities.users, User)
                        const transportLogChanges = normalizedEntitiesToRecordMap(normalizedData.entities.transport_log_changes, TransportLogChange)

                        transportLogs = mapNestedRecordsToRecordMap(
                            transportLogs,
                            {
                                transport_log_changes: { data: transportLogChanges, recordClass: TransportLogChange },
                            },
                            TransportLog
                        )

                        return Observable.concat(
                            Observable.of(usersActionCreators.fetchUsersFulfilled(users)),
                            Observable.of(actionCreators.fetchTransportLogFulfilled(transportLogs))
                        )
                    })
                    .catch(error => Observable.of(actionCreators.fetchTransportLogRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.FETCH_LOG_CANCELLED))
            )
        )

// Export

const exportOffersEpic = (action$, store) =>
    action$.ofType(actionTypes.EXPORT).switchMap(action => {
        const filters = JSON.stringify(action.payload.filters)
        const sorting = JSON.stringify(action.payload.sorting)
        const token = store.getState().auth.get('accessToken')

        new DownloadRequest({
            url: `transports/export?filters=${filters}&sorting=${sorting}&token=${token}`,
        }).run()

        return Observable.concat(
            Observable.of({
                type: actionTypes.EXPORT_FULFILLED,
            })
        )
    })

// Planned route calculation

const recountPlannedRouteEpic = (action$, store) =>
    action$
        .ofType(actionTypes.RECOUNT_PLANNED_ROUTE)
        .filter(() => !store.getState().transports.getIn(['plannedRouteCalculating', 'inProgress']))
        .switchMap(action =>
            Observable.concat(
                Observable.of({
                    type: actionTypes.RECOUNT_PLANNED_ROUTE_STARTED,
                }),
                request({
                    path: `transports/${action.payload}/recount-planned-route`,
                    method: 'POST',
                })
                    .switchMap(ajaxResponse => {
                        const data = normalizeTransport(ajaxResponse.response)

                        return Observable.concat(
                            getTransportDetailActions(data),
                            Observable.of(actionCreators.recountPlannedRouteFulfilled(data.transports.valueSeq().first()))
                        )
                    })
                    .catch(error => Observable.of(actionCreators.recountPlannedRouteRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.RECOUNT_PLANNED_ROUTE_CANCELLED))
            )
        )

// Specified route calculation

const recountSpecifiedRouteEpic = (action$, store) =>
    action$
        .ofType(actionTypes.RECOUNT_SPECIFIED_ROUTE)
        .filter(() => !store.getState().transports.getIn(['specifiedRouteCalculating', 'inProgress']))
        .switchMap(action =>
            Observable.concat(
                Observable.of({
                    type: actionTypes.RECOUNT_SPECIFIED_ROUTE_STARTED,
                }),
                request({
                    path: `transports/${action.payload}/recount-specified-route`,
                    method: 'POST',
                })
                    .switchMap(ajaxResponse => {
                        const data = normalizeTransport(ajaxResponse.response)

                        return Observable.concat(
                            getTransportDetailActions(data),
                            Observable.of(actionCreators.recountSpecifiedRouteFulfilled(data.transports.valueSeq().first()))
                        )
                    })
                    .catch(error => Observable.of(actionCreators.recountSpecifiedRouteRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.RECOUNT_SPECIFIED_ROUTE_CANCELLED))
            )
        )

// Real route calculation

const recountRealRouteEpic = (action$, store) =>
    action$
        .ofType(actionTypes.RECOUNT_REAL_ROUTE)
        .filter(() => !store.getState().transports.getIn(['realRouteCalculating', 'inProgress']))
        .switchMap(action =>
            Observable.concat(
                Observable.of({
                    type: actionTypes.RECOUNT_REAL_ROUTE_STARTED,
                }),
                request({
                    path: `transports/${action.payload}/recount-real-route`,
                    method: 'POST',
                })
                    .switchMap(ajaxResponse => {
                        const data = normalizeTransport(ajaxResponse.response)

                        return Observable.concat(
                            getTransportDetailActions(data),
                            Observable.of(actionCreators.recountRealRouteFulfilled(data.transports.valueSeq().first()))
                        )
                    })
                    .catch(error => Observable.of(actionCreators.recountRealRouteRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.RECOUNT_REAL_ROUTE_CANCELLED))
            )
        )

// Fetch log

const fetchRealVehiclePositionsEpic = (action$, store) =>
    action$
        .ofType(actionTypes.FETCH_REAL_VEHICLE_POSITIONS)
        .filter(() => !store.getState().transports.getIn(['realVehiclePositions', 'fetching']))
        .switchMap(action =>
            Observable.concat(
                Observable.of({
                    type: actionTypes.FETCH_REAL_VEHICLE_POSITIONS_STARTED,
                }),
                request({
                    path: `transports/${action.payload}/positions`,
                    method: 'GET',
                })
                    .switchMap(ajaxResponse => Observable.of(actionCreators.fetchRealVehiclePositionsFulfilled(ajaxResponse.response)))
                    .catch(error => Observable.of(actionCreators.fetchRealVehiclePositionsRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.FETCH_REAL_VEHICLE_POSITIONS_CANCELLED))
            )
        )

// Fetch log

const fetchVehicleDataSourceEpic = (action$, store) =>
    action$
        .ofType(actionTypes.FETCH_VEHICLE_DATA_SOURCE)
        .filter(() => !store.getState().transports.getIn(['vehicleDataSource', 'fetching']))
        .switchMap(action =>
            Observable.concat(
                Observable.of({
                    type: actionTypes.FETCH_VEHICLE_DATA_SOURCE_STARTED,
                }),
                request({
                    path: `vehicles/${action.payload}/data-sources`,
                    method: 'GET',
                })
                    .switchMap(ajaxResponse => Observable.of(actionCreators.fetchVehicleDataSourceFulfilled(ajaxResponse.response)))
                    .catch(error => Observable.of(actionCreators.fetchVehicleDataSourceRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.FETCH_VEHICLE_DATA_SOURCE_CANCELLED))
            )
        )


// Fetch home country

const fetchVehicleHomeCountryEpic = (action$, store) =>
    action$
        .ofType(actionTypes.FETCH_VEHICLE_HOME_COUNTRY)
        .filter(() => !store.getState().transports.getIn(['vehicleHomeCountry', 'fetching']))
        .switchMap(action =>
            Observable.concat(
                Observable.of({
                    type: actionTypes.FETCH_VEHICLE_HOME_COUNTRY_STARTED,
                }),
                request({
                    path: `vehicles/${action.payload}/home-country`,
                    method: 'GET',
                })
                    .switchMap(ajaxResponse => Observable.of(actionCreators.fetchVehicleHomeCountryFulfilled(ajaxResponse.response)))
                    .catch(error => Observable.of(actionCreators.fetchVehicleHomeCountryRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.FETCH_VEHICLE_HOME_COUNTRY_CANCELLED))
            )
        )

// Fetch preview

const fetchTransportPreviewEpic = (action$, store) =>
    action$
        .ofType(actionTypes.FETCH_PREVIEW)
        .filter(() => !store.getState().transports.getIn(['preview', 'fetching']))
        .switchMap(action =>
            Observable.concat(
                Observable.of({
                    type: actionTypes.FETCH_PREVIEW_STARTED,
                }),
                request({
                    path: `transports/${action.payload}/preview`,
                    method: 'GET',
                })
                    .switchMap(ajaxResponse => Observable.of(actionCreators.fetchTransportPreviewFulfilled(ajaxResponse.response.transport)))
                    .catch(error => Observable.of(actionCreators.fetchTransportPreviewRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.FETCH_PREVIEW_CANCELLED))
            )
        )

// Fetch map preview

const fetchTransportMapPreviewEpic = (action$, store) =>
    action$
        .ofType(actionTypes.FETCH_MAP_PREVIEW)
        .filter(() => !store.getState().transports.getIn(['preview', 'fetching']))
        .switchMap(action =>
            Observable.concat(
                Observable.of({
                    type: actionTypes.FETCH_MAP_PREVIEW_STARTED,
                }),
                request({
                    path: `transports/${action.payload}/preview`,
                    method: 'GET',
                })
                    .switchMap(ajaxResponse => Observable.of(actionCreators.fetchTransportMapPreviewFulfilled(ajaxResponse.response.transport)))
                    .catch(error => Observable.of(actionCreators.fetchTransportMapPreviewRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.FETCH_MAP_PREVIEW_CANCELLED))
            )
        )

// Check customers

const checkCustomersEpic = (action$, store) =>
    action$
        .ofType(actionTypes.CHECK_CUSTOMERS)
        .filter(() => !store.getState().transports.getIn(['checkCustomers', 'fetching']))
        .switchMap(action =>
            Observable.concat(
                Observable.of({
                    type: actionTypes.CHECK_CUSTOMERS_STARTED,
                }),
                request({
                    path: 'transports/check-customers',
                    method: 'POST',
                    body: {
                        customers_ids: action.payload.customersIds,
                        transport_id: action.payload.transportId,
                    },
                })
                    .switchMap(ajaxResponse => Observable.of(actionCreators.checkCustomersFulfilled(ajaxResponse.response)))
                    .catch(error => Observable.of(actionCreators.checkCustomersRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.CHECK_CUSTOMERS_CANCELLED))
            )
        )

// Check carriers

const checkCarriersEpic = (action$, store) =>
    action$
        .ofType(actionTypes.CHECK_CARRIERS)
        .filter(() => !store.getState().transports.getIn(['checkCarriers', 'fetching']))
        .switchMap(action =>
            Observable.concat(
                Observable.of({
                    type: actionTypes.CHECK_CARRIERS_STARTED,
                }),
                request({
                    path: 'transports/check-carriers',
                    method: 'POST',
                    body: {
                        carriers_ids: action.payload.carriersIds,
                        transport_id: action.payload.transportId,
                    },
                })
                    .switchMap(ajaxResponse => Observable.of(actionCreators.checkCarriersFulfilled(ajaxResponse.response)))
                    .catch(error => Observable.of(actionCreators.checkCarriersRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.CHECK_CARRIERS_CANCELLED))
            )
        )

// Fetch orders info

const fetchTransportOrdersInfoEpic = (action$, store) =>
    action$
        .ofType(actionTypes.FETCH_ORDERS_INFO)
        .filter(() => !store.getState().transports.getIn(['ordersInfo', 'fetching']))
        .switchMap(action =>
            Observable.concat(
                Observable.of({
                    type: actionTypes.FETCH_ORDERS_INFO_STARTED,
                }),
                request({
                    path: `transports/${action.payload.id}/orders-info`,
                    method: 'POST',
                    body: { ...action.payload.values },
                })
                    .switchMap(ajaxResponse => Observable.of(actionCreators.fetchTransportOrdersInfoFulfilled(ajaxResponse.response.data)))
                    .catch(error => Observable.of(actionCreators.fetchTransportOrdersInfoRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.FETCH_ORDERS_INFO_CANCELLED))
            )
        )

// Create order

const createTransportOrderEpic = (action$, store) =>
    action$
        .ofType(actionTypes.CREATE_ORDER)
        .filter(() => !store.getState().transports.getIn(['orderCreating', 'inProgress']))
        .switchMap(action =>
            Observable.concat(
                Observable.of({
                    type: actionTypes.CREATE_ORDER_STARTED,
                }),
                request({
                    path: `transports/${action.payload.id}/create-order`,
                    method: 'POST',
                    body: { ...action.payload.values },
                })
                    .switchMap(ajaxResponse => Observable.of(actionCreators.createTransportOrderFulfilled(ajaxResponse.response.data)))
                    .catch(error => Observable.of(actionCreators.createTransportOrderRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.FETCH_ORDERS_INFO_CANCELLED))
            )
        )

// Send order to email

const sendTransportOrderToEmailEpic = (action$, store) =>
    action$
        .ofType(actionTypes.SEND_ORDER_TO_EMAIL)
        .filter(() => !store.getState().transports.getIn(['orderSending', 'inProgress']))
        .switchMap(action =>
            Observable.concat(
                Observable.of({
                    type: actionTypes.SEND_ORDER_TO_EMAIL_STARTED,
                }),
                request({
                    path: `transports/${action.payload.id}/send-order-to-email`,
                    method: 'POST',
                    body: { ...action.payload.values },
                })
                    .switchMap(() => Observable.of(actionCreators.sendTransportOrderToEmailFulfilled()))
                    .catch(error => Observable.of(actionCreators.sendTransportOrderToEmailRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.SEND_ORDER_TO_EMAIL_CANCELLED))
            )
        )

// Fetch files

const fetchTransportFilesEpic = (action$, store) =>
    action$
        .ofType(actionTypes.FETCH_FILES)
        .filter(() => !store.getState().transports.getIn(['files', 'fetching']))
        .switchMap(action =>
            Observable.concat(
                Observable.of({
                    type: actionTypes.FETCH_FILES_STARTED,
                }),
                request({
                    path: `transports/${action.payload}/files`,
                    method: 'GET',
                })
                    .switchMap(ajaxResponse =>
                        Observable.of(actionCreators.fetchTransportFilesFulfilled(ajaxResponse.response.files, ajaxResponse.response.eventFiles))
                    )
                    .catch(error => Observable.of(actionCreators.fetchTransportFilesRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.FETCH_FILES_CANCELLED))
            )
        )

// Upload files

const uploadTransportFilesEpic = (action$, store) =>
    action$
        .ofType(actionTypes.UPLOAD_FILES)
        .filter(() => !store.getState().transports.getIn(['files', 'saving']))
        .switchMap(action =>
            Observable.concat(
                Observable.of({
                    type: actionTypes.UPLOAD_FILES_STARTED,
                }),
                request({
                    path: `transports/${action.payload.id}/files`,
                    method: 'POST',
                    body: { ...action.payload },
                })
                    .switchMap(ajaxResponse =>
                        Observable.of(actionCreators.uploadTransportFilesFulfilled(ajaxResponse.response.files, ajaxResponse.response.eventFiles))
                    )
                    .catch(error => Observable.of(actionCreators.uploadTransportFilesRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.UPLOAD_FILES_CANCELLED))
            )
        )

// Delete file

const deleteTransportFileEpic = (action$, store) =>
    action$
        .ofType(actionTypes.DELETE_FILE)
        .filter(() => !store.getState().transports.getIn(['files', 'deleting']))
        .switchMap(action =>
            Observable.concat(
                Observable.of({
                    type: actionTypes.DELETE_FILE_STARTED,
                }),
                request({
                    path: `transports/${action.payload.id}/files/${action.payload.file_id}/${action.payload.isEventFile ? '2' : '1'}`,
                    method: 'DELETE',
                })
                    .switchMap(ajaxResponse =>
                        Observable.of(actionCreators.deleteTransportFileFulfilled(ajaxResponse.response.files, ajaxResponse.response.eventFiles))
                    )
                    .catch(error => Observable.of(actionCreators.deleteTransportFileRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.DELETE_FILE_CANCELLED))
            )
        )

// Fetch goods

const fetchTransportGoodsEpic = (action$, store) =>
    action$
        .ofType(actionTypes.FETCH_GOODS)
        .filter(() => !store.getState().transports.getIn(['goods', 'fetching']))
        .switchMap(action =>
            Observable.concat(
                Observable.of({
                    type: actionTypes.FETCH_GOODS_STARTED,
                }),
                request({
                    path: `transports/${action.payload}/goods`,
                    method: 'GET',
                })
                    .switchMap(ajaxResponse => Observable.of(actionCreators.fetchTransportGoodsFulfilled(ajaxResponse.response.goods)))
                    .catch(error => Observable.of(actionCreators.fetchTransportGoodsRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.FETCH_GOODS_CANCELLED))
            )
        )

// Save goods

const saveTransportGoodsEpic = (action$, store) =>
    action$
        .ofType(actionTypes.SAVE_GOODS)
        .filter(() => !store.getState().transports.getIn(['goods', 'saving']))
        .switchMap(action =>
            Observable.concat(
                Observable.of({
                    type: actionTypes.SAVE_GOODS_STARTED,
                }),
                request({
                    path: `transports/${action.payload.id}/goods`,
                    method: 'POST',
                    body: { ...action.payload.data },
                })
                    .switchMap(ajaxResponse => Observable.of(actionCreators.saveTransportGoodsFulfilled(ajaxResponse.response.goods)))
                    .catch(error => Observable.of(actionCreators.saveTransportGoodsRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.SAVE_GOODS_CANCELLED))
            )
        )

// Delete goods

const deleteTransportGoodsEpic = (action$, store) =>
    action$
        .ofType(actionTypes.DELETE_GOODS)
        .filter(() => !store.getState().transports.getIn(['goods', 'deleting']))
        .switchMap(action =>
            Observable.concat(
                Observable.of({
                    type: actionTypes.DELETE_GOODS_STARTED,
                }),
                request({
                    path: `transports/${action.payload.id}/goods/${action.payload.goods_id}`,
                    method: 'DELETE',
                })
                    .switchMap(ajaxResponse => Observable.of(actionCreators.deleteTransportGoodsFulfilled(ajaxResponse.response.goods)))
                    .catch(error => Observable.of(actionCreators.deleteTransportGoodsRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.DELETE_GOODS_CANCELLED))
            )
        )

// Fetch parts

const fetchTransportPartsEpic = (action$, store) =>
    action$
        .ofType(actionTypes.FETCH_PARTS)
        .filter(() => !store.getState().transports.getIn(['parts', 'fetching']))
        .switchMap(action =>
            Observable.concat(
                Observable.of({
                    type: actionTypes.FETCH_PARTS_STARTED,
                }),
                request({
                    path: `transports/${action.payload}/parts`,
                    method: 'GET',
                })
                    .switchMap(ajaxResponse => Observable.of(actionCreators.fetchTransportPartsFulfilled(ajaxResponse.response.parts)))
                    .catch(error => Observable.of(actionCreators.fetchTransportPartsRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.FETCH_PARTS_CANCELLED))
            )
        )

// Save part

const saveTransportPartEpic = (action$, store) =>
    action$
        .ofType(actionTypes.SAVE_PART)
        .filter(() => !store.getState().transports.getIn(['parts', 'saving']))
        .switchMap(action =>
            Observable.concat(
                Observable.of({
                    type: actionTypes.SAVE_PART_STARTED,
                }),
                request({
                    path: `transports/${action.payload.id}/parts`,
                    method: 'POST',
                    body: { ...action.payload.data },
                })
                    .switchMap(ajaxResponse => Observable.of(actionCreators.saveTransportPartFulfilled(ajaxResponse.response.parts)))
                    .catch(error => Observable.of(actionCreators.saveTransportPartRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.SAVE_PART_CANCELLED))
            )
        )

// Save parts order

const saveTransportPartsOrderEpic = (action$, store) =>
    action$
        .ofType(actionTypes.SAVE_PARTS_ORDER)
        .filter(() => !store.getState().transports.getIn(['parts', 'saving']))
        .switchMap(action =>
            Observable.concat(
                Observable.of({
                    type: actionTypes.SAVE_PARTS_ORDER_STARTED,
                }),
                request({
                    path: `transports/${action.payload.id}/parts-order`,
                    method: 'POST',
                    body: {
                        ids: { ...action.payload.data },
                    },
                })
                    .switchMap(ajaxResponse => Observable.of(actionCreators.saveTransportPartsOrderFulfilled(ajaxResponse.response.parts)))
                    .catch(error => Observable.of(actionCreators.saveTransportPartsOrderRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.SAVE_PARTS_ORDER_CANCELLED))
            )
        )

// Delete part

const deleteTransportPartEpic = (action$, store) =>
    action$
        .ofType(actionTypes.DELETE_PART)
        .filter(() => !store.getState().transports.getIn(['parts', 'deleting']))
        .switchMap(action =>
            Observable.concat(
                Observable.of({
                    type: actionTypes.DELETE_PART_STARTED,
                }),
                request({
                    path: `transports/${action.payload.id}/parts/${action.payload.part_id}`,
                    method: 'DELETE',
                })
                    .switchMap(ajaxResponse => Observable.of(actionCreators.deleteTransportPartFulfilled(ajaxResponse.response.parts)))
                    .catch(error => Observable.of(actionCreators.deleteTransportPartRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.DELETE_PART_CANCELLED))
            )
        )

// Correct event times

const correctEventTimesEpic = (action$, store) =>
    action$
        .ofType(actionTypes.CORRECT_EVENT_TIMES)
        .filter(() => !store.getState().transports.getIn(['eventTimesCorrection', 'inProgress']))
        .switchMap(action =>
            Observable.concat(
                Observable.of({
                    type: actionTypes.CORRECT_EVENT_TIMES_STARTED,
                }),
                request({
                    path: `transports/${action.payload}/correct-event-times`,
                    method: 'POST',
                })
                    .switchMap(ajaxResponse => {
                        const data = normalizeTransport(ajaxResponse.response)

                        return Observable.concat(
                            getTransportDetailActions(data),
                            Observable.of(actionCreators.correctEventTimesFulfilled(data.transports.valueSeq().first()))
                        )
                    })
                    .catch(error => Observable.of(actionCreators.correctEventTimesRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.CORRECT_EVENT_TIMES_CANCELLED))
            )
        )

// Correct point event times

const correctPointEventTimesEpic = (action$, store) =>
    action$
    .ofType(actionTypes.CORRECT_POINT_EVENT_TIMES)
    .filter(() => !store.getState().transports.getIn(['pointEventTimesCorrection', 'inProgress']))
    .switchMap(action =>
        Observable.concat(
            Observable.of({
                type: actionTypes.CORRECT_POINT_EVENT_TIMES_STARTED,
            }),
            request({
                path: `transports/${action.payload.transport}/correct-point-event-times/${action.payload.point}`,
                method: 'POST',
            })
            .switchMap(ajaxResponse => {
                const data = normalizeTransport(ajaxResponse.response)

                return Observable.concat(
                    getTransportDetailActions(data),
                    Observable.of(actionCreators.correctPointEventTimesFulfilled(data.transports.valueSeq().first()))
                )
            })
            .catch(error => Observable.of(actionCreators.correctPointEventTimesRejected(parseAPIError(error))))
            .takeUntil(action$.ofType(actionTypes.CORRECT_POINT_EVENT_TIMES_CANCELLED))
        )
    )

// Allowances

const fetchAllowancesEpic = (action$, store) =>
    action$
        .ofType(actionTypes.FETCH_ALLOWANCES)
        .filter(() => !store.getState().transports.getIn(['allowances', 'fetching']))
        .switchMap(action =>
            Observable.concat(
                Observable.of({
                    type: actionTypes.FETCH_ALLOWANCES_STARTED,
                }),
                request({
                    path: `allowances?filters=${JSON.stringify({ transport: action.payload.id })}`,
                    method: 'GET',
                })
                    .switchMap(ajaxResponse => Observable.of(actionCreators.fetchAllowancesFulfilled(ajaxResponse.response)))
                    .catch(error => Observable.of(actionCreators.fetchAllowancesRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.FETCH_ALLOWANCES_CANCELLED))
            )
        )

// Update/save

const saveAllowancesEpic = (action$, store) => {
    return action$
        .ofType(actionTypes.SAVE_ALLOWANCES)
        .filter(() => !store.getState().drivers.getIn(['allowances', 'saving']))
        .switchMap(action => {
            return Observable.concat(
                Observable.of({
                    type: actionTypes.SAVE_ALLOWANCES_STARTED,
                }),
                request({
                    method: 'POST',
                    path: `allowances?filters=${JSON.stringify({ transport: action.payload[0].transport_id })}`,
                    body: {
                        data: action.payload,
                    },
                })
                    .switchMap(ajaxResponse => Observable.of(actionCreators.saveAllowancesFulfilled(ajaxResponse.response)))
                    .catch(error => Observable.of(actionCreators.saveAllowancesRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.SAVE_ALLOWANCES_CANCELLED))
            )
        })
}

// Delete allowance

const deleteAllowanceEpic = (action$, store) => {
    return action$
        .ofType(actionTypes.DELETE_ALLOWANCE)
        .filter(() => !store.getState().drivers.getIn(['allowances', 'deleting']))
        .switchMap(action => {
            return Observable.concat(
                Observable.of({
                    type: actionTypes.DELETE_ALLOWANCE_STARTED,
                }),
                request({
                    method: 'DELETE',
                    path: `allowances/${action.payload.allowanceId}?filters=${JSON.stringify({ transport: action.payload.transportId })}`,
                })
                    .switchMap(ajaxResponse => Observable.of(actionCreators.deleteAllowanceFulfilled(ajaxResponse.response)))
                    .catch(error => Observable.of(actionCreators.deleteAllowanceRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.DELETE_ALLOWANCE_CANCELLED))
            )
        })
}
// Re-Assign allowances

const reassignAllowancesEpic = (action$, store) => {
    return action$
        .ofType(actionTypes.REASSIGN_ALLOWANCES)
        .filter(() => !store.getState().drivers.getIn(['allowances', 'fetching']))
        .switchMap(action => {
            return Observable.concat(
                Observable.of({
                    type: actionTypes.REASSIGN_ALLOWANCES_STARTED,
                }),
                request({
                    method: 'GET',
                    path: `transports/${action.payload.id}/reassign-allowances`,
                })
                    .switchMap(ajaxResponse => Observable.of(actionCreators.reassignAllowanceFulfilled(ajaxResponse.response)))
                    .catch(error => Observable.of(actionCreators.reassignAllowanceRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.REASSIGN_ALLOWANCES_CANCELLED))
            )
        })
}

// Fetch scoring

const fetchScoringEpic = (action$, store) => {
    return action$
        .ofType(actionTypes.FETCH_SCORING)
        .filter(() => !selectors.isTransportScoringFetching(store.getState()))
        .switchMap(action =>
            Observable.concat(
                Observable.of(actionCreators.fetchScoringStarted()),
                request({
                    method: 'GET',
                    path: `transports/${action.payload}/scoring`,
                })
                    .switchMap(ajaxResponse => Observable.of(actionCreators.fetchScoringFulfilled(ajaxResponse.response)))
                    .catch(error => Observable.of(actionCreators.fetchScoringRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.FETCH_SCORING_CANCELLED))
            )
        )
}

// Save scoring

const saveScoringEpic = (action$, store) => {
    return action$
        .ofType(actionTypes.SAVE_SCORING)
        .filter(() => !selectors.isTransportScoringSaving(store.getState()))
        .switchMap(action =>
            Observable.concat(
                Observable.of(actionCreators.saveScoringStarted()),
                request({
                    method: 'POST',
                    path: `transports/${action.payload.id}/scoring`,
                    body: action.payload.values,
                })
                    .switchMap(ajaxResponse => Observable.of(actionCreators.saveScoringFulfilled(ajaxResponse.response)))
                    .catch(error => Observable.of(actionCreators.saveScoringRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.SAVE_SCORING_CANCELLED))
            )
        )
}

// Fetch suggest for carrier

const fetchSuggestForCarrierEpic = (action$, store) => {
    return action$
        .ofType(actionTypes.FETCH_SUGGEST_FOR_CARRIER)
        .filter(() => !store.getState().transports.getIn(['carrierSuggests', 'fetching']))
        .switchMap(action =>
            Observable.concat(
                Observable.of({
                    type: actionTypes.FETCH_SUGGEST_FOR_CARRIER_STARTED,
                }),
                request({
                    path: `transports/suggest-for-carrier`,
                    method: 'POST',
                    body: action.payload,
                })
                    .switchMap(ajaxResponse => Observable.of(actionCreators.fetchSuggestForCarrierFulfilled(ajaxResponse.response)))
                    .catch(error => Observable.of(actionCreators.fetchSuggestForCarrierRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.FETCH_SUGGEST_FOR_CARRIER_CANCELLED))
            )
        )
}

// Fetch import headers

const fetchImportHeadersEpic = (action$, store) => {
    return action$
        .ofType(actionTypes.FETCH_IMPORT_HEADERS)
        .filter(() => !store.getState().transports.getIn(['import', 'inProgress']))
        .switchMap(action =>
            Observable.concat(
                Observable.of({
                    type: actionTypes.FETCH_IMPORT_HEADERS_STARTED,
                }),
                request({
                    path: `transports/import/get-headers`,
                    method: 'POST',
                    body: {
                        file: action.payload,
                    },
                })
                .switchMap(ajaxResponse => {
                    const importHeaders = {
                        file_id: ajaxResponse.response.file_id,
                        headers: fromJS(ajaxResponse.response.headers),
                        headers_default: fromJS(ajaxResponse.response.headers_default),
                    }
                    return Observable.of(actionCreators.fetchTransportsImportHeadersFulfilled(importHeaders))
                })
                .catch(error => Observable.of(actionCreators.fetchTransportsImportHeadersRejected(parseAPIError(error))))
                .takeUntil(action$.ofType(actionTypes.FETCH_IMPORT_HEADERS_CANCELLED))
            )
        )
}

// Fetch import items

const fetchImportItemsEpic = (action$, store) => {
    return action$
        .ofType(actionTypes.FETCH_IMPORT_ITEMS)
        .filter(() => !store.getState().transports.getIn(['import', 'inProgress']))
        .switchMap(action =>
            Observable.concat(
                Observable.of({
                    type: actionTypes.FETCH_IMPORT_ITEMS_STARTED,
                }),
                request({
                    path: `transports/import/get-items`,
                    method: 'POST',
                    body: {
                        fileId: action.payload.fileId,
                        columnTypes: action.payload.columnTypes,
                        defaultDate: action.payload.defaultDate,
                    },
                })
                .switchMap(ajaxResponse => {
                    return Observable.of(actionCreators.fetchTransportsImportItemsFulfilled(ajaxResponse.response.items, ajaxResponse.response.defaultDate))
                })
                .catch(error => Observable.of(actionCreators.fetchTransportsImportItemsRejected(parseAPIError(error))))
                .takeUntil(action$.ofType(actionTypes.FETCH_IMPORT_ITEMS_CANCELLED))
            )
        )
}

// Import transports

const importTransportsEpic = (action$, store) => {
    return action$
        .ofType(actionTypes.IMPORT_TRANSPORTS)
        .filter(() => !store.getState().transports.getIn(['import', 'inProgress']))
        .switchMap(action =>
            Observable.concat(
                Observable.of({
                    type: actionTypes.IMPORT_TRANSPORTS_STARTED,
                }),
                request({
                    path: `transports/import/import`,
                    method: 'POST',
                    body: {
                        fileId: action.payload.fileId,
                        columnTypes: action.payload.columnTypes,
                        defaultDate: action.payload.defaultDate,
                        keys: action.payload.keys,
                    },
                })
                .switchMap(ajaxResponse => {
                    return Observable.of(actionCreators.importTransportsFulfilled())
                })
                .catch(error => Observable.of(actionCreators.importTransportsRejected(parseAPIError(error))))
                .takeUntil(action$.ofType(actionTypes.IMPORT_TRANSPORTS_CANCELLED))
            )
        )
}

// Fetch import prices headers

const fetchImportPricesHeadersEpic = (action$, store) => {
    return action$
    .ofType(actionTypes.FETCH_IMPORT_PRICES_HEADERS)
    .filter(() => !store.getState().transports.getIn(['importPrices', 'inProgress']))
    .switchMap(action =>
        Observable.concat(
            Observable.of({
                type: actionTypes.FETCH_IMPORT_PRICES_HEADERS_STARTED,
            }),
            request({
                path: `transports/import-prices/get-headers`,
                method: 'POST',
                body: {
                    file: action.payload,
                },
            })
            .switchMap(ajaxResponse => {
                const importHeaders = {
                    file_id: ajaxResponse.response.file_id,
                    headers: fromJS(ajaxResponse.response.headers),
                    headers_default: fromJS(ajaxResponse.response.headers_default),
                }
                return Observable.of(actionCreators.fetchTransportsImportPricesHeadersFulfilled(importHeaders))
            })
            .catch(error => Observable.of(actionCreators.fetchTransportsImportPricesHeadersRejected(parseAPIError(error))))
            .takeUntil(action$.ofType(actionTypes.FETCH_IMPORT_PRICES_HEADERS_CANCELLED))
        )
    )
}

// Fetch import prices items

const fetchImportPricesItemsEpic = (action$, store) => {
    return action$
    .ofType(actionTypes.FETCH_IMPORT_PRICES_ITEMS)
    .filter(() => !store.getState().transports.getIn(['importPrices', 'inProgress']))
    .switchMap(action =>
        Observable.concat(
            Observable.of({
                type: actionTypes.FETCH_IMPORT_PRICES_ITEMS_STARTED,
            }),
            request({
                path: `transports/import-prices/get-items`,
                method: 'POST',
                body: {
                    fileId: action.payload.fileId,
                    columnTypes: action.payload.columnTypes,
                    defaultDate: action.payload.defaultDate,
                },
            })
            .switchMap(ajaxResponse => {
                return Observable.of(actionCreators.fetchTransportsImportPricesItemsFulfilled(ajaxResponse.response.items, ajaxResponse.response.defaultDate))
            })
            .catch(error => Observable.of(actionCreators.fetchTransportsImportPricesItemsRejected(parseAPIError(error))))
            .takeUntil(action$.ofType(actionTypes.FETCH_IMPORT_PRICES_ITEMS_CANCELLED))
        )
    )
}

// Import transports prices

const importTransportsPricesEpic = (action$, store) => {
    return action$
    .ofType(actionTypes.IMPORT_TRANSPORTS_PRICES)
    .filter(() => !store.getState().transports.getIn(['importPrices', 'inProgress']))
    .switchMap(action =>
        Observable.concat(
            Observable.of({
                type: actionTypes.IMPORT_TRANSPORTS_PRICES_STARTED,
            }),
            request({
                path: `transports/import-prices/import`,
                method: 'POST',
                body: {
                    fileId: action.payload.fileId,
                    columnTypes: action.payload.columnTypes,
                    defaultDate: action.payload.defaultDate,
                    keys: action.payload.keys,
                },
            })
            .switchMap(ajaxResponse => {
                return Observable.of(actionCreators.importTransportsPricesFulfilled())
            })
            .catch(error => Observable.of(actionCreators.importTransportsPricesRejected(parseAPIError(error))))
            .takeUntil(action$.ofType(actionTypes.IMPORT_TRANSPORTS_PRICES_CANCELLED))
        )
    )
}

export default [
    fetchTransportsEpic,
    fetchTransportEpic,
    loadCustomerInfoEpic,
    saveTransportEpic,
    deleteTransportEpic,
    restoreTransportEpic,
    approveTransportEpic,
    sendTransportToVehicleEpic,
    deleteTransportFromVehicleEpic,
    createTransportsFromTemplateEpic,
    createTransportEpic,
    duplicateTransportEpic,
    saveTransportEventEpic,
    deleteTransportEventEpic,
    createTransportEventsEpic,
    changeTransportPointStateEpic,
    fetchTransportLogEpic,
    exportOffersEpic,
    recountPlannedRouteEpic,
    recountSpecifiedRouteEpic,
    recountRealRouteEpic,
    fetchRealVehiclePositionsEpic,
    fetchVehicleDataSourceEpic,
    fetchVehicleHomeCountryEpic,
    fetchTransportPreviewEpic,
    fetchTransportMapPreviewEpic,
    checkCustomersEpic,
    checkCarriersEpic,
    fetchTransportOrdersInfoEpic,
    createTransportOrderEpic,
    sendTransportOrderToEmailEpic,
    fetchTransportFilesEpic,
    uploadTransportFilesEpic,
    deleteTransportFileEpic,
    fetchTransportGoodsEpic,
    saveTransportGoodsEpic,
    deleteTransportGoodsEpic,
    fetchTransportPartsEpic,
    saveTransportPartEpic,
    saveTransportPartsOrderEpic,
    deleteTransportPartEpic,
    correctEventTimesEpic,
    correctPointEventTimesEpic,
    fetchAllowancesEpic,
    saveAllowancesEpic,
    deleteAllowanceEpic,
    reassignAllowancesEpic,
    fetchScoringEpic,
    saveScoringEpic,
    fetchSuggestForCarrierEpic,
    copySmsEpic,
    fetchImportHeadersEpic,
    fetchImportItemsEpic,
    importTransportsEpic,
    fetchImportPricesHeadersEpic,
    fetchImportPricesItemsEpic,
    importTransportsPricesEpic,
]
