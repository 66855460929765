import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import Notifications from 'react-notification-system-redux'
import * as formatters from '../../../../common/formatters'

import PermissionsCheck from '../../../auth/components/PermissionsCheck'

import { PERMS } from '../../permissions'
import { preparePrerequisitesActionCreators } from "../../../../common/prerequisites/actionCreators"
import Dropzone from "react-dropzone"
import * as transportsActionCreators from "../../actionCreators"
import * as selectors from "../../selectors"
import { LoadingOverlay } from "../../../../common/loading_overlay"
import { SelectField } from "../../../../common/form";
import { convertISODateToCS } from "../../../../common/helpers";

const componentIdentifier = 'transports_import'
const prerequisites = [

]

class TransportsImportPrices extends Component {
    stepNames = {
        1: this.props.intl.formatMessage({ id: 'modules.transportsImportPrices.stepNames.1' }),
        2: this.props.intl.formatMessage({ id: 'modules.transportsImportPrices.stepNames.2' }),
        3: this.props.intl.formatMessage({ id: 'modules.transportsImportPrices.stepNames.3' })
    }

    columnTypes = {
        'orderNumber': this.props.intl.formatMessage({ id: 'modules.transportsImportPrices.columnTypes.orderNumber' }),
        'price': this.props.intl.formatMessage({ id: 'modules.transportsImportPrices.columnTypes.price' }),
        'priceToll': this.props.intl.formatMessage({ id: 'modules.transportsImportPrices.columnTypes.priceToll' }),
    }

    state = {
        step: 1,
        importHeaders: [],
        importFileId: null,
        importFileName: null,
        importItems: [],
        importItemValues: [],
        columnTypes: {},
        defaultDate: null,
        importAllItemsChecked: false
    }

    importProcessing = false

    onDropFiles = files => {
        files.forEach(file => {
            const reader = new FileReader()
            const fileName = file.name

            reader.onload = e => {
                const loadedFile = {
                    name: fileName,
                    base64: e.target.result,
                }
                this.props.actions.fetchTransportsImportPricesHeaders(loadedFile)
            }

            reader.readAsDataURL(file)
        })
    }

    onBackButtonClick = () => {
        this.setState({
            ...this.state,
            step: this.state.step - 1
        })
    }

    onSummaryButtonClick = () => {
        this.props.actions.fetchTransportsImportPricesItems(this.state.importFileId, this.state.columnTypes)
    }

    setColumnType = (id, value) => {
        this.setState({
            columnTypes: {
                ...this.state.columnTypes,
                [id]: value
            }
        })
    }

    onImportItemChange = (key, value) => {
        this.setState({
            importItemValues: {
                ...this.state.importItemValues,
                [key]: value
            }
        })
    }

    onImportAllItemsChange = (value) => {
        this.setState({
            importAllItemsChecked: value
        })

        var importItemValues = {};

        for (var key in this.state.importItemValues) {
            importItemValues[key] = value
        }

        this.setState({
            importItemValues: importItemValues
        })
    }

    onImportButtonClick = () => {
        this.importProcessing = true
        this.props.actions.importTransportsPrices(this.state.importFileId, this.state.columnTypes, this.state.defaultDate, this.state.importItemValues)
    }

    componentWillMount() {

    }

    componentDidMount() {

    }

    componentWillReceiveProps(nextProps) {
        if (this.props.importInProgress && !nextProps.importInProgress) {
            // Import error
            if (nextProps.importError) {
                this.props.notify(
                    {
                        title: this.props.intl.formatMessage({ id: 'alerts.titles.error' }),
                        message: nextProps.importError,
                        position: 'tc',
                    },
                    'error'
                )

                this.importProcessing = false

                return false;
            }
            else if (this.state.step === 1) {
                const headers = nextProps.importHeaders.valueSeq().toArray()
                const defaultHeaders = nextProps.importDefaultHeaders.toJS()
                const columnTypes = {}

                nextProps.importHeaders.map((headerName, headerId) => {
                    if (defaultHeaders[headerName]) {
                        columnTypes[headerId] = defaultHeaders[headerName]
                    }

                    return false;
                })

                this.setState({
                    ...this.state,
                    step: 2,
                    importHeaders: headers,
                    importFileId: nextProps.importFileId,
                    importFileName: nextProps.importFileName,
                    columnTypes,
                })
            }
            else if (this.state.step >= 2) {
                if (this.importProcessing) {
                    this.props.notify(
                        {
                            title: this.props.intl.formatMessage({ id: 'alerts.titles.success' }),
                            message: this.props.intl.formatMessage({ id: 'alerts.messages.importSuccess' }),
                            position: 'tc',
                        },
                        'success'
                    )

                    this.props.redirect('/transports/')
                }

                const items = nextProps.importItems
                const itemValues = []

                items.map((item, key) => {
                    itemValues[key] = !!item.transport

                    return false;
                })

                this.setState({
                    ...this.state,
                    step: 3,
                    importItems: items,
                    importItemValues: itemValues,
                    defaultDate: convertISODateToCS(nextProps.defaultDate)
                })
            }
        }
    }

    /* eslint-disable jsx-a11y/anchor-is-valid */
    render() {
        document.title = formatters.titleFormatter(this.props.intl.formatMessage({ id: 'modules.transportsImportPrices.heading' }))

        const columnTypesOptions = Object.keys(this.columnTypes).map(id => ({
            id: id,
            name: this.columnTypes[id],
        }))

        columnTypesOptions.unshift({
            id: null,
            name: '-'
        })

        return (
            <PermissionsCheck has={[PERMS.CREATE]} noPermissionsPage>
                <div className="page-inner">
                    <div id="main-wrapper">
                        <div className="row hp-100">
                            <div className={'col-md-12 col-xl-6 col-xl-offset-3'}>
                                <LoadingOverlay active={this.props.importInProgress}>
                                    <div className="panel panel-white">
                                        <div className="panel-body panel-padding">
                                            <h4>
                                                <FormattedMessage id="modules.transportsImportPrices.heading" /> - <FormattedMessage id="modules.transportsImportPrices.step" /> {this.state.step}/3 ({this.stepNames[this.state.step]})
                                            </h4>

                                            {this.state.step === 1 && (
                                                <div className="wp-100 pull-left clearfix">
                                                    <p className="m-b-lg">
                                                        Níže prosím nahrajte soubor, ze kterého se mají ceny naimportovat. Podporované formáty jsou XLS, XLSX a CSV.
                                                    </p>
                                                    <Dropzone onDrop={this.onDropFiles} multiple={false} style={{}} className="dropzone" acceptClassName="dropzone-accept">
                                                        <p className="ready">
                                                            <FormattedMessage id="fields.dropzoneReady" />
                                                        </p>
                                                        <p className="accept">
                                                            <FormattedMessage id="fields.dropzoneAccept" />
                                                        </p>
                                                    </Dropzone>
                                                </div>)}

                                            {this.state.step === 2 && (
                                                <div className="wp-100 pull-left clearfix">
                                                    <p className="m-b-lg">
                                                        Nastavte prosím význam jednotlivých sloupců tak, aby bylo možné ceny správně naimportovat.
                                                    </p>

                                                    <div className="row">
                                                        <div className="col-sm-6">
                                                            {Object.keys(this.state.importHeaders).map(headerId => {
                                                                let headerName = this.state.importHeaders[headerId]

                                                                return <div key={headerId}>
                                                                    <SelectField
                                                                        id={headerId}
                                                                        label={headerName}
                                                                        readonly={() => false}
                                                                        values={columnTypesOptions}
                                                                        value={this.state.columnTypes[headerId]}
                                                                        onChange={e => { this.setColumnType(headerId, e.value) }}
                                                                    />
                                                                </div>
                                                            })}
                                                        </div>
                                                    </div>
                                                </div>)}

                                            {this.state.step === 3 && (
                                                <div className="wp-100 pull-left clearfix">
                                                    <p className="m-b-lg">
                                                        Níže je přehled cen, které jsme v souboru našli. Vyberte prosím ty, které mají být zpracovány.
                                                    </p>

                                                    {!this.state.importItems.length && (<div className="alert alert-danger">
                                                        <p>
                                                            Nebyla nalezena žádná položka.
                                                        </p>
                                                    </div>)}

                                                    {this.state.importItems.length && (<div>
                                                        <table className="table table-striped">
                                                            <thead>
                                                            <tr>
                                                                <th className="w-40 text-center">
                                                                    <input
                                                                        type="checkbox"
                                                                        id={'item-all'}
                                                                        onChange={(e) => { this.onImportAllItemsChange(e.target.checked) }}
                                                                        checked={this.state.importAllItemsChecked}
                                                                    />
                                                                </th>
                                                                <th className="w-160">
                                                                    Přeprava
                                                                </th>
                                                                <th className="w-max">
                                                                    Číslo objednávky
                                                                </th>
                                                                <th className="w-120 text-right">
                                                                    Základní cena
                                                                </th>
                                                                <th className="w-120 text-right">
                                                                    Dálniční poplatky
                                                                </th>
                                                                <th className="w-120 text-right">
                                                                    Celková cena
                                                                </th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            {this.state.importItems.map((item, key) => {
                                                                return (<tr key={'importItem-'+key}>
                                                                    <td className="text-center">
                                                                        <input
                                                                            type="checkbox"
                                                                            id={'item-'+key}
                                                                            onChange={(e) => { this.onImportItemChange(key, e.target.checked) }}
                                                                            checked={this.state.importItemValues[key]}
                                                                        />
                                                                    </td>
                                                                    <td>
                                                                        {item.transport}
                                                                    </td>
                                                                    <td>
                                                                        <strong className={!item.transport ? 'text-default' : ''}>{item.orderNumber}</strong>
                                                                    </td>
                                                                    <td className="text-right">
                                                                        {item.priceBase ? (
                                                                            <span className={!item.transport ? 'text-default' : ''}>
                                                                                {formatters.priceFormatter(item.priceBase, '0,0.00', item.currency ? item.currency.iso_code : '')}
                                                                            </span>) : null}
                                                                    </td>
                                                                    <td className="text-right">
                                                                        {item.priceToll ? (
                                                                            <span className={!item.transport ? 'text-default' : ''}>
                                                                                {formatters.priceFormatter(item.priceToll, '0,0.00', item.currency ? item.currency.iso_code : '')}
                                                                            </span>) : null}
                                                                    </td>
                                                                    <td className="text-right">
                                                                        {item.price ? (
                                                                            <strong className={!item.transport ? 'text-default' : ''}>
                                                                                {formatters.priceFormatter(item.price, '0,0.00', item.currency ? item.currency.iso_code : '')}
                                                                            </strong>) : null}
                                                                    </td>
                                                                </tr>)
                                                            })}
                                                            </tbody>
                                                        </table>
                                                    </div>)}
                                                </div>)}

                                            <div className="pull-left wp-100 btns-form">
                                                {this.state.step > 1 && (<button onClick={this.onBackButtonClick} className="btn btn-addon btn-default">
                                                    <i className="far fa-chevron-left" /> <FormattedMessage id="buttons.back" />
                                                </button>)}

                                                {this.state.step === 2 && (<button onClick={this.onSummaryButtonClick} className="btn btn-addon btn-success pull-right">
                                                    <i className="far fa-chevron-right" /> <FormattedMessage id="buttons.continue" />
                                                </button>)}

                                                {this.state.step === 3 && (<button onClick={this.onImportButtonClick} className="btn btn-addon btn-success pull-right">
                                                    <i className="far fa-check" /> <FormattedMessage id="buttons.import" />
                                                </button>)}
                                            </div>
                                        </div>
                                    </div>
                                </LoadingOverlay>
                            </div>
                        </div>
                    </div>
                </div>
            </PermissionsCheck>
        )
    }
}

function mapStateToProps(state) {
    return {
        importInProgress: selectors.getImportPricesInProgress(state),
        importError: selectors.getImportPricesError(state),
        importFileId: selectors.getImportPricesFileId(state),
        importHeaders: selectors.getImportPricesHeaders(state),
        importDefaultHeaders: selectors.getImportPricesDefaultHeaders(state),
        importItems: selectors.getImportPricesItems(state),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            ...bindActionCreators(
                {
                    ...transportsActionCreators,
                    ...preparePrerequisitesActionCreators(componentIdentifier, prerequisites)
                },
                dispatch
            ),
        },
        notify: (notification, type) => dispatch(Notifications.show(notification, type)),
        redirect: toURL => dispatch(push(toURL)),
        dispatch,
    }
}

function mergeProps(stateProps, dispatchProps, ownProps) {
    return {
        ...stateProps,
        ...dispatchProps,
        ...ownProps
    }
}

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps,
        mergeProps
    )(TransportsImportPrices)
)
