import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import { push } from 'react-router-redux'
import Notifications from 'react-notification-system-redux'
import { FormattedMessage } from 'react-intl'
import { DropdownButton, MenuItem } from 'react-bootstrap'

import { LegendModal } from '../../pages/legend'
import PermissionsCheck from '../../pages/auth/components/PermissionsCheck'

import { getAuthInProgress, getAuthError, getLegends } from '../../pages/auth/selectors'
import * as vehicleMessagesSelectors from '../../pages/vehicle_messages/selectors'

import * as authActionCreators from '../../pages/auth/actionCreators'
import * as vehicleMessagesActionCreators from '../../pages/vehicle_messages/actionCreators'

import * as localeActionCreators from '../../pages/locale/actionCreators'
import { getCurrentLocaleSelector } from '../../pages/locale/selectors'

import WSNotifications from '../../common/notifications/components/WSNotifications'

import { PERMS as PERMS_CHAT } from '../../pages/vehicle_messages/permissions'

class TopMenu extends Component {
    state = {
        showLegendModal: false,
    }

    handleModalLegendOpen = () => {
        this.setState({ showLegendModal: true })
    }

    handleModalLegendClose = () => {
        this.setState({ showLegendModal: false })
    }

    handleLogout = e => {
        e.preventDefault()

        this.props.actions.logout()
    }

    toggleVehicleMessages = e => {
        e.preventDefault()

        this.props.chatSidebarIsOpen ? this.props.vehicleMessagesActions.hideSidebar() : this.props.vehicleMessagesActions.showSidebar()
    }

    handleChangeLocale = locale => {
        this.props.actions.changeLocale(locale)
    }

    componentWillReceiveProps(nextProps) {
        // Logout error
        if (this.props.authInProgress && !nextProps.authInProgress && nextProps.logoutError !== null) {
            this.props.notify(
                {
                    title: this.props.intl.formatMessage({ id: 'alerts.titles.error' }),
                    message: this.props.intl.formatMessage({ id: 'alerts.messages.logoutFailed' }),
                    position: 'tc',
                },
                'error'
            )
        }
    }

    componentDidMount() {
        this.props.vehicleMessagesActions.startMessageVehiclesAutoFetching()
    }

    /* eslint-disable jsx-a11y/anchor-is-valid */
    render() {
        const locales = {
            cs: 'Česky',
            en: 'English',
            pl: 'Polski',
        }

        const unreadMessages = this.props.unreadMessages

        const pathname = this.props.location.pathname
        const location = pathname.indexOf('/') === 0 ? pathname.substring(1) : pathname
        const component = location.substr(0, location.indexOf('/') === -1 ? location.length : location.indexOf('/')).replaceAll('-', '_')
        const component_languages = this.props.legends && this.props.legends.get(component)
        const showHelp = component_languages && component_languages.includes(this.props.currentLocale)

        return (
            <div className="navbar">
                <div className="navbar-inner">
                    <div className="sidebar-pusher">
                        <a href="#" className="waves-effect waves-button waves-classic push-sidebar">
                            <i className="fa fa-bars" />
                        </a>
                    </div>
                    <div className="logo-box">
                        <Link to="/" className="logo-text">
                            <span>
                                <FormattedMessage id="topLine.title" />
                            </span>
                        </Link>
                    </div>
                    <div className="topmenu-outer">
                        <div className="top-menu">
                            <ul className="nav navbar-nav navbar-right">
                                <WSNotifications />
                                {showHelp && (
                                    <li>
                                        <a href="#" onClick={() => this.handleModalLegendOpen()} className="waves-effect waves-button waves-classic">
                                            <span>
                                                <i className="far fa-info m-r-xxs" /> <FormattedMessage id="topLine.help" />
                                            </span>
                                        </a>
                                    </li>
                                )}
                                <li>
                                    <DropdownButton id={`dropdown-languages`} title={locales[this.props.currentLocale]}>
                                        {Object.keys(locales).map((key, index) => {
                                            const locale = locales[key]

                                            return (
                                                <MenuItem
                                                    key={key}
                                                    eventKey={index}
                                                    onSelect={() => {
                                                        this.handleChangeLocale(key)
                                                    }}
                                                    active={key === this.props.currentLocale}
                                                >
                                                    {locale}
                                                </MenuItem>
                                            )
                                        })}
                                    </DropdownButton>
                                </li>
                                <PermissionsCheck hasAny={Object.values(PERMS_CHAT)}>
                                    <li>
                                        <a href="#" onClick={this.toggleVehicleMessages} className="waves-effect waves-button waves-classic">
                                            <span>
                                                <i className="far fa-envelope m-r-xxs" /> <FormattedMessage id="topLine.messages" />
                                                {unreadMessages && unreadMessages.length > 0 && (
                                                    <span className="badge badge-success pull-right">{unreadMessages.length}</span>
                                                )}
                                            </span>
                                        </a>
                                    </li>
                                </PermissionsCheck>
                                <li>
                                    <a href="#" onClick={this.handleLogout} className="log-out waves-effect waves-button waves-classic">
                                        <span>
                                            <i className="far fa-sign-out m-r-xxs" /> <FormattedMessage id="topLine.signOut" />
                                        </span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <LegendModal
                    locale={this.props.currentLocale}
                    component={component}
                    show={Boolean(this.state.showLegendModal)}
                    onHide={this.handleModalLegendClose}
                />
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        authInProgress: getAuthInProgress(state),
        logoutError: getAuthError(state),
        chatSidebarIsOpen: vehicleMessagesSelectors.getIsOpen(state),
        currentLocale: getCurrentLocaleSelector(state),
        unreadMessages: vehicleMessagesSelectors.createGetUnreadMessagesSelector()(state),
        legends: getLegends(state),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            ...bindActionCreators(
                {
                    ...authActionCreators,
                    ...localeActionCreators,
                },
                dispatch
            ),
        },
        vehicleMessagesActions: bindActionCreators(vehicleMessagesActionCreators, dispatch),
        redirect: toURL => dispatch(push(toURL)),
        notify: (notification, type) => dispatch(Notifications.show(notification, type)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TopMenu))
