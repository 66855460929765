// Fetch list

export const FETCH = 'drivers.FETCH'
export const FETCH_STARTED = 'drivers.FETCH_STARTED'
export const FETCH_FULFILLED = 'drivers.FETCH_FULFILLED'
export const FETCH_REJECTED = 'drivers.FETCH_REJECTED'
export const FETCH_CANCELLED = 'drivers.FETCH_CANCELLED'

export const SORTING_CHANGED = 'drivers.SORTING_CHANGED'

// Fetch one

export const FETCH_ONE = 'drivers.FETCH_ONE'
export const FETCH_ONE_STARTED = 'drivers.FETCH_ONE_STARTED'
export const FETCH_ONE_FULFILLED = 'drivers.FETCH_ONE_FULFILLED'
export const FETCH_ONE_REJECTED = 'drivers.FETCH_ONE_REJECTED'
export const FETCH_ONE_CANCELLED = 'drivers.FETCH_ONE_CANCELLED'

// Save

export const SAVE = 'drivers.SAVE'
export const SAVE_STARTED = 'drivers.SAVE_STARTED'
export const SAVE_FULFILLED = 'drivers.SAVE_FULFILLED'
export const SAVE_REJECTED = 'drivers.SAVE_REJECTED'
export const SAVE_CANCELLED = 'drivers.SAVE_CANCELLED'
export const SAVE_DUPLICITY_FOUND = 'drivers.SAVE_DUPLICITY_FOUND'

// Delete

export const DELETE = 'drivers.DELETE'
export const DELETE_STARTED = 'drivers.DELETE_STARTED'
export const DELETE_FULFILLED = 'drivers.DELETE_FULFILLED'
export const DELETE_REJECTED = 'drivers.DELETE_REJECTED'
export const DELETE_CANCELLED = 'drivers.DELETE_CANCELLED'

// Restore

export const RESTORE = 'drivers.RESTORE'
export const RESTORE_STARTED = 'drivers.RESTORE_STARTED'
export const RESTORE_FULFILLED = 'drivers.RESTORE_FULFILLED'
export const RESTORE_REJECTED = 'drivers.RESTORE_REJECTED'
export const RESTORE_CANCELLED = 'drivers.RESTORE_CANCELLED'

// Export

export const EXPORT = 'drivers.EXPORT'
export const EXPORT_FULFILLED = 'drivers.EXPORT_FULFILLED'

// Fetch driver history

export const FETCH_HISTORY = 'drivers.FETCH_HISTORY'
export const FETCH_HISTORY_STARTED = 'drivers.FETCH_HISTORY_STARTED'
export const FETCH_HISTORY_FULFILLED = 'drivers.FETCH_HISTORY_FULFILLED'
export const FETCH_HISTORY_REJECTED = 'drivers.FETCH_HISTORY_REJECTED'
export const FETCH_HISTORY_CANCELLED = 'drivers.FETCH_HISTORY_CANCELLED'

// Export driver history

export const EXPORT_HISTORY = 'drivers.EXPORT_HISTORY'
export const EXPORT_HISTORY_STARTED = 'drivers.EXPORT_HISTORY_STARTED'
export const EXPORT_HISTORY_FULFILLED = 'drivers.EXPORT_HISTORY_FULFILLED'
export const EXPORT_HISTORY_REJECTED = 'drivers.EXPORT_HISTORY_REJECTED'
export const EXPORT_HISTORY_CANCELLED = 'drivers.EXPORT_HISTORY_CANCELLED'

// Fetch driver documents

export const FETCH_DOCUMENTS = 'drivers.FETCH_DOCUMENTS'
export const FETCH_DOCUMENTS_STARTED = 'drivers.FETCH_DOCUMENTS_STARTED'
export const FETCH_DOCUMENTS_FULFILLED = 'drivers.FETCH_DOCUMENTS_FULFILLED'
export const FETCH_DOCUMENTS_REJECTED = 'drivers.FETCH_DOCUMENTS_REJECTED'
export const FETCH_DOCUMENTS_CANCELLED = 'drivers.FETCH_DOCUMENTS_CANCELLED'

// Export driver documents

export const EXPORT_DOCUMENTS = 'drivers.EXPORT_DOCUMENTS'
export const EXPORT_DOCUMENTS_STARTED = 'drivers.EXPORT_DOCUMENTS_STARTED'
export const EXPORT_DOCUMENTS_FULFILLED = 'drivers.EXPORT_DOCUMENTS_FULFILLED'
export const EXPORT_DOCUMENTS_REJECTED = 'drivers.EXPORT_DOCUMENTS_REJECTED'
export const EXPORT_DOCUMENTS_CANCELLED = 'drivers.EXPORT_DOCUMENTS_CANCELLED'

// Fetch driver equipments

export const FETCH_EQUIPMENTS = 'drivers.FETCH_EQUIPMENTS'
export const FETCH_EQUIPMENTS_STARTED = 'drivers.FETCH_EQUIPMENTS_STARTED'
export const FETCH_EQUIPMENTS_FULFILLED = 'drivers.FETCH_EQUIPMENTS_FULFILLED'
export const FETCH_EQUIPMENTS_REJECTED = 'drivers.FETCH_EQUIPMENTS_REJECTED'
export const FETCH_EQUIPMENTS_CANCELLED = 'drivers.FETCH_EQUIPMENTS_CANCELLED'

// Export driver equipments

export const EXPORT_EQUIPMENTS = 'drivers.EXPORT_EQUIPMENTS'
export const EXPORT_EQUIPMENTS_STARTED = 'drivers.EXPORT_EQUIPMENTS_STARTED'
export const EXPORT_EQUIPMENTS_FULFILLED = 'drivers.EXPORT_EQUIPMENTS_FULFILLED'
export const EXPORT_EQUIPMENTS_REJECTED = 'drivers.EXPORT_EQUIPMENTS_REJECTED'
export const EXPORT_EQUIPMENTS_CANCELLED = 'drivers.EXPORT_EQUIPMENTS_CANCELLED'

// Fetch driver events

export const FETCH_EVENTS = 'drivers.FETCH_EVENTS'
export const FETCH_EVENTS_STARTED = 'drivers.FETCH_EVENTS_STARTED'
export const FETCH_EVENTS_FULFILLED = 'drivers.FETCH_EVENTS_FULFILLED'
export const FETCH_EVENTS_REJECTED = 'drivers.FETCH_EVENTS_REJECTED'
export const FETCH_EVENTS_CANCELLED = 'drivers.FETCH_EVENTS_CANCELLED'

// Export driver events

export const EXPORT_EVENTS = 'drivers.EXPORT_EVENTS'
export const EXPORT_EVENTS_STARTED = 'drivers.EXPORT_EVENTS_STARTED'
export const EXPORT_EVENTS_FULFILLED = 'drivers.EXPORT_EVENTS_FULFILLED'
export const EXPORT_EVENTS_REJECTED = 'drivers.EXPORT_EVENTS_REJECTED'
export const EXPORT_EVENTS_CANCELLED = 'drivers.EXPORT_EVENTS_CANCELLED'

// Fetch driver timesheets

export const FETCH_TIMESHEETS = 'drivers.FETCH_TIMESHEETS'
export const FETCH_TIMESHEETS_STARTED = 'drivers.FETCH_TIMESHEETS_STARTED'
export const FETCH_TIMESHEETS_FULFILLED = 'drivers.FETCH_TIMESHEETS_FULFILLED'
export const FETCH_TIMESHEETS_REJECTED = 'drivers.FETCH_TIMESHEETS_REJECTED'
export const FETCH_TIMESHEETS_CANCELLED = 'drivers.FETCH_TIMESHEETS_CANCELLED'

// Export driver timesheets

export const EXPORT_TIMESHEETS = 'drivers.EXPORT_TIMESHEETS'
export const EXPORT_TIMESHEETS_STARTED = 'drivers.EXPORT_TIMESHEETS_STARTED'
export const EXPORT_TIMESHEETS_FULFILLED = 'drivers.EXPORT_TIMESHEETS_FULFILLED'
export const EXPORT_TIMESHEETS_REJECTED = 'drivers.EXPORT_TIMESHEETS_REJECTED'
export const EXPORT_TIMESHEETS_CANCELLED = 'drivers.EXPORT_TIMESHEETS_CANCELLED'

// Fetch driver allowances

export const FETCH_ALLOWANCES = 'drivers.FETCH_ALLOWANCES'
export const FETCH_ALLOWANCES_STARTED = 'drivers.FETCH_ALLOWANCES_STARTED'
export const FETCH_ALLOWANCES_FULFILLED = 'drivers.FETCH_ALLOWANCES_FULFILLED'
export const FETCH_ALLOWANCES_REJECTED = 'drivers.FETCH_ALLOWANCES_REJECTED'
export const FETCH_ALLOWANCES_CANCELLED = 'drivers.FETCH_ALLOWANCES_CANCELLED'

// Save driver allowances

export const SAVE_ALLOWANCES = 'drivers.SAVE_ALLOWANCES'
export const SAVE_ALLOWANCES_STARTED = 'drivers.SAVE_ALLOWANCES_STARTED'
export const SAVE_ALLOWANCES_FULFILLED = 'drivers.SAVE_ALLOWANCES_FULFILLED'
export const SAVE_ALLOWANCES_REJECTED = 'drivers.SAVE_ALLOWANCES_REJECTED'
export const SAVE_ALLOWANCES_CANCELLED = 'drivers.SAVE_ALLOWANCES_CANCELLED'

// Delete driver allowances

export const DELETE_ALLOWANCES = 'drivers.DELETE_ALLOWANCES'
export const DELETE_ALLOWANCES_STARTED = 'drivers.DELETE_ALLOWANCES_STARTED'
export const DELETE_ALLOWANCES_FULFILLED = 'drivers.DELETE_ALLOWANCES_FULFILLED'
export const DELETE_ALLOWANCES_REJECTED = 'drivers.DELETE_ALLOWANCES_REJECTED'
export const DELETE_ALLOWANCES_CANCELLED = 'drivers.DELETE_ALLOWANCES_CANCELLED'
