import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import ReactPaginate from 'react-paginate'
import Notifications from 'react-notification-system-redux'
import { Tooltip, OverlayTrigger } from 'react-bootstrap'
import moment from 'moment'

import { DispatchersBox, TransportStateBox, ColoredBox } from '../../../common/colored_box'
import * as formatters from '../../../common/formatters'
import * as helpers from '../../../common/helpers'

import { prepareTableActionCreators } from '../../../common/table/actionCreators'
import { TableFilterInputField, TableFilterSelectField, TableFilterCheckboxField } from '../../../common/table/components'
import { getActiveFilters, getTotalNumberOfPages } from '../../../common/table/helpers'
import { TableModelColumn, TableModelColumnFilteringType } from '../../../common/table/model'
import { createTableDataSelector, createTableModelSelector } from '../../../common/table/selectors'

import { preparePrerequisitesFetchStatusSelectors } from '../../../common/prerequisites/selectors'
import { preparePrerequisitesActionCreators } from '../../../common/prerequisites/actionCreators'

import * as actionCreators from '../actionCreators'
import { getData } from '../selectors'
import PermissionsCheck from '../../auth/components/PermissionsCheck'
import { PERMS } from '../permissions'
import {distanceFormatter} from "../../../common/formatters";
import {Link} from "react-router-dom"

const tableIdentifier = 'report_spedition_transports'
const clientSideItemsPerPage = undefined

const componentIdentifier = 'report_spedition_transports'
const prerequisites = [
    'users',
    'transport_states',
    'transport_types',
    'cargo_types',
    'goods_types',
    'dispatchers',
    'company_cost_centers',
    'active_drivers',
]

const columns = {
    transport_id: new TableModelColumn({}),
}

class ReportSpeditionTransports extends Component {
    handleDatetimeChange = value => {
        this.props.actions.fetchReportSpeditionTransports(this.props.tableModel.get('filters'), value)
    }

    handleExport = e => {
        e && e.preventDefault()
        this.props.actions.exportReportSpeditionTransports(this.props.tableModel.get('filters'))
    }

    handleExportDetails = e => {
        e && e.preventDefault()
        this.props.actions.exportReportSpeditionTransportsDetails(this.props.tableModel.get('filters'))
    }

    refresh = () => {
        this.props.actions.fetchReportSpeditionTransports(this.props.tableModel.get('filters'))
    }

    resetFilters = () => {
        const dateFrom = moment().startOf('month')
        const dateTo = moment().endOf('month')

        this.props.table.resetFilters(
            [],
            [
                {
                    column: 'unloading_date_planned_from',
                    type: TableModelColumnFilteringType.equal,
                    value: dateFrom.format('DD.MM.YYYY'),
                },
                {
                    column: 'unloading_date_planned_to',
                    type: TableModelColumnFilteringType.equal,
                    value: dateTo.format('DD.MM.YYYY'),
                },
            ]
        )
    }

    isFilterActive = () => getActiveFilters(this.props.tableModel).size > 0

    componentDidMount() {
        const dateFrom = moment().startOf('month')
        const dateTo = moment().endOf('month')

        this.props.table.setConfiguration(
            [
                {
                    column: 'unloading_date_planned_from',
                    type: TableModelColumnFilteringType.equal,
                    value: dateFrom.format('DD.MM.YYYY'),
                },
                {
                    column: 'unloading_date_planned_to',
                    type: TableModelColumnFilteringType.equal,
                    value: dateTo.format('DD.MM.YYYY'),
                },
            ],
            false
        )

        this.props.actions.fetchReportSpeditionTransports(this.props.tableModel.get('filters'))
        this.props.actions.fetchPrerequisites()
    }

    render() {
        document.title = formatters.titleFormatter(this.props.intl.formatMessage({ id: 'modules.reportSpeditionTransports.heading' }))

        const filters = this.props.tableModel.get('filters')

        const tooltipNoTime = <Tooltip id="tooltipNoTime">{this.props.intl.formatMessage({ id: 'fields.noTime' })}</Tooltip>
        const tooltipNoVehicle = <Tooltip id="tooltipNoVehicle">{this.props.intl.formatMessage({ id: 'fields.noVehicle' })}</Tooltip>
        const tooltipNoTrailer = <Tooltip id="tooltipNoTrailer">{this.props.intl.formatMessage({ id: 'fields.noTrailer' })}</Tooltip>

        const driversOptions = helpers.getPrerequisitesArray(this.props.prerequisites.values.get('active_drivers'))
        driversOptions.unshift({
            id: 'no_driver',
            name: this.props.intl.formatMessage({ id: 'fields.noDriver' }),
        })

        return (
            <PermissionsCheck hasAny={Object.values(PERMS)} noPermissionsPage>
                <div className="page-inner">
                    <div id="main-wrapper">
                        <div className="panel panel-white">
                            <div className="panel-body">
                                <h4>
                                    <FormattedMessage id="modules.reportSpeditionTransports.heading" />
                                </h4>


                                <div className="btns-list">
                                    {this.isFilterActive() > 0 && (
                                        <button className="btn btn-default btn-addon m-r-xs" onClick={this.resetFilters}>
                                            <i className="far fa-times" /> <FormattedMessage id="buttons.resetFilters" />
                                        </button>
                                    )}
                                    <button className="btn btn-default btn-addon m-r-xs" onClick={this.refresh}>
                                        <i className="far fa-sync-alt" /> <FormattedMessage id="buttons.refresh" />
                                    </button>
                                    <PermissionsCheck has={[PERMS.EXPORT]}>
                                        <button className="btn btn-primary btn-addon m-r-xs" onClick={this.handleExport}>
                                            <i className="far fa-file-excel" /> <FormattedMessage id="buttons.exportToXLS" />
                                        </button>
                                    </PermissionsCheck>
                                    <PermissionsCheck has={[PERMS.EXPORT]}>
                                        <button className="btn btn-primary btn-addon" onClick={this.handleExportDetails}>
                                            <i className="far fa-file-excel" /> <FormattedMessage id="buttons.exportDetailsToXLS" />
                                        </button>
                                    </PermissionsCheck>
                                </div>

                                <div className="table-container">
                                    <table className="table table-striped table-hover table-fixed-header table-transports">
                                        <thead>
                                            <tr>
                                                <th className="w-80">
                                                    <FormattedMessage id="fields.number" />
                                                    <br />
                                                    <small>
                                                        <FormattedMessage id="fields.date" />
                                                    </small>
                                                </th>
                                                <th className="w-100">
                                                    <FormattedMessage id="fields.state" />
                                                    <br />
                                                    <small>
                                                        <FormattedMessage id="fields.disponent" />
                                                    </small>
                                                </th>
                                                <th className="w-max wm-400">
                                                    <FormattedMessage id="fields.customer" />
                                                    <br />
                                                    <small>
                                                        <FormattedMessage id="fields.transportType" /> | <FormattedMessage id="fields.cargoType" /> |{' '}
                                                        <FormattedMessage id="fields.goodsType" />
                                                    </small>
                                                </th>
                                                <th className="w-360">
                                                    <FormattedMessage id="fields.loading" />
                                                    <br />
                                                    <small>
                                                        {' '}
                                                        <FormattedMessage id="fields.plannedTime" /> | <FormattedMessage id="fields.code" />
                                                    </small>
                                                </th>
                                                <th className="w-360">
                                                    <FormattedMessage id="fields.unloading" />
                                                    <br />
                                                    <small>
                                                        <FormattedMessage id="fields.plannedTime" />
                                                    </small>
                                                </th>
                                                <th className="w-80">
                                                    <FormattedMessage id="fields.costCenter" />
                                                </th>
                                                <th className="w-120">
                                                    <FormattedMessage id="fields.vehicle" />
                                                    <br />
                                                    <small>
                                                        <FormattedMessage id="fields.dispatcher" />
                                                    </small>
                                                </th>
                                                <th className="w-120">
                                                    <FormattedMessage id="fields.trailer" />
                                                    <br />
                                                    <small>
                                                        <FormattedMessage id="fields.driver" />
                                                    </small>
                                                </th>
                                                <th className="w-90">
                                                    <FormattedMessage id="fields.realKilometers" />
                                                    <br />
                                                    <small>
                                                        <FormattedMessage id="fields.priceForKm" />
                                                    </small>
                                                </th>
                                                <th className="w-90">
                                                    <FormattedMessage id="fields.toll" />
                                                </th>
                                                <th className="w-90 text-right">
                                                    <FormattedMessage id="fields.price" />
                                                </th>
                                                <th className="w-90 text-right">
                                                    <FormattedMessage id="fields.costs" />
                                                </th>
                                                <th className="w-90 text-right">
                                                    <FormattedMessage id="fields.profit" />
                                                </th>
                                            </tr>
                                            <tr className="filters">
                                                <th className="w-80">
                                                    <TableFilterInputField
                                                        identifier="id"
                                                        type={TableModelColumnFilteringType.stringFromStart}
                                                        filters={filters}
                                                        onChange={this.props.table.changeFilter}
                                                        placeholder={this.props.intl.formatMessage({ id: 'fields.number' })}
                                                    />
                                                </th>
                                                <th className="w-100">
                                                    <TableFilterSelectField
                                                        identifier="transport_state_id"
                                                        type={TableModelColumnFilteringType.equal}
                                                        filters={filters}
                                                        onChange={this.props.table.changeFilter}
                                                        values={helpers.getPrerequisitesArray(
                                                            this.props.prerequisites.values.get('transport_states'),
                                                            'id',
                                                            'name',
                                                            this.props.intl,
                                                            'transportStates.'
                                                        )}
                                                        prompt={this.props.intl.formatMessage({ id: 'fields.state' })}
                                                    />
                                                    <div className="m-t-xxs">
                                                        <TableFilterSelectField
                                                            identifier="disponent_id"
                                                            type={TableModelColumnFilteringType.equal}
                                                            filters={filters}
                                                            onChange={this.props.table.changeFilter}
                                                            values={helpers.getPrerequisitesArray(this.props.prerequisites.values.get('users'))}
                                                            prompt={this.props.intl.formatMessage({ id: 'fields.disponent' })}
                                                        />
                                                    </div>
                                                </th>
                                                <th className="w-max wm-400">
                                                    <div className="pull-left wp-100">
                                                        <div className="pull-left m-r-xxs" style={{ width: '225px' }}>
                                                            <TableFilterInputField
                                                                identifier="customer"
                                                                type={TableModelColumnFilteringType.string}
                                                                filters={filters}
                                                                onChange={this.props.table.changeFilter}
                                                                placeholder={this.props.intl.formatMessage({ id: 'fields.customer' })}
                                                            />
                                                        </div>
                                                        <div className="pull-left w-110">
                                                            <TableFilterInputField
                                                                identifier="order_number"
                                                                type={TableModelColumnFilteringType.string}
                                                                filters={filters}
                                                                onChange={this.props.table.changeFilter}
                                                                placeholder={this.props.intl.formatMessage({ id: 'fields.orderNumber' })}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="m-t-xxs w-110 pull-left m-r-xxs">
                                                        <TableFilterSelectField
                                                            identifier="transport_type_id"
                                                            type={TableModelColumnFilteringType.equal}
                                                            filters={filters}
                                                            onChange={this.props.table.changeFilter}
                                                            values={helpers.getPrerequisitesArray(this.props.prerequisites.values.get('transport_types'))}
                                                            prompt={this.props.intl.formatMessage({ id: 'fields.transportType' })}
                                                        />
                                                    </div>
                                                    <div className="m-t-xxs w-110 pull-left m-r-xxs">
                                                        <TableFilterSelectField
                                                            identifier="cargo_type_id"
                                                            type={TableModelColumnFilteringType.equal}
                                                            filters={filters}
                                                            onChange={this.props.table.changeFilter}
                                                            values={helpers.getPrerequisitesArray(this.props.prerequisites.values.get('cargo_types'))}
                                                            prompt={this.props.intl.formatMessage({ id: 'fields.cargoType' })}
                                                        />
                                                    </div>
                                                    <div className="m-t-xxs w-110 pull-left">
                                                        <TableFilterSelectField
                                                            identifier="goods_type_id"
                                                            type={TableModelColumnFilteringType.equal}
                                                            filters={filters}
                                                            onChange={this.props.table.changeFilter}
                                                            values={helpers.getPrerequisitesArray(this.props.prerequisites.values.get('goods_types'))}
                                                            prompt={this.props.intl.formatMessage({ id: 'fields.goodsType' })}
                                                        />
                                                    </div>
                                                </th>
                                                <th className="w-360">
                                                    <TableFilterInputField
                                                        identifier="loading_address"
                                                        type={TableModelColumnFilteringType.string}
                                                        filters={filters}
                                                        onChange={this.props.table.changeFilter}
                                                        placeholder={this.props.intl.formatMessage({ id: 'fields.address' })}
                                                    />
                                                    <div className="m-t-xxs w-90 pull-left m-r-xxs">
                                                        <TableFilterInputField
                                                            identifier="loading_date_planned_from"
                                                            type={TableModelColumnFilteringType.string}
                                                            filters={filters}
                                                            onChange={this.props.table.changeFilter}
                                                            placeholder={this.props.intl.formatMessage({ id: 'fields.from' })}
                                                            datepicker
                                                        />
                                                    </div>
                                                    <div className="m-t-xxs w-90 pull-left">
                                                        <TableFilterInputField
                                                            identifier="loading_date_planned_to"
                                                            type={TableModelColumnFilteringType.string}
                                                            filters={filters}
                                                            onChange={this.props.table.changeFilter}
                                                            placeholder={this.props.intl.formatMessage({ id: 'fields.to' })}
                                                            datepicker
                                                        />
                                                    </div>
                                                    <div className="m-t-xxs w-20 pull-left m-r-xs">
                                                        <OverlayTrigger placement="top" overlay={tooltipNoTime}>
                                                            <div>
                                                                <TableFilterCheckboxField
                                                                    identifier="loading_no_date"
                                                                    type={TableModelColumnFilteringType.equal}
                                                                    filters={filters}
                                                                    onChange={this.props.table.changeFilter}
                                                                    label=""
                                                                />
                                                            </div>
                                                        </OverlayTrigger>
                                                    </div>
                                                    <div className="m-t-xxs pull-left" style={{ width: '99px' }}>
                                                        <TableFilterInputField
                                                            identifier="loading_code"
                                                            type={TableModelColumnFilteringType.string}
                                                            filters={filters}
                                                            onChange={this.props.table.changeFilter}
                                                            placeholder={this.props.intl.formatMessage({ id: 'fields.code' })}
                                                        />
                                                    </div>
                                                </th>
                                                <th className="w-360">
                                                    <TableFilterInputField
                                                        identifier="unloading_address"
                                                        type={TableModelColumnFilteringType.string}
                                                        filters={filters}
                                                        onChange={this.props.table.changeFilter}
                                                        placeholder={this.props.intl.formatMessage({ id: 'fields.address' })}
                                                    />
                                                    <div className="m-t-xxs w-90 pull-left m-r-xxs">
                                                        <TableFilterInputField
                                                            identifier="unloading_date_planned_from"
                                                            type={TableModelColumnFilteringType.string}
                                                            filters={filters}
                                                            onChange={this.props.table.changeFilter}
                                                            placeholder={this.props.intl.formatMessage({ id: 'fields.from' })}
                                                            datepicker
                                                        />
                                                    </div>
                                                    <div className="m-t-xxs w-90 pull-left">
                                                        <TableFilterInputField
                                                            identifier="unloading_date_planned_to"
                                                            type={TableModelColumnFilteringType.string}
                                                            filters={filters}
                                                            onChange={this.props.table.changeFilter}
                                                            placeholder={this.props.intl.formatMessage({ id: 'fields.to' })}
                                                            datepicker
                                                        />
                                                    </div>
                                                    <div className="m-t-xxs w-20 pull-left m-r-xs">
                                                        <OverlayTrigger placement="top" overlay={tooltipNoTime}>
                                                            <div>
                                                                <TableFilterCheckboxField
                                                                    identifier="unloading_no_date"
                                                                    type={TableModelColumnFilteringType.equal}
                                                                    filters={filters}
                                                                    onChange={this.props.table.changeFilter}
                                                                    label=""
                                                                />
                                                            </div>
                                                        </OverlayTrigger>
                                                    </div>
                                                </th>
                                                <th className="w-80">
                                                    <div className="pull-left wp-100">
                                                        <TableFilterSelectField
                                                            identifier="company_cost_center_id"
                                                            type={TableModelColumnFilteringType.equal}
                                                            filters={filters}
                                                            onChange={this.props.table.changeFilter}
                                                            values={helpers.getPrerequisitesArray(
                                                                this.props.prerequisites.values.get('company_cost_centers')
                                                            )}
                                                        />
                                                    </div>
                                                </th>
                                                <th className="w-240">
                                                    <div className="pull-left wp-100">
                                                        <div className="pull-left w-80">
                                                            <TableFilterInputField
                                                                identifier="vehicle"
                                                                type={TableModelColumnFilteringType.string}
                                                                filters={filters}
                                                                onChange={this.props.table.changeFilter}
                                                                placeholder={this.props.intl.formatMessage({ id: 'fields.registration' })}
                                                            />
                                                        </div>
                                                        <div className="pull-left w-20">
                                                            <OverlayTrigger placement="top" overlay={tooltipNoVehicle}>
                                                                <div>
                                                                    <TableFilterCheckboxField
                                                                        identifier="no_vehicle"
                                                                        type={TableModelColumnFilteringType.equal}
                                                                        filters={filters}
                                                                        onChange={this.props.table.changeFilter}
                                                                        label=""
                                                                    />
                                                                </div>
                                                            </OverlayTrigger>
                                                        </div>
                                                        <div className="pull-left w-20">&nbsp;</div>
                                                        <div className="pull-left w-80">
                                                            <TableFilterInputField
                                                                identifier="trailer"
                                                                type={TableModelColumnFilteringType.string}
                                                                filters={filters}
                                                                onChange={this.props.table.changeFilter}
                                                                placeholder={this.props.intl.formatMessage({ id: 'fields.registration' })}
                                                            />
                                                        </div>
                                                        <div className="pull-left w-20">
                                                            <OverlayTrigger placement="top" overlay={tooltipNoTrailer}>
                                                                <div>
                                                                    <TableFilterCheckboxField
                                                                        identifier="no_trailer"
                                                                        type={TableModelColumnFilteringType.equal}
                                                                        filters={filters}
                                                                        onChange={this.props.table.changeFilter}
                                                                        label=""
                                                                    />
                                                                </div>
                                                            </OverlayTrigger>
                                                        </div>
                                                    </div>
                                                    <div className="pull-left wp-100 m-t-xxs">
                                                        <div className="pull-left w-100 m-t-xxs">
                                                            <TableFilterSelectField
                                                                identifier="driver_id"
                                                                type={TableModelColumnFilteringType.equal}
                                                                filters={filters}
                                                                onChange={this.props.table.changeFilter}
                                                                values={driversOptions}
                                                                prompt={this.props.intl.formatMessage({ id: 'fields.driver' })}
                                                            />
                                                        </div>
                                                        <div className="pull-left w-20">&nbsp;</div>
                                                        <div className="pull-left w-100 m-t-xxs">
                                                            <TableFilterSelectField
                                                                identifier="dispatcher_id"
                                                                type={TableModelColumnFilteringType.equal}
                                                                filters={filters}
                                                                onChange={this.props.table.changeFilter}
                                                                values={helpers.getPrerequisitesArray(this.props.prerequisites.values.get('dispatchers'))}
                                                                prompt={this.props.intl.formatMessage({ id: 'fields.dispatcher' })}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="pull-left wp-100 m-t-xxs">
                                                        <div className="pull-left w-220 m-t-xxs">
                                                            <TableFilterInputField
                                                                identifier="spedition"
                                                                type={TableModelColumnFilteringType.string}
                                                                filters={filters}
                                                                onChange={this.props.table.changeFilter}
                                                                placeholder={this.props.intl.formatMessage({ id: 'fields.spedition' })}
                                                            />
                                                        </div>
                                                    </div>
                                                </th>
                                                <th className="w-90" />
                                                <th className="w-90" />
                                                <th className="w-90" />
                                                <th className="w-90" />
                                                <th className="w-90" />
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.props.data &&
                                                this.props.data.data.map(transport => {
                                                    const loading = transport.transport_points.find(
                                                        item => item.id === transport.transport_point_loading_id
                                                    )
                                                    const loadingDatetimeType = 'P'
                                                    const loadingDatetimeFrom = loading && loading.datetime_planned_from
                                                    const loadingDatetimeTo = loading && loading.datetime_planned_to
                                                    const loadingDatetimeArrival = loading && loading.datetime_arrival && moment(loading.datetime_arrival)
                                                    const loadingCode = loading && loading.transport_point_data[0]

                                                    const unloading = transport.transport_points.find(
                                                        item => item.id === transport.transport_point_unloading_id
                                                    )
                                                    const unloadingDatetimeType = 'P'
                                                    const unloadingDatetimeFrom = unloading && unloading.datetime_planned_from
                                                    const unloadingDatetimeTo = unloading && unloading.datetime_planned_to
                                                    const unloadingDatetimeArrival =
                                                        unloading && unloading.datetime_arrival && moment(unloading.datetime_arrival)

                                                    const vehicles = []
                                                    const vehicleIds = {}

                                                    const trailers = []
                                                    const trailerIds = {}

                                                    const drivers = []
                                                    const driverIds = {}

                                                    transport.transport_points &&
                                                        transport.transport_points.forEach(point => {
                                                            if (point.vehicle && !vehicleIds[point.vehicle.id]) {
                                                                vehicleIds[point.vehicle.id] = 1
                                                                vehicles.push(point.vehicle)
                                                            }

                                                            if (point.trailer && !trailerIds[point.trailer.id]) {
                                                                trailerIds[point.trailer.id] = 1
                                                                trailers.push(point.trailer)
                                                            }

                                                            if (point.driver && !driverIds[point.driver.id]) {
                                                                driverIds[point.driver.id] = 1
                                                                drivers.push(point.driver)
                                                            }
                                                        })

                                                    let totalPriceCZK = 0
                                                    let totalCostsCZK = 0
                                                    let currencyRate = 1
                                                    let currencyCode = 'CZK'
                                                    transport.transport_customers &&
                                                        transport.transport_customers.forEach(transportCustomer => {
                                                            totalPriceCZK += transportCustomer.price * transportCustomer.currency_rate
                                                            if (transportCustomer.currency_rate) {
                                                                currencyRate = transportCustomer.currency_rate
                                                            }
                                                            if (transportCustomer.currency) {
                                                                currencyCode = transportCustomer.currency.iso_code
                                                            }
                                                        })

                                                    if (transport.transport_parts && transport.transport_parts.length) {
                                                        transport.transport_parts.forEach(transportPart => {
                                                            totalCostsCZK += transportPart.carrier_price * transportPart.carrier_currency_rate
                                                        })
                                                    } else if (transport.is_spedition) {
                                                        totalCostsCZK += transport.carrier_price * transport.carrier_currency_rate
                                                    } else {
                                                        totalCostsCZK += transport.transport_route && transport.transport_route.costs_total
                                                    }

                                                    const totalCosts = totalCostsCZK / currencyRate

                                                    const totalProfitCZK = totalPriceCZK - totalCostsCZK
                                                    const totalProfit = totalProfitCZK / currencyRate

                                                    return (
                                                        <tr key={`item-${transport.id}`}>
                                                            <td className="w-80">
                                                                <strong>
                                                                    <Link to={`/transports/${transport.id}`}>
                                                                        {transport.id}
                                                                    </Link>
                                                                </strong>
                                                                <br />
                                                                <small className="text-gray">{formatters.dateFormatter(transport.date)}</small>
                                                            </td>
                                                            <td className="w-100">
                                                                {transport.transport_state && (
                                                                    <TransportStateBox transportState={transport.transport_state}>
                                                                        {this.props.intl.formatMessage({
                                                                            id: `transportStates.${transport.transport_state.name}`,
                                                                        })}
                                                                    </TransportStateBox>
                                                                )}
                                                                <br />
                                                                <small className="text-gray display-inline wp-100">
                                                                    {transport.disponent &&
                                                                        helpers.getShortFullName(
                                                                            transport.disponent.first_name,
                                                                            transport.disponent.last_name
                                                                        )}
                                                                </small>
                                                            </td>
                                                            <td className="w-max wm-400 relative">
                                                                {transport.transport_customers.map(transportCustomer => (
                                                                    <span key={`transportCustomer-${transportCustomer.id}`}>
                                                                        <strong>{transportCustomer.customer && transportCustomer.customer.name}</strong>
                                                                        {transportCustomer.order_number && ` [${transportCustomer.order_number}]`}
                                                                        <br />
                                                                    </span>
                                                                ))}
                                                                <small className="text-gray">
                                                                    {transport.transport_type && transport.transport_type.name} |{' '}
                                                                    {transport.cargo_type && transport.cargo_type.name} |{' '}
                                                                    {transport.goods_type && transport.goods_type.name}
                                                                </small>
                                                            </td>
                                                            <td className="w-360 relative">
                                                                {loading && (
                                                                    <div>
                                                                        {loading.city && (
                                                                            <strong>
                                                                                {loading.country && loading.country.iso_code}{' '}
                                                                                {`${loading.zipcode} ${loading.city}`}
                                                                            </strong>
                                                                        )}
                                                                        <br />
                                                                        <small className="text-gray">
                                                                            {loadingDatetimeType && `${loadingDatetimeType}: `}
                                                                            {formatters.datetimePeriodFormatter(loadingDatetimeFrom, loadingDatetimeTo)}
                                                                            {' | '}
                                                                            {loadingDatetimeArrival && (
                                                                                <span
                                                                                    className={
                                                                                        loadingDatetimeTo &&
                                                                                        moment(loadingDatetimeArrival).isBefore(moment(loadingDatetimeTo))
                                                                                            ? 'text-success'
                                                                                            : 'text-danger'
                                                                                    }
                                                                                >
                                                                                    A: {formatters.datetimeFormatter(loadingDatetimeArrival)}
                                                                                </span>
                                                                            )}
                                                                            {loadingCode && loadingCode.value && ` | ${loadingCode.value}`}
                                                                        </small>
                                                                    </div>
                                                                )}
                                                            </td>
                                                            <td className="w-360 relative">
                                                                {unloading && (
                                                                    <div>
                                                                        {unloading.city && (
                                                                            <strong>
                                                                                {unloading.country && unloading.country.iso_code}{' '}
                                                                                {`${unloading.zipcode} ${unloading.city}`}
                                                                            </strong>
                                                                        )}
                                                                        <br />
                                                                        <small className="text-gray">
                                                                            {unloadingDatetimeType && `${unloadingDatetimeType}: `}
                                                                            {formatters.datetimePeriodFormatter(
                                                                                unloadingDatetimeFrom,
                                                                                unloadingDatetimeTo
                                                                            )}
                                                                            {' | '}
                                                                            {unloadingDatetimeArrival && (
                                                                                <span
                                                                                    className={
                                                                                        unloadingDatetimeTo &&
                                                                                        moment(unloadingDatetimeArrival).isBefore(
                                                                                            moment(unloadingDatetimeTo)
                                                                                        )
                                                                                            ? 'text-success'
                                                                                            : 'text-danger'
                                                                                    }
                                                                                >
                                                                                    A: {formatters.datetimeFormatter(unloadingDatetimeArrival)}
                                                                                </span>
                                                                            )}
                                                                        </small>
                                                                    </div>
                                                                )}
                                                            </td>
                                                            <td className="w-80">
                                                                {transport.is_spedition && transport.company_cost_center
                                                                    ? transport.company_cost_center.code
                                                                    : ''}
                                                                {!transport.is_spedition &&
                                                                    vehicles.length > 0 && (
                                                                        <div>
                                                                            {vehicles.map(vehicle => (
                                                                                <span
                                                                                    key={`cost_center-${transport.id}-${vehicle.id}`}
                                                                                    className="display-inline wp-100"
                                                                                >
                                                                                    {vehicle.cost_center}
                                                                                </span>
                                                                            ))}
                                                                        </div>
                                                                    )}
                                                            </td>
                                                            {transport.is_spedition ? (
                                                                <td colSpan={2} className="w-240">
                                                                    <div>
                                                                        <ColoredBox
                                                                            bgRGB="#cccccc"
                                                                            textRGB="#222222"
                                                                            className="wp-100 display-inline text-center"
                                                                        >
                                                                            <FormattedMessage id="fields.spedition" />
                                                                        </ColoredBox>
                                                                        <br />
                                                                        <small className="text-gray">{transport.carrier && transport.carrier.name}</small>
                                                                    </div>
                                                                </td>
                                                            ) : (
                                                                <React.Fragment>
                                                                    <td className="w-120">
                                                                        {vehicles.length > 0 && (
                                                                            <div>
                                                                                {vehicles.map((vehicle, key) => (
                                                                                    <span
                                                                                        key={`vehicles-${transport.id}-${vehicle.id}`}
                                                                                        style={{ marginTop: `${key ? 3 : 0}px` }}
                                                                                        className="display-inline"
                                                                                    >
                                                                                        <DispatchersBox dispatcher={transport.dispatcher}>
                                                                                            {vehicle.registration}
                                                                                        </DispatchersBox>
                                                                                        <br />
                                                                                    </span>
                                                                                ))}

                                                                                <div>
                                                                                    <small className="text-gray display-inline wp-100">
                                                                                        {transport.dispatcher &&
                                                                                            helpers.getShortFullName(
                                                                                                transport.dispatcher.first_name,
                                                                                                transport.dispatcher.last_name
                                                                                            )}
                                                                                    </small>
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                    </td>
                                                                    <td className="w-120">
                                                                        <div>
                                                                            {trailers.length > 0 &&
                                                                                trailers.map((t, k) => (
                                                                                    <span
                                                                                        key={`trailers-${t.id}`}
                                                                                        style={{ marginTop: `${k ? 3 : 0}px` }}
                                                                                        className="display-inline"
                                                                                    >
                                                                                        <DispatchersBox dispatcher={null}>
                                                                                            {t.registration}
                                                                                        </DispatchersBox>
                                                                                        <br />
                                                                                    </span>
                                                                                ))}
                                                                        </div>
                                                                        <small className="text-gray">
                                                                            {drivers &&
                                                                                drivers.map(driver => (
                                                                                    <span
                                                                                        key={`driver-${transport.id}-${driver.id}`}
                                                                                        className="text-gray display-inline wp-100"
                                                                                    >
                                                                                        {helpers.getShortFullName(driver.first_name, driver.last_name)}
                                                                                    </span>
                                                                                ))}
                                                                        </small>
                                                                    </td>
                                                                </React.Fragment>
                                                            )}
                                                            <td className="w-90 text-right">

                                                                {transport.real_distance > 0 &&
                                                                    totalCosts > 0 && (
                                                                    <div>
                                                                        {distanceFormatter(transport.real_distance)}
                                                                        <br />
                                                                        <small className="text-gray">
                                                                            {formatters.priceFormatter((totalCosts / transport.real_distance), '0,0', currencyCode)}
                                                                        </small>
                                                                    </div>
                                                                )}
                                                            </td>
                                                            <td className="w-90 text-right">
                                                                {transport.totalSurchargesForToll > 0 && (
                                                                        <div>
                                                                            {formatters.priceFormatter(transport.totalSurchargesForToll, '0,0', currencyCode)}
                                                                        </div>
                                                                    )}
                                                            </td>
                                                            <td className="w-90 text-right">
                                                                {transport.transport_customers.map(transportCustomer => (
                                                                    <span className="display-inline wp-100" key={`currency-${transportCustomer.id}`}>
                                                                        {formatters.priceFormatter(
                                                                            transportCustomer.price,
                                                                            '0,0',
                                                                            transportCustomer.currency ? transportCustomer.currency.iso_code : ''
                                                                        )}
                                                                    </span>
                                                                ))}
                                                                <span className="display-inline wp-100">
                                                                    <small className="text-gray">{formatters.priceFormatter(totalPriceCZK)}</small>
                                                                </span>
                                                            </td>
                                                            <td className="w-90 text-right">
                                                                {formatters.priceFormatter(totalCosts, '0,0', currencyCode)}
                                                                <span className="display-inline wp-100">
                                                                    <small className="text-gray">{formatters.priceFormatter(totalCostsCZK)}</small>
                                                                </span>
                                                            </td>
                                                            <td className="w-90 text-right">
                                                                {formatters.priceFormatter(totalProfit, '0,0', currencyCode)}
                                                                <span className="display-inline wp-100">
                                                                    <small className="text-gray">{formatters.priceFormatter(totalProfitCZK)}</small>
                                                                </span>
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                        </tbody>
                                    </table>
                                </div>

                                <div className="pull-left m-l-xxs m-t-md">
                                    <FormattedMessage id="pagination.totalRecords" />:{' '}
                                    {helpers.getListItemsCountMessage(
                                        clientSideItemsPerPage,
                                        this.props.data.count,
                                        this.props.tableModel.getIn(['pagination', 'totalCount'])
                                    )}
                                </div>

                                <ReactPaginate
                                    containerClassName="pagination"
                                    breakLabel={<span className="disabled">...</span>}
                                    activeClassName="active"
                                    pageCount={getTotalNumberOfPages(this.props.tableModel, this.props.data.count, clientSideItemsPerPage)}
                                    pageRangeDisplayed={10}
                                    marginPagesDisplayed={2}
                                    forcePage={this.props.tableModel.getIn(['pagination', 'current'])}
                                    onPageChange={this.props.table.changePage}
                                    previousLabel={this.props.intl.formatMessage({ id: 'pagination.previous' })}
                                    nextLabel={this.props.intl.formatMessage({ id: 'pagination.next' })}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </PermissionsCheck>
        )
    }
}

function mapStateToProps(state) {
    return {
        data: createTableDataSelector({
            tableIdentifier,
            columns,
            dataSelector: getData,
            clientSideItemsPerPage,
        })(state),
        tableModel: createTableModelSelector(tableIdentifier)(state),
        prerequisites: preparePrerequisitesFetchStatusSelectors(componentIdentifier, prerequisites, state),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            ...bindActionCreators(
                {
                    ...actionCreators,
                    ...preparePrerequisitesActionCreators(componentIdentifier, prerequisites),
                },
                dispatch
            ),
        },
        notify: (notification, type) => dispatch(Notifications.show(notification, type)),
        dispatch,
    }
}

function mergeProps(stateProps, dispatchProps, ownProps) {
    return {
        ...stateProps,
        ...dispatchProps,
        ...ownProps,
        table: bindActionCreators(prepareTableActionCreators(tableIdentifier, stateProps.tableModel), dispatchProps.dispatch),
    }
}

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps,
        mergeProps
    )(ReportSpeditionTransports)
)
