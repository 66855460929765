import * as actionTypes from './actionTypes'

// Fetch

export const fetchReportDriverCosts = (year, inclVehicles, inclCostCenters, inclTrailers) => ({
    type: actionTypes.FETCH,
    payload: {
        year,
        inclVehicles,
        inclCostCenters,
        inclTrailers
    },
})

export const fetchReportDriverCostsFulfilled = data => ({
    type: actionTypes.FETCH_FULFILLED,
    payload: data,
})

export const fetchReportDriverCostsRejected = message => ({
    type: actionTypes.FETCH_REJECTED,
    payload: message,
})

export const fetchReportDriverCostsCancelled = () => ({
    type: actionTypes.FETCH_CANCELLED,
})

// Export

export const exportReportDriverCosts = (year, filters, inclVehicles, inclCostCenters, inclTrailers) => ({
    type: actionTypes.EXPORT,
    payload: {
        year,
        filters,
        inclVehicles,
        inclCostCenters,
        inclTrailers
    },
})

// Clear

export const clearReportDriverCosts = () => ({
    type: actionTypes.CLEAR,
})

// Fetch details

export const fetchReportDriverCostsDetails = (id, year, month, inclVehicles, inclCostCenters, inclTrailers) => ({
    type: actionTypes.FETCH_DETAILS,
    payload: {
        id,
        year,
        month,
        inclVehicles,
        inclCostCenters,
        inclTrailers
    },
})

export const fetchReportDriverCostsDetailsFulfilled = data => ({
    type: actionTypes.FETCH_DETAILS_FULFILLED,
    payload: data,
})

export const fetchReportDriverCostsDetailsRejected = message => ({
    type: actionTypes.FETCH_DETAILS_REJECTED,
    payload: message,
})

export const fetchReportDriverCostsDetailsCancelled = () => ({
    type: actionTypes.FETCH_DETAILS_CANCELLED,
})

// Export

export const exportReportDriverCostsDetails = (id, year, month, inclVehicles, inclCostCenters, inclTrailers) => ({
    type: actionTypes.EXPORT_DETAILS,
    payload: {
        id,
        year,
        month,
        inclVehicles,
        inclCostCenters,
        inclTrailers
    },
})

// Clear

export const clearReportDriverCostsDetails = () => ({
    type: actionTypes.CLEAR_DETAILS,
})
