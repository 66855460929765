import { createSelector } from 'reselect'

export const getTrailers = state => state.trailers.getIn(['all', 'objects'])

export const createGetTrailerByIDSelector = id => createSelector([getTrailers], trailers => trailers.get(String(id)))

const getCurrentTrailerStatus = state => state.trailers.get('current')
export const createGetCurrentTrailerStatusSelector = () => createSelector([getCurrentTrailerStatus], status => status)

export const getTrailerDeleting = state => state.trailers.getIn(['deletion', 'inProgress'])
export const getTrailerDeleteError = state => state.trailers.getIn(['deletion', 'error'])

const getDuplicity = state => state.trailers.getIn(['all', 'duplicity'])
export const createGetDuplicitySelector = () => createSelector([getDuplicity], duplicity => duplicity)

export const getTrailerHistoryData = state => state.trailers.getIn(['history', 'data'])
export const getTrailerHistoryFetching = state => state.trailers.getIn(['history', 'fetching'])
export const getTrailerHistoryError = state => state.trailers.getIn(['history', 'error'])

export const getTrailerDocumentsData = state => state.trailers.getIn(['documents', 'data'])
export const getTrailerDocumentsFetching = state => state.trailers.getIn(['documents', 'fetching'])
export const getTrailerDocumentsError = state => state.trailers.getIn(['documents', 'error'])

export const getTrailerEquipmentsData = state => state.trailers.getIn(['equipments', 'data'])
export const getTrailerEquipmentsFetching = state => state.trailers.getIn(['equipments', 'fetching'])
export const getTrailerEquipmentsError = state => state.trailers.getIn(['equipments', 'error'])

export const getTrailerEventsData = state => state.trailers.getIn(['events', 'data'])
export const getTrailerEventsFetching = state => state.trailers.getIn(['events', 'fetching'])
export const getTrailerEventsError = state => state.trailers.getIn(['events', 'error'])
