import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { push } from 'react-router-redux'
import Notifications from 'react-notification-system-redux'
import validator from 'validator'

import { url, handleCommonEditActions, validationMessage, getPrerequisitesArray } from '../../../common/helpers'
import * as formatters from '../../../common/formatters'
import { Form, InputField, CheckboxField, SubmitButton } from '../../../common/form'
import { createGetUserRoleByIDSelector, createGetCurrentUserRoleStatusSelector } from '../selectors'
import * as userRolesActionCreators from '../actionCreators'
import { LoadingOverlay } from '../../../common/loading_overlay'
import { preparePrerequisitesFetchStatusSelectors } from '../../../common/prerequisites/selectors'
import { preparePrerequisitesActionCreators } from '../../../common/prerequisites/actionCreators'

import PermissionsCheck from '../../auth/components/PermissionsCheck'
import { PERMS } from '../permissions'

const componentIdentifier = 'user_roles_edit'
const prerequisites = ['user_permissions']

class UserRolesEdit extends Component {
    defaultValues = {}

    validationRules = {
        name: value => validator.isEmpty(String(value)) && validationMessage('isEmpty'),
    }

    state = {
        values: this.defaultValues,
    }

    setValues = (values, callback) => {
        this.setState(
            {
                values,
            },
            callback
        )
    }

    handleChangeValues = values => {
        this.setValues(values)
    }

    handleSubmit = values => {
        this.setValues(values, () => {
            this.save()
        })
    }

    save = () => {
        this.props.actions.saveUserRole(this.state.values)
    }

    successCallback = null

    handleSave = () => {
        this.successCallback = (props, nextProps) => {
            if (nextProps.status.get('id')) {
                props.redirect(url(props.match, `user-roles/${nextProps.status.get('id')}`))
            } else {
                props.redirect(url(props.match, 'user-roles'))
            }
        }

        this.save()
    }

    handleSaveAndClose = () => {
        this.successCallback = props => {
            props.redirect(url(props.match, 'user-roles'))
        }

        this.save()
    }

    componentDidMount() {
        this.props.match.params.userRoleID && this.props.actions.fetchUserRole(this.props.match.params.userRoleID)
        this.props.actions.fetchPrerequisites()
    }

    componentWillReceiveProps(nextProps) {
        handleCommonEditActions(this.props, nextProps, this.successCallback)

        if (nextProps.userRole && (JSON.stringify(nextProps.userRole) !== JSON.stringify(this.props.userRole) || !this.state.values.id)) {
            const newValues = nextProps.userRole.toJS()

            newValues.user_permission_ids.forEach(userPermissionID => (newValues[`user_permission_${userPermissionID}`] = true))

            this.setValues(newValues)
        }
    }

    render() {
        document.title = formatters.titleFormatter(this.props.intl.formatMessage({ id: 'modules.userRoles.heading' }))

        const groupNames = [
            'transports',
            'transports_cost',
            'transports_log',
            'transports_customer',
            'transports_customer_cost',
            'transports_spedition',
            'transports_spedition_cost',
            'transports_routes',
            'transports_goods',
            'transports_parts',
            'transports_files',
            'transports_allowances',
            'transports_scoring',
            'transports_events',
            'transport_to_vehicle',
            'transports_template',
            'transports_template_cost',
            'transports_template_customer',
            'transports_template_customer_cost',
            'transports_template_routes',
            'transports_template_allowances',
            'transporeon_offers',
            'tracing',
            'board',
            'here_map',
            'report_transports',
            'report_spedition_transports',
            'report_transport_changes',
            'report_transport_invoicing',
            'report_transport_orders',
            'report_vehicle_kilometers_total',
            'report_vehicle_traffic',
            'report_vehicle_performance',
            'report_vehicle_checks',
            'report_vehicle_fuelings',
            'report_vehicle_profits',
            'report_vehicle_results',
            'report_driver_allowances',
            'report_driver_performance_analysis',
            'report_driver_hourly_overview',
            'report_driver_timesheets',
            'report_driver_results',
            'report_driver_costs',
            'report_trailer_results',
            'report_trailer_costs',
            'report_vehicle_costs',
            'report_cost_center_costs',
            'report_cost_center_profits',
            'report_customer_results',
            'report_customer_plans',
            'report_customer_weekly_plans',
            'report_customer_monthly_plans',
            'report_carrier_results',
            'report_carrier_scoring',
            'report_carrier_usage',
            'report_dispatcher_kpi',
            'report_cost_center_results',
            'report_cost_center_traffic',
            'report_cost_center_performance',
            'report_customer_turnovers',
            'report_empty_kilometers',
            'report_pallets',
            'report_cost_type_costs',
            'invoicing_orders',
            'invoicing_invoices',
            'invoicing_received_invoices',
            'report_invoicing_summary',
            'vehicles',
            'vehicle_history',
            'vehicle_documents',
            'vehicle_equipments',
            'vehicle_events',
            'vehicle_fuelings',
            'trailers',
            'trailer_history',
            'trailer_documents',
            'trailer_equipments',
            'trailer_events',
            'drivers',
            'driver_history',
            'driver_documents',
            'driver_equipments',
            'driver_events',
            'driver_allowances',
            'driver_timesheets',
            'customers',
            'customer_addresses',
            'customer_contacts',
            'customer_plans',
            'addresses',
            'carriers',
            'carrier_contacts',
            'border_crossings',
            'transport_conditions',
            'transport_types',
            'cargo_types',
            'goods_types',
            'packaging_types',
            'surcharge_types',
            'transport_scoring',
            'tax_rates',
            'payment_methods',
            'invoice_item_types',
            'vehicle_types',
            'trailer_types',
            'document_types',
            'equipments',
            'companies',
            'company_cost_centers',
            'company_bank_accounts',
            'company_default_bank_accounts',
            'company_numberings',
            'countries',
            'currencies',
            'customer_address_types',
            'carrier_scoring',
            'users',
            'users_login_log',
            'user_roles',
            'user_logins',
            'blocked_ips',
        ]

        const permSort = ['read', 'create', 'update', 'delete', 'export', 'restore']

        const allUserPermissions = getPrerequisitesArray(this.props.prerequisites.values.get('user_permissions'), 'id', 'identifier')
        const groupedPermissions = {}

        allUserPermissions.forEach(permission => {
            const groupSplit = permission.name.split('.')
            const groupName = groupSplit[0]
            const groupPerm = groupSplit[1]

            if (!groupedPermissions.hasOwnProperty(groupName)) {
                groupedPermissions[groupName] = []
            }

            const groupPermId = permSort.includes(groupPerm)
                ? permSort.indexOf(groupPerm)
                : Math.max(permSort.length, groupedPermissions[groupName].length)

            groupedPermissions[groupName][groupPermId] = permission
        })

        return (
            <PermissionsCheck hasAny={Object.values(PERMS)} noPermissionsPage>
                <div className="page-inner">
                    <div id="main-wrapper">
                        <div className="row">
                            <div className="col-md-10 col-md-offset-1 col-lg-8 col-lg-offset-2 col-xl-6 col-xl-offset-3">
                                <div className="panel panel-white">
                                    <div className="panel-body panel-padding">
                                        <div className="panel-head">
                                            <h4>
                                                <FormattedMessage id="modules.userRoles.heading" /> -{' '}
                                                {this.props.userRole ? (
                                                    <FormattedMessage id="fields.itemEdit" />
                                                ) : (
                                                    <FormattedMessage id="fields.itemCreate" />
                                                )}
                                            </h4>
                                        </div>
                                        <LoadingOverlay active={this.props.prerequisites.status.get('fetching') || this.props.status.get('fetching')}>
                                            <PermissionsCheck hasAny={Object.values(PERMS)}>
                                                <Form
                                                    values={this.state.values}
                                                    validationRules={this.validationRules}
                                                    onChange={this.handleChangeValues}
                                                    onSubmit={this.handleSubmit}
                                                    isEdit={Boolean(this.props.userRole)}
                                                >
                                                    <InputField
                                                        id="name"
                                                        label={this.props.intl.formatMessage({ id: 'fields.name' })}
                                                        className="wp-50"
                                                    />

                                                    <div>
                                                        {groupNames.map(group => {
                                                            //console.log(group, groupedPermissions[group])
                                                            if (!groupedPermissions[group]) {
                                                                return null
                                                            }

                                                            return (
                                                                <div key={`group_${group}`} className="p-v-sm b-t">
                                                                    <h5>{this.props.intl.formatMessage({ id: `permissionGroups.${group}` })}</h5>
                                                                    <div className="row">
                                                                        {groupedPermissions[group].map(userPermission => (
                                                                            <div
                                                                                key={`permission-${userPermission.name}`}
                                                                                className="col-sm-6 col-md-4 col-lg-3 compact-checkboxes"
                                                                            >
                                                                                <CheckboxField
                                                                                    id={`user_permission_${userPermission.name}`}
                                                                                    label={this.props.intl.formatMessage({
                                                                                        id: `permissions.${userPermission.name.replace(
                                                                                            /^(.+?\.){1}/iu,
                                                                                            ''
                                                                                        )}`,
                                                                                    })}
                                                                                />
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                </div>
                                                            )
                                                        })}

                                                        {Object.keys(groupedPermissions).map(group => {
                                                            if (groupNames.indexOf(group) !== -1) {
                                                                return null
                                                            }

                                                            return (
                                                                <div key={`group_${group}`} className="p-v-sm b-t">
                                                                    <h5>{this.props.intl.formatMessage({ id: `permissionGroups.${group}` })}</h5>
                                                                    <div className="row">
                                                                        {groupedPermissions[group].map(userPermission => (
                                                                            <div
                                                                                key={`permission-${userPermission.name}`}
                                                                                className="col-sm-6 col-md-4 col-lg-3 compact-checkboxes"
                                                                            >
                                                                                <CheckboxField
                                                                                    id={`user_permission_${userPermission.name}`}
                                                                                    label={this.props.intl.formatMessage({
                                                                                        id: `permissions.${userPermission.name.replace(
                                                                                            // eslint-disable-next-line require-unicode-regexp
                                                                                            /^(.+?\.){1}/i,
                                                                                            ''
                                                                                        )}`,
                                                                                    })}
                                                                                />
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                </div>
                                                            )
                                                        })}
                                                    </div>

                                                    <div className="btns-form">
                                                        <Link to={url(this.props.match, 'user-roles')} className="btn btn-addon btn-default">
                                                            <i className="far fa-chevron-left" /> <FormattedMessage id="buttons.back" />
                                                        </Link>
                                                        {this.props.userRole && (
                                                            <SubmitButton
                                                                saveAndClose
                                                                onClick={this.handleSaveAndClose}
                                                                isEdit={Boolean(this.props.userRole)}
                                                                perms={[PERMS.CREATE, PERMS.UPDATE]}
                                                                className="btn-addon pull-right"
                                                            />
                                                        )}
                                                        <SubmitButton
                                                            type="button"
                                                            onClick={this.handleSave}
                                                            isEdit={Boolean(this.props.userRole)}
                                                            perms={[PERMS.CREATE, PERMS.UPDATE]}
                                                            className="btn-addon pull-right m-r-xs"
                                                        />
                                                    </div>
                                                </Form>
                                            </PermissionsCheck>
                                        </LoadingOverlay>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </PermissionsCheck>
        )
    }
}

function mapStateToProps(state, ownProps) {
    return {
        userRole: createGetUserRoleByIDSelector(ownProps.match.params.userRoleID)(state),
        status: createGetCurrentUserRoleStatusSelector()(state),
        prerequisites: preparePrerequisitesFetchStatusSelectors(componentIdentifier, prerequisites, state),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            ...bindActionCreators(
                {
                    ...userRolesActionCreators,
                    ...preparePrerequisitesActionCreators(componentIdentifier, prerequisites),
                },
                dispatch
            ),
        },
        redirect: toURL => dispatch(push(toURL)),
        notify: (notification, type) => dispatch(Notifications.show(notification, type)),
    }
}

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(UserRolesEdit)
)
