import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { FormattedMessage, injectIntl } from 'react-intl'

import { SimpleSelectField } from '../../../common/form'
import * as localeActionCreators from '../actionCreators'
import { getCurrentLocaleSelector } from '../selectors'

class ChangeLocale extends Component {
    handleLocaleChange = e => {
        this.props.actions.changeLocale(e.target.value)
    }

    render() {
        return (
            <div className="page-inner">
                <div className="page-title">
                    <h3>
                        <FormattedMessage id="locale.ChangeLocale.heading" />
                    </h3>
                </div>
                <div id="main-wrapper">
                    <div className="row">
                        <div className="col-md-8 col-md-offset-2 col-lg-6 col-lg-offset-3 col-xl-4 col-xl-offset-4">
                            <div className="panel panel-white">
                                <div className="panel-heading clearfix">
                                    <h4 className="panel-title">
                                        <FormattedMessage id="locale.ChangeLocale.title" />
                                    </h4>
                                </div>
                                <div className="panel-body">
                                    <label htmlFor="locales">
                                        <FormattedMessage id="locale.ChangeLocale.language" />:
                                    </label>
                                    &nbsp;&nbsp;&nbsp;
                                    <SimpleSelectField
                                        id="locales"
                                        onChange={e => {
                                            this.handleLocaleChange(e)
                                        }}
                                        values={[
                                            { id: 'cs', name: this.props.intl.formatMessage({ id: 'locale.ChangeLocale.languageSelect.czech' }) },
                                            { id: 'en', name: this.props.intl.formatMessage({ id: 'locale.ChangeLocale.languageSelect.english' }) },
                                        ]}
                                        value={this.props.currentLocale}
                                        className="form-control wp-50"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    currentLocale: getCurrentLocaleSelector(state),
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(localeActionCreators, dispatch),
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ChangeLocale))
