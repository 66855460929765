import React from 'react'
import { Route, Switch } from 'react-router'
import DriversList from './DriversList'
import DriversEdit from './DriversEdit'
import DriversHistory from './DriversHistory'

const Drivers = props => (
    <Switch>
        <Route exact path={props.match.url} component={DriversList} />
        <Route exact path={`${props.match.url}/new`} component={DriversEdit} />
        <Route exact path={`${props.match.url}/history`} component={DriversHistory} />
        <Route exact path={`${props.match.url}/:driverID`} component={DriversEdit} />
    </Switch>
)

export default Drivers
