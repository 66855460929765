import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { push } from 'react-router-redux'
import ReactPaginate from 'react-paginate'
import Notifications from 'react-notification-system-redux'

import { ConfirmDialog } from '../../../common/confirm_dialog'
import * as formatters from '../../../common/formatters'
import { url, handleCommonListActions, getListItemsCountMessage, getPrerequisitesArray } from '../../../common/helpers'

import { prepareTableActionCreators } from '../../../common/table/actionCreators'
import { createTableDataSelector, createTableModelSelector } from '../../../common/table/selectors'
import { TableModelColumn, TableModelColumnFilteringType } from '../../../common/table/model'
import { TableFilterInputField, TableFilterSelectField, TableFilterBooleanField } from '../../../common/table/components'
import { getActiveFilters, getTotalNumberOfPages } from '../../../common/table/helpers'

import { preparePrerequisitesActionCreators } from '../../../common/prerequisites/actionCreators'
import { preparePrerequisitesFetchStatusSelectors } from '../../../common/prerequisites/selectors'

import * as vehicleEventsActionCreators from '../actionCreators'
import { getVehicleEvents, getVehicleEventDeleting, getVehicleEventDeleteError } from '../selectors'
import { createGetCountryByIDSelector } from '../../countries/selectors'
import { createGetDriverByIDSelector } from '../../drivers/selectors'
import { createGetTrailerByIDSelector } from '../../trailers/selectors'
import { createGetTrailerTypeByIDSelector } from '../../trailer_types/selectors'
import { createGetTransportByIDSelector } from '../../transports/selectors'
import { createGetUserByIDSelector } from '../../users/selectors'
import { createGetVehicleByIDSelector } from '../../vehicles/selectors'

import VehicleEventsListRow from './VehicleEventsListRow'

import * as constants from '../constants'
import PermissionsCheck from '../../auth/components/PermissionsCheck'
import { PERMS } from '../permissions'

const tableIdentifier = 'vehicle_events_list'
const clientSideItemsPerPage = undefined

const componentIdentifier = 'vehicle_events_list'
const prerequisites = ['dispatchers', 'drivers']

const columns = {
    datetime_start: new TableModelColumn({}),
    datetime_end: new TableModelColumn({}),
}

class VehicleEventsList extends Component {
    vehicleEventTypesPairs = {}

    state = {
        confirmDialog: {
            show: false,
            title: '',
            message: '',
            labelCancel: '',
            labelAccept: '',
            classNameAccept: 'text-success',
            classNameCancel: 'text-default',
            onCancel: () => {},
            onAccept: () => {},
        },
    }

    // confirm dialog
    showConfirmDialog = options => {
        this.setState({
            confirmDialog: {
                show: true,
                title: options.title || this.props.intl.formatMessage({ id: 'confirmDialog.default.title' }),
                message: options.message || this.props.intl.formatMessage({ id: 'confirmDialog.default.message' }),
                labelCancel: options.labelCancel || this.props.intl.formatMessage({ id: 'confirmDialog.default.cancel' }),
                labelAccept: options.labelAccept || this.props.intl.formatMessage({ id: 'confirmDialog.default.accept' }),
                classNameAccept: options.classNameAccept || 'btn-success',
                classNameCancel: options.classNameCancel || 'btn-default',
                onCancel: options.onCancel || this.hideConfirmDialog,
                onAccept: options.onAccept || this.hideConfirmDialog,
            },
        })
    }

    hideConfirmDialog = () => {
        this.setState({
            confirmDialog: {
                show: false,
                onCancel: () => {},
                onAccept: () => {},
            },
        })
    }

    // handlers
    onClick = itemId => {
        this.props.redirect(`/vehicle-events/${itemId}`)
    }

    onDelete = itemId => {
        this.showConfirmDialog({
            title: this.props.intl.formatMessage({ id: 'confirmDialog.delete.title' }),
            message: this.props.intl.formatMessage({ id: 'confirmDialog.delete.message' }),
            labelCancel: this.props.intl.formatMessage({ id: 'confirmDialog.delete.cancel' }),
            labelAccept: this.props.intl.formatMessage({ id: 'confirmDialog.delete.accept' }),
            classNameAccept: 'btn-danger',
            onAccept: () => {
                this.props.actions.deleteVehicleEvent(itemId)
                this.hideConfirmDialog()
            },
        })
    }

    refresh = () => {
        this.props.actions.fetchVehicleEvents()
    }

    resetFilters = () => {
        this.props.table.resetFilters()
    }

    isFilterActive = () => getActiveFilters(this.props.tableModel).size > 0

    componentDidMount() {
        this.props.actions.fetchVehicleEvents()
        this.props.actions.fetchPrerequisites()

        constants.VEHICLE_EVENT_TYPES.forEach(item => {
            this.vehicleEventTypesPairs[item.id] = this.props.intl.formatMessage({ id: item.name })
        })
    }

    componentWillReceiveProps(nextProps) {
        handleCommonListActions(this.props, nextProps)
    }

    render() {
        document.title = formatters.titleFormatter(this.props.intl.formatMessage({ id: 'modules.vehicleEvents.heading' }))

        const filters = this.props.tableModel.get('filters')

        const vehicleEventTypesValues = constants.VEHICLE_EVENT_TYPES.map(item => ({
            id: item.id,
            name: this.props.intl.formatMessage({ id: item.name }),
        }))

        return (
            <PermissionsCheck hasAny={Object.values(PERMS)} noPermissionsPage>
                <div className="page-inner">
                    <div id="main-wrapper">
                        <div className="panel panel-white">
                            <div className="panel-body">
                                <h4>
                                    <FormattedMessage id="modules.vehicleEvents.heading" />
                                </h4>

                                <div className="btns-list">
                                    {this.isFilterActive() > 0 && (
                                        <button className="btn btn-default btn-addon m-r-xs" onClick={this.resetFilters}>
                                            <i className="far fa-times" /> <FormattedMessage id="buttons.resetFilters" />
                                        </button>
                                    )}
                                    <button className="btn btn-default btn-addon m-r-xs" onClick={this.refresh}>
                                        <i className="far fa-sync-alt" /> <FormattedMessage id="buttons.refresh" />
                                    </button>
                                    <PermissionsCheck has={[PERMS.CREATE]}>
                                        <Link to={url(this.props.match, 'vehicle-events/new')} className="btn btn-addon btn-success">
                                            <i className="far fa-plus" /> <FormattedMessage id="buttons.createItem" />
                                        </Link>
                                    </PermissionsCheck>
                                </div>

                                <div className="table-container">
                                    <table className="table table-striped table-hover table-fixed-header">
                                        <thead>
                                            <tr>
                                                <PermissionsCheck hasAny={[PERMS.UPDATE, PERMS.DELETE]}>
                                                    <th className="w-60" />
                                                </PermissionsCheck>
                                                <th className="w-120">
                                                    <FormattedMessage id="fields.from" />
                                                </th>
                                                <th className="w-120">
                                                    <FormattedMessage id="fields.to" />
                                                </th>
                                                <th className="w-140">
                                                    <FormattedMessage id="fields.vehicle" />
                                                </th>
                                                <th className="w-140">
                                                    <FormattedMessage id="fields.trailer" />
                                                </th>
                                                <th className="w-120">
                                                    <FormattedMessage id="fields.driver" />
                                                </th>
                                                <th className="w-120">
                                                    <FormattedMessage id="fields.dispatcher" />
                                                </th>
                                                <th className="w-80">
                                                    <FormattedMessage id="fields.transport" />
                                                </th>
                                                <th className="w-200">
                                                    <FormattedMessage id="fields.eventType" />
                                                </th>
                                                <th className="w-max wm-300">
                                                    <FormattedMessage id="fields.address" />
                                                </th>
                                                <th className="w-70 text-center">
                                                    <small>
                                                        <FormattedMessage id="fields.trailerLoaded" />
                                                    </small>
                                                </th>
                                                <th className="w-70 text-center">
                                                    <small>
                                                        <FormattedMessage id="fields.trailerDamaged" />
                                                    </small>
                                                </th>
                                                <th className="w-70 text-center">
                                                    <small>
                                                        <FormattedMessage id="fields.securityDamaged" />
                                                    </small>
                                                </th>
                                                <th className="w-70 text-center">
                                                    <small>
                                                        <FormattedMessage id="fields.coolingDamaged" />
                                                    </small>
                                                </th>
                                            </tr>
                                            <tr className="filters">
                                                <PermissionsCheck hasAny={[PERMS.UPDATE, PERMS.DELETE]}>
                                                    <th className="w-60" />
                                                </PermissionsCheck>
                                                <th className="w-120" />
                                                <th className="w-120" />
                                                <th className="w-140">
                                                    <TableFilterInputField
                                                        identifier="vehicle"
                                                        type={TableModelColumnFilteringType.string}
                                                        filters={filters}
                                                        onChange={this.props.table.changeFilter}
                                                    />
                                                </th>
                                                <th className="w-140">
                                                    <TableFilterInputField
                                                        identifier="trailer"
                                                        type={TableModelColumnFilteringType.string}
                                                        filters={filters}
                                                        onChange={this.props.table.changeFilter}
                                                    />
                                                </th>
                                                <th className="w-120">
                                                    <TableFilterSelectField
                                                        identifier="driver_id"
                                                        type={TableModelColumnFilteringType.equal}
                                                        filters={filters}
                                                        onChange={this.props.table.changeFilter}
                                                        values={getPrerequisitesArray(this.props.prerequisites.values.get('drivers'))}
                                                    />
                                                </th>
                                                <th className="w-120">
                                                    <TableFilterSelectField
                                                        identifier="dispatcher_id"
                                                        type={TableModelColumnFilteringType.equal}
                                                        filters={filters}
                                                        onChange={this.props.table.changeFilter}
                                                        values={getPrerequisitesArray(this.props.prerequisites.values.get('dispatchers'))}
                                                    />
                                                </th>
                                                <th className="w-80">
                                                    <TableFilterInputField
                                                        identifier="transport_id"
                                                        type={TableModelColumnFilteringType.string}
                                                        filters={filters}
                                                        onChange={this.props.table.changeFilter}
                                                    />
                                                </th>
                                                <th className="w-200">
                                                    <TableFilterSelectField
                                                        identifier="type_id"
                                                        type={TableModelColumnFilteringType.equal}
                                                        filters={filters}
                                                        onChange={this.props.table.changeFilter}
                                                        values={vehicleEventTypesValues}
                                                    />
                                                </th>
                                                <th className="w-max wm-300">
                                                    <TableFilterInputField
                                                        identifier="address"
                                                        type={TableModelColumnFilteringType.string}
                                                        filters={filters}
                                                        onChange={this.props.table.changeFilter}
                                                    />
                                                </th>
                                                <th className="w-70">
                                                    <TableFilterBooleanField
                                                        identifier="is_trailer_loaded"
                                                        type={TableModelColumnFilteringType.equal}
                                                        filters={filters}
                                                        onChange={this.props.table.changeFilter}
                                                    />
                                                </th>
                                                <th className="w-70">
                                                    <TableFilterBooleanField
                                                        identifier="is_trailer_damaged"
                                                        type={TableModelColumnFilteringType.equal}
                                                        filters={filters}
                                                        onChange={this.props.table.changeFilter}
                                                    />
                                                </th>
                                                <th className="w-70">
                                                    <TableFilterBooleanField
                                                        identifier="is_trailer_security_damaged"
                                                        type={TableModelColumnFilteringType.equal}
                                                        filters={filters}
                                                        onChange={this.props.table.changeFilter}
                                                    />
                                                </th>
                                                <th className="w-70">
                                                    <TableFilterBooleanField
                                                        identifier="is_trailer_cooling_damaged"
                                                        type={TableModelColumnFilteringType.equal}
                                                        filters={filters}
                                                        onChange={this.props.table.changeFilter}
                                                    />
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.props.vehicleEvents.data.valueSeq().map(row => {
                                                const trailer = this.props.createGetTrailerByIDSelector(row.trailer_id)
                                                const trailerType = trailer && this.props.createGetTrailerTypeByIDSelector(trailer.trailer_type_id)

                                                return (
                                                    <VehicleEventsListRow
                                                        key={`row-${row.id}`}
                                                        data={row}
                                                        onClick={this.onClick}
                                                        onDelete={this.onDelete}
                                                        country={
                                                            row.transport_point &&
                                                            row.transport_point.country_id &&
                                                            this.props.createGetCountryByIDSelector(row.transport_point.country_id)
                                                        }
                                                        dispatcher={this.props.createGetUserByIDSelector(row.dispatcher_id)}
                                                        driver={this.props.createGetDriverByIDSelector(row.driver_id)}
                                                        trailer={trailer}
                                                        trailerType={trailerType}
                                                        transport={this.props.createGetTransportByIDSelector(row.transport_id)}
                                                        vehicle={this.props.createGetVehicleByIDSelector(row.vehicle_id)}
                                                        vehicleEventType={row.type_id && this.vehicleEventTypesPairs[row.type_id]}
                                                    />
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>

                                <div className="pull-left m-l-xxs m-t-md">
                                    <FormattedMessage id="pagination.totalRecords" />:{' '}
                                    {getListItemsCountMessage(
                                        clientSideItemsPerPage,
                                        this.props.vehicleEvents.count,
                                        this.props.tableModel.getIn(['pagination', 'totalCount'])
                                    )}
                                </div>

                                <ReactPaginate
                                    containerClassName="pagination"
                                    breakLabel={<span className="disabled">...</span>}
                                    activeClassName="active"
                                    pageCount={getTotalNumberOfPages(this.props.tableModel, this.props.vehicleEvents.count, clientSideItemsPerPage)}
                                    pageRangeDisplayed={10}
                                    marginPagesDisplayed={2}
                                    forcePage={this.props.tableModel.getIn(['pagination', 'current'])}
                                    onPageChange={this.props.table.changePage}
                                    previousLabel={this.props.intl.formatMessage({ id: 'pagination.previous' })}
                                    nextLabel={this.props.intl.formatMessage({ id: 'pagination.next' })}
                                />
                            </div>
                        </div>
                    </div>

                    <ConfirmDialog options={this.state.confirmDialog} />
                </div>
            </PermissionsCheck>
        )
    }
}

function mapStateToProps(state) {
    return {
        vehicleEvents: createTableDataSelector({
            tableIdentifier,
            columns,
            dataSelector: getVehicleEvents,
            clientSideItemsPerPage,
        })(state),
        tableModel: createTableModelSelector(tableIdentifier)(state),
        prerequisites: preparePrerequisitesFetchStatusSelectors(componentIdentifier, prerequisites, state),
        deleting: getVehicleEventDeleting(state),
        deleteError: getVehicleEventDeleteError(state),
        createGetVehicleByIDSelector: id => createGetVehicleByIDSelector(id)(state),
        createGetTrailerByIDSelector: id => createGetTrailerByIDSelector(id)(state),
        createGetDriverByIDSelector: id => createGetDriverByIDSelector(id)(state),
        createGetUserByIDSelector: id => createGetUserByIDSelector(id)(state),
        createGetTransportByIDSelector: id => createGetTransportByIDSelector(id)(state),
        createGetCountryByIDSelector: id => createGetCountryByIDSelector(id)(state),
        createGetTrailerTypeByIDSelector: id => createGetTrailerTypeByIDSelector(id)(state),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            ...bindActionCreators(
                {
                    ...vehicleEventsActionCreators,
                    ...preparePrerequisitesActionCreators(componentIdentifier, prerequisites),
                },
                dispatch
            ),
        },
        notify: (notification, type) => dispatch(Notifications.show(notification, type)),
        redirect: toURL => dispatch(push(toURL)),
        dispatch,
    }
}

function mergeProps(stateProps, dispatchProps, ownProps) {
    return {
        ...stateProps,
        ...dispatchProps,
        ...ownProps,
        table: bindActionCreators(prepareTableActionCreators(tableIdentifier, stateProps.tableModel), dispatchProps.dispatch),
    }
}

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps,
        mergeProps
    )(VehicleEventsList)
)
