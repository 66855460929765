import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import Notifications from 'react-notification-system-redux'
import DatePicker from 'react-datepicker'
import moment from 'moment'

import { Form, SelectField, InputField } from '../../../common/form'
import * as formatters from '../../../common/formatters'
import * as helpers from '../../../common/helpers'
import { LoadingOverlay } from '../../../common/loading_overlay'

import * as constants from '../constants'

import * as actionCreators from '../actionCreators'
import {
    getDataForInvoicing,
    getDataForInvoicingFetching,
    getDataForInvoicingError,
    getCreatingInvoiceInProgress,
    getCreatingInvoiceError,
    getCreatedInvoiceId,
} from '../selectors'

import { preparePrerequisitesFetchStatusSelectors } from '../../../common/prerequisites/selectors'
import { preparePrerequisitesActionCreators } from '../../../common/prerequisites/actionCreators'
import PermissionsCheck from '../../auth/components/PermissionsCheck'
import { PERMS } from '../permissions'

const componentIdentifier = 'create_invoice_form'
const prerequisites = ['invoice_item_types']

class CreateInvoiceForm extends Component {
    state = {
        data: null,
        values: {},
        datetimes: {
            date_issued: moment().format('DD.MM.YYYY'),
            date_due: moment().format('DD.MM.YYYY'),
            date_tax: moment().format('DD.MM.YYYY'),
        },
        items: [],
    }

    validationRules = {}

    fetchDataForInvoicing = transportCustomersIds => {
        this.props.actions.fetchDataForInvoicing(transportCustomersIds)
    }

    setDataForInvoicing = data => {
        const company = data.companies && data.companies[0]
        const taxRate = company && company.country_id && data.tax_rates && data.tax_rates[company.country_id] && data.tax_rates[company.country_id][0]
        const customer = data.customers && data.customers[0]

        const dates = data.items && data.items.map(item => item.unloading_date && moment(item.unloading_date))
        const maxDate = dates && moment.max(dates)
        const days = customer && customer.invoice_deadline_days ? parseInt(customer.invoice_deadline_days) : 30

        const datetimes = {
            ...this.state.datetimes,
            date_due: moment(this.state.datetimes.date_issued, 'DD.MM.YYYY')
                .add(days, 'days')
                .format('DD.MM.YYYY'),
        }

        if (maxDate) {
            datetimes.date_tax = maxDate.format('DD.MM.YYYY')
        }

        this.handleDatetimeChange('date_due')

        this.setState(
            {
                data,
                datetimes,
                items: data.items
                    ? data.items.map(item => ({
                          id: item.id,
                          transport_id: item.transport_id,
                          units_count: item.units_count || 1,
                          unit: item.unit || this.props.intl.formatMessage({ id: 'units.pcs' }),
                          name: item.name,
                          text: item.text,
                          unit_price: item.unit_price,
                          tax_rate_id: taxRate.id,
                          invoice_item_type_id: company && company.default_invoice_item_type_id,
                          loading_is_in_eu: item.loading_is_in_eu,
                          unloading_is_in_eu: item.unloading_is_in_eu,
                      }))
                    : [],
            },
            () => {
                this.handleChangeValues(
                    {
                        company_id: company && company.id,
                        company_cost_center_id: company && company.default_company_cost_center_id,
                        currency_id: data.currencies && data.currencies[0] && data.currencies[0].id,
                        payment_method_id: data.payment_methods && data.payment_methods[0] && data.payment_methods[0].id,
                        customer_id: customer && customer.id,
                        text_before_items_type_id: constants.TEXT_BEFORE_ITEMS_TYPE_ITEMS,
                        note: data.note,
                    },
                    true
                )
            }
        )
    }

    setValues = values => {
        this.setState({
            values,
        })
    }

    handleChangeValues = (values, changeFromDataSet) => {
        const data = this.state.data
        const customerChanged = values.customer_id && String(values.customer_id) !== String(this.state.values.customer_id)
        const companyChanged = values.company_id && String(values.company_id) !== String(this.state.values.company_id)
        const currencyChanged = values.currency_id && String(values.currency_id) !== String(this.state.values.currency_id)

        const customer = data.customers && data.customers.find(item => String(item.id) === String(values.customer_id))
        const company = data.companies && data.companies.find(item => String(item.id) === String(values.company_id))

        // customer changed
        if (customerChanged) {
            const days = customer && customer.invoice_deadline_days ? parseInt(customer.invoice_deadline_days) : 14
            this.setState({
                datetimes: {
                    ...this.state.datetimes,
                    date_due: moment(this.state.datetimes.date_issued, 'DD.MM.YYYY')
                        .add(days, 'days')
                        .format('DD.MM.YYYY'),
                },
            })

            values.customer_invoicing_address_id = customer && customer.invoicing_address_id ? customer.invoicing_address_id : 0
            values.customer_delivery_address_id = customer && customer.delivery_address_id ? customer.delivery_address_id : 0
        }

        // company changed
        if (companyChanged) {
            const numbering = company && company.numberings && company.numberings[0]
            values.numbering_id = numbering ? numbering.id : null

            let costCenter =
                company && company.cost_centers && company.cost_centers.find(item => String(item.id) === String(values.company_cost_center_id))
            if ((!costCenter && company && company.cost_centers) || changeFromDataSet) {
                costCenter = company.default_company_cost_center_id
                    ? company.cost_centers.find(item => String(item.id) === String(company.default_company_cost_center_id))
                    : company.cost_centers[0]
            }
            values.company_cost_center_id = costCenter ? costCenter.id : null

            values.bank_account_id = null
        }

        // customer address changed
        const customerInvoicingAddressChanged =
            String(values.customer_invoicing_address_id) !== String(this.state.values.customer_invoicing_address_id)

        let customerInvoicingAddress =
            customer &&
            customer.invoicing_addresses &&
            customer.invoicing_address_id &&
            customer.invoicing_addresses.find(item => String(item.id) === String(customer.invoicing_address_id))
        if (!customerInvoicingAddress && customer && customer.invoicing_addresses) {
            customerInvoicingAddress = customer.invoicing_addresses.find(item => String(item.id) === String(values.customer_invoicing_address_id))
            if (!customerInvoicingAddress) {
                customerInvoicingAddress = customer.invoicing_addresses[0]
            }
        }

        if ((customerInvoicingAddressChanged || companyChanged) && company && customerInvoicingAddress) {
            if (company.country_id === customerInvoicingAddress.country_id) {
                values.tax_transaction_type_id = constants.TAX_TRANSACTION_TYPE_DOMESTIC
            } else if (company.country_is_in_eu && customerInvoicingAddress.country_is_in_eu) {
                values.tax_transaction_type_id = constants.TAX_TRANSACTION_TYPE_EU
            } else {
                values.tax_transaction_type_id = constants.TAX_TRANSACTION_TYPE_OTHER
            }
        }

        const taxTransactionTypeId = String(values.tax_transaction_type_id)
        const taxTransactionTypeChanged = taxTransactionTypeId !== String(this.state.values.tax_transaction_type_id)

        if (taxTransactionTypeChanged || changeFromDataSet) {
            this.setTaxRateForItems(company, customerInvoicingAddress)
        }

        // currency changed
        if (currencyChanged || companyChanged || customerInvoicingAddressChanged) {
            const bankAccounts = company && company.bank_accounts
            const defaultBankAccounts = company && company.default_bank_accounts

            const possibleAccounts =
                bankAccounts &&
                bankAccounts.filter(
                    item => String(item.currency_id) === String(values.currency_id) && String(item.is_factoring) === String(customer.factoring)
                )

            const defaultBankAccountCustomerTypeKey =
                customerInvoicingAddress && company && customerInvoicingAddress.country_id === company.country_id ? 1 : 2
            const defaultBankAccountCurrencyKey = values.currency_id
            const defaultBankAccountFactoringKey = customer.factoring ? 1 : 2
            var defaultBankAccountId =
                defaultBankAccounts &&
                defaultBankAccounts[defaultBankAccountCustomerTypeKey] &&
                defaultBankAccounts[defaultBankAccountCustomerTypeKey][defaultBankAccountCurrencyKey] &&
                defaultBankAccounts[defaultBankAccountCustomerTypeKey][defaultBankAccountCurrencyKey][defaultBankAccountFactoringKey]
            var defaultBankAccount = bankAccounts && bankAccounts.find(item => String(item.id) === String(defaultBankAccountId))

            if(customer && customer.company_bank_accounts){
                const keys = Object.keys(customer.company_bank_accounts);
                keys.forEach((key, index) => {
                    if(customer.company_bank_accounts[key].currency_id === values.currency_id){
                        var item = bankAccounts.find(item => String(item.id) === String(customer.company_bank_accounts[key].id))
                        if(item){
                            defaultBankAccountId = item.id
                            defaultBankAccount = item
                        }
                    }
                });
            }

            if (!possibleAccounts || !possibleAccounts.length) {
                values.bank_account_id = null
            } else {
                values.bank_account_id = defaultBankAccount ? defaultBankAccount.id : possibleAccounts[0].id
            }
        }

        this.setValues(values)
    }

    handleDatetimeChange = (key, value) => {
        const datetimes = {
            ...this.state.datetimes,
            [key]: value,
        }

        if (key === 'date_issued' && datetimes.date_due) {
            const originalDateIssue = moment(this.state.datetimes.date_issued, 'DD.MM.YYYY')
            const newDateIssue = moment(datetimes.date_issued, 'DD.MM.YYYY')
            const dateDue = moment(datetimes.date_due, 'DD.MM.YYYY')
            const diff = dateDue.diff(originalDateIssue, 'days')

            datetimes.date_due = newDateIssue.add(diff, 'days').format('DD.MM.YYYY')
        }

        this.setState({
            datetimes,
        })
    }

    handleChangeItemValues = (itemKey, name, value) => {
        const items = [...this.state.items]
        items[itemKey] = {
            ...this.state.items[itemKey],
            [name]: value,
        }

        this.setState({
            items,
        })
    }

    handleAddItem = e => {
        e && e.preventDefault()
        e && e.stopPropagation()

        const data = this.state.data
        const values = this.state.values

        let company = values.company_id && data && data.companies && data.companies.find(item => String(item.id) === String(values.company_id))
        if (!company) {
            company = data && data.companies && data.companies[0]
        }

        const taxRates = company && company.country_id && data.tax_rates && data.tax_rates[company.country_id]
        let taxRate = taxRates && taxRates[0]

        if (String(values.tax_transaction_type_id) !== String(constants.TAX_TRANSACTION_TYPE_DOMESTIC)) {
            taxRate = taxRates && taxRates.find(item => !item.percent)
        }

        const items = [...this.state.items]
        items.push({
            id: null,
            transport_id: null,
            units_count: 1,
            unit: this.props.intl.formatMessage({ id: 'units.pcs' }),
            name: '',
            text: '',
            unit_price: 0,
            tax_rate_id: taxRate.id,
            invoice_item_type_id: company && company.default_invoice_item_type_id,
        })

        this.setState({
            items,
        })
    }

    handleRemoveItem = (e, itemKey) => {
        e && e.preventDefault()
        e && e.stopPropagation()

        const items = [...this.state.items]
        items.splice(itemKey, 1)

        this.setState({
            items,
        })
    }

    handleSubmit = () => {
        this.props.actions.createInvoice({
            ...this.state.values,
            ...this.state.datetimes,
            items: this.state.items,
        })
    }

    setTaxRateForItems = (company, customerInvoicingAddress) => {
        const data = this.state.data
        const taxRates = company && company.country_id && data.tax_rates && data.tax_rates[company.country_id]
        const taxRateHigh = taxRates && taxRates[0]
        const taxRateZero = taxRates && taxRates.find(item => !item.percent)

        const newItems = this.state.items.map(item => {
            let taxRateId = taxRateZero.id

            if (company.country_is_in_eu && company.country_id === customerInvoicingAddress.country_id) {
                if ((!item.loading_is_in_eu && !item.unloading_is_in_eu) || (item.loading_is_in_eu && item.unloading_is_in_eu)) {
                    taxRateId = taxRateHigh.id
                }
            }

            return {
                ...item,
                tax_rate_id: taxRateId,
            }
        })

        this.setState({
            items: newItems,
        })
    }

    componentDidMount() {
        this.props.actions.fetchPrerequisites()

        if (this.props.transportCustomersIds) {
            this.fetchDataForInvoicing(this.props.transportCustomersIds)
        }

        if (this.props.dataForInvoicing) {
            this.setDataForInvoicing(this.props.dataForInvoicing)
        }
    }

    componentWillReceiveProps(nextProps) {
        if (JSON.stringify(nextProps.transportCustomersIds) !== JSON.stringify(this.props.transportCustomersIds)) {
            this.fetchDataForInvoicing(nextProps.transportCustomersIds)
        }

        if (JSON.stringify(nextProps.dataForInvoicing) !== JSON.stringify(this.props.dataForInvoicing)) {
            this.setDataForInvoicing(nextProps.dataForInvoicing)
        }

        // Create invoice
        if (this.props.creatingInvoiceInProgress && !nextProps.creatingInvoiceInProgress) {
            if (nextProps.creatingInvoiceError === null) {
                this.props.notify(
                    {
                        title: this.props.intl.formatMessage({ id: 'alerts.titles.success' }),
                        message: this.props.intl.formatMessage({ id: 'alerts.messages.invoiceCreateSuccess' }),
                        position: 'tc',
                    },
                    'success'
                )

                this.props.onInvoiceCreateSuccess && this.props.onInvoiceCreateSuccess(nextProps.createdInvoiceId)
            } else {
                this.props.notify(
                    {
                        title: this.props.intl.formatMessage({ id: 'alerts.titles.error' }),
                        message: nextProps.invoicingStateSettingError,
                        position: 'tc',
                    },
                    'error'
                )
            }
        }
    }

    /* eslint-disable jsx-a11y/anchor-is-valid */
    render() {
        const data = this.state.data
        const invoiceItems = this.state.items

        if (!data) {
            return null
        }

        const company =
            this.state.values.company_id && data.companies && data.companies.find(item => String(item.id) === String(this.state.values.company_id))

        const currency =
            this.state.values.currency_id &&
            data.currencies &&
            data.currencies.find(item => String(item.id) === String(this.state.values.currency_id))

        const customer =
            this.state.values.customer_id && data.customers && data.customers.find(item => String(item.id) === String(this.state.values.customer_id))

        const companiesOptions = data.companies
            ? data.companies.map(item => ({
                  id: item.id,
                  name: item.name,
              }))
            : []

        const costCentersOptions =
            company && company.cost_centers
                ? company.cost_centers.map(item => ({
                      id: item.id,
                      name: `${item.code} - ${item.name}`,
                  }))
                : []

        const bankAccountsOptions =
            company && company.bank_accounts
                ? company.bank_accounts
                      .filter(item => String(item.currency_id) === String(this.state.values.currency_id))
                      .map(item => ({
                          id: item.id,
                          name: item.name,
                      }))
                : []

        const numberingsOptions =
            company && company.numberings
                ? company.numberings.map(item => ({
                      id: item.id,
                      name: `${item.name} [${item.format}]`,
                  }))
                : []

        const taxRatesOptions =
            company && company.country_id && data.tax_rates && data.tax_rates[company.country_id]
                ? data.tax_rates[company.country_id].map(item => ({
                      id: item.id,
                      name: item.percent,
                  }))
                : []

        const taxRatesValues = {}
        company &&
            company.country_id &&
            data.tax_rates &&
            data.tax_rates[company.country_id] &&
            data.tax_rates[company.country_id].forEach(item => {
                taxRatesValues[item.id] = item.percent
            })

        const currenciesOptions = data.currencies
            ? data.currencies.map(item => ({
                  id: item.id,
                  name: item.iso_code,
              }))
            : []

        const paymentMethodsOptions = data.payment_methods
            ? data.payment_methods.map(item => ({
                  id: item.id,
                  name: item.name,
              }))
            : []

        const customersOptions = data.customers
            ? data.customers.map(item => ({
                  id: item.id,
                  name: item.name,
              }))
            : []

        const invoicingAddressesOptions =
            customer && customer.invoicing_addresses
                ? customer.invoicing_addresses.map(item => ({
                      id: item.id,
                      name: item.address,
                  }))
                : []

        const deliveryAddressesOptions =
            customer && customer.delivery_addresses
                ? customer.delivery_addresses.map(item => ({
                      id: item.id,
                      name: item.address,
                  }))
                : []

        const taxTransactionTypesOptions = [
            {
                id: constants.TAX_TRANSACTION_TYPE_DOMESTIC,
                name: this.props.intl.formatMessage({ id: `taxTransactionTypes.${constants.TAX_TRANSACTION_TYPE_DOMESTIC}` }),
            },
            {
                id: constants.TAX_TRANSACTION_TYPE_EU,
                name: this.props.intl.formatMessage({ id: `taxTransactionTypes.${constants.TAX_TRANSACTION_TYPE_EU}` }),
            },
            {
                id: constants.TAX_TRANSACTION_TYPE_OTHER,
                name: this.props.intl.formatMessage({ id: `taxTransactionTypes.${constants.TAX_TRANSACTION_TYPE_OTHER}` }),
            },
            {
                id: constants.TAX_TRANSACTION_TYPE_NOT_SUBJECT_TO_VAT,
                name: this.props.intl.formatMessage({ id: `taxTransactionTypes.${constants.TAX_TRANSACTION_TYPE_NOT_SUBJECT_TO_VAT}` }),
            },
        ]

        const textBeforeItemsTypeOptions = [
            {
                id: constants.TEXT_BEFORE_ITEMS_TYPE_ITEMS,
                name: this.props.intl.formatMessage({ id: `textBeforeItemsTypes.${constants.TEXT_BEFORE_ITEMS_TYPE_ITEMS}` }),
            },
            {
                id: constants.TEXT_BEFORE_ITEMS_TYPE_TRANSPORTS,
                name: this.props.intl.formatMessage({ id: `textBeforeItemsTypes.${constants.TEXT_BEFORE_ITEMS_TYPE_TRANSPORTS}` }),
            }
        ]

        const invoiceItemTypes = helpers.getPrerequisitesArray(this.props.prerequisites.values.get('invoice_item_types'))

        const total = {
            exclVat: 0,
            vat: 0,
            inclVat: 0,
        }
        invoiceItems.forEach(item => {
            const taxRateValue = item.tax_rate_id && taxRatesValues[item.tax_rate_id] ? parseFloat(taxRatesValues[item.tax_rate_id]) : 0
            const exclVat = parseFloat(item.units_count) * parseFloat(item.unit_price)

            if (!isNaN(exclVat)) {
                total.exclVat += exclVat
                total.inclVat += exclVat * (1 + taxRateValue / 100)
            }
        })
        total.vat = total.inclVat - total.exclVat

        return (
            <div>
                <LoadingOverlay
                    active={
                        this.props.prerequisites.status.get('fetching') || this.props.dataForInvoicingFetching || this.props.creatingInvoiceInProgress
                    }
                >
                    <PermissionsCheck has={[PERMS.CREATE]}>
                        <Form
                            values={this.state.values}
                            validationRules={this.validationRules}
                            onChange={this.handleChangeValues}
                            onSubmit={this.handleSubmit}
                        >
                            <div className="row">
                                <div className="col-md-6 b-r">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <SelectField
                                                id="company_id"
                                                label={this.props.intl.formatMessage({ id: 'fields.supplier' })}
                                                values={companiesOptions}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <SelectField
                                                id="company_cost_center_id"
                                                label={this.props.intl.formatMessage({ id: 'fields.costCenter' })}
                                                values={costCentersOptions}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <SelectField
                                                id="numbering_id"
                                                label={this.props.intl.formatMessage({ id: 'fields.numbering' })}
                                                values={numberingsOptions}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <SelectField
                                                id="currency_id"
                                                label={this.props.intl.formatMessage({ id: 'fields.currency' })}
                                                values={currenciesOptions}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <SelectField
                                                id="payment_method_id"
                                                label={this.props.intl.formatMessage({ id: 'fields.paymentMethod' })}
                                                values={paymentMethodsOptions}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <SelectField
                                                id="bank_account_id"
                                                label={this.props.intl.formatMessage({ id: 'fields.bankAccount' })}
                                                values={bankAccountsOptions}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <SelectField
                                                id="tax_transaction_type_id"
                                                label={this.props.intl.formatMessage({ id: 'fields.taxTransactionType' })}
                                                values={taxTransactionTypesOptions}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="form-group w-200">
                                                <label htmlFor="value_date_issued">
                                                    <FormattedMessage id="fields.dateIssue" />
                                                </label>
                                                <DatePicker
                                                    id="value_date_issued"
                                                    dateFormat="DD.MM.YYYY"
                                                    autoComplete="off"
                                                    selected={helpers.convertCSDateToMoment(this.state.datetimes.date_issued)}
                                                    value={this.state.datetimes.date_issued}
                                                    onChange={value => {
                                                        this.handleDatetimeChange('date_issued', value ? formatters.dateFormatter(value) : '')
                                                    }}
                                                    onChangeRaw={e => {
                                                        this.handleDatetimeChange('date_issued', e.target.value)
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group w-200">
                                                <label htmlFor="value_date_due">
                                                    <FormattedMessage id="fields.dateDue" />
                                                </label>
                                                <DatePicker
                                                    id="value_date_due"
                                                    dateFormat="DD.MM.YYYY"
                                                    autoComplete="off"
                                                    selected={helpers.convertCSDateToMoment(this.state.datetimes.date_due)}
                                                    value={this.state.datetimes.date_due}
                                                    onChange={value => {
                                                        this.handleDatetimeChange('date_due', value ? formatters.dateFormatter(value) : '')
                                                    }}
                                                    onChangeRaw={e => {
                                                        this.handleDatetimeChange('date_due', e.target.value)
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group w-200">
                                                <label htmlFor="value_date_tax">
                                                    <FormattedMessage id="fields.dateTax" />
                                                </label>
                                                <DatePicker
                                                    id="value_date_tax"
                                                    dateFormat="DD.MM.YYYY"
                                                    autoComplete="off"
                                                    selected={helpers.convertCSDateToMoment(this.state.datetimes.date_tax)}
                                                    value={this.state.datetimes.date_tax}
                                                    onChange={value => {
                                                        this.handleDatetimeChange('date_tax', value ? formatters.dateFormatter(value) : '')
                                                    }}
                                                    onChangeRaw={e => {
                                                        this.handleDatetimeChange('date_tax', e.target.value)
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <InputField id="note" className="mh-50" label={this.props.intl.formatMessage({ id: 'fields.note' })} autosize />
                                </div>

                                <div className="col-md-6">
                                    <SelectField
                                        id="customer_id"
                                        label={this.props.intl.formatMessage({ id: 'fields.customer' })}
                                        values={customersOptions}
                                    />
                                    <SelectField
                                        id="customer_invoicing_address_id"
                                        label={this.props.intl.formatMessage({ id: 'fields.invoicingAddress' })}
                                        values={invoicingAddressesOptions}
                                    />
                                    <SelectField
                                        id="customer_delivery_address_id"
                                        label={this.props.intl.formatMessage({ id: 'fields.deliveryAddress' })}
                                        values={deliveryAddressesOptions}
                                    />
                                </div>
                            </div>
                            <hr />

                            <div className="row">
                                <div className="col-md-6">
                                    <SelectField
                                        id="text_before_items_type_id"
                                        values={textBeforeItemsTypeOptions}
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-12">
                                    <table className="table table-striped table-hover">
                                        <thead>
                                            <tr>
                                                <th className="w-70">
                                                    <FormattedMessage id="fields.count" />
                                                </th>
                                                <th className="w-70 text-center">
                                                    <FormattedMessage id="fields.unit" />
                                                </th>
                                                <th className="w-max wm-400">
                                                    <FormattedMessage id="fields.item" />
                                                </th>
                                                <th className="w-100 text-right">
                                                    <FormattedMessage id="fields.unitPrice" />
                                                </th>
                                                <th className="w-80">
                                                    <FormattedMessage id="fields.vat" /> %
                                                </th>
                                                <th className="w-100">
                                                    <FormattedMessage id="fields.invoiceItemType" />
                                                </th>
                                                <th className="w-40" />
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {invoiceItems.map((
                                                item,
                                                key // eslint-disable-next-line react/no-array-index-key
                                            ) => (
                                                <tr key={`invoiceItems-${key}`}>
                                                    <td className="text-right">
                                                        <input
                                                            type="text"
                                                            value={item.units_count}
                                                            className="form-control"
                                                            onChange={e => {
                                                                this.handleChangeItemValues(key, 'units_count', e.target.value)
                                                            }}
                                                        />
                                                    </td>
                                                    <td className="text-center">
                                                        <input
                                                            type="text"
                                                            value={item.unit}
                                                            className="form-control text-center"
                                                            onChange={e => {
                                                                this.handleChangeItemValues(key, 'unit', e.target.value)
                                                            }}
                                                        />
                                                    </td>
                                                    <td>
                                                        <input
                                                            type="text"
                                                            value={item.name}
                                                            className="form-control wp-50 m-b-xxs"
                                                            onChange={e => {
                                                                this.handleChangeItemValues(key, 'name', e.target.value)
                                                            }}
                                                        />
                                                        <input
                                                            type="text"
                                                            value={item.text}
                                                            className="form-control wp-100"
                                                            onChange={e => {
                                                                this.handleChangeItemValues(key, 'text', e.target.value)
                                                            }}
                                                        />
                                                    </td>
                                                    <td className="text-right">
                                                        <input
                                                            type="text"
                                                            value={item.unit_price}
                                                            className="form-control text-right"
                                                            onChange={e => {
                                                                this.handleChangeItemValues(key, 'unit_price', e.target.value)
                                                            }}
                                                        />
                                                    </td>
                                                    <td>
                                                        <select
                                                            value={item.tax_rate_id}
                                                            className="form-control"
                                                            onChange={e => {
                                                                this.handleChangeItemValues(key, 'tax_rate_id', e.target.value)
                                                            }}
                                                        >
                                                            {taxRatesOptions.map((
                                                                option // eslint-disable-next-line react/no-array-index-key
                                                            ) => (
                                                                <option key={`taxRatesOptions-${key}-${option.id}`} value={option.id}>
                                                                    {option.name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </td>
                                                    <td>
                                                        <select
                                                            value={item.invoice_item_type_id}
                                                            className="form-control"
                                                            onChange={e => {
                                                                this.handleChangeItemValues(key, 'invoice_item_type_id', e.target.value)
                                                            }}
                                                        >
                                                            {invoiceItemTypes.map((
                                                                option // eslint-disable-next-line react/no-array-index-key
                                                            ) => (
                                                                <option key={`invoiceItemTypes-${key}-${option.id}`} value={option.id}>
                                                                    {option.name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </td>
                                                    <td className="text-center">
                                                        <a
                                                            href="#"
                                                            className="display-inline m-t-xxs text-gray-light text-md"
                                                            onClick={e => {
                                                                this.handleRemoveItem(e, key)
                                                            }}
                                                        >
                                                            <i className="far fa-trash" />
                                                        </a>
                                                    </td>
                                                </tr>
                                            ))}

                                            <tr className="transparent">
                                                <td colSpan="3" className="text-right">
                                                    <strong>
                                                        <FormattedMessage id="fields.totalExclVat" />:
                                                    </strong>
                                                </td>
                                                <td colSpan="2" className="text-right">
                                                    <strong>
                                                        {formatters.priceFormatter(total.exclVat, '0,0.00', currency ? currency.iso_code : '')}
                                                    </strong>
                                                </td>
                                                <td />
                                                <td />
                                            </tr>
                                            <tr className="transparent">
                                                <td colSpan="3" className="text-right">
                                                    <strong>
                                                        <FormattedMessage id="fields.vat" />:
                                                    </strong>
                                                </td>
                                                <td colSpan="2" className="text-right">
                                                    <strong>
                                                        {formatters.priceFormatter(total.vat, '0,0.00', currency ? currency.iso_code : '')}
                                                    </strong>
                                                </td>
                                                <td />
                                                <td />
                                            </tr>
                                            <tr className="transparent">
                                                <td colSpan="3" className="text-right">
                                                    <strong>
                                                        <FormattedMessage id="fields.totalInclVat" />:
                                                    </strong>
                                                </td>
                                                <td colSpan="2" className="text-right">
                                                    <strong>
                                                        {formatters.priceFormatter(total.inclVat, '0,0.00', currency ? currency.iso_code : '')}
                                                    </strong>
                                                </td>
                                                <td />
                                                <td />
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <button
                                                className="btn btn-default btn-addon"
                                                onClick={e => {
                                                    this.handleAddItem(e)
                                                }}
                                            >
                                                <i className="far fa-plus" /> <FormattedMessage id="buttons.addItem" />
                                            </button>
                                        </div>
                                        <div className="col-md-6 text-right">
                                            <button type="submit" className="btn btn-success btn-addon">
                                                <i className="far fa-check" /> <FormattedMessage id="buttons.createInvoice" />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    </PermissionsCheck>
                </LoadingOverlay>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        dataForInvoicing: getDataForInvoicing(state),
        dataForInvoicingFetching: getDataForInvoicingFetching(state),
        dataForInvoicingError: getDataForInvoicingError(state),
        creatingInvoiceInProgress: getCreatingInvoiceInProgress(state),
        creatingInvoiceError: getCreatingInvoiceError(state),
        createdInvoiceId: getCreatedInvoiceId(state),
        prerequisites: preparePrerequisitesFetchStatusSelectors(componentIdentifier, prerequisites, state),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            ...bindActionCreators(
                {
                    ...actionCreators,
                    ...preparePrerequisitesActionCreators(componentIdentifier, prerequisites),
                },
                dispatch
            ),
        },
        notify: (notification, type) => dispatch(Notifications.show(notification, type)),
        dispatch,
    }
}

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(CreateInvoiceForm)
)
