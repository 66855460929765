import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { push } from 'react-router-redux'
import TextareaAutosize from 'react-autosize-textarea'
import ReactPaginate from 'react-paginate'
import Notifications from 'react-notification-system-redux'
import { Modal, Tooltip, OverlayTrigger } from 'react-bootstrap'
import Dropzone from 'react-dropzone'
import Select from 'react-select'
import 'react-select/dist/react-select.css'
import { Set, Map } from 'immutable'

import * as constants from '../constants'

import { VariableAssignment } from '../../../common/variable_assignment'

import { LoadingOverlay } from '../../../common/loading_overlay'
import { ConfirmDialog } from '../../../common/confirm_dialog'
import * as formatters from '../../../common/formatters'
import { url, handleCommonListActions, getListItemsCountMessage, getPrerequisitesArray } from '../../../common/helpers'

import { prepareTableActionCreators } from '../../../common/table/actionCreators'
import { createTableDataSelector, createTableModelSelector } from '../../../common/table/selectors'
import { TableModelColumn, TableModelColumnFilteringType } from '../../../common/table/model'
import { TableFilterInputField, TableFilterSelectField } from '../../../common/table/components'
import { getActiveFilters, getTotalNumberOfPages } from '../../../common/table/helpers'

import { preparePrerequisitesActionCreators } from '../../../common/prerequisites/actionCreators'
import { preparePrerequisitesFetchStatusSelectors } from '../../../common/prerequisites/selectors'

import * as vehicleFuelingsActionCreators from '../actionCreators'
import * as selectors from '../selectors'
import { createGetCountryByIDSelector } from '../../countries/selectors'
import { createGetDriverByIDSelector } from '../../drivers/selectors'
import { createGetVehicleByIDSelector } from '../../vehicles/selectors'
import { createGetTrailerByIDSelector } from '../../trailers/selectors'

import { DispatchersBox } from '../../../common/colored_box'
import VehicleFuelingsListRow from './VehicleFuelingsListRow'

import PermissionsCheck from '../../auth/components/PermissionsCheck'
import { PERMS } from '../permissions'

const tableIdentifier = 'vehicle_fuelings_list'
const clientSideItemsPerPage = undefined

const componentIdentifier = 'vehicle_fuelings_list'
const prerequisites = ['drivers', 'countries']

const columns = {
    datetime: new TableModelColumn({}),
}

function Infolog(props) {
    const infolog = props.infolog
    if (infolog === 'fromFile') {
        return <i className="far fa-check-circle text-success m-r-xxs" />
    }
    let className = 'far fa-times-circle text-danger'
    if (['fromPoint', 'fromLastPoint', 'notFoundObject', 'timeNotFound', 'empty'].includes(infolog)) {
        className = 'far fa-question-circle text-orange'
    } else if (['format'].includes(infolog)) {
        className = 'far fa-exclamation-circle text-danger'
    }
    const overlay = (
        <Tooltip id="infolog-tooltip">
            <FormattedMessage id={`modules.vehicleFuelings.infolog.${infolog}`} />
        </Tooltip>
    )
    return (
        <OverlayTrigger placement="top" overlay={overlay}>
            <i className={`${className} m-r-xxs`} />
        </OverlayTrigger>
    )
}

class VehicleFuelingsList extends Component {
    defaultModalImport = {
        step: 1,
        show: false,
        pairs: Map(),
        vehicleFuelingCompanyId: null,
        items: Map(),
        dateFormat: constants.DATE_FORMATS[0].value,
        timeFormat: constants.TIME_FORMATS[0].value,
        description: null,
    }

    defaultModalImportProducts = {
        step: 1,
        show: false,
        pairs: Map(),
        vehicleFuelingCompanyName: '',
    }

    state = {
        confirmDialog: {
            show: false,
            title: '',
            message: '',
            labelCancel: '',
            labelAccept: '',
            classNameAccept: 'text-success',
            classNameCancel: 'text-default',
            onCancel: () => {},
            onAccept: () => {},
        },
        modalImportHistory: {
            show: false,
        },
        modalImport: { ...this.defaultModalImport },
        modalImportProducts: { ...this.defaultModalImportProducts },
    }

    // confirm dialog
    showConfirmDialog = options => {
        this.setState({
            confirmDialog: {
                show: true,
                title: options.title || this.props.intl.formatMessage({ id: 'confirmDialog.default.title' }),
                message: options.message || this.props.intl.formatMessage({ id: 'confirmDialog.default.message' }),
                labelCancel: options.labelCancel || this.props.intl.formatMessage({ id: 'confirmDialog.default.cancel' }),
                labelAccept: options.labelAccept || this.props.intl.formatMessage({ id: 'confirmDialog.default.accept' }),
                classNameAccept: options.classNameAccept || 'btn-success',
                classNameCancel: options.classNameCancel || 'btn-default',
                onCancel: options.onCancel || this.hideConfirmDialog,
                onAccept: options.onAccept || this.hideConfirmDialog,
            },
        })
    }

    hideConfirmDialog = () => {
        this.setState({
            confirmDialog: {
                show: false,
                onCancel: () => {},
                onAccept: () => {},
            },
        })
    }

    // handlers
    onClick = itemId => {
        this.props.redirect(`/vehicle-fuelings/${itemId}`)
    }

    onDelete = itemId => {
        this.showConfirmDialog({
            title: this.props.intl.formatMessage({ id: 'confirmDialog.delete.title' }),
            message: this.props.intl.formatMessage({ id: 'confirmDialog.delete.message' }),
            labelCancel: this.props.intl.formatMessage({ id: 'confirmDialog.delete.cancel' }),
            labelAccept: this.props.intl.formatMessage({ id: 'confirmDialog.delete.accept' }),
            classNameAccept: 'btn-danger',
            onAccept: () => {
                this.props.actions.deleteVehicleFueling(itemId)
                this.hideConfirmDialog()
            },
        })
    }

    refresh = () => {
        this.props.actions.fetchVehicleFuelings()
    }

    resetFilters = () => {
        this.props.table.resetFilters()
    }

    isFilterActive = () => getActiveFilters(this.props.tableModel).size > 0

    // Import history
    handleShowImportHistory = () => {
        this.props.actions.fetchImports()
        const modalImportHistory = { ...this.state.modalImportHistory, show: true }
        this.setState({ modalImportHistory })
    }

    handleHideImportHistory = () => {
        const modalImportHistory = { ...this.state.modalImportHistory, show: false }
        this.setState({ modalImportHistory })
    }

    onDeleteImport = importId => {
        this.showConfirmDialog({
            title: this.props.intl.formatMessage({ id: 'confirmDialog.delete.title' }),
            message: this.props.intl.formatMessage({ id: 'confirmDialog.delete.message' }),
            labelCancel: this.props.intl.formatMessage({ id: 'confirmDialog.delete.cancel' }),
            labelAccept: this.props.intl.formatMessage({ id: 'confirmDialog.delete.accept' }),
            classNameAccept: 'btn-danger',
            onAccept: () => {
                this.props.actions.deleteImport(importId)
                this.hideConfirmDialog()
            },
        })
    }

    // Import
    handleShowImport = e => {
        e && e.preventDefault()
        this.props.actions.clearImport()
        this.props.actions.clearProducts()

        const modalImport = { ...this.defaultModalImport, show: true }
        this.setState({ modalImport })
    }

    handleHideImport = e => {
        e && e.preventDefault()

        this.setState({ modalImport: this.defaultModalImport })
    }

    handlePairingChange = pairs => {
        const modalImport = { ...this.state.modalImport, pairs }
        this.setState({ modalImport })
    }

    handleItemsChange = items => {
        const modalImport = { ...this.state.modalImport, items }
        this.setState({ modalImport })
    }

    handleFuelingCompanyChange = item => {
        const modalImport = { ...this.state.modalImport, vehicleFuelingCompanyId: item.value }
        this.setState({ modalImport })
        this.props.actions.fetchProducts(item.value)
    }

    setDateFormat = dateFormat => {
        const modalImport = { ...this.state.modalImport, dateFormat: dateFormat.value }
        this.setState({ modalImport })
    }

    setTimeFormat = timeFormat => {
        const modalImport = { ...this.state.modalImport, timeFormat: timeFormat.value }
        this.setState({ modalImport })
    }

    handleImportDescriptionChange = e => {
        const modalImport = { ...this.state.modalImport, description: e.target.value }
        this.setState({ modalImport })
    }

    setImportStep = step => {
        const modalImport = { ...this.state.modalImport, step }
        this.setState({ modalImport })
    }

    // Import step 1
    onDropImport = files => {
        files.forEach(file => {
            const reader = new FileReader()
            const fileName = file.name

            reader.onload = e => {
                const loadedFile = {
                    name: fileName,
                    base64: e.target.result,
                }
                this.props.actions.fetchVehicleFuelingsImportHeaders(loadedFile)
            }

            reader.readAsDataURL(file)
        })
    }

    // Import step 2
    handleSetPairs = () => {
        const pairs = this.state.modalImport.pairs.toJS()
        if (pairs.item && pairs.value && pairs.registration && ((pairs.date && pairs.time) || pairs.date_time)) {
            this.props.actions.fetchVehicleFuelingsImportValues(this.props.importFileId, pairs)
        } else {
            this.props.notify(
                {
                    title: this.props.intl.formatMessage({ id: 'alerts.titles.error' }),
                    message: `${this.props.intl.formatMessage({ id: 'alerts.messages.mandatoryItems' })}: ${this.props.intl.formatMessage({
                        id: 'fields.dateTime',
                    })}, ${this.props.intl.formatMessage({ id: 'fields.registration' })}, ${this.props.intl.formatMessage({
                        id: 'fields.item',
                    })}, ${this.props.intl.formatMessage({ id: 'fields.volume' })}`,
                    position: 'tc',
                },
                'error'
            )
        }
    }

    // Import step 3
    handleImportPreview = e => {
        e && e.preventDefault()

        const formats = {
            date_format: this.state.modalImport.dateFormat,
            time_format: this.state.modalImport.timeFormat,
        }
        this.props.actions.fetchVehicleFuelingsImportPreview(
            this.props.importFileId,
            this.state.modalImport.pairs.toJS(),
            this.state.modalImport.items.toJS(),
            formats
        )
    }

    // Import step 4
    handleImport = e => {
        e && e.preventDefault()

        const formats = {
            date_format: this.state.modalImport.dateFormat,
            time_format: this.state.modalImport.timeFormat,
        }
        this.props.actions.importVehicleFuelings(
            this.props.importFileId,
            this.state.modalImport.pairs,
            this.state.modalImport.vehicleFuelingCompanyId,
            this.state.modalImport.items,
            formats,
            this.state.modalImport.description
        )
    }

    // Import products
    handleShowImportProducts = e => {
        e && e.preventDefault()
        this.props.actions.clearImportProducts()

        const modalImportProducts = { ...this.defaultModalImportProducts, show: true }
        this.setState({ modalImportProducts })
    }

    handleHideImportProducts = e => {
        e && e.preventDefault()

        this.setState({ modalImportProducts: this.defaultModalImportProducts })
    }

    setImportProductsStep = step => {
        const modalImportProducts = { ...this.state.modalImportProducts, step }
        this.setState({ modalImportProducts })
    }

    handlePairingProductsChange = pairs => {
        const modalImportProducts = { ...this.state.modalImportProducts, pairs }
        this.setState({ modalImportProducts })
    }

    handleVehicleFuelingCompanyNameChange = event => {
        const modalImportProducts = { ...this.state.modalImportProducts, vehicleFuelingCompanyName: event.target.value }
        this.setState({ modalImportProducts })
    }

    // Import step 1
    onDropImportProducts = files => {
        files.forEach(file => {
            const reader = new FileReader()
            const fileName = file.name

            reader.onload = e => {
                const loadedFile = {
                    name: fileName,
                    base64: e.target.result,
                }
                this.props.actions.fetchProductsImportHeaders(loadedFile)
            }

            reader.readAsDataURL(file)
        })
    }

    // Import products step 2
    handleImportProductsPreview = e => {
        e && e.preventDefault()

        const pairs = this.state.modalImportProducts.pairs
        if (pairs.has('key') && pairs.has('name')) {
            this.props.actions.fetchProductsImportPreview(
                this.props.importProductsFileId,
                this.state.modalImportProducts.pairs,
                this.state.modalImportProducts.vehicleFuelingCompanyName
            )
        } else {
            this.props.notify(
                {
                    title: this.props.intl.formatMessage({ id: 'alerts.titles.error' }),
                    message: `${this.props.intl.formatMessage({ id: 'alerts.messages.mandatoryItems' })}: ${this.props.intl.formatMessage({
                        id: 'fields.code',
                    })}, ${this.props.intl.formatMessage({ id: 'fields.name' })}`,
                    position: 'tc',
                },
                'error'
            )
        }

    }

    // Import products step 3
    handleImportProducts = e => {
        e && e.preventDefault()

        this.props.actions.importProducts(
            this.props.importProductsFileId,
            this.state.modalImportProducts.pairs,
            this.state.modalImportProducts.vehicleFuelingCompanyName
        )
    }

    componentDidMount() {
        this.props.actions.fetchVehicleFuelings()
        this.props.actions.fetchPrerequisites()
    }

    componentWillReceiveProps(nextProps) {
        handleCommonListActions(this.props, nextProps)

        // Import
        if (this.props.importInProgress && !nextProps.importInProgress) {
            const step = this.state.modalImport.step
            // Import error
            if (nextProps.importError) {
                this.props.notify(
                    {
                        title: this.props.intl.formatMessage({ id: 'alerts.titles.error' }),
                        message: nextProps.importError,
                        position: 'tc',
                    },
                    'error'
                )
            } else if (step === 1) {
                let pairs = this.state.modalImport.pairs
                if (pairs.isEmpty()) {
                    pairs = nextProps.importDefaultHeaders
                }
                const modalImport = { ...this.state.modalImport, step: 2, pairs: Map(pairs) }
                this.setState({ modalImport })
            } else if (step === 2) {
                let items = this.state.modalImport.items
                if (items.isEmpty()) {
                    items = nextProps.importDefaultValues
                }
                let vehicleFuelingCompanyId = this.state.modalImport.vehicleFuelingCompanyId
                if (!vehicleFuelingCompanyId && nextProps.importSelectedFuelingCompany) {
                    vehicleFuelingCompanyId = nextProps.importSelectedFuelingCompany
                    this.props.actions.fetchProducts(nextProps.importSelectedFuelingCompany)
                }
                const modalImport = {
                    ...this.state.modalImport,
                    step: 3,
                    items: Map(items),
                    vehicleFuelingCompanyId,
                    dateFormat: nextProps.importFormats.get('date', constants.DATE_FORMATS[0].value),
                    timeFormat: nextProps.importFormats.get('time', constants.TIME_FORMATS[0].value),
                }
                this.setState({ modalImport })
            } else if (step === 3) {
                const modalImport = {
                    ...this.state.modalImport,
                    step: 4,
                    description: nextProps.importDescription,
                }
                this.setState({ modalImport })
            } else if (step === 4) {
                this.setState(
                    {
                        modalImport: this.defaultModalImport,
                    },
                    () => {
                        this.props.actions.clearImport()
                        this.props.notify(
                            {
                                title: this.props.intl.formatMessage({ id: 'alerts.titles.success' }),
                                message: this.props.intl.formatMessage({ id: 'alerts.messages.fileImportSuccess' }),
                                position: 'tc',
                            },
                            'success'
                        )
                        this.refresh()
                    }
                )
            }
        }

        // Import fetch product list
        if (this.props.productsInProgress && !nextProps.productsInProgress) {
            if (!nextProps.products.isEmpty()) {
                let fuel_level = Set()
                let adblue_level = Set()
                nextProps.importValues.forEach(itemKey => {
                    const item = nextProps.products.find(product => String(product.get('key')) === String(itemKey))
                    if (item) {
                        if (item.get('vehicle_fueling_product_type_id') === 1) {
                            fuel_level = fuel_level.add(itemKey)
                        }
                        if (item.get('vehicle_fueling_product_type_id') === 2) {
                            adblue_level = adblue_level.add(itemKey)
                        }
                    }
                })
                let items = this.state.modalImport.items
                items = items.set('fuel_level', fuel_level)
                items = items.set('adblue_level', adblue_level)
                const modalImport = { ...this.state.modalImport, items }
                this.setState({ modalImport })
            }
        }

        // Import products
        if (this.props.importProductsInProgress && !nextProps.importProductsInProgress) {
            const step = this.state.modalImportProducts.step
            if (nextProps.importProductsError) {
                this.props.notify(
                    {
                        title: this.props.intl.formatMessage({ id: 'alerts.titles.error' }),
                        message: nextProps.importProductsError,
                        position: 'tc',
                    },
                    'error'
                )
            } else if (step === 1) {
                const modalImportProducts = { ...this.state.modalImportProducts, step: 2 }
                this.setState({ modalImportProducts })
            } else if (step === 2) {
                const modalImportProducts = { ...this.state.modalImportProducts, step: 3 }
                this.setState({ modalImportProducts })
            } else if (step === 3) {
                const vehicleFuelingCompanyId = nextProps.importFuelingCompanies.last().get('value')
                const modalImport = { ...this.state.modalImport, vehicleFuelingCompanyId, items: Map() }
                this.setState(
                    {
                        modalImport,
                        modalImportProducts: this.defaultModalImportProducts,
                    },
                    () => {
                        this.props.notify(
                            {
                                title: this.props.intl.formatMessage({ id: 'alerts.titles.success' }),
                                message: this.props.intl.formatMessage({ id: 'alerts.messages.fileImportSuccess' }),
                                position: 'tc',
                            },
                            'success'
                        )
                    }
                )
            }
        }
        // Delete Import
        if (this.props.importDeleting && !nextProps.importDeleting) {
            if (nextProps.importDeleteError === null) {
                this.props.notify(
                    {
                        title: this.props.intl.formatMessage({ id: `alerts.titles.success` }),
                        message: this.props.intl.formatMessage({ id: `alerts.messages.deleteSuccess` }),
                        position: 'tc',
                    },
                    'success'
                )
                this.refresh()
            } else {
                this.props.notify(
                    {
                        title: this.props.intl.formatMessage({ id: `alerts.titles.error` }),
                        message: nextProps.deleteError,
                        position: 'tc',
                    },
                    'error'
                )
            }
        }
    }

    render() {
        document.title = formatters.titleFormatter(this.props.intl.formatMessage({ id: 'modules.vehicleFuelings.heading' }))

        const filters = this.props.tableModel.get('filters')

        const modalImportStep = this.state.modalImport.step
        const modalImportProductsStep = this.state.modalImportProducts.step

        const products = this.props.products
        let items = this.props.importValues

        if (!products.isEmpty()) {
            items = Map()
            this.props.importValues.forEach(itemKey => {
                const item = products.find(product => String(product.get('key')) === String(itemKey))
                items = items.set(itemKey, Map({ label: item ? item.get('name') : itemKey }))
            })
        }

        return (
            <PermissionsCheck hasAny={Object.values(PERMS)} noPermissionsPage>
                <div className="page-inner">
                    <div id="main-wrapper">
                        <div className="panel panel-white">
                            <div className="panel-body">
                                <h4>
                                    <FormattedMessage id="modules.vehicleFuelings.heading" />
                                </h4>

                                <div className="btns-list">
                                    {this.isFilterActive() > 0 && (
                                        <button className="btn btn-default btn-addon m-r-xs" onClick={this.resetFilters}>
                                            <i className="far fa-times" /> <FormattedMessage id="buttons.resetFilters" />
                                        </button>
                                    )}
                                    <button className="btn btn-default btn-addon m-r-xs" onClick={this.refresh}>
                                        <i className="far fa-sync-alt" /> <FormattedMessage id="buttons.refresh" />
                                    </button>
                                    <PermissionsCheck has={[PERMS.CREATE]}>
                                        <Link to={url(this.props.match, 'vehicle-fuelings/new')} className="btn btn-addon btn-success  m-r-xs">
                                            <i className="far fa-plus" /> <FormattedMessage id="buttons.createItem" />
                                        </Link>
                                    </PermissionsCheck>
                                    <PermissionsCheck has={[PERMS.IMPORT]}>
                                        <button className="btn btn-primary btn-addon m-r-xs" onClick={this.handleShowImport}>
                                            <i className="far fa-file-excel" /> <FormattedMessage id="buttons.import" />
                                        </button>
                                    </PermissionsCheck>
                                    <PermissionsCheck has={[PERMS.IMPORT]}>
                                        <button className="btn btn-primary btn-addon" onClick={this.handleShowImportHistory}>
                                            <i className="far fa-file-excel" /> <FormattedMessage id="fields.importHistory" />
                                        </button>
                                    </PermissionsCheck>
                                </div>

                                <div className="table-container">
                                    <table className="table table-striped table-hover table-fixed-header">
                                        <thead>
                                            <tr>
                                                <PermissionsCheck hasAny={[PERMS.UPDATE, PERMS.DELETE]}>
                                                    <th className="w-60" />
                                                </PermissionsCheck>
                                                <th className="w-120">
                                                    <FormattedMessage id="fields.dateTime" />
                                                </th>
                                                <th className="w-max wm-140">
                                                    <FormattedMessage id="fields.vehicle" />
                                                </th>
                                                <th className="w-max wm-140">
                                                    <FormattedMessage id="fields.trailer" />
                                                </th>
                                                <th className="w-140">
                                                    <FormattedMessage id="fields.driver" />
                                                </th>
                                                <th className="w-100">
                                                    <FormattedMessage id="fields.country" />
                                                </th>
                                                <th className="w-100 text-right">
                                                    <FormattedMessage id="fields.fuelLevel" />
                                                </th>
                                                <th className="w-100 text-right">
                                                    <FormattedMessage id="fields.adblueLevel" />
                                                </th>
                                                <th className="w-200">
                                                    <FormattedMessage id="fields.documentNumber" />
                                                </th>
                                                <th className="w-200">
                                                    <FormattedMessage id="fields.ImportFileName" />
                                                </th>
                                                <th className="w-120">
                                                    <FormattedMessage id="fields.ImportDateTime" />
                                                </th>
                                            </tr>
                                            <tr className="filters">
                                                <PermissionsCheck hasAny={[PERMS.UPDATE, PERMS.DELETE]}>
                                                    <th className="w-60" />
                                                </PermissionsCheck>
                                                <th className="w-120" />
                                                <th className="w-max wm-140">
                                                    <TableFilterInputField
                                                        identifier="registration"
                                                        type={TableModelColumnFilteringType.string}
                                                        filters={filters}
                                                        onChange={this.props.table.changeFilter}
                                                    />
                                                </th>
                                                <th className="w-max wm-140">
                                                    <TableFilterInputField
                                                        identifier="registration_trailer"
                                                        type={TableModelColumnFilteringType.string}
                                                        filters={filters}
                                                        onChange={this.props.table.changeFilter}
                                                    />
                                                </th>
                                                <th className="w-140">
                                                    <TableFilterSelectField
                                                        identifier="driver_id"
                                                        type={TableModelColumnFilteringType.equal}
                                                        filters={filters}
                                                        onChange={this.props.table.changeFilter}
                                                        values={getPrerequisitesArray(this.props.prerequisites.values.get('drivers'))}
                                                    />
                                                </th>
                                                <th className="w-100">
                                                    <TableFilterSelectField
                                                        identifier="country_id"
                                                        type={TableModelColumnFilteringType.equal}
                                                        filters={filters}
                                                        onChange={this.props.table.changeFilter}
                                                        values={getPrerequisitesArray(this.props.prerequisites.values.get('countries'), 'id', 'iso_code')}
                                                    />
                                                </th>
                                                <th className="w-100" />
                                                <th className="w-100" />
                                                <th className="w-200">
                                                    <TableFilterInputField
                                                        identifier="number"
                                                        type={TableModelColumnFilteringType.string}
                                                        filters={filters}
                                                        onChange={this.props.table.changeFilter}
                                                    />
                                                </th>
                                                <th className="w-200" />
                                                <th className="w-120" />
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.props.vehicleFuelings.data.valueSeq().map(row => {
                                                return (
                                                    <VehicleFuelingsListRow
                                                        key={`row-${row.id}`}
                                                        data={row}
                                                        onClick={this.onClick}
                                                        onDelete={this.onDelete}
                                                        country={row.country_id && this.props.createGetCountryByIDSelector(row.country_id)}
                                                        driver={this.props.createGetDriverByIDSelector(row.driver_id)}
                                                        vehicle={this.props.createGetVehicleByIDSelector(row.vehicle_id)}
                                                        trailer={this.props.createGetTrailerByIDSelector(row.trailer_id)}
                                                        importFile={this.props.createGetImportFileByIDSelector(row.import_file_id)}
                                                    />
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>

                                <div className="pull-left m-l-xxs m-t-md">
                                    <FormattedMessage id="pagination.totalRecords" />:{' '}
                                    {getListItemsCountMessage(
                                        clientSideItemsPerPage,
                                        this.props.vehicleFuelings.count,
                                        this.props.tableModel.getIn(['pagination', 'totalCount'])
                                    )}
                                </div>

                                <ReactPaginate
                                    containerClassName="pagination"
                                    breakLabel={<span className="disabled">...</span>}
                                    activeClassName="active"
                                    pageCount={getTotalNumberOfPages(this.props.tableModel, this.props.vehicleFuelings.count, clientSideItemsPerPage)}
                                    pageRangeDisplayed={10}
                                    marginPagesDisplayed={2}
                                    forcePage={this.props.tableModel.getIn(['pagination', 'current'])}
                                    onPageChange={this.props.table.changePage}
                                    previousLabel={this.props.intl.formatMessage({ id: 'pagination.previous' })}
                                    nextLabel={this.props.intl.formatMessage({ id: 'pagination.next' })}
                                />
                            </div>
                        </div>
                    </div>

                    <Modal show={Boolean(this.state.modalImport.show)} onHide={this.handleHideImport} className="modal-size-md-2">
                        <Modal.Header closeButton>
                            <Modal.Title>
                                <FormattedMessage id={`modules.vehicleFuelings.importStep${modalImportStep}`} />
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="clearfix">
                            {modalImportStep === 1 && (
                                <Dropzone onDrop={this.onDropImport} multiple={false} style={{}} className="dropzone" acceptClassName="dropzone-accept">
                                    <p className="ready">
                                        <FormattedMessage id="fields.dropzoneReady" />
                                    </p>
                                    <p className="accept">
                                        <FormattedMessage id="fields.dropzoneAccept" />
                                    </p>
                                </Dropzone>
                            )}
                            {modalImportStep === 2 && (
                                <LoadingOverlay active={this.props.importInProgress}>
                                    <VariableAssignment
                                        variables={this.props.importHeaders}
                                        constants={constants.VEHICLE_FUELINGS_IMPORT_CONSTANTS}
                                        values={this.state.modalImport.pairs}
                                        onChange={this.handlePairingChange}
                                        singleValue
                                    />
                                </LoadingOverlay>
                            )}
                            {modalImportStep === 3 && (
                                <LoadingOverlay active={this.props.importInProgress}>
                                    {items && (
                                        <div className="row no-m">
                                            {this.props.importShowFuelingCompanies && (
                                                <React.Fragment>
                                                    <FormattedMessage id="modules.vehicleFuelings.numberedValues" />

                                                    <label htmlFor="vehicle_fueling_company_id" className="wp-100 display-inline">
                                                        <FormattedMessage id="fields.fuelingCompany" />
                                                    </label>
                                                    <div className="input-group m-b-sm">
                                                        <Select
                                                            id="vehicle_fueling_company_id"
                                                            value={this.state.modalImport.vehicleFuelingCompanyId}
                                                            options={this.props.importFuelingCompanies.toJS()}
                                                            onChange={this.handleFuelingCompanyChange}
                                                            clearable={false}
                                                        />
                                                        <div className="input-group-btn">
                                                            <button className="btn btn-default" onClick={this.handleShowImportProducts}>
                                                                <FormattedMessage id="buttons.import" />
                                                            </button>
                                                        </div>
                                                    </div>
                                                </React.Fragment>
                                            )}
                                            <VariableAssignment
                                                variables={items}
                                                constants={constants.ITEMS_CONSTANTS}
                                                values={this.state.modalImport.items}
                                                onChange={this.handleItemsChange}
                                            />
                                        </div>
                                    )}
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="date_format" className="wp-100 display-inline">
                                                    {this.props.intl.formatMessage({ id: 'fields.dateFormat' })} (
                                                    {this.props.intl.formatMessage({ id: 'modules.vehicleFuelings.firstImportLine' })}:{' '}
                                                    {this.props.importExamples.get('date')})
                                                </label>
                                                <Select
                                                    name="date_format"
                                                    value={this.state.modalImport.dateFormat}
                                                    options={constants.DATE_FORMATS}
                                                    onChange={this.setDateFormat}
                                                    clearable={false}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="time_format" className="wp-100 display-inline">
                                                    {this.props.intl.formatMessage({ id: 'fields.timeFormat' })} (
                                                    {this.props.intl.formatMessage({ id: 'modules.vehicleFuelings.firstImportLine' })}:{' '}
                                                    {this.props.importExamples.get('time')})
                                                </label>
                                                <Select
                                                    name="time_format"
                                                    value={this.state.modalImport.timeFormat}
                                                    options={constants.TIME_FORMATS}
                                                    onChange={this.setTimeFormat}
                                                    clearable={false}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </LoadingOverlay>
                            )}
                            {modalImportStep === 4 && (
                                <LoadingOverlay active={this.props.importInProgress}>
                                    <div className="table-container">
                                        <table className="table table-striped table-hover table-fixed-header">
                                            <thead>
                                                <tr>
                                                    <th className="w-140">
                                                        <FormattedMessage id="fields.dateTime" />
                                                    </th>
                                                    <th className="w-max wm-140">
                                                        <FormattedMessage id="fields.vehicle" />
                                                    </th>
                                                    <th className="w-140">
                                                        <FormattedMessage id="fields.driver" />
                                                    </th>
                                                    <th className="w-100">
                                                        <FormattedMessage id="fields.country" />
                                                    </th>
                                                    <th className="w-100 text-right">
                                                        <FormattedMessage id="fields.fuelLevel" />
                                                    </th>
                                                    <th className="w-100 text-right">
                                                        <FormattedMessage id="fields.adblueLevel" />
                                                    </th>
                                                    <th className="w-200">
                                                        <FormattedMessage id="fields.documentNumber" />
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.props.importPreview.valueSeq().map(row => {
                                                    const index = row.id
                                                    let dispatchersBox = null

                                                    const country = row.country && this.props.createGetCountryByIDSelector(row.country)
                                                    const driver = row.driver && this.props.createGetDriverByIDSelector(row.driver)
                                                    const vehicle = row.vehicle && this.props.createGetVehicleByIDSelector(row.vehicle)

                                                    if (vehicle) {
                                                        dispatchersBox = <DispatchersBox>{row.registration}</DispatchersBox>
                                                    } else if (row.registration) {
                                                        dispatchersBox = <DispatchersBox>{row.registration}</DispatchersBox>
                                                    }

                                                    return (
                                                        <tr key={index}>
                                                            <td className="w-140">
                                                                <Infolog infolog={row.infolog_datetime} />
                                                                {row.datetime ? formatters.datetimeFormatter(row.datetime) : null}
                                                            </td>
                                                            <td className="w-max wm-140">
                                                                {row.infolog_vehicle === 'notFound' ? (
                                                                    <Infolog infolog={row.infolog_registration} />
                                                                ) : (
                                                                    <Infolog infolog={row.infolog_vehicle} />
                                                                )}
                                                                {dispatchersBox}
                                                            </td>
                                                            <td className="w-140">
                                                                <Infolog infolog={row.infolog_driver} />
                                                                {driver ? driver.getShortFullName() : null}
                                                            </td>
                                                            <td className="w-100">
                                                                <Infolog infolog={row.infolog_country} />
                                                                {country ? country.iso_code : null}
                                                            </td>
                                                            <td className="w-100 text-right">
                                                                {row.fuel_level ? formatters.floatFormatter(row.fuel_level) : null}
                                                            </td>
                                                            <td className="w-100 text-right">
                                                                {row.adblue_level ? formatters.floatFormatter(row.adblue_level) : null}
                                                            </td>
                                                            <td className="w-200">
                                                                {this.state.modalImport.pairs.has('number') && <Infolog infolog={row.infolog_number} />}
                                                                {row.number ? row.number : null}
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                        <label htmlFor="import_vehicle_fueling_description" className="wp-100 display-inline">
                                            <FormattedMessage id="fields.description" />
                                        </label>
                                        <TextareaAutosize
                                            id="import_vehicle_fueling_description"
                                            className="form-control"
                                            placeholder={this.props.intl.formatMessage({ id: 'messages.writeMessage' })}
                                            value={this.state.modalImport.description}
                                            onChange={this.handleImportDescriptionChange}
                                            maxRows={5}
                                        />
                                    </div>
                                </LoadingOverlay>
                            )}
                        </Modal.Body>
                        <Modal.Footer>
                            <div className="row">
                                <div className="col-md-6 text-left">
                                    {modalImportStep !== 1 && (
                                        <button className="btn btn-default" onClick={() => this.setImportStep(modalImportStep - 1)}>
                                            <FormattedMessage id="buttons.back" />
                                        </button>
                                    )}
                                </div>
                                <div className="col-md-6">
                                    {modalImportStep === 2 && (
                                        <React.Fragment>
                                            <button className="btn btn-success m-r-xs" onClick={this.handleSetPairs}>
                                                <FormattedMessage id="buttons.next" />
                                            </button>
                                        </React.Fragment>
                                    )}
                                    {modalImportStep === 3 && (
                                        <button className="btn btn-success m-r-xs" onClick={this.handleImportPreview}>
                                            <FormattedMessage id="buttons.next" />
                                        </button>
                                    )}
                                    {modalImportStep === 4 && (
                                        <button className="btn btn-success m-r-xs" onClick={this.handleImport}>
                                            <FormattedMessage id="buttons.import" />
                                        </button>
                                    )}
                                    <button className="btn btn-default" onClick={this.handleHideImport}>
                                        <FormattedMessage id="buttons.cancel" />
                                    </button>
                                </div>
                            </div>
                        </Modal.Footer>
                    </Modal>

                    <Modal show={Boolean(this.state.modalImportProducts.show)} onHide={this.handleHideImportProducts} className="modal-size-md-2">
                        <Modal.Header closeButton>
                            <Modal.Title>
                                <FormattedMessage id={`modules.vehicleFuelings.importProductsStep${modalImportProductsStep}`} />
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="clearfix">
                            {modalImportProductsStep === 1 && (
                                <Dropzone
                                    onDrop={this.onDropImportProducts}
                                    multiple={false}
                                    style={{}}
                                    className="dropzone"
                                    acceptClassName="dropzone-accept"
                                >
                                    <p className="ready">
                                        <FormattedMessage id="fields.dropzoneReady" />
                                    </p>
                                    <p className="accept">
                                        <FormattedMessage id="fields.dropzoneAccept" />
                                    </p>
                                </Dropzone>
                            )}
                            {modalImportProductsStep === 2 && (
                                <LoadingOverlay active={this.props.importProductsInProgress}>
                                    <div>
                                        <label htmlFor="vehicle_fueling_company_name">
                                            {this.props.intl.formatMessage({ id: 'fields.fuelingCompanyName' })}
                                            <input
                                                id="vehicle_fueling_company_name"
                                                placeholder={this.props.intl.formatMessage({ id: 'fields.fuelingCompanyName' })}
                                                value={this.state.modalImportProducts.vehicleFuelingCompanyName}
                                                onChange={this.handleVehicleFuelingCompanyNameChange}
                                                className={'form-control m-b-lg'}
                                            />
                                        </label>
                                    </div>
                                    <VariableAssignment
                                        variables={this.props.importProductsHeaders}
                                        constants={constants.PRODUCTS_CONSTANTS}
                                        values={this.state.modalImportProducts.pairs}
                                        onChange={this.handlePairingProductsChange}
                                        singleValue
                                    />
                                </LoadingOverlay>
                            )}
                            {modalImportProductsStep === 3 && (
                                <LoadingOverlay active={this.props.importProductsInProgress}>
                                    <div className="table-container">
                                        <table className="table table-striped table-hover table-fixed-header">
                                            <thead>
                                                <tr>
                                                    <th className="w-140">
                                                        <FormattedMessage id="fields.code" />
                                                    </th>
                                                    <th className="w-max wm-140">
                                                        <FormattedMessage id="fields.name" />
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.props.importProductsPreview.valueSeq().map(product => {
                                                    return (
                                                        <tr key={product.id}>
                                                            <td className="w-140">{product.key}</td>
                                                            <td className="w-max wm-140">{product.name}</td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </LoadingOverlay>
                            )}
                        </Modal.Body>
                        <Modal.Footer>
                            <div className="row">
                                <div className="col-md-6 text-left">
                                    {modalImportProductsStep !== 1 && (
                                        <button className="btn btn-default" onClick={() => this.setImportProductsStep(modalImportProductsStep - 1)}>
                                            <FormattedMessage id="buttons.back" />
                                        </button>
                                    )}
                                </div>
                                <div className="col-md-6">
                                    {modalImportProductsStep === 2 && (
                                        <React.Fragment>
                                            <button className="btn btn-success m-r-xs" onClick={this.handleImportProductsPreview}>
                                                <FormattedMessage id="buttons.next" />
                                            </button>
                                        </React.Fragment>
                                    )}
                                    {modalImportProductsStep === 3 && (
                                        <button className="btn btn-success m-r-xs" onClick={this.handleImportProducts}>
                                            <FormattedMessage id="buttons.import" />
                                        </button>
                                    )}
                                    <button className="btn btn-default" onClick={this.handleHideImportProducts}>
                                        <FormattedMessage id="buttons.cancel" />
                                    </button>
                                </div>
                            </div>
                        </Modal.Footer>
                    </Modal>

                    <Modal show={Boolean(this.state.modalImportHistory.show)} onHide={this.handleHideImportHistory} className="modal-size-md-2">
                        <Modal.Header closeButton>
                            <Modal.Title>
                                <FormattedMessage id="modules.vehicleFuelings.importHistory" />
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="clearfix">
                            <LoadingOverlay active={this.props.importsInProgress}>
                                <div className="table-container">
                                    <table className="table table-striped table-hover table-fixed-header">
                                        <thead>
                                            <tr>
                                                <PermissionsCheck has={[PERMS.DELETE]}>
                                                    <th className="w-30" />
                                                </PermissionsCheck>
                                                <th className="w-140">
                                                    <FormattedMessage id="fields.dateTime" />
                                                </th>
                                                <th className="w-260">
                                                    <FormattedMessage id="fields.fileName" />
                                                </th>
                                                <th className="w-max wm-140">
                                                    <FormattedMessage id="fields.description" />
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.props.imports.valueSeq().map(row => (
                                                <tr key={row.id}>
                                                    <PermissionsCheck has={[PERMS.DELETE]}>
                                                        <td className="w-30 table-buttons">
                                                            <button onClick={() => this.onDeleteImport(row.id)} className="far fa-trash text-gray" />
                                                        </td>
                                                    </PermissionsCheck>
                                                    <td className="w-140">{formatters.datetimeFormatter(row.imported_at)}</td>
                                                    <td className="w-260">{row.name}</td>
                                                    <td className="w-max wm-140">{row.description}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </LoadingOverlay>
                        </Modal.Body>
                        <Modal.Footer>
                            <button className="btn btn-default" onClick={this.handleHideImportHistory}>
                                <FormattedMessage id="buttons.cancel" />
                            </button>
                        </Modal.Footer>
                    </Modal>

                    <ConfirmDialog options={this.state.confirmDialog} />
                </div>
            </PermissionsCheck>
        )
    }
}

function mapStateToProps(state) {
    return {
        vehicleFuelings: createTableDataSelector({
            tableIdentifier,
            columns,
            dataSelector: selectors.getVehicleFuelings,
            clientSideItemsPerPage,
        })(state),
        tableModel: createTableModelSelector(tableIdentifier)(state),
        prerequisites: preparePrerequisitesFetchStatusSelectors(componentIdentifier, prerequisites, state),
        deleting: selectors.getVehicleFuelingDeleting(state),
        deleteError: selectors.getVehicleFuelingDeleteError(state),
        // Import
        importInProgress: selectors.getImportInProgress(state),
        importError: selectors.getImportError(state),
        importFileId: selectors.getImportFileId(state),
        importHeaders: selectors.getImportHeaders(state),
        importDefaultHeaders: selectors.getImportDefaultHeaders(state),
        importValues: selectors.getImportValues(state),
        importDefaultValues: selectors.getImportDefaultValues(state),
        importFuelingCompanies: selectors.getImportFuelingCompanies(state),
        importShowFuelingCompanies: selectors.getImportShowFuelingCompanies(state),
        importSelectedFuelingCompany: selectors.getImportSelectedFuelingCompany(state),
        importFormats: selectors.getImportFormats(state),
        importExamples: selectors.getImportExamples(state),
        importPreview: selectors.getImportPreview(state),
        importDescription: selectors.getImportDescription(state),
        // Import products
        importProductsInProgress: selectors.getImportProductsInProgress(state),
        importProductsError: selectors.getImportProductsError(state),
        importProductsFileId: selectors.getImportProductsFileId(state),
        importProductsHeaders: selectors.getImportProductsHeaders(state),
        importProductsPreview: selectors.getImportProductsPreview(state),
        // Products
        productsInProgress: selectors.getProductsInProgress(state),
        productsError: selectors.getProductsError(state),
        products: selectors.getProducts(state),
        // Imports
        importsInProgress: selectors.getImportsInProgress(state),
        importsError: selectors.getImportsError(state),
        imports: selectors.getImports(state),
        importDeleting: selectors.getImportDeleting(state),
        importDeleteError: selectors.getImportDeleteError(state),
        // selectors
        createGetImportFileByIDSelector: id => selectors.createGetImportFileByIDSelector(id)(state),
        createGetVehicleByIDSelector: id => createGetVehicleByIDSelector(id)(state),
        createGetTrailerByIDSelector: id => createGetTrailerByIDSelector(id)(state),
        createGetDriverByIDSelector: id => createGetDriverByIDSelector(id)(state),
        createGetCountryByIDSelector: id => createGetCountryByIDSelector(id)(state),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            ...bindActionCreators(
                {
                    ...vehicleFuelingsActionCreators,
                    ...preparePrerequisitesActionCreators(componentIdentifier, prerequisites),
                },
                dispatch
            ),
        },
        notify: (notification, type) => dispatch(Notifications.show(notification, type)),
        redirect: toURL => dispatch(push(toURL)),
        dispatch,
    }
}

function mergeProps(stateProps, dispatchProps, ownProps) {
    return {
        ...stateProps,
        ...dispatchProps,
        ...ownProps,
        table: bindActionCreators(prepareTableActionCreators(tableIdentifier, stateProps.tableModel), dispatchProps.dispatch),
    }
}

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps,
        mergeProps
    )(VehicleFuelingsList)
)
