import React, { Component } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'

import * as formatters from '../../../common/formatters'

import PermissionsCheck from '../../auth/components/PermissionsCheck'
import { PERMS } from '../permissions'

import CustomerAddressesEditForm from './CustomerAddressesEditForm'

// eslint-disable-next-line react/prefer-stateless-function
class CustomerAddressesEdit extends Component {
    render() {
        document.title = formatters.titleFormatter(this.props.intl.formatMessage({ id: 'modules.customerAddresses.heading' }))

        return (
            <PermissionsCheck hasAny={Object.values(PERMS)} noPermissionsPage>
                <div className="page-inner">
                    <div id="main-wrapper">
                        <div className="row">
                            <div className="col-lg-8 col-lg-offset-2 col-xl-6 col-xl-offset-3">
                                <div className="panel panel-white">
                                    <div className="panel-body panel-padding">
                                        <div className="panel-head">
                                            <h4>
                                                <FormattedMessage id="modules.customerAddresses.heading" /> -{' '}
                                                {this.props.match.params.customerAddressID ? (
                                                    <FormattedMessage id="fields.itemEdit" />
                                                ) : (
                                                    <FormattedMessage id="fields.itemCreate" />
                                                )}
                                            </h4>
                                        </div>

                                        <CustomerAddressesEditForm customerAddressID={this.props.match.params.customerAddressID} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </PermissionsCheck>
        )
    }
}

export default injectIntl(CustomerAddressesEdit)
