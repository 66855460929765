import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { push } from 'react-router-redux'
import ReactPaginate from 'react-paginate'
import Notifications from 'react-notification-system-redux'
import { Tooltip, OverlayTrigger, Modal } from 'react-bootstrap'

import { ConfirmDialog } from '../../../common/confirm_dialog'
import * as formatters from '../../../common/formatters'
import { url, handleCommonListActions, getListItemsCountMessage, getPrerequisitesArray } from '../../../common/helpers'

import { prepareTableActionCreators } from '../../../common/table/actionCreators'
import { TableSortButtons, TableFilterInputField, TableFilterSelectField, TableFilterCheckboxField } from '../../../common/table/components'
import { getActiveFilters, getTotalNumberOfPages } from '../../../common/table/helpers'
import { TableModelColumn, TableModelColumnFilteringType } from '../../../common/table/model'
import { createTableDataSelector, createTableModelSelector } from '../../../common/table/selectors'

import { preparePrerequisitesFetchStatusSelectors } from '../../../common/prerequisites/selectors'
import { preparePrerequisitesActionCreators } from '../../../common/prerequisites/actionCreators'

import * as transportsActionCreators from '../actionCreators'
import {
    getTransports,
    getTransportDeleting,
    getTransportDeleteError,
    getTransportDuplication,
    getTransportDuplicateError,
    getTransportDuplicated,
    getTransportCreationTemplate,
    getTransportCreateTemplateError,
    getTransportCreatedTemplate,
    getTransportSmsFetching,
    getTransportSmsError,
    getTransportSmsData,
    createGetDuplicitySelector,
} from '../selectors'
import { getSettings } from '../../auth/selectors'
import { createGetUserByIDSelector } from '../../users/selectors'
import { createGetTransportTypeByIDSelector } from '../../transport_types/selectors'
import { createGetCargoTypeByIDSelector } from '../../cargo_types/selectors'
import { createGetGoodsTypeByIDSelector } from '../../goods_types/selectors'
import { createGetCustomerByIDSelector } from '../../customers/selectors'
import { createGetVehicleByIDSelector } from '../../vehicles/selectors'
import { createGetTrailerByIDSelector } from '../../trailers/selectors'
import { createGetCurrencyByIDSelector } from '../../currencies/selectors'
import { createGetTransportStateByIDSelector } from '../../transport_states/selectors'
import { createGetCountryByIDSelector } from '../../countries/selectors'
import { createGetTransportPointStateByIDSelector } from '../../transport_point_states/selectors'
import { createGetCarrierByIDSelector } from '../../carriers/selectors'
import { createGetDriverByIDSelector } from '../../drivers/selectors'

import TransportsListRow from './TransportsListRow'

import PermissionsCheck from '../../auth/components/PermissionsCheck'
import PermissionsProps from '../../auth/components/PermissionsProps'

import { PERMS as PERMS_TEMPLATES } from '../../transport_templates/permissions'
import { PERMS, PERMS_CUSTOMER, PERMS_SPEDITION, PERMS_COST } from '../permissions'

const tableIdentifier = 'transports_list'
const clientSideItemsPerPage = undefined

const componentIdentifier = 'transports_list'
const prerequisites = [
    'users',
    'transport_states',
    'transport_types',
    'cargo_types',
    'goods_types',
    'dispatchers',
    'active_drivers',
    'transport_scoring_settings',
]

const columns = {
    id: new TableModelColumn({}),
    created_at: new TableModelColumn({}),
    transport_state: new TableModelColumn({}),
    transport_type: new TableModelColumn({}),
}

class TransportsList extends Component {
    state = {
        confirmDialog: {
            show: false,
            title: '',
            message: '',
            labelCancel: '',
            labelAccept: '',
            classNameAccept: 'text-success',
            classNameCancel: 'text-default',
            onCancel: () => {},
            onAccept: () => {},
        },
        modalDuplicity: {
            show: false,
            duplicity: null,
            transportId: null,
        },
    }

    // confirm dialog
    showConfirmDialog = options => {
        this.setState({
            confirmDialog: {
                show: true,
                title: options.title || this.props.intl.formatMessage({ id: 'confirmDialog.default.title' }),
                message: options.message || this.props.intl.formatMessage({ id: 'confirmDialog.default.message' }),
                labelCancel: options.labelCancel || this.props.intl.formatMessage({ id: 'confirmDialog.default.cancel' }),
                labelAccept: options.labelAccept || this.props.intl.formatMessage({ id: 'confirmDialog.default.accept' }),
                classNameAccept: options.classNameAccept || 'btn-success',
                classNameCancel: options.classNameCancel || 'btn-default',
                onCancel: options.onCancel || this.hideConfirmDialog,
                onAccept: options.onAccept || this.hideConfirmDialog,
            },
        })
    }

    hideConfirmDialog = () => {
        this.setState({
            confirmDialog: {
                show: false,
                onCancel: () => {},
                onAccept: () => {},
            },
        })
    }

    // handlers
    onClick = itemId => {
        this.props.redirect(`/transports/${itemId}`)
    }

    onDelete = transportId => {
        this.showConfirmDialog({
            title: this.props.intl.formatMessage({ id: 'confirmDialog.transportDelete.title' }),
            message: this.props.intl.formatMessage({ id: 'confirmDialog.transportDelete.message' }),
            labelCancel: this.props.intl.formatMessage({ id: 'confirmDialog.transportDelete.cancel' }),
            labelAccept: this.props.intl.formatMessage({ id: 'confirmDialog.transportDelete.accept' }),
            classNameAccept: 'btn-danger',
            onAccept: () => {
                this.props.actions.deleteTransport(transportId)
                this.hideConfirmDialog()
            },
        })
    }

    onCreateTemplate = transportId => {
        this.setState({
            modalDuplicity: {
                transportId,
            },
        })

        this.props.actions.createTemplate(transportId)
    }

    onDuplicate = transportId => {
        this.props.actions.duplicateTransport(transportId)
    }

    onCopySms = transportId => {
        this.props.actions.fetchTransportSms(transportId)
    }

    // duplicity
    duplicityFoundCallback = duplicity => {
        this.setState({
            modalDuplicity: {
                ...this.state.modalDuplicity,
                show: true,
                duplicity,
            },
        })
    }

    handleDuplicityCancel = () => {
        this.setState({
            modalDuplicity: {
                show: false,
                duplicity: null,
            },
        })
    }

    handleDuplicityConfirm = () => {
        this.handleDuplicityCancel()

        this.props.actions.createTemplate(this.state.modalDuplicity.transportId, true)
    }

    handleExport = e => {
        e && e.preventDefault()
        this.props.actions.exportTransports(this.props.tableModel.get('filters').toJS(), this.props.tableModel.get('sorting').toJS())
    }

    handleChangeThreeWaySwitchFilter = (e, identifier) => {
        const filters = this.props.tableModel.get('filters')
        const currentValue = filters.getIn([identifier, 'value']) || 0
        const switchOptions = {
            0: 1,
            1: 2,
            2: 0,
        }

        this.props.table.changeFilter(identifier, TableModelColumnFilteringType.equal, switchOptions[currentValue])
    }

    refresh = () => {
        this.props.actions.fetchTransports()
    }

    resetFilters = () => {
        this.props.table.resetFilters(
            [],
            [
                {
                    column: 'is_template',
                    type: TableModelColumnFilteringType.equal,
                    value: 0,
                },
            ]
        )
    }

    isFilterActive = () => getActiveFilters(this.props.tableModel, ['is_template']).size > 0

    componentWillMount() {
        this.props.actions.clearTransports()
    }

    componentDidMount() {
        this.props.table.changeFilters([
            {
                column: 'is_template',
                type: TableModelColumnFilteringType.equal,
                value: 0,
            },
        ])

        this.props.actions.fetchPrerequisites()
    }

    componentWillReceiveProps(nextProps) {
        handleCommonListActions(this.props, nextProps)

        // Duplicate
        if (this.props.duplication && !nextProps.duplication) {
            if (nextProps.duplicateError === null && nextProps.duplicatedTransport) {
                this.props.redirect(url(this.props.match, `transports/${nextProps.duplicatedTransport.id}`))
                this.props.notify(
                    {
                        title: this.props.intl.formatMessage({ id: 'alerts.titles.success' }),
                        message: this.props.intl.formatMessage({ id: 'alerts.messages.copySuccess' }),
                        position: 'tc',
                    },
                    'success'
                )
            } else {
                this.props.notify(
                    {
                        title: this.props.intl.formatMessage({ id: 'alerts.titles.error' }),
                        message: nextProps.duplicateError,
                        position: 'tc',
                    },
                    'error'
                )
            }
        }

        // Create template
        if (this.props.creationTemplate && !nextProps.creationTemplate) {
            if (nextProps.createTemplateError === null && nextProps.createdTemplate && !nextProps.duplicity) {
                this.props.redirect(url(this.props.match, `transport-templates/${nextProps.createdTemplate.id}`))
                this.props.notify(
                    {
                        title: this.props.intl.formatMessage({ id: 'alerts.titles.success' }),
                        message: this.props.intl.formatMessage({ id: 'alerts.messages.createSuccess' }),
                        position: 'tc',
                    },
                    'success'
                )
            } else if (nextProps.createTemplateError === null && nextProps.duplicity) {
                this.duplicityFoundCallback && this.duplicityFoundCallback(nextProps.duplicity)
            } else {
                this.props.notify(
                    {
                        title: this.props.intl.formatMessage({ id: 'alerts.titles.error' }),
                        message: nextProps.createTemplateError,
                        position: 'tc',
                    },
                    'error'
                )
            }
        }

        if (this.props.smsFetching && !nextProps.smsFetching) {
            if (nextProps.smsError === null && nextProps.smsData) {
                let dummy = document.createElement("textarea")
                document.body.appendChild(dummy)
                dummy.value = nextProps.smsData
                dummy.select()
                document.execCommand("copy")
                document.body.removeChild(dummy)

                this.props.notify(
                    {
                        title: this.props.intl.formatMessage({ id: 'alerts.titles.success' }),
                        message: this.props.intl.formatMessage({ id: 'alerts.messages.copySuccess' }),
                        position: 'tc',
                    },
                    'success'
                )
            } else {
                this.props.notify(
                    {
                        title: this.props.intl.formatMessage({ id: 'alerts.titles.error' }),
                        message: nextProps.duplicateError,
                        position: 'tc',
                    },
                    'error'
                )
            }
        }
    }

    /* eslint-disable jsx-a11y/anchor-is-valid */
    render() {
        document.title = formatters.titleFormatter(this.props.intl.formatMessage({ id: 'modules.transports.heading' }))

        const sorting = this.props.tableModel.get('sorting')
        const filters = this.props.tableModel.get('filters')

        const tooltipNoTime = <Tooltip id="tooltipNoTime">{this.props.intl.formatMessage({ id: 'fields.noTime' })}</Tooltip>

        const tooltipNoVehicle = <Tooltip id="tooltipNoVehicle">{this.props.intl.formatMessage({ id: 'fields.noVehicle' })}</Tooltip>

        const tooltipNoTrailer = <Tooltip id="tooltipNoTrailer">{this.props.intl.formatMessage({ id: 'fields.noTrailer' })}</Tooltip>

        const driversOptions = getPrerequisitesArray(this.props.prerequisites.values.get('active_drivers'))
        driversOptions.unshift({
            id: 'no_driver',
            name: this.props.intl.formatMessage({ id: 'fields.noDriver' }),
        })

        const duplicity = this.props.duplicity

        const threeWaySwitchOptions = {
            0: 'text-gray-lighter',
            1: 'text-gray-dark',
            2: 'text-success',
        }

        const filterIconFilesClassName = threeWaySwitchOptions[filters.getIn(['files', 'value']) || 0]
        const transpportCanSendSms = this.props.settings && this.props.settings.get('sms_template')

        return (
            <PermissionsCheck hasAny={Object.values(PERMS)} noPermissionsPage>
                <div className="page-inner">
                    <div id="main-wrapper">
                        <div className="panel panel-white">
                            <div className="panel-body">
                                <h4>
                                    <FormattedMessage id="modules.transports.heading" />
                                </h4>
                                <div className="pull-left" style={{ marginLeft: '100px' }}>
                                    <TableFilterCheckboxField
                                        identifier="with_trashed"
                                        type={TableModelColumnFilteringType.equal}
                                        filters={filters}
                                        onChange={this.props.table.changeFilter}
                                        label={this.props.intl.formatMessage({ id: 'modules.transports.showWithTrashed' })}
                                    />
                                </div>

                                <div className="pull-left" style={{ marginLeft: '20px' }}>
                                    <TableFilterCheckboxField
                                        identifier="only_trashed"
                                        type={TableModelColumnFilteringType.equal}
                                        filters={filters}
                                        onChange={this.props.table.changeFilter}
                                        label={this.props.intl.formatMessage({ id: 'modules.transports.showOnlyTrashed' })}
                                    />
                                </div>

                                <div className="pull-left" style={{ marginLeft: '20px' }}>
                                    <TableFilterCheckboxField
                                        identifier="only_combined"
                                        type={TableModelColumnFilteringType.equal}
                                        filters={filters}
                                        onChange={this.props.table.changeFilter}
                                        label={this.props.intl.formatMessage({ id: 'modules.transports.showOnlyCombined' })}
                                    />
                                </div>

                                <div className="pull-left" style={{ marginLeft: '20px' }}>
                                    <TableFilterCheckboxField
                                        identifier="only_not_approved"
                                        type={TableModelColumnFilteringType.equal}
                                        filters={filters}
                                        onChange={this.props.table.changeFilter}
                                        label={this.props.intl.formatMessage({ id: 'modules.transports.showOnlyNotApproved' })}
                                    />
                                </div>

                                <div className="pull-left" style={{ marginLeft: '20px' }}>
                                    <TableFilterCheckboxField
                                        identifier="only_cabotage"
                                        type={TableModelColumnFilteringType.equal}
                                        filters={filters}
                                        onChange={this.props.table.changeFilter}
                                        label={this.props.intl.formatMessage({ id: 'modules.transports.showOnlyCabotage' })}
                                    />
                                </div>

                                <div className="btns-list">
                                    {this.isFilterActive() > 0 && (
                                        <button className="btn btn-default btn-addon m-r-xs" onClick={this.resetFilters}>
                                            <i className="far fa-times" /> <FormattedMessage id="buttons.resetFilters" />
                                        </button>
                                    )}
                                    <button className="btn btn-default btn-addon m-r-xs" onClick={this.refresh}>
                                        <i className="far fa-sync-alt" /> <FormattedMessage id="buttons.refresh" />
                                    </button>
                                    <PermissionsCheck has={[PERMS.EXPORT]}>
                                        <button className="btn btn-primary btn-addon m-r-xs" onClick={this.handleExport}>
                                            <i className="far fa-file-excel" /> <FormattedMessage id="buttons.exportToXLS" />
                                        </button>
                                    </PermissionsCheck>
                                    <PermissionsCheck has={[PERMS.CREATE]}>
                                        <Link to={url(this.props.match, 'transports/import')} className="btn btn-addon btn-success m-r-xs">
                                            <i className="far fa-plus" /> <FormattedMessage id="buttons.import" />
                                        </Link>
                                    </PermissionsCheck>
                                    <PermissionsCheck has={[PERMS.CREATE]}>
                                        <Link to={url(this.props.match, 'transports/import-prices')} className="btn btn-addon btn-success m-r-xs">
                                            <i className="far fa-plus" /> <FormattedMessage id="buttons.importPrices" />
                                        </Link>
                                    </PermissionsCheck>
                                    <PermissionsCheck has={[PERMS.CREATE]}>
                                        <Link to={url(this.props.match, 'transports/new')} className="btn btn-addon btn-success">
                                            <i className="far fa-plus" /> <FormattedMessage id="buttons.createItem" />
                                        </Link>
                                    </PermissionsCheck>
                                </div>

                                <div className="table-container">
                                    <table className="table table-striped table-hover table-fixed-header table-transports">
                                        <thead>
                                            <tr>
                                                <PermissionsCheck
                                                    hasAny={[
                                                        PERMS.UPDATE,
                                                        PERMS.CREATE,
                                                        PERMS_TEMPLATES.CREATE,
                                                        PERMS.DELETE,
                                                        PERMS.COPY_TO_CLIPBOARD,
                                                    ]}
                                                >
                                                    <th className="w-40" />
                                                </PermissionsCheck>
                                                <th className="w-100">
                                                    <FormattedMessage id="fields.number" />
                                                    <TableSortButtons identifier="id" sorting={sorting} onChange={this.props.table.changeSorting} />
                                                    <br />
                                                    <small>
                                                        <FormattedMessage id="fields.createdAt" />
                                                        <TableSortButtons
                                                            identifier="created_at"
                                                            sorting={sorting}
                                                            onChange={this.props.table.changeSorting}
                                                        />
                                                    </small>
                                                </th>
                                                <th className="w-100">
                                                    <FormattedMessage id="fields.state" />
                                                    <br />
                                                    <small>
                                                        <FormattedMessage id="fields.disponent" />
                                                    </small>
                                                </th>
                                                <th className="w-30" />
                                                <PermissionsCheck hasAny={Object.values(PERMS_CUSTOMER)}>
                                                    <th className="w-max wm-360">
                                                        <FormattedMessage id="fields.customer" />
                                                        <br />
                                                        <small>
                                                            <FormattedMessage id="fields.transportType" /> |{' '}
                                                            <FormattedMessage id="fields.cargoType" /> | <FormattedMessage id="fields.goodsType" />
                                                        </small>
                                                    </th>
                                                </PermissionsCheck>
                                                <PermissionsProps hasAny={Object.values(PERMS_CUSTOMER)} props={{ className: 'w-360' }}>
                                                    <th className="w-max wm-360">
                                                        <FormattedMessage id="fields.loading" />
                                                        <br />
                                                        <small>
                                                            {' '}
                                                            <FormattedMessage id="fields.time" />
                                                            <TableSortButtons
                                                                identifier="loading_time"
                                                                sorting={sorting}
                                                                onChange={this.props.table.changeSorting}
                                                            />{' '}
                                                            | <FormattedMessage id="fields.code" />
                                                        </small>
                                                    </th>
                                                </PermissionsProps>
                                                <PermissionsProps hasAny={Object.values(PERMS_CUSTOMER)} props={{ className: 'w-360' }}>
                                                    <th className="w-max wm-360">
                                                        <FormattedMessage id="fields.unloading" />
                                                        <br />
                                                        <small>
                                                            <FormattedMessage id="fields.time" />
                                                            <TableSortButtons
                                                                identifier="unloading_time"
                                                                sorting={sorting}
                                                                onChange={this.props.table.changeSorting}
                                                            />
                                                        </small>
                                                    </th>
                                                </PermissionsProps>
                                                <th className="w-120">
                                                    <FormattedMessage id="fields.vehicle" />
                                                    <br />
                                                    <small>
                                                        <FormattedMessage id="fields.dispatcher" />
                                                    </small>
                                                    <PermissionsCheck hasAny={Object.values(PERMS_SPEDITION)}>
                                                        <br />
                                                        <small>
                                                            <FormattedMessage id="fields.spedition" />
                                                        </small>
                                                    </PermissionsCheck>
                                                </th>
                                                <th className="w-120">
                                                    <FormattedMessage id="fields.trailer" />
                                                    <br />
                                                    <small>
                                                        <FormattedMessage id="fields.driver" />
                                                    </small>
                                                    <br />
                                                    &nbsp;
                                                </th>
                                                <th className="w-90 text-right">
                                                    <FormattedMessage id="fields.distance" />
                                                </th>
                                                <PermissionsCheck hasAny={Object.values(PERMS_COST)}>
                                                    <th className="w-90 text-right">
                                                        <FormattedMessage id="fields.price" />
                                                    </th>
                                                    <th className="w-100 text-right">
                                                        <FormattedMessage id="fields.pricePerKm" />
                                                    </th>
                                                </PermissionsCheck>
                                                <th className="w-50 text-center">
                                                    <i className="far fa-star" />
                                                </th>
                                            </tr>
                                            <tr className="filters">
                                                <PermissionsCheck
                                                    hasAny={[
                                                        PERMS.UPDATE,
                                                        PERMS.CREATE,
                                                        PERMS_TEMPLATES.CREATE,
                                                        PERMS.DELETE,
                                                        PERMS.COPY_TO_CLIPBOARD,
                                                    ]}
                                                >
                                                    <th className="w-40" />
                                                </PermissionsCheck>
                                                <th className="w-100">
                                                    <TableFilterInputField
                                                        identifier="id"
                                                        type={TableModelColumnFilteringType.stringFromStart}
                                                        filters={filters}
                                                        onChange={this.props.table.changeFilter}
                                                        placeholder={this.props.intl.formatMessage({ id: 'fields.number' })}
                                                    />
                                                    <div className="m-t-xxs">
                                                        <TableFilterInputField
                                                            identifier="created_from"
                                                            type={TableModelColumnFilteringType.string}
                                                            filters={filters}
                                                            onChange={this.props.table.changeFilter}
                                                            placeholder={this.props.intl.formatMessage({ id: 'fields.from' })}
                                                            datepicker
                                                        />
                                                    </div>
                                                    <div className="m-t-xxs">
                                                        <TableFilterInputField
                                                            identifier="created_to"
                                                            type={TableModelColumnFilteringType.string}
                                                            filters={filters}
                                                            onChange={this.props.table.changeFilter}
                                                            placeholder={this.props.intl.formatMessage({ id: 'fields.to' })}
                                                            datepicker
                                                        />
                                                    </div>
                                                </th>
                                                <th className="w-100">
                                                    <TableFilterSelectField
                                                        identifier="transport_state_id"
                                                        type={TableModelColumnFilteringType.equal}
                                                        filters={filters}
                                                        onChange={this.props.table.changeFilter}
                                                        values={getPrerequisitesArray(
                                                            this.props.prerequisites.values.get('transport_states'),
                                                            'id',
                                                            'name',
                                                            this.props.intl,
                                                            'transportStates.'
                                                        )}
                                                        prompt={this.props.intl.formatMessage({ id: 'fields.state' })}
                                                    />
                                                    <div className="m-t-xxs">
                                                        <TableFilterSelectField
                                                            identifier="disponent_id"
                                                            type={TableModelColumnFilteringType.equal}
                                                            filters={filters}
                                                            onChange={this.props.table.changeFilter}
                                                            values={getPrerequisitesArray(this.props.prerequisites.values.get('users'))}
                                                            prompt={this.props.intl.formatMessage({ id: 'fields.disponent' })}
                                                        />
                                                    </div>
                                                </th>
                                                <th className="w-30 text-center">
                                                    <a
                                                        href="#"
                                                        className="m-t-xxs display-inline p-h-xxs"
                                                        onClick={e => {
                                                            this.handleChangeThreeWaySwitchFilter(e, 'files')
                                                        }}
                                                    >
                                                        <i className={`fas fa-file ${filterIconFilesClassName}`} />
                                                    </a>
                                                </th>
                                                <PermissionsCheck hasAny={Object.values(PERMS_CUSTOMER)}>
                                                    <th className="w-max wm-360">
                                                        <div className="pull-left wp-100">
                                                            <div className="pull-left m-r-xxs" style={{ width: '225px' }}>
                                                                <TableFilterInputField
                                                                    identifier="customer"
                                                                    type={TableModelColumnFilteringType.string}
                                                                    filters={filters}
                                                                    onChange={this.props.table.changeFilter}
                                                                    placeholder={this.props.intl.formatMessage({ id: 'fields.customer' })}
                                                                />
                                                            </div>
                                                            <div className="pull-left w-110">
                                                                <TableFilterInputField
                                                                    identifier="order_number"
                                                                    type={TableModelColumnFilteringType.string}
                                                                    filters={filters}
                                                                    onChange={this.props.table.changeFilter}
                                                                    placeholder={this.props.intl.formatMessage({ id: 'fields.orderNumber' })}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="m-t-xxs w-110 pull-left m-r-xxs">
                                                            <TableFilterSelectField
                                                                identifier="transport_type_id"
                                                                type={TableModelColumnFilteringType.equal}
                                                                filters={filters}
                                                                onChange={this.props.table.changeFilter}
                                                                values={getPrerequisitesArray(this.props.prerequisites.values.get('transport_types'))}
                                                                prompt={this.props.intl.formatMessage({ id: 'fields.transportType' })}
                                                            />
                                                        </div>
                                                        <div className="m-t-xxs w-110 pull-left m-r-xxs">
                                                            <TableFilterSelectField
                                                                identifier="cargo_type_id"
                                                                type={TableModelColumnFilteringType.equal}
                                                                filters={filters}
                                                                onChange={this.props.table.changeFilter}
                                                                values={getPrerequisitesArray(this.props.prerequisites.values.get('cargo_types'))}
                                                                prompt={this.props.intl.formatMessage({ id: 'fields.cargoType' })}
                                                            />
                                                        </div>
                                                        <div className="m-t-xxs w-110 pull-left">
                                                            <TableFilterSelectField
                                                                identifier="goods_type_id"
                                                                type={TableModelColumnFilteringType.equal}
                                                                filters={filters}
                                                                onChange={this.props.table.changeFilter}
                                                                values={getPrerequisitesArray(this.props.prerequisites.values.get('goods_types'))}
                                                                prompt={this.props.intl.formatMessage({ id: 'fields.goodsType' })}
                                                            />
                                                        </div>
                                                    </th>
                                                </PermissionsCheck>
                                                <PermissionsProps hasAny={Object.values(PERMS_CUSTOMER)} props={{ className: 'w-360' }}>
                                                    <th className="w-max wm-360">
                                                        <TableFilterInputField
                                                            identifier="loading_address"
                                                            type={TableModelColumnFilteringType.string}
                                                            filters={filters}
                                                            onChange={this.props.table.changeFilter}
                                                            placeholder={this.props.intl.formatMessage({ id: 'fields.address' })}
                                                        />
                                                        <div className="m-t-xxs w-90 pull-left m-r-xxs">
                                                            <TableFilterInputField
                                                                identifier="loading_date_from"
                                                                type={TableModelColumnFilteringType.string}
                                                                filters={filters}
                                                                onChange={this.props.table.changeFilter}
                                                                placeholder={this.props.intl.formatMessage({ id: 'fields.from' })}
                                                                datepicker
                                                            />
                                                        </div>
                                                        <div className="m-t-xxs w-90 pull-left">
                                                            <TableFilterInputField
                                                                identifier="loading_date_to"
                                                                type={TableModelColumnFilteringType.string}
                                                                filters={filters}
                                                                onChange={this.props.table.changeFilter}
                                                                placeholder={this.props.intl.formatMessage({ id: 'fields.to' })}
                                                                datepicker
                                                            />
                                                        </div>
                                                        <div className="m-t-xxs w-20 pull-left m-r-xs">
                                                            <OverlayTrigger placement="top" overlay={tooltipNoTime}>
                                                                <div>
                                                                    <TableFilterCheckboxField
                                                                        identifier="loading_no_date"
                                                                        type={TableModelColumnFilteringType.equal}
                                                                        filters={filters}
                                                                        onChange={this.props.table.changeFilter}
                                                                        label=""
                                                                    />
                                                                </div>
                                                            </OverlayTrigger>
                                                        </div>
                                                        <div className="m-t-xxs pull-left" style={{ width: '99px' }}>
                                                            <TableFilterInputField
                                                                identifier="loading_code"
                                                                type={TableModelColumnFilteringType.string}
                                                                filters={filters}
                                                                onChange={this.props.table.changeFilter}
                                                                placeholder={this.props.intl.formatMessage({ id: 'fields.code' })}
                                                            />
                                                        </div>
                                                    </th>
                                                </PermissionsProps>
                                                <PermissionsProps hasAny={Object.values(PERMS_CUSTOMER)} props={{ className: 'w-360' }}>
                                                    <th className="w-max wm-360">
                                                        <TableFilterInputField
                                                            identifier="unloading_address"
                                                            type={TableModelColumnFilteringType.string}
                                                            filters={filters}
                                                            onChange={this.props.table.changeFilter}
                                                            placeholder={this.props.intl.formatMessage({ id: 'fields.address' })}
                                                        />
                                                        <div className="m-t-xxs w-90 pull-left m-r-xxs">
                                                            <TableFilterInputField
                                                                identifier="unloading_date_from"
                                                                type={TableModelColumnFilteringType.string}
                                                                filters={filters}
                                                                onChange={this.props.table.changeFilter}
                                                                placeholder={this.props.intl.formatMessage({ id: 'fields.from' })}
                                                                datepicker
                                                            />
                                                        </div>
                                                        <div className="m-t-xxs w-90 pull-left">
                                                            <TableFilterInputField
                                                                identifier="unloading_date_to"
                                                                type={TableModelColumnFilteringType.string}
                                                                filters={filters}
                                                                onChange={this.props.table.changeFilter}
                                                                placeholder={this.props.intl.formatMessage({ id: 'fields.to' })}
                                                                datepicker
                                                            />
                                                        </div>
                                                        <div className="m-t-xxs w-20 pull-left m-r-xs">
                                                            <OverlayTrigger placement="top" overlay={tooltipNoTime}>
                                                                <div>
                                                                    <TableFilterCheckboxField
                                                                        identifier="unloading_no_date"
                                                                        type={TableModelColumnFilteringType.equal}
                                                                        filters={filters}
                                                                        onChange={this.props.table.changeFilter}
                                                                        label=""
                                                                    />
                                                                </div>
                                                            </OverlayTrigger>
                                                        </div>
                                                    </th>
                                                </PermissionsProps>
                                                <th colSpan="2" className="w-240">
                                                    <div className="pull-left wp-100">
                                                        <div className="pull-left w-80">
                                                            <TableFilterInputField
                                                                identifier="vehicle"
                                                                type={TableModelColumnFilteringType.string}
                                                                filters={filters}
                                                                onChange={this.props.table.changeFilter}
                                                                placeholder={this.props.intl.formatMessage({ id: 'fields.registration' })}
                                                            />
                                                        </div>
                                                        <div className="pull-left w-20">
                                                            <OverlayTrigger placement="top" overlay={tooltipNoVehicle}>
                                                                <div>
                                                                    <TableFilterCheckboxField
                                                                        identifier="no_vehicle"
                                                                        type={TableModelColumnFilteringType.equal}
                                                                        filters={filters}
                                                                        onChange={this.props.table.changeFilter}
                                                                        label=""
                                                                    />
                                                                </div>
                                                            </OverlayTrigger>
                                                        </div>
                                                        <div className="pull-left w-20">&nbsp;</div>
                                                        <div className="pull-left w-80">
                                                            <TableFilterInputField
                                                                identifier="trailer"
                                                                type={TableModelColumnFilteringType.string}
                                                                filters={filters}
                                                                onChange={this.props.table.changeFilter}
                                                                placeholder={this.props.intl.formatMessage({ id: 'fields.registration' })}
                                                            />
                                                        </div>
                                                        <div className="pull-left w-20">
                                                            <OverlayTrigger placement="top" overlay={tooltipNoTrailer}>
                                                                <div>
                                                                    <TableFilterCheckboxField
                                                                        identifier="no_trailer"
                                                                        type={TableModelColumnFilteringType.equal}
                                                                        filters={filters}
                                                                        onChange={this.props.table.changeFilter}
                                                                        label=""
                                                                    />
                                                                </div>
                                                            </OverlayTrigger>
                                                        </div>
                                                    </div>
                                                    <div className="pull-left wp-100">
                                                        <div className="pull-left w-100 m-t-xxs">
                                                            <TableFilterSelectField
                                                                identifier="dispatcher_id"
                                                                type={TableModelColumnFilteringType.equal}
                                                                filters={filters}
                                                                onChange={this.props.table.changeFilter}
                                                                values={getPrerequisitesArray(this.props.prerequisites.values.get('dispatchers'))}
                                                                prompt={this.props.intl.formatMessage({ id: 'fields.dispatcher' })}
                                                            />
                                                        </div>
                                                        <div className="pull-left w-20">&nbsp;</div>
                                                        <div className="pull-left w-100 m-t-xxs">
                                                            <TableFilterSelectField
                                                                identifier="driver_id"
                                                                type={TableModelColumnFilteringType.equal}
                                                                filters={filters}
                                                                onChange={this.props.table.changeFilter}
                                                                values={driversOptions}
                                                                prompt={this.props.intl.formatMessage({ id: 'fields.driver' })}
                                                            />
                                                        </div>
                                                    </div>
                                                    <PermissionsCheck hasAny={Object.values(PERMS_SPEDITION)}>
                                                        <div className="pull-left wp-100">
                                                            <div className="pull-left w-220 m-t-xxs">
                                                                <TableFilterInputField
                                                                    identifier="spedition"
                                                                    type={TableModelColumnFilteringType.string}
                                                                    filters={filters}
                                                                    onChange={this.props.table.changeFilter}
                                                                    placeholder={this.props.intl.formatMessage({ id: 'fields.spedition' })}
                                                                />
                                                            </div>
                                                        </div>
                                                    </PermissionsCheck>
                                                </th>
                                                <th className="w-90 text-right" />
                                                <PermissionsCheck hasAny={Object.values(PERMS_COST)}>
                                                    <th className="w-90 text-right" />
                                                    <th className="w-100 text-right" />
                                                </PermissionsCheck>
                                                <th className="w-50" />
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.props.transports.data.valueSeq().map(row => {
                                                const vehicle = !row.is_spedition && this.props.createGetVehicleByIDSelector(row.vehicle_id)
                                                const carrier = row.is_spedition && this.props.createGetCarrierByIDSelector(row.carrier_id)
                                                const carrierCurrency =
                                                    row.is_spedition && this.props.createGetCurrencyByIDSelector(row.carrier_currency_id)

                                                return (
                                                    <TransportsListRow
                                                        key={`TransportsListRow-${row.id}`}
                                                        onClick={this.onClick}
                                                        onDelete={this.onDelete}
                                                        onDuplicate={this.onDuplicate}
                                                        onCreateTemplate={this.onCreateTemplate}
	                                                    onCopySms={this.onCopySms}
	                                                    transpportCanSendSms={transpportCanSendSms}
                                                        redirect={this.props.redirect}
                                                        transport={row}
                                                        disponent={this.props.createGetUserByIDSelector(row.disponent_id)}
                                                        transportState={this.props.createGetTransportStateByIDSelector(row.transport_state_id)}
                                                        transportType={this.props.createGetTransportTypeByIDSelector(row.transport_type_id)}
                                                        cargoType={this.props.createGetCargoTypeByIDSelector(row.cargo_type_id)}
                                                        goodsType={this.props.createGetGoodsTypeByIDSelector(row.goods_type_id)}
                                                        vehicle={vehicle}
                                                        dispatcher={this.props.createGetUserByIDSelector(row.dispatcher_id)}
                                                        carrier={carrier}
                                                        carrierCurrency={carrierCurrency}
                                                        transportScoringSettings={this.props.prerequisites.values.get('transport_scoring_settings')}
                                                        createGetCustomerByIDSelector={this.props.createGetCustomerByIDSelector}
                                                        createGetCurrencyByIDSelector={this.props.createGetCurrencyByIDSelector}
                                                        createGetCountryByIDSelector={this.props.createGetCountryByIDSelector}
                                                        createGetTransportPointStateByIDSelector={this.props.createGetTransportPointStateByIDSelector}
                                                        createGetDriverByIDSelector={this.props.createGetDriverByIDSelector}
                                                        createGetVehicleByIDSelector={this.props.createGetVehicleByIDSelector}
                                                        createGetTrailerByIDSelector={this.props.createGetTrailerByIDSelector}
                                                        settings={this.props.settings}
                                                    />
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>

                                <div className="pull-left m-l-xxs m-t-md">
                                    <FormattedMessage id="pagination.totalRecords" />:{' '}
                                    {getListItemsCountMessage(
                                        clientSideItemsPerPage,
                                        this.props.transports.count,
                                        this.props.tableModel.getIn(['pagination', 'totalCount'])
                                    )}
                                </div>

                                <ReactPaginate
                                    containerClassName="pagination"
                                    breakLabel={<span className="disabled">...</span>}
                                    activeClassName="active"
                                    pageCount={getTotalNumberOfPages(this.props.tableModel, this.props.transports.count, clientSideItemsPerPage)}
                                    pageRangeDisplayed={10}
                                    marginPagesDisplayed={2}
                                    forcePage={this.props.tableModel.getIn(['pagination', 'current'])}
                                    onPageChange={this.props.table.changePage}
                                    previousLabel={this.props.intl.formatMessage({ id: 'pagination.previous' })}
                                    nextLabel={this.props.intl.formatMessage({ id: 'pagination.next' })}
                                />
                            </div>
                        </div>
                    </div>

                    <ConfirmDialog options={this.state.confirmDialog} />

                    <Modal show={Boolean(this.state.modalDuplicity.show)} onHide={this.handleDuplicityCancel} bsSize="sm">
                        <Modal.Header closeButton>
                            <Modal.Title>
                                <FormattedMessage id="confirmDialog.duplicityFound.title" />
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <FormattedMessage id="confirmDialog.duplicityFound.message" />
                            {duplicity && (
                                <div>
                                    <div className="row m-t-lg">
                                        <div className="col-sm-4">
                                            <FormattedMessage id="fields.templateName" />:
                                        </div>
                                        <div className="col-sm-8 text-right">
                                            <strong>{duplicity.template_name}</strong>
                                        </div>
                                    </div>
                                    <hr className="tiny" />
                                    <div className="row">
                                        <div className="col-sm-4">
                                            <FormattedMessage id="fields.customer" />:
                                        </div>
                                        <div className="col-sm-8 text-right">
                                            <strong>{duplicity.customer_name}</strong>
                                        </div>
                                    </div>
                                    <hr className="tiny" />
                                    <div className="row">
                                        <div className="col-sm-4">
                                            <FormattedMessage id="fields.loading" />:
                                        </div>
                                        <div className="col-sm-8 text-right">
                                            <strong>{duplicity.loading_address}</strong>
                                        </div>
                                    </div>
                                    <hr className="tiny" />
                                    <div className="row">
                                        <div className="col-sm-4">
                                            <FormattedMessage id="fields.unloading" />:
                                        </div>
                                        <div className="col-sm-8 text-right">
                                            <strong>{duplicity.unloading_address}</strong>
                                        </div>
                                    </div>
                                    <hr className="tiny" />
                                    <div className="row m-b-lg">
                                        <div className="col-sm-4">
                                            <FormattedMessage id="fields.price" />:
                                        </div>
                                        <div className="col-sm-8 text-right">
                                            <strong>{formatters.priceFormatter(duplicity.price, '0,0', duplicity.currency_code)}</strong>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </Modal.Body>
                        <Modal.Footer>
                            <PermissionsCheck hasAny={[PERMS.UPDATE, PERMS.CREATE]}>
                                <button className="btn btn-success m-r-xs" onClick={this.handleDuplicityConfirm}>
                                    <FormattedMessage id="confirmDialog.duplicityFound.accept" />
                                </button>
                            </PermissionsCheck>
                            <button className="btn btn-default" onClick={this.handleDuplicityCancel}>
                                <FormattedMessage id="confirmDialog.duplicityFound.cancel" />
                            </button>
                        </Modal.Footer>
                    </Modal>
                </div>
            </PermissionsCheck>
        )
    }
}

function mapStateToProps(state) {
    return {
        transports: createTableDataSelector({
            tableIdentifier,
            columns,
            dataSelector: getTransports,
            clientSideItemsPerPage,
        })(state),
        tableModel: createTableModelSelector(tableIdentifier)(state),
        prerequisites: preparePrerequisitesFetchStatusSelectors(componentIdentifier, prerequisites, state),
        deleting: getTransportDeleting(state),
        deleteError: getTransportDeleteError(state),
        duplication: getTransportDuplication(state),
        duplicateError: getTransportDuplicateError(state),
        duplicatedTransport: getTransportDuplicated(state),
        creationTemplate: getTransportCreationTemplate(state),
        createTemplateError: getTransportCreateTemplateError(state),
        createdTemplate: getTransportCreatedTemplate(state),
        duplicity: createGetDuplicitySelector()(state),
        smsFetching: getTransportSmsFetching(state),
        smsError: getTransportSmsError(state),
        smsData: getTransportSmsData(state),
        settings: getSettings(state),
        createGetUserByIDSelector: id => createGetUserByIDSelector(id)(state),
        createGetTransportTypeByIDSelector: id => createGetTransportTypeByIDSelector(id)(state),
        createGetCargoTypeByIDSelector: id => createGetCargoTypeByIDSelector(id)(state),
        createGetGoodsTypeByIDSelector: id => createGetGoodsTypeByIDSelector(id)(state),
        createGetCustomerByIDSelector: id => createGetCustomerByIDSelector(id)(state),
        createGetVehicleByIDSelector: id => createGetVehicleByIDSelector(id)(state),
        createGetTrailerByIDSelector: id => createGetTrailerByIDSelector(id)(state),
        createGetCurrencyByIDSelector: id => createGetCurrencyByIDSelector(id)(state),
        createGetTransportStateByIDSelector: id => createGetTransportStateByIDSelector(id)(state),
        createGetCountryByIDSelector: id => createGetCountryByIDSelector(id)(state),
        createGetTransportPointStateByIDSelector: id => createGetTransportPointStateByIDSelector(id)(state),
        createGetCarrierByIDSelector: id => createGetCarrierByIDSelector(id)(state),
        createGetDriverByIDSelector: id => createGetDriverByIDSelector(id)(state),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            ...bindActionCreators(
                {
                    ...transportsActionCreators,
                    ...preparePrerequisitesActionCreators(componentIdentifier, prerequisites),
                },
                dispatch
            ),
        },
        notify: (notification, type) => dispatch(Notifications.show(notification, type)),
        redirect: toURL => dispatch(push(toURL)),
        dispatch,
    }
}

function mergeProps(stateProps, dispatchProps, ownProps) {
    return {
        ...stateProps,
        ...dispatchProps,
        ...ownProps,
        table: bindActionCreators(prepareTableActionCreators(tableIdentifier, stateProps.tableModel), dispatchProps.dispatch),
    }
}

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps,
        mergeProps
    )(TransportsList)
)
