import React from 'react'
import { FormattedMessage } from 'react-intl'

export const NoPermissionsPage = () => {
    return (
        <div className="page-inner">
            <div id="main-wrapper">
                <div className="panel panel-white text-center">
                    <i className="fas fa-lock text-xl m-t-xxl" />
                    <h1>
                        <FormattedMessage id="permissions.no_permission" />
                    </h1>
                </div>
            </div>
        </div>
    )
}

export const NoPermissionsTab = () => {
    return (
        <div className="text-center">
            <i className="fas fa-lock text-xl m-t-xxl" />
            <h1>
                <FormattedMessage id="permissions.no_permission" />
            </h1>
        </div>
    )
}