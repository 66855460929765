import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { push } from 'react-router-redux'
import Notifications from 'react-notification-system-redux'
import validator from 'validator'
import { Tabs, Tab } from 'react-bootstrap'

import { url, handleCommonEditActions, validationMessage, getPrerequisitesArray } from '../../../common/helpers'
import * as formatters from '../../../common/formatters'
import { Form, InputField, CheckboxField, SelectField, SubmitButton } from '../../../common/form'
import { createGetUserByIDSelector, createGetCurrentUserStatusSelector } from '../selectors'
import * as usersActionCreators from '../actionCreators'
import { LoadingOverlay } from '../../../common/loading_overlay'
import { preparePrerequisitesFetchStatusSelectors } from '../../../common/prerequisites/selectors'
import { preparePrerequisitesActionCreators } from '../../../common/prerequisites/actionCreators'
import PermissionsCheck from '../../auth/components/PermissionsCheck'
import { PERMS } from '../permissions'

import UserLoginLogList from './UserLoginLogList'

const componentIdentifier = 'users_edit'
const prerequisites = ['user_roles', 'company_cost_centers']

class UsersEdit extends Component {
    defaultValues = {}

    validationRules = {
        first_name: value => validator.isEmpty(String(value)) && validationMessage('isEmpty'),
        last_name: value => validator.isEmpty(String(value)) && validationMessage('isEmpty'),
        email: [
            value => validator.isEmpty(String(value)) && validationMessage('isEmpty'),
            value => !validator.isEmail(String(value)) && validationMessage('isEmail'),
        ],
        password: (value, isEditing) =>
            (!validator.isEmpty(String(value)) || isEditing) &&
            !validator.isLength(String(value), { min: 6 }) &&
            validationMessage('isLength', { min: 6 }),
        rgb_text: value =>
            !validator.isEmpty(String(value)) &&
            !validator.isLength(String(value), { min: 7, max: 7 }) &&
            validationMessage('isLength', { min: 7, max: 7 }),
        rgb_bg: value =>
            !validator.isEmpty(String(value)) &&
            !validator.isLength(String(value), { min: 7, max: 7 }) &&
            validationMessage('isLength', { min: 7, max: 7 }),
    }

    state = {
        values: this.defaultValues,
        userLoginLog: {
            show: true,
        },
    }

    setValues = (values, callback) => {
        this.setState(
            {
                values,
            },
            callback
        )
    }

    handleChangeValues = values => {
        this.setValues(values)
    }

    handleSubmit = values => {
        this.setValues(values, () => {
            this.handleSave()
        })
    }

    handleSave = () => {
        this.props.actions.saveUser(this.state.values)
    }

    handleSelectTab = tabKey => {
        if (tabKey === 1) {
            this.loadUserLoginLog()
        }
    }

    loadUserLoginLog = () => {
        this.setState({
            userLoginLog: {
                show: true,
            },
        })
    }

    componentDidMount() {
        this.props.match.params.userID && this.props.actions.fetchUser(this.props.match.params.userID)
        this.props.actions.fetchPrerequisites()
    }

    componentWillReceiveProps(nextProps) {
        handleCommonEditActions(this.props, nextProps, () => {
            this.props.redirect(url(this.props.match, 'users'))
        })

        if (JSON.stringify(nextProps.user) !== JSON.stringify(this.props.user) || (nextProps.user && !this.state.values.id)) {
            this.setValues(nextProps.user.toJS())
        }
    }

    render() {
        document.title = formatters.titleFormatter(this.props.intl.formatMessage({ id: 'modules.users.heading' }))

        const user = this.props.user
        const mainColClassName = user ? 'col-lg-4' : 'col-md-8 col-md-offset-2 col-lg-6 col-lg-offset-3 col-xl-4 col-xl-offset-4'

        return (
            <PermissionsCheck hasAny={Object.values(PERMS)} noPermissionsPage>
                <div className="page-inner">
                    <div id="main-wrapper">
                        <div className="row hp-100">
                            <div className={mainColClassName} style={{ paddingRight: '0' }}>
                                <div className="panel panel-white">
                                    <div className="panel-body panel-padding">
                                        <div className="panel-head">
                                            <h4>
                                                <FormattedMessage id="modules.users.heading" /> -{' '}
                                                {this.props.user ? (
                                                    <FormattedMessage id="fields.itemEdit" />
                                                ) : (
                                                    <FormattedMessage id="fields.itemCreate" />
                                                )}
                                            </h4>
                                        </div>
                                        <LoadingOverlay active={this.props.prerequisites.status.get('fetching') || this.props.status.get('fetching')}>
                                            <PermissionsCheck hasAny={Object.values(PERMS)}>
                                                <Form
                                                    values={this.state.values}
                                                    validationRules={this.validationRules}
                                                    onChange={this.handleChangeValues}
                                                    onSubmit={this.handleSubmit}
                                                    isEdit={Boolean(this.props.user)}
                                                >
                                                    <InputField
                                                        id="first_name"
                                                        label={this.props.intl.formatMessage({ id: 'fields.firstName' })}
                                                        className="wp-50"
                                                    />

                                                    <InputField
                                                        id="last_name"
                                                        label={this.props.intl.formatMessage({ id: 'fields.lastName' })}
                                                        className="wp-50"
                                                    />

                                                    <SelectField
                                                        id="company_cost_center_id"
                                                        label={this.props.intl.formatMessage({ id: 'fields.costCenter' })}
                                                        values={getPrerequisitesArray(this.props.prerequisites.values.get('company_cost_centers'))}
                                                        className="wp-50"
                                                    />

                                                    <InputField
                                                        id="email"
                                                        label={this.props.intl.formatMessage({ id: 'fields.email' })}
                                                        className="wp-50"
                                                    />

                                                    <InputField
                                                        id="password"
                                                        type="password"
                                                        label={this.props.intl.formatMessage({ id: 'fields.password' })}
                                                        className="wp-50"
                                                    />

                                                    <InputField
                                                        id="phone"
                                                        label={this.props.intl.formatMessage({ id: 'fields.phone' })}
                                                        className="wp-50"
                                                    />

                                                    <InputField
                                                        id="rgb_text"
                                                        label={this.props.intl.formatMessage({ id: 'fields.textColor' })}
                                                        className="wp-50"
                                                    />

                                                    <InputField
                                                        id="rgb_bg"
                                                        label={this.props.intl.formatMessage({ id: 'fields.bgColor' })}
                                                        className="wp-50"
                                                    />

                                                    <CheckboxField id="is_blocked" label={this.props.intl.formatMessage({ id: 'fields.blocked' })} />

                                                    <SelectField
                                                        id="user_role_id"
                                                        label={this.props.intl.formatMessage({ id: 'fields.userRole' })}
                                                        values={getPrerequisitesArray(
                                                            this.props.prerequisites.values.get('user_roles'),
                                                            'id',
                                                            'name'
                                                        )}
                                                        className="wp-50"
                                                        nullable
                                                    />

                                                    <div className="btns-form">
                                                        <Link to={url(this.props.match, 'users')} className="btn btn-addon btn-default">
                                                            <i className="far fa-chevron-left" /> <FormattedMessage id="buttons.back" />
                                                        </Link>
                                                        <SubmitButton
                                                            perms={Object.values(PERMS)}
                                                            isEdit={Boolean(this.props.user)}
                                                            className="btn-addon pull-right"
                                                        />
                                                    </div>
                                                </Form>
                                            </PermissionsCheck>
                                        </LoadingOverlay>
                                    </div>
                                </div>
                            </div>
                            {user && (
                                <div className="col-lg-8 hp-100" style={{ paddingLeft: '5px' }}>
                                    <div className="panel panel-white hp-100 m-b-no">
                                        <div className="panel-body panel-padding hp-100">
                                            <Tabs
                                                defaultActiveKey={1}
                                                animation={false}
                                                id="detail-tabs"
                                                className="detail-tabs"
                                                onSelect={this.handleSelectTab}
                                            >
                                                <Tab
                                                    eventKey={1}
                                                    title={
                                                        <span>
                                                            <i className="fas fa-sign-in-alt m-r-xxs" />
                                                            {this.props.intl.formatMessage({ id: 'fields.loginLog' })}
                                                        </span>
                                                    }
                                                >
                                                    {this.state.userLoginLog.show && <UserLoginLogList user={user} />}
                                                </Tab>
                                            </Tabs>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </PermissionsCheck>
        )
    }
}

function mapStateToProps(state, ownProps) {
    return {
        user: createGetUserByIDSelector(ownProps.match.params.userID)(state),
        status: createGetCurrentUserStatusSelector()(state),
        prerequisites: preparePrerequisitesFetchStatusSelectors(componentIdentifier, prerequisites, state),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            ...bindActionCreators(
                {
                    ...usersActionCreators,
                    ...preparePrerequisitesActionCreators(componentIdentifier, prerequisites),
                },
                dispatch
            ),
        },
        redirect: toURL => dispatch(push(toURL)),
        notify: (notification, type) => dispatch(Notifications.show(notification, type)),
    }
}

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(UsersEdit)
)
