// Fetch list

export const FETCH = 'transports.FETCH'
export const FETCH_STARTED = 'transports.FETCH_STARTED'
export const FETCH_FULFILLED = 'transports.FETCH_FULFILLED'
export const FETCH_REJECTED = 'transports.FETCH_REJECTED'
export const FETCH_CANCELLED = 'transports.FETCH_CANCELLED'

export const SORTING_CHANGED = 'transports.SORTING_CHANGED'

// Fetch one

export const FETCH_ONE = 'transports.FETCH_ONE'
export const FETCH_ONE_STARTED = 'transports.FETCH_ONE_STARTED'
export const FETCH_ONE_FULFILLED = 'transports.FETCH_ONE_FULFILLED'
export const FETCH_ONE_REJECTED = 'transports.FETCH_ONE_REJECTED'
export const FETCH_ONE_CANCELLED = 'transports.FETCH_ONE_CANCELLED'

// Customer info

export const LOAD_CUSTOMER_INFO = 'transports.LOAD_CUSTOMER_INFO'
export const LOAD_CUSTOMER_INFO_STARTED = 'transports.LOAD_CUSTOMER_INFO_STARTED'
export const LOAD_CUSTOMER_INFO_FULFILLED = 'transports.LOAD_CUSTOMER_INFO_FULFILLED'
export const LOAD_CUSTOMER_INFO_REJECTED = 'transports.LOAD_CUSTOMER_INFO_REJECTED'
export const LOAD_CUSTOMER_INFO_CANCELLED = 'transports.LOAD_CUSTOMER_INFO_CANCELLED'
export const CLEAR_CUSTOMER_INFO = 'transports.CLEAR_CUSTOMER_INFO'

// Save

export const SAVE = 'transports.SAVE'
export const SAVE_STARTED = 'transports.SAVE_STARTED'
export const SAVE_FULFILLED = 'transports.SAVE_FULFILLED'
export const SAVE_REJECTED = 'transports.SAVE_REJECTED'
export const SAVE_CANCELLED = 'transports.SAVE_CANCELLED'
export const SAVE_DUPLICITY_FOUND = 'transports.SAVE_DUPLICITY_FOUND'

// Delete

export const DELETE = 'transports.DELETE'
export const DELETE_STARTED = 'transports.DELETE_STARTED'
export const DELETE_FULFILLED = 'transports.DELETE_FULFILLED'
export const DELETE_REJECTED = 'transports.DELETE_REJECTED'
export const DELETE_CANCELLED = 'transports.DELETE_CANCELLED'

// Restore

export const RESTORE = 'transports.RESTORE'
export const RESTORE_STARTED = 'transports.RESTORE_STARTED'
export const RESTORE_FULFILLED = 'transports.RESTORE_FULFILLED'
export const RESTORE_REJECTED = 'transports.RESTORE_REJECTED'
export const RESTORE_CANCELLED = 'transports.RESTORE_CANCELLED'

// Approve

export const APPROVE = 'transports.APPROVE'
export const APPROVE_STARTED = 'transports.APPROVE_STARTED'
export const APPROVE_FULFILLED = 'transports.APPROVE_FULFILLED'
export const APPROVE_REJECTED = 'transports.APPROVE_REJECTED'
export const APPROVE_CANCELLED = 'transports.APPROVE_CANCELLED'

// Copy sms

export const COPY_SMS = 'transports.COPY_SMS'
export const COPY_SMS_STARTED = 'transports.COPY_SMS_STARTED'
export const COPY_SMS_FULFILLED = 'transports.COPY_SMS_FULFILLED'
export const COPY_SMS_REJECTED = 'transports.COPY_SMS_REJECTED'
export const COPY_SMS_CANCELLED = 'transports.COPY_SMS_CANCELLED'

// Send to vehicle

export const SEND_TO_VEHICLE = 'transports.SEND_TO_VEHICLE'
export const SEND_TO_VEHICLE_STARTED = 'transports.SEND_TO_VEHICLE_STARTED'
export const SEND_TO_VEHICLE_FULFILLED = 'transports.SEND_TO_VEHICLE_FULFILLED'
export const SEND_TO_VEHICLE_REJECTED = 'transports.SEND_TO_VEHICLE_REJECTED'
export const SEND_TO_VEHICLE_CANCELLED = 'transports.SEND_TO_VEHICLE_CANCELLED'

// Delete from vehicle

export const DELETE_FROM_VEHICLE = 'transports.DELETE_FROM_VEHICLE'
export const DELETE_FROM_VEHICLE_STARTED = 'transports.DELETE_FROM_VEHICLE_STARTED'
export const DELETE_FROM_VEHICLE_FULFILLED = 'transports.DELETE_FROM_VEHICLE_FULFILLED'
export const DELETE_FROM_VEHICLE_REJECTED = 'transports.DELETE_FROM_VEHICLE_REJECTED'
export const DELETE_FROM_VEHICLE_CANCELLED = 'transports.DELETE_FROM_VEHICLE_CANCELLED'

// Clear

export const CLEAR = 'transports.CLEAR'

// Create from template

export const CREATE_FROM_TEMPLATE = 'transports.CREATE_FROM_TEMPLATE'
export const CREATE_FROM_TEMPLATE_STARTED = 'transports.CREATE_FROM_TEMPLATE_STARTED'
export const CREATE_FROM_TEMPLATE_FULFILLED = 'transports.CREATE_FROM_TEMPLATE_FULFILLED'
export const CREATE_FROM_TEMPLATE_REJECTED = 'transports.CREATE_FROM_TEMPLATE_REJECTED'
export const CREATE_FROM_TEMPLATE_CANCELED = 'transports.CREATE_FROM_TEMPLATE_CANCELED'

// Duplicate

export const DUPLICATE = 'transports.DUPLICATE'
export const DUPLICATE_STARTED = 'transports.DUPLICATE_STARTED'
export const DUPLICATE_FULFILLED = 'transports.DUPLICATE_FULFILLED'
export const DUPLICATE_REJECTED = 'transports.DUPLICATE_REJECTED'
export const DUPLICATE_CANCELED = 'transports.DUPLICATE_CANCELED'

// Create template

export const CREATE_TEMPLATE = 'transports.CREATE_TEMPLATE'
export const CREATE_TEMPLATE_STARTED = 'transports.CREATE_TEMPLATE_STARTED'
export const CREATE_TEMPLATE_FULFILLED = 'transports.CREATE_TEMPLATE_FULFILLED'
export const CREATE_TEMPLATE_REJECTED = 'transports.CREATE_TEMPLATE_REJECTED'
export const CREATE_TEMPLATE_CANCELED = 'transports.CREATE_TEMPLATE_CANCELED'
export const CREATE_TEMPLATE_DUPLICITY_FOUND = 'transports.CREATE_TEMPLATE_DUPLICITY_FOUND'

// Save event

export const SAVE_EVENT = 'transports.SAVE_EVENT'
export const SAVE_EVENT_STARTED = 'transports.SAVE_EVENT_STARTED'
export const SAVE_EVENT_FULFILLED = 'transports.SAVE_EVENT_FULFILLED'
export const SAVE_EVENT_REJECTED = 'transports.SAVE_EVENT_REJECTED'
export const SAVE_EVENT_CANCELLED = 'transports.SAVE_EVENT_CANCELLED'

// Delete event

export const DELETE_EVENT = 'transports.DELETE_EVENT'
export const DELETE_EVENT_STARTED = 'transports.DELETE_EVENT_STARTED'
export const DELETE_EVENT_FULFILLED = 'transports.DELETE_EVENT_FULFILLED'
export const DELETE_EVENT_REJECTED = 'transports.DELETE_EVENT_REJECTED'
export const DELETE_EVENT_CANCELLED = 'transports.DELETE_EVENT_CANCELLED'

// Create events

export const CREATE_EVENTS = 'transports.CREATE_EVENTS'
export const CREATE_EVENTS_STARTED = 'transports.CREATE_EVENTS_STARTED'
export const CREATE_EVENTS_FULFILLED = 'transports.CREATE_EVENTS_FULFILLED'
export const CREATE_EVENTS_REJECTED = 'transports.CREATE_EVENTS_REJECTED'
export const CREATE_EVENTS_CANCELLED = 'transports.CREATE_EVENTS_CANCELLED'

// Change point state

export const CHANGE_POINT_STATE = 'transports.CHANGE_POINT_STATE'
export const CHANGE_POINT_STATE_STARTED = 'transports.CHANGE_POINT_STATE_STARTED'
export const CHANGE_POINT_STATE_FULFILLED = 'transports.CHANGE_POINT_STATE_FULFILLED'
export const CHANGE_POINT_STATE_REJECTED = 'transports.CHANGE_POINT_STATE_REJECTED'
export const CHANGE_POINT_STATE_CANCELLED = 'transports.CHANGE_POINT_STATE_CANCELLED'

// Fetch log

export const FETCH_LOG = 'transports.FETCH_LOG'
export const FETCH_LOG_STARTED = 'transports.FETCH_LOG_STARTED'
export const FETCH_LOG_FULFILLED = 'transports.FETCH_LOG_FULFILLED'
export const FETCH_LOG_REJECTED = 'transports.FETCH_LOG_REJECTED'
export const FETCH_LOG_CANCELLED = 'transports.FETCH_LOG_CANCELLED'

// Export

export const EXPORT = 'transports.EXPORT'
export const EXPORT_FULFILLED = 'transports.EXPORT_FULFILLED'

// Recount planned route

export const RECOUNT_PLANNED_ROUTE = 'transports.RECOUNT_PLANNED_ROUTE'
export const RECOUNT_PLANNED_ROUTE_STARTED = 'transports.RECOUNT_PLANNED_ROUTE_STARTED'
export const RECOUNT_PLANNED_ROUTE_FULFILLED = 'transports.RECOUNT_PLANNED_ROUTE_FULFILLED'
export const RECOUNT_PLANNED_ROUTE_REJECTED = 'transports.RECOUNT_PLANNED_ROUTE_REJECTED'
export const RECOUNT_PLANNED_ROUTE_CANCELLED = 'transports.RECOUNT_PLANNED_ROUTE_CANCELLED'

// Recount specified route

export const RECOUNT_SPECIFIED_ROUTE = 'transports.RECOUNT_SPECIFIED_ROUTE'
export const RECOUNT_SPECIFIED_ROUTE_STARTED = 'transports.RECOUNT_SPECIFIED_ROUTE_STARTED'
export const RECOUNT_SPECIFIED_ROUTE_FULFILLED = 'transports.RECOUNT_SPECIFIED_ROUTE_FULFILLED'
export const RECOUNT_SPECIFIED_ROUTE_REJECTED = 'transports.RECOUNT_SPECIFIED_ROUTE_REJECTED'
export const RECOUNT_SPECIFIED_ROUTE_CANCELLED = 'transports.RECOUNT_SPECIFIED_ROUTE_CANCELLED'

// Recount real route

export const RECOUNT_REAL_ROUTE = 'transports.RECOUNT_REAL_ROUTE'
export const RECOUNT_REAL_ROUTE_STARTED = 'transports.RECOUNT_REAL_ROUTE_STARTED'
export const RECOUNT_REAL_ROUTE_FULFILLED = 'transports.RECOUNT_REAL_ROUTE_FULFILLED'
export const RECOUNT_REAL_ROUTE_REJECTED = 'transports.RECOUNT_REAL_ROUTE_REJECTED'
export const RECOUNT_REAL_ROUTE_CANCELLED = 'transports.RECOUNT_REAL_ROUTE_CANCELLED'

// Real vehicle positions

export const FETCH_REAL_VEHICLE_POSITIONS = 'transports.FETCH_REAL_VEHICLE_POSITIONS'
export const FETCH_REAL_VEHICLE_POSITIONS_STARTED = 'transports.FETCH_REAL_VEHICLE_POSITIONS_STARTED'
export const FETCH_REAL_VEHICLE_POSITIONS_FULFILLED = 'transports.FETCH_REAL_VEHICLE_POSITIONS_FULFILLED'
export const FETCH_REAL_VEHICLE_POSITIONS_REJECTED = 'transports.FETCH_REAL_VEHICLE_POSITIONS_REJECTED'
export const FETCH_REAL_VEHICLE_POSITIONS_CANCELLED = 'transports.FETCH_REAL_VEHICLE_POSITIONS_CANCELLED'

// Vehicle data source

export const FETCH_VEHICLE_DATA_SOURCE = 'transports.FETCH_VEHICLE_DATA_SOURCE'
export const FETCH_VEHICLE_DATA_SOURCE_STARTED = 'transports.FETCH_VEHICLE_DATA_SOURCE_STARTED'
export const FETCH_VEHICLE_DATA_SOURCE_FULFILLED = 'transports.FETCH_VEHICLE_DATA_SOURCE_FULFILLED'
export const FETCH_VEHICLE_DATA_SOURCE_REJECTED = 'transports.FETCH_VEHICLE_DATA_SOURCE_REJECTED'
export const FETCH_VEHICLE_DATA_SOURCE_CANCELLED = 'transports.FETCH_VEHICLE_DATA_SOURCE_CANCELLED'
export const CLEAR_VEHICLE_DATA_SOURCE = 'transports.CLEAR_VEHICLE_DATA_SOURCE'

// Vehicle home country

export const FETCH_VEHICLE_HOME_COUNTRY = 'transports.FETCH_VEHICLE_HOME_COUNTRY'
export const FETCH_VEHICLE_HOME_COUNTRY_STARTED = 'transports.FETCH_VEHICLE_HOME_COUNTRY_STARTED'
export const FETCH_VEHICLE_HOME_COUNTRY_FULFILLED = 'transports.FETCH_VEHICLE_HOME_COUNTRY_FULFILLED'
export const FETCH_VEHICLE_HOME_COUNTRY_REJECTED = 'transports.FETCH_VEHICLE_HOME_COUNTRY_REJECTED'
export const FETCH_VEHICLE_HOME_COUNTRY_CANCELLED = 'transports.FETCH_VEHICLE_HOME_COUNTRY_CANCELLED'

// Fetch preview

export const FETCH_PREVIEW = 'transports.FETCH_PREVIEW'
export const FETCH_PREVIEW_STARTED = 'transports.FETCH_PREVIEW_STARTED'
export const FETCH_PREVIEW_FULFILLED = 'transports.FETCH_PREVIEW_FULFILLED'
export const FETCH_PREVIEW_REJECTED = 'transports.FETCH_PREVIEW_REJECTED'
export const FETCH_PREVIEW_CANCELLED = 'transports.FETCH_PREVIEW_CANCELLED'

// Fetch preview

export const FETCH_MAP_PREVIEW = 'transports.FETCH_MAP_PREVIEW'
export const FETCH_MAP_PREVIEW_STARTED = 'transports.FETCH_MAP_PREVIEW_STARTED'
export const FETCH_MAP_PREVIEW_FULFILLED = 'transports.FETCH_MAP_PREVIEW_FULFILLED'
export const FETCH_MAP_PREVIEW_REJECTED = 'transports.FETCH_MAP_PREVIEW_REJECTED'
export const FETCH_MAP_PREVIEW_CANCELLED = 'transports.FETCH_MAP_PREVIEW_CANCELLED'

// Check customers

export const CHECK_CUSTOMERS = 'transports.CHECK_CUSTOMERS'
export const CHECK_CUSTOMERS_STARTED = 'transports.CHECK_CUSTOMERS_STARTED'
export const CHECK_CUSTOMERS_FULFILLED = 'transports.CHECK_CUSTOMERS_FULFILLED'
export const CHECK_CUSTOMERS_REJECTED = 'transports.CHECK_CUSTOMERS_REJECTED'
export const CHECK_CUSTOMERS_CANCELLED = 'transports.CHECK_CUSTOMERS_CANCELLED'

// Check carriers

export const CHECK_CARRIERS = 'transports.CHECK_CARRIERS'
export const CHECK_CARRIERS_STARTED = 'transports.CHECK_CARRIERS_STARTED'
export const CHECK_CARRIERS_FULFILLED = 'transports.CHECK_CARRIERS_FULFILLED'
export const CHECK_CARRIERS_REJECTED = 'transports.CHECK_CARRIERS_REJECTED'
export const CHECK_CARRIERS_CANCELLED = 'transports.CHECK_CARRIERS_CANCELLED'

// Fetch orders info

export const FETCH_ORDERS_INFO = 'transports.FETCH_ORDERS_INFO'
export const FETCH_ORDERS_INFO_STARTED = 'transports.FETCH_ORDERS_INFO_STARTED'
export const FETCH_ORDERS_INFO_FULFILLED = 'transports.FETCH_ORDERS_INFO_FULFILLED'
export const FETCH_ORDERS_INFO_REJECTED = 'transports.FETCH_ORDERS_INFO_REJECTED'
export const FETCH_ORDERS_INFO_CANCELLED = 'transports.FETCH_ORDERS_INFO_CANCELLED'

// Create order

export const CREATE_ORDER = 'transports.CREATE_ORDER'
export const CREATE_ORDER_STARTED = 'transports.CREATE_ORDER_STARTED'
export const CREATE_ORDER_FULFILLED = 'transports.CREATE_ORDER_FULFILLED'
export const CREATE_ORDER_REJECTED = 'transports.CREATE_ORDER_REJECTED'
export const CREATE_ORDER_CANCELLED = 'transports.CREATE_ORDER_CANCELLED'

// Send order

export const SEND_ORDER_TO_EMAIL = 'transports.SEND_ORDER_TO_EMAIL'
export const SEND_ORDER_TO_EMAIL_STARTED = 'transports.SEND_ORDER_TO_EMAIL_STARTED'
export const SEND_ORDER_TO_EMAIL_FULFILLED = 'transports.SEND_ORDER_TO_EMAIL_FULFILLED'
export const SEND_ORDER_TO_EMAIL_REJECTED = 'transports.SEND_ORDER_TO_EMAIL_REJECTED'
export const SEND_ORDER_TO_EMAIL_CANCELLED = 'transports.SEND_ORDER_TO_EMAIL_CANCELLED'

// Load files

export const FETCH_FILES = 'transports.FETCH_FILES'
export const FETCH_FILES_STARTED = 'transports.FETCH_FILES_STARTED'
export const FETCH_FILES_FULFILLED = 'transports.FETCH_FILES_FULFILLED'
export const FETCH_FILES_REJECTED = 'transports.FETCH_FILES_REJECTED'
export const FETCH_FILES_CANCELLED = 'transports.FETCH_FILES_CANCELLED'

// Upload files

export const UPLOAD_FILES = 'transports.UPLOAD_FILES'
export const UPLOAD_FILES_STARTED = 'transports.UPLOAD_FILES_STARTED'
export const UPLOAD_FILES_FULFILLED = 'transports.UPLOAD_FILES_FULFILLED'
export const UPLOAD_FILES_REJECTED = 'transports.UPLOAD_FILES_REJECTED'
export const UPLOAD_FILES_CANCELLED = 'transports.UPLOAD_FILES_CANCELLED'

// Delete file

export const DELETE_FILE = 'transports.DELETE_FILE'
export const DELETE_FILE_STARTED = 'transports.DELETE_FILE_STARTED'
export const DELETE_FILE_FULFILLED = 'transports.DELETE_FILE_FULFILLED'
export const DELETE_FILE_REJECTED = 'transports.DELETE_FILE_REJECTED'
export const DELETE_FILE_CANCELLED = 'transports.DELETE_FILE_CANCELLED'

// Load goods

export const FETCH_GOODS = 'transports.FETCH_GOODS'
export const FETCH_GOODS_STARTED = 'transports.FETCH_GOODS_STARTED'
export const FETCH_GOODS_FULFILLED = 'transports.FETCH_GOODS_FULFILLED'
export const FETCH_GOODS_REJECTED = 'transports.FETCH_GOODS_REJECTED'
export const FETCH_GOODS_CANCELLED = 'transports.FETCH_GOODS_CANCELLED'

// Save goods

export const SAVE_GOODS = 'transports.SAVE_GOODS'
export const SAVE_GOODS_STARTED = 'transports.SAVE_GOODS_STARTED'
export const SAVE_GOODS_FULFILLED = 'transports.SAVE_GOODS_FULFILLED'
export const SAVE_GOODS_REJECTED = 'transports.SAVE_GOODS_REJECTED'
export const SAVE_GOODS_CANCELLED = 'transports.SAVE_GOODS_CANCELLED'

// Delete goods

export const DELETE_GOODS = 'transports.DELETE_GOODS'
export const DELETE_GOODS_STARTED = 'transports.DELETE_GOODS_STARTED'
export const DELETE_GOODS_FULFILLED = 'transports.DELETE_GOODS_FULFILLED'
export const DELETE_GOODS_REJECTED = 'transports.DELETE_GOODS_REJECTED'
export const DELETE_GOODS_CANCELLED = 'transports.DELETE_GOODS_CANCELLED'

// Load parts

export const FETCH_PARTS = 'transports.FETCH_PARTS'
export const FETCH_PARTS_STARTED = 'transports.FETCH_PARTS_STARTED'
export const FETCH_PARTS_FULFILLED = 'transports.FETCH_PARTS_FULFILLED'
export const FETCH_PARTS_REJECTED = 'transports.FETCH_PARTS_REJECTED'
export const FETCH_PARTS_CANCELLED = 'transports.FETCH_PARTS_CANCELLED'

// Save part

export const SAVE_PART = 'transports.SAVE_PART'
export const SAVE_PART_STARTED = 'transports.SAVE_PART_STARTED'
export const SAVE_PART_FULFILLED = 'transports.SAVE_PART_FULFILLED'
export const SAVE_PART_REJECTED = 'transports.SAVE_PART_REJECTED'
export const SAVE_PART_CANCELLED = 'transports.SAVE_PART_CANCELLED'

// Save parts order

export const SAVE_PARTS_ORDER = 'transports.SAVE_PARTS_ORDER'
export const SAVE_PARTS_ORDER_STARTED = 'transports.SAVE_PARTS_ORDER_STARTED'
export const SAVE_PARTS_ORDER_FULFILLED = 'transports.SAVE_PARTS_ORDER_FULFILLED'
export const SAVE_PARTS_ORDER_REJECTED = 'transports.SAVE_PARTS_ORDER_REJECTED'
export const SAVE_PARTS_ORDER_CANCELLED = 'transports.SAVE_PARTS_ORDER_CANCELLED'

// Delete part

export const DELETE_PART = 'transports.DELETE_PART'
export const DELETE_PART_STARTED = 'transports.DELETE_PART_STARTED'
export const DELETE_PART_FULFILLED = 'transports.DELETE_PART_FULFILLED'
export const DELETE_PART_REJECTED = 'transports.DELETE_PART_REJECTED'
export const DELETE_PART_CANCELLED = 'transports.DELETE_PART_CANCELLED'

// Correct event times

export const CORRECT_EVENT_TIMES = 'transports.CORRECT_EVENT_TIMES'
export const CORRECT_EVENT_TIMES_STARTED = 'transports.CORRECT_EVENT_TIMES_STARTED'
export const CORRECT_EVENT_TIMES_FULFILLED = 'transports.CORRECT_EVENT_TIMES_FULFILLED'
export const CORRECT_EVENT_TIMES_REJECTED = 'transports.CORRECT_EVENT_TIMES_REJECTED'
export const CORRECT_EVENT_TIMES_CANCELLED = 'transports.CORRECT_EVENT_TIMES_CANCELLED'

// Correct point event times

export const CORRECT_POINT_EVENT_TIMES = 'transports.CORRECT_POINT_EVENT_TIMES'
export const CORRECT_POINT_EVENT_TIMES_STARTED = 'transports.CORRECT_POINT_EVENT_TIMES_STARTED'
export const CORRECT_POINT_EVENT_TIMES_FULFILLED = 'transports.CORRECT_POINT_EVENT_TIMES_FULFILLED'
export const CORRECT_POINT_EVENT_TIMES_REJECTED = 'transports.CORRECT_POINT_EVENT_TIMES_REJECTED'
export const CORRECT_POINT_EVENT_TIMES_CANCELLED = 'transports.CORRECT_POINT_EVENT_TIMES_CANCELLED'

// Fetch allowances

export const FETCH_ALLOWANCES = 'transports.FETCH_ALLOWANCES'
export const FETCH_ALLOWANCES_STARTED = 'transports.FETCH_ALLOWANCES_STARTED'
export const FETCH_ALLOWANCES_FULFILLED = 'transports.FETCH_ALLOWANCES_FULFILLED'
export const FETCH_ALLOWANCES_REJECTED = 'transports.FETCH_ALLOWANCES_REJECTED'
export const FETCH_ALLOWANCES_CANCELLED = 'transports.FETCH_ALLOWANCES_CANCELLED'

// Add allowances

export const SAVE_ALLOWANCES = 'transports.SAVE_ALLOWANCES'
export const SAVE_ALLOWANCES_STARTED = 'transports.SAVE_ALLOWANCES_STARTED'
export const SAVE_ALLOWANCES_FULFILLED = 'transports.SAVE_ALLOWANCES_FULFILLED'
export const SAVE_ALLOWANCES_REJECTED = 'transports.SAVE_ALLOWANCES_REJECTED'
export const SAVE_ALLOWANCES_CANCELLED = 'transports.SAVE_ALLOWANCES_CANCELLED'

// Delete allowance

export const DELETE_ALLOWANCE = 'transports.DELETE_ALLOWANCE'
export const DELETE_ALLOWANCE_STARTED = 'transports.DELETE_ALLOWANCE_STARTED'
export const DELETE_ALLOWANCE_FULFILLED = 'transports.DELETE_ALLOWANCE_FULFILLED'
export const DELETE_ALLOWANCE_REJECTED = 'transports.DELETE_ALLOWANCE_REJECTED'
export const DELETE_ALLOWANCE_CANCELLED = 'transports.DELETE_ALLOWANCE_CANCELLED'

// Re_assign allowance

export const REASSIGN_ALLOWANCES = 'transports.REASSIGN_ALLOWANCES'
export const REASSIGN_ALLOWANCES_STARTED = 'transports.REASSIGN_ALLOWANCES_STARTED'
export const REASSIGN_ALLOWANCES_FULFILLED = 'transports.REASSIGN_ALLOWANCES_FULFILLED'
export const REASSIGN_ALLOWANCES_REJECTED = 'transports.REASSIGN_ALLOWANCES_REJECTED'
export const REASSIGN_ALLOWANCES_CANCELLED = 'transports.REASSIGN_ALLOWANCES_CANCELLED'

// Fetch

export const FETCH_SCORING = 'transportScoring.FETCH_SCORING'
export const FETCH_SCORING_STARTED = 'transportScoring.FETCH_SCORING_STARTED'
export const FETCH_SCORING_FULFILLED = 'transportScoring.FETCH_SCORING_FULFILLED'
export const FETCH_SCORING_REJECTED = 'transportScoring.FETCH_SCORING_REJECTED'
export const FETCH_SCORING_CANCELLED = 'transportScoring.FETCH_SCORING_CANCELLED'

// Save scoring

export const SAVE_SCORING = 'transportScoring.SAVE_SCORING'
export const SAVE_SCORING_STARTED = 'transportScoring.SAVE_SCORING_STARTED'
export const SAVE_SCORING_FULFILLED = 'transportScoring.SAVE_SCORING_FULFILLED'
export const SAVE_SCORING_REJECTED = 'transportScoring.SAVE_SCORING_REJECTED'
export const SAVE_SCORING_CANCELLED = 'transportScoring.SAVE_SCORING_CANCELLED'

// Fetch suggest for carrier

export const FETCH_SUGGEST_FOR_CARRIER = 'transports.FETCH_SUGGEST_FOR_CARRIER'
export const FETCH_SUGGEST_FOR_CARRIER_STARTED = 'transports.FETCH_SUGGEST_FOR_CARRIER_STARTED'
export const FETCH_SUGGEST_FOR_CARRIER_FULFILLED = 'transports.FETCH_SUGGEST_FOR_CARRIER_FULFILLED'
export const FETCH_SUGGEST_FOR_CARRIER_REJECTED = 'transports.FETCH_SUGGEST_FOR_CARRIER_REJECTED'
export const FETCH_SUGGEST_FOR_CARRIER_CANCELLED = 'transports.FETCH_SUGGEST_FOR_CARRIER_CANCELLED'

// Fetch import headers

export const FETCH_IMPORT_HEADERS = 'transports.FETCH_IMPORT_HEADERS'
export const FETCH_IMPORT_HEADERS_STARTED = 'transports.FETCH_IMPORT_HEADERS_STARTED'
export const FETCH_IMPORT_HEADERS_FULFILLED = 'transports.FETCH_IMPORT_HEADERS_FULFILLED'
export const FETCH_IMPORT_HEADERS_REJECTED = 'transports.FETCH_IMPORT_HEADERS_REJECTED'
export const FETCH_IMPORT_HEADERS_CANCELLED = 'transports.FETCH_IMPORT_HEADERS_CANCELLED'

// Fetch import values

export const FETCH_IMPORT_ITEMS = 'transports.FETCH_IMPORT_ITEMS'
export const FETCH_IMPORT_ITEMS_STARTED = 'transports.FETCH_IMPORT_ITEMS_STARTED'
export const FETCH_IMPORT_ITEMS_FULFILLED = 'transports.FETCH_IMPORT_ITEMS_FULFILLED'
export const FETCH_IMPORT_ITEMS_REJECTED = 'transports.FETCH_IMPORT_ITEMS_REJECTED'
export const FETCH_IMPORT_ITEMS_CANCELLED = 'transports.FETCH_IMPORT_ITEMS_CANCELLED'

// Import transports

export const IMPORT_TRANSPORTS = 'transports.IMPORT_TRANSPORTS'
export const IMPORT_TRANSPORTS_STARTED = 'transports.IMPORT_TRANSPORTS_STARTED'
export const IMPORT_TRANSPORTS_FULFILLED = 'transports.IMPORT_TRANSPORTS_FULFILLED'
export const IMPORT_TRANSPORTS_REJECTED = 'transports.IMPORT_TRANSPORTS_REJECTED'
export const IMPORT_TRANSPORTS_CANCELLED = 'transports.IMPORT_TRANSPORTS_CANCELLED'

// Fetch import prices headers

export const FETCH_IMPORT_PRICES_HEADERS = 'transports.FETCH_IMPORT_PRICES_HEADERS'
export const FETCH_IMPORT_PRICES_HEADERS_STARTED = 'transports.FETCH_IMPORT_PRICES_HEADERS_STARTED'
export const FETCH_IMPORT_PRICES_HEADERS_FULFILLED = 'transports.FETCH_IMPORT_PRICES_HEADERS_FULFILLED'
export const FETCH_IMPORT_PRICES_HEADERS_REJECTED = 'transports.FETCH_IMPORT_PRICES_HEADERS_REJECTED'
export const FETCH_IMPORT_PRICES_HEADERS_CANCELLED = 'transports.FETCH_IMPORT_PRICES_HEADERS_CANCELLED'

// Fetch import prices values

export const FETCH_IMPORT_PRICES_ITEMS = 'transports.FETCH_IMPORT_PRICES_ITEMS'
export const FETCH_IMPORT_PRICES_ITEMS_STARTED = 'transports.FETCH_IMPORT_PRICES_ITEMS_STARTED'
export const FETCH_IMPORT_PRICES_ITEMS_FULFILLED = 'transports.FETCH_IMPORT_PRICES_ITEMS_FULFILLED'
export const FETCH_IMPORT_PRICES_ITEMS_REJECTED = 'transports.FETCH_IMPORT_PRICES_ITEMS_REJECTED'
export const FETCH_IMPORT_PRICES_ITEMS_CANCELLED = 'transports.FETCH_IMPORT_PRICES_ITEMS_CANCELLED'

// Import transports prices

export const IMPORT_TRANSPORTS_PRICES = 'transports.IMPORT_TRANSPORTS_PRICES'
export const IMPORT_TRANSPORTS_PRICES_STARTED = 'transports.IMPORT_TRANSPORTS_PRICES_STARTED'
export const IMPORT_TRANSPORTS_PRICES_FULFILLED = 'transports.IMPORT_TRANSPORTS_PRICES_FULFILLED'
export const IMPORT_TRANSPORTS_PRICES_REJECTED = 'transports.IMPORT_TRANSPORTS_PRICES_REJECTED'
export const IMPORT_TRANSPORTS_PRICES_CANCELLED = 'transports.IMPORT_TRANSPORTS_PRICES_CANCELLED'