import * as actionTypes from './actionTypes'

// Fetch

export const fetchReportTrailerCosts = (year, inclCostCenters, inclVehicles, inclDrivers) => ({
    type: actionTypes.FETCH,
    payload: {
        year,
        inclCostCenters,
        inclVehicles,
        inclDrivers
    },
})

export const fetchReportTrailerCostsFulfilled = data => ({
    type: actionTypes.FETCH_FULFILLED,
    payload: data,
})

export const fetchReportTrailerCostsRejected = message => ({
    type: actionTypes.FETCH_REJECTED,
    payload: message,
})

export const fetchReportTrailerCostsCancelled = () => ({
    type: actionTypes.FETCH_CANCELLED,
})

// Export

export const exportReportTrailerCosts = (year, filters, inclCostCenters, inclVehicles, inclDrivers) => ({
    type: actionTypes.EXPORT,
    payload: {
        year,
        filters,
        inclCostCenters,
        inclVehicles,
        inclDrivers
    },
})

// Clear

export const clearReportTrailerCosts = () => ({
    type: actionTypes.CLEAR,
})

// Fetch details

export const fetchReportTrailerCostsDetails = (id, year, month, inclCostCenters, inclVehicles, inclDrivers) => ({
    type: actionTypes.FETCH_DETAILS,
    payload: {
        id,
        year,
        month,
        inclCostCenters,
        inclVehicles,
        inclDrivers
    },
})

export const fetchReportTrailerCostsDetailsFulfilled = data => ({
    type: actionTypes.FETCH_DETAILS_FULFILLED,
    payload: data,
})

export const fetchReportTrailerCostsDetailsRejected = message => ({
    type: actionTypes.FETCH_DETAILS_REJECTED,
    payload: message,
})

export const fetchReportTrailerCostsDetailsCancelled = () => ({
    type: actionTypes.FETCH_DETAILS_CANCELLED,
})

// Export

export const exportReportTrailerCostsDetails = (id, year, month, inclCostCenters, inclVehicles, inclDrivers) => ({
    type: actionTypes.EXPORT_DETAILS,
    payload: {
        id,
        year,
        month,
        inclCostCenters,
        inclVehicles,
        inclDrivers
    },
})

// Clear

export const clearReportTrailerCostsDetails = () => ({
    type: actionTypes.CLEAR_DETAILS,
})
