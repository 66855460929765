import { Observable } from 'rxjs'
import { normalize, schema } from 'normalizr'

import { request, parseAPIError, DownloadRequest } from '../../common/api'
import { customerAddressSchema } from '../../common/normalizrSchemas'
import { normalizedEntitiesToRecordMap } from '../../common/helpers'

import * as actionTypes from './actionTypes'
import * as actionCreators from './actionCreators'
import * as tableModelActionTypes from '../../common/table/actionTypes'
import * as tableModelActionCreators from '../../common/table/actionCreators'
import * as countriesActionCreators from '../countries/actionCreators'
import * as customersActionCreators from '../customers/actionCreators'
import * as customerAddressTypesActionCreators from '../customer_address_types/actionCreators'
import { resolveModelState } from '../../common/table/helpers'
import { CustomerAddress } from './model'
import { Country } from '../countries/model'
import { Customer } from '../customers/model'
import { CustomerAddressType } from '../customer_address_types/model'

// Fetch

const fetchCustomerAddressesEpic = (action$, store) => {
    const tableIdentifier = 'customer_addresses_list'
    const serverSide = true

    const triggeringAction = serverSide
        ? action$.ofType(actionTypes.FETCH, tableModelActionTypes.CONFIGURATION_CHANGED)
        : action$.ofType(actionTypes.FETCH)

    return triggeringAction
        .filter(action => action.type !== tableModelActionTypes.CONFIGURATION_CHANGED || action.payload.tableIdentifier === tableIdentifier)
        .switchMap(action => {
            const modelState = resolveModelState(tableIdentifier, store.getState(), action)

            const requestParams = {
                method: 'GET',
                path: `customer-addresses/list`,
            }

            if (serverSide) {
                requestParams.method = 'POST'
                requestParams.path += `?page=${modelState.getIn(['pagination', 'current']) + 1}`
                requestParams.body = {
                    sorting: modelState.get('sorting').toJS(),
                    filters: modelState.get('filters').toJS(),
                }
            }

            return Observable.concat(
                Observable.of({
                    type: actionTypes.FETCH_STARTED,
                }),
                request(requestParams)
                    .switchMap(ajaxResponse => {
                        const normalizedData = normalize(
                            serverSide ? ajaxResponse.response.data : ajaxResponse.response,
                            new schema.Array(customerAddressSchema)
                        )

                        const customerAddresses = normalizedEntitiesToRecordMap(
                            normalizedData.entities.customer_addresses,
                            CustomerAddress,
                            normalizedData.result
                        )
                        const countries = normalizedEntitiesToRecordMap(normalizedData.entities.countries, Country)
                        const customers = normalizedEntitiesToRecordMap(normalizedData.entities.customers, Customer)
                        const customerAddressTypes = normalizedEntitiesToRecordMap(
                            normalizedData.entities.customer_address_types,
                            CustomerAddressType
                        )

                        const observables = [
                            Observable.of(countriesActionCreators.fetchCountriesFulfilled(countries)),
                            Observable.of(customerAddressTypesActionCreators.fetchCustomerAddressTypesFulfilled(customerAddressTypes)),
                            Observable.of(customersActionCreators.fetchCustomersFulfilled(customers)),
                            Observable.of(actionCreators.fetchCustomerAddressesFulfilled(customerAddresses)),
                        ]

                        serverSide &&
                            observables.push(
                                Observable.of(
                                    tableModelActionCreators.updatePagination(
                                        tableIdentifier,
                                        ajaxResponse.response.last_page,
                                        ajaxResponse.response.current_page - 1,
                                        ajaxResponse.response.total
                                    )
                                )
                            )

                        return Observable.concat(...observables)
                    })
                    .catch(error => Observable.of(actionCreators.fetchCustomerAddressesRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.FETCH_CANCELLED, actionTypes.FETCH, tableModelActionTypes.CONFIGURATION_CHANGED))
            )
        })
}

// Fetch one

const fetchCustomerAddressEpic = (action$, store) =>
    action$
        .ofType(actionTypes.FETCH_ONE)
        .filter(() => !store.getState().customerAddresses.getIn(['current', 'fetching']))
        .switchMap(action =>
            Observable.concat(
                Observable.of({
                    type: actionTypes.FETCH_ONE_STARTED,
                }),
                request({
                    path: `customer-addresses/${action.payload}`,
                    method: 'GET',
                })
                    .switchMap(ajaxResponse => {
                        const normalizedData = normalize(ajaxResponse.response, customerAddressSchema)

                        const customerAddresses = normalizedEntitiesToRecordMap(normalizedData.entities.customer_addresses, CustomerAddress)
                        const countries = normalizedEntitiesToRecordMap(normalizedData.entities.countries, Country)
                        const customers = normalizedEntitiesToRecordMap(normalizedData.entities.customers, Customer)

                        return Observable.concat(
                            Observable.of(actionCreators.fetchCustomerAddressesFulfilled(customerAddresses)),
                            Observable.of(actionCreators.fetchCustomerAddressFulfilled()),
                            Observable.of(countriesActionCreators.fetchCountriesFulfilled(countries)),
                            Observable.of(customersActionCreators.fetchCustomersFulfilled(customers))
                        )
                    })
                    .catch(error => Observable.of(actionCreators.fetchCustomerAddressesRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.FETCH_ONE_CANCELLED))
            )
        )

// Save

const saveCustomerAddressEpic = (action$, store) =>
    action$
        .ofType(actionTypes.SAVE)
        .filter(() => !store.getState().customerAddresses.getIn(['current', 'saving']))
        .switchMap(action => {
            const values = Object.assign({}, action.payload.values)
            if (action.payload.ignoreDuplicity) {
                values.ignore_duplicity = 1
            }

            let path = `customer-addresses`
            let method = 'POST'

            if (values.id) {
                path = `customer-addresses/${values.id}`
                method = 'PUT'

                delete values.id
            }

            return Observable.concat(
                Observable.of({
                    type: actionTypes.SAVE_STARTED,
                }),
                request({
                    path,
                    method,
                    body: values,
                })
                    .switchMap(ajaxResponse => {
                        if (ajaxResponse.response.duplicity_found) {
                            return Observable.concat(Observable.of(actionCreators.saveCustomerAddressDuplicityFound(ajaxResponse.response.duplicity)))
                        }

                        const normalizedData = normalize(ajaxResponse.response, customerAddressSchema)

                        const customerAddresses = normalizedEntitiesToRecordMap(normalizedData.entities.customer_addresses, CustomerAddress)
                        const customerAddress = customerAddresses.valueSeq().first()

                        return Observable.concat(Observable.of(actionCreators.saveCustomerAddressFulfilled(customerAddress)))
                    })
                    .catch(error => Observable.of(actionCreators.saveCustomerAddressRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.SAVE_CANCELLED))
            )
        })

// Delete

const deleteCustomerAddressEpic = (action$, store) =>
    action$
        .ofType(actionTypes.DELETE)
        .filter(() => !store.getState().customerAddresses.getIn(['deletion', 'inProgress']))
        .switchMap(action =>
            Observable.concat(
                Observable.of({
                    type: actionTypes.DELETE_STARTED,
                }),
                request({
                    path: `customer-addresses/${action.payload}`,
                    method: 'DELETE',
                })
                    .switchMap(() => Observable.of(actionCreators.deleteCustomerAddressFulfilled(action.payload)))
                    .catch(error => Observable.of(actionCreators.deleteCustomerAddressRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.DELETE_CANCELLED))
            )
        )



// Restore

const restoreCustomerAddressEpic = (action$, store) =>
    action$
        .ofType(actionTypes.RESTORE)
        .filter(() => !store.getState().customerAddresses.getIn(['restoring', 'inProgress']))
        .switchMap(action =>
            Observable.concat(
                Observable.of({
                    type: actionTypes.RESTORE_STARTED,
                }),
                request({
                    path: `customer-addresses/${action.payload}/restore`,
                    method: 'GET',
                })
                    .switchMap(ajaxResponse => {
                        const normalizedData = normalize(ajaxResponse.response, customerAddressSchema)
                        const items = normalizedEntitiesToRecordMap(normalizedData.entities.customer_addresses, CustomerAddress)
                        const item = items.valueSeq().first()
                        return Observable.concat(Observable.of(actionCreators.restoreCustomerAddressFulfilled(item)))
                    })
                    .catch(error => Observable.of(actionCreators.restoreCustomerAddressRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.RESTORE_CANCELLED))
            )
        )


// Export

const exportCustomerAddressesEpic = (action$, store) =>
    action$.ofType(actionTypes.EXPORT).switchMap(action => {
        const filters = JSON.stringify(action.payload.filters)
        const sorting = JSON.stringify(action.payload.sorting)
        const token = store.getState().auth.get('accessToken')

        new DownloadRequest({
            url: `customer-addresses/export?filters=${filters}&sorting=${sorting}&token=${token}`,
        }).run()

        return Observable.concat(
            Observable.of({
                type: actionTypes.EXPORT_FULFILLED,
            })
        )
    })

// Find

const findCustomerAddressesEpic = (action$, store) =>
    action$
        .ofType(actionTypes.FIND)
        .filter(() => !store.getState().customerAddresses.getIn(['finding', 'fetching']))
        .switchMap(action =>
            Observable.concat(
                Observable.of({
                    type: actionTypes.FIND_STARTED,
                }),
                request({
                    path: 'customer-addresses/find',
                    method: 'POST',
                    body: Object.assign({}, action.payload),
                })
                    .switchMap(ajaxResponse =>
                        Observable.concat(Observable.of(actionCreators.findCustomerAddressesFulfilled(ajaxResponse.response.addresses)))
                    )
                    .catch(error => Observable.of(actionCreators.findCustomerAddressesRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.FIND_CANCELLED))
            )
        )

export default [
    fetchCustomerAddressesEpic,
    fetchCustomerAddressEpic,
    saveCustomerAddressEpic,
    deleteCustomerAddressEpic,
    restoreCustomerAddressEpic,
    exportCustomerAddressesEpic,
    findCustomerAddressesEpic,
]
