import React, { Component } from 'react'
import {Link, withRouter} from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import moment from 'moment'
import { Modal } from 'react-bootstrap'

import { PeriodPicker } from '../../../common/period_picker'
import * as periodPickerConstants from '../../../common/period_picker/constants'
import * as formatters from '../../../common/formatters'
import { LoadingOverlay } from '../../../common/loading_overlay'

import * as reportCustomerResultsActionCreators from '../actionCreators'
import { getYear, getData, getFetching, getDetailsData, getDetailsFetching } from '../selectors'

import { prepareTableActionCreators } from '../../../common/table/actionCreators'
import { TableFilterInputField } from '../../../common/table/components'
import { getActiveFilters } from '../../../common/table/helpers'
import { TableModelColumnFilteringType } from '../../../common/table/model'
import { createTableModelSelector } from '../../../common/table/selectors'
import PermissionsCheck from '../../auth/components/PermissionsCheck'
import { PERMS } from '../permissions'

const tableIdentifier = 'report_customer_results'

class ReportCustomerResults extends Component {
    state = {
        modalDetails: {
            show: false,
            costCenterId: null,
            customerId: null,
            month: null,
        },
    }

    getYear = () => {
        if (this.props.year) {
            return parseInt(this.props.year)
        }

        return parseInt(this.props.match.params.year ? this.props.match.params.year : moment().format('YYYY'))
    }

    refresh = () => {
        this.props.actions.fetchReportCustomerResults(this.getYear())
    }

    resetFilters = () => {
        this.props.table.resetFilters()
    }

    isFilterActive = () => getActiveFilters(this.props.tableModel).size > 0

    handleChangeYearMonth = format => {
        const year = format.year.toString()
        const path = `/report-customer-results/${year}`
        this.props.history.push(path)
    }

    // Export
    handleExport = e => {
        e && e.preventDefault()

        this.props.actions.exportReportCustomerResults(this.getYear(), this.props.tableModel.get('filters').toJS())
    }

    // Detail
    handleShowDetails = (e, costCenterId, customerId, month) => {
        e && e.preventDefault()

        this.setState(
            {
                modalDetails: {
                    show: true,
                    costCenterId,
                    customerId,
                    month,
                },
            },
            () => {
                this.props.actions.fetchReportCustomerResultsDetails(
                    this.getYear(),
                    costCenterId,
                    customerId,
                    month,
                    this.props.tableModel.get('filters').toJS()
                )
            }
        )
    }

    handleHideDetails = e => {
        e && e.preventDefault()

        this.setState(
            {
                modalDetails: {
                    show: false,
                    costCenterId: null,
                    customerId: null,
                    month: null,
                },
            },
            () => {
                this.props.actions.clearReportCustomerResultsDetails()
            }
        )
    }

    handleExportDetails = e => {
        e && e.preventDefault()

        this.props.actions.exportReportCustomerResultsDetails(
            this.getYear(),
            this.state.modalDetails.costCenterId,
            this.state.modalDetails.customerId,
            this.state.modalDetails.month,
            this.props.tableModel.get('filters').toJS()
        )
    }

    componentDidMount() {
        this.props.actions.fetchReportCustomerResults(this.getYear())
    }

    componentWillUnmount() {
        this.props.actions.clearReportCustomerResults()
    }

    componentWillReceiveProps(nextProps) {
        const parsedYear = parseInt(nextProps.match.params.year ? nextProps.match.params.year : moment().format('YYYY'))

        if (parsedYear !== this.getYear()) {
            this.props.actions.clearReportCustomerResults()
            this.props.actions.fetchReportCustomerResults(parsedYear)
        }
    }

    render() {
        document.title = formatters.titleFormatter(this.props.intl.formatMessage({ id: 'modules.reportCustomerResults.heading' }))

        const data = this.props.data
        const momentFrom = moment(`${this.getYear()}-01-01`, 'YYYY-MM-DD')
        const momentTo = moment(momentFrom).endOf('year')

        const monthKeys = [...Array(12).keys()]
        const monthTotals = {}
        let total = 0

        monthKeys.forEach(monthKey => {
            monthTotals[monthKey] = 0
        })

        const filters = this.props.tableModel.get('filters')

        const detailsData = this.props.detailsData

        let detailsDataTotalKm = 0
        let detailsDataTotalPrice = 0

        /* eslint-disable jsx-a11y/click-events-have-key-events */
        /* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
        return (
            <PermissionsCheck hasAny={Object.values(PERMS)} noPermissionsPage>
                <div className="page-inner">
                    <div id="main-wrapper">
                        <div className="panel panel-white">
                            <div className="panel-body">
                                <div className="wp-100 pull-left m-b-xs">
                                    <h4 className="pull-left">
                                        <span className="pull-left">
                                            <FormattedMessage id="modules.reportCustomerResults.heading" />
                                        </span>
                                    </h4>
                                    <div className="btns-list">
                                        <PeriodPicker
                                            className={'m-r-sm'}
                                            momentFrom={momentFrom}
                                            momentTo={momentTo}
                                            onChange={(momentFrom, momentTo, format) => this.handleChangeYearMonth(format)}
                                            allowedPickers={[periodPickerConstants.PERIOD_PICKER_TYPE_YEAR]}
                                            forbidPickType
                                        />
                                        {this.isFilterActive() > 0 && (
                                            <button className="btn btn-default btn-addon m-r-xs" onClick={this.resetFilters}>
                                                <i className="far fa-times" /> <FormattedMessage id="buttons.resetFilters" />
                                            </button>
                                        )}
                                        <button className="btn btn-default btn-addon m-r-xs" onClick={this.refresh}>
                                            <i className="far fa-sync-alt" /> <FormattedMessage id="buttons.refresh" />
                                        </button>
                                        <PermissionsCheck has={[PERMS.EXPORT]}>
                                            <button className="btn btn-primary btn-addon" onClick={this.handleExport}>
                                                <i className="far fa-file-excel" /> <FormattedMessage id="buttons.exportToXLS" />
                                            </button>
                                        </PermissionsCheck>
                                    </div>
                                </div>

                                <div className="table-container">
                                    <table className="table table-striped table-hover table-fixed-header">
                                        <thead>
                                            <tr>
                                                <th className="w-160">
                                                    <FormattedMessage id="fields.costCenter" />
                                                </th>
                                                <th className="w-160">
                                                    <FormattedMessage id="fields.dispatcher" />
                                                </th>
                                                <th className="w-max wm-300">
                                                    <FormattedMessage id="fields.customer" />
                                                </th>
                                                {monthKeys.map(key => {
                                                    const month = moment().set('month', key)

                                                    return (
                                                        <th key={`month-${key}`} className="w-90 text-right">
                                                            {this.props.intl.formatMessage({ id: `monthNames.${month.format('MM')}` })}
                                                        </th>
                                                    )
                                                })}
                                                <th className="w-140 text-right">
                                                    <FormattedMessage id="fields.total" />
                                                </th>
                                            </tr>
                                            <tr className="filters">
                                                <th className="w-160">
                                                    <TableFilterInputField
                                                        identifier="cost_center_name"
                                                        type={TableModelColumnFilteringType.string}
                                                        filters={filters}
                                                        onChange={this.props.table.changeFilter}
                                                    />
                                                </th>
                                                <th className="w-160">
                                                    <TableFilterInputField
                                                        identifier="dispatcher_name"
                                                        type={TableModelColumnFilteringType.string}
                                                        filters={filters}
                                                        onChange={this.props.table.changeFilter}
                                                    />
                                                </th>
                                                <th className="w-max wm-300">
                                                    <TableFilterInputField
                                                        identifier="customer_name"
                                                        type={TableModelColumnFilteringType.string}
                                                        filters={filters}
                                                        onChange={this.props.table.changeFilter}
                                                    />
                                                </th>
                                                {monthKeys.map(key => (
                                                    <th key={`month-${key}`} className="w-90" />
                                                ))}
                                                <th className="w-140 text-right" />
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data &&
                                                data.customers &&
                                                data.customers.map(customer => {
                                                    if (
                                                        filters.getIn(['customer_name', 'value']) &&
                                                        // eslint-disable-next-line prettier/prettier
                                                        customer.name.toLowerCase().indexOf(filters.getIn(['customer_name', 'value']).toLowerCase()) < 0
                                                        ) {
                                                        return null
                                                    }

                                                    if (
                                                        filters.getIn(['dispatcher_name', 'value']) &&
                                                        customer.dispatcherName
                                                            .toLowerCase()
                                                            .indexOf(filters.getIn(['dispatcher_name', 'value']).toLowerCase()) < 0
                                                    ) {
                                                        return null
                                                    }

                                                    const rows = []

                                                    customer.costCenters.forEach(costCenter => {
                                                        if (
                                                            filters.getIn(['cost_center_name', 'value']) &&
                                                            costCenter.name
                                                                .toLowerCase()
                                                                .indexOf(filters.getIn(['cost_center_name', 'value']).toLowerCase()) < 0
                                                        ) {
                                                            return null
                                                        }

                                                        let rowTotal = 0
                                                        monthKeys.forEach(monthKey => {
                                                            if (costCenter.months[monthKey + 1]) {
                                                                monthTotals[monthKey] += costCenter.months[monthKey + 1]
                                                                rowTotal += costCenter.months[monthKey + 1]
                                                                total += costCenter.months[monthKey + 1]
                                                            }
                                                        })

                                                        rows.push(
                                                            <tr key={`row-${costCenter.id}-${customer.id}`}>
                                                                <td
                                                                    className="w-160 td-clickable"
                                                                    onClick={e => {
                                                                        this.handleShowDetails(e, costCenter.id, null, null)
                                                                    }}
                                                                >
                                                                    {costCenter.name}
                                                                </td>
                                                                <td className="w-160">{customer.dispatcherName}</td>
                                                                <td
                                                                    className="w-max wm-300 td-clickable"
                                                                    onClick={e => {
                                                                        this.handleShowDetails(e, null, customer.id, null)
                                                                    }}
                                                                >
                                                                    {customer.name}
                                                                </td>
                                                                {monthKeys.map(monthKey => (
                                                                    <td
                                                                        key={`month-${monthKey}`}
                                                                        className="w-90 text-right td-clickable"
                                                                        onClick={e => {
                                                                            this.handleShowDetails(e, costCenter.id, customer.id, monthKey + 1)
                                                                        }}
                                                                    >
                                                                        {costCenter.months[monthKey + 1]
                                                                            ? formatters.integerFormatter(costCenter.months[monthKey + 1])
                                                                            : ''}
                                                                    </td>
                                                                ))}
                                                                <td
                                                                    className="w-140 text-right td-clickable"
                                                                    onClick={e => {
                                                                        this.handleShowDetails(e, costCenter.id, customer.id, null)
                                                                    }}
                                                                >
                                                                    <strong>{formatters.priceFormatter(rowTotal, '0,0')}</strong>
                                                                </td>
                                                            </tr>
                                                        )

                                                        return null
                                                    })

                                                    return rows
                                                })}

                                            <tr className="b-top row-sum">
                                                <td className="w-160">
                                                    <strong>
                                                        <FormattedMessage id="fields.total" />:
                                                    </strong>
                                                </td>
                                                <td className="w-160" />
                                                <td className="w-max wm-300" />
                                                {monthKeys.map(monthKey => (
                                                    <td
                                                        key={`total-${monthKey}`}
                                                        className="w-90 text-right td-clickable"
                                                        onClick={e => {
                                                            this.handleShowDetails(e, null, null, monthKey + 1)
                                                        }}
                                                    >
                                                        <strong>
                                                            {monthTotals[monthKey] ? formatters.integerFormatter(monthTotals[monthKey]) : ''}
                                                        </strong>
                                                    </td>
                                                ))}
                                                <td
                                                    className="w-140 text-right td-clickable"
                                                    onClick={e => {
                                                        this.handleShowDetails(e, null, null, null)
                                                    }}
                                                >
                                                    <strong>{formatters.priceFormatter(total, '0,0')}</strong>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Modal show={Boolean(this.state.modalDetails.show)} onHide={this.handleHideDetails} className="modal-size-xl">
                        <Modal.Header closeButton>
                            <Modal.Title>{detailsData ? detailsData.title : null}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <LoadingOverlay active={this.props.detailsFetching}>
                                <div className="btns-list">
                                    {detailsData && detailsData.transports.length > 0 && (
                                        <PermissionsCheck has={[PERMS.EXPORT]}>
                                            <button className="btn btn-primary btn-addon m-r-xs" onClick={this.handleExportDetails}>
                                                <i className="far fa-file-excel" /> <FormattedMessage id="buttons.exportToXLS" />
                                            </button>
                                        </PermissionsCheck>
                                    )}
                                </div>

                                <div className="table-container">
                                    <table className="table table-striped table-hover table-fixed-header">
                                        <thead>
                                            <tr>
                                                <th className="w-80 text-center">
                                                    <FormattedMessage id="fields.date" />
                                                </th>
                                                <th className="w-80 text-center">
                                                    <FormattedMessage id="fields.number" />
                                                </th>
                                                <th className="w-max wm-140">
                                                    <FormattedMessage id="fields.orderNumber" />
                                                </th>
                                                <th className="w-160">
                                                    <FormattedMessage id="fields.loadingCity" />
                                                </th>
                                                <th className="w-60 text-center">
                                                    <FormattedMessage id="fields.loadingCountry" />
                                                </th>
                                                <th className="w-160">
                                                    <FormattedMessage id="fields.unloadingCity" />
                                                </th>
                                                <th className="w-60 text-center">
                                                    <FormattedMessage id="fields.unloadingCountry" />
                                                </th>
                                                <th className="w-100 text-center">
                                                    <FormattedMessage id="fields.registration" />
                                                </th>
                                                <th className="w-140">
                                                    <FormattedMessage id="fields.dispatcher" />
                                                </th>
                                                <th className="w-100 text-right">
                                                    <FormattedMessage id="fields.km" />
                                                </th>
                                                <th className="w-100 text-right">
                                                    <FormattedMessage id="fields.price" />
                                                </th>
                                                <th className="w-120 text-right">
                                                    <FormattedMessage id="fields.priceCZK" />
                                                </th>
                                                <th className="w-90 text-right">
                                                    <FormattedMessage id="fields.profitKM" />
                                                </th>
                                                <th className="w-90 text-right">
                                                    <FormattedMessage id="fields.profitKMCZK" />
                                                </th>
                                                <th className="w-60 text-right">
                                                    <FormattedMessage id="fields.exchangeRate" />
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {detailsData &&
                                                detailsData.transports.map(transport => {
                                                    detailsDataTotalKm += transport.km
                                                    detailsDataTotalPrice += transport.price_czk

                                                    const datetime = moment(transport.datetime)

                                                    return (
                                                        <tr key={`transport-${transport.number}`}>
                                                            <td className="w-80 text-center">{formatters.dateFormatter(datetime)}</td>
                                                            <td className="w-80 text-center">
                                                                <Link to={`/transports/${transport.number}`}>
                                                                    {transport.number}
                                                                </Link>
                                                            </td>
                                                            <td className="w-max wm-140">{transport.order_number}</td>
                                                            <td className="w-160">{transport.loading_city}</td>
                                                            <td className="w-60 text-center">{transport.loading_country}</td>
                                                            <td className="w-160">{transport.unloading_city}</td>
                                                            <td className="w-60 text-center">{transport.unloading_country}</td>
                                                            <td className="w-100 text-center">{transport.registration}</td>
                                                            <td className="w-140">{transport.dispatcher}</td>
                                                            <td className="w-100 text-right">{formatters.distanceFormatter(transport.km)}</td>
                                                            <td className="w-100 text-right">
                                                                {formatters.priceFormatter(transport.price, '0,0', transport.currency_code)}
                                                            </td>
                                                            <td className="w-120 text-right">{formatters.priceFormatter(transport.price_czk)}</td>
                                                            <td className="w-90 text-right">{formatters.floatFormatter(transport.profit_km, 2)}</td>
                                                            <td className="w-90 text-right">
                                                                {formatters.floatFormatter(transport.profit_km_czk, 2)}
                                                            </td>
                                                            <td className="w-60 text-right">
                                                                {formatters.floatFormatter(transport.currency_rate, 2)}
                                                            </td>
                                                        </tr>
                                                    )
                                                })}

                                            <tr className="b-top row-sum">
                                                <td className="w-max" colSpan={9}>
                                                    <strong>
                                                        <FormattedMessage id="fields.total" />:
                                                    </strong>
                                                </td>
                                                <td className="w-100 text-right">
                                                    <strong>{formatters.distanceFormatter(detailsDataTotalKm)}</strong>
                                                </td>
                                                <td className="w-100 text-right" />
                                                <td className="w-120 text-right">
                                                    <strong>{formatters.priceFormatter(detailsDataTotalPrice)}</strong>
                                                </td>
                                                <td className="w-90 text-right" />
                                                <td className="w-90 text-right">
                                                    <strong>
                                                        {formatters.floatFormatter(
                                                            detailsDataTotalKm ? detailsDataTotalPrice / detailsDataTotalKm : 0
                                                        )}
                                                    </strong>
                                                </td>
                                                <td className="w-60 text-right" />
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </LoadingOverlay>
                        </Modal.Body>
                        <Modal.Footer>
                            <button className="btn btn-default" onClick={this.handleHideDetails}>
                                <FormattedMessage id="confirmDialog.duplicityFound.cancel" />
                            </button>
                        </Modal.Footer>
                    </Modal>
                </div>
            </PermissionsCheck>
        )
        /* eslint-enable jsx-a11y/no-noninteractive-element-interactions */
        /* eslint-enable jsx-a11y/click-events-have-key-events */
    }
}

function mapStateToProps(state) {
    return {
        tableModel: createTableModelSelector(tableIdentifier)(state),
        year: getYear(state),
        data: getData(state),
        fetching: getFetching(state),
        detailsData: getDetailsData(state),
        detailsFetching: getDetailsFetching(state),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            ...bindActionCreators(
                {
                    ...reportCustomerResultsActionCreators,
                },
                dispatch
            ),
        },
        dispatch,
    }
}

function mergeProps(stateProps, dispatchProps, ownProps) {
    return {
        ...stateProps,
        ...dispatchProps,
        ...ownProps,
        table: bindActionCreators(prepareTableActionCreators(tableIdentifier, stateProps.tableModel), dispatchProps.dispatch),
    }
}

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps,
        mergeProps
    )(withRouter(ReportCustomerResults))
)
