import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import moment from 'moment'
import { Modal } from 'react-bootstrap'

import { PeriodPicker } from '../../../common/period_picker'
import * as periodPickerConstants from '../../../common/period_picker/constants'
import * as formatters from '../../../common/formatters'
import { LoadingOverlay } from '../../../common/loading_overlay'

import * as ReportCostCenterCostsActionCreators from '../actionCreators'
import { getYear, getData, getFetching, getDetailsData, getDetailsFetching } from '../selectors'

import { prepareTableActionCreators } from '../../../common/table/actionCreators'
import { TableFilterInputField } from '../../../common/table/components'
import { getActiveFilters } from '../../../common/table/helpers'
import { TableModelColumnFilteringType } from '../../../common/table/model'
import { createTableModelSelector } from '../../../common/table/selectors'

import { preparePrerequisitesActionCreators } from '../../../common/prerequisites/actionCreators'
import { preparePrerequisitesFetchStatusSelectors } from '../../../common/prerequisites/selectors'
import PermissionsCheck from '../../auth/components/PermissionsCheck'
import { PERMS } from '../permissions'
import CheckboxField from "../../../common/form/components/CheckboxField";

const componentIdentifier = 'report_cost_center_costs'
const prerequisites = ['currencies', 'cost_types']

const tableIdentifier = 'report_cost_center_costs'

class ReportCostCenterCosts extends Component {
    state = {
        modalDetails: {
            show: false,
            id: null,
            month: null,
            name: null
        },
        inclVehicles: true,
        inclDrivers: true,
        inclTrailers: true
    }

    // list
    getYear = () => {
        if (this.props.year) {
            return parseInt(this.props.year)
        }

        return parseInt(this.props.match.params.year ? this.props.match.params.year : moment().format('YYYY'))
    }

    refresh = () => {
        this.props.actions.fetchReportCostCenterCosts(this.getYear(), this.state.inclVehicles, this.state.inclDrivers, this.state.inclTrailers)
    }

    resetFilters = () => {
        this.props.table.resetFilters()
    }

    isFilterActive = () => getActiveFilters(this.props.tableModel).size > 0

    handleChangeYearMonth = format => {
        const year = format.year.toString()
        const path = `/report-cost-center-costs/${year}`
        this.props.history.push(path)
    }

    changeInclVehicles = value => {
        this.setState({
            ...this.state,
            inclVehicles: value
        }, () => {
            this.refresh()
        })
    }

    changeInclDrivers = value => {
        this.setState({
            ...this.state,
            inclDrivers: value
        }, () => {
            this.refresh()
        })
    }

    changeInclTrailers = value => {
        this.setState({
            ...this.state,
            inclTrailers: value
        }, () => {
            this.refresh()
        })
    }

    // export
    handleExport = e => {
        e && e.preventDefault()

        this.props.actions.exportReportCostCenterCosts(this.getYear(), this.props.tableModel.get('filters').toJS(), this.state.inclVehicles, this.state.inclDrivers, this.state.inclTrailers)
    }

    // detail
    handleShowDetails = (e, id, month, name) => {
        e && e.preventDefault()

        this.setState({
            modalDetails: {
                show: true,
                id,
                month,
                name
            },
        }, () => {
            this.props.actions.fetchReportCostCenterCostsDetails(id, this.getYear(), month, this.state.inclVehicles, this.state.inclDrivers, this.state.inclTrailers)
        })
    }

    handleHideDetails = e => {
        e && e.preventDefault()

        this.setState({
            modalDetails: {
                show: false,
                id: null,
                month: null,
                name: null,
            },
        }, () => {
            this.refresh()
            this.props.actions.clearReportCostCenterCostsDetails()
        })
    }

    // export detail
    handleExportDetails = e => {
        e && e.preventDefault()

        this.props.actions.exportReportCostCenterCostsDetails(this.state.modalDetails.id, this.getYear(), this.state.modalDetails.month, this.state.inclVehicles, this.state.inclDrivers, this.state.inclTrailers)
    }

    componentDidMount() {
        this.props.actions.fetchReportCostCenterCosts(this.getYear(), this.state.inclVehicles, this.state.inclDrivers, this.state.inclTrailers)
        this.props.actions.fetchPrerequisites()
    }

    componentWillUnmount() {
        this.props.actions.clearReportCostCenterCosts()
    }

    componentWillReceiveProps(nextProps) {
        const parsedYear = parseInt(nextProps.match.params.year ? nextProps.match.params.year : moment().format('YYYY'))

        if (parsedYear !== this.getYear()) {
            this.props.actions.clearReportCostCenterCosts()
            this.props.actions.fetchReportCostCenterCosts(parsedYear, this.state.inclVehicles, this.state.inclDrivers, this.state.inclTrailers)
        }
    }

    render() {
        document.title = formatters.titleFormatter(this.props.intl.formatMessage({ id: 'modules.reportCostCenterCosts.heading' }))

        const data = this.props.data && this.props.data.cost_centers
        const total = this.props.data && this.props.data.total

        const detailsData = this.props.detailsData && this.props.detailsData.costs
        const detailsTotal = this.props.detailsData && this.props.detailsData.total

        const momentFrom = moment(`${this.getYear()}-01-01`, 'YYYY-MM-DD')
        const momentTo = moment(momentFrom).endOf('year')

        const monthKeys = [...Array(12).keys()]

        const filters = this.props.tableModel.get('filters')

        return (<PermissionsCheck hasAny={Object.values(PERMS)} noPermissionsPage>
            <div className="page-inner">
                <div id="main-wrapper">
                    <div className="panel panel-white">
                        <div className="panel-body">
                            <div className="wp-100 pull-left m-b-xs">
                                <h4 className="pull-left">
                                    <span className="pull-left">
                                        <FormattedMessage id="modules.reportCostCenterCosts.heading" />
                                    </span>
                                </h4>

                                <div className="pull-left" style={{ marginLeft: '100px' }}>
                                    <span className="pull-left text-gray" style={{ paddingTop: '10px' }}>
                                        <FormattedMessage id="fields.show_costs" />:
                                    </span>
                                    <div className="pull-left m-l-sm">
                                        <CheckboxField
                                            id="incl_vehicles"
                                            onChange={(e) => { this.changeInclVehicles(e.target.checked) }}
                                            label={this.props.intl.formatMessage({ id: 'fields.incl_vehicles' })}
                                            value={this.state.inclVehicles}
                                        />
                                    </div>
                                    <div className="pull-left m-l-sm">
                                        <CheckboxField
                                            id="incl_drivers"
                                            onChange={(e) => { this.changeInclDrivers(e.target.checked) }}
                                            label={this.props.intl.formatMessage({ id: 'fields.incl_drivers' })}
                                            value={this.state.inclDrivers}
                                        />
                                    </div>
                                    <div className="pull-left m-l-sm">
                                        <CheckboxField
                                            id="incl_trailers"
                                            onChange={(e) => { this.changeInclTrailers(e.target.checked) }}
                                            label={this.props.intl.formatMessage({ id: 'fields.incl_trailers' })}
                                            value={this.state.inclTrailers}
                                        />
                                    </div>
                                </div>

                                <div className="btns-list">
                                    <PeriodPicker
                                        className={'m-r-sm'}
                                        momentFrom={momentFrom}
                                        momentTo={momentTo}
                                        onChange={(momentFrom, momentTo, format) => this.handleChangeYearMonth(format)}
                                        allowedPickers={[periodPickerConstants.PERIOD_PICKER_TYPE_YEAR]}
                                        forbidPickType
                                    />
                                    {this.isFilterActive() > 0 && (<button className="btn btn-default btn-addon m-r-xs" onClick={this.resetFilters}>
                                        <i className="far fa-times" /> <FormattedMessage id="buttons.resetFilters" />
                                    </button>)}
                                    <button className="btn btn-default btn-addon m-r-xs" onClick={this.refresh}>
                                        <i className="far fa-sync-alt" /> <FormattedMessage id="buttons.refresh" />
                                    </button>
                                    <PermissionsCheck has={[PERMS.EXPORT]}>
                                        <button className="btn btn-primary btn-addon" onClick={this.handleExport}>
                                            <i className="far fa-file-excel" /> <FormattedMessage id="buttons.exportToXLS" />
                                        </button>
                                    </PermissionsCheck>
                                </div>
                            </div>

                            <div className="table-container">
                                <table className="table table-striped table-hover table-fixed-header">
                                    <thead>
                                    <tr>
                                        <th className="w-max wm-300" />

                                        {monthKeys.map(key => {
                                            const month = moment().set('month', key)

                                            return (<th key={`th-month-${key}`} className="w-100 text-right">
                                                {this.props.intl.formatMessage({ id: `monthNames.${month.format('MM')}` })}
                                            </th>)
                                        })}

                                        <th className="w-140 text-right">
                                            <FormattedMessage id="fields.total" />
                                        </th>
                                    </tr>

                                    <tr className="filters">
                                        <th className="w-max wm-300">
                                            <TableFilterInputField
                                                identifier="subject_name"
                                                type={TableModelColumnFilteringType.string}
                                                filters={filters}
                                                onChange={this.props.table.changeFilter}
                                            />
                                        </th>

                                        {monthKeys.map(key => (<th key={`filter-month-${key}`} className="w-100" />))}

                                        <th className="w-140 text-right" />
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {data && data.map(row => {
                                        if (filters.getIn(['subject_name', 'value']) && row.name.toLowerCase().indexOf(filters.getIn(['subject_name', 'value']).toLowerCase()) < 0) {
                                            return null
                                        }

                                        const total = row.data.total

                                        return (<tr key={`row-${row.id}`}>
                                            <td className="w-max wm-300 td-clickable" onClick={e => {
                                                this.handleShowDetails(e, row.id, 0, row.name)
                                            }}>
                                                {row.name}
                                                {row.vehicles.length > 0 && (
                                                    <span className="text-gray">
                                                       &nbsp;+ {row.vehicles.length} {row.vehicles.length === 1 ? 'vozidlo' : (row.vehicles.length <= 4 ? 'vozidla' : 'vozidel')}
                                                    </span>
                                                )}
                                                {row.drivers.length > 0 && (
                                                    <span className="text-gray">
                                                       &nbsp;+ {row.drivers.length} {row.drivers.length === 1 ? 'řidič' : (row.drivers.length <= 4 ? 'řidiči' : 'řidičů')}
                                                    </span>
                                                )}
                                                {row.trailers.length > 0 && (
                                                    <span className="text-gray">
                                                       &nbsp;+ {row.trailers.length} {row.trailers.length === 1 ? 'návěs' : (row.trailers.length <= 4 ? 'návěsy' : 'návěsů')}
                                                    </span>
                                                )}
                                            </td>

                                            {monthKeys.map(monthKey => {
                                                const price = row.data.months[monthKey + 1]

                                                return (
                                                    <td key={`row-${row.id}-month-${monthKey}`} className="w-100 text-right td-clickable" onClick={e => {
                                                        this.handleShowDetails(e, row.id, monthKey + 1, row.name)
                                                    }}>
                                                        {price ? formatters.priceFormatter(price, '0,0') : ''}
                                                    </td>)
                                            })}

                                            <td className="w-140 text-right td-clickable" onClick={e => {
                                                this.handleShowDetails(e, row.id, 0, row.name)
                                            }}>
                                                <strong>
                                                    {formatters.priceFormatter(total, '0,0')}
                                                </strong>
                                            </td>
                                        </tr>)
                                    })}

                                    {total && (<tr>
                                        <td>
                                            <strong>
                                                <FormattedMessage id="fields.total" />
                                            </strong>
                                        </td>

                                        {monthKeys.map(monthKey => {
                                            const price = total.months[monthKey + 1]

                                            return (<td key={`total-month-${monthKey}`} className="w-100 text-right">
                                                <strong>
                                                    {formatters.priceFormatter(price, '0,0')}
                                                </strong>
                                            </td>)
                                        })}

                                        <td className="w-140 text-right">
                                            <strong>
                                                {formatters.priceFormatter(total['total'], '0,0')}
                                            </strong>
                                        </td>
                                    </tr>)}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                <Modal show={Boolean(this.state.modalDetails.show)} onHide={this.handleHideDetails} className="modal-size-auto">
                    <Modal.Header closeButton>
                        <Modal.Title>{`${this.state.modalDetails.name} / ${momentFrom.format('DD.MM.YYYY')} - ${momentTo.format('DD.MM.YYYY')}`}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="clearfix">
                        <LoadingOverlay active={this.props.detailsFetching}>
                            <div className="btns-list">
                                <PermissionsCheck has={[PERMS.EXPORT]}>
                                    <button className="btn btn-primary btn-addon" onClick={this.handleExportDetails}>
                                        <i className="far fa-file-excel" /> <FormattedMessage id="buttons.exportToXLS" />
                                    </button>
                                </PermissionsCheck>
                            </div>

                            <div className="pull-left wp-100 mh-500">
                                <div className="pull-left wp-100 m-b-sm">
                                    {detailsData && (<div className="pull-left wp-100">
                                        <div className="table-container">
                                            <table className="table table-striped table-hover table-fixed-header">
                                                <thead>
                                                <tr>
                                                    <th className="w-120">
                                                        <FormattedMessage id="fields.date" />
                                                    </th>
                                                    <th className="w-400">
                                                        <FormattedMessage id="fields.cost" />
                                                    </th>
                                                    <th className="w-200">
                                                        <FormattedMessage id="fields.supplier" />
                                                    </th>
                                                    <th className="w-200">
                                                        <FormattedMessage id="fields.billNumber" />
                                                    </th>
                                                    <th className="w-max wm-300">
                                                        <FormattedMessage id="fields.note" />
                                                    </th>
                                                    <th className="w-140 text-right">
                                                        <FormattedMessage id="fields.amount" />
                                                    </th>
                                                    <th className="w-140 text-right">
                                                        <FormattedMessage id="fields.amountCZK" />
                                                    </th>
                                                    <th className="w-100 text-right">
                                                        <FormattedMessage id="fields.proportion" />
                                                    </th>
                                                    <th className="w-140 text-right">
                                                        <FormattedMessage id="fields.proportionalAmount" />
                                                    </th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {detailsData && detailsData.map(cost => {
                                                    return (<tr key={`row-${cost.id}`}>
                                                        <td className="w-120">
                                                            {cost.date ? formatters.dateFormatter(cost.date) : null}
                                                            {cost.cost_generator_id && <span className="far fa-repeat m-l-xs" />}
                                                        </td>
                                                        <td className="w-400">
                                                            {cost.cost_type && cost.cost_type.name}
                                                            {cost.type_suffix && (<span className="text-gray">
                                                                &nbsp;- {cost.type_suffix}
                                                            </span>)}
                                                        </td>
                                                        <td className="w-200">
                                                            {cost.supplier}
                                                        </td>
                                                        <td className="w-200">
                                                            {cost.bill_number}
                                                        </td>
                                                        <td className="w-max wm-300">
                                                            {cost.note}
                                                        </td>
                                                        <td className="w-140 text-right">
                                                            {formatters.priceFormatter(cost.price, '0,0', cost.currency ? cost.currency.iso_code : '')}
                                                        </td>
                                                        <td className="w-140 text-right">
                                                            {formatters.priceFormatter(cost.price * cost.currency_rate)}
                                                        </td>
                                                        <td className="w-100 text-right">
                                                            {formatters.percentFormatter(cost.costRatio * 100, '0,0.00')}
                                                        </td>
                                                        <td className="w-140 text-right">
                                                            <strong>
                                                                {formatters.priceFormatter(cost.price * cost.currency_rate * cost.costRatio)}
                                                            </strong>
                                                        </td>
                                                    </tr>)
                                                })}

                                                {detailsData && (
                                                    <tr>
                                                        <td className="w-120">
                                                            <strong>
                                                                <FormattedMessage id="fields.total" />
                                                            </strong>
                                                        </td>
                                                        <td colSpan={7}></td>
                                                        <td className="w-140 text-right">
                                                            <strong>
                                                                {formatters.priceFormatter(detailsTotal, '0,0')}
                                                            </strong>
                                                        </td>
                                                    </tr>
                                                )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>)}
                                </div>
                            </div>
                        </LoadingOverlay>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-default" onClick={this.handleHideDetails}>
                            <FormattedMessage id="confirmDialog.duplicityFound.cancel" />
                        </button>
                    </Modal.Footer>
                </Modal>
            </div>
        </PermissionsCheck>)
    }
}

function mapStateToProps(state) {
    return {
        tableModel: createTableModelSelector(tableIdentifier)(state),
        year: getYear(state),
        data: getData(state),
        fetching: getFetching(state),
        detailsData: getDetailsData(state),
        detailsFetching: getDetailsFetching(state),
        prerequisites: preparePrerequisitesFetchStatusSelectors(componentIdentifier, prerequisites, state),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            ...bindActionCreators({
                ...ReportCostCenterCostsActionCreators, ...preparePrerequisitesActionCreators(componentIdentifier, prerequisites),
            }, dispatch),
        },
        dispatch,
    }
}

function mergeProps(stateProps, dispatchProps, ownProps) {
    return {
        ...stateProps, ...dispatchProps, ...ownProps,
        table: bindActionCreators(prepareTableActionCreators(tableIdentifier, stateProps.tableModel), dispatchProps.dispatch),
    }
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps, mergeProps)(withRouter(ReportCostCenterCosts)))
