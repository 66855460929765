import * as actionTypes from './actionTypes'

// Fetch

export const fetchReportCarrierResults = year => ({
    type: actionTypes.FETCH,
    payload: year,
})

export const fetchReportCarrierResultsFulfilled = data => ({
    type: actionTypes.FETCH_FULFILLED,
    payload: data,
})

export const fetchReportCarrierResultsRejected = message => ({
    type: actionTypes.FETCH_REJECTED,
    payload: message,
})

export const fetchReportCarrierResultsCancelled = () => ({
    type: actionTypes.FETCH_CANCELLED,
})

// Clear

export const clearReportCarrierResults = () => ({
    type: actionTypes.CLEAR,
})

// Export

export const exportReportCarrierResults = (year, filters) => ({
    type: actionTypes.EXPORT,
    payload: {
        year,
        filters,
    },
})

// Fetch details

export const fetchReportCarrierResultsDetails = (year, carrierId, month, filters) => ({
    type: actionTypes.FETCH_DETAILS,
    payload: {
        year,
        carrierId,
        month,
        filters,
    },
})

export const fetchReportCarrierResultsDetailsFulfilled = data => ({
    type: actionTypes.FETCH_DETAILS_FULFILLED,
    payload: data,
})

export const fetchReportCarrierResultsDetailsRejected = message => ({
    type: actionTypes.FETCH_DETAILS_REJECTED,
    payload: message,
})

export const fetchReportCarrierResultsDetailsCancelled = () => ({
    type: actionTypes.FETCH_DETAILS_CANCELLED,
})

// Clear

export const clearReportCarrierResultsDetails = () => ({
    type: actionTypes.CLEAR_DETAILS,
})

// Export

export const exportReportCarrierResultsDetails = (year, carrierId, month, filters) => ({
    type: actionTypes.EXPORT_DETAILS,
    payload: {
        year,
        carrierId,
        month,
        filters,
    },
})
