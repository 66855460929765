import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { push } from 'react-router-redux'
import Notifications from 'react-notification-system-redux'
import moment from 'moment'
import deepEqual from 'deep-equal'
import { DropdownButton, MenuItem, Modal, Tab, Tabs } from 'react-bootstrap'

import * as constants from '../../transports/constants'
import * as formatters from '../../../common/formatters'
import { ConfirmDialog } from '../../../common/confirm_dialog'

import { url, getPrerequisitesArray, getFloatVal, getPrerequisitesPairs, hasAnyPermission, isReadOnlyPermission } from '../../../common/helpers'
import { Form, InputField, SelectField, CheckboxField, CustomerPicker, SubmitButton } from '../../../common/form'
import {
    createGetTransportByIDSelector,
    createGetCurrentTransportStatusSelector,
    createGetDuplicitySelector,
    getTransportDeleting,
    getTransportDeleteError,
} from '../../transports/selectors'
import * as transportsActionCreators from '../../transports/actionCreators'
import * as customerAddressesActionCreators from '../../customer_address/actionCreators'
import { LoadingOverlay } from '../../../common/loading_overlay'
import { preparePrerequisitesFetchStatusSelectors } from '../../../common/prerequisites/selectors'
import { preparePrerequisitesActionCreators } from '../../../common/prerequisites/actionCreators'
import { createGetCountryByIDSelector } from '../../countries/selectors'
import { createGetCurrencyByIDSelector } from '../../currencies/selectors'
import { createGetCustomerByIDSelector } from '../../customers/selectors'
import { createGetTransportTypeByIDSelector } from '../../transport_types/selectors'
import * as customerAddressesSelectors from '../../customer_address/selectors'
import { getCurrentUserId } from '../../auth/selectors'

import TransportTemplatesEditRoute from './TransportTemplatesEditRoute'
import TransportTemplatesAllowances from './TransportTemplatesAllowances'
import PermissionsCheck from '../../auth/components/PermissionsCheck'
import { PERMS, PERMS_CUSTOMER, PERMS_CUSTOMER_COST, PERMS_ROUTES } from '../permissions'

const componentIdentifier = 'transports_edit'
const prerequisites = [
    'transport_types',
    'cargo_types',
    'cargo_types_entities',
    'goods_types',
    'customers',
    'currencies',
    'surcharge_types',
    'trailers',
    'customer_address_types',
]

class TransportTemplatesEdit extends Component {
    transport = null
    successEditCallback = null
    pointTypes = constants.TRANSPORT_POINT_TYPES_DEFINITION
    eventDataTypes = constants.TRANSPORT_EVENT_DATA_TYPES_DEFINITION

    defaultValues = {}

    emptyCustomer = {
        id: null,
        customer_id: null,
        order_number: '',
        price: 0,
        currency_id: null,
        note: '',
        invoicing_distance: 0,
        priceParts: {},
    }

    state = {
        values: this.defaultValues,
        points: [],
        predepartureCheck: true,
        customers: [
            {
                id: null,
                customer_id: null,
                order_number: null,
                price: null,
                currency_id: null,
                note: null,
                invoicing_distance: 0,
                transport_description: '',
                invoicing_note: '',
                do_not_invoice: 0,
                priceParts: {},
            },
        ],
        confirmDialog: {
            show: false,
            title: '',
            message: '',
            labelCancel: '',
            labelAccept: '',
            classNameAccept: 'text-success',
            classNameCancel: 'text-default',
            onCancel: () => {},
            onAccept: () => {},
        },
        modalDuplicity: {
            show: false,
            duplicity: null,
        },
        modalPriceParts: {
            show: false,
            customersKey: 0,
        },
        modalSaveAddress: {
            show: false,
            values: {},
        },
        modalSaveAddressDuplicity: {
            show: false,
            duplicity: null,
        },
        modalTransportDescription: {
            show: false,
            text: '',
            customersKey: null,
        },
        modalInvoicingNote: {
            show: false,
            text: '',
            customersKey: null,
        },
        emptyMandatoryFields: [],
        fixedMandatoryFields: [],
        ignoreEmptyMandatoryFields: false,
        transportAllowances: {
            show: false,
        },
    }

    validationRules = {}

    setDisponentId = disponentId => {
        this.setState({
            values: {
                ...this.state.values,
                disponent_id: disponentId,
            },
        })
    }

    // confirm dialog
    showConfirmDialog = options => {
        this.setState({
            confirmDialog: {
                show: true,
                title: options.title || this.props.intl.formatMessage({ id: 'confirmDialog.default.title' }),
                message: options.message || this.props.intl.formatMessage({ id: 'confirmDialog.default.message' }),
                labelCancel: options.labelCancel || this.props.intl.formatMessage({ id: 'confirmDialog.default.cancel' }),
                labelAccept: options.labelAccept || this.props.intl.formatMessage({ id: 'confirmDialog.default.accept' }),
                classNameAccept: options.classNameAccept || 'btn-success',
                classNameCancel: options.classNameCancel || 'btn-default',
                onCancel: options.onCancel || this.hideConfirmDialog,
                onAccept: options.onAccept || this.hideConfirmDialog,
            },
        })
    }

    hideConfirmDialog = () => {
        this.setState({
            confirmDialog: {
                show: false,
                onCancel: () => {},
                onAccept: () => {},
            },
        })
    }

    // delete
    handleDelete = () => {
        this.showConfirmDialog({
            title: this.props.intl.formatMessage({ id: 'confirmDialog.transportTemplateDelete.title' }),
            message: this.props.intl.formatMessage({ id: 'confirmDialog.transportTemplateDelete.message' }),
            labelCancel: this.props.intl.formatMessage({ id: 'confirmDialog.transportTemplateDelete.cancel' }),
            labelAccept: this.props.intl.formatMessage({ id: 'confirmDialog.transportTemplateDelete.accept' }),
            classNameAccept: 'btn-danger',
            onAccept: () => {
                this.props.actions.deleteTransport(this.transport.id)
                this.hideConfirmDialog()
            },
        })
    }

    // save transport
    handleSubmit = values => {
        this.setValues(values)
        this.handleSave()
    }

    handleSave = () => {
        this.successEditCallback = null
        if (!this.transport) {
            this.successEditCallback = () => {
                this.props.redirect(url(this.props.match, 'transport-templates'))
            }
        } else {
            this.successEditCallback = () => {
                this.setState({
                    ignoreEmptyMandatoryFields: false,
                })
            }
        }
        this.saveTransport()
    }

    handleSaveAndClose = () => {
        this.successEditCallback = () => {
            this.props.redirect(url(this.props.match, 'transport-templates'))
        }
        this.saveTransport()
    }

    saveTransport = () => {
        const values = this.getValuesForSave()

        if (!this.state.ignoreEmptyMandatoryFields && this.getEmptyMandatoryFields(values).length > 0) {
            this.showConfirmDialog({
                title: this.props.intl.formatMessage({ id: 'confirmDialog.emptyMandatoryFields.title' }),
                message: this.props.intl.formatMessage({ id: 'confirmDialog.emptyMandatoryFields.message' }),
                labelCancel: this.props.intl.formatMessage({ id: 'confirmDialog.emptyMandatoryFields.cancel' }),
                labelAccept: this.props.intl.formatMessage({ id: 'confirmDialog.emptyMandatoryFields.accept' }),
                classNameAccept: 'btn-success',
                onAccept: () => {
                    this.hideConfirmDialog()
                    this.setState(
                        {
                            ignoreEmptyMandatoryFields: true,
                        },
                        () => {
                            this.saveTransport()
                        }
                    )
                },
            })
            return false
        }

        this.props.actions.saveTransport(values)

        return true
    }

    getValuesForSave = () => ({
        ...this.state.values,
        is_template: 1,
        is_predeparture_check: this.state.predepartureCheck,
        customers: this.state.customers.map(customer => ({
            id: customer.id,
            customer_id: customer.customer_id,
            order_number: customer.order_number,
            price: this.getTotalPrice(customer.priceParts),
            currency_id: customer.currency_id,
            note: customer.note,
            invoicing_distance: customer.invoicing_distance,
            transport_description: customer.transport_description,
            invoicing_note: customer.invoicing_note,
            do_not_invoice: customer.do_not_invoice,
            surcharges: Object.keys(customer.priceParts).map(key => ({
                surcharge_type_id: key,
                price: customer.priceParts[key],
            })),
        })),
        points: this.state.points.map(point => ({
            id: point.id,
            type_id: point.type_id,
            datetime_planned_from: point.plannedFrom && point.plannedFrom.format('YYYY-MM-DD HH:mm'),
            datetime_planned_to: point.plannedTo && point.plannedTo.format('YYYY-MM-DD HH:mm'),
            name: point.name,
            street: point.street,
            city: point.city,
            zipcode: point.zipcode,
            country_code: point.country_code,
            gps_lat: point.gps_lat,
            gps_lng: point.gps_lng,
            note: point.note,
            is_booking_required: point.is_booking_required,
            booking_url: point.booking_url,
            booking_note: point.booking_note,
            data: point.data
                ? point.data.map(item => ({
                      type_id: item.type_id,
                      value: item.value,
                  }))
                : [],
        })),
    })

    getSurchargesForSave = priceParts => {
        const surcharges = []
        Object.keys(priceParts).forEach(key => {
            surcharges.push({
                surcharge_type_id: key,
                price: priceParts[key],
            })
        })

        return surcharges
    }

    getEmptyMandatoryFields = values => {
        const emptyMandatoryFields = []

        if (!values.template_name) {
            emptyMandatoryFields.push('template_name')
        }

        if (!values.transport_type_id) {
            emptyMandatoryFields.push('transport_type_id')
        }

        if (!values.cargo_type_id) {
            emptyMandatoryFields.push('cargo_type_id')
        }

        if (!values.goods_type_id) {
            emptyMandatoryFields.push('goods_type_id')
        }

        this.state.customers.forEach((transportCustomer, index) => {
            if (!transportCustomer.customer_id) {
                emptyMandatoryFields.push(`customer_id-${index}`)
            }
        })

        this.setState({
            emptyMandatoryFields,
        })

        return emptyMandatoryFields
    }

    getFixedMandatoryFields = () => {
        const values = this.getValuesForSave()
        const fixedMandatoryFields = []

        if (this.state.emptyMandatoryFields.indexOf('template_name') !== -1 && values.template_name) {
            fixedMandatoryFields.push('template_name')
        }

        if (this.state.emptyMandatoryFields.indexOf('transport_type_id') !== -1 && values.transport_type_id) {
            fixedMandatoryFields.push('transport_type_id')
        }

        if (this.state.emptyMandatoryFields.indexOf('cargo_type_id') !== -1 && values.cargo_type_id) {
            fixedMandatoryFields.push('cargo_type_id')
        }

        if (this.state.emptyMandatoryFields.indexOf('goods_type_id') !== -1 && values.goods_type_id) {
            fixedMandatoryFields.push('goods_type_id')
        }

        this.state.customers.forEach((transportCustomer, index) => {
            if (this.state.emptyMandatoryFields.indexOf(`customer_id-${index}`) !== -1 && transportCustomer.customer_id) {
                fixedMandatoryFields.push(`customer_id-${index}`)
            }
        })

        this.setState({
            fixedMandatoryFields,
        })

        return fixedMandatoryFields
    }

    // transport form
    handleChangeValues = values => {
        this.setValues(values, this.getFixedMandatoryFields)
    }

    setValues = (values, callback) => {
        const customers = this.state.customers.map((customer, index) => ({
            ...customer,
            customer_id: values[`customer_id-${index}`],
            order_number: values[`customer_order_number-${index}`],
            currency_id: values[`customer_currency_id-${index}`],
            note: values[`customer_note-${index}`],
            invoicing_distance: values[`customer_invoicing_distance-${index}`],
            do_not_invoice: values[`customer_do_not_invoice-${index}`],
        }))

        this.setState(
            {
                values,
                customers,
            },
            () => {
                callback && callback()
            }
        )
    }

    setValuesFromTransport = transport => {
        const values = transport.toJS()
        const customers = []

        if (transport.transport_customers && transport.transport_customers.size) {
            transport.transport_customers.valueSeq().forEach(transport_customer => {
                const customer = {
                    id: transport_customer.id,
                    customer_id: transport_customer.customer_id,
                    order_number: transport_customer.order_number,
                    price: transport_customer.price,
                    currency_id: transport_customer.currency_id,
                    note: transport_customer.note,
                    invoicing_distance: transport_customer.invoicing_distance,
                    transport_description: transport_customer.transport_description,
                    invoicing_note: transport_customer.invoicing_note,
                    do_not_invoice: transport_customer.do_not_invoice,
                    priceParts: {},
                }

                customer.priceParts[0] = transport_customer.price_base
                if (transport_customer.surcharges && transport_customer.surcharges.size) {
                    transport_customer.surcharges.valueSeq().forEach(surcharge => {
                        customer.priceParts[surcharge.surcharge_type_id] = surcharge.price
                    })
                }

                customers.push(customer)
            })
        }

        if (customers.length === 0) {
            customers.push(this.emptyCustomer)
        }

        customers.forEach((customer, index) => {
            values[`customer_id-${index}`] = customer.customer_id
            values[`customer_order_number-${index}`] = customer.order_number
            values[`customer_currency_id-${index}`] = customer.currency_id
            values[`customer_note-${index}`] = customer.note
            values[`customer_invoicing_distance-${index}`] = customer.invoicing_distance
            values[`customer_do_not_invoice-${index}`] = customer.do_not_invoice
        })

        this.setState(
            {
                customers,
            },
            () => {
                this.setValues(values)
                this.handlePredepartureCheckChanged(transport.is_predeparture_check)
            }
        )
    }

    // route points
    handlePredepartureCheckChanged = value => {
        this.setState({
            predepartureCheck: value,
        })
    }

    handleRouteChanged = points => {
        this.setPoints(points)
    }

    setPoints = (points, callback) => {
        this.setState(
            {
                points,
            },
            () => {
                callback && callback()
            }
        )
    }

    setPointsFromTransport = transport => {
        const points =
            transport.transport_points &&
            transport.transport_points
                .valueSeq()
                .map(point => {
                    const country = this.props.createGetCountryByIDSelector(point.country_id)
                    return {
                        ...point.toJS(),
                        plannedFrom: point.datetime_planned_from && moment(point.datetime_planned_from),
                        plannedTo: point.datetime_planned_to && moment(point.datetime_planned_to),
                        specifiedFrom: point.datetime_specified_from && moment(point.datetime_specified_from),
                        specifiedTo: point.datetime_specified_to && moment(point.datetime_specified_to),
                        country_code: country ? country.iso_code : '',
                        data:
                            point.transport_point_data &&
                            point.transport_point_data
                                .map(dataItem => {
                                    let value = dataItem.value !== undefined ? dataItem.value : ''
                                    if (
                                        this.pointTypes[point.type_id].dataFields[dataItem.type_id] &&
                                        this.pointTypes[point.type_id].dataFields[dataItem.type_id].type === 'bool'
                                    ) {
                                        value = parseInt(dataItem.value)
                                    }
                                    return {
                                        ...dataItem.toJS(),
                                        ...this.pointTypes[point.type_id].dataFields[dataItem.type_id],
                                        value,
                                    }
                                })
                                .toJS(),
                    }
                })
                .toJS()

        this.setPoints(points)
    }

    // prices
    closePriceModal = e => {
        e && e.preventDefault()

        this.setState({
            modalPriceParts: {
                show: false,
            },
        })
    }

    openPriceModal = (e, customersKey) => {
        e && e.preventDefault()

        this.setState({
            modalPriceParts: {
                show: true,
                customersKey,
            },
        })
    }

    setPricePart = (id, value) => {
        const customersKey = this.state.modalPriceParts.customersKey
        const customers = this.state.customers

        if (customers[customersKey]) {
            customers[customersKey].priceParts[id] = value
        }

        this.setState({
            customers,
        })
    }

    getTotalPrice = priceParts => {
        let price = 0
        Object.keys(priceParts).forEach(key => {
            price += getFloatVal(priceParts[key])
        })

        return price
    }

    // save address
    closeSaveAddressModal = () => {
        this.setState({
            modalSaveAddress: {
                show: false,
                values: {},
            },
        })
    }

    handleSaveAddress = values => {
        this.state.customers.forEach(transportCustomer => {
            if (!values.customer_id && transportCustomer.customer_id) {
                values.customer_id = transportCustomer.customer_id
            }
        })

        values.is_booking_required_address = values.is_booking_required

        this.setState({
            modalSaveAddress: {
                show: true,
                values,
            },
        })
    }

    handleSaveAddressChange = values => {
        values.is_booking_required = values.is_booking_required_address

        this.setState({
            modalSaveAddress: {
                ...this.state.modalSaveAddress,
                values,
            },
        })
    }

    handleSaveAddressSubmit = () => {
        this.handleSaveAddressSave(false)
    }

    handleSaveAddressSave = ignoreDuplicity => {
        this.props.actions.saveCustomerAddress && this.props.actions.saveCustomerAddress(this.state.modalSaveAddress.values, ignoreDuplicity)
    }

    handleSaveAddressDuplicity = duplicity => {
        this.setState({
            modalSaveAddressDuplicity: {
                show: true,
                duplicity,
            },
        })
    }

    handleSaveAddressDuplicityCancel = () => {
        this.setState({
            modalSaveAddressDuplicity: {
                show: false,
                duplicity: null,
            },
        })
    }

    handleSaveAddressDuplicityConfirm = () => {
        this.handleSaveAddressDuplicityCancel()
        this.handleSaveAddressSave(true)
    }

    // customers
    handleAddCustomer = e => {
        e && e.preventDefault()

        const customers = this.state.customers
        customers.push(this.emptyCustomer)

        const values = this.state.values
        values[`customer_currency_id-${customers.length - 1}`] = this.emptyCustomer.currency_id

        this.setState({
            customers,
            values,
        })
    }

    handleRemoveCustomer = (e, customersKey) => {
        e && e.preventDefault()

        this.showConfirmDialog({
            title: this.props.intl.formatMessage({ id: 'confirmDialog.removeCustomer.title' }),
            message: this.props.intl.formatMessage({ id: 'confirmDialog.removeCustomer.message' }),
            labelCancel: this.props.intl.formatMessage({ id: 'confirmDialog.removeCustomer.cancel' }),
            labelAccept: this.props.intl.formatMessage({ id: 'confirmDialog.removeCustomer.accept' }),
            classNameAccept: 'btn-danger',
            onAccept: () => {
                this.removeCustomer(customersKey)
                this.hideConfirmDialog()
            },
        })
    }

    removeCustomer = customersKey => {
        const customers = this.state.customers
        customers.splice(customersKey, 1)

        const values = this.state.values
        delete values[`customer_id-${customersKey}`]
        delete values[`customer_order_number-${customersKey}`]
        delete values[`customer_currency_id-${customersKey}`]
        delete values[`customer_note-${customersKey}`]
        delete values[`customer_invoicing_distance-${customersKey}`]
        delete values[`customer_do_not_invoice-${customersKey}`]

        this.setState({
            customers,
            values,
        })
    }

    // transport description
    modalTransportDescriptionOpen = customersKey => {
        let text = this.state.values.carrier_transport_description
        if (customersKey !== null) {
            const customer = this.state.customers[customersKey]
            text = customer.transport_description
        }

        this.setState({
            modalTransportDescription: {
                ...this.state.modalTransportDescription,
                show: true,
                text,
                customersKey,
            },
        })
    }

    modalTransportDescriptionClose = () => {
        this.setState({
            modalTransportDescription: {
                ...this.state.modalTransportDescription,
                show: false,
                text: '',
                customersKey: null,
            },
        })
    }

    modalTransportDescriptionSetText = text => {
        this.setState({
            modalTransportDescription: {
                ...this.state.modalTransportDescription,
                text,
            },
        })
    }

    modalTransportDescriptionSubmit = () => {
        const customersKey = this.state.modalTransportDescription.customersKey
        const text = this.state.modalTransportDescription.text

        if (customersKey !== null) {
            const newCustomer = {
                ...this.state.customers[customersKey],
                transport_description: text,
            }

            this.setState(
                {
                    customers: this.state.customers.map((customer, index) => (index === customersKey ? newCustomer : customer)),
                },
                () => {
                    this.modalTransportDescriptionClose()
                }
            )
        } else {
            this.setState(
                {
                    values: {
                        ...this.state.values,
                        carrier_transport_description: text,
                    },
                },
                () => {
                    this.modalTransportDescriptionClose()
                }
            )
        }
    }

    // invoicing note
    modalInvoicingNoteOpen = customersKey => {
        let text = this.state.values.carrier_invoicing_note
        if (customersKey !== null) {
            const customer = this.state.customers[customersKey]
            text = customer.invoicing_note
        }

        this.setState({
            modalInvoicingNote: {
                ...this.state.modalInvoicingNote,
                show: true,
                text,
                customersKey,
            },
        })
    }

    modalInvoicingNoteClose = () => {
        this.setState({
            modalInvoicingNote: {
                ...this.state.modalInvoicingNote,
                show: false,
                text: '',
                customersKey: null,
            },
        })
    }

    modalInvoicingNoteSetText = text => {
        this.setState({
            modalInvoicingNote: {
                ...this.state.modalInvoicingNote,
                text,
            },
        })
    }

    modalInvoicingNoteSubmit = () => {
        const customersKey = this.state.modalInvoicingNote.customersKey
        const text = this.state.modalInvoicingNote.text

        if (customersKey !== null) {
            const newCustomer = {
                ...this.state.customers[customersKey],
                invoicing_note: text,
            }

            this.setState(
                {
                    customers: this.state.customers.map((customer, index) => (index === customersKey ? newCustomer : customer)),
                },
                () => {
                    this.modalInvoicingNoteClose()
                }
            )
        } else {
            this.setState(
                {
                    values: {
                        ...this.state.values,
                        carrier_invoicing_note: text,
                    },
                },
                () => {
                    this.modalInvoicingNoteClose()
                }
            )
        }
    }

    // duplicity
    duplicityFoundCallback = duplicity => {
        this.setState({
            modalDuplicity: {
                show: true,
                duplicity,
            },
        })
    }

    handleDuplicityCancel = () => {
        this.setState({
            modalDuplicity: {
                show: false,
                duplicity: null,
            },
        })
    }

    handleDuplicityConfirm = () => {
        this.handleDuplicityCancel()

        this.props.actions.saveTransport(this.getValuesForSave(), true)
    }

    componentDidMount() {
        this.props.match.params.transportID && this.props.actions.fetchTransport(this.props.match.params.transportID)
        this.props.actions.fetchPrerequisites()

        if (!this.props.match.params.transportID) {
            this.setDisponentId(this.props.currentUserId)
        }
    }

    componentWillReceiveProps(nextProps) {
        // Save
        if (this.props.status.get('saving') && !nextProps.status.get('saving')) {
            if (nextProps.status.get('error') === null && !nextProps.duplicity) {
                this.props.notify(
                    {
                        title: this.props.intl.formatMessage({ id: 'alerts.titles.success' }),
                        message: this.props.intl.formatMessage({ id: 'alerts.messages.saveSuccess' }),
                        position: 'tc',
                    },
                    'success'
                )
                this.successEditCallback && this.successEditCallback()
            } else if (nextProps.status.get('error') === null && nextProps.duplicity) {
                this.duplicityFoundCallback && this.duplicityFoundCallback(nextProps.duplicity)
            } else {
                this.props.notify(
                    {
                        title: this.props.intl.formatMessage({ id: 'alerts.titles.error' }),
                        message: nextProps.status.get('error'),
                        position: 'tc',
                    },
                    'error'
                )
            }
        }

        // Delete
        if (this.props.deleting && !nextProps.deleting) {
            if (nextProps.deleteError === null) {
                this.props.notify(
                    {
                        title: this.props.intl.formatMessage({ id: 'alerts.titles.success' }),
                        message: this.props.intl.formatMessage({ id: 'alerts.messages.deleteSuccess' }),
                        position: 'tc',
                    },
                    'success'
                )
                this.props.redirect(url(this.props.match, 'transport-templates'))
            } else {
                this.props.notify(
                    {
                        title: this.props.intl.formatMessage({ id: 'alerts.titles.error' }),
                        message: nextProps.deleteError,
                        position: 'tc',
                    },
                    'error'
                )
            }
        }

        // Save customer address
        if (this.props.customerAddressStatus.get('saving') && !nextProps.customerAddressStatus.get('saving')) {
            if (nextProps.customerAddressStatus.get('error') === null && !nextProps.customerAddressDuplicity) {
                this.props.notify(
                    {
                        title: this.props.intl.formatMessage({ id: 'alerts.titles.success' }),
                        message: this.props.intl.formatMessage({ id: 'alerts.messages.addressSaveSuccess' }),
                        position: 'tc',
                    },
                    'success'
                )
                this.closeSaveAddressModal()
            } else if (nextProps.customerAddressStatus.get('error') === null && nextProps.customerAddressDuplicity) {
                this.handleSaveAddressDuplicity(nextProps.customerAddressDuplicity)
            } else {
                this.props.notify(
                    {
                        title: this.props.intl.formatMessage({ id: 'alerts.titles.error' }),
                        message: nextProps.customerAddressStatus.get('error'),
                        position: 'tc',
                    },
                    'error'
                )
            }
        }

        const transportIsEqual = deepEqual(nextProps.transport, this.transport)
        if (transportIsEqual === false && nextProps.transport) {
            this.transport = nextProps.transport
            this.setValuesFromTransport(this.transport)
        }

        if (
            nextProps.transport &&
            nextProps.transport.transport_points &&
            (!this.props.transport ||
                !this.state.points.length ||
                JSON.stringify(nextProps.transport.transport_points) !== JSON.stringify(this.props.transport.transport_points))
        ) {
            this.setPointsFromTransport(nextProps.transport)
        }

        if (nextProps.prerequisites.values.get('trailers').size && !this.props.prerequisites.values.get('trailers').size) {
            const trailersOptions = getPrerequisitesArray(nextProps.prerequisites.values.get('trailers'), 'name', 'name')

            this.pointTypes[constants.TRANSPORT_POINT_TYPE_TRAILER_CHANGE].dataFields[
                constants.TRANSPORT_POINT_DATA_TYPE_REGISTRATION
            ].values = trailersOptions
            this.pointTypes[constants.TRANSPORT_POINT_TYPE_TRAILER_CATCH].dataFields[
                constants.TRANSPORT_POINT_DATA_TYPE_REGISTRATION
            ].values = trailersOptions
            this.pointTypes[constants.TRANSPORT_POINT_TYPE_TRAILER_OUT].dataFields[
                constants.TRANSPORT_POINT_DATA_TYPE_REGISTRATION
            ].values = trailersOptions
        }
    }

    loadTransportAllowances = () => {
        this.setState({
            transportAllowances: {
                show: true,
            },
        })
    }

    handleSelectTab = tabKey => {
        if (tabKey === 2) {
            this.loadTransportAllowances()
        }
    }

    /* eslint-disable jsx-a11y/anchor-is-valid */
    render() {
        document.title = formatters.titleFormatter(
            this.transport
                ? `${this.props.intl.formatMessage({ id: 'modules.transportTemplates.headingEdit' })}`
                : this.props.intl.formatMessage({ id: 'modules.transportTemplates.headingNew' })
        )

        const points = this.state.points
        const customers = this.state.customers

        const pricePartsToEdit = customers[this.state.modalPriceParts.customersKey] && customers[this.state.modalPriceParts.customersKey].priceParts

        const duplicity = this.props.duplicity
        const customerAddressDuplicity = this.props.customerAddressDuplicity

        const emptyMandatoryFields = this.state.emptyMandatoryFields
        const fixedMandatoryFields = this.state.fixedMandatoryFields

        const isDisableFieldCustomer = isReadOnlyPermission([PERMS_CUSTOMER.READ, PERMS_CUSTOMER.UPDATE])
        const isDisableFieldCustomerCost = !hasAnyPermission([PERMS_CUSTOMER_COST.UPDATE])

        return (
            <PermissionsCheck hasAny={Object.values(PERMS)} noPermissionsPage>
                <div className="page-inner">
                    <div id="main-wrapper">
                        <div className="row">
                            <div className="col-md-4" style={{ paddingRight: 0 }}>
                                <div className="panel panel-white">
                                    <div className="panel-body panel-padding">
                                        <div className="panel-head">
                                            {this.transport ? (
                                                <h2>
                                                    <FormattedMessage id="modules.transportTemplates.headingEdit" />
                                                </h2>
                                            ) : (
                                                <h2>
                                                    <FormattedMessage id="modules.transportTemplates.headingNew" />
                                                </h2>
                                            )}

                                            {this.transport && (
                                                <span className="dropdown pull-right" style={{ marginTop: '-40px' }}>
                                                    <DropdownButton id={`dropdown-actions`} className={'far fa-bars'} noCaret title="" pullRight>
                                                        <PermissionsCheck has={[PERMS.DELETE]}>
                                                            <MenuItem eventKey={1} onSelect={this.handleDelete}>
                                                                <i className="far fa-trash" /> <FormattedMessage id="buttons.delete" />
                                                            </MenuItem>
                                                        </PermissionsCheck>
                                                    </DropdownButton>
                                                </span>
                                            )}
                                        </div>

                                        <LoadingOverlay
                                            active={
                                                this.props.prerequisites.status.get('fetching') ||
                                                this.props.status.get('fetching') ||
                                                this.props.status.get('saving')
                                            }
                                        >
                                            <PermissionsCheck hasAny={Object.values(PERMS)}>
                                                <Form
                                                    values={this.state.values}
                                                    validationRules={this.validationRules}
                                                    onChange={this.handleChangeValues}
                                                    onSubmit={this.handleSubmit}
                                                    className="pull-left wp-100"
                                                    isEdit={Boolean(this.transport)}
                                                >
                                                    <div>
                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                <InputField
                                                                    id="template_name"
                                                                    label={this.props.intl.formatMessage({ id: 'fields.templateName' })}
                                                                    formGroupClassName={`${
                                                                        emptyMandatoryFields.indexOf('template_name') !== -1 ? 'has-error' : ''
                                                                    } ${
                                                                        fixedMandatoryFields.indexOf('template_name') !== -1 ? 'has-error-fixed' : ''
                                                                    }`}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div>
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <InputField
                                                                    id="template_identifier"
                                                                    label={this.props.intl.formatMessage({ id: 'fields.importIdentifier' })}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="wp-100 m-t-lg display-inline">
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <SelectField
                                                                    id="transport_type_id"
                                                                    label={this.props.intl.formatMessage({ id: 'fields.transportType' })}
                                                                    values={getPrerequisitesArray(
                                                                        this.props.prerequisites.values.get('transport_types')
                                                                    )}
                                                                    formGroupClassName={`${
                                                                        emptyMandatoryFields.indexOf('transport_type_id') !== -1 ? 'has-error' : ''
                                                                    } ${
                                                                        fixedMandatoryFields.indexOf('transport_type_id') !== -1
                                                                            ? 'has-error-fixed'
                                                                            : ''
                                                                    }`}
                                                                />
                                                            </div>
                                                            <div className="col-md-6" />
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <SelectField
                                                                    id="cargo_type_id"
                                                                    label={this.props.intl.formatMessage({ id: 'fields.cargoType' })}
                                                                    values={getPrerequisitesArray(this.props.prerequisites.values.get('cargo_types'))}
                                                                    formGroupClassName={`${
                                                                        emptyMandatoryFields.indexOf('cargo_type_id') !== -1 ? 'has-error' : ''
                                                                    } ${
                                                                        fixedMandatoryFields.indexOf('cargo_type_id') !== -1 ? 'has-error-fixed' : ''
                                                                    }`}
                                                                />
                                                            </div>
                                                            <div className="col-md-6">
                                                                <SelectField
                                                                    id="goods_type_id"
                                                                    label={this.props.intl.formatMessage({ id: 'fields.goodsType' })}
                                                                    values={getPrerequisitesArray(this.props.prerequisites.values.get('goods_types'))}
                                                                    formGroupClassName={`${
                                                                        emptyMandatoryFields.indexOf('goods_type_id') !== -1 ? 'has-error' : ''
                                                                    } ${
                                                                        fixedMandatoryFields.indexOf('goods_type_id') !== -1 ? 'has-error-fixed' : ''
                                                                    }`}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                <InputField
                                                                    id="note"
                                                                    label={this.props.intl.formatMessage({ id: 'fields.note' })}
                                                                    autosize
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <PermissionsCheck hasAny={Object.values(PERMS_CUSTOMER)}>
                                                        <div className="wp-100 m-t-lg display-inline transport-customers">
                                                            <h4 className="wp-100">
                                                                <FormattedMessage id="fields.customer" />
                                                            </h4>
                                                            {customers.map((customer, index) => (
                                                                // eslint-disable-next-line react/no-array-index-key
                                                                <div key={index} className="transport-customers-row">
                                                                    <div className="row">
                                                                        <div className="col-md-12">
                                                                            <CustomerPicker
                                                                                id={`customer_id-${index}`}
                                                                                onDelete={e => {this.handleRemoveCustomer(e, index)}}
                                                                                label={this.props.intl.formatMessage({ id: 'fields.customer' })}
                                                                                values={getPrerequisitesPairs(
                                                                                    this.props.prerequisites.values.get('customers')
                                                                                )}
                                                                                readonly={() => isDisableFieldCustomer}
                                                                                formGroupClassName={`${
                                                                                    emptyMandatoryFields.indexOf(`customer_id-${index}`) !== -1
                                                                                        ? 'has-error'
                                                                                        : ''
                                                                                } ${
                                                                                    fixedMandatoryFields.indexOf(`customer_id-${index}`) !== -1
                                                                                        ? 'has-error-fixed'
                                                                                        : ''
                                                                                }`}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-md-3">
                                                                            <InputField
                                                                                id={`customer_order_number-${index}`}
                                                                                readonly={() => isDisableFieldCustomer}
                                                                                label={this.props.intl.formatMessage({ id: 'fields.orderNumber' })}
                                                                                formGroupClassName={`${
                                                                                    // eslint-disable-next-line prettier/prettier
                                                                                    emptyMandatoryFields.indexOf(`customer_order_number-${index}`) !== -1
                                                                                        ? 'has-error'
                                                                                        : ''
                                                                                } ${
                                                                                    // eslint-disable-next-line prettier/prettier
                                                                                    fixedMandatoryFields.indexOf(`customer_order_number-${index}`) !== -1
                                                                                        ? 'has-error-fixed'
                                                                                        : ''
                                                                                }`}
                                                                            />
                                                                        </div>
                                                                        <div className="col-md-3">
                                                                            <InputField
                                                                                id={`customer_invoicing_distance-${index}`}
                                                                                readonly={() => isDisableFieldCustomer}
                                                                                label={this.props.intl.formatMessage({
                                                                                    id: 'fields.invoicingDistance',
                                                                                })}
                                                                            />
                                                                        </div>
                                                                        <PermissionsCheck hasAny={Object.values(PERMS_CUSTOMER_COST)}>
                                                                            <div className="col-md-6">
                                                                                <div className="form-group">
                                                                                    <div className="pull-left wp-60">
                                                                                        <label htmlFor="nothing">
                                                                                            <FormattedMessage id="fields.price" />:
                                                                                        </label>
                                                                                        <span className="form-control text-right">
                                                                                            <a
                                                                                                href="#"
                                                                                                onClick={e => {
                                                                                                    this.openPriceModal(e, index)
                                                                                                }}
                                                                                                className="pull-left"
                                                                                            >
                                                                                                {!isDisableFieldCustomerCost && (
                                                                                                    <i className="far fa-pencil m-r-xxs" />
                                                                                                )}
                                                                                                <FormattedMessage id={`buttons.${isDisableFieldCustomerCost ? 'details' : 'edit'}`} />
                                                                                            </a>
                                                                                            {formatters.floatFormatter(
                                                                                                this.getTotalPrice(customer.priceParts)
                                                                                            )}
                                                                                        </span>
                                                                                    </div>
                                                                                    <div className="pull-left wp-40">
                                                                                        <SelectField
                                                                                            id={`customer_currency_id-${index}`}
                                                                                            label="&nbsp;"
                                                                                            values={getPrerequisitesArray(
                                                                                                this.props.prerequisites.values.get('currencies'),
                                                                                                'id',
                                                                                                'iso_code'
                                                                                            )}
                                                                                            readonly={() => isDisableFieldCustomerCost}
                                                                                            prompt={false}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </PermissionsCheck>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-md-12">
                                                                            <CheckboxField
                                                                                id={`customer_do_not_invoice-${index}`}
                                                                                readonly={() => isDisableFieldCustomer}
                                                                                label={this.props.intl.formatMessage({ id: 'fields.doNotInvoice' })}
                                                                                className="pull-left no-m"
                                                                            />
                                                                            <a
                                                                                href="#"
                                                                                className="no-text-decoration text-gray m-l-md"
                                                                                onClick={() => {
                                                                                    this.modalInvoicingNoteOpen(index)
                                                                                }}
                                                                            >
                                                                                <i className="far fa-pencil" />{' '}
                                                                                <FormattedMessage id="fields.invoicingNote" />
                                                                            </a>
                                                                            <a
                                                                                href="#"
                                                                                className="no-text-decoration text-gray m-l-md"
                                                                                onClick={() => {
                                                                                    this.modalTransportDescriptionOpen(index)
                                                                                }}
                                                                            >
                                                                                <i className="far fa-pencil" />{' '}
                                                                                <FormattedMessage id="fields.transportDescription" />
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-md-12">
                                                                            <InputField
                                                                                id={`customer_note-${index}`}
                                                                                readonly={() => isDisableFieldCustomer}
                                                                                label={this.props.intl.formatMessage({ id: 'fields.note' })}
                                                                                autosize
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                            <div className="m-t-xxs">
                                                                <PermissionsCheck hasAny={[PERMS_CUSTOMER.UPDATE]}>
                                                                    <a
                                                                        href="#"
                                                                        className="no-text-decoration text-gray"
                                                                        onClick={this.handleAddCustomer}
                                                                    >
                                                                        <i className="far fa-plus-circle" />{' '}
                                                                        <FormattedMessage id="buttons.addCustomer" />
                                                                    </a>
                                                                </PermissionsCheck>
                                                            </div>
                                                        </div>
                                                    </PermissionsCheck>

                                                    <div className="wp-100 m-t-lg btns-form display-inline">
                                                        <Link to={url(this.props.match, 'transport-templates')} className="btn btn-addon btn-default">
                                                            <i className="far fa-chevron-left" /> <FormattedMessage id="buttons.back" />
                                                        </Link>
                                                        {this.transport && (
                                                            <SubmitButton
                                                                saveAndClose
                                                                onClick={this.handleSaveAndClose}
                                                                isEdit={Boolean(this.transport)}
                                                                perms={[
                                                                    PERMS.CREATE,
                                                                    PERMS.UPDATE,
                                                                    PERMS_CUSTOMER.UPDATE,
                                                                    PERMS_CUSTOMER_COST.UPDATE,
                                                                    PERMS_ROUTES.CREATE,
                                                                    PERMS_ROUTES.UPDATE,
                                                                ]}
                                                                className="btn-addon pull-right m-l-sm"
                                                            />
                                                        )}
                                                        <SubmitButton
                                                            isEdit={Boolean(this.transport)}
                                                            perms={[
                                                                PERMS.CREATE,
                                                                PERMS.UPDATE,
                                                                PERMS_CUSTOMER.UPDATE,
                                                                PERMS_CUSTOMER_COST.UPDATE,
                                                                PERMS_ROUTES.CREATE,
                                                                PERMS_ROUTES.UPDATE,
                                                            ]}
                                                            className="btn-addon pull-right m-l-sm"
                                                        />
                                                    </div>
                                                </Form>
                                            </PermissionsCheck>
                                        </LoadingOverlay>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-8" style={{ paddingLeft: '5px' }}>
                                <div className="panel panel-white">
                                    <div className="panel-body panel-padding transport-waypoints">
                                        <Tabs
                                            defaultActiveKey={1}
                                            animation={false}
                                            id="detail-tabs"
                                            className="detail-tabs"
                                            onSelect={this.handleSelectTab}
                                        >
                                            <Tab
                                                eventKey={1}
                                                title={
                                                    <span>
                                                        <i className="far fa-route m-r-xxs" />
                                                        {this.props.intl.formatMessage({ id: 'modules.transports.routeDefinition' })}
                                                    </span>
                                                }
                                            >
                                                <LoadingOverlay
                                                    active={
                                                        this.props.prerequisites.status.get('fetching') ||
                                                        this.props.status.get('fetching') ||
                                                        this.props.status.get('saving')
                                                    }
                                                >
                                                    <TransportTemplatesEditRoute
                                                        transport={this.transport}
                                                        transportType={this.props.createGetTransportTypeByIDSelector(
                                                            this.transport && this.transport.transport_type_id
                                                        )}
                                                        points={points}
                                                        pointTypes={this.pointTypes}
                                                        eventDataTypes={this.eventDataTypes}
                                                        predepartureCheck={this.state.predepartureCheck}
                                                        cargoType={
                                                            this.state.values.cargo_type_id &&
                                                            getPrerequisitesPairs(
                                                                this.props.prerequisites.values.getIn(['cargo_types_entities']),
                                                                'id',
                                                                null
                                                            )[this.state.values.cargo_type_id]
                                                        }
                                                        handleRouteChanged={this.handleRouteChanged}
                                                        handlePredepartureCheckChanged={this.handlePredepartureCheckChanged}
                                                        handleSaveAddress={this.handleSaveAddress}
                                                    />
                                                </LoadingOverlay>
                                            </Tab>
                                            <Tab
                                                eventKey={2}
                                                title={
                                                    <span>
                                                        <i className="far fa-money-bill m-r-xxs" />
                                                        {this.props.intl.formatMessage({ id: 'fields.allowances' })}
                                                    </span>
                                                }
                                            >
                                                {this.state.transportAllowances.show && <TransportTemplatesAllowances transport={this.transport} />}
                                            </Tab>
                                        </Tabs>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Modal show={this.state.modalPriceParts.show} onHide={this.closePriceModal} bsSize="sm">
                        <Modal.Header closeButton>
                            <Modal.Title>
                                {' '}
                                <FormattedMessage id="modules.transports.pricing" />
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <PermissionsCheck hasAny={Object.values(PERMS_CUSTOMER_COST)}>
                                {pricePartsToEdit ? (
                                    <form
                                        onSubmit={e => {
                                            e.preventDefault()
                                            this.closePriceModal()
                                        }}
                                    >
                                        <div className="row form-group">
                                            <div className="col-md-6 p-v-xxs">
                                                <b>
                                                    <FormattedMessage id="fields.basePrice" />:
                                                </b>
                                            </div>
                                            <div className="col-md-6">
                                                <input
                                                    onChange={e => this.setPricePart(0, e.target.value)}
                                                    value={pricePartsToEdit[0]}
                                                    type="text"
                                                    className="form-control text-right"
                                                    disabled={isDisableFieldCustomerCost}
                                                />
                                            </div>
                                        </div>
                                        {this.props.prerequisites.values.get('surcharge_types') &&
                                            this.props.prerequisites.values.get('surcharge_types').map(item => (
                                                <div key={item.get('id')} className="row form-group">
                                                    <div className="col-md-6 p-v-xxs">{item.get('name')}:</div>
                                                    <div className="col-md-6">
                                                        <input
                                                            onChange={e => this.setPricePart(item.get('id'), e.target.value)}
                                                            value={pricePartsToEdit[item.get('id')]}
                                                            type="text"
                                                            className="form-control text-right"
                                                            disabled={isDisableFieldCustomerCost}
                                                        />
                                                    </div>
                                                </div>
                                            ))}
                                        <div className="row form-group">
                                            <div className="col-md-6 p-v-xxs">
                                                <b>
                                                    <FormattedMessage id="fields.totalPrice" />:
                                                </b>
                                            </div>
                                            <div className="col-md-6 p-v-xxs text-right">
                                                <b>{formatters.floatFormatter(this.getTotalPrice(pricePartsToEdit))}</b>
                                            </div>
                                        </div>
                                        <PermissionsCheck hasAny={[PERMS_CUSTOMER_COST.UPDATE]}>
                                            <div className="btns-form text-right">
                                                <button type="submit" className="btn btn-addon btn-success">
                                                    <i className="far fa-check" /> <FormattedMessage id="buttons.done" />
                                                </button>
                                            </div>
                                        </PermissionsCheck>
                                    </form>
                                ) : null}
                            </PermissionsCheck>
                        </Modal.Body>
                    </Modal>

                    <Modal show={Boolean(this.state.modalDuplicity.show)} onHide={this.handleDuplicityCancel} bsSize="sm">
                        <Modal.Header closeButton>
                            <Modal.Title>
                                <FormattedMessage id="confirmDialog.duplicityFound.title" />
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <FormattedMessage id="confirmDialog.duplicityFound.message" />
                            {duplicity && (
                                <div>
                                    <div className="row m-t-lg">
                                        <div className="col-sm-4">
                                            <FormattedMessage id="fields.templateName" />:
                                        </div>
                                        <div className="col-sm-8 text-right">
                                            <strong>{duplicity.template_name}</strong>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-4">
                                            <FormattedMessage id="fields.importIdentifier" />:
                                        </div>
                                        <div className="col-sm-8 text-right">
                                            <strong>{duplicity.template_identifier}</strong>
                                        </div>
                                    </div>
                                    <hr className="tiny" />
                                    <div className="row">
                                        <div className="col-sm-4">
                                            <FormattedMessage id="fields.customer" />:
                                        </div>
                                        <div className="col-sm-8 text-right">
                                            <strong>{duplicity.customer_name}</strong>
                                        </div>
                                    </div>
                                    <hr className="tiny" />
                                    <div className="row">
                                        <div className="col-sm-4">
                                            <FormattedMessage id="fields.loading" />:
                                        </div>
                                        <div className="col-sm-8 text-right">
                                            <strong>{duplicity.loading_address}</strong>
                                        </div>
                                    </div>
                                    <hr className="tiny" />
                                    <div className="row">
                                        <div className="col-sm-4">
                                            <FormattedMessage id="fields.unloading" />:
                                        </div>
                                        <div className="col-sm-8 text-right">
                                            <strong>{duplicity.unloading_address}</strong>
                                        </div>
                                    </div>
                                    <hr className="tiny" />
                                    <div className="row m-b-lg">
                                        <div className="col-sm-4">
                                            <FormattedMessage id="fields.price" />:
                                        </div>
                                        <div className="col-sm-8 text-right">
                                            <strong>{formatters.priceFormatter(duplicity.price, '0,0', duplicity.currency_code)}</strong>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </Modal.Body>
                        <Modal.Footer>
                            <PermissionsCheck hasAny={[PERMS.UPDATE, PERMS.CREATE]}>
                                <button className="btn btn-success m-r-xs" onClick={this.handleDuplicityConfirm}>
                                    <FormattedMessage id="confirmDialog.duplicityFound.accept" />
                                </button>
                            </PermissionsCheck>
                            <button className="btn btn-default" onClick={this.handleDuplicityCancel}>
                                <FormattedMessage id="confirmDialog.duplicityFound.cancel" />
                            </button>
                        </Modal.Footer>
                    </Modal>

                    <Modal show={this.state.modalSaveAddress.show} onHide={this.closeSaveAddressModal} className="modal-level-2">
                        <Modal.Header closeButton>
                            <Modal.Title>
                                <FormattedMessage id="buttons.saveAddress" />
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <PermissionsCheck hasAny={[PERMS.UPDATE, PERMS.CREATE]}>
                                <Form
                                    values={this.state.modalSaveAddress.values}
                                    validationRules={{}}
                                    onChange={this.handleSaveAddressChange}
                                    onSubmit={this.handleSaveAddressSubmit}
                                    className="pull-left wp-100"
                                >
                                    <div className="row">
                                        <div className="col-md-6 b-r">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <InputField id="code" label={this.props.intl.formatMessage({ id: 'fields.code' })} />
                                                </div>
                                                <div className="col-md-6">
                                                    <SelectField
                                                        id="customer_address_type_id"
                                                        label={this.props.intl.formatMessage({ id: 'fields.addressType' })}
                                                        values={getPrerequisitesArray(this.props.prerequisites.values.get('customer_address_types'))}
                                                    />
                                                </div>
                                            </div>
                                            <InputField
                                                id="name"
                                                label={this.props.intl.formatMessage({ id: 'fields.name' })}
                                                formGroupClassName="form-group-bigger"
                                            />
                                            <SelectField
                                                id="customer_id"
                                                label={this.props.intl.formatMessage({ id: 'fields.customer' })}
                                                values={getPrerequisitesArray(this.props.prerequisites.values.get('customers'))}
                                            />
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <InputField id="email" label={this.props.intl.formatMessage({ id: 'fields.email' })} />
                                                </div>
                                                <div className="col-md-6">
                                                    <InputField id="phone" label={this.props.intl.formatMessage({ id: 'fields.phone' })} />
                                                </div>
                                            </div>
                                            <InputField id="note" label={this.props.intl.formatMessage({ id: 'fields.note' })} autosize />
                                            <div className="form-part">
                                                <CheckboxField
                                                    id="is_booking_required_address"
                                                    label={this.props.intl.formatMessage({ id: 'fields.bookingRequired' })}
                                                />
                                                {Boolean(this.state.modalSaveAddress.values.is_booking_required) && (
                                                    <div>
                                                        <InputField
                                                            id="booking_url"
                                                            label={this.props.intl.formatMessage({ id: 'fields.bookingURL' })}
                                                        />
                                                        <InputField
                                                            id="booking_note"
                                                            label={this.props.intl.formatMessage({ id: 'fields.bookingNote' })}
                                                            autosize
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="value_street">
                                                    <FormattedMessage id="fields.street" />:
                                                </label>
                                                <span className="form-control input-readonly">{this.state.modalSaveAddress.values.street}</span>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label htmlFor="value_zipcode">
                                                            <FormattedMessage id="fields.zipcode" />:
                                                        </label>
                                                        <span className="form-control input-readonly">
                                                            {this.state.modalSaveAddress.values.zipcode}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="col-md-8">
                                                    <div className="form-group">
                                                        <label htmlFor="value_street">
                                                            <FormattedMessage id="fields.street" />:
                                                        </label>
                                                        <span className="form-control input-readonly">
                                                            {this.state.modalSaveAddress.values.street}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label htmlFor="value_country_code">
                                                            <FormattedMessage id="fields.country" />:
                                                        </label>
                                                        <span className="form-control input-readonly">
                                                            {this.state.modalSaveAddress.values.country_code}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="col-md-8" />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="value_gps">
                                                    <FormattedMessage id="fields.gps" />:
                                                </label>
                                                <span className="form-control input-readonly">
                                                    {this.state.modalSaveAddress.values
                                                        ? `${this.state.modalSaveAddress.values.gps_lat}, ${
                                                              this.state.modalSaveAddress.values.gps_lng
                                                          }`
                                                        : ''}
                                                </span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="btns-form text-right">
                                        <button type="submit" className="btn btn-success">
                                            <FormattedMessage id="buttons.save" />
                                        </button>
                                    </div>
                                </Form>
                            </PermissionsCheck>
                        </Modal.Body>
                    </Modal>

                    <Modal show={Boolean(this.state.modalSaveAddressDuplicity.show)} onHide={this.handleSaveAddressDuplicityCancel} bsSize="sm">
                        <Modal.Header closeButton>
                            <Modal.Title>
                                <FormattedMessage id="confirmDialog.duplicityFound.title" />
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <FormattedMessage id="confirmDialog.duplicityFound.message" />
                            {customerAddressDuplicity && (
                                <div>
                                    <div className="row m-t-lg">
                                        <div className="col-sm-4">
                                            <FormattedMessage id="fields.customer" />:
                                        </div>
                                        <div className="col-sm-8 text-right">
                                            <strong>{customerAddressDuplicity.customer}</strong>
                                        </div>
                                    </div>
                                    <hr className="tiny" />
                                    <div className="row">
                                        <div className="col-sm-4">
                                            <FormattedMessage id="fields.addressType" />:
                                        </div>
                                        <div className="col-sm-8 text-right">
                                            <strong>{customerAddressDuplicity.type}</strong>
                                        </div>
                                    </div>
                                    <hr className="tiny" />
                                    <div className="row">
                                        <div className="col-sm-4">
                                            <FormattedMessage id="fields.name" />:
                                        </div>
                                        <div className="col-sm-8 text-right">
                                            <strong>{customerAddressDuplicity.name}</strong>
                                        </div>
                                    </div>
                                    <hr className="tiny" />
                                    <div className="row">
                                        <div className="col-sm-4">
                                            <FormattedMessage id="fields.address" />:
                                        </div>
                                        <div className="col-sm-8 text-right">
                                            <strong>{customerAddressDuplicity.address}</strong>
                                        </div>
                                    </div>
                                    <hr className="tiny" />
                                    <div className="row">
                                        <div className="col-sm-4">
                                            <FormattedMessage id="fields.email" />:
                                        </div>
                                        <div className="col-sm-8 text-right">
                                            <strong>{customerAddressDuplicity.email}</strong>
                                        </div>
                                    </div>
                                    <hr className="tiny" />
                                    <div className="row">
                                        <div className="col-sm-4">
                                            <FormattedMessage id="fields.phone" />:
                                        </div>
                                        <div className="col-sm-8 text-right">
                                            <strong>{customerAddressDuplicity.phone}</strong>
                                        </div>
                                    </div>
                                    <hr className="tiny" />
                                    <div className="row">
                                        <div className="col-sm-4">
                                            <FormattedMessage id="fields.note" />:
                                        </div>
                                        <div className="col-sm-8 text-right">
                                            <strong>{customerAddressDuplicity.note}</strong>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </Modal.Body>
                        <Modal.Footer>
                            <PermissionsCheck has={[PERMS.UPDATE, PERMS.CREATE]}>
                                <button className="btn btn-success m-r-xs" onClick={this.handleSaveAddressDuplicityConfirm}>
                                    <FormattedMessage id="confirmDialog.duplicityFound.accept" />
                                </button>
                            </PermissionsCheck>
                            <button className="btn btn-default" onClick={this.handleSaveAddressDuplicityCancel}>
                                <FormattedMessage id="confirmDialog.duplicityFound.cancel" />
                            </button>
                        </Modal.Footer>
                    </Modal>

                    <Modal show={Boolean(this.state.modalTransportDescription.show)} onHide={this.modalTransportDescriptionClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>
                                <FormattedMessage id="fields.transportDescription" />
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <InputField
                                id="transport_description"
                                autosize
                                rows={20}
                                value={this.state.modalTransportDescription.text}
                                readonly={() => isDisableFieldCustomer}
                                onChange={e => {
                                    this.modalTransportDescriptionSetText(e.target.value)
                                }}
                            />
                        </Modal.Body>
                        <Modal.Footer>
                            {!isDisableFieldCustomer && (
                                <button className="btn btn-success btn-addon m-r-xs" onClick={this.modalTransportDescriptionSubmit}>
                                    <i className="far fa-check" /> <FormattedMessage id="buttons.done" />
                                </button>
                            )}
                            <button className="btn btn-default" onClick={this.modalTransportDescriptionClose}>
                                <FormattedMessage id="buttons.cancel" />
                            </button>
                        </Modal.Footer>
                    </Modal>

                    <Modal show={Boolean(this.state.modalInvoicingNote.show)} onHide={this.modalInvoicingNoteClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>
                                <FormattedMessage id="fields.invoicingNote" />
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <InputField
                                id="invoicing_note"
                                autosize
                                rows={20}
                                value={this.state.modalInvoicingNote.text}
                                readonly={() => isDisableFieldCustomer}
                                onChange={e => {
                                    this.modalInvoicingNoteSetText(e.target.value)
                                }}
                            />
                        </Modal.Body>
                        <Modal.Footer>
                            {!isDisableFieldCustomer && (
                                <button className="btn btn-success btn-addon m-r-xs" onClick={this.modalInvoicingNoteSubmit}>
                                    <i className="far fa-check" /> <FormattedMessage id="buttons.done" />
                                </button>
                            )}
                            <button className="btn btn-default" onClick={this.modalInvoicingNoteClose}>
                                <FormattedMessage id="buttons.cancel" />
                            </button>
                        </Modal.Footer>
                    </Modal>

                    <ConfirmDialog options={this.state.confirmDialog} />
                </div>
            </PermissionsCheck>
        )
    }
}

function mapStateToProps(state, ownProps) {
    return {
        transport: createGetTransportByIDSelector(ownProps.match.params.transportID)(state),
        status: createGetCurrentTransportStatusSelector()(state),
        deleting: getTransportDeleting(state),
        deleteError: getTransportDeleteError(state),
        prerequisites: preparePrerequisitesFetchStatusSelectors(componentIdentifier, prerequisites, state),
        duplicity: createGetDuplicitySelector()(state),
        currentUserId: getCurrentUserId(state),
        customerAddressStatus: customerAddressesSelectors.createGetCurrentCustomerAddressStatusSelector()(state),
        customerAddressDuplicity: customerAddressesSelectors.createGetDuplicitySelector()(state),
        createGetCountryByIDSelector: id => createGetCountryByIDSelector(id)(state),
        createGetCurrencyByIDSelector: id => createGetCurrencyByIDSelector(id)(state),
        createGetTransportTypeByIDSelector: id => createGetTransportTypeByIDSelector(id)(state),
        createGetCustomerByIDSelector: id => createGetCustomerByIDSelector(id)(state),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            ...bindActionCreators(
                {
                    ...transportsActionCreators,
                    ...customerAddressesActionCreators,
                    ...preparePrerequisitesActionCreators(componentIdentifier, prerequisites),
                },
                dispatch
            ),
        },
        redirect: toURL => dispatch(push(toURL)),
        notify: (notification, type) => dispatch(Notifications.show(notification, type)),
    }
}

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(TransportTemplatesEdit)
)
