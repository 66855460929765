import { Observable } from 'rxjs'
import { normalize, schema } from 'normalizr'

import { request, parseAPIError } from '../../common/api'
import { vehiclePositionSchema, vehicleSchema } from '../../common/normalizrSchemas'
import { normalizedEntitiesToRecordMap } from '../../common/helpers'

import * as actionTypes from './actionTypes'

import * as actionCreators from './actionCreators'
import * as vehiclesActionCreators from '../vehicles/actionCreators'
import * as vehicleTypesActionCreators from '../vehicle_types/actionCreators'
import * as usersActionCreators from '../users/actionCreators'
import * as driversActionCreators from '../drivers/actionCreators'
import * as driverTimesActionCreators from '../driver_times/actionCreators'

import { VehiclePosition } from './model'
import { Vehicle } from '../vehicles/model'
import { VehicleType } from '../vehicle_types/model'
import { User } from '../users/model'
import { Driver } from '../drivers/model'
import { DriverTimes } from '../driver_times/model'

// Fetch

const fetchVehiclePositionsEpic = action$ =>
    action$.ofType(actionTypes.FETCH).switchMap(() =>
        Observable.concat(
            Observable.of({
                type: actionTypes.FETCH_STARTED,
            }),
            request({
                method: 'POST',
                path: `vehicles/last-positions`,
            })
                .switchMap(ajaxResponse => {
                    const normalizedDataVehicles = normalize(ajaxResponse.response.vehicles, new schema.Array(vehicleSchema))

                    const normalizedDataVehiclePositions = normalize(ajaxResponse.response.vehicle_position, new schema.Array(vehiclePositionSchema))

                    const vehicles = normalizedEntitiesToRecordMap(normalizedDataVehicles.entities.vehicles, Vehicle, normalizedDataVehicles.result)
                    const vehicleTypes = normalizedEntitiesToRecordMap(normalizedDataVehicles.entities.vehicle_types, VehicleType)
                    const users = normalizedEntitiesToRecordMap(normalizedDataVehicles.entities.users, User)

                    const vehiclePositions = normalizedEntitiesToRecordMap(normalizedDataVehiclePositions.entities.vehicle_positions, VehiclePosition)
                    const drivers = normalizedEntitiesToRecordMap(normalizedDataVehiclePositions.entities.drivers, Driver)
                    const driverTimes = normalizedEntitiesToRecordMap(normalizedDataVehiclePositions.entities.times, DriverTimes)

                    const observables = [
                        Observable.of(vehiclesActionCreators.fetchVehiclesFulfilled(vehicles)),
                        Observable.of(vehicleTypesActionCreators.fetchVehicleTypesFulfilled(vehicleTypes)),
                        Observable.of(usersActionCreators.fetchUsersFulfilled(users)),
                        Observable.of(actionCreators.fetchVehiclePositionsFulfilled(vehiclePositions)),
                        Observable.of(driversActionCreators.fetchDriversFulfilled(drivers)),
                        Observable.of(driverTimesActionCreators.fetchDriverTimesFulfilled(driverTimes)),
                    ]

                    return Observable.concat(...observables)
                })
                .catch(error => Observable.of(vehiclesActionCreators.fetchVehiclesRejected(parseAPIError(error))))
                .takeUntil(action$.ofType(actionTypes.FETCH_CANCELLED, actionTypes.FETCH))
        )
    )

export default [fetchVehiclePositionsEpic]
