import React, { Component } from 'react'
import { SortableHandle } from 'react-sortable-hoc'
import moment from 'moment'
import { Tooltip, OverlayTrigger, Collapse } from 'react-bootstrap'
import { FormattedMessage, injectIntl } from 'react-intl'

import * as config from '../../../common/config'
import { getFirstExistValue } from '../../../common/helpers'
import { datetimeFormatter, gpsFormatter, datetimePeriodFormatter, distanceFormatter } from '../../../common/formatters'
import * as constants from '../constants'

import TransportListRowStateIcon from './TransportsListRowStateIcon'
import PermissionsCheck from '../../auth/components/PermissionsCheck'
import { PERMS_ROUTES, PERMS_EVENTS } from '../permissions'

const DragHandle = SortableHandle(() => <i className="far fa-bars m-r-sm sort-handle" />)

class TransportsEditRoutePoint extends Component {
    events = constants.TRANSPORT_EVENT_TYPES_DEFINITION

    state = {
        showEvents: false,
        showNaviEvents: false,
    }

    closeEvents = () => {
        this.setState({ showEvents: false })
    }

    openEvents = () => {
        this.setState({ showEvents: true })
    }

    closeNaviEvents = () => {
        this.setState({ showNaviEvents: false })
    }

    openNaviEvents = () => {
        this.setState({ showNaviEvents: true })
    }

    handleEditButtonClicked = () => {
        this.props.handleEditButtonClicked && this.props.handleEditButtonClicked()
    }

    handleDeleteButtonClicked = () => {
        this.props.handleDeleteButtonClicked && this.props.handleDeleteButtonClicked()
    }

    handleCreateEventButtonClicked = (eventType, driverId) => {
        this.props.handleCreateEventButtonClicked && this.props.handleCreateEventButtonClicked(eventType, driverId)
    }

    handleEditEventButtonClicked = event => {
        this.props.handleEditEventButtonClicked && this.props.handleEditEventButtonClicked(event)
    }

    handleDeleteEventButtonClicked = event => {
        this.props.handleDeleteEventButtonClicked && this.props.handleDeleteEventButtonClicked(event)
    }

    handleChangeStateButtonClicked = stateId => {
        this.props.handleChangeStateButtonClicked && this.props.handleChangeStateButtonClicked(stateId)
    }

    handleCreateEvents = () => {
        this.props.handleCreateEvents && this.props.handleCreateEvents()
    }

    handleCorrectPointEventTimes = () => {
        this.props.handleCorrectPointEventTimes && this.props.handleCorrectPointEventTimes()
    }

    /* eslint-disable jsx-a11y/anchor-is-valid */
    render() {
        const point = this.props.point
        const pointType = this.props.pointType
        const eventTypes = constants.TRANSPORT_EVENT_TYPES_DEFINITION
        const eventDataTypes = this.props.eventDataTypes

        const state = point.transport_point_state_id && this.props.createGetTransportPointStateByIDSelector(point.transport_point_state_id)

        const datetimeFrom = getFirstExistValue([point.bookingFrom, point.specifiedFrom, point.plannedFrom])
        const datetimeTo = getFirstExistValue([
            point.bookingTo || point.bookingFrom,
            point.specifiedTo || point.specifiedFrom,
            point.plannedTo || point.plannedFrom,
        ])
        const datetime = getFirstExistValue([point.finishedAt, point.arrivedAt, datetimeFrom])

        const arrivalClassName = point.arrivedAt && datetimeTo && (point.arrivedAt.isAfter(datetimeTo) ? 'text-danger' : 'text-success')

        const eta = (!state || state.id === constants.TRANSPORT_POINT_STATE_ON_WAY) && point.eta
        const etaClassName = eta && datetimeTo && (eta.isAfter(datetimeTo) ? 'text-danger' : 'text-success')

        const totalPlannedDistance = this.props.totalPlannedDistance ? this.props.totalPlannedDistance : null
        const totalRealDistance = this.props.totalRealDistance ? this.props.totalRealDistance : null

        const tooltipPlannedDistance = (
            <Tooltip id="tooltipPlannedDistance">
                <FormattedMessage id="fields.plannedKilometers" />
            </Tooltip>
        )
        const tooltipRealDistance = (
            <Tooltip id="tooltipRealDistance">
                <FormattedMessage id="fields.realKilometers" />
            </Tooltip>
        )

        let driverId = null
        let possibleNextEvents = []

        if (this.props.transport) {
            const lastEvent = point.transport_events && point.transport_events.length > 0 && point.transport_events[point.transport_events.length - 1]
            const prevPoint = this.props.prevPoint

            if (lastEvent) {
                possibleNextEvents = eventTypes[lastEvent.type_id] && eventTypes[lastEvent.type_id].possibleNextEvents
                driverId = lastEvent.driver_id
            } else if (prevPoint && prevPoint.transport_point_state_id === constants.TRANSPORT_POINT_STATE_FINISHED) {
                possibleNextEvents = pointType.eventTypes.length > 0 ? [pointType.eventTypes[0]] : []
                driverId =
                    prevPoint.transport_events &&
                    prevPoint.transport_events.length > 0 &&
                    prevPoint.transport_events[prevPoint.transport_events.length - 1].driver_id
            } else if (!prevPoint) {
                possibleNextEvents = pointType.eventTypes.length > 0 ? [pointType.eventTypes[0]] : []
            }
        }

        return (
            <div className={`panel panel-${pointType && pointType.className}`}>
                <div className="panel-heading">
                    <span className="pull-left">
                        <PermissionsCheck hasAny={[PERMS_ROUTES.CREATE, PERMS_ROUTES.UPDATE]}>
                            <DragHandle />
                        </PermissionsCheck>
                        <span className="type">{pointType && this.props.intl.formatMessage({ id: pointType.name })}</span>
                        {point.city && `${point.country_code} / ${point.zipcode} / ${point.city}`}
                    </span>
                    <span className="pull-right">
                        <span className="m-l-lg w-140 display-inline text-right">
                            <OverlayTrigger placement="top" overlay={tooltipPlannedDistance}>
                                <span>
                                    {distanceFormatter(point.planned_distance)}
                                    {totalPlannedDistance && totalPlannedDistance !== point.planned_distance
                                        ? ` / ${distanceFormatter(totalPlannedDistance)}`
                                        : ''}
                                </span>
                            </OverlayTrigger>
                        </span>
                        <span className="m-l-lg w-140 display-inline text-right">
                            <OverlayTrigger placement="top" overlay={tooltipRealDistance}>
                                <span>
                                    {distanceFormatter(point.real_distance)}
                                    {totalRealDistance && totalRealDistance !== point.real_distance
                                        ? ` / ${distanceFormatter(totalRealDistance)}`
                                        : ''}
                                </span>
                            </OverlayTrigger>
                        </span>
                        <span className="m-l-lg w-120 display-inline">{datetime && datetimeFormatter(datetime)}</span>
                        <PermissionsCheck hasAny={[PERMS_ROUTES.UPDATE, PERMS_ROUTES.CREATE]}>
                            <a href="#" onClick={this.handleEditButtonClicked} className="m-l-lg">
                                <i className="far fa-pencil" /> <FormattedMessage id="buttons.edit" />
                            </a>
                        </PermissionsCheck>
                        <PermissionsCheck has={[PERMS_ROUTES.DELETE]}>
                            <a href="#" onClick={this.handleDeleteButtonClicked} className="m-l-lg">
                                <i className="far fa-trash" /> <FormattedMessage id="buttons.delete" />
                            </a>
                        </PermissionsCheck>
                    </span>
                </div>
                {!pointType.noDetails && (
                    <div className="panel-body">
                        <div className="row">
                            <div className="col-md-9">
                                <div className="row">
                                    <div className="col-md-4">
                                        <span className="text-gray-light">
                                            <FormattedMessage id="fields.address" />:
                                        </span>
                                        <p>
                                            {point.name && (
                                                <span>
                                                    <strong>{point.name}</strong>
                                                    <br />
                                                </span>
                                            )}
                                            {point.street && `${point.street},`}
                                            <br />
                                            {point.zipcode} {point.city && `${point.city},`}
                                            <br />
                                            {point.country_code}
                                            <br />
                                            {point.gps_lat &&
                                                point.gps_lng &&
                                                `${this.props.intl.formatMessage({ id: 'fields.gps' })}: ${gpsFormatter(
                                                    point.gps_lat,
                                                    point.gps_lng
                                                )}`}
                                        </p>
                                    </div>
                                    <div className="col-md-4 b-l b-r">
                                        <span className="text-gray-light">
                                            <FormattedMessage id="fields.times" />:
                                        </span>
                                        <p className="times">
                                            <span className="left">
                                                <FormattedMessage id="fields.planned" />:
                                            </span>{' '}
                                            {datetimePeriodFormatter(point.plannedFrom, point.plannedTo)}
                                            <br />
                                            <span className="left">
                                                <FormattedMessage id="fields.specified" />:
                                            </span>{' '}
                                            {datetimePeriodFormatter(point.specifiedFrom, point.specifiedTo)}
                                            <br />
                                            {eta && (
                                                <span>
                                                    <span className="left">
                                                        <FormattedMessage id="fields.eta" />:
                                                    </span>
                                                    <span>
                                                        <span className={etaClassName}> {datetimeFormatter(eta) || '-'}</span>
                                                        {point.last_eta_update.distance &&
                                                            ` (${distanceFormatter(point.last_eta_update.distance / 1000)})`}
                                                    </span>
                                                    <br />
                                                </span>
                                            )}
                                            <span className="left">
                                                <FormattedMessage id="fields.arrival" />:
                                            </span>
                                            <span className={arrivalClassName}> {datetimeFormatter(point.arrivedAt) || '-'}</span>
                                            <br />
                                            <span className="left">
                                                <FormattedMessage id="fields.finish" />:
                                            </span>{' '}
                                            {datetimeFormatter(point.finishedAt) || '-'}
                                            <br />
                                            <PermissionsCheck hasAny={[PERMS_ROUTES.UPDATE, PERMS_ROUTES.CREATE]}>
                                                {state && state.id === constants.TRANSPORT_POINT_STATE_FINISHED ? (
                                                    <a href="#" onClick={this.handleCorrectPointEventTimes} className="btn btn-xs btn-default btn-addon m-t-xxs">
                                                        <i className="far fa-clock" /> <FormattedMessage id="fields.correctEventTimes" />
                                                    </a>
                                                ) : null}
                                            </PermissionsCheck>
                                        </p>
                                    </div>
                                    <div className="col-md-4">
                                        <span className="text-gray-light">
                                            <FormattedMessage id="fields.details" />:
                                        </span>
                                        <p className="cargo">
                                            {point.data &&
                                                point.data.map((item, key) => (
                                                    // eslint-disable-next-line react/no-array-index-key
                                                    <span key={`data-${key}`}>
                                                        <span className="left">{this.props.intl.formatMessage({ id: item.label })}:</span>
                                                        {item.value !== '' && (
                                                            <span className="right">
                                                                {item.formatter ? item.formatter(item.value) : item.value}
                                                                {item.unit ? ` ${item.unit}` : ''}
                                                            </span>
                                                        )}
                                                        <br />
                                                    </span>
                                                ))}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                {point.gps_lat && point.gps_lng && (
                                    <a href="#" onClick={this.handleEditButtonClicked}>
                                        <img
                                            className="wp-100 b"
                                            src={`https://image.maps.cit.api.here.com/mia/1.6/mapview?app_id=${
                                                config.CFG_HERE_MAPS_APP_ID
                                            }&app_code=${config.CFG_HERE_MAPS_APP_CODE}&c=${point.gps_lat},${point.gps_lng}&z=16&w=300&h=140`}
                                            alt=""
                                        />
                                    </a>
                                )}
                            </div>
                        </div>

                        {point.note && (
                            <div className="form-part form-part-transport pull-left wp-100">
                                <span className="pull-left w-140 text-gray">
                                    <FormattedMessage id="fields.note" />:
                                </span>
                                <span className="pull-left wrapped">{point.note}</span>
                            </div>
                        )}

                        {Boolean(point.is_booking_required) && pointType.showBookingTime && (
                            <div className="form-part form-part-transport pull-left wp-100">
                                <span className="pull-left w-140 text-gray">
                                    <FormattedMessage id="fields.booking" />:
                                </span>
                                {point.bookingFrom || point.bookingTo ? (
                                    <strong className="pull-left m-r-sm">{datetimePeriodFormatter(point.bookingFrom, point.bookingTo)}</strong>
                                ) : null}
                                {point.booking_url && (
                                    <a href={point.booking_url} target="_blank" rel="noopener noreferrer" className="pull-left m-r-sm">
                                        <i className="far fa-external-link" />
                                    </a>
                                )}
                                {point.booking_note && <span className="pull-left wrapped">{point.booking_note}</span>}
                            </div>
                        )}
                        {point.id &&
                            (state ||
                                possibleNextEvents.length > 0 ||
                                (point.transport_events && point.transport_events.length > 0) ||
                                (point.transport_navi_events && point.transport_navi_events.length > 0)) && (
                                <div className="form-part form-part-transport pull-left wp-100">
                                    {state && (
                                        <span>
                                            <span className="display-inline w-140 text-gray">
                                                <FormattedMessage id="fields.state" />:
                                            </span>
                                            {this.props.intl.formatMessage({ id: `transportPointStates.${state.name}` })}
                                            <span className="m-l-xxs m-r-xxs">
                                                <TransportListRowStateIcon state={state} />
                                            </span>
                                        </span>
                                    )}
                                    {!state && possibleNextEvents.length > 0 && (
                                        <PermissionsCheck hasAny={[PERMS_EVENTS.CREATE, PERMS_EVENTS.UPDATE]}>
                                            <span>
                                                <span className="display-inline w-140 text-gray">
                                                    <FormattedMessage id="buttons.changeState" />:
                                                </span>
                                                <a
                                                    href="#"
                                                    onClick={() => {
                                                        this.handleChangeStateButtonClicked(constants.TRANSPORT_POINT_STATE_ON_WAY)
                                                    }}
                                                    className="btn btn-xs btn-default"
                                                >
                                                    <FormattedMessage id="transportPointStates.onWay" />
                                                </a>
                                            </span>
                                        </PermissionsCheck>
                                    )}
                                    {(!point.transport_events || point.transport_events.length === 0) && possibleNextEvents.length > 0 && (
                                        <PermissionsCheck hasAny={[PERMS_EVENTS.CREATE, PERMS_EVENTS.UPDATE]}>
                                            <span className="m-l-xs">
                                                <a href="#" onClick={this.handleCreateEvents} className="btn btn-xs btn-default">
                                                    <FormattedMessage id="fields.finishQuickly" />
                                                </a>
                                            </span>
                                        </PermissionsCheck>
                                    )}
                                    {point.transport_events && point.transport_events.length > 0 && (
                                        <PermissionsCheck hasAny={Object.values(PERMS_EVENTS)}>
                                            <span className="m-l-lg">
                                                {this.state.showEvents ? (
                                                    <a href="#" onClick={this.closeEvents} className="no-text-decoration">
                                                        <FormattedMessage id="buttons.hideEvents" /> ({point.transport_events.length}){' '}
                                                        <i className="fal fa-angle-up" />
                                                    </a>
                                                ) : (
                                                    <a href="#" onClick={this.openEvents} className="no-text-decoration">
                                                        <FormattedMessage id="buttons.showEvents" /> ({point.transport_events.length}){' '}
                                                        <i className="fal fa-angle-down" />
                                                    </a>
                                                )}
                                            </span>
                                        </PermissionsCheck>
                                    )}

                                    {point.transport_navi_events && point.transport_navi_events.length > 0 && (
                                        <PermissionsCheck hasAny={Object.values(PERMS_EVENTS)}>
                                            <span className="m-l-lg">
                                                {this.state.showNaviEvents ? (
                                                    <a href="#" onClick={this.closeNaviEvents} className="no-text-decoration">
                                                        <FormattedMessage id="buttons.hideNaviEvents" /> ({point.transport_navi_events.length}){' '}
                                                        <i className="fal fa-angle-up" />
                                                    </a>
                                                ) : (
                                                    <a href="#" onClick={this.openNaviEvents} className="no-text-decoration">
                                                        <FormattedMessage id="buttons.showNaviEvents" /> ({point.transport_navi_events.length}){' '}
                                                        <i className="fal fa-angle-down" />
                                                    </a>
                                                )}
                                            </span>
                                        </PermissionsCheck>
                                    )}
                                </div>
                            )}

                        {point.transport_events && point.transport_events.length ? (
                            <div className="row">
                                <Collapse in={this.state.showEvents}>
                                    <div className="col-md-12 m-t-sm">
                                        <table className="table table-no-border no-m">
                                            <thead>
                                                <tr>
                                                    <PermissionsCheck hasAny={[PERMS_EVENTS.UPDATE, PERMS_EVENTS.DELETE]}>
                                                        <th className="w-60" />
                                                    </PermissionsCheck>
                                                    <th className="w-140">
                                                        <FormattedMessage id="fields.time" />
                                                    </th>
                                                    <th className="w-100">
                                                        <FormattedMessage id="fields.vehicle" />
                                                    </th>
                                                    <th className="w-140">
                                                        <FormattedMessage id="fields.driver" />
                                                    </th>
                                                    <th className="w-200">
                                                        <FormattedMessage id="fields.event" />
                                                    </th>
                                                    <th />
                                                    <th className="w-180 text-center">
                                                        <FormattedMessage id="fields.gps" />
                                                    </th>
                                                    <th className="w-80 text-right">
                                                        <FormattedMessage id="fields.attachments" />
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {point.transport_events.map(event => {
                                                    const driver = event.driver_id && this.props.createGetDriverByIDSelector(event.driver_id)
                                                    const vehicle = event.vehicle_id && this.props.createGetVehicleByIDSelector(event.vehicle_id)

                                                    if (!this.events[parseInt(event.type_id)]) {
                                                        return null
                                                    }

                                                    const tooltipEventSource = (
                                                        <Tooltip id="tooltipEventSource">
                                                            {this.props.intl.formatMessage({
                                                                id: event.fb_tmo_id
                                                                    ? 'fields.eventCreatedByDriver'
                                                                    : 'fields.eventCreatedByDispatcher',
                                                            })}
                                                        </Tooltip>
                                                    )

                                                    return (
                                                        <tr key={event.id}>
                                                            <PermissionsCheck hasAny={[PERMS_EVENTS.UPDATE, PERMS_EVENTS.DELETE]}>
                                                                <td>
                                                                    <PermissionsCheck has={[PERMS_EVENTS.UPDATE]}>
                                                                        <a
                                                                            onClick={() => {
                                                                                this.handleEditEventButtonClicked(event)
                                                                            }}
                                                                            href="#"
                                                                            className="m-r-xs"
                                                                        >
                                                                            <i className="far fa-pencil text-gray" />
                                                                        </a>
                                                                    </PermissionsCheck>
                                                                    <PermissionsCheck has={[PERMS_EVENTS.DELETE]}>
                                                                        <a
                                                                            onClick={() => {
                                                                                this.handleDeleteEventButtonClicked(event)
                                                                            }}
                                                                            href="#"
                                                                        >
                                                                            <i className="far fa-trash text-gray" />
                                                                        </a>
                                                                    </PermissionsCheck>
                                                                </td>
                                                            </PermissionsCheck>
                                                            <td>
                                                                {event.datetime && moment(event.datetime).format('DD.MM.YYYY HH:mm')}
                                                                <span className="pull-right w-20 text-center">
                                                                    <OverlayTrigger placement="top" overlay={tooltipEventSource}>
                                                                        {event.fb_tmo_id ? (
                                                                            <i className="far fa-car text-gray-lighter" />
                                                                        ) : (
                                                                            <i className="far fa-user text-gray-lighter" />
                                                                        )}
                                                                    </OverlayTrigger>
                                                                </span>
                                                            </td>
                                                            <td>{vehicle && vehicle.name}</td>
                                                            <td>{driver && driver.getShortFullName()}</td>
                                                            <td>
                                                                {this.props.intl.formatMessage({ id: this.events[parseInt(event.type_id)].name })}
                                                            </td>
                                                            <td>
                                                                {event.transport_event_data &&
                                                                    event.transport_event_data.map((item, key) => {
                                                                        const type = eventDataTypes[item.type_id]
                                                                        return (
                                                                            <span key={item.id}>
                                                                                {key > 0 && ', '}
                                                                                {type && `${this.props.intl.formatMessage({ id: type.label })}: `}
                                                                                {type && type.formatter ? type.formatter(item.value) : item.value}
                                                                                {type && type.unit ? ` ${type.unit}` : ''}
                                                                            </span>
                                                                        )
                                                                    })}
                                                            </td>
                                                            <td className="text-center">{gpsFormatter(event.gps_lat, event.gps_lng)}</td>
                                                            <td className="text-right">
                                                                {event.transport_point_files &&
                                                                    event.transport_point_files.map(file =>
                                                                        file.filename ? (
                                                                            <a
                                                                                key={file.id}
                                                                                href={`${config.CFG_API_BASE_URL}/files/transport-documents/${
                                                                                    file.filename
                                                                                }`}
                                                                                className="text-gray m-l-xxs"
                                                                                target="_blank"
                                                                                rel="noopener noreferrer"
                                                                            >
                                                                                <i className="far fa-file-image" />
                                                                            </a>
                                                                        ) : (
                                                                            <span key={file.id} className="text-default m-l-xxs">
                                                                                <i className="far fa-file-image" />
                                                                            </span>
                                                                        )
                                                                    )}
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </Collapse>
                            </div>
                        ) : null}

                        {point.transport_navi_events && point.transport_navi_events.length ? (
                            <div className="row">
                                <Collapse in={this.state.showNaviEvents}>
                                    <div className="col-md-12 m-t-sm">
                                        <table className="table table-no-border no-m">
                                            <thead>
                                                <tr>
                                                    <th className="w-140">
                                                        <FormattedMessage id="fields.time" />
                                                    </th>
                                                    <th className="w-100">
                                                        <FormattedMessage id="fields.vehicle" />
                                                    </th>
                                                    <th className="w-max">
                                                        <FormattedMessage id="fields.event" />
                                                    </th>
                                                    <th className="w-180 text-center">
                                                        <FormattedMessage id="fields.gps" />
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {point.transport_navi_events.map(event => {
                                                    const vehicle = event.vehicle_id && this.props.createGetVehicleByIDSelector(event.vehicle_id)

                                                    return (
                                                        <tr key={event.id}>
                                                            <td>{event.datetime && moment(event.datetime).format('DD.MM.YYYY HH:mm')}</td>
                                                            <td>{vehicle && vehicle.name}</td>
                                                            <td>
                                                                {event.type_id === 1 ? (
                                                                    <i className="far fa-toggle-on text-success m-r-xs" />
                                                                ) : (
                                                                    <i className="far fa-toggle-off text-danger m-r-xs" />
                                                                )}
                                                                {this.props.intl.formatMessage({
                                                                    id: event.type_id === 1 ? 'fields.navigationStart' : 'fields.navigationStop',
                                                                })}
                                                            </td>
                                                            <td className="text-center">{gpsFormatter(event.gps_lat, event.gps_lng)}</td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </Collapse>
                            </div>
                        ) : null}

                        <PermissionsCheck hasAny={[PERMS_ROUTES.CREATE, PERMS_ROUTES.UPDATE]}>
                            {this.props.transport && point.id && possibleNextEvents.length > 0 && state && (
                                <div className="form-part form-part-transport pull-left wp-100">
                                    <span className="display-inline w-140 text-gray">
                                        <FormattedMessage id="buttons.createEvent" />:
                                    </span>
                                    {possibleNextEvents.map(possibleNextEventTypeId => {
                                        const nextEventType = eventTypes[possibleNextEventTypeId]

                                        return (
                                            <a
                                                href="#"
                                                onClick={() => {
                                                    this.handleCreateEventButtonClicked(possibleNextEventTypeId, driverId)
                                                }}
                                                key={`possibleNextEventTypeId-${possibleNextEventTypeId}`}
                                                className="btn btn-xs btn-default m-r-xs"
                                            >
                                                {this.props.intl.formatMessage({ id: nextEventType.name })}
                                            </a>
                                        )
                                    })}
                                </div>
                            )}
                        </PermissionsCheck>
                    </div>
                )}
            </div>
        )
    }
}

export default injectIntl(TransportsEditRoutePoint)
