/* eslint-disable */
import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { push } from 'react-router-redux'
import Notifications from 'react-notification-system-redux'
import validator from 'validator'
import moment from 'moment'
import deepEqual from 'deep-equal'
import { DropdownButton, MenuItem, Modal, Tabs, Tab } from 'react-bootstrap'

import * as constants from '../constants'

import * as config from '../../../common/config'
import { ConfirmDialog } from '../../../common/confirm_dialog'
import { Form, InputField, SelectField, BooleanField, CheckboxField, SuggestField, CustomerPicker, SubmitButton } from '../../../common/form'
import * as formatters from '../../../common/formatters'
import {
    url,
    validationMessage,
    getPrerequisitesArray,
    getFirstExistValue,
    getFloatVal,
    getPrerequisitesPairs,
    hasAnyPermission,
    isReadOnlyPermission,
    isDisableField,
} from '../../../common/helpers'
import { LoadingOverlay } from '../../../common/loading_overlay'

import { preparePrerequisitesActionCreators } from '../../../common/prerequisites/actionCreators'
import { preparePrerequisitesFetchStatusSelectors } from '../../../common/prerequisites/selectors'

import * as transportsActionCreators from '../actionCreators'
import * as customerAddressesActionCreators from '../../customer_address/actionCreators'
import * as vehiclesActionCreators from '../../vehicles/actionCreators'

import {
    createGetTransportByIDSelector,
    createGetCurrentTransportStatusSelector,
    getTransportDeleting,
    getTransportDeleteError,
    getTransportSendingToVehicle,
    getTransportSendToVehicleError,
    getTransportDeletingFromVehicle,
    getTransportDeleteFromVehicleError,
    createGetCurrentTransportEventStatusSelector,
    getTransportEventDeleting,
    getTransportEventDeleteError,
    getTransportPointStateChanging,
    getTransportPointStateChangeError,
    getTransportLogs,
    getTransportRestoring,
    getTransportRestoreError,
    getTransportApproving,
    getTransportApproveError,
    getPlannedRouteCalculating,
    getPlannedRouteCalculatingError,
    getSpecifiedRouteCalculating,
    getSpecifiedRouteCalculatingError,
    getRealRouteCalculating,
    getRealRouteCalculatingError,
    getRealVehiclePositions,
    getVehicleDataSource,
    getVehicleHomeCountry,
    getCustomersCheckingData,
    getCarriersCheckingData,
    getTransportOrderData,
    getTransportOrderCreating,
    getTransportOrderCreatingError,
    getTransportOrderSending,
    getTransportOrderSendingError,
    getEventTimesCorrection,
    getEventTimesCorrectionError,
    getPointEventTimesCorrection,
    getPointEventTimesCorrectionError,
    getTransportEventsCreating,
    getTransportEventsCreatingError,
    getSuggestsForCarrier,
    getCustomerInfo, getCustomerInfoFetching,
} from '../selectors'
import { getCurrentUserId, getSettings } from '../../auth/selectors'
import { createGetCountryByIDSelector } from '../../countries/selectors'
import {
    createGetCurrentCustomerAddressStatusSelector,
    createGetDuplicitySelector,
    getFoundAddresses,
    getFoundAddressesFetching,
    getFoundAddressesError,
} from '../../customer_address/selectors'
import { createGetDriverByIDSelector } from '../../drivers/selectors'
import { createGetTrailerByIDSelector } from '../../trailers/selectors'
import { createGetTransportTypeByIDSelector } from '../../transport_types/selectors'
import { createGetTransportPointStateByIDSelector } from '../../transport_point_states/selectors'
import { createGetTransportStartSelector, createGetVehicleByIDSelector } from '../../vehicles/selectors'

import PermissionsCheck from '../../auth/components/PermissionsCheck'
import {
    PERMS,
    PERMS_TO_VEHICLE,
    PERMS_LOG,
    PERMS_CUSTOMER,
    PERMS_CUSTOMER_COST,
    PERMS_SPEDITION,
    PERMS_SPEDITION_COST,
    PERMS_FILES
} from '../permissions'
import { createGetUserByIDSelector } from '../../users/selectors'

import TransportsEditRoute from './TransportsEditRoute'
import TransportsFiles from './TransportsFiles'
import TransportsAllowances from './TransportsAllowances'
import TransportsGoods from './TransportsGoods'
import TransportsParts from './TransportsParts'
import TransportsScoring from './TransportsScoring'
import TransportsShippingOrderForm from './TransportsShippingOrderForm'

const componentIdentifier = 'transports_edit'
const prerequisites = [
    'users',
    'transport_states',
    'transport_types',
    'cargo_types',
    'cargo_types_entities',
    'goods_types',
    'customers',
    'currencies',
    'vehicles',
    'carriers',
    'surcharge_types',
    'trailers',
    'drivers',
    'customer_address_types',
    'transport_conditions',
    'company_cost_centers',
]

class TransportsEdit extends Component {
    transport = null
    successEditCallback = null
    pointTypes = constants.TRANSPORT_POINT_TYPES_DEFINITION
    eventDataTypes = constants.TRANSPORT_EVENT_DATA_TYPES_DEFINITION

    defaultValues = {
        is_spedition: false,
        is_cabotage_auto: true,
        is_cabotage: false,
    }

    emptyCustomer = {
        id: null,
        customer_id: null,
        order_number: '',
        price: 0,
        currency_id: null,
        note: '',
        transport_description: '',
        invoicing_note: '',
        invoicing_distance: 0,
        do_not_invoice: 0,
        priceParts: {},
    }

    state = {
        values: this.defaultValues,
        points: [],
        customers: [
            {
                id: null,
                customer_id: null,
                order_number: null,
                price: null,
                currency_id: null,
                note: null,
                transport_description: null,
                invoicing_note: null,
                invoicing_distance: 0,
                do_not_invoice: 0,
                priceParts: {},
                delivery_addresses_id: null,
                delivery_address: null,
                invoicing_address_id: null,
                invoicing_addresses: null,
            },
        ],
        predepartureCheck: true,
        preloading: {
            preloaded: true,
            datetime: '',
            address: '',
            note: '',
        },
        transportStart: null,
        showLogModal: false,
        confirmDialog: {
            show: false,
            title: '',
            message: '',
            labelCancel: '',
            labelAccept: '',
            classNameAccept: 'text-success',
            classNameCancel: 'text-default',
            onCancel: () => {},
            onAccept: () => {},
        },
        modalPriceParts: {
            show: false,
            customersKey: 0,
        },
        modalSaveAddress: {
            show: false,
            values: {},
        },
        modalSaveAddressDuplicity: {
            show: false,
            duplicity: null,
        },
        modalOrder: {
            show: false,
            transportPart: null,
            transportCustomer: null,
            forWhom: constants.TRANSPORT_ORDER_FOR_SUPPLIER,
            documentType: constants.TRANSPORT_ORDER_TYPE_ORDER,
        },
        modalCustomerAddressesFound: {
            show: false,
            selectedAddress: null,
        },
        modalTransportDescription: {
            show: false,
            text: '',
            customersKey: null,
            readonly: null,
        },
        modalInvoicingNote: {
            show: false,
            text: '',
            customersKey: null,
        },
        emptyMandatoryFields: [],
        fixedMandatoryFields: [],
        ignoreEmptyMandatoryFields: false,
        customerAddressToFind: {
            values: {},
        },
        transportFiles: {
            show: false,
        },
        allRequiredFilesUploaded: false,
        transportGoods: {
            show: false,
        },
        transportParts: {
            show: false,
        },
        allowances: {
            show: false,
        },
        transportScoring: {
            show: false,
        },
    }

    validationRules = {
        transport_state_id: value => validator.isEmpty(String(value)) && validationMessage('isEmpty'),
    }

    setDisponentId = disponentId => {
        this.setState({
            values: {
                ...this.state.values,
                disponent_id: disponentId,
            },
        })
    }

    // confirm dialog
    showConfirmDialog = options => {
        this.setState({
            confirmDialog: {
                show: true,
                title: options.title || this.props.intl.formatMessage({ id: 'confirmDialog.default.title' }),
                message: options.message || this.props.intl.formatMessage({ id: 'confirmDialog.default.message' }),
                labelCancel: options.labelCancel || this.props.intl.formatMessage({ id: 'confirmDialog.default.cancel' }),
                labelAccept: options.labelAccept || this.props.intl.formatMessage({ id: 'confirmDialog.default.accept' }),
                classNameAccept: options.classNameAccept || 'btn-success',
                classNameCancel: options.classNameCancel || 'btn-default',
                onCancel: options.onCancel || this.hideConfirmDialog,
                onAccept: options.onAccept || this.hideConfirmDialog,
            },
        })
    }

    hideConfirmDialog = () => {
        this.setState({
            confirmDialog: {
                show: false,
                onCancel: () => {},
                onAccept: () => {},
            },
        })
    }

    // delete
    handleDelete = () => {
        this.showConfirmDialog({
            title: this.props.intl.formatMessage({ id: 'confirmDialog.transportDelete.title' }),
            message: this.props.intl.formatMessage({ id: 'confirmDialog.transportDelete.message' }),
            labelCancel: this.props.intl.formatMessage({ id: 'confirmDialog.transportDelete.cancel' }),
            labelAccept: this.props.intl.formatMessage({ id: 'confirmDialog.transportDelete.accept' }),
            classNameAccept: 'btn-danger',
            onAccept: () => {
                this.props.actions.deleteTransport(this.transport.id)
                this.hideConfirmDialog()
            },
        })
    }

    transportCanBeDeleted = () => this.props.transport && !this.props.transport.deleted_at

    // restore
    handleRestore = () => {
        this.showConfirmDialog({
            title: this.props.intl.formatMessage({ id: 'confirmDialog.transportRestore.title' }),
            message: this.props.intl.formatMessage({ id: 'confirmDialog.transportRestore.message' }),
            labelCancel: this.props.intl.formatMessage({ id: 'confirmDialog.transportRestore.cancel' }),
            labelAccept: this.props.intl.formatMessage({ id: 'confirmDialog.transportRestore.accept' }),
            classNameAccept: 'btn-success',
            onAccept: () => {
                this.props.actions.restoreTransport(this.transport.id)
                this.hideConfirmDialog()
            },
        })
    }

    // approval
    handleApproval = () => {
        this.showConfirmDialog({
            title: this.props.intl.formatMessage({ id: 'confirmDialog.transportApproval.title' }),
            message: this.props.intl.formatMessage({ id: 'confirmDialog.transportApproval.message' }),
            labelCancel: this.props.intl.formatMessage({ id: 'confirmDialog.transportApproval.cancel' }),
            labelAccept: this.props.intl.formatMessage({ id: 'confirmDialog.transportApproval.accept' }),
            classNameAccept: 'btn-success',
            onAccept: () => {
                this.props.actions.approveTransport(this.transport.id)
                this.hideConfirmDialog()
            },
        })
    }

    transportCanBeRestored = () => this.props.transport && this.props.transport.deleted_at

    // copy sms
    handleSmsCopy = () => {
        const sms_template = this.props.settings && this.props.settings.get('sms_template')
        const sms_customer_template = this.props.settings && this.props.settings.get('sms_customer_template')
        const sms_route_point_template = this.props.settings && this.props.settings.get('sms_route_point_template')

        let sms = sms_template

        if (sms.includes('[transport_id]')) {
            sms = sms.replace('[transport_id]', this.transport.id)
        }

        if (sms.includes('[customers]')) {
            const customers = []

            if (sms_customer_template) {
                const customersList = this.props.prerequisites.values.get('customers')
                const customersPairs = getPrerequisitesPairs(customersList)

                this.state.customers.forEach(customer => {
                    let text = sms_customer_template

                    if (text.includes('[customer_name]')) {
                        text = text.replace('[customer_name]', customersPairs[customer.customer_id] ? customersPairs[customer.customer_id] : '-')
                    }
                    if (text.includes('[order_number]')) {
                        text = text.replace('[order_number]', customer.order_number ? customer.order_number : '-')
                    }

                    customers.push(text)
                })
            }

            if (customers.length === 0) sms = sms.replace('[customers]', '-')
            else sms = sms.replace('[customers]', customers.join('\n'))
        }

        if (sms.includes('[points]')) {
            const points = []
            if (sms_route_point_template) {
                this.state.points.forEach(point => {
                    let text = sms_route_point_template

                    const datetimeFrom = getFirstExistValue([point.bookingFrom, point.specifiedFrom, point.plannedFrom])
                    const datetime = getFirstExistValue([point.finishedAt, point.arrivedAt, datetimeFrom])

                    point.data.forEach(data => {
                        switch (data.label) {
                            case 'fields.code':
                                if (text.includes('[fields.code]')) {
                                    text = text.replace('[fields.code]', data.value !== '' ? data.value : '-')
                                }
                                break
                            case 'fields.weight':
                                if (text.includes('[fields.weight]')) {
                                    text = text.replace('[fields.weight]', data.value !== '' ? data.value : '-')
                                }
                                break
                            case 'fields.pcsToUnload':
                                if (text.includes('[fields.pcs]')) {
                                    text = text.replace('[fields.pcs]', data.value !== '' ? data.value : '-')
                                }
                                break
                            case 'fields.pcsToLoad':
                                if (text.includes('[fields.pcs]')) {
                                    text = text.replace('[fields.pcs]', data.value !== '' ? data.value : '-')
                                }
                                break
                            default:
                                break
                        }
                    })

                    if (text.includes('[fields.code]')) {
                        text = text.replace('[fields.code]', '-')
                    }
                    if (text.includes('[fields.weight]')) {
                        text = text.replace('[fields.weight]', '-')
                    }
                    if (text.includes('[fields.pcs]')) {
                        text = text.replace('[fields.pcs]', '-')
                    }

                    if (text.includes('[datetime]')) {
                        text = text.replace(
                            '[datetime]',
                            datetime || (datetime !== '' && datetime !== undefined) ? formatters.datetimeFormatter(datetime) : '-'
                        )
                    }
                    if (text.includes('[type]')) {
                        text = text.replace(
                            '[type]',
                            point.type_id !== '' ? this.props.intl.formatMessage({ id: this.pointTypes[point.type_id].name }) : '-'
                        )
                    }
                    if (text.includes('[street]')) {
                        text = text.replace('[street]', point.street || point.street !== '' ? point.street : '-')
                    }
                    if (text.includes('[name]')) {
                        text = text.replace('[name]', point.name || point.name !== '' ? point.name : '-')
                    }
                    if (text.includes('[city]')) {
                        text = text.replace('[city]', point.city || point.city !== '' ? point.city : '-')
                    }
                    if (text.includes('[zipcode]')) {
                        text = text.replace('[zipcode]', point.zipcode || point.zipcode !== '' ? point.zipcode : '-')
                    }
                    if (text.includes('[country_code]')) {
                        text = text.replace('[country_code]', point.country_code || point.country_code !== '' ? point.country_code : '-')
                    }
                    if (text.includes('[gps_lat]')) {
                        text = text.replace('[gps_lat]', point.gps_lat || point.gps_lat !== '' ? point.gps_lat : '-')
                    }
                    if (text.includes('[gps_lng]')) {
                        text = text.replace('[gps_lng]', point.gps_lng || point.gps_lng !== '' ? point.gps_lng : '-')
                    }
                    if (text.includes('[fieldsCode]')) {
                        text = text.replace('[fieldsCode]', point.fieldsCode || point.fieldsCode !== '' ? point.fieldsCode : '-')
                    }
                    if (text.includes('[fieldsWeight]')) {
                        text = text.replace('[fieldsWeight]', point.fieldsWeight || point.fieldsWeight !== '' ? point.fieldsWeight : '-')
                    }
                    if (text.includes('[fieldsPcsToLoad]')) {
                        text = text.replace('[fieldsPcsToLoad]', point.fieldsPcsToLoad || point.fieldsPcsToLoad !== '' ? point.fieldsPcsToLoad : '-')
                    }

                    points.push(text)
                })
            }
            if (points.length === 0) sms = sms.replace('[points]', '-')
            else sms = sms.replace('[points]', points.join('\n'))
        }

        const dummy = document.createElement('textarea')
        document.body.appendChild(dummy)
        dummy.value = sms

        dummy.select()
        document.execCommand('copy')

        document.body.removeChild(dummy)
    }

    transpportCanSendSms = () => this.props.settings && this.props.settings.get('sms_template')

    // save transport
    handleSubmit = values => {
        this.setValues(values)
        this.handleSave()
    }

    handleSave = () => {
        this.successEditCallback = null
        if (!this.transport) {
            this.successEditCallback = () => {
                this.props.redirect(url(this.props.match, 'transports'))
            }
        } else {
            this.successEditCallback = () => {
                this.setState({
                    ignoreEmptyMandatoryFields: false,
                })
            }
        }
        this.saveTransport()
    }

    handleSaveAndClose = () => {
        this.successEditCallback = () => {
            this.props.redirect(url(this.props.match, 'transports'))
        }
        this.saveTransport()
    }

    saveTransport = () => {
        const values = this.getValuesForSave()

        if (!this.state.ignoreEmptyMandatoryFields && this.getEmptyMandatoryFields(values).length > 0) {
            this.showConfirmDialog({
                title: this.props.intl.formatMessage({ id: 'confirmDialog.emptyMandatoryFields.title' }),
                message: this.props.intl.formatMessage({ id: 'confirmDialog.emptyMandatoryFields.message' }),
                labelCancel: this.props.intl.formatMessage({ id: 'confirmDialog.emptyMandatoryFields.cancel' }),
                labelAccept: this.props.intl.formatMessage({ id: 'confirmDialog.emptyMandatoryFields.accept' }),
                classNameAccept: 'btn-success',
                onAccept: () => {
                    this.hideConfirmDialog()
                    this.setState(
                        {
                            ignoreEmptyMandatoryFields: true,
                        },
                        () => {
                            this.saveTransport()
                        }
                    )
                },
            })
            return false
        }

        this.props.actions.saveTransport(values)

        return true
    }

    getValuesForSave = () => ({
        ...this.state.values,
        is_predeparture_check: this.state.predepartureCheck,
        is_all_required_files_uploaded: this.state.allRequiredFilesUploaded,
        is_preloaded: this.state.preloading.is_preloaded,
        preloading_datetime: this.state.preloading.datetime,
        preloading_address: this.state.preloading.address,
        preloading_note: this.state.preloading.note,
        customers: this.state.customers.map(customer => ({
            id: customer.id,
            customer_id: customer.customer_id,
            order_number: customer.order_number,
            price: this.getTotalPrice(customer.priceParts),
            currency_id: customer.currency_id,
            note: customer.note,
            transport_description: customer.transport_description,
            invoicing_note: customer.invoicing_note,
            invoicing_distance: customer.invoicing_distance,
            do_not_invoice: customer.do_not_invoice,
            surcharges: Object.keys(customer.priceParts).map(key => ({
                surcharge_type_id: key,
                price: customer.priceParts[key],
            })),
            invoicing_address_id: customer.invoicing_address_id,
            delivery_address_id: customer.delivery_address_id,
        })),
        points: this.state.points.map(point => ({
            id: point.id,
            type_id: point.type_id,
            datetime_planned_from: point.plannedFrom && point.plannedFrom.format('YYYY-MM-DD HH:mm'),
            datetime_planned_to: point.plannedTo && point.plannedTo.format('YYYY-MM-DD HH:mm'),
            datetime_specified_from: point.specifiedFrom && point.specifiedFrom.format('YYYY-MM-DD HH:mm'),
            datetime_specified_to: point.specifiedTo && point.specifiedTo.format('YYYY-MM-DD HH:mm'),
            datetime_booking_from: point.bookingFrom && point.bookingFrom.format('YYYY-MM-DD HH:mm'),
            datetime_booking_to: point.bookingTo && point.bookingTo.format('YYYY-MM-DD HH:mm'),
            name: point.name,
            street: point.street,
            city: point.city,
            zipcode: point.zipcode,
            country_code: point.country_code,
            gps_lat: point.gps_lat,
            gps_lng: point.gps_lng,
            note: point.note,
            is_booking_required: point.is_booking_required,
            booking_url: point.booking_url,
            booking_note: point.booking_note,
            data: point.data
                ? point.data.map(item => ({
                      type_id: item.type_id,
                      value: item.value,
                  }))
                : [],
        })),
    })

    getEmptyMandatoryFields = values => {
        const emptyMandatoryFields = []

        if (!values.transport_type_id) {
            emptyMandatoryFields.push('transport_type_id')
        }

        if (!values.cargo_type_id) {
            emptyMandatoryFields.push('cargo_type_id')
        }

        if (!values.goods_type_id) {
            emptyMandatoryFields.push('goods_type_id')
        }

        this.state.customers.forEach((transportCustomer, index) => {
            if (!transportCustomer.order_number) {
                emptyMandatoryFields.push(`customer_order_number-${index}`)
            }

            if (!transportCustomer.customer_id) {
                emptyMandatoryFields.push(`customer_id-${index}`)
            }
        })

        this.setState({
            emptyMandatoryFields,
        })

        return emptyMandatoryFields
    }

    getFixedMandatoryFields = () => {
        const values = this.getValuesForSave()
        const fixedMandatoryFields = []

        if (this.state.emptyMandatoryFields.indexOf('transport_type_id') !== -1 && values.transport_type_id) {
            fixedMandatoryFields.push('transport_type_id')
        }

        if (this.state.emptyMandatoryFields.indexOf('cargo_type_id') !== -1 && values.cargo_type_id) {
            fixedMandatoryFields.push('cargo_type_id')
        }

        if (this.state.emptyMandatoryFields.indexOf('goods_type_id') !== -1 && values.goods_type_id) {
            fixedMandatoryFields.push('goods_type_id')
        }

        this.state.customers.forEach((transportCustomer, index) => {
            if (this.state.emptyMandatoryFields.indexOf(`customer_order_number-${index}`) !== -1 && transportCustomer.order_number) {
                fixedMandatoryFields.push(`customer_order_number-${index}`)
            }

            if (this.state.emptyMandatoryFields.indexOf(`customer_id-${index}`) !== -1 && transportCustomer.customer_id) {
                fixedMandatoryFields.push(`customer_id-${index}`)
            }
        })

        this.setState({
            fixedMandatoryFields,
        })

        return fixedMandatoryFields
    }

    // transport form
    handleChangeValues = values => {
        this.setValues(values, this.getFixedMandatoryFields)
    }

    setValues = (values, callback) => {

        let customerChanged = false
        const customersIds = []
        const customers = this.state.customers.map((customer, index) => {
            if (customer.customer_id !== values[`customer_id-${index}`]) {
                customerChanged = true
            }
            if (
                values[`customer_id-${index}`] &&
                ((customer.customer_id && customer.customer_id !== values[`customer_id-${index}`]) ||
                    !(customer.invoicing_addresses || customer.delivery_addresses))
            ) {
                this.loadCustomerInfo(index, values[`customer_id-${index}`])
            }

            if (values[`customer_id-${index}`]) {
                customersIds.push(values[`customer_id-${index}`])
            }

            return {
                ...customer,
                customer_id: values[`customer_id-${index}`],
                order_number: values[`customer_order_number-${index}`],
                currency_id: values[`customer_currency_id-${index}`],
                note: values[`customer_note-${index}`],
                invoicing_distance: values[`customer_invoicing_distance-${index}`],
                do_not_invoice: values[`customer_do_not_invoice-${index}`],
                invoicing_address_id: values[`customer_invoicing_address_id-${index}`],
                delivery_address_id: values[`customer_delivery_address_id-${index}`],
            }
        })

        if (customerChanged) {
            this.props.actions.checkCustomers(customersIds, this.transport && this.transport.id)
        }

        if (values.carrier_id !== this.state.values.carrier_id) {
            this.props.actions.checkCarriers([values.carrier_id], this.transport && this.transport.id)
            this.props.actions.fetchSuggestForCarrier(values.carrier_id)
        }

        if (values.vehicle_id !== this.state.values.vehicle_id) {
            this.loadVehicleHomeCountry(values.vehicle_id)
        }

        this.setState(
            {
                values,
                customers,
            },
            () => {
                callback && callback()
            }
        )
    }

    setCustomerInfo = customerInfo => {
        const customersInfo = customerInfo.toJS()
        const customers = [...this.state.customers]
        const values = { ...this.state.values }

        customers.forEach((customer, key) => {
            if (!customersInfo[customer.customer_id]) {
                return
            }

            customerInfo = customersInfo[customer.customer_id]

            customers[key]['delivery_addresses'] = customerInfo.delivery_addresses
            customers[key]['invoicing_addresses'] = customerInfo.invoicing_addresses

            if (customerInfo.default_invoicing_address_id && !values[`customer_invoicing_address_id-${key}`]) {
                values[`customer_invoicing_address_id-${key}`] = String(customerInfo.default_invoicing_address_id)
            }
        })

        this.setState({
            customers,
            values,
        })
    }

    setValuesFromTransport = transport => {
        const values = transport.toJS()
        const customers = []
        const customersIds = []

        if (transport.transport_customers && transport.transport_customers.size) {
            transport.transport_customers.valueSeq().forEach(transport_customer => {
                const customer = {
                    id: transport_customer.id,
                    customer_id: transport_customer.customer_id,
                    order_number: transport_customer.order_number,
                    price: transport_customer.price,
                    currency_id: transport_customer.currency_id,
                    note: transport_customer.note,
                    transport_description: transport_customer.transport_description,
                    invoicing_note: transport_customer.invoicing_note,
                    invoicing_distance: transport_customer.invoicing_distance,
                    do_not_invoice: transport_customer.do_not_invoice,
                    invoicing_address_id: transport_customer.invoicing_address_id,
                    delivery_address_id: transport_customer.delivery_address_id,
                    priceParts: {},
                }

                customer.priceParts[0] = transport_customer.price_base
                if (transport_customer.surcharges && transport_customer.surcharges.size) {
                    transport_customer.surcharges.valueSeq().forEach(surcharge => {
                        customer.priceParts[surcharge.surcharge_type_id] = surcharge.price
                    })
                }

                customers.push(customer)
                customersIds.push(customer.customer_id)
            })
        }

        if (customers.length === 0) {
            customers.push(this.emptyCustomer)
        } else {
            this.props.actions.checkCustomers(customersIds, transport.id)
        }

        if (transport.carrier_id) {
            this.props.actions.checkCarriers([transport.carrier_id], transport.id)
        }

        customers.forEach((customer, index) => {
            values[`customer_id-${index}`] = customer.customer_id
            values[`customer_order_number-${index}`] = customer.order_number
            values[`customer_currency_id-${index}`] = customer.currency_id
            values[`customer_note-${index}`] = customer.note
            values[`customer_invoicing_distance-${index}`] = customer.invoicing_distance
            values[`customer_do_not_invoice-${index}`] = customer.do_not_invoice
            values[`customer_invoicing_address_id-${index}`] = customer.invoicing_address_id
            values[`customer_delivery_address_id-${index}`] = customer.delivery_address_id
        })

        if (!transport.company_cost_center_id) {
            values.company_cost_center_id = this.props.settings && this.props.settings.get('transports_def_cost_center_id')
        }

        this.setState(
            {
                customers,
                predepartureCheck: transport.is_predeparture_check,
                preloading: {
                    is_preloaded: transport.is_preloaded,
                    datetime: transport.preloading_datetime,
                    address: transport.preloading_address,
                    note: transport.preloading_note,
                },
                allRequiredFilesUploaded: transport.is_all_required_files_uploaded,
            },
            () => {
                this.setValues(values)
            }
        )
    }

    handleChangeVehicle = (key, input) => {
        var vehicleId = input.value

        this.loadTransportStart(vehicleId)
        this.loadVehicleDataSource(vehicleId)
        this.loadVehicleHomeCountry(vehicleId)

        this.setState({
            values: {
                ...this.state.values,
                vehicle_id: vehicleId,
            },
        })

    }

    handleChangeSpedition = state => {
        var values = {
            ...this.state.values,
            is_spedition: state
        };
        if(this.state.values.is_cabotage_auto){
            values.is_cabotage = this.isCabotage(this.props.vehicleHomeCountry, this.state.points, state)
        }

        this.setState({
            values: values,
        })

    }

    handleChangeCabotageAuto = state => {
        var values = {
            ...this.state.values,
            is_cabotage_auto: state
        };
        if(state){
            values.is_cabotage = this.isCabotage(this.props.vehicleHomeCountry, this.state.points, this.state.values.is_spedition)
        }
        this.setState({
            values: values,
        })

    }

    isCabotage = (homeCountry = null, points = [], isSpedition) => {
        var isCabotage = 0;
        if(!isSpedition){
            var loadingCountryCode = null;
            var unloadingCountryCode = null;
            var homeCountryCode = homeCountry ? homeCountry.iso_code : null;
            if(points.length){
                points.map((point, pointIndex) => {
                    if(point.type_id == constants.TRANSPORT_POINT_TYPE_LOADING && loadingCountryCode == null){ //only first loading
                        loadingCountryCode = point.country_code;
                    }
                    if(point.type_id == constants.TRANSPORT_POINT_TYPE_UNLOADING){ //last unloading
                        unloadingCountryCode = point.country_code;
                    }
                })
            }
            if(homeCountryCode && loadingCountryCode && unloadingCountryCode){
                isCabotage = homeCountryCode != loadingCountryCode && homeCountryCode != unloadingCountryCode ? 1 : 0;
            }
        }
        return isCabotage;
    }

    loadTransportStart = vehicleId => {
        const firstTransportPoint = this.getFirstTransportPoint()

        firstTransportPoint &&
            this.props.actions.fetchTransportStart({
                vehicle_id: vehicleId,
                transport_id: this.transport && this.transport.id,
                datetime: getFirstExistValue([
                    firstTransportPoint.arrivedAt && firstTransportPoint.arrivedAt.format('YYYY-MM-DD HH:mm'),
                    firstTransportPoint.bookingFrom && firstTransportPoint.bookingFrom.format('YYYY-MM-DD HH:mm'),
                    firstTransportPoint.specifiedFrom && firstTransportPoint.specifiedFrom.format('YYYY-MM-DD HH:mm'),
                    firstTransportPoint.plannedFrom && firstTransportPoint.plannedFrom.format('YYYY-MM-DD HH:mm'),
                ]),
            })
    }

    // route points
    handlePredepartureCheckChanged = value => {
        this.setState({
            predepartureCheck: value,
        })
    }

    handlePreloadingChanged = (key, value) => {
        this.setState({
            preloading: {
                ...this.state.preloading,
                [key]: value,
            },
        })
    }

    handleRouteChanged = points => {
        this.setPoints(points, () => {
            this.loadTransportStart(this.state.values.vehicle_id)
            if(this.state.values.is_cabotage_auto){
                var isCabotage = this.isCabotage(this.props.vehicleHomeCountry, this.state.points, this.state.values.is_spedition)
                this.setState({
                    values: {
                        ...this.state.values,
                        is_cabotage: isCabotage
                    },
                });
            }

        })
    }

    getFirstTransportPoint = () => this.state.points.find(point => point.type_id !== constants.TRANSPORT_POINT_TYPE_WAYPOINT)

    setPoints = (points, callback) => {
        this.setState(
            {
                points,
            },
            () => {
                callback && callback()
            }
        )
    }

    setPointsFromTransport = transport => {
        const points =
            transport.transport_points &&
            transport.transport_points
                .valueSeq()
                .map(point => {
                    const country = this.props.createGetCountryByIDSelector(point.country_id)
                    return {
                        ...point.toJS(),
                        plannedFrom: point.datetime_planned_from && moment(point.datetime_planned_from),
                        plannedTo: point.datetime_planned_to && moment(point.datetime_planned_to),
                        specifiedFrom: point.datetime_specified_from && moment(point.datetime_specified_from),
                        specifiedTo: point.datetime_specified_to && moment(point.datetime_specified_to),
                        bookingFrom: point.datetime_booking_from && moment(point.datetime_booking_from),
                        bookingTo: point.datetime_booking_to && moment(point.datetime_booking_to),
                        arrivedAt: point.datetime_arrival && moment(point.datetime_arrival),
                        finishedAt: point.datetime_finished && moment(point.datetime_finished),
                        eta: point.last_eta_update && point.last_eta_update.datetime_real_eta && moment(point.last_eta_update.datetime_real_eta),
                        country_code: country ? country.iso_code : '',
                        data:
                            point.transport_point_data &&
                            point.transport_point_data
                                .map(dataItem => {
                                    let value = dataItem.value !== undefined ? dataItem.value : ''
                                    if (
                                        this.pointTypes[point.type_id].dataFields[dataItem.type_id] &&
                                        this.pointTypes[point.type_id].dataFields[dataItem.type_id].type === 'bool'
                                    ) {
                                        value = parseInt(dataItem.value)
                                    }
                                    return {
                                        ...dataItem.toJS(),
                                        ...this.pointTypes[point.type_id].dataFields[dataItem.type_id],
                                        value,
                                    }
                                })
                                .toJS(),
                    }
                })
                .toJS()

        this.setTransportStart(transport.transport_point_start)
        this.setPoints(points, () => {
            this.loadTransportStart(transport.vehicle_id)
        })
    }

    setTransportStart = point => {
        let start = null
        if (point) {
            let country = this.props.createGetCountryByIDSelector(point.country_id)
            if (!country && point.country) {
                country = point.country
            }
            let trailer = this.props.createGetTrailerByIDSelector(point.trailer_id)
            if (!trailer && point.trailer) {
                trailer = point.trailer
            }
            start = {
                ...point.toJS(),
                plannedFrom: point.datetime_planned_from && moment(point.datetime_planned_from),
                plannedTo: point.datetime_planned_to && moment(point.datetime_planned_to),
                specifiedFrom: point.datetime_specified_from && moment(point.datetime_specified_from),
                specifiedTo: point.datetime_specified_to && moment(point.datetime_specified_to),
                bookingFrom: point.datetime_booking_from && moment(point.datetime_booking_from),
                bookingTo: point.datetime_booking_to && moment(point.datetime_booking_to),
                arrivedAt: point.datetime_arrival && moment(point.datetime_arrival),
                finishedAt: point.datetime_finished && moment(point.datetime_finished),
                country_code: country ? country.iso_code : '',
                data: [],
                trailer,
            }
        }

        this.setState({
            transportStart: start,
        })
    }

    // transport files
    handleAllRequiredFilesUploadedChanged = value => {
        this.setState({
            allRequiredFilesUploaded: value,
        })
    }

    // send to/remove from vehicle
    handleSendToVehicle = () => {
        this.showConfirmDialog({
            title: this.props.intl.formatMessage({ id: 'confirmDialog.transportSendToVehicle.title' }),
            message: this.props.intl.formatMessage({ id: 'confirmDialog.transportSendToVehicle.message' }),
            labelCancel: this.props.intl.formatMessage({ id: 'confirmDialog.transportSendToVehicle.cancel' }),
            labelAccept: this.props.intl.formatMessage({ id: 'confirmDialog.transportSendToVehicle.accept' }),
            classNameAccept: 'btn-success',
            onAccept: () => {
                this.props.actions.sendTransportToVehicle(this.props.match.params.transportID)
                this.hideConfirmDialog()
            },
        })
    }

    handleDeleteFromVehicle = () => {
        this.showConfirmDialog({
            title: this.props.intl.formatMessage({ id: 'confirmDialog.transportDeleteFromVehicle.title' }),
            message: this.props.intl.formatMessage({ id: 'confirmDialog.transportDeleteFromVehicle.message' }),
            labelCancel: this.props.intl.formatMessage({ id: 'confirmDialog.transportDeleteFromVehicle.cancel' }),
            labelAccept: this.props.intl.formatMessage({ id: 'confirmDialog.transportDeleteFromVehicle.accept' }),
            classNameAccept: 'btn-danger',
            onAccept: () => {
                this.props.actions.deleteTransportFromVehicle(this.props.match.params.transportID)
                this.hideConfirmDialog()
            },
        })
    }

    transportCanBeDeletedFromVehicle = () => {
        const transport = this.props.transport

        return (
            transport &&
            transport.fb_sent_at &&
            (transport.transport_state_id === constants.TRANSPORT_STATE_ORDER ||
                transport.transport_state_id === constants.TRANSPORT_STATE_PLANNED ||
                transport.transport_state_id === constants.TRANSPORT_STATE_SENT ||
                transport.transport_state_id === constants.TRANSPORT_STATE_IN_PROCESS)
        )
    }

    // prices
    closePriceModal = e => {
        e && e.preventDefault()

        this.setState({
            modalPriceParts: {
                show: false,
            },
        })
    }

    openPriceModal = (e, customersKey) => {
        e && e.preventDefault()

        this.setState({
            modalPriceParts: {
                show: true,
                customersKey,
            },
        })
    }

    setPricePart = (id, value) => {
        const customersKey = this.state.modalPriceParts.customersKey
        const customers = this.state.customers

        if (customers[customersKey]) {
            customers[customersKey].priceParts[id] = value
        }

        this.setState({
            customers,
        })
    }

    getTotalPrice = priceParts => {
        let price = 0
        Object.keys(priceParts).forEach(key => {
            price += getFloatVal(priceParts[key])
        })

        return price
    }

    // events
    handleEventSubmit = values => {
        this.props.actions.saveTransportEvent(values)
    }

    handleDeleteEvent = values => {
        this.props.actions.deleteTransportEvent(values)
    }

    handleChangePointState = (point, stateId) => {
        this.transport &&
            this.props.actions.changeTransportPointState({
                transport_id: this.transport.id,
                transport_point_id: point.id,
                transport_point_state_id: stateId,
            })
    }

    // log
    closeLogModal = () => {
        this.setState({ showLogModal: false })
    }

    openLogModal = () => {
        this.setState({ showLogModal: true })
    }

    handleShowLog = () => {
        this.transport && this.props.actions.fetchTransportLog(this.transport.id)
        this.openLogModal()
    }

    transportLogEventParamFormatter = (typeId, value) => {
        if (typeId === 'type_id' && constants.TRANSPORT_POINT_TYPES_DEFINITION[value]) {
            return constants.TRANSPORT_POINT_TYPES_DEFINITION[value].name
        } else if (typeId === 'event_type_id' && constants.TRANSPORT_EVENT_TYPES_DEFINITION[value]) {
            return constants.TRANSPORT_EVENT_TYPES_DEFINITION[value].name
        }

        return value
    }

    // find address
    handleChangeAddress = values => {
        this.setState({
            customerAddressToFind: {
                ...this.state.customerAddressToFind,
                values,
            },
        })
    }

    handleFindAddress = values => {
        this.setState(
            {
                customerAddressToFind: {
                    ...this.state.customerAddressToFind,
                    values,
                },
            },
            () => {
                this.findCustomerAddresses(values)
            }
        )
    }

    findCustomerAddresses = values => {
        this.props.actions.findCustomerAddresses(values)
    }

    showCustomerAddressesNotFoundDialog = () => {
        this.showConfirmDialog({
            title: this.props.intl.formatMessage({ id: 'confirmDialog.addressNotFoundCreateNew.title' }),
            message: this.props.intl.formatMessage({ id: 'confirmDialog.addressNotFoundCreateNew.message' }),
            labelCancel: this.props.intl.formatMessage({ id: 'confirmDialog.addressNotFoundCreateNew.cancel' }),
            labelAccept: this.props.intl.formatMessage({ id: 'confirmDialog.addressNotFoundCreateNew.accept' }),
            classNameAccept: 'btn-success',
            onAccept: () => {
                this.handleSaveAddress()
                this.hideConfirmDialog()
            },
        })
    }

    modalCustomerAddressesFoundOpen = () => {
        this.setState({
            modalCustomerAddressesFound: {
                ...this.state.modalCustomerAddressesFound,
                show: true,
            },
        })
    }

    modalCustomerAddressesFoundClose = () => {
        this.setState({
            modalCustomerAddressesFound: {
                ...this.state.modalCustomerAddressesFound,
                show: false,
            },
        })
    }

    modalCustomerAddressesFoundSaveAsNewAddress = () => {
        this.handleSaveAddress()
        this.modalCustomerAddressesFoundClose()
    }

    modalCustomerAddressesFoundSelectAddress = address => {
        this.setState({
            modalCustomerAddressesFound: {
                ...this.state.modalCustomerAddressesFound,
                show: false,
                selectedAddress: address,
            },
        })
    }

    modalCustomerAddressesFoundClearSelectedAddress = () => {
        this.setState({
            modalCustomerAddressesFound: {
                ...this.state.modalCustomerAddressesFound,
                selectedAddress: null,
            },
        })
    }

    // save address
    closeSaveAddressModal = () => {
        this.setState({
            modalSaveAddress: {
                show: false,
                values: {},
            },
        })
    }

    handleSaveAddress = values => {
        if (!values) {
            values = this.state.customerAddressToFind.values
        }

        this.state.customers.forEach(transportCustomer => {
            if (!values.customer_id && transportCustomer.customer_id) {
                values.customer_id = transportCustomer.customer_id
            }
        })

        values.is_booking_required_address = values.is_booking_required

        this.setState({
            modalSaveAddress: {
                show: true,
                values,
            },
        })
    }

    handleSaveAddressChange = values => {
        values.is_booking_required = values.is_booking_required_address

        this.setState({
            modalSaveAddress: {
                ...this.state.modalSaveAddress,
                values,
            },
        })
    }

    handleSaveAddressSubmit = () => {
        this.handleSaveAddressSave(false)
    }

    handleSaveAddressSave = ignoreDuplicity => {
        this.props.actions.saveCustomerAddress && this.props.actions.saveCustomerAddress(this.state.modalSaveAddress.values, ignoreDuplicity)
    }

    handleSaveAddressDuplicity = duplicity => {
        this.setState({
            modalSaveAddressDuplicity: {
                show: true,
                duplicity,
            },
        })
    }

    handleSaveAddressDuplicityCancel = () => {
        this.setState({
            modalSaveAddressDuplicity: {
                show: false,
                duplicity: null,
            },
        })
    }

    handleSaveAddressDuplicityConfirm = () => {
        this.handleSaveAddressDuplicityCancel()
        this.handleSaveAddressSave(true)
    }

    // routes recounting
    handleRecountPlannedRoute = () => {
        this.props.actions.recountPlannedRoute && this.props.actions.recountPlannedRoute(this.transport.id)
    }

    handleRecountSpecifiedRoute = () => {
        this.props.actions.recountSpecifiedRoute && this.props.actions.recountSpecifiedRoute(this.transport.id)
    }

    handleRecountRealRoute = () => {
        this.props.actions.recountRealRoute && this.props.actions.recountRealRoute(this.transport.id)
    }

    // vehicle real positions
    handleLoadRealVehiclePositions = () => {
        this.props.actions.fetchRealVehiclePositions && this.props.actions.fetchRealVehiclePositions(this.transport.id)
    }

    // event times correction
    handleCorrectEventTimes = () => {
        this.props.actions.correctEventTimes && this.props.actions.correctEventTimes(this.transport.id)
    }

    // event times correction
    handleCorrectPointEventTimes = point => {
        this.props.actions.correctPointEventTimes && this.props.actions.correctPointEventTimes(this.transport.id, point.id)
    }

    // create events
    handleCreateEvents = values => {
        this.props.actions.createTransportEvents &&
            this.props.actions.createTransportEvents({
                ...values,
                transport_id: this.transport.id,
            })
    }

    // customers
    handleAddCustomer = e => {
        e && e.preventDefault()

        const customers = this.state.customers
        customers.push(this.emptyCustomer)

        const values = this.state.values
        values[`customer_currency_id-${customers.length - 1}`] = this.emptyCustomer.currency_id

        this.setState({
            customers,
            values,
        })
    }

    handleRemoveCustomer = (e, customersKey) => {
        e && e.preventDefault()

        this.showConfirmDialog({
            title: this.props.intl.formatMessage({ id: 'confirmDialog.removeCustomer.title' }),
            message: this.props.intl.formatMessage({ id: 'confirmDialog.removeCustomer.message' }),
            labelCancel: this.props.intl.formatMessage({ id: 'confirmDialog.removeCustomer.cancel' }),
            labelAccept: this.props.intl.formatMessage({ id: 'confirmDialog.removeCustomer.accept' }),
            classNameAccept: 'btn-danger',
            onAccept: () => {
                this.removeCustomer(customersKey)
                this.hideConfirmDialog()
            },
        })
    }

    removeCustomer = customersKey => {
        const customers = this.state.customers
        customers.splice(customersKey, 1)

        const values = this.state.values

        const lastCustomer = customers.length
        for (let i = customersKey; i < lastCustomer; i++) {
            const next_i = i + 1
            values[`customer_id-${i}`] = values[`customer_id-${next_i}`]
            values[`customer_order_number-${i}`] = values[`customer_order_number-${next_i}`]
            values[`customer_currency_id-${i}`] = values[`customer_currency_id-${next_i}`]
            values[`customer_note-${i}`] = values[`customer_note-${next_i}`]
            values[`customer_invoicing_distance-${i}`] = values[`customer_invoicing_distance-${next_i}`]
            values[`customer_do_not_invoice-${i}`] = values[`customer_do_not_invoice-${next_i}`]
        }

        delete values[`customer_id-${lastCustomer}`]
        delete values[`customer_order_number-${lastCustomer}`]
        delete values[`customer_currency_id-${lastCustomer}`]
        delete values[`customer_note-${lastCustomer}`]
        delete values[`customer_invoicing_distance-${lastCustomer}`]
        delete values[`customer_do_not_invoice-${lastCustomer}`]

        this.setState({
            customers,
            values,
        })
    }

    // order for supplier/customer
    handleDownloadOrderForSupplier = e => {
        e && e.preventDefault()

        this.modalOrderOpen(null)
    }

    handleDownloadOrderForCustomer = (e, transportCustomer = null, documentType = constants.TRANSPORT_ORDER_TYPE_ORDER) => {
        e && e.preventDefault()

        this.modalOrderOpen(null, transportCustomer, constants.TRANSPORT_ORDER_FOR_CUSTOMER, documentType)
    }

    modalOrderOpen = (
        transportPart,
        transportCustomer,
        forWhom = constants.TRANSPORT_ORDER_FOR_SUPPLIER,
        documentType = constants.TRANSPORT_ORDER_TYPE_ORDER
    ) => {
        this.setState({
            modalOrder: {
                ...this.state.modalOrder,
                show: true,
                transportPart,
                transportCustomer,
                forWhom,
                documentType,
            },
        })
    }

    modalOrderClose = () => {
        this.setState({
            modalOrder: {
                ...this.state.modalOrder,
                show: false,
                transportPart: null,
                transportCustomer: null,
                documentType: null
            },
        })
    }

    modalOrderSubmit = values => {
        this.props.actions.createTransportOrder(this.transport.id, values)
    }

    modalOrderSendToEmail = values => {
        this.props.actions.sendTransportOrderToEmail(this.transport.id, values)
    }

    // transport description
    modalTransportDescriptionOpen = customersKey => {
        let text = this.state.values.carrier_transport_description
        let readonly = isDisableField(Boolean(this.transport), Object.values(PERMS_SPEDITION))
        if (customersKey !== null) {
            const customer = this.state.customers[customersKey]
            text = customer.transport_description
            readonly = isDisableField(Boolean(this.transport), Object.values(PERMS_CUSTOMER))
        }

        this.setState({
            modalTransportDescription: {
                ...this.state.modalTransportDescription,
                show: true,
                text,
                customersKey,
                readonly,
            },
        })
    }

    modalTransportDescriptionClose = () => {
        this.setState({
            modalTransportDescription: {
                ...this.state.modalTransportDescription,
                show: false,
                text: '',
                customersKey: null,
            },
        })
    }

    modalTransportDescriptionSetText = text => {
        this.setState({
            modalTransportDescription: {
                ...this.state.modalTransportDescription,
                text,
            },
        })
    }

    modalTransportDescriptionSubmit = () => {
        const customersKey = this.state.modalTransportDescription.customersKey
        const text = this.state.modalTransportDescription.text

        if (customersKey !== null) {
            const newCustomer = {
                ...this.state.customers[customersKey],
                transport_description: text,
            }

            this.setState(
                {
                    customers: this.state.customers.map((customer, index) => (index === customersKey ? newCustomer : customer)),
                },
                () => {
                    this.modalTransportDescriptionClose()
                }
            )
        } else {
            this.setState(
                {
                    values: {
                        ...this.state.values,
                        carrier_transport_description: text,
                    },
                },
                () => {
                    this.modalTransportDescriptionClose()
                }
            )
        }
    }

    // invoicing note
    modalInvoicingNoteOpen = customersKey => {
        let text = this.state.values.carrier_invoicing_note
        if (customersKey !== null) {
            const customer = this.state.customers[customersKey]
            text = customer.invoicing_note
        }

        this.setState({
            modalInvoicingNote: {
                ...this.state.modalInvoicingNote,
                show: true,
                text,
                customersKey,
            },
        })
    }

    modalInvoicingNoteClose = () => {
        this.setState({
            modalInvoicingNote: {
                ...this.state.modalInvoicingNote,
                show: false,
                text: '',
                customersKey: null,
            },
        })
    }

    modalInvoicingNoteSetText = text => {
        this.setState({
            modalInvoicingNote: {
                ...this.state.modalInvoicingNote,
                text,
            },
        })
    }

    modalInvoicingNoteSubmit = () => {
        const customersKey = this.state.modalInvoicingNote.customersKey
        const text = this.state.modalInvoicingNote.text

        if (customersKey !== null) {
            const newCustomer = {
                ...this.state.customers[customersKey],
                invoicing_note: text,
            }

            this.setState(
                {
                    customers: this.state.customers.map((customer, index) => (index === customersKey ? newCustomer : customer)),
                },
                () => {
                    this.modalInvoicingNoteClose()
                }
            )
        } else {
            this.setState(
                {
                    values: {
                        ...this.state.values,
                        carrier_invoicing_note: text,
                    },
                },
                () => {
                    this.modalInvoicingNoteClose()
                }
            )
        }
    }

    // Tabs

    handleSelectTab = tabKey => {
        if (tabKey === 2) {
            this.loadTransportGoods()
        } else if (tabKey === 3) {
            this.loadTransportParts()
        } else if (tabKey === 4) {
            this.loadTransportFiles()
        } else if (tabKey === 5) {
            this.loadAllowances()
        } else if (tabKey === 6) {
            this.loadTransportScoring()
        }
    }

    loadTransportGoods = () => {
        this.setState({
            transportGoods: {
                show: true,
            },
        })
    }

    loadTransportParts = () => {
        this.setState({
            transportParts: {
                show: true,
            },
        })
    }

    loadTransportFiles = () => {
        this.setState({
            transportFiles: {
                show: true,
            },
        })
    }

    loadAllowances = () => {
        this.setState({
            allowances: {
                show: true,
            },
        })
    }

    loadTransportScoring = () => {
        this.setState({
            transportScoring: {
                show: true,
            },
        })
    }

    loadCustomerInfo = (index, customerId) => {
        this.props.actions.loadCustomerInfo(index, customerId)
    }

    loadVehicleDataSource = vehicleId => {
        this.props.actions.fetchVehicleDataSource && this.props.actions.fetchVehicleDataSource(vehicleId)
    }

    clearVehicleDataSource = () => {
        this.props.actions.clearVehicleDataSource && this.props.actions.clearVehicleDataSource()
    }

    loadVehicleHomeCountry = vehicleId => {
        this.props.actions.fetchVehicleHomeCountry && this.props.actions.fetchVehicleHomeCountry(vehicleId)
    }

    componentDidMount() {
        this.props.match.params.transportID && this.props.actions.fetchTransport(this.props.match.params.transportID)
        this.props.actions.fetchPrerequisites()

        if (!this.props.match.params.transportID) {
            this.setDisponentId(this.props.currentUserId)
        }
    }

    componentWillReceiveProps(nextProps) {

        if (nextProps.match.params.transportID && nextProps.match.params.transportID !== this.props.match.params.transportID) {
            this.props.actions.fetchTransport(nextProps.match.params.transportID)
        }

        if (this.props.customerInfoFetching && !nextProps.customerInfoFetching) {
            this.setCustomerInfo(nextProps.customerInfo)
        }

        if ((!this.props.vehicleHomeCountry && nextProps.vehicleHomeCountry) || (this.props.vehicleHomeCountry && nextProps.vehicleHomeCountry && this.props.vehicleHomeCountry.iso_code != nextProps.vehicleHomeCountry.iso_code)) {
            if(this.state.values.is_cabotage_auto){
                var isCabotage = this.isCabotage(nextProps.vehicleHomeCountry, this.state.points, this.state.values.is_spedition)
                this.setState({
                    values: {
                        ...this.state.values,
                        is_cabotage: isCabotage
                    },
                });
            }
        }

        // Save
        if (this.props.status.get('saving') && !nextProps.status.get('saving')) {
            if (nextProps.status.get('error') === null) {
                this.props.notify(
                    {
                        title: this.props.intl.formatMessage({ id: 'alerts.titles.success' }),
                        message: this.props.intl.formatMessage({ id: 'alerts.messages.saveSuccess' }),
                        position: 'tc',
                    },
                    'success'
                )

                if(nextProps.status.get('isTransportCustomerBlocked')) {
                    this.props.notify(
                        {
                            title: this.props.intl.formatMessage({ id: 'alerts.titles.success' }),
                            message: this.props.intl.formatMessage({ id: 'alerts.messages.customerBlockedNeedApproval' }),
                            position: 'tc',
                        },
                        'warning'
                    )
                }

                this.successEditCallback && this.successEditCallback()
            } else {
                this.props.notify(
                    {
                        title: this.props.intl.formatMessage({ id: 'alerts.titles.error' }),
                        message: nextProps.status.get('error'),
                        position: 'tc',
                    },
                    'error'
                )
            }
        }

        // Delete
        if (this.props.deleting && !nextProps.deleting) {
            if (nextProps.deleteError === null) {
                this.props.notify(
                    {
                        title: this.props.intl.formatMessage({ id: 'alerts.titles.success' }),
                        message: this.props.intl.formatMessage({ id: 'alerts.messages.deleteSuccess' }),
                        position: 'tc',
                    },
                    'success'
                )
                this.props.redirect(url(this.props.match, 'transports'))
            } else {
                this.props.notify(
                    {
                        title: this.props.intl.formatMessage({ id: 'alerts.titles.error' }),
                        message: nextProps.deleteError,
                        position: 'tc',
                    },
                    'error'
                )
            }
        }

        // Restore
        if (this.props.restoring && !nextProps.restoring) {
            if (nextProps.restoreError === null) {
                this.props.notify(
                    {
                        title: this.props.intl.formatMessage({ id: 'alerts.titles.success' }),
                        message: this.props.intl.formatMessage({ id: 'alerts.messages.restoreSuccess' }),
                        position: 'tc',
                    },
                    'success'
                )
            } else {
                this.props.notify(
                    {
                        title: this.props.intl.formatMessage({ id: 'alerts.titles.error' }),
                        message: nextProps.restoreError,
                        position: 'tc',
                    },
                    'error'
                )
            }
        }

        // Approving
        if (this.props.approving && !nextProps.approving) {
            if (nextProps.approveError === null) {
                this.props.notify(
                    {
                        title: this.props.intl.formatMessage({ id: 'alerts.titles.success' }),
                        message: this.props.intl.formatMessage({ id: 'alerts.messages.approveSuccess' }),
                        position: 'tc',
                    },
                    'success'
                )
            } else {
                this.props.notify(
                    {
                        title: this.props.intl.formatMessage({ id: 'alerts.titles.error' }),
                        message: nextProps.approveError,
                        position: 'tc',
                    },
                    'error'
                )
            }
        }

        // Send to vehicle
        if (this.props.sendingToVehicle && !nextProps.sendingToVehicle) {
            if (nextProps.sendToVehicleError === null) {
                this.props.notify(
                    {
                        title: this.props.intl.formatMessage({ id: 'alerts.titles.success' }),
                        message: this.props.intl.formatMessage({ id: 'alerts.messages.transportSendToVehicleSuccess' }),
                        position: 'tc',
                    },
                    'success'
                )
            } else {
                this.props.notify(
                    {
                        title: this.props.intl.formatMessage({ id: 'alerts.titles.error' }),
                        message: nextProps.sendToVehicleError,
                        position: 'tc',
                    },
                    'error'
                )
            }
        }

        // Delete from vehicle
        if (this.props.deletionFromVehicle && !nextProps.deletionFromVehicle) {
            if (nextProps.deleteFromVehicleError === null) {
                this.props.notify(
                    {
                        title: this.props.intl.formatMessage({ id: 'alerts.titles.success' }),
                        message: this.props.intl.formatMessage({ id: 'alerts.messages.transportDeleteFromVehicleSuccess' }),
                        position: 'tc',
                    },
                    'success'
                )
            } else {
                this.props.notify(
                    {
                        title: this.props.intl.formatMessage({ id: 'alerts.titles.error' }),
                        message: nextProps.deleteFromVehicleError,
                        position: 'tc',
                    },
                    'error'
                )
            }
        }

        // Save event
        if (this.props.eventStatus.get('saving') && !nextProps.eventStatus.get('saving')) {
            if (nextProps.eventStatus.get('error') === null) {
                this.props.notify(
                    {
                        title: this.props.intl.formatMessage({ id: 'alerts.titles.success' }),
                        message: this.props.intl.formatMessage({ id: 'alerts.messages.saveSuccess' }),
                        position: 'tc',
                    },
                    'success'
                )
                this.successEditCallback && this.successEditCallback()
            } else {
                this.props.notify(
                    {
                        title: this.props.intl.formatMessage({ id: 'alerts.titles.error' }),
                        message: nextProps.eventStatus.get('error'),
                        position: 'tc',
                    },
                    'error'
                )
            }
        }

        // Change point state
        if (this.props.transportPointStateChanging && !nextProps.transportPointStateChanging) {
            if (this.props.transportPointStateChangeError === null) {
                this.props.notify(
                    {
                        title: this.props.intl.formatMessage({ id: 'alerts.titles.success' }),
                        message: this.props.intl.formatMessage({ id: 'alerts.messages.saveSuccess' }),
                        position: 'tc',
                    },
                    'success'
                )
                this.successEditCallback && this.successEditCallback()
            } else {
                this.props.notify(
                    {
                        title: this.props.intl.formatMessage({ id: 'alerts.titles.error' }),
                        message: this.props.transportPointStateChangeError,
                        position: 'tc',
                    },
                    'error'
                )
            }
        }

        // Save customer address
        if (this.props.customerAddressStatus.get('saving') && !nextProps.customerAddressStatus.get('saving')) {
            if (nextProps.customerAddressStatus.get('error') === null && !nextProps.customerAddressDuplicity) {
                this.props.notify(
                    {
                        title: this.props.intl.formatMessage({ id: 'alerts.titles.success' }),
                        message: this.props.intl.formatMessage({ id: 'alerts.messages.addressSaveSuccess' }),
                        position: 'tc',
                    },
                    'success'
                )
                this.closeSaveAddressModal()
            } else if (nextProps.customerAddressStatus.get('error') === null && nextProps.customerAddressDuplicity) {
                this.handleSaveAddressDuplicity(nextProps.customerAddressDuplicity)
            } else {
                this.props.notify(
                    {
                        title: this.props.intl.formatMessage({ id: 'alerts.titles.error' }),
                        message: nextProps.customerAddressStatus.get('error'),
                        position: 'tc',
                    },
                    'error'
                )
            }
        }

        // Find customer addresses
        if (this.props.foundCustomerAddressesFetching && !nextProps.foundCustomerAddressesFetching && !nextProps.foundCustomerAddressesError) {
            if (nextProps.foundCustomerAddresses.length) {
                this.modalCustomerAddressesFoundOpen()
            } else {
                this.showCustomerAddressesNotFoundDialog()
            }
        }

        // Planned route calculating
        if (this.props.plannedRouteCalculating && !nextProps.plannedRouteCalculating) {
            if (nextProps.plannedRouteCalculatingError === null) {
                this.props.notify(
                    {
                        title: this.props.intl.formatMessage({ id: 'alerts.titles.success' }),
                        message: this.props.intl.formatMessage({ id: 'alerts.messages.routeCalculateSuccess' }),
                        position: 'tc',
                    },
                    'success'
                )
            } else {
                this.props.notify(
                    {
                        title: this.props.intl.formatMessage({ id: 'alerts.titles.error' }),
                        message: nextProps.plannedRouteCalculatingError,
                        position: 'tc',
                    },
                    'error'
                )
            }
        }

        // Specified route calculating
        if (this.props.specifiedRouteCalculating && !nextProps.specifiedRouteCalculating) {
            if (nextProps.specifiedRouteCalculatingError === null) {
                this.props.notify(
                    {
                        title: this.props.intl.formatMessage({ id: 'alerts.titles.success' }),
                        message: this.props.intl.formatMessage({ id: 'alerts.messages.routeCalculateSuccess' }),
                        position: 'tc',
                    },
                    'success'
                )
            } else {
                this.props.notify(
                    {
                        title: this.props.intl.formatMessage({ id: 'alerts.titles.error' }),
                        message: nextProps.specifiedRouteCalculatingError,
                        position: 'tc',
                    },
                    'error'
                )
            }
        }

        // Real route calculating
        if (this.props.realRouteCalculating && !nextProps.realRouteCalculating) {
            if (nextProps.realRouteCalculatingError === null) {
                this.props.notify(
                    {
                        title: this.props.intl.formatMessage({ id: 'alerts.titles.success' }),
                        message: this.props.intl.formatMessage({ id: 'alerts.messages.routeCalculateSuccess' }),
                        position: 'tc',
                    },
                    'success'
                )
            } else {
                this.props.notify(
                    {
                        title: this.props.intl.formatMessage({ id: 'alerts.titles.error' }),
                        message: nextProps.realRouteCalculatingError,
                        position: 'tc',
                    },
                    'error'
                )
            }
        }

        // Order creating
        if (this.props.transportOrderCreating && !nextProps.transportOrderCreating) {
            if (!nextProps.transportOrderCreatingError === null) {
                this.props.notify(
                    {
                        title: this.props.intl.formatMessage({ id: 'alerts.titles.error' }),
                        message: nextProps.realRouteCalculatingError,
                        position: 'tc',
                    },
                    'error'
                )
            } else {
                // this.modalOrderClose()
                // eslint-disable-next-line no-lonely-if
                if (nextProps.transportOrderData && nextProps.transportOrderData.file_name) {
                    window.location.href = `${config.CFG_API_BASE_URL}/files/transport-orders/${nextProps.transportOrderData.file_name}`
                }
            }
        }

        // Order sending
        if (this.props.transportOrderSending && !nextProps.transportOrderSending) {
            if (nextProps.transportOrderSendingError === null) {
                // this.modalOrderClose()
                this.props.notify(
                    {
                        title: this.props.intl.formatMessage({ id: 'alerts.titles.success' }),
                        message: this.props.intl.formatMessage({ id: 'alerts.messages.transportOrderSendingSuccess' }),
                        position: 'tc',
                    },
                    'success'
                )
            } else {
                this.props.notify(
                    {
                        title: this.props.intl.formatMessage({ id: 'alerts.titles.error' }),
                        message: nextProps.transportOrderSendingError,
                        position: 'tc',
                    },
                    'error'
                )
            }
        }

        // Event times correction
        if (this.props.eventTimesCorrection && !nextProps.eventTimesCorrection) {
            if (nextProps.eventTimesCorrectionError === null) {
                this.props.notify(
                    {
                        title: this.props.intl.formatMessage({ id: 'alerts.titles.success' }),
                        message: this.props.intl.formatMessage({ id: 'alerts.messages.eventTimesCorrectionSuccess' }),
                        position: 'tc',
                    },
                    'success'
                )
            } else {
                this.props.notify(
                    {
                        title: this.props.intl.formatMessage({ id: 'alerts.titles.error' }),
                        message: nextProps.eventTimesCorrectionError,
                        position: 'tc',
                    },
                    'error'
                )
            }
        }

        // Point event times correction
        if (this.props.pointEventTimesCorrection && !nextProps.pointEventTimesCorrection) {
            if (nextProps.pointEventTimesCorrectionError === null) {
                this.props.notify(
                    {
                        title: this.props.intl.formatMessage({ id: 'alerts.titles.success' }),
                        message: this.props.intl.formatMessage({ id: 'alerts.messages.eventTimesCorrectionSuccess' }),
                        position: 'tc',
                    },
                    'success'
                )
            } else {
                this.props.notify(
                    {
                        title: this.props.intl.formatMessage({ id: 'alerts.titles.error' }),
                        message: nextProps.pointEventTimesCorrectionError,
                        position: 'tc',
                    },
                    'error'
                )
            }
        }

        // Events creating
        if (this.props.transportEventsCreating && !nextProps.transportEventsCreating) {
            if (nextProps.transportEventsCreatingError === null) {
                this.props.notify(
                    {
                        title: this.props.intl.formatMessage({ id: 'alerts.titles.success' }),
                        message: this.props.intl.formatMessage({ id: 'alerts.messages.transportEventsCreatingSuccess' }),
                        position: 'tc',
                    },
                    'success'
                )
            } else {
                this.props.notify(
                    {
                        title: this.props.intl.formatMessage({ id: 'alerts.titles.error' }),
                        message: nextProps.transportEventsCreatingError,
                        position: 'tc',
                    },
                    'error'
                )
            }
        }

        const transportIsEqual = deepEqual(nextProps.transport, this.transport)
        if (transportIsEqual === false && nextProps.transport) {
            this.transport = nextProps.transport
            this.setValuesFromTransport(this.transport)
        }

        if (
            nextProps.prerequisites.values.get('vehicles').size &&
            !this.pointTypes[constants.TRANSPORT_POINT_TYPE_TRAILER_CHANGE].dataFields[constants.TRANSPORT_POINT_DATA_TYPE_VEHICLE].values.length
        ) {
            const vehiclePairs = getPrerequisitesPairs(nextProps.prerequisites.values.get('vehicles'), 'id', 'name')

            this.pointTypes[constants.TRANSPORT_POINT_TYPE_TRAILER_CHANGE].dataFields[
                constants.TRANSPORT_POINT_DATA_TYPE_VEHICLE
            ].values = getPrerequisitesArray(nextProps.prerequisites.values.get('vehicles'), 'id', 'name')

            this.pointTypes[constants.TRANSPORT_POINT_TYPE_TRAILER_CHANGE].dataFields[
                constants.TRANSPORT_POINT_DATA_TYPE_VEHICLE
            ].formatter = value => vehiclePairs[value] || ''
        }

        if (
            nextProps.prerequisites.values.get('trailers').size &&
            !this.pointTypes[constants.TRANSPORT_POINT_TYPE_TRAILER_CATCH].dataFields[constants.TRANSPORT_POINT_DATA_TYPE_REGISTRATION].values.length
        ) {
            const trailersOptions = getPrerequisitesArray(nextProps.prerequisites.values.get('trailers'), 'name', 'name')

            this.pointTypes[constants.TRANSPORT_POINT_TYPE_TRAILER_CATCH].dataFields[
                constants.TRANSPORT_POINT_DATA_TYPE_REGISTRATION
            ].values = trailersOptions
            this.pointTypes[constants.TRANSPORT_POINT_TYPE_TRAILER_OUT].dataFields[
                constants.TRANSPORT_POINT_DATA_TYPE_REGISTRATION
            ].values = trailersOptions
        }

        if (
            nextProps.prerequisites.values.get('drivers').size &&
            !this.pointTypes[constants.TRANSPORT_POINT_TYPE_PARKING_AND_CHANGE].dataFields[constants.TRANSPORT_POINT_DATA_TYPE_DRIVER].values.length
        ) {
            const driverPairs = getPrerequisitesPairs(nextProps.prerequisites.values.get('drivers'), 'id', 'name')

            this.pointTypes[constants.TRANSPORT_POINT_TYPE_PARKING_AND_CHANGE].dataFields[
                constants.TRANSPORT_POINT_DATA_TYPE_DRIVER
            ].values = getPrerequisitesArray(nextProps.prerequisites.values.get('drivers'), 'id', 'name')

            this.pointTypes[constants.TRANSPORT_POINT_TYPE_PARKING_AND_CHANGE].dataFields[
                constants.TRANSPORT_POINT_DATA_TYPE_DRIVER
            ].formatter = value => driverPairs[value] || ''
        }

        if (
            nextProps.transport &&
            nextProps.transport.transport_points &&
            nextProps.prerequisites.values.get('vehicles').size &&
            (!this.props.transport ||
                !this.state.points.length ||
                JSON.stringify(nextProps.transport.transport_points) !== JSON.stringify(this.props.transport.transport_points))
        ) {
            this.setPointsFromTransport(nextProps.transport)
        }

        if (
            !this.state.transportStart ||
            (!nextProps.transportStart && this.state.transportStart) ||
            nextProps.transportStart.id !== this.state.transportStart.id
        ) {
            this.setTransportStart(nextProps.transportStart)
        }

        if (!this.emptyCustomer.currency_id && nextProps.prerequisites.values.get('currencies').size) {
            const currency = nextProps.prerequisites.values
                .get('currencies')
                .valueSeq()
                .first()
            this.emptyCustomer.currency_id = currency.get('id')
        }

        const newVehicle = nextProps.vehicleDataSource ? nextProps.vehicleDataSource.id : null
        const originVehicle = this.props.vehicleDataSource ? this.props.vehicleDataSource.id : null
        const vehicleHasChanged = newVehicle !== originVehicle

        if (!nextProps.transport) {
            if (vehicleHasChanged) {
                this.setState({
                    values: {
                        ...this.state.values,
                        company_cost_center_id: nextProps.vehicleDataSource ? nextProps.vehicleDataSource.company_cost_center_id : null
                    },
                });
            }
        }
        else {
            if (
                nextProps.vehicleDataSource &&
                nextProps.vehicleDataSource.company_cost_center_id &&
                nextProps.vehicleDataSource.company_cost_center_id !== this.state.values.company_cost_center_id &&
                (!this.props.vehicleDataSource || this.props.vehicleDataSource.company_cost_center_id !== nextProps.vehicleDataSource.company_cost_center_id)
            ) {
                this.setState({
                    values: {
                        ...this.state.values,
                        company_cost_center_id: nextProps.vehicleDataSource.company_cost_center_id
                    },
                });
            }
        }
    }

    componentWillUnmount() {
        this.props.actions.clearTransportStart()
        this.clearVehicleDataSource()
    }

    render() {
        document.title = formatters.titleFormatter(
            this.transport
                ? `${this.props.intl.formatMessage({ id: 'modules.transports.headingEdit' })} ${this.transport.id}`
                : this.props.intl.formatMessage({ id: 'modules.transports.headingNew' })
        )

        const transportStart = this.state.transportStart
        const points = this.state.points
        const customers = this.state.customers

        const pricePartsToEdit = customers[this.state.modalPriceParts.customersKey] && customers[this.state.modalPriceParts.customersKey].priceParts

        const customerAddressDuplicity = this.props.customerAddressDuplicity

        const emptyMandatoryFields = this.state.emptyMandatoryFields
        const fixedMandatoryFields = this.state.fixedMandatoryFields

        const customersCheckingData = this.props.customersCheckingData
        const carriersCheckingData = this.props.carriersCheckingData

        const suggestsVehiclesRegistration = this.props.suggestsForCarrier.vehicles_registration
            ? this.props.suggestsForCarrier.vehicles_registration
            : []
        const suggestsTrailersRegistration = this.props.suggestsForCarrier.trailers_registration
            ? this.props.suggestsForCarrier.trailers_registration
            : []
        const suggestsDriversName = this.props.suggestsForCarrier.drivers_name ? this.props.suggestsForCarrier.drivers_name : []
        const suggestsDriversPhone = this.props.suggestsForCarrier.drivers_phone ? this.props.suggestsForCarrier.drivers_phone : []

        const foundCustomerAddresses = this.props.foundCustomerAddresses

        const isDisableFieldCustomer = !hasAnyPermission([PERMS_CUSTOMER.UPDATE])
        const isDisableFieldCustomerCost = !hasAnyPermission([PERMS_CUSTOMER_COST.UPDATE])
        const isDisableFieldSpedition = !hasAnyPermission([PERMS_SPEDITION.UPDATE])
        const isDisableFieldSpeditionCost = !hasAnyPermission([PERMS_SPEDITION_COST.UPDATE])
        const isDisableFieldTransportState =  this.props.transport && this.props.transport.is_transport_customer_blocked && String(this.props.transport.transport_state_id) === '8' && !hasAnyPermission([PERMS.APPROVAL])

        return (
            <PermissionsCheck hasAny={Object.values(PERMS)} noPermissionsPage>
                <div className="page-inner">
                    <div id="main-wrapper">
                        <div className="row">
                            <div className="col-md-4" style={{ paddingRight: 0 }}>
                                <div className="panel panel-white">
                                    <div className="panel-body panel-padding">
                                        <div className="panel-head">
                                            {this.transport ? (
                                                <h2>
                                                    <FormattedMessage id="modules.transports.headingEdit" /> <b>{this.transport.id}</b>
                                                </h2>
                                            ) : (
                                                <h2>
                                                    <FormattedMessage id="modules.transports.headingNew" />
                                                </h2>
                                            )}

                                            {this.transport && (
                                                <span className="dropdown pull-right" style={{ marginTop: '-40px' }}>
                                                    <DropdownButton id={`dropdown-actions`} className={'far fa-bars'} noCaret title="" pullRight>
                                                        <PermissionsCheck hasAny={Object.values(PERMS_LOG)}>
                                                            <MenuItem eventKey={1} onSelect={this.handleShowLog}>
                                                                <i className="far fa-clock" /> <FormattedMessage id="buttons.log" />
                                                            </MenuItem>
                                                        </PermissionsCheck>
                                                        <PermissionsCheck has={[PERMS_TO_VEHICLE.DELETE]}>
                                                            {this.transportCanBeDeletedFromVehicle() && (
                                                                <MenuItem eventKey={2} onSelect={this.handleDeleteFromVehicle}>
                                                                    <i className="far fa-times-circle" />{' '}
                                                                    <FormattedMessage id="buttons.deleteFromVehicle" />
                                                                </MenuItem>
                                                            )}
                                                        </PermissionsCheck>
                                                        <PermissionsCheck has={[PERMS.DELETE]}>
                                                            {this.transportCanBeDeleted() && (
                                                                <MenuItem
                                                                    eventKey={3}
                                                                    onSelect={this.handleDelete}
                                                                    disabled={this.transportCanBeDeletedFromVehicle()}
                                                                >
                                                                    <i className="far fa-trash" /> <FormattedMessage id="buttons.delete" />
                                                                </MenuItem>
                                                            )}
                                                        </PermissionsCheck>
                                                        <PermissionsCheck has={[PERMS.RESTORE]}>
                                                            {this.transportCanBeRestored() && (
                                                                <MenuItem eventKey={4} onSelect={this.handleRestore}>
                                                                    <i className="far fa-sync-alt" /> <FormattedMessage id="buttons.restore" />
                                                                </MenuItem>
                                                            )}
                                                        </PermissionsCheck>
                                                        <PermissionsCheck has={[PERMS.COPY_TO_CLIPBOARD]}>
                                                            {this.transpportCanSendSms() && (
                                                                <MenuItem onSelect={this.handleSmsCopy}>
                                                                    <i className="far fa-clipboard" /> <FormattedMessage id="buttons.smsCopy" />
                                                                </MenuItem>
                                                            )}
                                                        </PermissionsCheck>
                                                    </DropdownButton>
                                                </span>
                                            )}
                                        </div>

                                        <LoadingOverlay
                                            active={
                                                this.props.prerequisites.status.get('fetching') ||
                                                this.props.status.get('fetching') ||
                                                this.props.status.get('saving') ||
                                                this.props.eventStatus.get('saving') ||
                                                this.props.sendingToVehicle ||
                                                this.props.deletionFromVehicle ||
                                                this.props.eventDeleting ||
                                                this.props.transportPointStateChanging ||
                                                this.props.restoring ||
                                                this.props.approving ||
                                                this.props.eventTimesCorrection ||
                                                this.props.pointEventTimesCorrection ||
                                                this.props.transportEventsCreating
                                            }
                                        >
                                            {this.transport && (
                                                <div className="wp-100 b-b m-b-lg display-inline">
                                                    <div className="m-b-sm">
                                                        <small className="text-gray">
                                                            <FormattedMessage id="fields.createdAt" />:{' '}
                                                            {formatters.datetimeFormatter(this.transport.created_at)}
                                                        </small>
                                                        <br />
                                                        {this.transport.fb_sent_at ? (
                                                            <small className="text-gray">
                                                                <FormattedMessage id="fields.sentAt" />:{' '}
                                                                <span className="text-success">
                                                                    {formatters.datetimeFormatter(this.transport.fb_sent_at)}{' '}
                                                                    <i className="fas fa-check" />
                                                                </span>
                                                            </small>
                                                        ) : (
                                                            <small className="text-gray">
                                                                <FormattedMessage id="fields.sentAt" />:{' '}
                                                                <span className="text-danger">
                                                                    <FormattedMessage id="modules.transports.notSentYet" />
                                                                </span>
                                                            </small>
                                                        )}
                                                    </div>
                                                </div>
                                            )}

                                            {this.transport && this.transport.deleted_at && (
                                                <div className="alert alert-danger m-b-lg">
                                                    <div className="row">
                                                        <div className="col-sm-8">
                                                            <p className="no-m">
                                                                <FormattedMessage id="modules.transports.warningDeletedTransport" />
                                                            </p>
                                                        </div>
                                                        <PermissionsCheck has={[PERMS.RESTORE]}>
                                                            <div className="col-sm-4 text-right">
                                                                <button onClick={this.handleRestore} type="button" className="btn btn-danger">
                                                                    <FormattedMessage id="buttons.restore" />
                                                                </button>
                                                            </div>
                                                        </PermissionsCheck>
                                                    </div>
                                                </div>
                                            )}

                                            {this.transport && this.transport.not_approved === 1 && (
                                                <div className="alert alert-danger m-b-lg">
                                                    <div className="row">
                                                        <div className="col-sm-8">
                                                            <p className="no-m">
                                                                <FormattedMessage id="modules.transports.warningNotApprovalTransport" />
                                                            </p>
                                                        </div>
                                                        <PermissionsCheck has={[PERMS.APPROVAL]}>
                                                            <div className="col-sm-4 text-right">
                                                                <button onClick={this.handleApproval} type="button" className="btn btn-danger">
                                                                    <FormattedMessage id="buttons.approval" />
                                                                </button>
                                                            </div>
                                                        </PermissionsCheck>
                                                    </div>
                                                </div>
                                            )}

                                            <PermissionsCheck hasAny={Object.values(PERMS)}>
                                                <Form
                                                    values={this.state.values}
                                                    validationRules={this.validationRules}
                                                    onChange={this.handleChangeValues}
                                                    onSubmit={this.handleSubmit}
                                                    className="pull-left wp-100"
                                                    isEdit={Boolean(this.transport)}
                                                >
                                                    <div>
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <SelectField
                                                                    id="transport_state_id"
                                                                    label={this.props.intl.formatMessage({ id: 'fields.state' })}
                                                                    values={getPrerequisitesArray(
                                                                        this.props.prerequisites.values.get('transport_states'),
                                                                        'id',
                                                                        'name',
                                                                        this.props.intl,
                                                                        'transportStates.'
                                                                    )}
                                                                    disableSorting
                                                                    prompt={false}
                                                                    readonly={() => isDisableFieldTransportState}
                                                                />
                                                            </div>
                                                            <div className="col-md-6">
                                                                <SelectField
                                                                    id="disponent_id"
                                                                    label={this.props.intl.formatMessage({ id: 'fields.disponent' })}
                                                                    values={getPrerequisitesArray(this.props.prerequisites.values.get('users'))}
                                                                    prompt={false}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <SelectField
                                                                    id="transport_type_id"
                                                                    label={this.props.intl.formatMessage({ id: 'fields.transportType' })}
                                                                    values={getPrerequisitesArray(
                                                                        this.props.prerequisites.values.get('transport_types')
                                                                    )}
                                                                    formGroupClassName={`${
                                                                        emptyMandatoryFields.indexOf('transport_type_id') !== -1 ? 'has-error' : ''
                                                                    } ${
                                                                        fixedMandatoryFields.indexOf('transport_type_id') !== -1
                                                                            ? 'has-error-fixed'
                                                                            : ''
                                                                    }`}
                                                                    prompt="-"
                                                                    nullable
                                                                />
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="row">
                                                                    <div className="col-md-6">
                                                                        <BooleanField
                                                                            id="is_cabotage"
                                                                            label={this.props.intl.formatMessage({ id: 'fields.cabotage' })}
                                                                            readonly={() => this.state.values.is_cabotage_auto}
                                                                        />
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <br />
                                                                        <CheckboxField
                                                                            id="is_cabotage_auto"
                                                                            onChange={e => this.handleChangeCabotageAuto(e.target.checked)}
                                                                            label={this.props.intl.formatMessage({ id: 'fields.autoCabotage' })}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <SelectField
                                                                    id="cargo_type_id"
                                                                    label={this.props.intl.formatMessage({ id: 'fields.cargoType' })}
                                                                    values={getPrerequisitesArray(this.props.prerequisites.values.get('cargo_types'))}
                                                                    formGroupClassName={`${
                                                                        emptyMandatoryFields.indexOf('cargo_type_id') !== -1 ? 'has-error' : ''
                                                                    } ${
                                                                        fixedMandatoryFields.indexOf('cargo_type_id') !== -1 ? 'has-error-fixed' : ''
                                                                    }`}
                                                                    prompt="-"
                                                                    nullable
                                                                />
                                                            </div>
                                                            <div className="col-md-6">
                                                                <SelectField
                                                                    id="goods_type_id"
                                                                    label={this.props.intl.formatMessage({ id: 'fields.goodsType' })}
                                                                    values={getPrerequisitesArray(this.props.prerequisites.values.get('goods_types'))}
                                                                    formGroupClassName={`${
                                                                        emptyMandatoryFields.indexOf('goods_type_id') !== -1 ? 'has-error' : ''
                                                                    } ${
                                                                        fixedMandatoryFields.indexOf('goods_type_id') !== -1 ? 'has-error-fixed' : ''
                                                                    }`}
                                                                    prompt="-"
                                                                    nullable
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                <SelectField
                                                                    id="transport_conditions"
                                                                    label={this.props.intl.formatMessage({ id: 'fields.transportConditions' })}
                                                                    values={getPrerequisitesArray(
                                                                        this.props.prerequisites.values.get('transport_conditions'),
                                                                        'id',
                                                                        'code'
                                                                    )}
                                                                    multi
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                <InputField
                                                                    id="note"
                                                                    label={this.props.intl.formatMessage({ id: 'fields.note' })}
                                                                    autosize
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <PermissionsCheck hasAny={Object.values(PERMS_CUSTOMER)}>
                                                        <div className="wp-100 m-t-lg display-inline transport-customers">
                                                            <h4 className="wp-100">
                                                                <FormattedMessage id="fields.customer" />
                                                            </h4>
                                                            {customers.map((customer, index) => {
                                                                const checkingData =
                                                                    customersCheckingData && customersCheckingData[customer.customer_id]
                                                                const invoicingAddressOptions = customer.invoicing_addresses
                                                                    ? customer.invoicing_addresses.map(address => ({
                                                                          id: address.id,
                                                                          name: address.address,
                                                                      }))
                                                                    : []
                                                                const deliveryAddressOptions = customer.delivery_addresses
                                                                    ? customer.delivery_addresses.map(address => ({
                                                                          id: address.id,
                                                                          name: address.address,
                                                                      }))
                                                                    : []

                                                                return (
                                                                    // eslint-disable-next-line react/no-array-index-key
                                                                    <div key={index} className="transport-customers-row">
                                                                        <div className="row">
                                                                            <div className="col-md-12">
                                                                                <CustomerPicker
                                                                                    id={`customer_id-${index}`}
                                                                                    onDelete={e => {this.handleRemoveCustomer(e, index)}}
                                                                                    label={this.props.intl.formatMessage({ id: 'fields.customer' })}
                                                                                    values={getPrerequisitesPairs(
                                                                                        this.props.prerequisites.values.get('customers')
                                                                                    )}
                                                                                    readonly={() => isDisableFieldCustomer}
                                                                                    formGroupClassName={`${
                                                                                        emptyMandatoryFields.indexOf(`customer_id-${index}`) !== -1
                                                                                            ? 'has-error'
                                                                                            : ''
                                                                                    } ${
                                                                                        fixedMandatoryFields.indexOf(`customer_id-${index}`) !== -1
                                                                                            ? 'has-error-fixed'
                                                                                            : ''
                                                                                    }`}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        {checkingData && checkingData.is_blocked === 1 && (
                                                                            <div className="alert alert-danger m-b-sm">
                                                                                <FormattedMessage id="modules.transports.warningBlockedCustomer" />
                                                                            </div>
                                                                        )}

                                                                        <SelectField
                                                                            id={`customer_invoicing_address_id-${index}`}
                                                                            label={this.props.intl.formatMessage({ id: 'fields.invoicingAddress' })}
                                                                            values={invoicingAddressOptions}
                                                                            readonly={() => isDisableFieldCustomer}
                                                                            prompt="-"
                                                                            nullable
                                                                        />

                                                                        <SelectField
                                                                            id={`customer_delivery_address_id-${index}`}
                                                                            label={this.props.intl.formatMessage({ id: 'fields.deliveryAddress' })}
                                                                            values={deliveryAddressOptions}
                                                                            readonly={() => isDisableFieldCustomer}
                                                                            prompt="-"
                                                                            nullable
                                                                        />

                                                                        <div className="row">
                                                                            <div className="col-md-3">
                                                                                <InputField
                                                                                    id={`customer_order_number-${index}`}
                                                                                    readonly={() => isDisableFieldCustomer}
                                                                                    label={this.props.intl.formatMessage({
                                                                                        id: 'fields.orderNumber',
                                                                                    })}
                                                                                    formGroupClassName={`${
                                                                                        emptyMandatoryFields.indexOf(
                                                                                            `customer_order_number-${index}`
                                                                                        ) !== -1
                                                                                            ? 'has-error'
                                                                                            : ''
                                                                                    } ${
                                                                                        fixedMandatoryFields.indexOf(
                                                                                            `customer_order_number-${index}`
                                                                                        ) !== -1
                                                                                            ? 'has-error-fixed'
                                                                                            : ''
                                                                                    }`}
                                                                                />
                                                                            </div>
                                                                            <div className="col-md-3">
                                                                                <InputField
                                                                                    id={`customer_invoicing_distance-${index}`}
                                                                                    readonly={() => isDisableFieldCustomer}
                                                                                    label={this.props.intl.formatMessage({
                                                                                        id: 'fields.invoicingDistance',
                                                                                    })}
                                                                                />
                                                                            </div>
                                                                            <PermissionsCheck hasAny={Object.values(PERMS_CUSTOMER_COST)}>
                                                                                <div className="col-md-6">
                                                                                    <div className="form-group">
                                                                                        <div className="pull-left wp-60">
                                                                                            <label htmlFor="nothing">
                                                                                                <FormattedMessage id="fields.price" />:
                                                                                            </label>
                                                                                            <span className="form-control text-right">
                                                                                                <a
                                                                                                    href="#"
                                                                                                    onClick={e => {
                                                                                                        this.openPriceModal(e, index)
                                                                                                    }}
                                                                                                    className="pull-left"
                                                                                                >
                                                                                                    {!isDisableFieldCustomerCost && (
                                                                                                        <i className="far fa-pencil m-r-xxs" />
                                                                                                    )}
                                                                                                    <FormattedMessage id={`buttons.${isDisableFieldCustomerCost ? 'details' : 'edit'}`} />
                                                                                                </a>
                                                                                                {formatters.floatFormatter(
                                                                                                    this.getTotalPrice(customer.priceParts)
                                                                                                )}
                                                                                            </span>
                                                                                        </div>
                                                                                        <div className="pull-left wp-40">
                                                                                            <SelectField
                                                                                                id={`customer_currency_id-${index}`}
                                                                                                label="&nbsp;"
                                                                                                values={getPrerequisitesArray(
                                                                                                    this.props.prerequisites.values.get('currencies'),
                                                                                                    'id',
                                                                                                    'iso_code'
                                                                                                )}
                                                                                                readonly={() => isDisableFieldCustomerCost}
                                                                                                prompt={false}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </PermissionsCheck>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col-md-12">
                                                                                <CheckboxField
                                                                                    id={`customer_do_not_invoice-${index}`}
                                                                                    readonly={() => isDisableFieldCustomer}
                                                                                    label={this.props.intl.formatMessage({ id: 'fields.doNotInvoice' })}
                                                                                    className="pull-left no-m"
                                                                                />
                                                                                <a
                                                                                    href="#"
                                                                                    className="no-text-decoration text-gray m-l-md"
                                                                                    onClick={() => {
                                                                                        this.modalInvoicingNoteOpen(index)
                                                                                    }}
                                                                                >
                                                                                    <i className="far fa-pencil" />{' '}
                                                                                    <FormattedMessage id="fields.invoicingNote" />
                                                                                </a>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col-md-12">
                                                                                <InputField
                                                                                    id={`customer_note-${index}`}
                                                                                    readonly={() => isDisableFieldCustomer}
                                                                                    label={this.props.intl.formatMessage({ id: 'fields.note' })}
                                                                                    autosize
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="m-t-xxs">
                                                                            <a
                                                                                href="#"
                                                                                className="no-text-decoration text-gray"
                                                                                onClick={() => {
                                                                                    this.modalTransportDescriptionOpen(index)
                                                                                }}
                                                                            >
                                                                                <i className="far fa-pencil" />{' '}
                                                                                <FormattedMessage id="fields.transportDescription" />
                                                                            </a>
                                                                            {this.transport && (
                                                                                <span>
                                                                                    <a
                                                                                        href="#"
                                                                                        className="no-text-decoration text-gray m-l-md"
                                                                                        onClick={e => {
                                                                                            this.handleDownloadOrderForCustomer(
                                                                                                e,
                                                                                                customer,
                                                                                                constants.TRANSPORT_ORDER_TYPE_OFFER
                                                                                            )
                                                                                        }}
                                                                                    >
                                                                                        <i className="far fa-file-pdf" />{' '}
                                                                                        <FormattedMessage id="fields.offer" />
                                                                                    </a>
                                                                                    {!isDisableFieldTransportState && (
                                                                                        <a
                                                                                            href="#"
                                                                                            className="no-text-decoration text-gray m-l-md"
                                                                                            onClick={e => {
                                                                                                this.handleDownloadOrderForCustomer(
                                                                                                    e,
                                                                                                    customer,
                                                                                                    constants.TRANSPORT_ORDER_TYPE_ORDER
                                                                                                )
                                                                                            }}
                                                                                        >
                                                                                            <i className="far fa-file-pdf" />{' '}
                                                                                            <FormattedMessage id="fields.order" />
                                                                                        </a>
                                                                                    )}
                                                                                    <a
                                                                                        href="#"
                                                                                        className="no-text-decoration text-gray m-l-md"
                                                                                        onClick={e => {
                                                                                            this.handleDownloadOrderForCustomer(
                                                                                                e,
                                                                                                customer,
                                                                                                constants.TRANSPORT_ORDER_TYPE_ORDER_CONFIRM
                                                                                            )
                                                                                        }}
                                                                                    >
                                                                                        <i className="far fa-file-pdf" />{' '}
                                                                                        <FormattedMessage id="fields.orderConfirm" />
                                                                                    </a>
                                                                                </span>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })}
                                                            <div className="m-t-xs">
                                                                <PermissionsCheck has={[PERMS_CUSTOMER.UPDATE]}>
                                                                    <a
                                                                        href="#"
                                                                        className="no-text-decoration text-gray"
                                                                        onClick={this.handleAddCustomer}
                                                                    >
                                                                        <i className="far fa-plus-circle" />{' '}
                                                                        <FormattedMessage id="buttons.addCustomer" />
                                                                    </a>
                                                                </PermissionsCheck>
                                                            </div>
                                                        </div>
                                                    </PermissionsCheck>
                                                    <div className="wp-100 m-t-lg display-inline">
                                                        <h4 className="wp-100">
                                                            {this.state.values.is_spedition ? (
                                                                <FormattedMessage id="fields.carrier" />
                                                            ) : (
                                                                <FormattedMessage id="fields.vehicle" />
                                                            )}
                                                            <div
                                                                className="pull-right"
                                                                style={{
                                                                    marginTop: '-10px',
                                                                }}
                                                            >
                                                                <CheckboxField
                                                                    id="is_spedition"
                                                                    readonly={() => isDisableFieldSpedition}
                                                                    onChange={e => this.handleChangeSpedition(e.target.checked)}
                                                                    label={this.props.intl.formatMessage({ id: 'fields.spedition' })}
                                                                />
                                                            </div>
                                                        </h4>
                                                        <div>
                                                            {this.state.values.is_spedition ? (
                                                                <PermissionsCheck hasAny={Object.values(PERMS_SPEDITION)}>
                                                                    <div>
                                                                        <div className="row">
                                                                            <div className="col-md-12">
                                                                                <SelectField
                                                                                    id="carrier_id"
                                                                                    label={this.props.intl.formatMessage({ id: 'fields.carrier' })}
                                                                                    values={getPrerequisitesArray(
                                                                                        this.props.prerequisites.values
                                                                                            .get('carriers')
                                                                                            .filter(
                                                                                                carrier =>
                                                                                                    carrier.get('is_blocked') === 0 ||
                                                                                                    carrier.get('id') === this.state.values.carrier_id
                                                                                            )
                                                                                    )}
                                                                                    readonly={() => isDisableFieldSpedition}
                                                                                    prompt="-"
                                                                                    nullable
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        {carriersCheckingData &&
                                                                            carriersCheckingData[this.state.values.carrier_id] &&
                                                                            carriersCheckingData[this.state.values.carrier_id].is_blocked === 1 && (
                                                                                <div className="alert alert-danger m-b-sm">
                                                                                    <FormattedMessage id="modules.transports.warningBlockedCarrier" />
                                                                                </div>
                                                                            )}
                                                                        <div className="row">
                                                                            <div className="col-md-6">
                                                                                <InputField
                                                                                    id="carrier_order_number"
                                                                                    readonly={() => isDisableFieldSpedition}
                                                                                    label={this.props.intl.formatMessage({ id: 'fields.orderNumber' })}
                                                                                />
                                                                            </div>
                                                                            <PermissionsCheck hasAny={Object.values(PERMS_SPEDITION_COST)}>
                                                                                <div className="col-md-6">
                                                                                    <div className="pull-left wp-60">
                                                                                        <InputField
                                                                                            id="carrier_price"
                                                                                            readonly={() => isDisableFieldSpeditionCost}
                                                                                            label={this.props.intl.formatMessage({ id: 'fields.price' })}
                                                                                            className="text-right"
                                                                                        />
                                                                                    </div>
                                                                                    <div className="pull-left wp-40">
                                                                                        <SelectField
                                                                                            id="carrier_currency_id"
                                                                                            label="&nbsp;"
                                                                                            values={getPrerequisitesArray(
                                                                                                this.props.prerequisites.values.get('currencies'),
                                                                                                'id',
                                                                                                'iso_code'
                                                                                            )}
                                                                                            readonly={() => isDisableFieldSpeditionCost}
                                                                                            prompt={false}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </PermissionsCheck>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col-md-6">
                                                                                <SuggestField
                                                                                    suggestions={suggestsVehiclesRegistration}
                                                                                    id="carrier_vehicle_registration"
                                                                                    readonly={() => isDisableFieldSpedition}
                                                                                    label={this.props.intl.formatMessage({
                                                                                        id: 'fields.vehicleRegistration',
                                                                                    })}
                                                                                />
                                                                            </div>
                                                                            <div className="col-md-6">
                                                                                <SuggestField
                                                                                    suggestions={suggestsTrailersRegistration}
                                                                                    readonly={() => isDisableFieldSpedition}
                                                                                    id="carrier_trailer_registration"
                                                                                    label={this.props.intl.formatMessage({
                                                                                        id: 'fields.trailerRegistration',
                                                                                    })}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col-md-6">
                                                                                <SuggestField
                                                                                    suggestions={suggestsDriversName}
                                                                                    id="carrier_driver_name"
                                                                                    readonly={() => isDisableFieldSpedition}
                                                                                    label={this.props.intl.formatMessage({ id: 'fields.driverName' })}
                                                                                />
                                                                            </div>
                                                                            <div className="col-md-6">
                                                                                <SuggestField
                                                                                    suggestions={suggestsDriversPhone}
                                                                                    id="carrier_driver_phone"
                                                                                    readonly={() => isDisableFieldSpedition}
                                                                                    label={this.props.intl.formatMessage({ id: 'fields.driverPhone' })}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col-md-12">
                                                                                <InputField
                                                                                    id="carrier_note"
                                                                                    readonly={() => isDisableFieldSpedition}
                                                                                    label={this.props.intl.formatMessage({ id: 'fields.note' })}
                                                                                    autosize
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col-md-6">
                                                                                <SelectField
                                                                                    id="company_cost_center_id"
                                                                                    label={this.props.intl.formatMessage({ id: 'fields.costCenter' })}
                                                                                    values={getPrerequisitesArray(
                                                                                        this.props.prerequisites.values.get('company_cost_centers')
                                                                                    )}
                                                                                    readonly={() => isDisableFieldSpedition}
                                                                                    prompt="-"
                                                                                    nullable
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="m-t-xxs">
                                                                            <a
                                                                                href="#"
                                                                                className="no-text-decoration text-gray"
                                                                                onClick={() => {
                                                                                    this.modalTransportDescriptionOpen(null)
                                                                                }}
                                                                            >
                                                                                <i className="far fa-pencil" />{' '}
                                                                                <FormattedMessage id="fields.transportDescription" />
                                                                            </a>
                                                                            {this.transport && (
                                                                                <a
                                                                                    href="#"
                                                                                    className="no-text-decoration text-gray m-l-md"
                                                                                    onClick={this.handleDownloadOrderForSupplier}
                                                                                >
                                                                                    <i className="far fa-file-pdf" />{' '}
                                                                                    <FormattedMessage id="fields.order" />
                                                                                </a>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </PermissionsCheck>
                                                            ) : (
                                                                <div>
                                                                    <div className="row">
                                                                        <div className="col-md-6">
                                                                            <SelectField
                                                                                id="vehicle_id"
                                                                                label={this.props.intl.formatMessage({ id: 'fields.vehicle' })}
                                                                                values={getPrerequisitesArray(
                                                                                    this.props.prerequisites.values.get('vehicles')
                                                                                )}
                                                                                prompt="-"
                                                                                nullable
                                                                                onChange={this.handleChangeVehicle}
                                                                            />
                                                                        </div>
                                                                        <div
                                                                            className="col-md-6"
                                                                            style={{
                                                                                paddingTop: '25px',
                                                                            }}
                                                                        >
                                                                            {transportStart &&
                                                                            transportStart.type_id !== constants.TRANSPORT_POINT_TYPE_TRAILER_OUT &&
                                                                            transportStart.trailer &&
                                                                            typeof transportStart.trailer === 'object' ? (
                                                                                <span>
                                                                                    <FormattedMessage id="fields.trailerRegistration" />:{' '}
                                                                                    {transportStart.trailer.registration}
                                                                                </span>
                                                                            ) : (
                                                                                <span>
                                                                                    <FormattedMessage id="fields.unknownTrailer" />
                                                                                </span>
                                                                            )}
                                                                            <a href="#" className="text-gray no-text-decoration hidden">
                                                                                <i className="far fa-search" />{' '}
                                                                                <FormattedMessage id="buttons.findVehicle" />
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-md-12">
                                                                            <InputField
                                                                                id="vehicle_note"
                                                                                label={this.props.intl.formatMessage({ id: 'fields.note' })}
                                                                                autosize
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-md-6">
                                                                            <SelectField
                                                                                id="company_cost_center_id"
                                                                                label={this.props.intl.formatMessage({ id: 'fields.costCenter' })}
                                                                                values={getPrerequisitesArray(
                                                                                    this.props.prerequisites.values.get('company_cost_centers')
                                                                                )}
                                                                                prompt="-"
                                                                                nullable
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>

                                                    {this.transport && (
                                                        <div className="wp-100 b-t display-inline hidden">
                                                            <div className="pull-right text-gray">
                                                                <CheckboxField
                                                                    id="is_archived"
                                                                    label={this.props.intl.formatMessage({ id: 'fields.archived' })}
                                                                />
                                                            </div>
                                                        </div>
                                                    )}

                                                    <div className="wp-100 m-t-lg btns-form display-inline">
                                                        <Link to={url(this.props.match, 'transports')} className="btn btn-addon btn-default">
                                                            <i className="far fa-chevron-left" /> <FormattedMessage id="buttons.back" />
                                                        </Link>

                                                            {this.transport && (
                                                                <SubmitButton
                                                                    saveAndClose
                                                                    onClick={this.handleSaveAndClose}
                                                                    isEdit={Boolean(this.transport)}
                                                                    perms={[
                                                                        PERMS.CREATE,
                                                                        PERMS.UPDATE,
                                                                        PERMS_CUSTOMER.UPDATE,
                                                                        PERMS_CUSTOMER_COST.UPDATE,
                                                                        PERMS_SPEDITION.UPDATE,
                                                                        PERMS_SPEDITION_COST.UPDATE,
                                                                        PERMS_FILES.UPDATE
                                                                    ]}
                                                                    className="btn-addon pull-right m-l-sm"
                                                                />
                                                            )}
                                                            <SubmitButton
                                                                isEdit={Boolean(this.transport)}
                                                                perms={[
                                                                    PERMS.CREATE,
                                                                    PERMS.UPDATE,
                                                                    PERMS_CUSTOMER.UPDATE,
                                                                    PERMS_CUSTOMER_COST.UPDATE,
                                                                    PERMS_SPEDITION.UPDATE,
                                                                    PERMS_SPEDITION_COST.UPDATE,
                                                                    PERMS_FILES.UPDATE
                                                                ]}
                                                                className="btn-addon pull-right m-l-sm"
                                                            />
                                                    </div>
                                                </Form>
                                            </PermissionsCheck>
                                        </LoadingOverlay>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-8" style={{ paddingLeft: '5px' }}>
                                <div className="panel panel-white">
                                    <div className="panel-body panel-padding transport-waypoints">
                                        <Tabs
                                            defaultActiveKey={1}
                                            animation={false}
                                            id="detail-tabs"
                                            className="detail-tabs"
                                            onSelect={this.handleSelectTab}
                                        >
                                            <Tab
                                                eventKey={1}
                                                title={
                                                    <span>
                                                        <i className="far fa-route m-r-xxs" />
                                                        {this.props.intl.formatMessage({ id: 'modules.transports.routeDefinition' })}
                                                    </span>
                                                }
                                            >
                                                <LoadingOverlay
                                                    active={
                                                        this.props.prerequisites.status.get('fetching') ||
                                                        this.props.status.get('fetching') ||
                                                        this.props.status.get('saving') ||
                                                        this.props.eventStatus.get('saving') ||
                                                        this.props.sendingToVehicle ||
                                                        this.props.deletionFromVehicle ||
                                                        this.props.eventDeleting ||
                                                        this.props.transportPointStateChanging ||
                                                        this.props.restoring ||
                                                        this.props.approving ||
                                                        this.props.eventTimesCorrection ||
                                                        this.props.pointEventTimesCorrection ||
                                                        this.props.transportEventsCreating
                                                    }
                                                >
                                                    <TransportsEditRoute
                                                        transport={this.transport}
                                                        transportType={this.props.createGetTransportTypeByIDSelector(
                                                            this.transport && this.transport.transport_type_id
                                                        )}
                                                        vehicleDataSource={this.props.vehicleDataSource}
                                                        points={points}
                                                        transportStart={transportStart}
                                                        firstTransportPoint={this.getFirstTransportPoint()}
                                                        pointTypes={this.pointTypes}
                                                        eventDataTypes={this.eventDataTypes}
                                                        predepartureCheck={this.state.predepartureCheck}
                                                        preloading={this.state.preloading}
                                                        createGetTransportPointStateByIDSelector={
                                                            this.props.createGetTransportPointStateByIDSelector
                                                        }
                                                        createGetDriverByIDSelector={this.props.createGetDriverByIDSelector}
                                                        createGetVehicleByIDSelector={this.props.createGetVehicleByIDSelector}
                                                        prerequisites={this.props.prerequisites}
                                                        realVehiclePositions={this.props.realVehiclePositions}
                                                        cargoType={
                                                            this.state.values.cargo_type_id &&
                                                            getPrerequisitesPairs(
                                                                this.props.prerequisites.values.getIn(['cargo_types_entities']),
                                                                'id',
                                                                null
                                                            )[this.state.values.cargo_type_id]
                                                        }
                                                        selectedAddress={this.state.modalCustomerAddressesFound.selectedAddress}
                                                        onSelectedAddressSet={this.modalCustomerAddressesFoundClearSelectedAddress}
                                                        handleRouteChanged={this.handleRouteChanged}
                                                        handleSendToVehicle={this.handleSendToVehicle}
                                                        handleDeleteFromVehicle={this.handleDeleteFromVehicle}
                                                        handlePredepartureCheckChanged={this.handlePredepartureCheckChanged}
                                                        handlePreloadingChanged={this.handlePreloadingChanged}
                                                        handleEventSubmit={this.handleEventSubmit}
                                                        handleDeleteEvent={this.handleDeleteEvent}
                                                        handleChangePointState={this.handleChangePointState}
                                                        handleSaveAddress={this.handleSaveAddress}
                                                        handleFindAddress={this.handleFindAddress}
                                                        handleChangeAddress={this.handleChangeAddress}
                                                        handleRecountPlannedRoute={this.handleRecountPlannedRoute}
                                                        handleRecountSpecifiedRoute={this.handleRecountSpecifiedRoute}
                                                        handleRecountRealRoute={this.handleRecountRealRoute}
                                                        handleLoadRealVehiclePositions={this.handleLoadRealVehiclePositions}
                                                        handleCorrectEventTimes={this.handleCorrectEventTimes}
                                                        handleCreateEvents={this.handleCreateEvents}
                                                        handleCorrectPointEventTimes={this.handleCorrectPointEventTimes}
                                                    />
                                                </LoadingOverlay>
                                            </Tab>
                                            {this.transport && (
                                                <Tab
                                                    eventKey={2}
                                                    title={
                                                        <span>
                                                            <i className="far fa-boxes m-r-xxs" />
                                                            {this.props.intl.formatMessage({ id: 'modules.transports.goods' })}
                                                        </span>
                                                    }
                                                >
                                                    {this.state.transportGoods.show && <TransportsGoods transport={this.transport} />}
                                                </Tab>
                                            )}
                                            {this.transport && (
                                                <Tab
                                                    eventKey={3}
                                                    title={
                                                        <span>
                                                            <i className="fas fa-th-list m-r-xxs" />
                                                            {this.props.intl.formatMessage({ id: 'modules.transports.parts' })}
                                                        </span>
                                                    }
                                                >
                                                    {this.state.transportParts.show && (
                                                        <TransportsParts transport={this.transport} onDownloadOrder={this.modalOrderOpen} />
                                                    )}
                                                </Tab>
                                            )}
                                            {this.transport && (
                                                <Tab
                                                    eventKey={4}
                                                    title={
                                                        <span>
                                                            <i className="far fa-file m-r-xxs" />
                                                            {this.props.intl.formatMessage({ id: 'modules.transports.files' })}
                                                        </span>
                                                    }
                                                >
                                                    {this.state.transportFiles.show && (
                                                        <TransportsFiles
                                                            transport={this.transport}
                                                            allRequiredFilesUploaded={this.state.allRequiredFilesUploaded}
                                                            handleSetAllRequiredFilesUploaded={this.handleAllRequiredFilesUploadedChanged}
                                                        />
                                                    )}
                                                </Tab>
                                            )}
                                            {this.transport && (
                                                <Tab
                                                    eventKey={5}
                                                    title={
                                                        <span>
                                                            <i className="far fa-money-bill m-r-xxs" />
                                                            {this.props.intl.formatMessage({ id: 'modules.transports.allowances' })}
                                                        </span>
                                                    }
                                                >
                                                    {this.state.allowances.show && <TransportsAllowances transport={this.transport} />}
                                                </Tab>
                                            )}
                                            {this.transport && (
                                                <Tab
                                                    eventKey={6}
                                                    title={
                                                        <span>
                                                            <i className="far fa-star m-r-xxs" />
                                                            {this.props.intl.formatMessage({ id: 'modules.transports.scoring' })}
                                                        </span>
                                                    }
                                                >
                                                    {this.state.transportScoring.show && <TransportsScoring transport={this.transport} />}
                                                </Tab>
                                            )}
                                        </Tabs>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Modal show={this.state.modalPriceParts.show} onHide={this.closePriceModal} bsSize="sm">
                        <Modal.Header closeButton>
                            <Modal.Title>
                                {' '}
                                <FormattedMessage id="modules.transports.pricing" />
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <PermissionsCheck hasAny={Object.values(PERMS_CUSTOMER_COST)}>
                                {pricePartsToEdit ? (
                                    <form
                                        onSubmit={e => {
                                            e.preventDefault()
                                            this.closePriceModal()
                                        }}
                                    >
                                        <div className="row form-group">
                                            <div className="col-md-6 p-v-xxs">
                                                <b>
                                                    <FormattedMessage id="fields.basePrice" />:
                                                </b>
                                            </div>
                                            <div className="col-md-6">
                                                <input
                                                    onChange={e => this.setPricePart(0, e.target.value)}
                                                    value={pricePartsToEdit[0]}
                                                    type="text"
                                                    className="form-control text-right"
                                                    disabled={isDisableFieldCustomerCost}
                                                />
                                            </div>
                                        </div>
                                        {this.props.prerequisites.values.get('surcharge_types') &&
                                            this.props.prerequisites.values.get('surcharge_types').map(item => (
                                                <div key={item.get('id')} className="row form-group">
                                                    <div className="col-md-6 p-v-xxs">{item.get('name')}:</div>
                                                    <div className="col-md-6">
                                                        <input
                                                            onChange={e => this.setPricePart(item.get('id'), e.target.value)}
                                                            value={pricePartsToEdit[item.get('id')]}
                                                            type="text"
                                                            className="form-control text-right"
                                                            disabled={isDisableFieldCustomerCost}
                                                        />
                                                    </div>
                                                </div>
                                            ))}
                                        <div className="row form-group">
                                            <div className="col-md-6 p-v-xxs">
                                                <b>
                                                    <FormattedMessage id="fields.totalPrice" />:
                                                </b>
                                            </div>
                                            <div className="col-md-6 p-v-xxs text-right">
                                                <b>{formatters.floatFormatter(this.getTotalPrice(pricePartsToEdit))}</b>
                                            </div>
                                        </div>
                                        <PermissionsCheck hasAny={[PERMS_CUSTOMER_COST.UPDATE]}>
                                            <div className="btns-form text-right">
                                                <button type="submit" className="btn btn-addon btn-success">
                                                    <i className="far fa-check" /> <FormattedMessage id="buttons.done" />
                                                </button>
                                            </div>
                                        </PermissionsCheck>
                                    </form>
                                ) : null}
                            </PermissionsCheck>
                        </Modal.Body>
                    </Modal>

                    <Modal show={this.state.showLogModal} onHide={this.closeLogModal}>
                        <Modal.Header closeButton>
                            <Modal.Title>
                                <FormattedMessage id="fields.log" />
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <table className="table table-striped table-hover">
                                <thead>
                                    <tr>
                                        <th className="w-140">
                                            <FormattedMessage id="fields.dateTime" />
                                        </th>
                                        <th className="w-160">
                                            <FormattedMessage id="fields.user" />
                                        </th>
                                        <th>
                                            <FormattedMessage id="fields.changes" />
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.props.transportLogs.valueSeq().map(transportLog => {
                                        const user = transportLog.user_id && this.props.createGetUserByIDSelector(transportLog.user_id)
                                        const event = constants.LOG_EVENTS_DEFINITION[transportLog.event]
                                        const parameters = transportLog.getParameters()
                                        const changes = transportLog.transport_log_changes

                                        if (!event) {
                                            return null
                                        }

                                        return (
                                            <tr key={`transportLog-${transportLog}`}>
                                                <td>{formatters.datetimeFormatter(transportLog.created_at)}</td>
                                                <td>{user && user.getShortFullName()}</td>
                                                <td>
                                                    <i className={`fas fa-circle m-r-xs ${event.className || ''}`} />
                                                    {this.props.intl.formatMessage({ id: event.name })}
                                                    <br />
                                                    {event.data &&
                                                        parameters &&
                                                        Object.keys(event.data).map((typeId, key) => (
                                                            <span key={`typeId-${typeId}`} className="text-gray">
                                                                {key ? ', ' : ''}
                                                                {`${this.props.intl.formatMessage({ id: event.data[typeId] })}: `}
                                                                {this.props.intl.formatMessage({
                                                                    id: this.transportLogEventParamFormatter(typeId, parameters[typeId]),
                                                                })}
                                                            </span>
                                                        ))}
                                                    {changes.size > 0 && (
                                                        <div className="m-t-xxs text-gray">
                                                            {changes.valueSeq().map(change => {
                                                                const changeType = constants.LOG_CHANGES_DEFINITION[change.type]
                                                                const name = changeType ? this.props.intl.formatMessage({ id: changeType.name }) : ''

                                                                let originalValue = change.original_value
                                                                let newValue = change.new_value

                                                                if (change.type === constants.LOG_CHANGE_TYPE_TRANSPORT_STATE) {
                                                                    originalValue = this.props.intl.formatMessage({
                                                                        id: `transportStates.${change.original_value}`,
                                                                    })

                                                                    newValue = this.props.intl.formatMessage({
                                                                        id: `transportStates.${change.new_value}`,
                                                                    })
                                                                }

                                                                if (changeType.formatter) {
                                                                    originalValue = changeType.formatter(originalValue)
                                                                    newValue = changeType.formatter(newValue)
                                                                }

                                                                return (
                                                                    <div key={`change-${change.id}`} className="row transport-log-row">
                                                                        <div className="col-lg-4">{name}</div>
                                                                        <div className="col-lg-4">{originalValue}</div>
                                                                        <div className="col-lg-4">{newValue}</div>
                                                                    </div>
                                                                )
                                                            })}
                                                        </div>
                                                    )}
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </Modal.Body>
                    </Modal>

                    <Modal show={this.state.modalSaveAddress.show} onHide={this.closeSaveAddressModal} className="modal-level-2">
                        <Modal.Header closeButton>
                            <Modal.Title>
                                <FormattedMessage id="buttons.saveAddress" />
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form
                                values={this.state.modalSaveAddress.values}
                                validationRules={{}}
                                onChange={this.handleSaveAddressChange}
                                onSubmit={this.handleSaveAddressSubmit}
                                className="pull-left wp-100"
                            >
                                <div className="row">
                                    <div className="col-md-6 b-r">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <InputField id="code" label={this.props.intl.formatMessage({ id: 'fields.code' })} />
                                            </div>
                                            <div className="col-md-6">
                                                <SelectField
                                                    id="customer_address_type_id"
                                                    label={this.props.intl.formatMessage({ id: 'fields.addressType' })}
                                                    values={getPrerequisitesArray(this.props.prerequisites.values.get('customer_address_types'))}
                                                />
                                            </div>
                                        </div>
                                        <InputField
                                            id="name"
                                            label={this.props.intl.formatMessage({ id: 'fields.name' })}
                                            formGroupClassName="form-group-bigger"
                                        />
                                        <SelectField
                                            id="customer_id"
                                            label={this.props.intl.formatMessage({ id: 'fields.customer' })}
                                            values={getPrerequisitesArray(this.props.prerequisites.values.get('customers'))}
                                            prompt="-"
                                            nullable
                                        />
                                        <div className="row">
                                            <div className="col-md-6">
                                                <InputField id="email" label={this.props.intl.formatMessage({ id: 'fields.email' })} />
                                            </div>
                                            <div className="col-md-6">
                                                <InputField id="phone" label={this.props.intl.formatMessage({ id: 'fields.phone' })} />
                                            </div>
                                        </div>
                                        <InputField id="note" label={this.props.intl.formatMessage({ id: 'fields.note' })} autosize />
                                        <div className="form-part">
                                            <CheckboxField
                                                id="is_booking_required_address"
                                                label={this.props.intl.formatMessage({ id: 'fields.bookingRequired' })}
                                            />
                                            {Boolean(this.state.modalSaveAddress.values.is_booking_required) && (
                                                <div>
                                                    <InputField id="booking_url" label={this.props.intl.formatMessage({ id: 'fields.bookingURL' })} />
                                                    <InputField
                                                        id="booking_note"
                                                        label={this.props.intl.formatMessage({ id: 'fields.bookingNote' })}
                                                        autosize
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="value_street">
                                                <FormattedMessage id="fields.street" />:
                                            </label>
                                            <span className="form-control input-readonly">{this.state.modalSaveAddress.values.street}</span>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor="value_zipcode">
                                                        <FormattedMessage id="fields.zipcode" />:
                                                    </label>
                                                    <span className="form-control input-readonly">{this.state.modalSaveAddress.values.zipcode}</span>
                                                </div>
                                            </div>
                                            <div className="col-md-8">
                                                <div className="form-group">
                                                    <label htmlFor="value_city">
                                                        <FormattedMessage id="fields.city" />:
                                                    </label>
                                                    <span className="form-control input-readonly">{this.state.modalSaveAddress.values.city}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor="value_country_code">
                                                        <FormattedMessage id="fields.country" />:
                                                    </label>
                                                    <span className="form-control input-readonly">
                                                        {this.state.modalSaveAddress.values.country_code}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="col-md-8" />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="value_gps">
                                                <FormattedMessage id="fields.gps" />:
                                            </label>
                                            <span className="form-control input-readonly">
                                                {this.state.modalSaveAddress.values
                                                    ? `${this.state.modalSaveAddress.values.gps_lat}, ${this.state.modalSaveAddress.values.gps_lng}`
                                                    : ''}
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                <PermissionsCheck hasAny={[PERMS.CREATE, PERMS.UPDATE]}>
                                    <div className="btns-form text-right">
                                        <button type="submit" className="btn btn-success">
                                            <FormattedMessage id="buttons.save" />
                                        </button>
                                    </div>
                                </PermissionsCheck>
                            </Form>
                        </Modal.Body>
                    </Modal>

                    <Modal show={Boolean(this.state.modalSaveAddressDuplicity.show)} onHide={this.handleSaveAddressDuplicityCancel} bsSize="sm">
                        <Modal.Header closeButton>
                            <Modal.Title>
                                <FormattedMessage id="confirmDialog.duplicityFound.title" />
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <FormattedMessage id="confirmDialog.duplicityFound.message" />
                            {customerAddressDuplicity && (
                                <div>
                                    <div className="row m-t-lg">
                                        <div className="col-sm-4">
                                            <FormattedMessage id="fields.customer" />:
                                        </div>
                                        <div className="col-sm-8 text-right">
                                            <strong>{customerAddressDuplicity.customer}</strong>
                                        </div>
                                    </div>
                                    <hr className="tiny" />
                                    <div className="row">
                                        <div className="col-sm-4">
                                            <FormattedMessage id="fields.addressType" />:
                                        </div>
                                        <div className="col-sm-8 text-right">
                                            <strong>{customerAddressDuplicity.type}</strong>
                                        </div>
                                    </div>
                                    <hr className="tiny" />
                                    <div className="row">
                                        <div className="col-sm-4">
                                            <FormattedMessage id="fields.name" />:
                                        </div>
                                        <div className="col-sm-8 text-right">
                                            <strong>{customerAddressDuplicity.name}</strong>
                                        </div>
                                    </div>
                                    <hr className="tiny" />
                                    <div className="row">
                                        <div className="col-sm-4">
                                            <FormattedMessage id="fields.address" />:
                                        </div>
                                        <div className="col-sm-8 text-right">
                                            <strong>{customerAddressDuplicity.address}</strong>
                                        </div>
                                    </div>
                                    <hr className="tiny" />
                                    <div className="row">
                                        <div className="col-sm-4">
                                            <FormattedMessage id="fields.email" />:
                                        </div>
                                        <div className="col-sm-8 text-right">
                                            <strong>{customerAddressDuplicity.email}</strong>
                                        </div>
                                    </div>
                                    <hr className="tiny" />
                                    <div className="row">
                                        <div className="col-sm-4">
                                            <FormattedMessage id="fields.phone" />:
                                        </div>
                                        <div className="col-sm-8 text-right">
                                            <strong>{customerAddressDuplicity.phone}</strong>
                                        </div>
                                    </div>
                                    <hr className="tiny" />
                                    <div className="row">
                                        <div className="col-sm-4">
                                            <FormattedMessage id="fields.note" />:
                                        </div>
                                        <div className="col-sm-8 text-right">
                                            <strong>{customerAddressDuplicity.note}</strong>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </Modal.Body>
                        <Modal.Footer>
                            <PermissionsCheck hasAny={[PERMS.UPDATE, PERMS.CREATE]}>
                                <button className="btn btn-success m-r-xs" onClick={this.handleSaveAddressDuplicityConfirm}>
                                    <FormattedMessage id="confirmDialog.duplicityFound.accept" />
                                </button>
                            </PermissionsCheck>
                            <button className="btn btn-default" onClick={this.handleSaveAddressDuplicityCancel}>
                                <FormattedMessage id="confirmDialog.duplicityFound.cancel" />
                            </button>
                        </Modal.Footer>
                    </Modal>

                    {this.transport && (
                        <Modal show={Boolean(this.state.modalOrder.show)} onHide={this.modalOrderClose}>
                            <Modal.Header closeButton>
                                <Modal.Title>
                                    <FormattedMessage id="fields.order" />
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <TransportsShippingOrderForm
                                    transport={this.transport}
                                    transportCustomers={customers}
                                    transportPoints={points}
                                    transportFormValues={this.state.values}
                                    transportPart={this.state.modalOrder.transportPart}
                                    transportCustomer={this.state.modalOrder.transportCustomer}
                                    forWhom={this.state.modalOrder.forWhom}
                                    documentType={this.state.modalOrder.documentType}
                                    onSubmit={this.modalOrderSubmit}
                                    onSendToEmail={this.modalOrderSendToEmail}
                                    onCancel={this.modalOrderClose}
                                />
                            </Modal.Body>
                        </Modal>
                    )}

                    <Modal show={Boolean(this.state.modalCustomerAddressesFound.show)} onHide={this.modalCustomerAddressesFoundClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>
                                <FormattedMessage id="fields.foundAddressesTitle" />
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>
                                <FormattedMessage id="fields.foundAddressesText" />
                            </p>
                            <table className="table table-striped table-hover m-t-lg">
                                <thead>
                                    <tr>
                                        <th className="w-40" />
                                        <th className="w-300">
                                            <FormattedMessage id="fields.customer" />
                                        </th>
                                        <th className="w-max wm-300">
                                            <FormattedMessage id="fields.address" />
                                        </th>
                                        <th className="w-200">
                                            <FormattedMessage id="fields.addressType" />
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {foundCustomerAddresses &&
                                        foundCustomerAddresses.length &&
                                        foundCustomerAddresses.map(address => (
                                            <tr
                                                key={`address-${address.id}`}
                                                onClick={() => {
                                                    this.modalCustomerAddressesFoundSelectAddress(address)
                                                }}
                                                className="row-selectable"
                                            >
                                                <td className="w-40 table-buttons text-center">
                                                    <button className="far fa-check-circle text-gray" />
                                                </td>
                                                <td>{address.customer}</td>
                                                <td>
                                                    <strong>
                                                        {address.code && `${address.code} - `}
                                                        {address.name}
                                                    </strong>
                                                    <br />
                                                    <small>{address.address}</small>
                                                </td>
                                                <td>{address.type}</td>
                                            </tr>
                                        ))}
                                </tbody>
                            </table>
                        </Modal.Body>
                        <Modal.Footer>
                            <PermissionsCheck hasAny={[PERMS.UPDATE, PERMS.CREATE]}>
                                <button className="btn btn-success m-r-xs" onClick={this.modalCustomerAddressesFoundSaveAsNewAddress}>
                                    <FormattedMessage id="buttons.saveAsNewAddress" />
                                </button>
                            </PermissionsCheck>
                            <button className="btn btn-default" onClick={this.modalCustomerAddressesFoundClose}>
                                <FormattedMessage id="buttons.cancel" />
                            </button>
                        </Modal.Footer>
                    </Modal>

                    <Modal show={Boolean(this.state.modalTransportDescription.show)} onHide={this.modalTransportDescriptionClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>
                                <FormattedMessage id="fields.transportDescription" />
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <InputField
                                id="transport_description"
                                readonly={() => this.state.modalTransportDescription.readonly}
                                autosize
                                rows={20}
                                value={this.state.modalTransportDescription.text}
                                onChange={e => {
                                    this.modalTransportDescriptionSetText(e.target.value)
                                }}
                            />
                        </Modal.Body>
                        <Modal.Footer>
                            {!this.state.modalTransportDescription.readonly && (
                                <button className="btn btn-success btn-addon m-r-xs" onClick={this.modalTransportDescriptionSubmit}>
                                    <i className="far fa-check" /> <FormattedMessage id="buttons.done" />
                                </button>
                            )}
                            <button className="btn btn-default" onClick={this.modalTransportDescriptionClose}>
                                <FormattedMessage id="buttons.cancel" />
                            </button>
                        </Modal.Footer>
                    </Modal>

                    <Modal show={Boolean(this.state.modalInvoicingNote.show)} onHide={this.modalInvoicingNoteClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>
                                <FormattedMessage id="fields.invoicingNote" />
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <InputField
                                id="invoicing_note"
                                autosize
                                rows={20}
                                value={this.state.modalInvoicingNote.text}
                                readonly={() => isDisableFieldCustomer}
                                onChange={e => {
                                    this.modalInvoicingNoteSetText(e.target.value)
                                }}
                            />
                        </Modal.Body>
                        <Modal.Footer>
                            {!isDisableFieldCustomer && (
                                <button className="btn btn-success btn-addon m-r-xs" onClick={this.modalInvoicingNoteSubmit}>
                                    <i className="far fa-check" /> <FormattedMessage id="buttons.done" />
                                </button>
                            )}
                            <button className="btn btn-default" onClick={this.modalInvoicingNoteClose}>
                                <FormattedMessage id="buttons.cancel" />
                            </button>
                        </Modal.Footer>
                    </Modal>

                    <ConfirmDialog options={this.state.confirmDialog} />
                </div>
            </PermissionsCheck>
        )
    }
}

function mapStateToProps(state, ownProps) {
    return {
        transport: createGetTransportByIDSelector(ownProps.match.params.transportID)(state),
        status: createGetCurrentTransportStatusSelector()(state),
        eventStatus: createGetCurrentTransportEventStatusSelector()(state),
        deleting: getTransportDeleting(state),
        deleteError: getTransportDeleteError(state),
        restoring: getTransportRestoring(state),
        restoreError: getTransportRestoreError(state),
        approving: getTransportApproving(state),
        approveError: getTransportApproveError(state),
        eventDeleting: getTransportEventDeleting(state),
        eventDeleteError: getTransportEventDeleteError(state),
        sendingToVehicle: getTransportSendingToVehicle(state),
        sendToVehicleError: getTransportSendToVehicleError(state),
        deletionFromVehicle: getTransportDeletingFromVehicle(state),
        deleteFromVehicleError: getTransportDeleteFromVehicleError(state),
        transportPointStateChanging: getTransportPointStateChanging(state),
        transportPointStateChangeError: getTransportPointStateChangeError(state),
        plannedRouteCalculating: getPlannedRouteCalculating(state),
        plannedRouteCalculatingError: getPlannedRouteCalculatingError(state),
        specifiedRouteCalculating: getSpecifiedRouteCalculating(state),
        specifiedRouteCalculatingError: getSpecifiedRouteCalculatingError(state),
        realRouteCalculating: getRealRouteCalculating(state),
        realRouteCalculatingError: getRealRouteCalculatingError(state),
        prerequisites: preparePrerequisitesFetchStatusSelectors(componentIdentifier, prerequisites, state),
        transportStart: createGetTransportStartSelector()(state),
        transportLogs: getTransportLogs(state),
        realVehiclePositions: getRealVehiclePositions(state),
        vehicleDataSource: getVehicleDataSource(state),
        vehicleHomeCountry: getVehicleHomeCountry(state),
        currentUserId: getCurrentUserId(state),
        customersCheckingData: getCustomersCheckingData(state),
        carriersCheckingData: getCarriersCheckingData(state),
        customerAddressStatus: createGetCurrentCustomerAddressStatusSelector()(state),
        customerAddressDuplicity: createGetDuplicitySelector()(state),
        transportOrderData: getTransportOrderData(state),
        transportOrderCreating: getTransportOrderCreating(state),
        transportOrderCreatingError: getTransportOrderCreatingError(state),
        transportOrderSending: getTransportOrderSending(state),
        transportOrderSendingError: getTransportOrderSendingError(state),
        foundCustomerAddresses: getFoundAddresses(state),
        foundCustomerAddressesFetching: getFoundAddressesFetching(state),
        foundCustomerAddressesError: getFoundAddressesError(state),
        eventTimesCorrection: getEventTimesCorrection(state),
        eventTimesCorrectionError: getEventTimesCorrectionError(state),
        pointEventTimesCorrection: getPointEventTimesCorrection(state),
        pointEventTimesCorrectionError: getPointEventTimesCorrectionError(state),
        transportEventsCreating: getTransportEventsCreating(state),
        transportEventsCreatingError: getTransportEventsCreatingError(state),
        suggestsForCarrier: getSuggestsForCarrier(state),
        settings: getSettings(state),
        customerInfo: getCustomerInfo(state),
        customerInfoFetching: getCustomerInfoFetching(state),
        createGetCountryByIDSelector: id => createGetCountryByIDSelector(id)(state),
        createGetTransportTypeByIDSelector: id => createGetTransportTypeByIDSelector(id)(state),
        createGetTransportPointStateByIDSelector: id => createGetTransportPointStateByIDSelector(id)(state),
        createGetDriverByIDSelector: id => createGetDriverByIDSelector(id)(state),
        createGetVehicleByIDSelector: id => createGetVehicleByIDSelector(id)(state),
        createGetTrailerByIDSelector: id => createGetTrailerByIDSelector(id)(state),
        createGetUserByIDSelector: id => createGetUserByIDSelector(id)(state),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            ...bindActionCreators(
                {
                    ...transportsActionCreators,
                    ...preparePrerequisitesActionCreators(componentIdentifier, prerequisites),
                    ...vehiclesActionCreators,
                    ...customerAddressesActionCreators,
                },
                dispatch
            ),
        },
        redirect: toURL => dispatch(push(toURL)),
        notify: (notification, type) => dispatch(Notifications.show(notification, type)),
    }
}

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(TransportsEdit)
)
