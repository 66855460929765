import { List, Map, OrderedMap } from 'immutable'
import * as actionTypes from './actionTypes'

const initialState = Map({
    all: Map({
        objects: OrderedMap({}),
        fetching: false,
        error: null,
        duplicity: null,
    }),
    current: Map({
        saving: false,
        error: null,
        isTransportCustomerBlocked: null,
        id: null,
    }),
    deletion: Map({
        inProgress: false,
        error: null,
    }),
    restoring: Map({
        inProgress: false,
        error: null,
    }),
    approving: Map({
        inProgress: false,
        error: null,
    }),
    sms: Map({
        inProgress: false,
        error: null,
        text: null,
    }),
    sendingToVehicle: Map({
        inProgress: false,
        error: null,
    }),
    deletionFromVehicle: Map({
        inProgress: false,
        error: null,
    }),
    creationFromTemplate: Map({
        inProgress: false,
        error: null,
    }),
    creationTemplate: Map({
        inProgress: false,
        error: null,
        createdTemplate: null,
    }),
    duplication: Map({
        inProgress: false,
        error: null,
        duplicatedTransport: null,
    }),
    event: Map({
        saving: false,
        error: null,
    }),
    eventDeletion: Map({
        inProgress: false,
        error: null,
    }),
    eventsCreating: Map({
        inProgress: false,
        error: null,
    }),
    pointStateChanging: Map({
        inProgress: false,
        error: null,
    }),
    log: Map({
        objects: OrderedMap({}),
        fetching: false,
        error: null,
    }),
    plannedRouteCalculating: Map({
        inProgress: false,
        error: null,
    }),
    specifiedRouteCalculating: Map({
        inProgress: false,
        error: null,
    }),
    realRouteCalculating: Map({
        inProgress: false,
        error: null,
    }),
    realVehiclePositions: Map({
        objects: [],
        fetching: false,
        error: null,
    }),
    vehicleDataSource: Map({
        object: null,
        fetching: false,
        error: null,
    }),
    vehicleHomeCountry: Map({
        object: null,
        fetching: false,
        error: null,
    }),
    preview: Map({
        object: null,
        fetching: false,
        error: null,
    }),
    mapPreview: Map({
        object: null,
        fetching: false,
        error: null,
    }),
    checkCustomers: Map({
        data: null,
        fetching: false,
        error: null,
    }),
    checkCarriers: Map({
        data: null,
        fetching: false,
        error: null,
    }),
    ordersInfo: Map({
        data: null,
        fetching: false,
        error: null,
    }),
    orderCreating: Map({
        data: null,
        inProgress: false,
        error: null,
    }),
    orderSending: Map({
        inProgress: false,
        error: null,
    }),
    files: Map({
        objects: [],
        objectsEvent: [],
        fetching: false,
        saving: false,
        deleting: false,
        error: null,
    }),
    goods: Map({
        objects: [],
        fetching: false,
        saving: false,
        deleting: false,
        error: null,
    }),
    parts: Map({
        objects: [],
        fetching: false,
        saving: false,
        deleting: false,
        error: null,
    }),
    eventTimesCorrection: Map({
        inProgress: false,
        error: null,
    }),
    pointEventTimesCorrection: Map({
        inProgress: false,
        error: null,
    }),
    allowances: Map({
        data: [],
        fetching: false,
        saving: false,
        adding: false,
        deleting: false,
        error: null,
    }),
    scoring: Map({
        data: {},
        fetching: false,
        saving: false,
        error: null,
    }),
    carrierSuggests: Map({
        data: {},
        fetching: false,
        error: null,
    }),
    customerInfo: Map({
        data: OrderedMap({}),
        fetching: false,
        error: null,
    }),
    import: Map({
        inProgress: false,
        error: null,
        file_id: null,
        headers: List(),
        headersDefault: Map(),
        items: List(),
        defaultDate: null
    }),
    importPrices: Map({
        inProgress: false,
        error: null,
        file_id: null,
        headers: List(),
        headersDefault: Map(),
        items: List()
    }),
})

function transports(state = initialState, action) {
    switch (action.type) {
        // List
        case actionTypes.FETCH_STARTED:
            return state.setIn(['all', 'objects'], OrderedMap({})).setIn(['all', 'fetching'], true)
        case actionTypes.FETCH_FULFILLED:
            return state.withMutations(map => {
                map.setIn(['all', 'objects'], action.payload)
                    .setIn(['all', 'fetching'], false)
                    .setIn(['all', 'error'], null)
            })
        case actionTypes.FETCH_REJECTED:
            return state.withMutations(map => {
                map.setIn(['all', 'fetching'], false).setIn(['all', 'error'], action.payload)
            })
        case actionTypes.FETCH_CANCELLED:
            return state.setIn(['all', 'fetching'], false)
        case actionTypes.SORTING_CHANGED:
            return state.setIn(['all', 'sorting'], action.payload)

        // One
        case actionTypes.FETCH_ONE_STARTED:
            return state.setIn(['current', 'fetching'], true)
        case actionTypes.FETCH_ONE_FULFILLED:
        case actionTypes.FETCH_ONE_REJECTED:
        case actionTypes.FETCH_ONE_CANCELLED:
            return state.setIn(['current', 'fetching'], false)

        // Load customer info
        case actionTypes.LOAD_CUSTOMER_INFO_STARTED:
            return state.setIn(['customerInfo', 'fetching'], true)
        case actionTypes.LOAD_CUSTOMER_INFO_FULFILLED:
            return state.withMutations(map => {
                map
                    .setIn(['customerInfo', 'data', action.payload.index], action.payload.data)
                    .setIn(['customerInfo', 'fetching'], false)
                    .setIn(['customerInfo', 'error'], null)
            })
        case actionTypes.LOAD_CUSTOMER_INFO_REJECTED:
            return state.withMutations(map => {
                map.setIn(['customerInfo', 'fetching'], false).setIn(['customerInfo', 'error'], action.payload)
            })
        case actionTypes.LOAD_CUSTOMER_INFO_CANCELLED:
            return state.setIn(['customerInfo', 'fetching'], false)
        case actionTypes.CLEAR_CUSTOMER_INFO:
            return state.setIn(['customerInfo', 'data'], {})

        // New/edit
        case actionTypes.SAVE_STARTED:
            return state.withMutations(map => {
                map.setIn(['current', 'saving'], true)
                    .setIn(['current', 'error'], null)
                    .setIn(['all', 'duplicity'], null)
            })
        case actionTypes.SAVE_FULFILLED:
            return state.withMutations(map => {
                map.setIn(['current', 'saving'], false)
                    .setIn(['current', 'error'], null)
                    .setIn(['current', 'isTransportCustomerBlocked'], action.payload.is_transport_customer_blocked)
                    .setIn(['current', 'id'], String(action.payload.get('id')))
                    .setIn(['all', 'duplicity'], null)
                    .setIn(['all', 'objects', String(action.payload.get('id'))], action.payload)
            })
        case actionTypes.SAVE_REJECTED:
            return state.withMutations(map => {
                map.setIn(['current', 'saving'], false)
                    .setIn(['current', 'error'], action.payload)
                    .setIn(['all', 'duplicity'], null)
            })
        case actionTypes.SAVE_DUPLICITY_FOUND:
            return state.withMutations(map => {
                map.setIn(['current', 'saving'], false)
                    .setIn(['current', 'error'], null)
                    .setIn(['all', 'duplicity'], action.payload)
            })

        // Delete
        case actionTypes.DELETE_STARTED:
            return state.setIn(['deletion', 'inProgress'], true)
        case actionTypes.DELETE_FULFILLED:
            return state.deleteIn(['all', 'objects', String(action.payload)]).withMutations(map => {
                map.setIn(['deletion', 'inProgress'], false).setIn(['deletion', 'error'], null)
            })
        case actionTypes.DELETE_REJECTED:
            return state.withMutations(map => {
                map.setIn(['deletion', 'inProgress'], false).setIn(['deletion', 'error'], action.payload)
            })

        // Restore
        case actionTypes.RESTORE_STARTED:
            return state.setIn(['restoring', 'inProgress'], true)
        case actionTypes.RESTORE_FULFILLED:
            return state.withMutations(map => {
                map.setIn(['restoring', 'inProgress'], false)
                    .setIn(['restoring', 'error'], null)
                    .setIn(['all', 'objects', String(action.payload.get('id'))], action.payload)
            })
        case actionTypes.RESTORE_REJECTED:
            return state.withMutations(map => {
                map.setIn(['restoring', 'inProgress'], false).setIn(['restoring', 'error'], action.payload)
            })

        // Approve
        case actionTypes.APPROVE_STARTED:
            return state.setIn(['approving', 'inProgress'], true)
        case actionTypes.APPROVE_FULFILLED:
            return state.withMutations(map => {
                map.setIn(['approving', 'inProgress'], false)
                    .setIn(['approving', 'error'], null)
                    .setIn(['all', 'objects', String(action.payload.get('id'))], action.payload)
            })
        case actionTypes.APPROVE_REJECTED:
            return state.withMutations(map => {
                map.setIn(['approving', 'inProgress'], false).setIn(['approving', 'error'], action.payload)
            })

        // Sms
        case actionTypes.COPY_SMS_STARTED:
            return state.setIn(['sms', 'inProgress'], true)
        case actionTypes.COPY_SMS_FULFILLED:
            return state.withMutations(map => {
                map.setIn(['sms', 'inProgress'], false)
                    .setIn(['sms', 'error'], null)
                    .setIn(['sms', 'text'], action.payload)
            })
        case actionTypes.COPY_SMS_REJECTED:
            return state.withMutations(map => {
                map.setIn(['sms', 'inProgress'], false).setIn(['sms', 'error'], action.payload)
            })
        case actionTypes.COPY_SMS_CANCELLED:
            return state.setIn(['sms', 'fetching'], false)

        // Send to vehicle
        case actionTypes.SEND_TO_VEHICLE_STARTED:
            return state.setIn(['sendingToVehicle', 'inProgress'], true)
        case actionTypes.SEND_TO_VEHICLE_FULFILLED:
            return state.withMutations(map => {
                map.setIn(['sendingToVehicle', 'inProgress'], false)
                    .setIn(['sendingToVehicle', 'error'], null)
                    .setIn(['all', 'objects', String(action.payload.get('id'))], action.payload)
            })
        case actionTypes.SEND_TO_VEHICLE_REJECTED:
            return state.withMutations(map => {
                map.setIn(['sendingToVehicle', 'inProgress'], false).setIn(['sendingToVehicle', 'error'], action.payload)
            })

        // Delete from vehicle
        case actionTypes.DELETE_FROM_VEHICLE_STARTED:
            return state.setIn(['deletionFromVehicle', 'inProgress'], true)
        case actionTypes.DELETE_FROM_VEHICLE_FULFILLED:
            return state.withMutations(map => {
                map.setIn(['deletionFromVehicle', 'inProgress'], false)
                    .setIn(['deletionFromVehicle', 'error'], null)
                    .setIn(['all', 'objects', String(action.payload.get('id'))], action.payload)
            })
        case actionTypes.DELETE_FROM_VEHICLE_REJECTED:
            return state.withMutations(map => {
                map.setIn(['deletionFromVehicle', 'inProgress'], false).setIn(['deletionFromVehicle', 'error'], action.payload)
            })

        // Create from template
        case actionTypes.CREATE_FROM_TEMPLATE_STARTED:
            return state.setIn(['creationFromTemplate', 'inProgress'], true)
        case actionTypes.CREATE_FROM_TEMPLATE_FULFILLED:
            return state.withMutations(map => {
                map.setIn(['creationFromTemplate', 'inProgress'], false).setIn(['creationFromTemplate', 'error'], null)
            })
        case actionTypes.CREATE_FROM_TEMPLATE_REJECTED:
            return state.withMutations(map => {
                map.setIn(['creationFromTemplate', 'inProgress'], false).setIn(['creationFromTemplate', 'error'], action.payload)
            })

        // Create template
        case actionTypes.CREATE_TEMPLATE_STARTED:
            return state
                .setIn(['creationTemplate', 'inProgress'], true)
                .setIn(['creationTemplate', 'createdTemplate'], null)
                .setIn(['all', 'duplicity'], null)
        case actionTypes.CREATE_TEMPLATE_FULFILLED:
            return state.withMutations(map => {
                map.setIn(['creationTemplate', 'inProgress'], false)
                    .setIn(['creationTemplate', 'error'], null)
                    .setIn(['creationTemplate', 'createdTemplate'], action.payload)
                    .setIn(['all', 'duplicity'], null)
            })
        case actionTypes.CREATE_TEMPLATE_REJECTED:
            return state.withMutations(map => {
                map.setIn(['creationTemplate', 'inProgress'], false)
                    .setIn(['creationTemplate', 'error'], action.payload)
                    .setIn(['creationTemplate', 'createdTemplate'], null)
                    .setIn(['all', 'duplicity'], null)
            })
        case actionTypes.CREATE_TEMPLATE_DUPLICITY_FOUND:
            return state.withMutations(map => {
                map.setIn(['creationTemplate', 'inProgress'], false)
                    .setIn(['creationTemplate', 'error'], null)
                    .setIn(['creationTemplate', 'createdTemplate'], null)
                    .setIn(['all', 'duplicity'], action.payload)
            })

        // Duplication
        case actionTypes.DUPLICATE_STARTED:
            return state.setIn(['duplication', 'inProgress'], true).setIn(['duplication', 'duplicatedTransport'], null)
        case actionTypes.DUPLICATE_FULFILLED:
            return state.withMutations(map => {
                map.setIn(['duplication', 'inProgress'], false)
                    .setIn(['duplication', 'error'], null)
                    .setIn(['duplication', 'duplicatedTransport'], action.payload)
            })
        case actionTypes.DUPLICATE_REJECTED:
            return state.withMutations(map => {
                map.setIn(['duplication', 'inProgress'], false)
                    .setIn(['duplication', 'error'], action.payload)
                    .setIn(['duplication', 'duplicatedTransport'], null)
            })

        // New/edit event
        case actionTypes.SAVE_EVENT_STARTED:
            return state.withMutations(map => {
                map.setIn(['event', 'saving'], true).setIn(['event', 'error'], null)
            })
        case actionTypes.SAVE_EVENT_FULFILLED:
            return state.withMutations(map => {
                map.setIn(['event', 'saving'], false)
                    .setIn(['event', 'error'], null)
                    .setIn(['all', 'objects', String(action.payload.get('id'))], action.payload)
            })
        case actionTypes.SAVE_EVENT_REJECTED:
            return state.withMutations(map => {
                map.setIn(['event', 'saving'], false).setIn(['event', 'error'], action.payload)
            })

        // Delete event
        case actionTypes.DELETE_EVENT_STARTED:
            return state.setIn(['eventDeletion', 'inProgress'], true)
        case actionTypes.DELETE_EVENT_FULFILLED:
            return state.withMutations(map => {
                map.setIn(['eventDeletion', 'inProgress'], false)
                    .setIn(['eventDeletion', 'error'], null)
                    .setIn(['all', 'objects', String(action.payload.get('id'))], action.payload)
            })
        case actionTypes.DELETE_EVENT_REJECTED:
            return state.withMutations(map => {
                map.setIn(['eventDeletion', 'inProgress'], false).setIn(['eventDeletion', 'error'], action.payload)
            })

        // Create events
        case actionTypes.CREATE_EVENTS_STARTED:
            return state.withMutations(map => {
                map.setIn(['eventsCreating', 'inProgress'], true).setIn(['eventsCreating', 'error'], null)
            })
        case actionTypes.CREATE_EVENTS_FULFILLED:
            return state.withMutations(map => {
                map.setIn(['eventsCreating', 'inProgress'], false)
                    .setIn(['eventsCreating', 'error'], null)
                    .setIn(['all', 'objects', String(action.payload.get('id'))], action.payload)
            })
        case actionTypes.CREATE_EVENTS_REJECTED:
            return state.withMutations(map => {
                map.setIn(['eventsCreating', 'inProgress'], false).setIn(['eventsCreating', 'error'], action.payload)
            })

        // Change point state
        case actionTypes.CHANGE_POINT_STATE_STARTED:
            return state.setIn(['pointStateChanging', 'inProgress'], true)
        case actionTypes.CHANGE_POINT_STATE_FULFILLED:
            return state.withMutations(map => {
                map.setIn(['pointStateChanging', 'inProgress'], false)
                    .setIn(['pointStateChanging', 'error'], null)
                    .setIn(['all', 'objects', String(action.payload.get('id'))], action.payload)
            })
        case actionTypes.CHANGE_POINT_STATE_REJECTED:
            return state.withMutations(map => {
                map.setIn(['pointStateChanging', 'inProgress'], false).setIn(['pointStateChanging', 'error'], action.payload)
            })

        // Log
        case actionTypes.FETCH_LOG_STARTED:
            return state.setIn(['log', 'objects'], OrderedMap({})).setIn(['log', 'fetching'], true)
        case actionTypes.FETCH_LOG_FULFILLED:
            return state.withMutations(map => {
                map.setIn(['log', 'objects'], action.payload)
                    .setIn(['log', 'fetching'], false)
                    .setIn(['log', 'error'], null)
            })
        case actionTypes.FETCH_LOG_REJECTED:
            return state.withMutations(map => {
                map.setIn(['log', 'fetching'], false).setIn(['log', 'error'], action.payload)
            })
        case actionTypes.FETCH_LOG_CANCELLED:
            return state.setIn(['log', 'fetching'], false)

        // Clear
        case actionTypes.CLEAR:
            return state.withMutations(map => {
                map.setIn(['all', 'objects'], OrderedMap({}))
            })

        // Recount planned route
        case actionTypes.RECOUNT_PLANNED_ROUTE_STARTED:
            return state.setIn(['plannedRouteCalculating', 'inProgress'], true)
        case actionTypes.RECOUNT_PLANNED_ROUTE_FULFILLED:
            return state.withMutations(map => {
                map.setIn(['plannedRouteCalculating', 'inProgress'], false)
                    .setIn(['plannedRouteCalculating', 'error'], null)
                    .setIn(['all', 'objects', String(action.payload.get('id'))], action.payload)
            })
        case actionTypes.RECOUNT_PLANNED_ROUTE_REJECTED:
            return state.withMutations(map => {
                map.setIn(['plannedRouteCalculating', 'inProgress'], false).setIn(['plannedRouteCalculating', 'error'], action.payload)
            })

        // Recount specified route
        case actionTypes.RECOUNT_SPECIFIED_ROUTE_STARTED:
            return state.setIn(['specifiedRouteCalculating', 'inProgress'], true)
        case actionTypes.RECOUNT_SPECIFIED_ROUTE_FULFILLED:
            return state.withMutations(map => {
                map.setIn(['specifiedRouteCalculating', 'inProgress'], false)
                    .setIn(['specifiedRouteCalculating', 'error'], null)
                    .setIn(['all', 'objects', String(action.payload.get('id'))], action.payload)
            })
        case actionTypes.RECOUNT_SPECIFIED_ROUTE_REJECTED:
            return state.withMutations(map => {
                map.setIn(['specifiedRouteCalculating', 'inProgress'], false).setIn(['specifiedRouteCalculating', 'error'], action.payload)
            })

        // Recount real route
        case actionTypes.RECOUNT_REAL_ROUTE_STARTED:
            return state.setIn(['realRouteCalculating', 'inProgress'], true)
        case actionTypes.RECOUNT_REAL_ROUTE_FULFILLED:
            return state.withMutations(map => {
                map.setIn(['realRouteCalculating', 'inProgress'], false)
                    .setIn(['realRouteCalculating', 'error'], null)
                    .setIn(['all', 'objects', String(action.payload.get('id'))], action.payload)
            })
        case actionTypes.RECOUNT_REAL_ROUTE_REJECTED:
            return state.withMutations(map => {
                map.setIn(['realRouteCalculating', 'inProgress'], false).setIn(['realRouteCalculating', 'error'], action.payload)
            })

        // Real vehicle positions
        case actionTypes.FETCH_REAL_VEHICLE_POSITIONS_STARTED:
            return state.setIn(['realVehiclePositions', 'objects'], []).setIn(['realVehiclePositions', 'fetching'], true)
        case actionTypes.FETCH_REAL_VEHICLE_POSITIONS_FULFILLED:
            return state.withMutations(map => {
                map.setIn(['realVehiclePositions', 'objects'], action.payload)
                    .setIn(['realVehiclePositions', 'fetching'], false)
                    .setIn(['realVehiclePositions', 'error'], null)
            })
        case actionTypes.FETCH_REAL_VEHICLE_POSITIONS_REJECTED:
            return state.withMutations(map => {
                map.setIn(['realVehiclePositions', 'fetching'], false).setIn(['realVehiclePositions', 'error'], action.payload)
            })
        case actionTypes.FETCH_REAL_VEHICLE_POSITIONS_CANCELLED:
            return state.setIn(['realVehiclePositions', 'fetching'], false)

        // Fetch vehicle data source
        case actionTypes.FETCH_VEHICLE_DATA_SOURCE_STARTED:
            return state.setIn(['vehicleDataSource', 'fetching'], true)
        case actionTypes.FETCH_VEHICLE_DATA_SOURCE_FULFILLED:
            return state.withMutations(map => {
                map.setIn(['vehicleDataSource', 'object'], action.payload)
                    .setIn(['vehicleDataSource', 'fetching'], false)
                    .setIn(['vehicleDataSource', 'error'], null)
            })
        case actionTypes.FETCH_VEHICLE_DATA_SOURCE_REJECTED:
            return state.withMutations(map => {
                map.setIn(['vehicleDataSource', 'fetching'], false).setIn(['vehicleDataSource', 'error'], action.payload)
            })
        case actionTypes.FETCH_VEHICLE_DATA_SOURCE_CANCELLED:
            return state.setIn(['vehicleDataSource', 'fetching'], false)
        case actionTypes.CLEAR_VEHICLE_DATA_SOURCE:
            return state.setIn(['vehicleDataSource', 'object'], null)

        // Fetch home country
        case actionTypes.FETCH_VEHICLE_HOME_COUNTRY_STARTED:
            return state.setIn(['vehicleHomeCountry', 'fetching'], true)
        case actionTypes.FETCH_VEHICLE_HOME_COUNTRY_FULFILLED:
            return state.withMutations(map => {
                map.setIn(['vehicleHomeCountry', 'object'], action.payload)
                    .setIn(['vehicleHomeCountry', 'fetching'], false)
                    .setIn(['vehicleHomeCountry', 'error'], null)
            })
        case actionTypes.FETCH_VEHICLE_HOME_COUNTRY_REJECTED:
            return state.withMutations(map => {
                map.setIn(['vehicleHomeCountry', 'fetching'], false).setIn(['vehicleHomeCountry', 'error'], action.payload)
            })
        case actionTypes.FETCH_VEHICLE_HOME_COUNTRY_CANCELLED:
            return state.setIn(['vehicleHomeCountry', 'fetching'], false)

        // Preview
        case actionTypes.FETCH_PREVIEW_STARTED:
            return state.setIn(['preview', 'fetching'], true)
        case actionTypes.FETCH_PREVIEW_FULFILLED:
            return state.withMutations(map => {
                map.setIn(['preview', 'fetching'], false)
                    .setIn(['preview', 'error'], null)
                    .setIn(['preview', 'object'], action.payload)
            })
        case actionTypes.FETCH_PREVIEW_REJECTED:
            return state.withMutations(map => {
                map.setIn(['preview', 'fetching'], false).setIn(['preview', 'error'], action.payload)
            })
        case actionTypes.FETCH_PREVIEW_CANCELLED:
            return state.setIn(['preview', 'fetching'], false)

        // Map preview
        case actionTypes.FETCH_MAP_PREVIEW_STARTED:
            return state.setIn(['mapPreview', 'fetching'], true)
        case actionTypes.FETCH_MAP_PREVIEW_FULFILLED:
            return state.withMutations(map => {
                map.setIn(['mapPreview', 'fetching'], false)
                    .setIn(['mapPreview', 'error'], null)
                    .setIn(['mapPreview', 'object'], action.payload)
            })
        case actionTypes.FETCH_MAP_PREVIEW_REJECTED:
            return state.withMutations(map => {
                map.setIn(['mapPreview', 'fetching'], false).setIn(['mapPreview', 'error'], action.payload)
            })
        case actionTypes.FETCH_MAP_PREVIEW_CANCELLED:
            return state.setIn(['mapPreview', 'fetching'], false)

        // Check customers
        case actionTypes.CHECK_CUSTOMERS_STARTED:
            return state.setIn(['checkCustomers', 'fetching'], true)
        case actionTypes.CHECK_CUSTOMERS_FULFILLED:
            return state.withMutations(map => {
                map.setIn(['checkCustomers', 'fetching'], false)
                    .setIn(['checkCustomers', 'error'], null)
                    .setIn(['checkCustomers', 'data'], action.payload)
            })
        case actionTypes.CHECK_CUSTOMERS_REJECTED:
            return state.withMutations(map => {
                map.setIn(['checkCustomers', 'fetching'], false).setIn(['checkCustomers', 'error'], action.payload)
            })
        case actionTypes.CHECK_CUSTOMERS_CANCELLED:
            return state.setIn(['checkCustomers', 'fetching'], false)

        // Check carriers
        case actionTypes.CHECK_CARRIERS_STARTED:
            return state.setIn(['checkCarriers', 'fetching'], true)
        case actionTypes.CHECK_CARRIERS_FULFILLED:
            return state.withMutations(map => {
                map.setIn(['checkCarriers', 'fetching'], false)
                    .setIn(['checkCarriers', 'error'], null)
                    .setIn(['checkCarriers', 'data'], action.payload)
            })
        case actionTypes.CHECK_CARRIERS_REJECTED:
            return state.withMutations(map => {
                map.setIn(['checkCarriers', 'fetching'], false).setIn(['checkCarriers', 'error'], action.payload)
            })
        case actionTypes.CHECK_CARRIERS_CANCELLED:
            return state.setIn(['checkCarriers', 'fetching'], false)

        // Orders info
        case actionTypes.FETCH_ORDERS_INFO_STARTED:
            return state.setIn(['ordersInfo', 'fetching'], true)
        case actionTypes.FETCH_ORDERS_INFO_FULFILLED:
            return state.withMutations(map => {
                map.setIn(['ordersInfo', 'fetching'], false)
                    .setIn(['ordersInfo', 'error'], null)
                    .setIn(['ordersInfo', 'data'], action.payload)
            })
        case actionTypes.FETCH_ORDERS_INFO_REJECTED:
            return state.withMutations(map => {
                map.setIn(['ordersInfo', 'fetching'], false).setIn(['ordersInfo', 'error'], action.payload)
            })
        case actionTypes.FETCH_ORDERS_INFO_CANCELLED:
            return state.setIn(['ordersInfo', 'fetching'], false)

        // Create order
        case actionTypes.CREATE_ORDER_STARTED:
            return state.setIn(['orderCreating', 'inProgress'], true)
        case actionTypes.CREATE_ORDER_FULFILLED:
            return state.withMutations(map => {
                map.setIn(['orderCreating', 'inProgress'], false)
                    .setIn(['orderCreating', 'error'], null)
                    .setIn(['orderCreating', 'data'], action.payload)
            })
        case actionTypes.CREATE_ORDER_REJECTED:
            return state.withMutations(map => {
                map.setIn(['orderCreating', 'inProgress'], false).setIn(['orderCreating', 'error'], action.payload)
            })
        case actionTypes.CREATE_ORDER_CANCELLED:
            return state.setIn(['orderCreating', 'inProgress'], false)

        // Send order to email
        case actionTypes.SEND_ORDER_TO_EMAIL_STARTED:
            return state.setIn(['orderSending', 'inProgress'], true)
        case actionTypes.SEND_ORDER_TO_EMAIL_FULFILLED:
            return state.withMutations(map => {
                map.setIn(['orderSending', 'inProgress'], false).setIn(['orderSending', 'error'], null)
            })
        case actionTypes.SEND_ORDER_TO_EMAIL_REJECTED:
            return state.withMutations(map => {
                map.setIn(['orderSending', 'inProgress'], false).setIn(['orderSending', 'error'], action.payload)
            })
        case actionTypes.SEND_ORDER_TO_EMAIL_CANCELLED:
            return state.setIn(['orderSending', 'inProgress'], false)

        // Fetch files
        case actionTypes.FETCH_FILES_STARTED:
            return state.setIn(['files', 'fetching'], true)
        case actionTypes.FETCH_FILES_FULFILLED:
            return state.withMutations(map => {
                map.setIn(['files', 'fetching'], false)
                    .setIn(['files', 'error'], null)
                    .setIn(['files', 'objects'], action.payload.files)
                    .setIn(['files', 'objectsEvent'], action.payload.eventFiles)
            })
        case actionTypes.FETCH_FILES_REJECTED:
            return state.withMutations(map => {
                map.setIn(['files', 'fetching'], false).setIn(['files', 'error'], action.payload)
            })
        case actionTypes.FETCH_FILES_CANCELLED:
            return state.setIn(['files', 'fetching'], false)

        // Upload files
        case actionTypes.UPLOAD_FILES_STARTED:
            return state.setIn(['files', 'saving'], true)
        case actionTypes.UPLOAD_FILES_FULFILLED:
            return state.withMutations(map => {
                map.setIn(['files', 'saving'], false)
                    .setIn(['files', 'error'], null)
                    .setIn(['files', 'objects'], action.payload.files)
                    .setIn(['files', 'objectsEvent'], action.payload.eventFiles)
            })
        case actionTypes.UPLOAD_FILES_REJECTED:
            return state.withMutations(map => {
                map.setIn(['files', 'saving'], false).setIn(['files', 'error'], action.payload)
            })
        case actionTypes.UPLOAD_FILES_CANCELLED:
            return state.setIn(['files', 'saving'], false)

        // Delete file
        case actionTypes.DELETE_FILE_STARTED:
            return state.setIn(['files', 'deleting'], true)
        case actionTypes.DELETE_FILE_FULFILLED:
            return state.withMutations(map => {
                map.setIn(['files', 'deleting'], false)
                    .setIn(['files', 'error'], null)
                    .setIn(['files', 'objects'], action.payload.files)
                    .setIn(['files', 'objectsEvent'], action.payload.eventFiles)
            })
        case actionTypes.DELETE_FILE_REJECTED:
            return state.withMutations(map => {
                map.setIn(['files', 'deleting'], false).setIn(['files', 'error'], action.payload)
            })
        case actionTypes.DELETE_FILE_CANCELLED:
            return state.setIn(['files', 'deleting'], false)

        // Fetch goods
        case actionTypes.FETCH_GOODS_STARTED:
            return state.setIn(['goods', 'fetching'], true)
        case actionTypes.FETCH_GOODS_FULFILLED:
            return state.withMutations(map => {
                map.setIn(['goods', 'fetching'], false)
                    .setIn(['goods', 'error'], null)
                    .setIn(['goods', 'objects'], action.payload)
            })
        case actionTypes.FETCH_GOODS_REJECTED:
            return state.withMutations(map => {
                map.setIn(['goods', 'fetching'], false).setIn(['goods', 'error'], action.payload)
            })
        case actionTypes.FETCH_GOODS_CANCELLED:
            return state.setIn(['goods', 'fetching'], false)

        // Save goods
        case actionTypes.SAVE_GOODS_STARTED:
            return state.setIn(['goods', 'saving'], true)
        case actionTypes.SAVE_GOODS_FULFILLED:
            return state.withMutations(map => {
                map.setIn(['goods', 'saving'], false)
                    .setIn(['goods', 'error'], null)
                    .setIn(['goods', 'objects'], action.payload)
            })
        case actionTypes.SAVE_GOODS_REJECTED:
            return state.withMutations(map => {
                map.setIn(['goods', 'saving'], false).setIn(['goods', 'error'], action.payload)
            })
        case actionTypes.SAVE_GOODS_CANCELLED:
            return state.setIn(['goods', 'saving'], false)

        // Delete goods
        case actionTypes.DELETE_GOODS_STARTED:
            return state.setIn(['goods', 'deleting'], true)
        case actionTypes.DELETE_GOODS_FULFILLED:
            return state.withMutations(map => {
                map.setIn(['goods', 'deleting'], false)
                    .setIn(['goods', 'error'], null)
                    .setIn(['goods', 'objects'], action.payload)
            })
        case actionTypes.DELETE_GOODS_REJECTED:
            return state.withMutations(map => {
                map.setIn(['goods', 'deleting'], false).setIn(['goods', 'error'], action.payload)
            })
        case actionTypes.DELETE_GOODS_CANCELLED:
            return state.setIn(['goods', 'deleting'], false)

        // Fetch parts
        case actionTypes.FETCH_PARTS_STARTED:
            return state.setIn(['parts', 'fetching'], true)
        case actionTypes.FETCH_PARTS_FULFILLED:
            return state.withMutations(map => {
                map.setIn(['parts', 'fetching'], false)
                    .setIn(['parts', 'error'], null)
                    .setIn(['parts', 'objects'], action.payload)
            })
        case actionTypes.FETCH_PARTS_REJECTED:
            return state.withMutations(map => {
                map.setIn(['parts', 'fetching'], false).setIn(['parts', 'error'], action.payload)
            })
        case actionTypes.FETCH_PARTS_CANCELLED:
            return state.setIn(['parts', 'fetching'], false)

        // Save part
        case actionTypes.SAVE_PART_STARTED:
            return state.setIn(['parts', 'saving'], true)
        case actionTypes.SAVE_PART_FULFILLED:
            return state.withMutations(map => {
                map.setIn(['parts', 'saving'], false)
                    .setIn(['parts', 'error'], null)
                    .setIn(['parts', 'objects'], action.payload)
            })
        case actionTypes.SAVE_PART_REJECTED:
            return state.withMutations(map => {
                map.setIn(['parts', 'saving'], false).setIn(['parts', 'error'], action.payload)
            })
        case actionTypes.SAVE_PART_CANCELLED:
            return state.setIn(['parts', 'saving'], false)

        // Save parts order
        case actionTypes.SAVE_PARTS_ORDER_STARTED:
            return state.setIn(['parts', 'fetching'], true)
        case actionTypes.SAVE_PARTS_ORDER_FULFILLED:
            return state.withMutations(map => {
                map.setIn(['parts', 'fetching'], false)
                    .setIn(['parts', 'error'], null)
                    .setIn(['parts', 'objects'], action.payload)
            })
        case actionTypes.SAVE_PARTS_ORDER_REJECTED:
            return state.withMutations(map => {
                map.setIn(['parts', 'fetching'], false).setIn(['parts', 'error'], action.payload)
            })
        case actionTypes.SAVE_PARTS_ORDER_CANCELLED:
            return state.setIn(['parts', 'fetching'], false)

        // Delete part
        case actionTypes.DELETE_PART_STARTED:
            return state.setIn(['parts', 'deleting'], true)
        case actionTypes.DELETE_PART_FULFILLED:
            return state.withMutations(map => {
                map.setIn(['parts', 'deleting'], false)
                    .setIn(['parts', 'error'], null)
                    .setIn(['parts', 'objects'], action.payload)
            })
        case actionTypes.DELETE_PART_REJECTED:
            return state.withMutations(map => {
                map.setIn(['parts', 'deleting'], false).setIn(['parts', 'error'], action.payload)
            })
        case actionTypes.DELETE_PART_CANCELLED:
            return state.setIn(['parts', 'deleting'], false)

        // Correct event times
        case actionTypes.CORRECT_EVENT_TIMES_STARTED:
            return state.withMutations(map => {
                map.setIn(['eventTimesCorrection', 'inProgress'], true).setIn(['eventTimesCorrection', 'error'], null)
            })
        case actionTypes.CORRECT_EVENT_TIMES_FULFILLED:
            return state.withMutations(map => {
                map.setIn(['eventTimesCorrection', 'inProgress'], false)
                    .setIn(['eventTimesCorrection', 'error'], null)
                    .setIn(['all', 'objects', String(action.payload.get('id'))], action.payload)
            })
        case actionTypes.CORRECT_EVENT_TIMES_REJECTED:
            return state.withMutations(map => {
                map.setIn(['eventTimesCorrection', 'inProgress'], false).setIn(['eventTimesCorrection', 'error'], action.payload)
            })

        // Correct point event times
        case actionTypes.CORRECT_POINT_EVENT_TIMES_STARTED:
            return state.withMutations(map => {
                map.setIn(['pointEventTimesCorrection', 'inProgress'], true).setIn(['pointEventTimesCorrection', 'error'], null)
            })
        case actionTypes.CORRECT_POINT_EVENT_TIMES_FULFILLED:
            return state.withMutations(map => {
                map.setIn(['pointEventTimesCorrection', 'inProgress'], false)
                .setIn(['pointEventTimesCorrection', 'error'], null)
                .setIn(['all', 'objects', String(action.payload.get('id'))], action.payload)
            })
        case actionTypes.CORRECT_POINT_EVENT_TIMES_REJECTED:
            return state.withMutations(map => {
                map.setIn(['pointEventTimesCorrection', 'inProgress'], false).setIn(['pointEventTimesCorrection', 'error'], action.payload)
            })

        // Fetch allowances
        case actionTypes.FETCH_ALLOWANCES_STARTED:
            return state.withMutations(map => {
                map.setIn(['allowances', 'fetching'], true).setIn(['allowances', 'error'], null)
            })
        case actionTypes.FETCH_ALLOWANCES_FULFILLED:
            return state.withMutations(map => {
                map.setIn(['allowances', 'fetching'], false)
                    .setIn(['allowances', 'error'], null)
                    .setIn(['allowances', 'data'], action.payload)
            })
        case actionTypes.FETCH_ALLOWANCES_REJECTED:
            return state.withMutations(map => {
                map.setIn(['allowances', 'fetching'], false)
                    .setIn(['allowances', 'error'], action.payload)
                    .setIn(['allowances', 'data'], null)
            })

        // Add/save allowances
        case actionTypes.SAVE_ALLOWANCES:
        case actionTypes.SAVE_ALLOWANCES_STARTED:
            return state.withMutations(map => {
                map.setIn(['allowances', 'saving'], true)
            })
        case actionTypes.SAVE_ALLOWANCES_FULFILLED:
            return state.withMutations(map => {
                map.setIn(['allowances', 'saving'], false)
                    .setIn(['allowances', 'error'], null)
                    .setIn(['allowances', 'data'], action.payload)
            })
        case actionTypes.SAVE_ALLOWANCES_CANCELLED:
            return state.withMutations(map => {
                map.setIn(['allowances', 'saving'], false)
            })
        case actionTypes.SAVE_ALLOWANCES_REJECTED:
            return state.withMutations(map => {
                map.setIn(['allowances', 'saving'], false).setIn(['allowances', 'error'], action.payload)
            })

        // Delete allowance
        case actionTypes.DELETE_ALLOWANCE:
        case actionTypes.DELETE_ALLOWANCE_STARTED:
            return state.withMutations(map => {
                map.setIn(['allowances', 'deleting'], true)
            })
        case actionTypes.DELETE_ALLOWANCE_FULFILLED:
            return state.withMutations(map => {
                map.setIn(['allowances', 'deleting'], false)
                    .setIn(['allowances', 'error'], null)
                    .setIn(['allowances', 'data'], action.payload)
            })
        case actionTypes.DELETE_ALLOWANCE_CANCELLED:
            return state.withMutations(map => {
                map.setIn(['allowances', 'deleting'], false)
            })
        case actionTypes.DELETE_ALLOWANCE_REJECTED:
            return state.withMutations(map => {
                map.setIn(['allowances', 'deleting'], false).setIn(['allowances', 'error'], action.payload)
            })
        // Re-Assign allowances
        case actionTypes.REASSIGN_ALLOWANCES:
        case actionTypes.REASSIGN_ALLOWANCES_STARTED:
            return state.withMutations(map => {
                map.setIn(['allowances', 'fetching'], true)
            })
        case actionTypes.REASSIGN_ALLOWANCES_FULFILLED:
            return state.withMutations(map => {
                map.setIn(['allowances', 'fetching'], false)
                    .setIn(['allowances', 'error'], null)
                    .setIn(['allowances', 'data'], action.payload)
            })
        case actionTypes.REASSIGN_ALLOWANCES_CANCELLED:
            return state.withMutations(map => {
                map.setIn(['allowances', 'fetching'], false)
            })
        case actionTypes.REASSIGN_ALLOWANCES_REJECTED:
            return state.withMutations(map => {
                map.setIn(['allowances', 'fetching'], false).setIn(['allowances', 'error'], action.payload)
            })

        // Fetch scoring

        case actionTypes.FETCH_SCORING_STARTED:
            return state.setIn(['scoring', 'fetching'], true)
        case actionTypes.FETCH_SCORING_FULFILLED:
            return state.withMutations(map => {
                map.setIn(['scoring', 'data'], action.payload)
                    .setIn(['scoring', 'fetching'], false)
                    .setIn(['scoring', 'error'], null)
            })
        case actionTypes.FETCH_SCORING_REJECTED:
            return state.withMutations(map => {
                map.setIn(['scoring', 'fetching'], false).setIn(['scoring', 'error'], action.payload)
            })
        case actionTypes.FETCH_SCORING_CANCELLED:
            return state.setIn(['scoring', 'fetching'], false)

        // Save question

        case actionTypes.SAVE_SCORING_STARTED:
            return state.withMutations(map => {
                map.setIn(['scoring', 'saving'], true).setIn(['scoring', 'error'], null)
            })
        case actionTypes.SAVE_SCORING_FULFILLED:
            return state.withMutations(map => {
                map.setIn(['scoring', 'saving'], false).setIn(['scoring', 'data'], action.payload)
            })
        case actionTypes.SAVE_SCORING_REJECTED:
            return state.withMutations(map => {
                map.setIn(['scoring', 'saving'], false).setIn(['scoring', 'error'], action.payload)
            })
        case actionTypes.SAVE_SCORING_CANCELLED:
            return state.setIn(['scoring', 'saving'], false)

        // Fetch suggest for carrier

        case actionTypes.FETCH_SUGGEST_FOR_CARRIER_STARTED:
            return state.setIn(['carrierSuggests', 'fetching'], true)
        case actionTypes.FETCH_SUGGEST_FOR_CARRIER_FULFILLED:
            return state.withMutations(map => {
                map.setIn(['carrierSuggests', 'fetching'], false)
                    .setIn(['carrierSuggests', 'error'], null)
                    .setIn(['carrierSuggests', 'data'], action.payload)
            })
        case actionTypes.FETCH_SUGGEST_FOR_CARRIER_REJECTED:
            return state.withMutations(map => {
                map.setIn(['carrierSuggests', 'fetching'], false).setIn(['carrierSuggests', 'error'], action.payload)
            })
        case actionTypes.FETCH_SUGGEST_FOR_CARRIER_CANCELLED:
            return state.setIn(['carrierSuggests', 'fetching'], false)

        // Import headers

        case actionTypes.FETCH_IMPORT_HEADERS_STARTED:
            return state.setIn(['import', 'inProgress'], true)
        case actionTypes.FETCH_IMPORT_HEADERS_FULFILLED:
            return state.withMutations(map => {
                map.setIn(['import', 'inProgress'], false)
                map.setIn(['import', 'error'], null)
                map.setIn(['import', 'file_id'], action.payload.file_id)
                map.setIn(['import', 'headers'], action.payload.headers)
                map.setIn(['import', 'headersDefault'], action.payload.headers_default)
            })
        case actionTypes.FETCH_IMPORT_HEADERS_REJECTED:
            return state.withMutations(map => {
                map.setIn(['import', 'inProgress'], false).setIn(['import', 'error'], action.payload)
            })
        case actionTypes.FETCH_IMPORT_HEADERS_CANCELLED:
            return state.setIn(['import', 'inProgress'], false)

        // Import values

        case actionTypes.FETCH_IMPORT_ITEMS_STARTED:
            return state.setIn(['import', 'inProgress'], true)
        case actionTypes.FETCH_IMPORT_ITEMS_FULFILLED:
            return state.withMutations(map => {
                map.setIn(['import', 'inProgress'], false)
                map.setIn(['import', 'error'], null)
                map.setIn(['import', 'items'], action.payload.items)
                map.setIn(['import', 'defaultDate'], action.payload.defaultDate)
            })
        case actionTypes.FETCH_IMPORT_ITEMS_REJECTED:
            return state.withMutations(map => {
                map.setIn(['import', 'inProgress'], false).setIn(['import', 'error'], action.payload)
            })
        case actionTypes.FETCH_IMPORT_ITEMS_CANCELLED:
            return state.setIn(['import', 'inProgress'], false)

        // Import transports

        case actionTypes.IMPORT_TRANSPORTS_STARTED:
            return state.setIn(['import', 'inProgress'], true)
        case actionTypes.IMPORT_TRANSPORTS_FULFILLED:
            return state.withMutations(map => {
                map.setIn(['import', 'inProgress'], false)
                map.setIn(['import', 'error'], null)
            })
        case actionTypes.IMPORT_TRANSPORTS_REJECTED:
            return state.withMutations(map => {
                map.setIn(['import', 'inProgress'], false).setIn(['import', 'error'], action.payload)
            })
        case actionTypes.IMPORT_TRANSPORTS_CANCELLED:
            return state.setIn(['import', 'inProgress'], false)

        // Import prices headers

        case actionTypes.FETCH_IMPORT_PRICES_HEADERS_STARTED:
            return state.setIn(['importPrices', 'inProgress'], true)
        case actionTypes.FETCH_IMPORT_PRICES_HEADERS_FULFILLED:
            return state.withMutations(map => {
                map.setIn(['importPrices', 'inProgress'], false)
                map.setIn(['importPrices', 'error'], null)
                map.setIn(['importPrices', 'file_id'], action.payload.file_id)
                map.setIn(['importPrices', 'headers'], action.payload.headers)
                map.setIn(['importPrices', 'headersDefault'], action.payload.headers_default)
            })
        case actionTypes.FETCH_IMPORT_PRICES_HEADERS_REJECTED:
            return state.withMutations(map => {
                map.setIn(['import', 'inProgress'], false).setIn(['importPrices', 'error'], action.payload)
            })
        case actionTypes.FETCH_IMPORT_PRICES_HEADERS_CANCELLED:
            return state.setIn(['import', 'inProgress'], false)

        // Import prices values

        case actionTypes.FETCH_IMPORT_PRICES_ITEMS_STARTED:
            return state.setIn(['importPrices', 'inProgress'], true)
        case actionTypes.FETCH_IMPORT_PRICES_ITEMS_FULFILLED:
            return state.withMutations(map => {
                map.setIn(['importPrices', 'inProgress'], false)
                map.setIn(['importPrices', 'error'], null)
                map.setIn(['importPrices', 'items'], action.payload.items)
            })
        case actionTypes.FETCH_IMPORT_PRICES_ITEMS_REJECTED:
            return state.withMutations(map => {
                map.setIn(['importPrices', 'inProgress'], false).setIn(['importPrices', 'error'], action.payload)
            })
        case actionTypes.FETCH_IMPORT_PRICES_ITEMS_CANCELLED:
            return state.setIn(['importPrices', 'inProgress'], false)

        // Import transports prices

        case actionTypes.IMPORT_TRANSPORTS_PRICES_STARTED:
            return state.setIn(['importPrices', 'inProgress'], true)
        case actionTypes.IMPORT_TRANSPORTS_PRICES_FULFILLED:
            return state.withMutations(map => {
                map.setIn(['importPrices', 'inProgress'], false)
                map.setIn(['importPrices', 'error'], null)
            })
        case actionTypes.IMPORT_TRANSPORTS_PRICES_REJECTED:
            return state.withMutations(map => {
                map.setIn(['importPrices', 'inProgress'], false).setIn(['importPrices', 'error'], action.payload)
            })
        case actionTypes.IMPORT_TRANSPORTS_PRICES_CANCELLED:
            return state.setIn(['importPrices', 'inProgress'], false)

        default:
            return state
    }
}

export default transports
