import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import Notifications from 'react-notification-system-redux'
import { Modal } from 'react-bootstrap'

import { InputField } from '../../../common/form'
import * as constants from '../constants'
import * as transportConstants from '../../transports/constants'
import { hasAnyPermission } from '../../../common/helpers'
import * as formatters from '../../../common/formatters'
import { LoadingOverlay } from '../../../common/loading_overlay'

import * as actionCreators from '../actionCreators'
import { getInvoiceDetail, getInvoiceDetailFetching, getInvoiceDetailError, getPdfCreatingInProgress, getInvoiceSending } from '../selectors'
import PermissionsCheck from '../../auth/components/PermissionsCheck'
import { PERMS } from '../permissions'
import * as config from "../../../common/config";

class InvoiceDetail extends Component {
    state = {
        data: null,
        modalSendToEmail: {
            show: false,
            emailAddress: '',
            files: [],
            documents: []
        },
    }

    fetchInvoiceDetail = invoiceId => {
        this.props.actions.fetchInvoiceDetail(invoiceId)
    }

    setData = data => {
        this.setState({
            data,
        })
    }

    onCancel = () => {
        this.props.onCancel && this.props.onCancel()
    }

    onDownload = () => {
        const language = this.props.intl.locale ? this.props.intl.locale : 'cs'
        this.props.actions.createInvoicePdf(this.props.invoiceId, { language })
    }

    // send to email
    handleSendToEmail = e => {
        e && e.preventDefault()

        this.modalSendToEmailOpen()
    }

    modalSendToEmailOpen = () => {
        this.setState({
            modalSendToEmail: {
                ...this.state.modalSendToEmail,
                show: true,
            },
        })
    }

    modalSendToEmailClose = () => {
        this.setState({
            modalSendToEmail: {
                ...this.state.modalSendToEmail,
                show: false,
            },
        })
    }

    modalSendToEmailSetEmailAddress = e => {
        this.setState({
            modalSendToEmail: {
                ...this.state.modalSendToEmail,
                emailAddress: e.target.value,
            },
        })
    }

    modalSendToEmailSelectFile = id => {
        let files = [...this.state.modalSendToEmail.files]
        var index = files.indexOf(id)

        if (index === -1) {
            files.push(id)
        }
        else {
            files.splice(index, 1)
        }

        this.setState({
            modalSendToEmail: {
                ...this.state.modalSendToEmail,
                files: files,
            },
        })
    }

    modalSendToEmailSelectDocument = id => {
        let documents = [...this.state.modalSendToEmail.documents]
        var index = documents.indexOf(id)

        if (index === -1) {
            documents.push(id)
        }
        else {
            documents.splice(index, 1)
        }

        this.setState({
            modalSendToEmail: {
                ...this.state.modalSendToEmail,
                documents: documents,
            },
        })
    }

    modalSendToEmailSubmit = () => {
        this.props.onSendToEmail && this.props.onSendToEmail(this.props.invoiceId, this.state.modalSendToEmail.emailAddress, this.state.modalSendToEmail.files, this.state.modalSendToEmail.documents)

        this.modalSendToEmailClose()
    }

    componentDidMount() {
        if (this.props.invoiceId) {
            this.fetchInvoiceDetail(this.props.invoiceId)
        }

        if (this.props.data) {
            this.setData(this.props.data)
        }
    }

    componentWillReceiveProps(nextProps) {
        if (JSON.stringify(nextProps.invoiceId) !== JSON.stringify(this.props.invoiceId)) {
            this.fetchInvoiceDetail(nextProps.invoiceId)
        }

        if (JSON.stringify(nextProps.data) !== JSON.stringify(this.props.data)) {
            this.setData(nextProps.data)
        }
    }

    render() {
        const data = this.state.data
        const currencyIsoCode = data && data.currency ? data.currency.iso_code : ''
        const currencyRate = data && data.currency_rate ? data.currency_rate : 1
        const files = data && data.attachments ? data.attachments.files : []
        const documents = data && data.attachments ? data.attachments.documents : []

        return (
            <PermissionsCheck hasAny={Object.values(PERMS)}>
                <div>
                    <LoadingOverlay active={this.props.dataFetching || this.props.pdfCreatingInProgress || this.props.invoiceSending}>
                        {data ? (
                            <React.Fragment>
                                <div className="row">
                                    <div className="col-md-12">
                                        {data.type_id === constants.TYPE_CREDIT_NOTE ? (
                                            <h2 className="no-m">
                                                <FormattedMessage id="fields.creditNoteNo" /> {data.number}{' '}
                                                <small className="m-l-sm">
                                                    (<FormattedMessage id="fields.forInvoiceNo" />{' '}
                                                    {data.related_invoice && data.related_invoice.number})
                                                </small>
                                            </h2>
                                        ) : (
                                            <h2 className="no-m">
                                                <FormattedMessage id="fields.invoiceNo" /> {data.number}
                                            </h2>
                                        )}
                                        {data.imported_at && (
                                            <div className="alert alert-danger m-t-sm">
                                                <FormattedMessage  className="text-danger" id="fields.importedAlert" />
                                            </div>
                                        )}
                                        <hr />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-4">
                                        {data.supplier && (
                                            <div>
                                                <h3 className="m-t-xxs">{data.supplier.name}</h3>
                                                <p>
                                                    {data.supplier.street}
                                                    <br />
                                                    {data.supplier.zipcode} {data.supplier.city}
                                                    <br />
                                                    {data.supplier.country ? data.supplier.country.iso_code : null}
                                                </p>
                                                <p>
                                                    <FormattedMessage id="fields.regNumber" />: {data.supplier.reg_number}
                                                    <br />
                                                    <FormattedMessage id="fields.vatNumber" />: {data.supplier.vat_number}
                                                </p>
                                                <p>
                                                    <FormattedMessage id="fields.phone" />: {data.supplier.phone}
                                                    <br />
                                                    <FormattedMessage id="fields.email" />: {data.supplier.email}
                                                </p>
                                            </div>
                                        )}
                                    </div>

                                    <div className="col-md-4 b-l b-r">
                                        <div className="row">
                                            <div className="col-md-6 text-gray">
                                                <FormattedMessage id="fields.paymentMethod" />:
                                            </div>
                                            <div className="col-md-6 text-right">
                                                <p>{data.payment_method ? data.payment_method.name : '-'}</p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6 text-gray">
                                                <p>
                                                    <FormattedMessage id="fields.dateIssue" />:
                                                    <br />
                                                    <FormattedMessage id="fields.dateDue" />:
                                                    <br />
                                                    <FormattedMessage id="fields.dateTax" />:
                                                </p>
                                                <p>
                                                    <FormattedMessage id="fields.varSymbol" />:
                                                    <br />
                                                    <FormattedMessage id="fields.taxTransactionType" />:
                                                </p>
                                            </div>
                                            <div className="col-md-6 text-right">
                                                <p>
                                                    {data.date_issued ? formatters.dateFormatter(data.date_issued) : '-'}
                                                    <br />
                                                    <strong>{data.date_due ? formatters.dateFormatter(data.date_due) : '-'}</strong>
                                                    <br />
                                                    {data.date_tax ? formatters.dateFormatter(data.date_tax) : '-'}
                                                </p>
                                                <p>
                                                    <strong>{data.var_symbol}</strong>
                                                    <br />
                                                    <FormattedMessage id={`taxTransactionTypes.${data.tax_transaction_type_id}`} />
                                                </p>
                                            </div>

                                            <div className="col-md-12">
                                                <FormattedMessage id="fields.bankAccount" />:
                                                <p className="well no-m text-center">
                                                    <strong className="text-md">
                                                        {data.bank_account ? `${data.bank_account.number} / ${data.bank_account.bank_code}` : '-'}
                                                    </strong>
                                                </p>
                                            </div>

                                            <div className="col-md-4 text-gray">
                                                <p>
                                                    <FormattedMessage id="fields.bankAccountSWIFT" />:
                                                    <br />
                                                    <FormattedMessage id="fields.bankAccountIBAN" />:
                                                </p>
                                            </div>
                                            <div className="col-md-8 text-right">
                                                <p>
                                                    {data.bank_account ? data.bank_account.swift : '-'}
                                                    <br />
                                                    {data.bank_account ? data.bank_account.iban : '-'}
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        {data.customer && <h3 className="m-t-xxs">{data.customer.name}</h3>}
                                        {data.customer_address && (
                                            <div>
                                                <p>
                                                    {data.customer_address.street}
                                                    <br />
                                                    {data.customer_address.zipcode} {data.customer_address.city}
                                                    <br />
                                                    {data.customer_address.country ? data.customer_address.country.iso_code : null}
                                                </p>
                                                <p>
                                                    <FormattedMessage id="fields.regNumber" />: {data.customer ? data.customer.reg_number : null}
                                                    <br />
                                                    <FormattedMessage id="fields.vatNumber" />: {data.customer ? data.customer.vat_number : null}
                                                </p>
                                                <p>
                                                    <FormattedMessage id="fields.phone" />: {data.customer_address.phone}
                                                    <br />
                                                    <FormattedMessage id="fields.email" />: {data.customer_address.email}
                                                </p>
                                            </div>
                                        )}
                                        {data.customer_delivery_address && (
                                            <h3 className="m-t-xxs">
                                                <FormattedMessage id="fields.deliveryAddress" />
                                            </h3>
                                        )}
                                        {data.customer_delivery_address && (
                                            <div>
                                                <p>
                                                    {data.customer_delivery_address.street}
                                                    <br />
                                                    {data.customer_delivery_address.zipcode} {data.customer_delivery_address.city}
                                                    <br />
                                                    {data.customer_delivery_address.country ? data.customer_delivery_address.country.iso_code : null}
                                                </p>
                                            </div>
                                        )}
                                    </div>
                                </div>

                                <hr />

                                <div className="row m-t-sm">
                                    <div className="col-md-12">
                                        <p>
                                            <FormattedMessage id={`textBeforeItemsTypes.${data.text_before_items_type_id}`} />
                                        </p>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-12">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th className="w-max wm-400">
                                                        <FormattedMessage id="fields.name" />
                                                    </th>
                                                    <th className="w-80 text-right">
                                                        <FormattedMessage id="fields.count" />
                                                    </th>
                                                    <th className="w-60 text-center">
                                                        <FormattedMessage id="fields.unit" />
                                                    </th>
                                                    <th className="w-120 text-right">
                                                        <FormattedMessage id="fields.unitPrice" />
                                                    </th>
                                                    <th className="w-120 text-right">
                                                        <FormattedMessage id="fields.total" />
                                                    </th>
                                                    <th className="w-80 text-right">
                                                        <FormattedMessage id="fields.vat" /> %
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {data.items &&
                                                    data.items.map(item => (
                                                        <tr key={`item-${item.id}`}>
                                                            <td>
                                                                {item.name}
                                                                <br />
                                                                {item.text}
                                                            </td>
                                                            <td className="text-right">{formatters.floatFormatter(item.units_count)}</td>
                                                            <td className="text-center">{item.unit}</td>
                                                            <td className="text-right">
                                                                {formatters.priceFormatter(item.unit_price, '0,0.00', currencyIsoCode)}
                                                            </td>
                                                            <td className="text-right">
                                                                <strong>
                                                                    {formatters.priceFormatter(
                                                                        item.unit_price * item.units_count,
                                                                        '0,0.00',
                                                                        currencyIsoCode
                                                                    )}
                                                                </strong>
                                                            </td>
                                                            <td className="text-right">{formatters.percentFormatter(item.tax_rate_percent)}</td>
                                                        </tr>
                                                    ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                <div className="row m-t-md">
                                    <div className="col-md-6 col-md-offset-6">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th className="w-max wm-100">
                                                        <FormattedMessage id="fields.taxRate" />
                                                    </th>
                                                    <th className="w-120 text-right">
                                                        <FormattedMessage id="fields.base" />
                                                    </th>
                                                    <th className="w-120 text-right">
                                                        <FormattedMessage id="fields.vat" />
                                                    </th>
                                                    <th className="w-120 text-right">
                                                        <FormattedMessage id="fields.total" />
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {data.vat_summary &&
                                                    data.vat_summary.map(item => (
                                                        <tr key={`vatSummary-${item.percent}`}>
                                                            <td>{formatters.percentFormatter(item.percent)}</td>
                                                            <td className="text-right">
                                                                {formatters.priceFormatter(item.base, '0,0.00', currencyIsoCode)}
                                                            </td>
                                                            <td className="text-right">
                                                                {formatters.priceFormatter(item.vat, '0,0.00', currencyIsoCode)}
                                                            </td>
                                                            <td className="text-right">
                                                                {formatters.priceFormatter(item.base + item.vat, '0,0.00', currencyIsoCode)}
                                                            </td>
                                                        </tr>
                                                    ))}
                                                <tr>
                                                    <td>
                                                        <strong>
                                                            <FormattedMessage id="fields.total" />
                                                        </strong>
                                                    </td>
                                                    <td className="text-right">
                                                        <strong>
                                                            {formatters.priceFormatter(data.total && data.total.excl_vat, '0,0.00', currencyIsoCode)}
                                                        </strong>
                                                    </td>
                                                    <td className="text-right">
                                                        <strong>
                                                            {formatters.priceFormatter(data.total && data.total.vat, '0,0.00', currencyIsoCode)}
                                                        </strong>
                                                    </td>
                                                    <td className="text-right">
                                                        <strong>
                                                            {formatters.priceFormatter(data.total && data.total.incl_vat, '0,0.00', currencyIsoCode)}
                                                        </strong>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                <div className="row m-t-md">
                                    <div className="col-md-3 col-md-offset-6">
                                        <p className="text-right m-t-md">
                                            <FormattedMessage id="fields.totalToPay" />:
                                        </p>
                                    </div>
                                    <div className="col-md-3 text-right">
                                        <p className="well">
                                            <strong className="text-md">
                                                {formatters.priceFormatter(data.total && data.total.incl_vat, '0,0.00', currencyIsoCode)}
                                            </strong>
                                        </p>
                                    </div>
                                </div>

                                {currencyIsoCode !== 'CZK' ? (
                                    <div className="row m-t-md">
                                        <div className="col-md-6 col-md-offset-6">
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th className="w-max wm-100">
                                                            <FormattedMessage id="fields.taxRate" />
                                                        </th>
                                                        <th className="w-120 text-right">
                                                            <FormattedMessage id="fields.base" />
                                                        </th>
                                                        <th className="w-120 text-right">
                                                            <FormattedMessage id="fields.vat" />
                                                        </th>
                                                        <th className="w-120 text-right">
                                                            <FormattedMessage id="fields.total" />
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {data.vat_summary &&
                                                        data.vat_summary.map(item => (
                                                            <tr key={`vatSummary-${item.percent}`}>
                                                                <td>{formatters.percentFormatter(item.percent)}</td>
                                                                <td className="text-right">
                                                                    {formatters.priceFormatter(item.base * currencyRate, '0,0.00')}
                                                                </td>
                                                                <td className="text-right">
                                                                    {formatters.priceFormatter(item.vat * currencyRate, '0,0.00')}
                                                                </td>
                                                                <td className="text-right">
                                                                    {formatters.priceFormatter((item.base + item.vat) * currencyRate, '0,0.00')}
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    <tr>
                                                        <td>
                                                            <strong>
                                                                <FormattedMessage id="fields.total" />
                                                            </strong>
                                                        </td>
                                                        <td className="text-right">
                                                            <strong>
                                                                {formatters.priceFormatter(
                                                                    (data.total && data.total.excl_vat) * currencyRate,
                                                                    '0,0.00'
                                                                )}
                                                            </strong>
                                                        </td>
                                                        <td className="text-right">
                                                            <strong>
                                                                {formatters.priceFormatter((data.total && data.total.vat) * currencyRate, '0,0.00')}
                                                            </strong>
                                                        </td>
                                                        <td className="text-right">
                                                            <strong>
                                                                {formatters.priceFormatter(
                                                                    (data.total && data.total.incl_vat) * currencyRate,
                                                                    '0,0.00'
                                                                )}
                                                            </strong>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <p>
                                                <FormattedMessage id="fields.rate" /> {formatters.floatFormatter(currencyRate, 3)} CZK/{currencyIsoCode}{' '}
                                                <FormattedMessage id="fields.forDay" /> {data.date_tax ? formatters.dateFormatter(data.date_tax) : '-'}
                                            </p>
                                        </div>
                                    </div>
                                ) : null}

                                {data.note && (
                                    <div className="row m-t-sm">
                                        <div className="col-md-12">
                                            <p>
                                                <FormattedMessage id="fields.note" />:<br />
                                                <div className="wrapped">{data.note}</div>
                                            </p>
                                        </div>
                                    </div>
                                )}

                                <div className="row m-t-md">
                                    <div className="col-md-12">
                                        <p>
                                            <FormattedMessage id="fields.createdBy" />:{' '}
                                            {data.user ? formatters.fullName(data.user.first_name, data.user.last_name) : '-'}
                                        </p>
                                    </div>
                                </div>
                                <div className="btns-form m-t-lg clearfix">
                                    <div className="pull-right">
                                        <PermissionsCheck has={[PERMS.DOWNLOAD]}>
                                            <button type="submit" className="btn btn-addon btn-success m-r-xs" onClick={this.onDownload}>
                                                <i className="far fa-download" /> <FormattedMessage id="buttons.download" />
                                            </button>
                                        </PermissionsCheck>
                                        <PermissionsCheck has={[PERMS.SEND]}>
                                            <button type="button" className="btn btn-addon btn-success m-r-xs" onClick={this.handleSendToEmail}>
                                                <i className="far fa-envelope" /> <FormattedMessage id="buttons.sendToEmail" />
                                            </button>
                                        </PermissionsCheck>
                                        <button type="button" className="btn btn-addon btn-default" onClick={this.onCancel}>
                                            <i className="far fa-times" /> <FormattedMessage id="buttons.cancel" />
                                        </button>
                                    </div>
                                </div>
                            </React.Fragment>
                        ) : (
                            <div className="h-200" />
                        )}
                    </LoadingOverlay>

                    <Modal show={Boolean(this.state.modalSendToEmail.show)} onHide={this.modalSendToEmailClose} bsSize="md">
                        <Modal.Header closeButton>
                            <Modal.Title>
                                <FormattedMessage id="fields.sendToEmail" />
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <InputField
                                id="email"
                                label={this.props.intl.formatMessage({ id: 'fields.email' })}
                                value={this.state.modalSendToEmail.emailAddress}
                                onChange={this.modalSendToEmailSetEmailAddress}
                                readonly={() => !hasAnyPermission([PERMS.SEND])}
                            />
                            {(files.length > 0 || documents.length > 0) && (
                                <div className="m-t-lg">
                                    <p>
                                        <strong>Přílohy</strong><br />
                                        Vyberte soubory, které se mají odeslat přílohou e-mailu.
                                    </p>

                                    <div className="wp-100 m-b-md">
                                        <div className="table-container">
                                            <table className="table table-striped table-no-border no-m">
                                                <tbody>
                                                {files.map(file => (
                                                    <tr key={`files-${file.id}`}>
                                                        <td className="w-30 table-buttons">
                                                            <input type="checkbox" onChange={() => this.modalSendToEmailSelectFile(file.id)} />
                                                        </td>
                                                        <td>
                                                            <a
                                                                key={`preview-file-${file.id}`}
                                                                href={`${config.CFG_API_BASE_URL}/files/transport-files/${
                                                                    file.filename
                                                                }`}
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                            ><i class="fa fa-search"></i> {file.name}</a>
                                                        </td>
                                                        <td className="w-60 text-center">{file.extension}</td>
                                                        <td className="w-80 text-right">{file.size}</td>
                                                    </tr>
                                                ))}

                                                {documents.map(file => (
                                                    <tr key={`documents-${file.id}`}>
                                                        <td className="w-30 table-buttons">
                                                            <input type="checkbox" onChange={() => this.modalSendToEmailSelectDocument(file.id)} />
                                                        </td>
                                                        <td>
                                                            <a
                                                                key={`preview-document-${file.id}`}
                                                                href={`${config.CFG_API_BASE_URL}/files/transport-documents/${
                                                                    file.filename
                                                                }`}
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                            ><i className="fa fa-search"></i>  {file.item} - {this.props.intl.formatMessage({ id: transportConstants.TRANSPORT_EVENT_TYPES_DEFINITION[file.event_type].name })}</a>

                                                        </td>
                                                        <td className="w-60 text-center">{file.extension}</td>
                                                        <td className="w-80 text-right">{file.size}</td>
                                                    </tr>
                                                ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </Modal.Body>
                        <Modal.Footer>
                            <button className="btn btn-success m-r-xs" onClick={this.modalSendToEmailSubmit}>
                                <FormattedMessage id="buttons.send" />
                            </button>
                            <button className="btn btn-default" onClick={this.modalSendToEmailClose}>
                                <FormattedMessage id="buttons.cancel" />
                            </button>
                        </Modal.Footer>
                    </Modal>
                </div>
            </PermissionsCheck>
        )
    }
}

function mapStateToProps(state) {
    return {
        data: getInvoiceDetail(state),
        dataFetching: getInvoiceDetailFetching(state),
        dataError: getInvoiceDetailError(state),
        pdfCreatingInProgress: getPdfCreatingInProgress(state),
        invoiceSending: getInvoiceSending(state),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            ...bindActionCreators(
                {
                    ...actionCreators,
                },
                dispatch
            ),
        },
        notify: (notification, type) => dispatch(Notifications.show(notification, type)),
        dispatch,
    }
}

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(InvoiceDetail)
)
