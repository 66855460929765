import * as actionTypes from './actionTypes'

// Fetch

export const fetchCustomerAddresses = () => ({
    type: actionTypes.FETCH,
})

export const fetchCustomerAddressesFulfilled = customerAddresses => ({
    type: actionTypes.FETCH_FULFILLED,
    payload: customerAddresses,
})

export const fetchCustomerAddressesRejected = message => ({
    type: actionTypes.FETCH_REJECTED,
    payload: message,
})

export const fetchCustomerAddressesCancelled = () => ({
    type: actionTypes.FETCH_CANCELLED,
})

// Fetch one

export const fetchCustomerAddress = id => ({
    type: actionTypes.FETCH_ONE,
    payload: id,
})

export const fetchCustomerAddressFulfilled = () => ({
    type: actionTypes.FETCH_ONE_FULFILLED,
})

export const fetchCustomerAddressRejected = message => ({
    type: actionTypes.FETCH_ONE_REJECTED,
    payload: message,
})

export const fetchCustomerAddressCancelled = () => ({
    type: actionTypes.FETCH_ONE_CANCELLED,
})

// Save

export const saveCustomerAddress = (values, ignoreDuplicity) => ({
    type: actionTypes.SAVE,
    payload: {
        values,
        ignoreDuplicity,
    },
})

export const saveCustomerAddressFulfilled = customer => ({
    type: actionTypes.SAVE_FULFILLED,
    payload: customer,
})

export const saveCustomerAddressRejected = message => ({
    type: actionTypes.SAVE_REJECTED,
    payload: message,
})

export const saveCustomerAddressDuplicityFound = duplicity => ({
    type: actionTypes.SAVE_DUPLICITY_FOUND,
    payload: duplicity,
})

// Delete

export const deleteCustomerAddress = id => ({
    type: actionTypes.DELETE,
    payload: id,
})

export const deleteCustomerAddressFulfilled = id => ({
    type: actionTypes.DELETE_FULFILLED,
    payload: id,
})

export const deleteCustomerAddressRejected = message => ({
    type: actionTypes.DELETE_REJECTED,
    payload: message,
})

// Restore

export const restoreCustomerAddress = id => ({
    type: actionTypes.RESTORE,
    payload: id,
})

export const restoreCustomerAddressFulfilled = item => ({
    type: actionTypes.RESTORE_FULFILLED,
    payload: item,
})

export const restoreCustomerAddressRejected = message => ({
    type: actionTypes.RESTORE_REJECTED,
    payload: message,
})

// Export

export const exportCustomerAddresses = (filters, sorting) => ({
    type: actionTypes.EXPORT,
    payload: {
        filters,
        sorting,
    },
})

// Find

export const findCustomerAddresses = values => ({
    type: actionTypes.FIND,
    payload: values,
})

export const findCustomerAddressesFulfilled = addresses => ({
    type: actionTypes.FIND_FULFILLED,
    payload: addresses,
})

export const findCustomerAddressesRejected = message => ({
    type: actionTypes.FIND_REJECTED,
    payload: message,
})
