import * as actionTypes from './actionTypes'

// Fetch

export const fetchVehiclePositions = () => ({
    type: actionTypes.FETCH,
})

export const fetchVehiclePositionsFulfilled = vehicles => ({
    type: actionTypes.FETCH_FULFILLED,
    payload: vehicles,
})

export const fetchVehiclePositionsRejected = message => ({
    type: actionTypes.FETCH_REJECTED,
    payload: message,
})

export const fetchVehiclePositionsCancelled = () => ({
    type: actionTypes.FETCH_CANCELLED,
})
