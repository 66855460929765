import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import ReactPaginate from 'react-paginate'
import Notifications from 'react-notification-system-redux'
import { Modal } from 'react-bootstrap'

import { SimpleSelectField } from '../../../common/form'
import { getListItemsCountMessage } from '../../../common/helpers'
import * as formatters from '../../../common/formatters'
import * as offersActionCreators from '../actionCreators'
import { prepareTableActionCreators } from '../../../common/table/actionCreators'
import { getOffers, getOffersLoading, getAcceptInProgress, getAcceptError, getDeclineInProgress, getDeclineError } from '../selectors'
import { createTableDataSelector, createTableModelSelector } from '../../../common/table/selectors'
import { TableModelColumn, TableModelColumnFilteringType } from '../../../common/table/model'
import { TableFilterInputField } from '../../../common/table/components'
import { getActiveFilters, getTotalNumberOfPages } from '../../../common/table/helpers'
import { preparePrerequisitesFetchStatusSelectors } from '../../../common/prerequisites/selectors'
import { preparePrerequisitesActionCreators } from '../../../common/prerequisites/actionCreators'
import { createGetCountryByIDSelector } from '../../countries/selectors'
import { createGetCurrencyByIDSelector } from '../../currencies/selectors'

import OffersListRow from './OffersListRow'
import PermissionsCheck from '../../auth/components/PermissionsCheck'
import { PERMS } from '../permissions'

const tableIdentifier = 'transporeon_offers_list'
const clientSideItemsPerPage = undefined

const componentIdentifier = 'transporeon_offers_list'
const prerequisites = ['countries', 'transporeon_declination_reasons']

const columns = {
    number: new TableModelColumn({}),
}

class OffersList extends Component {
    state = {
        acceptItemIDs: [],
        declineItemIDs: [],
        declineReasonID: null,
        transporeonDeclinationReasons: [],
        selectedIDs: [],
    }

    handleAcceptProgress = nextProps => {
        // Accept successful
        if (this.props.acceptInProgress && !nextProps.acceptInProgress && nextProps.acceptError === null) {
            this.props.notify(
                {
                    title: this.props.intl.formatMessage({ id: 'alerts.titles.success' }),
                    message: this.props.intl.formatMessage({ id: 'alerts.messages.transporeonOfferAcceptSuccess' }),
                    position: 'tc',
                },
                'success'
            )
        }

        // Accept error
        if (this.props.acceptInProgress && !nextProps.acceptInProgress && nextProps.acceptError !== null) {
            this.props.notify(
                {
                    title: this.props.intl.formatMessage({ id: 'alerts.titles.error' }),
                    message: nextProps.acceptError,
                    position: 'tc',
                },
                'error'
            )
        }
    }

    handleDeclineProgress = nextProps => {
        // Decline successful
        if (this.props.declineInProgress && !nextProps.declineInProgress && nextProps.declineError === null) {
            this.props.notify(
                {
                    title: this.props.intl.formatMessage({ id: 'alerts.titles.success' }),
                    message: this.props.intl.formatMessage({ id: 'alerts.messages.transporeonOfferRejectSuccess' }),

                    position: 'tc',
                },
                'success'
            )
        }

        // Decline error
        if (this.props.declineInProgress && !nextProps.declineInProgress && nextProps.declineError !== null) {
            this.props.notify(
                {
                    title: this.props.intl.formatMessage({ id: 'alerts.titles.error' }),
                    message: nextProps.declineError,
                    position: 'tc',
                },
                'error'
            )
        }
    }

    handleAccept = ids => {
        this.setState({
            acceptItemIDs: ids,
        })
    }

    handleAcceptCancellation = e => {
        e && e.preventDefault()

        this.setState({
            acceptItemIDs: [],
        })
    }

    handleAcceptConfirmation = e => {
        e.preventDefault()

        this.props.actions.accept(this.state.acceptItemIDs)

        this.setState({
            acceptItemIDs: [],
        })
    }

    handleDecline = ids => {
        this.setState({
            declineItemIDs: ids,
        })
    }

    handleDeclineCancellation = e => {
        e && e.preventDefault()

        this.setState({
            declineItemIDs: [],
        })
    }

    handleDeclineConfirmation = e => {
        e.preventDefault()

        this.props.actions.decline(this.state.declineItemIDs, this.state.declineReasonID)

        this.setState({
            declineItemIDs: [],
        })
    }

    handleDeclineReasonChange = e => {
        this.setState({ declineReasonID: e.target.value })
    }

    handleExport = e => {
        e && e.preventDefault()
        this.props.actions.exportOffers(this.props.tableModel.get('filters').toJS(), this.props.tableModel.get('sorting').toJS())
    }

    handleSelect = id => {
        if (this.state.selectedIDs.indexOf(id) === -1) {
            this.setState({
                selectedIDs: [...this.state.selectedIDs, id],
            })
        } else {
            const selectedIDs = [...this.state.selectedIDs]
            selectedIDs.splice(selectedIDs.indexOf(id), 1)

            this.setState({
                selectedIDs,
            })
        }
    }

    handleSelectAll = e => {
        this.setState({
            selectedIDs:
                e.target.checked && this.props.offers.data
                    ? this.props.offers.data
                          .valueSeq()
                          .map(item => item.id)
                          .toJS()
                    : [],
        })
    }

    handleAcceptSelected = () => {
        this.setState({
            acceptItemIDs: [...this.state.selectedIDs],
        })
    }

    handleDeclineSelected = () => {
        this.setState({
            declineItemIDs: [...this.state.selectedIDs],
        })
    }

    deselectNoExistsItems = () => {
        const existsIds = this.props.offers.data ? this.props.offers.data.valueSeq().map(item => item.id) : []

        this.setState({
            selectedIDs: [...this.state.selectedIDs].filter(id => existsIds.indexOf(id) !== -1),
        })
    }

    refresh = () => {
        this.props.actions.fetchOffers()
    }

    resetFilters = () => {
        this.props.table.resetFilters()
    }

    isFilterActive = () => getActiveFilters(this.props.tableModel).size > 0

    componentDidMount() {
        this.props.actions.fetchOffers()
        this.props.actions.fetchPrerequisites()
    }

    componentWillReceiveProps = nextProps => {
        // Prepare declination reasons
        const transporeonDeclinationReasons = nextProps.prerequisites.values
            .get('transporeon_declination_reasons')
            .valueSeq()
            .map(prerequisite => ({
                id: prerequisite.get('id'),
                name: this.props.intl.formatMessage({ id: `modules.transporeonOffers.reason.${prerequisite.get('identifier')}` }),
            }))
            .sort((first, second) => first.id > second.id)
            .toJS()

        this.setState({
            transporeonDeclinationReasons,
            declineReasonID: transporeonDeclinationReasons.length > 0 ? transporeonDeclinationReasons[0].id : null,
        })

        // Progress handlers
        this.handleAcceptProgress(nextProps)
        this.handleDeclineProgress(nextProps)

        this.deselectNoExistsItems()
    }

    render() {
        document.title = formatters.titleFormatter(this.props.intl.formatMessage({ id: 'modules.transporeonOffers.heading' }))

        const filters = this.props.tableModel.get('filters')

        return (
            <PermissionsCheck hasAny={Object.values(PERMS)} noPermissionsPage>
                <div className="page-inner">
                    <div id="main-wrapper">
                        <div className="panel panel-white">
                            <div className="panel-body">
                                <h4>
                                    <FormattedMessage id="modules.transporeonOffers.heading" />
                                </h4>

                                <div className="btns-list">
                                    {this.isFilterActive() > 0 && (
                                        <button className="btn btn-default btn-addon m-r-xs" onClick={this.resetFilters}>
                                            <i className="far fa-times" /> <FormattedMessage id="buttons.resetFilters" />
                                        </button>
                                    )}
                                    <button className="btn btn-default btn-addon m-r-xs" onClick={this.refresh}>
                                        <i className="far fa-sync-alt" /> <FormattedMessage id="buttons.refresh" />
                                    </button>
                                    <PermissionsCheck has={[PERMS.UPDATE]}>
                                        {this.state.selectedIDs.length > 0 && (
                                            <button className="btn btn-success btn-addon m-r-xs" onClick={this.handleAcceptSelected}>
                                                <i className="far fa-check" /> <FormattedMessage id="buttons.acceptSelected" />
                                            </button>
                                        )}
                                        {this.state.selectedIDs.length > 0 && (
                                            <button className="btn btn-danger btn-addon m-r-xs" onClick={this.handleDeclineSelected}>
                                                <i className="far fa-ban" /> <FormattedMessage id="buttons.rejectSelected" />
                                            </button>
                                        )}
                                    </PermissionsCheck>
                                    <PermissionsCheck has={[PERMS.EXPORT]}>
                                        <button className="btn btn-primary btn-addon" onClick={this.handleExport}>
                                            <i className="far fa-file-excel" /> <FormattedMessage id="buttons.exportToXLS" />
                                        </button>
                                    </PermissionsCheck>
                                </div>

                                <div className="table-container">
                                    <table className="table table-striped table-hover table-fixed-header">
                                        <thead>
                                            <tr>
                                                <PermissionsCheck has={[PERMS.UPDATE]}>
                                                    <th className="w-30" />
                                                </PermissionsCheck>
                                                <PermissionsCheck has={[PERMS.UPDATE]}>
                                                    <th className="w-60" />
                                                </PermissionsCheck>
                                                <th className="w-100">
                                                    <FormattedMessage id="fields.transporeonOffersNumber" />
                                                </th>
                                                <th className="w-max wm-300">
                                                    <FormattedMessage id="fields.customer" />
                                                </th>
                                                <th className="w-200">
                                                    <FormattedMessage id="fields.loadingTime" />
                                                </th>
                                                <th className="w-300">
                                                    <FormattedMessage id="fields.loadingAddress" />
                                                </th>
                                                <th className="w-200">
                                                    <FormattedMessage id="fields.unloadingTime" />
                                                </th>
                                                <th className="w-300">
                                                    <FormattedMessage id="fields.unloadingAddress" />
                                                </th>
                                                <th className="w-100 text-right">
                                                    <FormattedMessage id="fields.weight" />
                                                </th>
                                                <th className="w-100 text-right">
                                                    <FormattedMessage id="fields.price" />
                                                </th>
                                            </tr>
                                            <tr className="filters">
                                                <PermissionsCheck has={[PERMS.UPDATE]}>
                                                    <th className="w-30 text-center">
                                                        <input type="checkbox" defaultChecked={false} onChange={this.handleSelectAll} />
                                                    </th>
                                                </PermissionsCheck>
                                                <PermissionsCheck has={[PERMS.UPDATE]}>
                                                    <th className="w-60" />
                                                </PermissionsCheck>
                                                <th className="w-100">
                                                    <TableFilterInputField
                                                        identifier="number"
                                                        type={TableModelColumnFilteringType.stringFromStart}
                                                        filters={filters}
                                                        onChange={this.props.table.changeFilter}
                                                    />
                                                </th>
                                                <th className="w-max wm-300">
                                                    <TableFilterInputField
                                                        identifier="company_name"
                                                        type={TableModelColumnFilteringType.stringFromStart}
                                                        filters={filters}
                                                        onChange={this.props.table.changeFilter}
                                                    />
                                                </th>
                                                <th className="w-200">
                                                    <div className="w-90 pull-left m-r-xxs">
                                                        <TableFilterInputField
                                                            identifier="start_date_from"
                                                            type={TableModelColumnFilteringType.string}
                                                            filters={filters}
                                                            onChange={this.props.table.changeFilter}
                                                            placeholder={this.props.intl.formatMessage({ id: 'fields.from' })}
                                                            datepicker
                                                        />
                                                    </div>
                                                    <div className="w-90 pull-left">
                                                        <TableFilterInputField
                                                            identifier="start_date_to"
                                                            type={TableModelColumnFilteringType.string}
                                                            filters={filters}
                                                            onChange={this.props.table.changeFilter}
                                                            placeholder={this.props.intl.formatMessage({ id: 'fields.to' })}
                                                            datepicker
                                                        />
                                                    </div>
                                                </th>
                                                <th className="w-300">
                                                    <TableFilterInputField
                                                        identifier="start_address"
                                                        type={TableModelColumnFilteringType.stringFromStart}
                                                        filters={filters}
                                                        onChange={this.props.table.changeFilter}
                                                    />
                                                </th>
                                                <th className="w-200">
                                                    <div className="w-90 pull-left m-r-xxs">
                                                        <TableFilterInputField
                                                            identifier="end_date_from"
                                                            type={TableModelColumnFilteringType.string}
                                                            filters={filters}
                                                            onChange={this.props.table.changeFilter}
                                                            placeholder={this.props.intl.formatMessage({ id: 'fields.from' })}
                                                            datepicker
                                                        />
                                                    </div>
                                                    <div className="w-90 pull-left">
                                                        <TableFilterInputField
                                                            identifier="end_date_to"
                                                            type={TableModelColumnFilteringType.string}
                                                            filters={filters}
                                                            onChange={this.props.table.changeFilter}
                                                            placeholder={this.props.intl.formatMessage({ id: 'fields.to' })}
                                                            datepicker
                                                        />
                                                    </div>
                                                </th>
                                                <th className="w-300">
                                                    <TableFilterInputField
                                                        identifier="end_address"
                                                        type={TableModelColumnFilteringType.stringFromStart}
                                                        filters={filters}
                                                        onChange={this.props.table.changeFilter}
                                                    />
                                                </th>
                                                <th className="w-100" />
                                                <th className="w-100" />
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.props.offers.data.valueSeq().map(row => (
                                                <OffersListRow
                                                    key={`OffersListRow-${row.id}`}
                                                    data={row}
                                                    isSelected={this.state.selectedIDs.indexOf(row.id) !== -1}
                                                    onAccept={this.handleAccept}
                                                    onDecline={this.handleDecline}
                                                    onSelect={this.handleSelect}
                                                    countrySelector={this.props.createGetCountryByIDSelector}
                                                    currencySelector={this.props.createGetCurrencyByIDSelector}
                                                />
                                            ))}
                                        </tbody>
                                    </table>
                                </div>

                                <div className="pull-left m-l-xxs m-t-md">
                                    <FormattedMessage id="pagination.totalRecords" />:{' '}
                                    {getListItemsCountMessage(
                                        clientSideItemsPerPage,
                                        this.props.offers.count,
                                        this.props.tableModel.getIn(['pagination', 'totalCount'])
                                    )}
                                </div>

                                <ReactPaginate
                                    containerClassName="pagination"
                                    breakLabel={<span className="disabled">...</span>}
                                    activeClassName="active"
                                    pageCount={getTotalNumberOfPages(this.props.tableModel, this.props.offers.count, clientSideItemsPerPage)}
                                    pageRangeDisplayed={10}
                                    marginPagesDisplayed={2}
                                    forcePage={this.props.tableModel.getIn(['pagination', 'current'])}
                                    onPageChange={this.props.table.changePage}
                                    previousLabel={this.props.intl.formatMessage({ id: 'pagination.previous' })}
                                    nextLabel={this.props.intl.formatMessage({ id: 'pagination.next' })}
                                />
                            </div>
                        </div>
                    </div>

                    <Modal show={this.state.acceptItemIDs.length > 0} onHide={this.handleAcceptCancellation} bsSize="sm">
                        <Modal.Header closeButton>
                            <Modal.Title>
                                <FormattedMessage id="modules.transporeonOffers.accept.title" />
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <FormattedMessage id="modules.transporeonOffers.accept.message" />
                        </Modal.Body>
                        <Modal.Footer>
                            <button className="btn btn-success" onClick={this.handleAcceptConfirmation}>
                                <FormattedMessage id="buttons.accept" />
                            </button>
                            <button className="btn btn-default" onClick={this.handleAcceptCancellation}>
                                <FormattedMessage id="buttons.cancel" />
                            </button>
                        </Modal.Footer>
                    </Modal>

                    <Modal show={this.state.declineItemIDs.length > 0} onHide={this.handleDeclineCancellation} bsSize="sm">
                        <form onSubmit={this.handleDeclineConfirmation}>
                            <Modal.Header closeButton>
                                <Modal.Title>
                                    <FormattedMessage id="modules.transporeonOffers.reject.title" />
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <FormattedMessage id="modules.transporeonOffers.reject.message" />
                                <br />
                                <br />
                                <SimpleSelectField
                                    id="declination_reasons"
                                    label={this.props.intl.formatMessage({ id: 'fields.reason' })}
                                    onChange={e => {
                                        this.handleDeclineReasonChange(e)
                                    }}
                                    values={this.state.transporeonDeclinationReasons}
                                    value={this.state.declineReason}
                                    className="form-control wp-100"
                                />
                            </Modal.Body>
                            <Modal.Footer>
                                <button className="btn btn-danger">
                                    <FormattedMessage id="buttons.reject" />
                                </button>
                                <button className="btn btn-default" onClick={this.handleDeclineCancellation}>
                                    <FormattedMessage id="buttons.cancel" />
                                </button>
                            </Modal.Footer>
                        </form>
                    </Modal>
                </div>
            </PermissionsCheck>
        )
    }
}

function mapStateToProps(state) {
    return {
        offers: createTableDataSelector({
            tableIdentifier,
            columns,
            dataSelector: getOffers,
            clientSideItemsPerPage,
        })(state),
        tableModel: createTableModelSelector(tableIdentifier)(state),
        offersLoading: getOffersLoading(state),
        acceptInProgress: getAcceptInProgress(state),
        acceptError: getAcceptError(state),
        declineInProgress: getDeclineInProgress(state),
        declineError: getDeclineError(state),
        createGetCountryByIDSelector: id => createGetCountryByIDSelector(id)(state),
        createGetCurrencyByIDSelector: id => createGetCurrencyByIDSelector(id)(state),
        prerequisites: preparePrerequisitesFetchStatusSelectors(componentIdentifier, prerequisites, state),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            ...bindActionCreators(
                {
                    ...offersActionCreators,
                    ...preparePrerequisitesActionCreators(componentIdentifier, prerequisites),
                },
                dispatch
            ),
        },
        notify: (notification, type) => dispatch(Notifications.show(notification, type)),
        dispatch,
    }
}

function mergeProps(stateProps, dispatchProps, ownProps) {
    return {
        ...stateProps,
        ...dispatchProps,
        ...ownProps,
        table: bindActionCreators(prepareTableActionCreators(tableIdentifier, stateProps.tableModel), dispatchProps.dispatch),
    }
}

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps,
        mergeProps
    )(OffersList)
)
