import { Map } from 'immutable'

import * as actionTypes from './actionTypes'

const createInitialPrerequisites = () =>
    Map({
        fetching: false,
        fetched: false,
        error: null,
    })

const initialState = Map({
    cargo_types_edit: createInitialPrerequisites(),
    companies_edit: createInitialPrerequisites(),
    companies_list: createInitialPrerequisites(),
    countries_edit: createInitialPrerequisites(),
    countries_list: createInitialPrerequisites(),
    currencies_edit: createInitialPrerequisites(),
    trailer_types_edit: createInitialPrerequisites(),
    transport_types_edit: createInitialPrerequisites(),
    vehicle_types_edit: createInitialPrerequisites(),
    carriers_edit: createInitialPrerequisites(),
    carriers_list: createInitialPrerequisites(),
    carrier_contacts_edit: createInitialPrerequisites(),
    carrier_contacts_list: createInitialPrerequisites(),
    customers_edit: createInitialPrerequisites(),
    customers_list: createInitialPrerequisites(),
    drivers_edit: createInitialPrerequisites(),
    drivers_list: createInitialPrerequisites(),
    trailers_edit: createInitialPrerequisites(),
    trailers_list: createInitialPrerequisites(),
    vehicles_edit: createInitialPrerequisites(),
    vehicles_list: createInitialPrerequisites(),
    customer_addresses_edit: createInitialPrerequisites(),
    customer_addresses_list: createInitialPrerequisites(),
    customer_contacts_edit: createInitialPrerequisites(),
    customer_contacts_list: createInitialPrerequisites(),
    customer_plans_edit: createInitialPrerequisites(),
    customer_plans_list: createInitialPrerequisites(),
    map_page: createInitialPrerequisites(),
    transports_edit: createInitialPrerequisites(),
    transports_list: createInitialPrerequisites(),
    surcharge_types_edit: createInitialPrerequisites(),
    customer_address_types_edit: createInitialPrerequisites(),
    values: Map(),
    vehicle_events_edit: createInitialPrerequisites(),
    vehicle_events_list: createInitialPrerequisites(),
    goods_types_edit: createInitialPrerequisites(),
    document_types_edit: createInitialPrerequisites(),
    document_types_list: createInitialPrerequisites(),
    driver_documents_edit: createInitialPrerequisites(),
    driver_documents_list: createInitialPrerequisites(),
    vehicle_documents_edit: createInitialPrerequisites(),
    vehicle_documents_list: createInitialPrerequisites(),
    trailer_documents_edit: createInitialPrerequisites(),
    trailer_documents_list: createInitialPrerequisites(),
    company_cost_centers_edit: createInitialPrerequisites(),
    company_cost_centers_list: createInitialPrerequisites(),
    company_bank_accounts_edit: createInitialPrerequisites(),
    company_bank_accounts_list: createInitialPrerequisites(),
    company_numberings_edit: createInitialPrerequisites(),
    company_numberings_list: createInitialPrerequisites(),
    equipments_edit: createInitialPrerequisites(),
    equipments_list: createInitialPrerequisites(),
    driver_equipments_edit: createInitialPrerequisites(),
    driver_equipments_list: createInitialPrerequisites(),
    vehicle_equipments_edit: createInitialPrerequisites(),
    vehicle_equipments_list: createInitialPrerequisites(),
    trailer_equipments_edit: createInitialPrerequisites(),
    trailer_equipments_list: createInitialPrerequisites(),
    driver_timesheets_list: createInitialPrerequisites(),
    driver_timesheets_edit: createInitialPrerequisites(),
    report_transport_invoicing: createInitialPrerequisites(),
    report_transport_changes: createInitialPrerequisites(),
    report_transports: createInitialPrerequisites(),
    report_spedition_transports: createInitialPrerequisites(),
    report_vehicle_kilometers_total: createInitialPrerequisites(),
    report_vehicle_traffic: createInitialPrerequisites(),
    report_vehicle_performance: createInitialPrerequisites(),
    report_driver_timesheets: createInitialPrerequisites(),
    report_vehicle_fuelings: createInitialPrerequisites(),
    report_cost_center_performance: createInitialPrerequisites(),
    report_cost_center_profits: createInitialPrerequisites(),
    tax_rates_edit: createInitialPrerequisites(),
    tax_rates_list: createInitialPrerequisites(),
    payment_methods_edit: createInitialPrerequisites(),
    payment_methods_list: createInitialPrerequisites(),
    invoicing_orders_list: createInitialPrerequisites(),
    invoicing_invoices_list: createInitialPrerequisites(),
    invoicing_invoices_edit: createInitialPrerequisites(),
    packaging_types_edit: createInitialPrerequisites(),
    transports_goods: createInitialPrerequisites(),
    transports_parts: createInitialPrerequisites(),
    transport_conditions_edit: createInitialPrerequisites(),
    transports_shipping_order: createInitialPrerequisites(),
    invoice_item_types_edit: createInitialPrerequisites(),
    create_invoice_form: createInitialPrerequisites(),
    invoicing_received_invoices_list: createInitialPrerequisites(),
    invoicing_received_invoices_edit: createInitialPrerequisites(),
    users_edit: createInitialPrerequisites(),
    user_roles_edit: createInitialPrerequisites(),
    blocked_ips_edit: createInitialPrerequisites(),
    report_driver_allowances: createInitialPrerequisites(),
    drivers_allowances: createInitialPrerequisites(),
    transport_allowances: createInitialPrerequisites(),
    transport_template_allowances: createInitialPrerequisites(),
    companies_bank_accounts_list: createInitialPrerequisites(),
    report_transport_orders: createInitialPrerequisites(),
    report_customer_plans: createInitialPrerequisites(),
    report_customer_monthly_plans: createInitialPrerequisites(),
    report_customer_weekly_plans: createInitialPrerequisites(),
    report_carrier_results: createInitialPrerequisites(),
    border_crossings_list: createInitialPrerequisites(),
    border_crossings_edit: createInitialPrerequisites(),
    tracing: createInitialPrerequisites(),
    diet_rates_list: createInitialPrerequisites(),
    costs_edit: createInitialPrerequisites(),
    costs_list: createInitialPrerequisites(),
    cost_generators_edit: createInitialPrerequisites(),
    cost_generators_list: createInitialPrerequisites(),
    report_vehicle_results: createInitialPrerequisites(),
    report_driver_results: createInitialPrerequisites(),
    report_trailer_results: createInitialPrerequisites(),
    report_trailer_fuelings: createInitialPrerequisites(),
    report_vehicle_profits: createInitialPrerequisites(),
    report_cost_type_costs: createInitialPrerequisites(),
    sales_edit: createInitialPrerequisites(),
    sales_list: createInitialPrerequisites(),
})

function prerequisites(state = initialState, action) {
    switch (action.type) {
        case actionTypes.FETCH_STARTED:
            return state
                .setIn([action.payload.identifier, 'fetching'], true)
                .setIn([action.payload.identifier, 'error'], null)
                .setIn([action.payload.identifier, 'fetched'], false)
        case actionTypes.FETCH_FULFILLED:
            return state
                .setIn([action.payload.identifier, 'fetching'], false)
                .setIn([action.payload.identifier, 'fetched'], true)
                .mergeDeep(
                    Map({
                        values: action.payload.prerequisites,
                    })
                )
        case actionTypes.FETCH_REJECTED:
            return state.setIn([action.payload.identifier, 'fetching'], false).setIn([action.payload.identifier, 'error'], action.payload)
        default:
            return state
    }
}

export default prerequisites
