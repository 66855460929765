import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { push } from 'react-router-redux'
import Notifications from 'react-notification-system-redux'
import { Modal, Tabs, Tab } from 'react-bootstrap'

import { ConfirmDialog } from '../../../common/confirm_dialog'
import * as formatters from '../../../common/formatters'
import { Form, InputField, SelectField, CheckboxField, SubmitButton } from '../../../common/form'
import { url, handleCommonEditActions, getPrerequisitesArray } from '../../../common/helpers'
import { LoadingOverlay } from '../../../common/loading_overlay'

import { preparePrerequisitesActionCreators } from '../../../common/prerequisites/actionCreators'
import { preparePrerequisitesFetchStatusSelectors } from '../../../common/prerequisites/selectors'

import * as trailersActionCreators from '../actionCreators'
import { createGetTrailerByIDSelector, createGetCurrentTrailerStatusSelector, createGetDuplicitySelector } from '../selectors'

import TrailersDocumentsList from './TrailersDocumentsList'
import TrailersEquipmentsList from './TrailersEquipmentsList'
import TrailersEventsList from './TrailersEventsList'
import TrailersHistoryList from './TrailersHistoryList'
import PermissionsCheck from '../../auth/components/PermissionsCheck'
import { PERMS } from '../permissions'

const componentIdentifier = 'trailers_edit'
const prerequisites = ['trailerTypes', 'companyCostCenters', 'carriers']

class TrailersEdit extends Component {
    defaultValues = {
        is_spedition: false,
    }

    validationRules = {}

    state = {
        values: this.defaultValues,
        modalDuplicity: {
            show: false,
            duplicity: null,
        },
        trailerHistory: {
            show: true,
        },
        trailerDocuments: {
            show: false,
        },
        trailerEquipments: {
            show: false,
        },
        trailerEvents: {
            show: false,
        },
        confirmDialog: {
            show: false,
            title: '',
            message: '',
            labelCancel: '',
            labelAccept: '',
            classNameAccept: 'text-success',
            classNameCancel: 'text-default',
            onCancel: () => {},
            onAccept: () => {},
        },
    }

    setValues = (values, callback) => {
        this.setState(
            {
                values,
            },
            callback
        )
    }

    handleChangeValues = values => {
        this.setValues(values)
    }

    handleSubmit = values => {
        this.setValues(values, () => {
            this.save(false)
        })
    }

    save = ignoreDuplicity => {
        this.props.actions.saveTrailer(this.state.values, ignoreDuplicity)
    }

    duplicityFoundCallback = duplicity => {
        this.setState({
            modalDuplicity: {
                show: true,
                duplicity,
            },
        })
    }

    handleDuplicityCancel = () => {
        this.setState({
            modalDuplicity: {
                show: false,
                duplicity: null,
            },
        })
    }

    handleDuplicityConfirm = () => {
        this.handleDuplicityCancel()
        this.save(true)
    }

    handleSelectTab = tabKey => {
        if (tabKey === 1) {
            this.loadTrailerHistory()
        } else if (tabKey === 2) {
            this.loadTrailerDocuments()
        } else if (tabKey === 3) {
            this.loadTrailerEquipments()
        } else if (tabKey === 4) {
            this.loadTrailerEvents()
        }
    }

    loadTrailerHistory = () => {
        this.setState({
            trailerHistory: {
                show: true,
            },
        })
    }

    loadTrailerDocuments = () => {
        this.setState({
            trailerDocuments: {
                show: true,
            },
        })
    }

    loadTrailerEquipments = () => {
        this.setState({
            trailerEquipments: {
                show: true,
            },
        })
    }

    loadTrailerEvents = () => {
        this.setState({
            trailerEvents: {
                show: true,
            },
        })
    }

    successCallback = null

    handleSave = () => {
        this.successCallback = (props, nextProps) => {
            if (nextProps.status.get('id')) {
                props.redirect(url(props.match, `trailers/${nextProps.status.get('id')}`))
            } else {
                props.redirect(url(props.match, 'trailers'))
            }
        }

        this.save()
    }

    handleSaveAndClose = () => {
        this.successCallback = props => {
            props.redirect(url(props.match, 'trailers'))
        }

        this.save()
    }

    // restore
    handleRestore = () => {
        this.showConfirmDialog({
            title: this.props.intl.formatMessage({ id: 'confirmDialog.itemRestore.title' }),
            message: this.props.intl.formatMessage({ id: 'confirmDialog.itemRestore.message' }),
            labelCancel: this.props.intl.formatMessage({ id: 'confirmDialog.itemRestore.cancel' }),
            labelAccept: this.props.intl.formatMessage({ id: 'confirmDialog.itemRestore.accept' }),
            classNameAccept: 'btn-success',
            onAccept: () => {
                this.props.actions.restoreTrailer(this.props.trailer.id)
                this.hideConfirmDialog()
            },
        })
    }

    // confirm dialog
    showConfirmDialog = options => {
        this.setState({
            confirmDialog: {
                show: true,
                title: options.title || this.props.intl.formatMessage({ id: 'confirmDialog.default.title' }),
                message: options.message || this.props.intl.formatMessage({ id: 'confirmDialog.default.message' }),
                labelCancel: options.labelCancel || this.props.intl.formatMessage({ id: 'confirmDialog.default.cancel' }),
                labelAccept: options.labelAccept || this.props.intl.formatMessage({ id: 'confirmDialog.default.accept' }),
                classNameAccept: options.classNameAccept || 'btn-success',
                classNameCancel: options.classNameCancel || 'btn-default',
                onCancel: options.onCancel || this.hideConfirmDialog,
                onAccept: options.onAccept || this.hideConfirmDialog,
            },
        })
    }

    hideConfirmDialog = () => {
        this.setState({
            confirmDialog: {
                show: false,
                onCancel: () => {},
                onAccept: () => {},
            },
        })
    }

    componentDidMount() {
        this.props.match.params.trailerID && this.props.actions.fetchTrailer(this.props.match.params.trailerID)
        this.props.actions.fetchPrerequisites()
    }

    componentWillReceiveProps(nextProps) {
        handleCommonEditActions(this.props, nextProps, this.successCallback, this.duplicityFoundCallback)

        if (JSON.stringify(nextProps.trailer) !== JSON.stringify(this.props.trailer) || (nextProps.trailer && !this.state.values.id)) {
            this.setValues(nextProps.trailer.toJS())
        }
    }

    render() {
        document.title = formatters.titleFormatter(this.props.intl.formatMessage({ id: 'modules.trailers.heading' }))

        const trailer = this.props.trailer
        const duplicity = this.props.duplicity

        const mainColClassName = trailer ? 'col-lg-4' : 'col-md-8 col-md-offset-2 col-lg-6 col-lg-offset-3 col-xl-4 col-xl-offset-4'

        return (
            <PermissionsCheck hasAny={Object.values(PERMS)} noPermissionsPage>
                <div className="page-inner">
                    <div id="main-wrapper">
                        <div className="row hp-100">
                            <div className={mainColClassName} style={{ paddingRight: '0' }}>
                                <div className="panel panel-white">
                                    <div className="panel-body panel-padding">
                                        <div className="panel-head">
                                            <h4>
                                                <FormattedMessage id="modules.trailers.heading" /> -{' '}
                                                {this.props.trailer ? (
                                                    <FormattedMessage id="fields.itemEdit" />
                                                ) : (
                                                    <FormattedMessage id="fields.itemCreate" />
                                                )}
                                            </h4>
                                        </div>
                                        <LoadingOverlay
                                            active={
                                                this.props.prerequisites.status.get('fetching') ||
                                                this.props.status.get('fetching') ||
                                                this.props.status.get('saving')
                                            }
                                        >
                                            <PermissionsCheck hasAny={Object.values(PERMS)}>
                                                <Form
                                                    values={this.state.values}
                                                    validationRules={this.validationRules}
                                                    onChange={this.handleChangeValues}
                                                    onSubmit={this.handleSubmit}
                                                    isEdit={Boolean(this.props.trailer)}
                                                >

                                                    {trailer && trailer.deleted_at && (
                                                        <div className="row">
                                                            <div className="col-lg-12">
                                                                <div className="alert alert-danger m-b-lg">
                                                                    <div className="row">
                                                                        <div className="col-sm-8">
                                                                            <p className="no-m">
                                                                                <FormattedMessage id="alerts.messages.warningDeleted" />
                                                                            </p>
                                                                        </div>
                                                                        <PermissionsCheck has={[PERMS.RESTORE]}>
                                                                            <div className="col-sm-4 text-right">
                                                                                <button onClick={this.handleRestore} type="button" className="btn btn-danger">
                                                                                    <FormattedMessage id="buttons.restore" />
                                                                                </button>
                                                                            </div>
                                                                        </PermissionsCheck>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}

                                                    <div className="row">
                                                        <div className="col-sm-6">
                                                            <InputField
                                                                id="registration"
                                                                label={this.props.intl.formatMessage({ id: 'fields.registration' })}
                                                                formGroupClassName="form-group-bigger"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-6 col-lg-12 col-xl-6">
                                                            <InputField id="vin" label={this.props.intl.formatMessage({ id: 'fields.vin' })} />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-6 col-lg-12 col-xl-6">
                                                            <SelectField
                                                                id="trailer_type_id"
                                                                label={this.props.intl.formatMessage({ id: 'fields.trailerType' })}
                                                                values={getPrerequisitesArray(this.props.prerequisites.values.get('trailerTypes'))}
                                                            />
                                                        </div>
                                                        <div className="col-sm-6 col-lg-12 col-xl-6">
                                                            <SelectField
                                                                id="company_cost_center_id"
                                                                label={this.props.intl.formatMessage({ id: 'fields.costCenter' })}
                                                                values={getPrerequisitesArray(
                                                                    this.props.prerequisites.values.get('companyCostCenters')
                                                                )}
                                                            />
                                                        </div>
                                                    </div>
                                                    <CheckboxField id="is_active" label={this.props.intl.formatMessage({ id: 'fields.active' })} />
                                                    <CheckboxField id="is_damage" label={this.props.intl.formatMessage({ id: 'fields.damaged' })} />
                                                    <CheckboxField
                                                        id="is_security_damage"
                                                        label={this.props.intl.formatMessage({ id: 'fields.securityDamaged' })}
                                                    />
                                                    <CheckboxField
                                                        id="is_cooling_damage"
                                                        label={this.props.intl.formatMessage({ id: 'fields.coolingDamaged' })}
                                                    />
                                                    <div className="form-part">
                                                        <CheckboxField
                                                            id="is_spedition"
                                                            label={this.props.intl.formatMessage({ id: 'fields.isSpedition' })}
                                                        />
                                                        {Boolean(this.state.values.is_spedition) && (
                                                            <SelectField
                                                                id="carrier_id"
                                                                label={this.props.intl.formatMessage({ id: 'fields.carrier' })}
                                                                values={getPrerequisitesArray(this.props.prerequisites.values.get('carriers'))}
                                                            />
                                                        )}
                                                    </div>

                                                    <InputField
                                                        id="current_position"
                                                        label={this.props.intl.formatMessage({ id: 'fields.currentPosition' })}
                                                    />

                                                    <div className="btns-form">
                                                        <Link to={url(this.props.match, 'trailers')} className="btn btn-addon btn-default">
                                                            <i className="far fa-chevron-left" /> <FormattedMessage id="buttons.back" />
                                                        </Link>
                                                        {trailer && (
                                                            <SubmitButton
                                                                saveAndClose
                                                                onClick={this.handleSaveAndClose}
                                                                isEdit={Boolean(this.props.trailer)}
                                                                perms={[PERMS.CREATE, PERMS.UPDATE]}
                                                                className="btn-addon pull-right"
                                                            />
                                                        )}
                                                        <SubmitButton
                                                            type="button"
                                                            onClick={this.handleSave}
                                                            isEdit={Boolean(this.props.trailer)}
                                                            perms={[PERMS.CREATE, PERMS.UPDATE]}
                                                            className="btn-addon pull-right m-r-xs"
                                                        />
                                                    </div>
                                                </Form>
                                            </PermissionsCheck>
                                        </LoadingOverlay>
                                    </div>
                                </div>
                            </div>

                            {trailer && (
                                <div className="col-lg-8 hp-100" style={{ paddingLeft: '5px' }}>
                                    <div className="panel panel-white hp-100 m-b-no">
                                        <div className="panel-body panel-padding hp-100">
                                            <Tabs
                                                defaultActiveKey={1}
                                                animation={false}
                                                id="detail-tabs"
                                                className="detail-tabs"
                                                onSelect={this.handleSelectTab}
                                            >
                                                <Tab
                                                    eventKey={1}
                                                    title={
                                                        <span>
                                                            <i className="far fa-history m-r-xxs" />
                                                            {this.props.intl.formatMessage({ id: 'fields.history' })}
                                                        </span>
                                                    }
                                                >
                                                    {this.state.trailerHistory.show && <TrailersHistoryList trailer={trailer} />}
                                                </Tab>
                                                <Tab
                                                    eventKey={2}
                                                    title={
                                                        <span>
                                                            <i className="far fa-file-alt m-r-xxs" />
                                                            {this.props.intl.formatMessage({ id: 'fields.documents' })}
                                                        </span>
                                                    }
                                                >
                                                    {this.state.trailerDocuments.show && <TrailersDocumentsList trailer={trailer} />}
                                                </Tab>
                                                <Tab
                                                    eventKey={3}
                                                    title={
                                                        <span>
                                                            <i className="far fa-wrench m-r-xxs" />
                                                            {this.props.intl.formatMessage({ id: 'fields.equipment' })}
                                                        </span>
                                                    }
                                                >
                                                    {this.state.trailerEquipments.show && <TrailersEquipmentsList trailer={trailer} />}
                                                </Tab>
                                                <Tab
                                                    eventKey={4}
                                                    title={
                                                        <span>
                                                            <i className="far fa-calendar-alt m-r-xxs" />
                                                            {this.props.intl.formatMessage({ id: 'fields.events' })}
                                                        </span>
                                                    }
                                                >
                                                    {this.state.trailerEvents.show && <TrailersEventsList trailer={trailer} />}
                                                </Tab>
                                            </Tabs>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>

                    <Modal show={Boolean(this.state.modalDuplicity.show)} onHide={this.handleDuplicityCancel} bsSize="sm">
                        <Modal.Header closeButton>
                            <Modal.Title>
                                <FormattedMessage id="confirmDialog.duplicityFound.title" />
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <FormattedMessage id="confirmDialog.duplicityFound.message" />
                            {duplicity && (
                                <div>
                                    <div className="row m-t-lg">
                                        <div className="col-sm-4">
                                            <FormattedMessage id="fields.registration" />:
                                        </div>
                                        <div className="col-sm-8 text-right">
                                            <strong>{duplicity.registration}</strong>
                                        </div>
                                    </div>
                                    <hr className="tiny" />
                                    <div className="row">
                                        <div className="col-sm-4">
                                            <FormattedMessage id="fields.vin" />:
                                        </div>
                                        <div className="col-sm-8 text-right">
                                            <strong>{duplicity.vin}</strong>
                                        </div>
                                    </div>
                                    <hr className="tiny" />
                                    <div className="row">
                                        <div className="col-sm-4">
                                            <FormattedMessage id="fields.phone" />:
                                        </div>
                                        <div className="col-sm-8 text-right">
                                            <strong>{duplicity.phone}</strong>
                                        </div>
                                    </div>
                                    <hr className="tiny" />
                                    <div className="row">
                                        <div className="col-sm-4">
                                            <FormattedMessage id="fields.trailerType" />:
                                        </div>
                                        <div className="col-sm-8 text-right">
                                            <strong>{duplicity.type}</strong>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </Modal.Body>
                        <Modal.Footer>
                            <PermissionsCheck has={[PERMS.CREATE, PERMS.UPDATE]}>
                                <button className="btn btn-success m-r-xs" onClick={this.handleDuplicityConfirm}>
                                    <FormattedMessage id="confirmDialog.duplicityFound.accept" />
                                </button>
                            </PermissionsCheck>
                            <button className="btn btn-default" onClick={this.handleDuplicityCancel}>
                                <FormattedMessage id="confirmDialog.duplicityFound.cancel" />
                            </button>
                        </Modal.Footer>
                    </Modal>
                </div>
                <ConfirmDialog options={this.state.confirmDialog} />
            </PermissionsCheck>
        )
    }
}

function mapStateToProps(state, ownProps) {
    return {
        trailer: createGetTrailerByIDSelector(ownProps.match.params.trailerID)(state),
        status: createGetCurrentTrailerStatusSelector()(state),
        duplicity: createGetDuplicitySelector()(state),
        prerequisites: preparePrerequisitesFetchStatusSelectors(componentIdentifier, prerequisites, state),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            ...bindActionCreators(
                {
                    ...trailersActionCreators,
                    ...preparePrerequisitesActionCreators(componentIdentifier, prerequisites),
                },
                dispatch
            ),
        },
        redirect: toURL => dispatch(push(toURL)),
        notify: (notification, type) => dispatch(Notifications.show(notification, type)),
    }
}

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(TrailersEdit)
)
