import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { push } from 'react-router-redux'
import ReactPaginate from 'react-paginate'
import Notifications from 'react-notification-system-redux'

import { ConfirmDialog } from '../../../common/confirm_dialog'
import * as formatters from '../../../common/formatters'
import { url, handleCommonListActions, getListItemsCountMessage, getPrerequisitesArray } from '../../../common/helpers'

import { prepareTableActionCreators } from '../../../common/table/actionCreators'
import {
    TableSortButtons,
    TableFilterInputField,
    TableFilterSelectField,
    TableFilterBooleanField,
    TableFilterCheckboxField
} from '../../../common/table/components'
import { getActiveFilters, getTotalNumberOfPages } from '../../../common/table/helpers'
import { TableModelColumn, TableModelColumnFilteringType } from '../../../common/table/model'
import { createTableDataSelector, createTableModelSelector } from '../../../common/table/selectors'

import { preparePrerequisitesActionCreators } from '../../../common/prerequisites/actionCreators'
import { preparePrerequisitesFetchStatusSelectors } from '../../../common/prerequisites/selectors'

import * as driversActionCreators from '../actionCreators'
import { getDrivers, getDriverDeleting, getDriverDeleteError } from '../selectors'
import { createGetDriversTypeByIDSelector } from '../../drivers_types/selectors'
import { createGetUserByIDSelector } from '../../users/selectors'

import DriversListRow from './DriversListRow'
import PermissionsCheck from '../../auth/components/PermissionsCheck'
import { PERMS } from '../permissions'

const tableIdentifier = 'drivers_list'
const clientSideItemsPerPage = undefined

const componentIdentifier = 'drivers_list'
const prerequisites = ['driversTypes', 'driverLocales', 'dispatchers']

const columns = {
    fb_id: new TableModelColumn({}),
    first_name: new TableModelColumn({}),
    last_name: new TableModelColumn({}),
    phone: new TableModelColumn({}),
    locale: new TableModelColumn({}),
}

class DriversList extends Component {
    state = {
        confirmDialog: {
            show: false,
            title: '',
            message: '',
            labelCancel: '',
            labelAccept: '',
            classNameAccept: 'text-success',
            classNameCancel: 'text-default',
            onCancel: () => {},
            onAccept: () => {},
        },
    }

    // confirm dialog
    showConfirmDialog = options => {
        this.setState({
            confirmDialog: {
                show: true,
                title: options.title || this.props.intl.formatMessage({ id: 'confirmDialog.default.title' }),
                message: options.message || this.props.intl.formatMessage({ id: 'confirmDialog.default.message' }),
                labelCancel: options.labelCancel || this.props.intl.formatMessage({ id: 'confirmDialog.default.cancel' }),
                labelAccept: options.labelAccept || this.props.intl.formatMessage({ id: 'confirmDialog.default.accept' }),
                classNameAccept: options.classNameAccept || 'btn-success',
                classNameCancel: options.classNameCancel || 'btn-default',
                onCancel: options.onCancel || this.hideConfirmDialog,
                onAccept: options.onAccept || this.hideConfirmDialog,
            },
        })
    }

    hideConfirmDialog = () => {
        this.setState({
            confirmDialog: {
                show: false,
                onCancel: () => {},
                onAccept: () => {},
            },
        })
    }

    // handlers
    onClick = itemId => {
        this.props.redirect(`/drivers/${itemId}`)
    }

    onDelete = itemId => {
        this.showConfirmDialog({
            title: this.props.intl.formatMessage({ id: 'confirmDialog.delete.title' }),
            message: this.props.intl.formatMessage({ id: 'confirmDialog.delete.message' }),
            labelCancel: this.props.intl.formatMessage({ id: 'confirmDialog.delete.cancel' }),
            labelAccept: this.props.intl.formatMessage({ id: 'confirmDialog.delete.accept' }),
            classNameAccept: 'btn-danger',
            onAccept: () => {
                this.props.actions.deleteDriver(itemId)
                this.hideConfirmDialog()
            },
        })
    }

    handleExport = e => {
        e && e.preventDefault()
        this.props.actions.exportDrivers(this.props.tableModel.get('filters').toJS(), this.props.tableModel.get('sorting').toJS())
    }

    refresh = () => {
        this.props.actions.fetchDrivers()
    }

    resetFilters = () => {
        this.props.table.resetFilters()
    }

    isFilterActive = () => getActiveFilters(this.props.tableModel).size > 0

    componentDidMount() {
        this.props.actions.fetchDrivers()
        this.props.actions.fetchPrerequisites()
    }

    componentWillReceiveProps(nextProps) {
        handleCommonListActions(this.props, nextProps)
    }

    render() {
        document.title = formatters.titleFormatter(this.props.intl.formatMessage({ id: 'modules.drivers.heading' }))

        const sorting = this.props.tableModel.get('sorting')
        const filters = this.props.tableModel.get('filters')

        return (
            <PermissionsCheck hasAny={Object.values(PERMS)} noPermissionsPage>
                <div className="page-inner">
                    <div id="main-wrapper">
                        <div className="panel panel-white">
                            <div className="panel-body">
                                <h4>
                                    <FormattedMessage id="modules.drivers.heading" />
                                </h4>

                                <div className="pull-left" style={{ marginLeft: '100px' }}>
                                    <TableFilterCheckboxField
                                        identifier="with_trashed"
                                        type={TableModelColumnFilteringType.equal}
                                        filters={filters}
                                        onChange={this.props.table.changeFilter}
                                        label={this.props.intl.formatMessage({ id: 'modules.transports.showWithTrashed' })}
                                    />
                                </div>

                                <div className="pull-left" style={{ marginLeft: '20px' }}>
                                    <TableFilterCheckboxField
                                        identifier="only_trashed"
                                        type={TableModelColumnFilteringType.equal}
                                        filters={filters}
                                        onChange={this.props.table.changeFilter}
                                        label={this.props.intl.formatMessage({ id: 'modules.transports.showOnlyTrashed' })}
                                    />
                                </div>

                                <div className="btns-list">
                                    {this.isFilterActive() > 0 && (
                                        <button className="btn btn-default btn-addon m-r-xs" onClick={this.resetFilters}>
                                            <i className="far fa-times" /> <FormattedMessage id="buttons.resetFilters" />
                                        </button>
                                    )}
                                    <button className="btn btn-default btn-addon m-r-xs" onClick={this.refresh}>
                                        <i className="far fa-sync-alt" /> <FormattedMessage id="buttons.refresh" />
                                    </button>
                                    <PermissionsCheck has={[PERMS.EXPORT]}>
                                        <button className="btn btn-primary btn-addon m-r-xs" onClick={this.handleExport}>
                                            <i className="far fa-file-excel" /> <FormattedMessage id="buttons.exportToXLS" />
                                        </button>
                                    </PermissionsCheck>
                                    <PermissionsCheck has={[PERMS.CREATE]}>
                                        <Link to={url(this.props.match, 'drivers/new')} className="btn btn-addon btn-success">
                                            <i className="far fa-plus" /> <FormattedMessage id="buttons.createItem" />
                                        </Link>
                                    </PermissionsCheck>
                                </div>

                                <div className="table-container">
                                    <table className="table table-striped table-hover table-fixed-header">
                                        <thead>
                                            <tr>
                                                <PermissionsCheck hasAny={[PERMS.UPDATE, PERMS.DELETE]}>
                                                    <th className="w-60" />
                                                </PermissionsCheck>
                                                <th className="w-160">
                                                    <FormattedMessage id="fields.lastName" />
                                                    <TableSortButtons
                                                        identifier="last_name"
                                                        sorting={sorting}
                                                        onChange={this.props.table.changeSorting}
                                                    />
                                                </th>
                                                <th className="w-160">
                                                    <FormattedMessage id="fields.firstName" />
                                                    <TableSortButtons
                                                        identifier="first_name"
                                                        sorting={sorting}
                                                        onChange={this.props.table.changeSorting}
                                                    />
                                                </th>
                                                <th className="w-120 text-center">
                                                    <FormattedMessage id="fields.type" />
                                                </th>
                                                <th className="w-100">
                                                    <FormattedMessage id="fields.code" />
                                                </th>
                                                <th className="w-180">
                                                    <FormattedMessage id="fields.cardNumber" />
                                                </th>
                                                <th className="w-140">
                                                    <FormattedMessage id="fields.dispatcher" />
                                                </th>
                                                <th className="w-max wm-200">
                                                    <FormattedMessage id="fields.phone" />
                                                </th>
                                                <th className="w-100 text-center">
                                                    <FormattedMessage id="fields.language" />
                                                </th>
                                                <th className="w-80 text-center">
                                                    <FormattedMessage id="fields.active" />
                                                </th>
                                            </tr>
                                            <tr className="filters">
                                                <PermissionsCheck hasAny={[PERMS.UPDATE, PERMS.DELETE]}>
                                                    <th className="w-60" />
                                                </PermissionsCheck>
                                                <th className="w-160">
                                                    <TableFilterInputField
                                                        identifier="last_name"
                                                        type={TableModelColumnFilteringType.string}
                                                        filters={filters}
                                                        onChange={this.props.table.changeFilter}
                                                    />
                                                </th>
                                                <th className="w-160">
                                                    <TableFilterInputField
                                                        identifier="first_name"
                                                        type={TableModelColumnFilteringType.string}
                                                        filters={filters}
                                                        onChange={this.props.table.changeFilter}
                                                    />
                                                </th>
                                                <th className="w-120">
                                                    <TableFilterSelectField
                                                        identifier="drivers_type_id"
                                                        type={TableModelColumnFilteringType.equal}
                                                        filters={filters}
                                                        onChange={this.props.table.changeFilter}
                                                        values={getPrerequisitesArray(
                                                            this.props.prerequisites.values.get('driversTypes')
                                                        )}
                                                    />
                                                </th>
                                                <th className="w-100">
                                                    <TableFilterInputField
                                                        identifier="code"
                                                        type={TableModelColumnFilteringType.string}
                                                        filters={filters}
                                                        onChange={this.props.table.changeFilter}
                                                    />
                                                </th>
                                                <th className="w-180">
                                                    <TableFilterInputField
                                                        identifier="card_number"
                                                        type={TableModelColumnFilteringType.string}
                                                        filters={filters}
                                                        onChange={this.props.table.changeFilter}
                                                    />
                                                </th>
                                                <th className="w-140">
                                                    <TableFilterSelectField
                                                        identifier="dispatcher_id"
                                                        type={TableModelColumnFilteringType.equal}
                                                        filters={filters}
                                                        onChange={this.props.table.changeFilter}
                                                        values={getPrerequisitesArray(this.props.prerequisites.values.get('dispatchers'))}
                                                    />
                                                </th>
                                                <th className="w-max wm-200">
                                                    <TableFilterInputField
                                                        identifier="phone"
                                                        type={TableModelColumnFilteringType.string}
                                                        filters={filters}
                                                        onChange={this.props.table.changeFilter}
                                                    />
                                                </th>
                                                <th className="w-100">
                                                    <TableFilterSelectField
                                                        identifier="locale"
                                                        type={TableModelColumnFilteringType.equal}
                                                        filters={filters}
                                                        onChange={this.props.table.changeFilter}
                                                        values={getPrerequisitesArray(
                                                            this.props.prerequisites.values.get('driverLocales'),
                                                            'locale',
                                                            'locale'
                                                        )}
                                                    />
                                                </th>
                                                <th className="w-80">
                                                    <TableFilterBooleanField
                                                        identifier="is_active"
                                                        type={TableModelColumnFilteringType.equal}
                                                        filters={filters}
                                                        onChange={this.props.table.changeFilter}
                                                    />
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.props.drivers.data.valueSeq().map(row => (
                                                <DriversListRow
                                                    key={`row-${row.id}`}
                                                    data={row}
                                                    onClick={this.onClick}
                                                    onDelete={this.onDelete}
                                                    dispatcher={this.props.createGetUserByIDSelector(row.dispatcher_id)}
                                                    driversType={this.props.createGetDriversTypeByIDSelector(row.drivers_type_id)}
                                                />
                                            ))}
                                        </tbody>
                                    </table>
                                </div>

                                <div className="pull-left m-l-xxs m-t-md">
                                    <FormattedMessage id="pagination.totalRecords" />:{' '}
                                    {getListItemsCountMessage(
                                        clientSideItemsPerPage,
                                        this.props.drivers.count,
                                        this.props.tableModel.getIn(['pagination', 'totalCount'])
                                    )}
                                </div>

                                <ReactPaginate
                                    containerClassName="pagination"
                                    breakLabel={<span className="disabled">...</span>}
                                    activeClassName="active"
                                    pageCount={getTotalNumberOfPages(this.props.tableModel, this.props.drivers.count, clientSideItemsPerPage)}
                                    pageRangeDisplayed={10}
                                    marginPagesDisplayed={2}
                                    forcePage={this.props.tableModel.getIn(['pagination', 'current'])}
                                    onPageChange={this.props.table.changePage}
                                    ppreviousLabel={this.props.intl.formatMessage({ id: 'pagination.previous' })}
                                    nextLabel={this.props.intl.formatMessage({ id: 'pagination.next' })}
                                />
                            </div>
                        </div>
                    </div>

                    <ConfirmDialog options={this.state.confirmDialog} />
                </div>
            </PermissionsCheck>
        )
    }
}

function mapStateToProps(state) {
    return {
        drivers: createTableDataSelector({
            tableIdentifier,
            columns,
            dataSelector: getDrivers,
            clientSideItemsPerPage,
        })(state),
        tableModel: createTableModelSelector(tableIdentifier)(state),
        prerequisites: preparePrerequisitesFetchStatusSelectors(componentIdentifier, prerequisites, state),
        deleting: getDriverDeleting(state),
        deleteError: getDriverDeleteError(state),
        createGetUserByIDSelector: id => createGetUserByIDSelector(id)(state),
        createGetDriversTypeByIDSelector: id => createGetDriversTypeByIDSelector(id)(state),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            ...bindActionCreators(
                {
                    ...driversActionCreators,
                    ...preparePrerequisitesActionCreators(componentIdentifier, prerequisites),
                },
                dispatch
            ),
        },
        notify: (notification, type) => dispatch(Notifications.show(notification, type)),
        redirect: toURL => dispatch(push(toURL)),
        dispatch,
    }
}

function mergeProps(stateProps, dispatchProps, ownProps) {
    return {
        ...stateProps,
        ...dispatchProps,
        ...ownProps,
        table: bindActionCreators(prepareTableActionCreators(tableIdentifier, stateProps.tableModel), dispatchProps.dispatch),
    }
}

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps,
        mergeProps
    )(DriversList)
)
