import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import Notifications from 'react-notification-system-redux'
import validator from 'validator'
import moment from 'moment'
import DatePicker from 'react-datepicker'

import 'react-datepicker/dist/react-datepicker.css'

import {
    url,
    handleCommonEditActions,
    getPrerequisitesArray,
    getPrerequisitesPairs,
    convertCSDatetimeToISO,
    convertISODatetimeToCS,
    convertCSDatetimeToMoment,
    validationMessage,
} from '../../../common/helpers'
import * as formatters from '../../../common/formatters'
import { Form, SelectField, InputField, SubmitButton } from '../../../common/form'
import {
    createGetVehicleFuelingByIDSelector,
    createGetCurrentVehicleFuelingStatusSelector,
    getCountryInProgress,
    getCountryError,
    getCountryValue,
    getDriverInProgress,
    getDriverError,
    getDriverValue,
} from '../selectors'
import * as vehicleFuelingsActionCreators from '../actionCreators'
import { LoadingOverlay } from '../../../common/loading_overlay'
import { preparePrerequisitesFetchStatusSelectors } from '../../../common/prerequisites/selectors'
import { preparePrerequisitesActionCreators } from '../../../common/prerequisites/actionCreators'
import { HEREMapAddressPicker } from '../../../common/here_maps'
import * as config from '../../../common/config'

import PermissionsCheck from '../../auth/components/PermissionsCheck'
import { PERMS } from '../permissions'

const componentIdentifier = 'vehicle_events_edit'
const prerequisites = ['vehicles', 'drivers', 'countries', 'trailers']

class VehicleFuelingsEdit extends Component {
    defaultValues = {
        vehicle_id: null,
        registration: null,
        trailer_id: null,
        registration_trailer: null,
        fuel_level: 0,
        adblue_level: 0,
    }

    validationRules = {
        fuel_level: value => !validator.isFloat(String(value)) && validationMessage('isFloat'),
        adblue_level: value => !validator.isFloat(String(value)) && validationMessage('isFloat'),
    }

    state = {
        values: this.defaultValues,
        datetimes: {
            datetime: moment().format('DD.MM.YYYY HH:mm'),
        },
        datetimesSet: false,
        gps: null,
    }

    setValues = values => {
        if (this.state.values.vehicle_id !== values.vehicle_id) {
            const vehicles = getPrerequisitesPairs(this.props.prerequisites.values.get('vehicles'))
            values.registration = vehicles[values.vehicle_id] ? vehicles[values.vehicle_id] : null
        }

        if (this.state.values.registration !== values.registration) {
            const vehicles = getPrerequisitesPairs(this.props.prerequisites.values.get('vehicles'), 'name', 'id')
            values.vehicle_id = vehicles[values.registration] ? vehicles[values.registration] : null
        }


        if (this.state.values.trailer_id !== values.trailer_id) {
            const trailers = getPrerequisitesPairs(this.props.prerequisites.values.get('trailers'))
            values.registration_trailer = trailers[values.trailer_id] ? trailers[values.trailer_id] : null
        }

        if (this.state.values.registration_trailer !== values.registration_trailer) {
            const trailers = getPrerequisitesPairs(this.props.prerequisites.values.get('trailers'), 'name', 'id')
            values.trailer_id = trailers[values.registration_trailer] ? trailers[values.registration_trailer] : null
        }

        if (this.state.values.vehicle_id !== values.vehicle_id || this.state.values.registration !== values.registration) {
            this.props.actions.fetchDriver(values.vehicle_id, this.state.datetimes.datetime)
        }

        this.setState({ values })
    }

    handleDatetimeChange = (key, value) => {
        const newState = {
            datetimes: {
                ...this.state.datetimes,
                [key]: value,
            },
        }

        this.setState(newState, () => this.props.actions.fetchDriver(this.state.values.vehicle_id, value))
    }

    handleSubmit = values => {
        values.datetime = convertCSDatetimeToISO(this.state.datetimes.datetime)

        this.props.actions.saveVehicleFueling(Object.assign(values, this.state.gps))
    }

    successCallback = () => {
        this.props.successCallback ? this.props.successCallback() : this.props.redirect(url(this.props.match, 'vehicle-fuelings'))
    }

    backCallback = () => {
        this.props.backCallback ? this.props.backCallback() : this.props.redirect(url(this.props.match, 'vehicle-fuelings'))
    }

    setGPS = coord => {
        this.setState(
            {
                gps: coord,
            },
            () => {
                if (coord.gps_lat && coord.gps_lng) {
                    this.props.actions.fetchCountry(coord.gps_lat, coord.gps_lng)
                }
            }
        )
    }

    handleMarkerPositionChanged = coord => {
        this.setGPS({
            gps_lat: coord.lat,
            gps_lng: coord.lng,
        })
    }

    componentDidMount() {
        this.props.vehicleFuelingID && this.props.actions.fetchVehicleFueling(this.props.vehicleFuelingID)
        this.props.actions.fetchPrerequisites()

        if (!this.props.vehicleFuelingID && this.props.defaultValues) {
            this.setValues({ ...this.defaultValues, ...this.props.defaultValues })
        }
    }

    componentWillReceiveProps(nextProps) {
        handleCommonEditActions(this.props, nextProps, this.successCallback)

        if (nextProps.vehicleFueling && !this.state.datetimesSet) {
            this.setState({
                datetimes: {
                    datetime: convertISODatetimeToCS(nextProps.vehicleFueling.datetime),
                },
                datetimesSet: true,
            })
        }

        if (!this.state.gps && nextProps.vehicleFueling) {
            if (nextProps.vehicleFueling.gps_lat && nextProps.vehicleFueling.gps_lng) {
                this.setGPS({
                    gps_lat: nextProps.vehicleFueling.gps_lat,
                    gps_lng: nextProps.vehicleFueling.gps_lng,
                })
            }
        }

        if (nextProps.vehicleFueling && JSON.stringify(nextProps.vehicleFueling) !== JSON.stringify(this.props.vehicleFueling)) {
            this.setValues(nextProps.vehicleFueling.toJS())
        }

        if (this.props.getCountryInProgress && !nextProps.getCountryInProgress) {
            const values = { ...this.state.values, country_id: nextProps.getCountryValue.id }
            this.setState({ values })
        }

        if (this.props.getDriverInProgress && !nextProps.getDriverInProgress) {
            const values = { ...this.state.values, driver_id: nextProps.getDriverValue.driver_id }
            this.setState({ values }, () =>
                this.setGPS({
                    gps_lat: nextProps.getDriverValue.gps_lat,
                    gps_lng: nextProps.getDriverValue.gps_lng,
                })
            )
        }
    }

    render() {
        const perms = this.props.PERMS ? this.props.PERMS : PERMS

        const gps = this.state.gps &&
            this.state.gps.gps_lat &&
            this.state.gps.gps_lat && {
                lat: this.state.gps.gps_lat,
                lng: this.state.gps.gps_lng,
            }

        console.log(this.state.values)

        return (
            <div>
                <LoadingOverlay active={this.props.prerequisites.status.get('fetching') || this.props.status.get('fetching')}>
                    <PermissionsCheck hasAny={Object.values(perms)}>
                        <Form
                            values={this.state.values}
                            validationRules={this.validationRules}
                            onChange={this.setValues}
                            onSubmit={this.handleSubmit}
                            isEdit={Boolean(this.props.vehicleFueling)}
                        >
                            <div className="row">
                                <div className="col-sm-6 b-r">
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label htmlFor="value_from">
                                                    <FormattedMessage id="fields.dateTime" />
                                                </label>
                                                <DatePicker
                                                    id="datetime"
                                                    dateFormat="DD.MM.YYYY HH:mm"
                                                    showTimeSelect
                                                    shouldCloseOnSelect={false}
                                                    timeFormat="HH:mm"
                                                    timeIntervals={5}
                                                    autoComplete="off"
                                                    popperClassName="react-datepicker-with-time"
                                                    selected={convertCSDatetimeToMoment(this.state.datetimes.datetime)}
                                                    value={this.state.datetimes.datetime}
                                                    onChange={value => {
                                                        this.handleDatetimeChange('datetime', value ? formatters.datetimeFormatter(value) : '')
                                                    }}
                                                    onChangeRaw={e => {
                                                        this.handleDatetimeChange('datetime', e.target.value)
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <SelectField
                                                id="country_id"
                                                label={this.props.intl.formatMessage({ id: 'fields.country' })}
                                                values={getPrerequisitesArray(this.props.prerequisites.values.get('countries'), 'id', 'iso_code')}
                                            />
                                        </div>
                                    </div>
                                    <div
                                        className={`row ${
                                            this.props.hiddenFields && this.props.hiddenFields.indexOf('vehicle_id') !== -1 ? 'hidden' : ''
                                        }`}
                                    >
                                        <div className="col-sm-6">
                                            <SelectField
                                                id="vehicle_id"
                                                label={this.props.intl.formatMessage({ id: 'fields.vehicle' })}
                                                values={getPrerequisitesArray(this.props.prerequisites.values.get('vehicles'))}
                                                nullable={true}
                                            />
                                        </div>
                                        <div className="col-sm-6">
                                            <InputField id="registration" label={this.props.intl.formatMessage({ id: 'fields.registration' })} />
                                        </div>
                                        <div className="col-sm-6">
                                            <SelectField
                                                id="trailer_id"
                                                label={this.props.intl.formatMessage({ id: 'fields.trailer' })}
                                                values={getPrerequisitesArray(this.props.prerequisites.values.get('trailers'))}
                                                nullable={true}
                                            />
                                        </div>
                                        <div className="col-sm-6">
                                            <InputField id="registration_trailer" label={this.props.intl.formatMessage({ id: 'fields.registration' })} />
                                        </div>
                                    </div>
                                    <SelectField
                                        id="driver_id"
                                        label={this.props.intl.formatMessage({ id: 'fields.driver' })}
                                        values={getPrerequisitesArray(this.props.prerequisites.values.get('drivers'))}
                                    />
                                </div>
                                <div className="col-sm-6">
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <InputField id="fuel_level" label={this.props.intl.formatMessage({ id: 'fields.fuelLevel' })} />
                                        </div>
                                        <div className="col-sm-6">
                                            <InputField id="adblue_level" label={this.props.intl.formatMessage({ id: 'fields.adblueLevel' })} />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <InputField id="number" label={this.props.intl.formatMessage({ id: 'fields.documentNumber' })} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="p-v-sm m-t-sm b-t">
                                <HEREMapAddressPicker
                                    appId={config.CFG_HERE_MAPS_APP_ID}
                                    appCode={config.CFG_HERE_MAPS_APP_CODE}
                                    markerCenter={gps}
                                    handleMarkerPositionChanged={this.handleMarkerPositionChanged}
                                    className="h-400"
                                />
                            </div>
                            <div className="btns-form">
                                <button onClick={this.backCallback} className="btn btn-addon btn-default">
                                    <i className="far fa-chevron-left" /> <FormattedMessage id="buttons.back" />
                                </button>
                                <SubmitButton
                                    perms={Object.values(perms)}
                                    isEdit={Boolean(this.props.vehicleFueling)}
                                    className="btn-addon pull-right"
                                />
                            </div>
                        </Form>
                    </PermissionsCheck>
                </LoadingOverlay>
            </div>
        )
    }
}

function mapStateToProps(state, ownProps) {
    return {
        vehicleFueling: createGetVehicleFuelingByIDSelector(ownProps.vehicleFuelingID)(state),
        status: createGetCurrentVehicleFuelingStatusSelector()(state),
        prerequisites: preparePrerequisitesFetchStatusSelectors(componentIdentifier, prerequisites, state),
        getCountryInProgress: getCountryInProgress(state),
        getCountryValue: getCountryValue(state),
        getCountryError: getCountryError(state),
        getDriverInProgress: getDriverInProgress(state),
        getDriverValue: getDriverValue(state),
        getDriverError: getDriverError(state),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            ...bindActionCreators(
                {
                    ...vehicleFuelingsActionCreators,
                    ...preparePrerequisitesActionCreators(componentIdentifier, prerequisites),
                },
                dispatch
            ),
        },
        redirect: toURL => dispatch(push(toURL)),
        notify: (notification, type) => dispatch(Notifications.show(notification, type)),
    }
}

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(VehicleFuelingsEdit)
)
