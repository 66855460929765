export const PERMS = {
    READ: 'users.read',
    UPDATE: 'users.update',
    DELETE: 'users.delete',
    CREATE: 'users.create',
    EXPORT: 'users.export',
}

export const PERMS_LOGIN_LOG = {
    READ: 'users_login_log.read',
    EXPORT: 'users_login_log.export',
}
