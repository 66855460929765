// Fetch list

export const FETCH = 'customers.FETCH'
export const FETCH_STARTED = 'customers.FETCH_STARTED'
export const FETCH_FULFILLED = 'customers.FETCH_FULFILLED'
export const FETCH_REJECTED = 'customers.FETCH_REJECTED'
export const FETCH_CANCELLED = 'customers.FETCH_CANCELLED'

export const SORTING_CHANGED = 'customers.SORTING_CHANGED'

// Fetch one

export const FETCH_ONE = 'customers.FETCH_ONE'
export const FETCH_ONE_STARTED = 'customers.FETCH_ONE_STARTED'
export const FETCH_ONE_FULFILLED = 'customers.FETCH_ONE_FULFILLED'
export const FETCH_ONE_REJECTED = 'customers.FETCH_ONE_REJECTED'
export const FETCH_ONE_CANCELLED = 'customers.FETCH_ONE_CANCELLED'

// Save

export const SAVE = 'customers.SAVE'
export const SAVE_STARTED = 'customers.SAVE_STARTED'
export const SAVE_FULFILLED = 'customers.SAVE_FULFILLED'
export const SAVE_REJECTED = 'customers.SAVE_REJECTED'
export const SAVE_CANCELLED = 'customers.SAVE_CANCELLED'
export const SAVE_DUPLICITY_FOUND = 'customers.SAVE_DUPLICITY_FOUND'

// Delete

export const DELETE = 'customers.DELETE'
export const DELETE_STARTED = 'customers.DELETE_STARTED'
export const DELETE_FULFILLED = 'customers.DELETE_FULFILLED'
export const DELETE_REJECTED = 'customers.DELETE_REJECTED'
export const DELETE_CANCELLED = 'customers.DELETE_CANCELLED'

// Restore

export const RESTORE = 'customers.RESTORE'
export const RESTORE_STARTED = 'customers.RESTORE_STARTED'
export const RESTORE_FULFILLED = 'customers.RESTORE_FULFILLED'
export const RESTORE_REJECTED = 'customers.RESTORE_REJECTED'
export const RESTORE_CANCELLED = 'customers.RESTORE_CANCELLED'


// Load info by VAT number

export const LOAD_INFO = 'customers.LOAD_INFO'
export const LOAD_INFO_STARTED = 'customers.LOAD_INFO_STARTED'
export const LOAD_INFO_FULFILLED = 'customers.LOAD_INFO_FULFILLED'
export const LOAD_INFO_REJECTED = 'customers.LOAD_INFO_REJECTED'
export const LOAD_INFO_CANCELLED = 'customers.LOAD_INFO_CANCELLED'
export const CLEAR_INFO = 'customers.CLEAR_INFO'

// Export

export const EXPORT = 'customers.EXPORT'
export const EXPORT_FULFILLED = 'customers.EXPORT_FULFILLED'

// Fetch customer addresses

export const FETCH_ADDRESSES = 'customers.FETCH_ADDRESSES'
export const FETCH_ADDRESSES_STARTED = 'customers.FETCH_ADDRESSES_STARTED'
export const FETCH_ADDRESSES_FULFILLED = 'customers.FETCH_ADDRESSES_FULFILLED'
export const FETCH_ADDRESSES_REJECTED = 'customers.FETCH_ADDRESSES_REJECTED'
export const FETCH_ADDRESSES_CANCELLED = 'customers.FETCH_ADDRESSES_CANCELLED'

// Export customer addresses

export const EXPORT_ADDRESSES = 'customers.EXPORT_ADDRESSES'
export const EXPORT_ADDRESSES_STARTED = 'customers.EXPORT_ADDRESSES_STARTED'
export const EXPORT_ADDRESSES_FULFILLED = 'customers.EXPORT_ADDRESSES_FULFILLED'
export const EXPORT_ADDRESSES_REJECTED = 'customers.EXPORT_ADDRESSES_REJECTED'
export const EXPORT_ADDRESSES_CANCELLED = 'customers.EXPORT_ADDRESSES_CANCELLED'

// Fetch customer contacts

export const FETCH_CONTACTS = 'customers.FETCH_CONTACTS'
export const FETCH_CONTACTS_STARTED = 'customers.FETCH_CONTACTS_STARTED'
export const FETCH_CONTACTS_FULFILLED = 'customers.FETCH_CONTACTS_FULFILLED'
export const FETCH_CONTACTS_REJECTED = 'customers.FETCH_CONTACTS_REJECTED'
export const FETCH_CONTACTS_CANCELLED = 'customers.FETCH_CONTACTS_CANCELLED'

// Export customer contacts

export const EXPORT_CONTACTS = 'customers.EXPORT_CONTACTS'
export const EXPORT_CONTACTS_STARTED = 'customers.EXPORT_CONTACTS_STARTED'
export const EXPORT_CONTACTS_FULFILLED = 'customers.EXPORT_CONTACTS_FULFILLED'
export const EXPORT_CONTACTS_REJECTED = 'customers.EXPORT_CONTACTS_REJECTED'
export const EXPORT_CONTACTS_CANCELLED = 'customers.EXPORT_CONTACTS_CANCELLED'

// Fetch customer plans

export const FETCH_PLANS = 'customers.FETCH_PLANS'
export const FETCH_PLANS_STARTED = 'customers.FETCH_PLANS_STARTED'
export const FETCH_PLANS_FULFILLED = 'customers.FETCH_PLANS_FULFILLED'
export const FETCH_PLANS_REJECTED = 'customers.FETCH_PLANS_REJECTED'
export const FETCH_PLANS_CANCELLED = 'customers.FETCH_PLANS_CANCELLED'

// Export customer plans

export const EXPORT_PLANS = 'customers.EXPORT_PLANS'
export const EXPORT_PLANS_STARTED = 'customers.EXPORT_PLANS_STARTED'
export const EXPORT_PLANS_FULFILLED = 'customers.EXPORT_PLANS_FULFILLED'
export const EXPORT_PLANS_REJECTED = 'customers.EXPORT_PLANS_REJECTED'
export const EXPORT_PLANS_CANCELLED = 'customers.EXPORT_PLANS_CANCELLED'

// Check duplicity

export const CHECK_DUPLICITY = 'customers.CHECK_DUPLICITY'
export const CHECK_DUPLICITY_STARTED = 'customers.CHECK_DUPLICITY_STARTED'
export const CHECK_DUPLICITY_FULFILLED = 'customers.CHECK_DUPLICITY_FULFILLED'
export const CHECK_DUPLICITY_REJECTED = 'customers.CHECK_DUPLICITY_REJECTED'
export const CHECK_DUPLICITY_CANCELLED = 'customers.CHECK_DUPLICITY_CANCELLED'
