import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { push } from 'react-router-redux'
import Notifications from 'react-notification-system-redux'
import DatePicker from 'react-datepicker'
import { Modal } from 'react-bootstrap'
import moment from 'moment'

import { ConfirmDialog } from '../../../common/confirm_dialog'
import { Form, InputField, SelectField, SubmitButton } from '../../../common/form'
import * as formatters from '../../../common/formatters'
import * as helpers from '../../../common/helpers'
import { LoadingOverlay } from '../../../common/loading_overlay'

import {
    getCurrentReceivedInvoice,
    getCurrentSupportData,
    getCurrentReceivedInvoiceFetching,
    getCurrentReceivedInvoiceSaving,
    getCurrentReceivedInvoiceError,
    createGetDuplicitiesSelector,
    getTransportInfo,
    getTransportInfoFetching,
    getTransportInfoError,
    getSupplierInfo,
    getSupplierInfoFetching,
    getReceivedInvoiceDuplicityChecking,
    getReceivedInvoiceDuplicityCheckingData,
    getReceivedInvoiceDuplicityCheckingError,
} from '../selectors'
import * as actionCreators from '../actionCreators'

import { preparePrerequisitesFetchStatusSelectors } from '../../../common/prerequisites/selectors'
import { preparePrerequisitesActionCreators } from '../../../common/prerequisites/actionCreators'
import * as constants from '../../invoicing_orders/constants'

import InvoicingReceivedInvoicesFiles from './InvoicingReceivedInvoicesFiles'
import PermissionsCheck from '../../auth/components/PermissionsCheck'
import { PERMS } from '../permissions'

const componentIdentifier = 'invoicing_received_invoices_edit'
const prerequisites = ['users', 'carriers', 'currencies', 'payment_methods', 'invoice_item_types']

class InvoicingReceivedInvoicesEdit extends Component {
    validationRules = {}

    state = {
        data: null,
        supplierInfo: null,
        values: {},
        datetimes: {},
        items: [],
        autoSetDefaultValues: false,
        modalDuplicity: {
            show: false,
            duplicities: null,
        },
        duplicityFound: false,
        confirmDialog: {
            show: false,
            title: '',
            message: '',
            labelCancel: '',
            labelAccept: '',
            classNameAccept: 'text-success',
            classNameCancel: 'text-default',
            onCancel: () => {},
            onAccept: () => {},
        },
    }

    transportSearchItemKey = null
    successCallback = null

    initialTaxRatesSet = false

    setDataForInvoicing = (receivedInvoice, data) => {
        this.initialTaxRatesSet = false

        const company = data && data.companies && data.companies[0]

        let values = {
            id: null,
            company_id: company && company.id,
            currency_id: data && data.currencies && data.currencies[0] && data.currencies[0].id,
            payment_method_id: data && data.payment_methods && data.payment_methods[0] && data.payment_methods[0].id,
            tax_transaction_type_id: constants.TAX_TRANSACTION_TYPE_DOMESTIC,
            supplier_id: null,
        }

        const datetimes = {
            date_issued: moment().format('DD.MM.YYYY'),
            date_received: moment().format('DD.MM.YYYY'),
            date_due: moment().format('DD.MM.YYYY'),
            date_tax: moment().format('DD.MM.YYYY'),
            paid_at: null,
        }

        let items = [
            {
                id: null,
                transport_id: '',
                units_count: 1,
                unit: this.props.intl.formatMessage({ id: 'units.pcs' }),
                name: '',
                text: '',
                unit_price: 0,
                tax_rate_percent: 0,
                invoice_item_type_id: company && company.default_invoice_item_type_id,
            },
        ]

        if (receivedInvoice) {
            values = {
                id: receivedInvoice.id,
                company_id: receivedInvoice.company_id,
                company_cost_center_id: receivedInvoice.company_cost_center_id,
                cost_user_id: receivedInvoice.cost_user_id,
                company_numbering_id: receivedInvoice.company_numbering_id,
                currency_id: receivedInvoice.currency_id,
                payment_method_id: receivedInvoice.payment_method_id,
                tax_transaction_type_id: receivedInvoice.tax_transaction_type_id,
                supplier_id: receivedInvoice.supplier_id,
                suppliers_number: receivedInvoice.suppliers_number,
                var_symbol: receivedInvoice.var_symbol,
                note: receivedInvoice.note,
                paid_amount: 0,
            }

            datetimes.date_issued = helpers.convertISODateToCS(receivedInvoice.date_issued)
            datetimes.date_received = helpers.convertISODateToCS(receivedInvoice.date_received)
            datetimes.date_due = helpers.convertISODateToCS(receivedInvoice.date_due)
            datetimes.date_tax = helpers.convertISODateToCS(receivedInvoice.date_tax)
            datetimes.paid_at = receivedInvoice.paid_at && helpers.convertISODateToCS(receivedInvoice.paid_at)

            items = receivedInvoice.items
                ? receivedInvoice.items.map(item => ({
                      id: item.id,
                      transport_id: item.transport_id || '',
                      units_count: item.units_count || 1,
                      unit: item.unit || this.props.intl.formatMessage({ id: 'units.pcs' }),
                      name: item.name,
                      text: item.text,
                      unit_price: item.unit_price,
                      tax_rate_percent: item.tax_rate_percent,
                      invoice_item_type_id: item.invoice_item_type_id || (company && company.default_invoice_item_type_id),
                      loading_is_in_eu: item.loading_is_in_eu,
                      unloading_is_in_eu: item.unloading_is_in_eu,
                  }))
                : []
        }

        this.setState({
            data,
            values: this.completeValues(values, data, this.state.supplierInfo),
            datetimes,
            items,
        })
    }

    setValues = values => {
        this.setState({
            values,
        })
    }

    completeValues = (values, data, supplier, changeFromTransportInfo) => {
        const supplierChanged = String(values.supplier_id) !== String(this.state.values.supplier_id)
        const newSupplierInfoLoaded = String(supplier && supplier.id) !== String(this.state.supplierInfo && this.state.supplierInfo.id)
        const companyChanged = values.company_id && String(values.company_id) !== String(this.state.values.company_id)
        const currencyChanged = values.currency_id && String(values.currency_id) !== String(this.state.values.currency_id)

        const company = data && data.companies && data.companies.find(item => String(item.id) === String(values.company_id))
        const numbering = company && company.numberings && company.numberings.find(item => String(item.id) === String(values.company_numbering_id))

        let costCenter =
            company && company.cost_centers && company.cost_centers.find(item => String(item.id) === String(values.company_cost_center_id))
        if (!costCenter && company && company.cost_centers) {
            costCenter = company.default_company_cost_center_id
                ? company.cost_centers.find(item => String(item.id) === String(company.default_company_cost_center_id))
                : company.cost_centers[0]
        }

        // company changed
        if (companyChanged) {
            values.company_cost_center_id = costCenter ? costCenter.id : null

            values.company_numbering_id = numbering
                ? numbering.id
                : company && company.numberings && company.numberings[0] && company.numberings[0].id
        }

        // currency changed
        if (currencyChanged || companyChanged) {
            const bankAccounts = company && company.bank_accounts
            const firstPossibleBankAccount = bankAccounts && bankAccounts.find(item => String(item.currency_id) === String(values.currency_id))
            const selectedBankAccount =
                bankAccounts &&
                bankAccounts.find(
                    item => String(item.id) === String(values.company_bank_account_id) && String(item.currency_id) === String(values.currency_id)
                )

            if (bankAccounts && (!bankAccounts.length || !selectedBankAccount)) {
                values.company_bank_account_id = firstPossibleBankAccount && firstPossibleBankAccount.id
            }
        }

        if ((newSupplierInfoLoaded || companyChanged) && company && supplier && this.state.autoSetDefaultValues) {
            if (company.country_id === supplier.country_id) {
                values.tax_transaction_type_id = constants.TAX_TRANSACTION_TYPE_DOMESTIC
            } else if (company.country_is_in_eu && supplier.country_is_in_eu) {
                values.tax_transaction_type_id = constants.TAX_TRANSACTION_TYPE_EU
            } else {
                values.tax_transaction_type_id = constants.TAX_TRANSACTION_TYPE_OTHER
            }
        }

        const taxTransactionTypeId = String(values.tax_transaction_type_id)
        const taxTransactionTypeChanged =
            taxTransactionTypeId &&
            this.state.values.tax_transaction_type_id &&
            taxTransactionTypeId !== String(this.state.values.tax_transaction_type_id)

        if (taxTransactionTypeChanged || changeFromTransportInfo) {
            this.setTaxRateForItems(company, supplier, data, taxTransactionTypeId)
        }

        values.id = this.props.receivedInvoice && this.props.receivedInvoice.id

        if (supplierChanged) {
            if (values.supplier_id) {
                this.loadSupplierInfo(values.supplier_id)
            } else {
                this.clearSupplierInfo()
            }
        }

        return values
    }

    handleChangeValues = values => {
        if (
            values.supplier_id !== this.state.values.supplier_id ||
            values.suppliers_number !== this.state.values.suppliers_number ||
            values.var_symbol !== this.state.values.var_symbol
        ) {
            this.checkDuplicity(values.supplier_id, values.suppliers_number, values.var_symbol)
        }

        this.setState({
            values: this.completeValues(values, this.state.data, this.state.supplierInfo),
        })
    }

    handleDatetimeChange = (key, value) => {
        const datetimes = {
            ...this.state.datetimes,
            [key]: value,
        }

        if (key === 'date_received' && datetimes.date_due) {
            const originalDateReceived = moment(this.state.datetimes.date_received, 'DD.MM.YYYY')
            const newDateReceived = moment(datetimes.date_received, 'DD.MM.YYYY')
            const dateDue = moment(datetimes.date_due, 'DD.MM.YYYY')
            const diff = dateDue.diff(originalDateReceived, 'days')

            datetimes.date_due = newDateReceived.add(diff, 'days').format('DD.MM.YYYY')
        }

        this.setState({
            datetimes,
        })
    }

    handleChangeItemValues = (itemKey, name, value) => {
        const items = [...this.state.items]
        items[itemKey] = {
            ...this.state.items[itemKey],
            [name]: value,
        }

        this.setState({
            items,
        })
    }

    handleChangeItemType = (itemKey, name, value) => {
        const data = this.state.data
        const values = this.state.values
        const supplier = this.state.supplierInfo

        const company = data && data.companies && data.companies.find(item => String(item.id) === String(values.company_id))

        const items = [...this.state.items]
        const taxRates = company && company.country_id && data && data.tax_rates && data.tax_rates[company.country_id]
        const invoiceItemTypesTaxRate = helpers.getPrerequisitesPairs(
            this.props.prerequisites.values.get('invoice_item_types'),
            'id',
            'tax_rate_type_id'
        )

        items[itemKey] = {
            ...this.state.items[itemKey],
            [name]: value,
        }

        items[itemKey] = {
            ...items[itemKey],
            tax_rate_percent: this.getItemTax(items[itemKey], taxRates, invoiceItemTypesTaxRate, company, supplier),
        }

        this.setState({
            items,
        })
    }

    handleAddItem = e => {
        e && e.preventDefault()
        e && e.stopPropagation()

        const values = this.state.values
        const data = this.state.data

        let company = values.company_id && data && data.companies && data.companies.find(item => String(item.id) === String(values.company_id))
        if (!company) {
            company = data && data.companies && data.companies[0]
        }

        const taxRates = company && company.country_id && data && data.tax_rates && data.tax_rates[company.country_id]
        const default_invoice_item_type_id = company && company.default_invoice_item_type_id
        const default_tax_rate_id =
            default_invoice_item_type_id &&
            helpers.getPrerequisitesPairs(this.props.prerequisites.values.get('invoice_item_types'), 'id', 'tax_rate_type_id')[
                default_invoice_item_type_id
            ]
        const tax_rate = taxRates && default_tax_rate_id && taxRates.find(item => String(item.id) === String(default_tax_rate_id))

        const items = [...this.state.items]
        items.push({
            id: null,
            transport_id: null,
            units_count: 1,
            unit: this.props.intl.formatMessage({ id: 'units.pcs' }),
            name: '',
            text: '',
            unit_price: 0,
            tax_rate_percent: tax_rate && tax_rate.percent,
            invoice_item_type_id: default_invoice_item_type_id,
        })

        this.setState({
            items,
        })
    }

    handleRemoveItem = (e, itemKey) => {
        e && e.preventDefault()
        e && e.stopPropagation()

        const items = [...this.state.items]
        items.splice(itemKey, 1)

        this.setState({
            items,
        })
    }

    handleTransportSearchButtonClick = itemKey => {
        this.transportSearchItemKey = itemKey
        const transportId = this.state.items[itemKey] && this.state.items[itemKey].transport_id

        if (transportId) {
            this.props.actions.loadTransportInfo(transportId)
        }
    }

    setItemsValues = (itemKey, values, callback) => {
        const items = [...this.state.items]
        items[itemKey] = {
            ...this.state.items[itemKey],
            name: (values && values.name) || '',
            text: (values && values.text) || '',
            unit_price: (values && values.unit_price) || '',
            loading_is_in_eu: values.loading_is_in_eu,
            unloading_is_in_eu: values.unloading_is_in_eu,
        }

        this.setState(
            {
                items,
            },
            () => {
                callback && callback()
            }
        )
    }

    loadSupplierInfo = supplierId => {
        this.props.actions.loadSupplierInfo(supplierId)
    }

    setSupplierInfo = (supplierInfo, currencyId, companyCostCenterId, dateTax, disponentId) => {
        const values = { ...this.state.values }
        const datetimes = { ...this.state.datetimes }

        if (this.state.autoSetDefaultValues) {
            const days = supplierInfo && supplierInfo.days ? parseInt(supplierInfo.days) : 60
            datetimes.date_due = moment(this.state.datetimes.date_received, 'DD.MM.YYYY')
                .add(days, 'days')
                .format('DD.MM.YYYY')
        }

        if (dateTax) {
            datetimes.date_tax = moment(dateTax, 'YYYY-MM-DD').format('DD.MM.YYYY')
        }

        if (supplierInfo.id) {
            values.supplier_id = supplierInfo.id
        }

        if (currencyId) {
            values.currency_id = currencyId
        }

        if (companyCostCenterId) {
            values.company_cost_center_id = companyCostCenterId
        }

        if (disponentId) {
            values.cost_user_id = disponentId
        }

        this.setState({
            supplierInfo,
            values: this.completeValues(values, this.state.data, supplierInfo, true),
            datetimes,
            autoSetDefaultValues: true,
        })
    }

    clearSupplierInfo = () => {
        this.setState({
            supplierInfo: null,
        })
    }

    handleSubmit = () => {
        this.successCallback = nextProps => {
            if (nextProps.receivedInvoice && nextProps.receivedInvoice.id) {
                this.props.redirect(helpers.url(this.props.match, `invoicing-received-invoices/${nextProps.receivedInvoice.id}`))
            } else {
                this.props.redirect(helpers.url(this.props.match, 'invoicing-received-invoices'))
            }
        }

        this.save(false)
    }

    handleSaveAndClose = () => {
        this.successCallback = () => {
            this.props.redirect(helpers.url(this.props.match, 'invoicing-received-invoices'))
        }

        this.save(false)
    }

    save = ignoreDuplicity => {
        this.props.actions.saveInvoicingReceivedInvoice(
            {
                ...this.state.values,
                ...this.state.datetimes,
                items: this.state.items,
            },
            ignoreDuplicity
        )
    }

    duplicityFoundCallback = duplicities => {
        this.setState({
            modalDuplicity: {
                show: true,
                duplicities,
            },
        })
    }

    handleDuplicityCancel = () => {
        this.setState({
            modalDuplicity: {
                show: false,
                duplicities: null,
            },
        })
    }

    handleDuplicityConfirm = () => {
        this.handleDuplicityCancel()
        this.save(true)
    }

    getItemTax = (item, taxRates, invoiceItemTypesTaxRate, company, supplier, taxTransactionTypeId) => {
        const taxRateId = invoiceItemTypesTaxRate && invoiceItemTypesTaxRate[item.invoice_item_type_id]

        if (taxRateId) {
            const taxRate = taxRates.find(item => item.id === taxRateId)

            return taxRate && taxRate.percent
        }

        const values = this.state.values

        if (!supplier) {
            supplier = this.state.supplierInfo
        }

        if (!taxTransactionTypeId) {
            taxTransactionTypeId = values.tax_transaction_type_id
        }

        const taxRateHigh = taxRates && taxRates[0]
        const taxRateZero = taxRates && taxRates.find(item => !item.percent)

        if (
            company &&
            supplier &&
            company.country_is_in_eu &&
            String(taxTransactionTypeId) === String(constants.TAX_TRANSACTION_TYPE_DOMESTIC) &&
            item.loading_is_in_eu === item.unloading_is_in_eu
        ) {
            return taxRateHigh && taxRateHigh.percent
        }

        return taxRateZero && taxRateZero.percent
    }

    setTaxRateForItems = (company, supplier, data, taxTransactionTypeId) => {
        if (!this.initialTaxRatesSet) {
            this.initialTaxRatesSet = true

            return
        }

        const taxRates = company && company.country_id && data && data.tax_rates && data.tax_rates[company.country_id]
        const invoiceItemTypesTaxRate = helpers.getPrerequisitesPairs(
            this.props.prerequisites.values.get('invoice_item_types'),
            'id',
            'tax_rate_type_id'
        )

        const newItems = this.state.items.map(item => {
            return {
                ...item,
                tax_rate_percent: this.getItemTax(item, taxRates, invoiceItemTypesTaxRate, company, supplier, taxTransactionTypeId),
            }
        })

        this.setState({
            items: newItems,
        })
    }

    checkDuplicity = (supplierId, suppliersNumber, varSymbol) => {
        this.props.actions.checkReceivedInvoiceDuplicity({
            supplier_id: supplierId,
            suppliers_number: suppliersNumber,
            var_symbol: varSymbol,
            id: this.props.match.params.receivedInvoiceID,
        })
    }

    checkDuplicityCallback = data => {
        if (data.duplicity_found) {
            this.props.notify(
                {
                    title: this.props.intl.formatMessage({ id: 'alerts.titles.warning' }),
                    message: this.props.intl.formatMessage({ id: 'alerts.messages.duplicityFound' }),
                    position: 'tc',
                },
                'error'
            )
        }
        this.setState({
            duplicityFound: data.duplicity_found,
        })
    }

    // restore
    handleRestore = () => {
        this.showConfirmDialog({
            title: this.props.intl.formatMessage({ id: 'confirmDialog.itemRestore.title' }),
            message: this.props.intl.formatMessage({ id: 'confirmDialog.itemRestore.message' }),
            labelCancel: this.props.intl.formatMessage({ id: 'confirmDialog.itemRestore.cancel' }),
            labelAccept: this.props.intl.formatMessage({ id: 'confirmDialog.itemRestore.accept' }),
            classNameAccept: 'btn-success',
            onAccept: () => {
                this.props.actions.restoreReceivedInvoice(this.props.receivedInvoice.id)
                this.hideConfirmDialog()
            },
        })
    }

    // confirm dialog
    showConfirmDialog = options => {
        this.setState({
            confirmDialog: {
                show: true,
                title: options.title || this.props.intl.formatMessage({ id: 'confirmDialog.default.title' }),
                message: options.message || this.props.intl.formatMessage({ id: 'confirmDialog.default.message' }),
                labelCancel: options.labelCancel || this.props.intl.formatMessage({ id: 'confirmDialog.default.cancel' }),
                labelAccept: options.labelAccept || this.props.intl.formatMessage({ id: 'confirmDialog.default.accept' }),
                classNameAccept: options.classNameAccept || 'btn-success',
                classNameCancel: options.classNameCancel || 'btn-default',
                onCancel: options.onCancel || this.hideConfirmDialog,
                onAccept: options.onAccept || this.hideConfirmDialog,
            },
        })
    }

    hideConfirmDialog = () => {
        this.setState({
            confirmDialog: {
                show: false,
                onCancel: () => {},
                onAccept: () => {},
            },
        })
    }

    componentDidMount() {
        if (!this.props.match.params.receivedInvoiceID) {
            this.setState({
                autoSetDefaultValues: true,
            })
        }

        this.props.actions.fetchInvoicingReceivedInvoice(this.props.match.params.receivedInvoiceID)
        this.props.actions.fetchPrerequisites()
    }

    componentWillUnmount() {
        this.props.actions.clearTransportInfo()
        this.props.actions.clearSupplierInfo()
        this.props.actions.clearInvoicingReceivedInvoice()
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.receivedInvoiceFetching && !nextProps.receivedInvoiceFetching) {
            this.setDataForInvoicing(nextProps.receivedInvoice, nextProps.receivedInvoiceSupportData)
        }

        if (this.props.supplierInfoFetching && !nextProps.supplierInfoFetching && this.props.supplierInfo) {
            this.setSupplierInfo(nextProps.supplierInfo)
        }

        if (this.props.transportInfoFetching && !nextProps.transportInfoFetching && !nextProps.transportInfoError) {
            const countryId = this.props.transportInfo.supplier && this.props.transportInfo.supplier.country_id
            const nextCountryId = nextProps.transportInfo.supplier && nextProps.transportInfo.supplier.country_id

            let callback
            if (nextProps.transportInfo.supplier && countryId !== nextCountryId) {
                callback = () =>
                    this.setSupplierInfo(
                        nextProps.transportInfo.supplier,
                        nextProps.transportInfo.currency_id,
                        nextProps.transportInfo.company_cost_center_id,
                        nextProps.transportInfo.date_tax,
                        nextProps.transportInfo.disponent_id
                    )
            }

            this.setItemsValues(this.transportSearchItemKey, nextProps.transportInfo, callback)
        }

        if (this.props.getDuplicityChecking && !nextProps.getDuplicityChecking && !nextProps.getDuplicityCheckingError) {
            this.checkDuplicityCallback(nextProps.getDuplicityCheckingData)
        }

        // saving
        if (this.props.receivedInvoiceSaving && !nextProps.receivedInvoiceSaving) {
            if (nextProps.receivedInvoiceError === null && !nextProps.duplicities) {
                this.props.notify(
                    {
                        title: this.props.intl.formatMessage({ id: `alerts.titles.success` }),
                        message: this.props.intl.formatMessage({ id: `alerts.messages.saveSuccess` }),
                        position: 'tc',
                    },
                    'success'
                )
                this.successCallback && this.successCallback(nextProps)
            } else if (nextProps.receivedInvoiceError === null && nextProps.duplicities) {
                this.duplicityFoundCallback(nextProps.duplicities)
            } else {
                this.props.notify(
                    {
                        title: this.props.intl.formatMessage({ id: `alerts.titles.error` }),
                        message: nextProps.receivedInvoiceError,
                        position: 'tc',
                    },
                    'error'
                )
            }
        }
    }

    /* eslint-disable jsx-a11y/anchor-is-valid */
    render() {
        document.title = formatters.titleFormatter(this.props.intl.formatMessage({ id: 'modules.invoicingReceivedInvoices.heading' }))

        const data = this.state.data
        const receivedInvoiceItems = this.state.items

        const company =
            this.state.values.company_id &&
            data &&
            data.companies &&
            data.companies.find(item => String(item.id) === String(this.state.values.company_id))

        const currency =
            this.state.values.currency_id &&
            data &&
            data.currencies &&
            data.currencies.find(item => String(item.id) === String(this.state.values.currency_id))

        const companiesOptions =
            data && data.companies
                ? data.companies.map(item => ({
                      id: item.id,
                      name: item.name,
                  }))
                : []

        const costCentersOptions =
            company && company.cost_centers
                ? company.cost_centers.map(item => ({
                      id: item.id,
                      name: `${item.code} - ${item.name}`,
                  }))
                : []

        const numberingsOptions =
            company && company.numberings
                ? company.numberings.map(item => ({
                      id: item.id,
                      name: `${item.name} [${item.format}]`,
                  }))
                : []

        const currenciesOptions = helpers.getPrerequisitesArray(this.props.prerequisites.values.get('currencies'), 'id', 'iso_code')
        const paymentMethodsOptions = helpers.getPrerequisitesArray(this.props.prerequisites.values.get('payment_methods'))
        const carriersOptions = helpers.getPrerequisitesArray(
            this.props.prerequisites.values
                .get('carriers')
                .filter(carrier => carrier.get('is_blocked') === 0 || carrier.get('id') === this.state.values.supplier_id)
        )
        const invoiceItemTypes = helpers.getPrerequisitesArray(this.props.prerequisites.values.get('invoice_item_types'))
        const usersOptions = helpers.getPrerequisitesArray(this.props.prerequisites.values.get('users'))

        const taxTransactionTypesOptions = [
            {
                id: constants.TAX_TRANSACTION_TYPE_DOMESTIC,
                name: this.props.intl.formatMessage({ id: `taxTransactionTypes.${constants.TAX_TRANSACTION_TYPE_DOMESTIC}` }),
            },
            {
                id: constants.TAX_TRANSACTION_TYPE_EU,
                name: this.props.intl.formatMessage({ id: `taxTransactionTypes.${constants.TAX_TRANSACTION_TYPE_EU}` }),
            },
            {
                id: constants.TAX_TRANSACTION_TYPE_OTHER,
                name: this.props.intl.formatMessage({ id: `taxTransactionTypes.${constants.TAX_TRANSACTION_TYPE_OTHER}` }),
            },
            {
                id: constants.TAX_TRANSACTION_TYPE_NOT_SUBJECT_TO_VAT,
                name: this.props.intl.formatMessage({ id: `taxTransactionTypes.${constants.TAX_TRANSACTION_TYPE_NOT_SUBJECT_TO_VAT}` }),
            },
        ]

        const total = {
            exclVat: 0,
            vat: 0,
            inclVat: 0,
        }
        receivedInvoiceItems.forEach(item => {
            const taxRateValue = !isNaN(parseFloat(item.tax_rate_percent)) ? parseFloat(item.tax_rate_percent) : 0
            const exclVat = parseFloat(item.units_count) * parseFloat(item.unit_price)

            if (!isNaN(exclVat)) {
                total.exclVat += exclVat
                total.inclVat += exclVat * (1 + taxRateValue / 100)
            }
        })
        total.vat = total.inclVat - total.exclVat

        const duplicities = this.props.duplicities
        const isDisableField = helpers.isDisableField(Boolean(this.props.receivedInvoice), [PERMS.READ, PERMS.CREATE, PERMS.UPDATE])

        return (
            <PermissionsCheck hasAny={Object.values(PERMS)} noPermissionsPage>
                <div className="page-inner">
                    <div id="main-wrapper">
                        <div className="row">
                            <div className="col-lg-10 col-lg-offset-1 col-xl-8 col-xl-offset-2">
                                <div className="panel panel-white">
                                    <div className="panel-body panel-padding">
                                        <div className="panel-head">
                                            <h4>
                                                <FormattedMessage id="modules.invoicingReceivedInvoices.heading" /> -{' '}
                                                {this.props.receivedInvoice ? (
                                                    <FormattedMessage id="fields.itemEdit" />
                                                ) : (
                                                    <FormattedMessage id="fields.itemCreate" />
                                                )}
                                            </h4>
                                        </div>
                                        <LoadingOverlay
                                            active={
                                                this.props.prerequisites.status.get('fetching') ||
                                                this.props.receivedInvoiceFetching ||
                                                this.props.receivedInvoiceSaving ||
                                                this.props.transportInfoFetching ||
                                                this.props.supplierInfoFetching
                                            }
                                        >
                                            <PermissionsCheck hasAny={Object.values(PERMS)}>
                                                <Form
                                                    values={this.state.values}
                                                    validationRules={this.validationRules}
                                                    onChange={this.handleChangeValues}
                                                    onSubmit={this.handleSubmit}
                                                    isEdit={Boolean(this.props.receivedInvoice)}
                                                >

                                                    {this.props.receivedInvoice && this.props.receivedInvoice.deleted_at && (
                                                        <div className="row">
                                                            <div className="col-lg-12">
                                                                <div className="alert alert-danger m-b-lg">
                                                                    <div className="row">
                                                                        <div className="col-sm-8">
                                                                            <p className="no-m">
                                                                                <FormattedMessage id="alerts.messages.warningDeleted" />
                                                                            </p>
                                                                        </div>
                                                                        <PermissionsCheck has={[PERMS.RESTORE]}>
                                                                            <div className="col-sm-4 text-right">
                                                                                <button onClick={this.handleRestore} type="button" className="btn btn-danger">
                                                                                    <FormattedMessage id="buttons.restore" />
                                                                                </button>
                                                                            </div>
                                                                        </PermissionsCheck>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}

                                                    <div className="row">
                                                        <div className="col-md-6 b-r">
                                                            <SelectField
                                                                id="supplier_id"
                                                                label={this.props.intl.formatMessage({ id: 'fields.supplier' })}
                                                                values={carriersOptions}
                                                            />
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <div className={`form-group ${this.state.duplicityFound ? 'has-error' : ''}`}>
                                                                        <InputField
                                                                            id="suppliers_number"
                                                                            label={this.props.intl.formatMessage({ id: 'fields.suppliersNumber' })}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <div className={`form-group ${this.state.duplicityFound ? 'has-error' : ''}`}>
                                                                        <InputField
                                                                            id="var_symbol"
                                                                            label={this.props.intl.formatMessage({ id: 'fields.varSymbol' })}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <SelectField
                                                                        id="payment_method_id"
                                                                        label={this.props.intl.formatMessage({ id: 'fields.paymentMethod' })}
                                                                        values={paymentMethodsOptions}
                                                                    />
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <SelectField
                                                                        id="currency_id"
                                                                        label={this.props.intl.formatMessage({ id: 'fields.currency' })}
                                                                        values={currenciesOptions}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <SelectField
                                                                        id="tax_transaction_type_id"
                                                                        label={this.props.intl.formatMessage({ id: 'fields.taxTransactionType' })}
                                                                        values={taxTransactionTypesOptions}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-md-3">
                                                                    <div className="form-group w-200">
                                                                        <label htmlFor="value_date_issued">
                                                                            <FormattedMessage id="fields.dateIssue" />
                                                                        </label>
                                                                        <DatePicker
                                                                            id="value_date_issued"
                                                                            dateFormat="DD.MM.YYYY"
                                                                            autoComplete="off"
                                                                            selected={helpers.convertCSDateToMoment(this.state.datetimes.date_issued)}
                                                                            value={this.state.datetimes.date_issued}
                                                                            onChange={value => {
                                                                                this.handleDatetimeChange(
                                                                                    'date_issued',
                                                                                    value ? formatters.dateFormatter(value) : ''
                                                                                )
                                                                            }}
                                                                            onChangeRaw={e => {
                                                                                this.handleDatetimeChange('date_issued', e.target.value)
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-3">
                                                                    <div className="form-group w-200">
                                                                        <label htmlFor="value_date_received">
                                                                            <FormattedMessage id="fields.dateReceived" />
                                                                        </label>
                                                                        <DatePicker
                                                                            id="value_date_received"
                                                                            dateFormat="DD.MM.YYYY"
                                                                            autoComplete="off"
                                                                            selected={helpers.convertCSDateToMoment(
                                                                                this.state.datetimes.date_received
                                                                            )}
                                                                            value={this.state.datetimes.date_received}
                                                                            onChange={value => {
                                                                                this.handleDatetimeChange(
                                                                                    'date_received',
                                                                                    value ? formatters.dateFormatter(value) : ''
                                                                                )
                                                                            }}
                                                                            onChangeRaw={e => {
                                                                                this.handleDatetimeChange('date_received', e.target.value)
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-3">
                                                                    <div className="form-group w-200">
                                                                        <label htmlFor="value_date_due">
                                                                            <FormattedMessage id="fields.dateDue" />
                                                                        </label>
                                                                        <DatePicker
                                                                            id="value_date_due"
                                                                            dateFormat="DD.MM.YYYY"
                                                                            autoComplete="off"
                                                                            selected={helpers.convertCSDateToMoment(this.state.datetimes.date_due)}
                                                                            value={this.state.datetimes.date_due}
                                                                            onChange={value => {
                                                                                this.handleDatetimeChange(
                                                                                    'date_due',
                                                                                    value ? formatters.dateFormatter(value) : ''
                                                                                )
                                                                            }}
                                                                            onChangeRaw={e => {
                                                                                this.handleDatetimeChange('date_due', e.target.value)
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-3">
                                                                    <div className="form-group w-200">
                                                                        <label htmlFor="value_date_tax">
                                                                            <FormattedMessage id="fields.dateTax" />
                                                                        </label>
                                                                        <DatePicker
                                                                            id="value_date_tax"
                                                                            dateFormat="DD.MM.YYYY"
                                                                            autoComplete="off"
                                                                            selected={helpers.convertCSDateToMoment(this.state.datetimes.date_tax)}
                                                                            value={this.state.datetimes.date_tax}
                                                                            onChange={value => {
                                                                                this.handleDatetimeChange(
                                                                                    'date_tax',
                                                                                    value ? formatters.dateFormatter(value) : ''
                                                                                )
                                                                            }}
                                                                            onChangeRaw={e => {
                                                                                this.handleDatetimeChange('date_tax', e.target.value)
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-md-3">
                                                                    <div className="form-group">
                                                                        <label htmlFor="value_paid_at">
                                                                            <FormattedMessage id="fields.paidAt" />
                                                                        </label>
                                                                        <DatePicker
                                                                            id="value_paid_at"
                                                                            dateFormat="DD.MM.YYYY"
                                                                            autoComplete="off"
                                                                            selected={helpers.convertCSDateToMoment(this.state.datetimes.paid_at)}
                                                                            value={this.state.datetimes.paid_at}
                                                                            onChange={value => {
                                                                                this.handleDatetimeChange(
                                                                                    'paid_at',
                                                                                    value ? formatters.dateFormatter(value) : ''
                                                                                )
                                                                            }}
                                                                            onChangeRaw={e => {
                                                                                this.handleDatetimeChange('paid_at', e.target.value)
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-3">
                                                                    <InputField
                                                                        id="paid_amount"
                                                                        label={this.props.intl.formatMessage({ id: 'fields.paidAmount' })}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-6">
                                                            <SelectField
                                                                id="company_id"
                                                                label={this.props.intl.formatMessage({ id: 'fields.company' })}
                                                                values={companiesOptions}
                                                            />
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <SelectField
                                                                        id="company_cost_center_id"
                                                                        label={this.props.intl.formatMessage({ id: 'fields.costCenter' })}
                                                                        values={costCentersOptions}
                                                                    />
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <SelectField
                                                                        id="cost_user_id"
                                                                        label={this.props.intl.formatMessage({ id: 'fields.costUser' })}
                                                                        values={usersOptions}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <SelectField
                                                                        id="company_numbering_id"
                                                                        label={this.props.intl.formatMessage({ id: 'fields.numbering' })}
                                                                        values={numberingsOptions}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <InputField
                                                                id="note"
                                                                className="mh-50"
                                                                label={this.props.intl.formatMessage({ id: 'fields.note' })}
                                                                autosize
                                                            />
                                                        </div>
                                                    </div>
                                                    <hr />
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <table className="table table-striped table-hover">
                                                                <thead>
                                                                    <tr>
                                                                        <th className="w-70">
                                                                            <FormattedMessage id="fields.count" />
                                                                        </th>
                                                                        <th className="w-70 text-center">
                                                                            <FormattedMessage id="fields.unit" />
                                                                        </th>
                                                                        <th className="w-max wm-400">
                                                                            <FormattedMessage id="fields.item" />
                                                                        </th>
                                                                        <th className="w-100 text-right">
                                                                            <FormattedMessage id="fields.unitPrice" />
                                                                        </th>
                                                                        <th className="w-80">
                                                                            <FormattedMessage id="fields.vat" /> %
                                                                        </th>
                                                                        <th className="w-100">
                                                                            <FormattedMessage id="fields.invoiceItemType" />
                                                                        </th>
                                                                        {!isDisableField && <th className="w-40" />}
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {receivedInvoiceItems.map((item, key) => (
                                                                        // eslint-disable-next-line react/no-array-index-key
                                                                        <tr key={`receivedInvoiceItems-${key}`}>
                                                                            <td className="text-right">
                                                                                <input
                                                                                    type="text"
                                                                                    value={item.units_count}
                                                                                    className="form-control"
                                                                                    onChange={e => {
                                                                                        this.handleChangeItemValues(
                                                                                            key,
                                                                                            'units_count',
                                                                                            e.target.value
                                                                                        )
                                                                                    }}
                                                                                    disabled={isDisableField}
                                                                                />
                                                                            </td>
                                                                            <td className="text-center">
                                                                                <input
                                                                                    type="text"
                                                                                    value={item.unit}
                                                                                    className="form-control text-center"
                                                                                    onChange={e => {
                                                                                        this.handleChangeItemValues(key, 'unit', e.target.value)
                                                                                    }}
                                                                                    disabled={isDisableField}
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <div>
                                                                                    <input
                                                                                        type="text"
                                                                                        value={item.name}
                                                                                        className="form-control wp-50 m-b-xxs pull-left"
                                                                                        onChange={e => {
                                                                                            this.handleChangeItemValues(key, 'name', e.target.value)
                                                                                        }}
                                                                                        placeholder={this.props.intl.formatMessage({
                                                                                            id: 'fields.name',
                                                                                        })}
                                                                                        disabled={isDisableField}
                                                                                    />
                                                                                    <div className="input-group wp-20 m-b-xxs pull-right">
                                                                                        <input
                                                                                            type="text"
                                                                                            value={item.transport_id}
                                                                                            className="form-control"
                                                                                            onChange={e => {
                                                                                                this.handleChangeItemValues(
                                                                                                    key,
                                                                                                    'transport_id',
                                                                                                    e.target.value
                                                                                                )
                                                                                            }}
                                                                                            placeholder={this.props.intl.formatMessage({
                                                                                                id: 'fields.transportNo',
                                                                                            })}
                                                                                            disabled={isDisableField}
                                                                                        />
                                                                                        <span className="input-group-btn">
                                                                                            <button
                                                                                                onClick={() => {
                                                                                                    this.handleTransportSearchButtonClick(key)
                                                                                                }}
                                                                                                className="btn btn-default"
                                                                                                type="button"
                                                                                                disabled={isDisableField}
                                                                                            >
                                                                                                <span className="far fa-search" />
                                                                                            </button>
                                                                                        </span>
                                                                                    </div>
                                                                                </div>
                                                                                <input
                                                                                    type="text"
                                                                                    value={item.text}
                                                                                    className="form-control wp-100"
                                                                                    onChange={e => {
                                                                                        this.handleChangeItemValues(key, 'text', e.target.value)
                                                                                    }}
                                                                                    placeholder={this.props.intl.formatMessage({
                                                                                        id: 'fields.description',
                                                                                    })}
                                                                                    disabled={isDisableField}
                                                                                />
                                                                            </td>
                                                                            <td className="text-right">
                                                                                <input
                                                                                    type="text"
                                                                                    value={item.unit_price}
                                                                                    className="form-control text-right"
                                                                                    onChange={e => {
                                                                                        this.handleChangeItemValues(key, 'unit_price', e.target.value)
                                                                                    }}
                                                                                    disabled={isDisableField}
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <input
                                                                                    type="text"
                                                                                    value={item.tax_rate_percent}
                                                                                    className="form-control text-right"
                                                                                    onChange={e => {
                                                                                        this.handleChangeItemValues(
                                                                                            key,
                                                                                            'tax_rate_percent',
                                                                                            e.target.value
                                                                                        )
                                                                                    }}
                                                                                    disabled={isDisableField}
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <select
                                                                                    value={item.invoice_item_type_id}
                                                                                    className="form-control"
                                                                                    onChange={e => {
                                                                                        this.handleChangeItemType(
                                                                                            key,
                                                                                            'invoice_item_type_id',
                                                                                            e.target.value
                                                                                        )
                                                                                    }}
                                                                                    disabled={isDisableField}
                                                                                >
                                                                                    {invoiceItemTypes.map(option => (
                                                                                        <option
                                                                                            // eslint-disable-next-line react/no-array-index-key
                                                                                            key={`invoiceItemTypes-${key}-${option.id}`}
                                                                                            value={option.id}
                                                                                        >
                                                                                            {option.name}
                                                                                        </option>
                                                                                    ))}
                                                                                </select>
                                                                            </td>
                                                                            {!isDisableField && (
                                                                                <td className="text-center">
                                                                                    <a
                                                                                        href="#"
                                                                                        className="display-inline m-t-xxs text-gray-light text-md"
                                                                                        onClick={e => {
                                                                                            this.handleRemoveItem(e, key)
                                                                                        }}
                                                                                    >
                                                                                        <i className="far fa-trash" />
                                                                                    </a>
                                                                                </td>
                                                                            )}
                                                                        </tr>
                                                                    ))}

                                                                    <tr className="transparent">
                                                                        <td colSpan="3" className="text-right">
                                                                            <strong>
                                                                                <FormattedMessage id="fields.totalExclVat" />:
                                                                            </strong>
                                                                        </td>
                                                                        <td colSpan="2" className="text-right">
                                                                            <strong>
                                                                                {formatters.priceFormatter(
                                                                                    total.exclVat,
                                                                                    '0,0.00',
                                                                                    currency ? currency.iso_code : ''
                                                                                )}
                                                                            </strong>
                                                                        </td>
                                                                        <td />
                                                                        {!isDisableField && <td />}
                                                                    </tr>
                                                                    <tr className="transparent">
                                                                        <td colSpan="3" className="text-right">
                                                                            <strong>
                                                                                <FormattedMessage id="fields.vat" />:
                                                                            </strong>
                                                                        </td>
                                                                        <td colSpan="2" className="text-right">
                                                                            <strong>
                                                                                {formatters.priceFormatter(
                                                                                    total.vat,
                                                                                    '0,0.00',
                                                                                    currency ? currency.iso_code : ''
                                                                                )}
                                                                            </strong>
                                                                        </td>
                                                                        <td />
                                                                        {!isDisableField && <td />}
                                                                    </tr>
                                                                    <tr className="transparent">
                                                                        <td colSpan="3" className="text-right">
                                                                            <strong>
                                                                                <FormattedMessage id="fields.totalInclVat" />:
                                                                            </strong>
                                                                        </td>
                                                                        <td colSpan="2" className="text-right">
                                                                            <strong>
                                                                                {formatters.priceFormatter(
                                                                                    total.inclVat,
                                                                                    '0,0.00',
                                                                                    currency ? currency.iso_code : ''
                                                                                )}
                                                                            </strong>
                                                                        </td>
                                                                        <td />
                                                                        {!isDisableField && <td />}
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                            {!isDisableField && (
                                                                <div className="row m-b-md">
                                                                    <div className="col-md-6">
                                                                        <button
                                                                            className="btn btn-default btn-addon"
                                                                            type="button"
                                                                            onClick={e => {
                                                                                this.handleAddItem(e)
                                                                            }}
                                                                        >
                                                                            <i className="far fa-plus" /> <FormattedMessage id="buttons.addItem" />
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>

                                                    {this.props.receivedInvoice && (
                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                <h4>
                                                                    <FormattedMessage id="fields.files" />
                                                                </h4>
                                                            </div>
                                                            <div className="col-md-12">
                                                                <InvoicingReceivedInvoicesFiles receivedInvoice={this.props.receivedInvoice} />
                                                            </div>
                                                        </div>
                                                    )}

                                                    <div className="btns-form">
                                                        <Link
                                                            to={helpers.url(this.props.match, 'invoicing-received-invoices')}
                                                            className="btn btn-addon btn-default"
                                                        >
                                                            <i className="far fa-chevron-left" /> <FormattedMessage id="buttons.back" />
                                                        </Link>
                                                        {this.props.receivedInvoice && (
                                                            <SubmitButton
                                                                saveAndClose
                                                                onClick={this.handleSaveAndClose}
                                                                isEdit={Boolean(this.props.receivedInvoice)}
                                                                perms={[PERMS.CREATE, PERMS.UPDATE]}
                                                                className="btn-addon pull-right m-l-sm"
                                                            />
                                                        )}
                                                        <SubmitButton
                                                            isEdit={Boolean(this.props.receivedInvoice)}
                                                            perms={[PERMS.CREATE, PERMS.UPDATE]}
                                                            className="btn-addon pull-right"
                                                        />
                                                    </div>
                                                </Form>
                                            </PermissionsCheck>
                                        </LoadingOverlay>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Modal show={Boolean(this.state.modalDuplicity.show)} onHide={this.handleDuplicityCancel}>
                        <Modal.Header closeButton>
                            <Modal.Title>
                                <FormattedMessage id="confirmDialog.duplicityFound.title" />
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <FormattedMessage id="confirmDialog.duplicityFound.message" />
                            <table className="table table-striped table-hover m-t-lg">
                                <thead>
                                    <tr>
                                        <th className="w-120">
                                            <FormattedMessage id="fields.number" />
                                        </th>
                                        <th className="w-160">
                                            <FormattedMessage id="fields.suppliersNumber" />
                                        </th>
                                        <th className="w-120">
                                            <FormattedMessage id="fields.varSymbol" />
                                        </th>
                                        <th className="w-120 text-center">
                                            <FormattedMessage id="fields.dateReceived" />
                                        </th>
                                        <th className="w-max">
                                            <FormattedMessage id="fields.supplier" />
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {duplicities &&
                                        duplicities.length &&
                                        duplicities.map(duplicity => (
                                            <tr key={`duplicity-${duplicity.id}`}>
                                                <td>{duplicity.number}</td>
                                                <td>{duplicity.suppliers_number}</td>
                                                <td>{duplicity.var_symbol}</td>
                                                <td className="text-center">{duplicity.date_received}</td>
                                                <td>{duplicity.supplier}</td>
                                            </tr>
                                        ))}
                                </tbody>
                            </table>
                        </Modal.Body>
                        <Modal.Footer>
                            <button className="btn btn-success m-r-xs" onClick={this.handleDuplicityConfirm}>
                                <FormattedMessage id="confirmDialog.duplicityFound.accept" />
                            </button>
                            <button className="btn btn-default" onClick={this.handleDuplicityCancel}>
                                <FormattedMessage id="confirmDialog.duplicityFound.cancel" />
                            </button>
                        </Modal.Footer>
                    </Modal>
                </div>
                <ConfirmDialog options={this.state.confirmDialog} />
            </PermissionsCheck>
        )
    }
}

function mapStateToProps(state) {
    return {
        receivedInvoice: getCurrentReceivedInvoice(state),
        receivedInvoiceSupportData: getCurrentSupportData(state),
        receivedInvoiceFetching: getCurrentReceivedInvoiceFetching(state),
        receivedInvoiceSaving: getCurrentReceivedInvoiceSaving(state),
        receivedInvoiceError: getCurrentReceivedInvoiceError(state),
        transportInfo: getTransportInfo(state),
        transportInfoFetching: getTransportInfoFetching(state),
        transportInfoError: getTransportInfoError(state),
        prerequisites: preparePrerequisitesFetchStatusSelectors(componentIdentifier, prerequisites, state),
        duplicities: createGetDuplicitiesSelector()(state),
        supplierInfo: getSupplierInfo(state),
        supplierInfoFetching: getSupplierInfoFetching(state),
        getDuplicityChecking: getReceivedInvoiceDuplicityChecking(state),
        getDuplicityCheckingError: getReceivedInvoiceDuplicityCheckingError(state),
        getDuplicityCheckingData: getReceivedInvoiceDuplicityCheckingData(state),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            ...bindActionCreators(
                {
                    ...actionCreators,
                    ...preparePrerequisitesActionCreators(componentIdentifier, prerequisites),
                },
                dispatch
            ),
        },
        redirect: toURL => dispatch(push(toURL)),
        notify: (notification, type) => dispatch(Notifications.show(notification, type)),
    }
}

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(InvoicingReceivedInvoicesEdit)
)
