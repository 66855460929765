import React, { Component } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'

import * as formatters from '../../../common/formatters'

import DriversHistoryDrivers from './DriversHistoryDrivers'
import DriversHistoryList from './DriversHistoryList'
import PermissionsCheck from '../../auth/components/PermissionsCheck'
import { PERMS_HISTORY } from '../permissions'

class DriversHistory extends Component {
    state = {
        selectedDriver: null,
    }

    selectDriver = driver => {
        this.setState({
            selectedDriver: driver,
        })
    }

    render() {
        document.title = formatters.titleFormatter(this.props.intl.formatMessage({ id: 'modules.driverHistory.heading' }))

        const selectedDriver = this.state.selectedDriver

        return (
            <PermissionsCheck hasAny={Object.values(PERMS_HISTORY)} noPermissionsPage>
                <div className="page-inner">
                    <div id="main-wrapper">
                        <div className="panel panel-white">
                            <div className="panel-body">
                                <div className="row hp-100">
                                    <div className="col-md-6 hp-100 b-r">
                                        <div className="pull-left wp-100">
                                            <h4>
                                                <FormattedMessage id="modules.driverHistory.heading" />
                                            </h4>
                                        </div>
                                        <DriversHistoryDrivers selectedDriver={selectedDriver} onDriverSelect={this.selectDriver} />
                                    </div>
                                    <div className="col-md-6 hp-100">
                                        {selectedDriver && <DriversHistoryList driver={selectedDriver} showDriverName />}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </PermissionsCheck>
        )
    }
}

export default injectIntl(DriversHistory)
