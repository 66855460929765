import React, { Component } from 'react'
import { SortableHandle } from 'react-sortable-hoc'
import { FormattedMessage, injectIntl } from 'react-intl'

import * as config from '../../../common/config'
import { datetimePeriodFormatter, gpsFormatter, datetimeFormatter } from '../../../common/formatters'
import { getFirstExistValue } from '../../../common/helpers'
import PermissionsCheck from '../../auth/components/PermissionsCheck'
import { PERMS_ROUTES } from '../permissions'

const DragHandle = SortableHandle(() => <i className="far fa-bars m-r-sm sort-handle" />)

class TransportTemplatesEditRoutePoint extends Component {
    handleEditButtonClicked = () => {
        this.props.handleEditButtonClicked && this.props.handleEditButtonClicked()
    }

    handleDeleteButtonClicked = () => {
        this.props.handleDeleteButtonClicked && this.props.handleDeleteButtonClicked()
    }

    /* eslint-disable jsx-a11y/anchor-is-valid */
    render() {
        const point = this.props.point
        const pointType = this.props.pointType

        const datetimeFrom = getFirstExistValue([point.specifiedFrom, point.plannedFrom])
        const datetime = getFirstExistValue([point.finishedAt, point.arrivedAt, datetimeFrom])

        return (
            <div className={`panel panel-${pointType && pointType.className}`}>
                <div className="panel-heading">
                    <span className="pull-left">
                        <PermissionsCheck hasAny={[PERMS_ROUTES.UPDATE, PERMS_ROUTES.CREATE]}>
                            <DragHandle />
                        </PermissionsCheck>
                        <span className="type">{pointType && this.props.intl.formatMessage({ id: pointType.name })}</span>
                        {point.city && `${point.country_code} / ${point.zipcode} / ${point.city}`}
                    </span>
                    <span className="pull-right">
                        <span className="m-l-lg w-120 display-inline">{datetime && datetimeFormatter(datetime)}</span>
                        <PermissionsCheck has={[PERMS_ROUTES.UPDATE]}>
                            <a href="#" onClick={this.handleEditButtonClicked} className="m-l-lg">
                                <i className="far fa-pencil" /> <FormattedMessage id="buttons.edit" />
                            </a>
                        </PermissionsCheck>
                        <PermissionsCheck has={[PERMS_ROUTES.DELETE]}>
                            <a href="#" onClick={this.handleDeleteButtonClicked} className="m-l-lg">
                                <i className="far fa-trash" /> <FormattedMessage id="buttons.delete" />
                            </a>
                        </PermissionsCheck>
                    </span>
                </div>
                {!pointType.noDetails && (
                    <div className="panel-body">
                        <div className="row">
                            <div className="col-md-9">
                                <div className="row">
                                    <div className="col-md-4">
                                        <span className="text-gray-light">
                                            <FormattedMessage id="fields.address" />:
                                        </span>
                                        <p>
                                            {point.name && (
                                                <span>
                                                    <strong>{point.name}</strong>
                                                    <br />
                                                </span>
                                            )}
                                            {point.street && `${point.street},`}
                                            <br />
                                            {point.zipcode} {point.city && `${point.city},`}
                                            <br />
                                            {point.country_code}
                                            <br />
                                            {point.gps_lat &&
                                                point.gps_lng &&
                                                `${this.props.intl.formatMessage({ id: 'fields.gps' })}: ${gpsFormatter(
                                                    point.gps_lat,
                                                    point.gps_lng
                                                )}`}
                                        </p>
                                    </div>
                                    <div className="col-md-4">
                                        <span className="text-gray-light">
                                            <FormattedMessage id="fields.times" />:
                                        </span>
                                        <p className="times">
                                            <span className="left">
                                                <FormattedMessage id="fields.planned" />:
                                            </span>
                                            {datetimePeriodFormatter(point.plannedFrom, point.plannedTo)}
                                        </p>
                                    </div>
                                    <div className="col-md-4">
                                        <span className="text-gray-light">
                                            <FormattedMessage id="fields.details" />:
                                        </span>
                                        <p className="cargo">
                                            {point.data &&
                                                point.data.map((item, key) => (
                                                    // eslint-disable-next-line react/no-array-index-key
                                                    <span key={`data-${key}`}>
                                                        <span className="left">{this.props.intl.formatMessage({ id: item.label })}:</span>
                                                        {item.value !== '' && (
                                                            <span className="right">
                                                                {item.formatter ? item.formatter(item.value) : item.value}
                                                                {item.unit ? ` ${item.unit}` : ''}
                                                            </span>
                                                        )}
                                                        <br />
                                                    </span>
                                                ))}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                {point.gps_lat && point.gps_lng && (
                                    <a href="#" onClick={this.handleEditButtonClicked}>
                                        <img
                                            className="wp-100 b"
                                            src={`https://image.maps.cit.api.here.com/mia/1.6/mapview?app_id=${
                                                config.CFG_HERE_MAPS_APP_ID
                                            }&app_code=${config.CFG_HERE_MAPS_APP_CODE}&c=${point.gps_lat},${point.gps_lng}&z=16&w=300&h=140`}
                                            alt=""
                                        />
                                    </a>
                                )}
                            </div>
                        </div>

                        {point.note && (
                            <div className="form-part form-part-transport pull-left wp-100">
                                <span className="pull-left w-140 text-gray">
                                    <FormattedMessage id="fields.note" />:
                                </span>
                                <span className="pull-left wrapped">{point.note}</span>
                            </div>
                        )}

                        {Boolean(point.is_booking_required) && pointType.showBookingTime && (
                            <div className="form-part form-part-transport pull-left wp-100">
                                <span className="pull-left w-140 text-gray">
                                    <FormattedMessage id="fields.booking" />:
                                </span>
                                {point.booking_url && (
                                    <a href={point.booking_url} target="_blank" rel="noopener noreferrer" className="pull-left m-r-sm">
                                        <i className="far fa-external-link" />
                                    </a>
                                )}
                                {point.booking_note && <span className="pull-left wrapped">{point.booking_note}</span>}
                            </div>
                        )}
                    </div>
                )}
            </div>
        )
    }
}

export default injectIntl(TransportTemplatesEditRoutePoint)
