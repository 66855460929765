import React, { Component } from 'react'
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete'
import { FormattedMessage, injectIntl } from 'react-intl'
import scriptLoader from 'react-async-script-loader'
import * as config from '../../../common/config'

import HEREMap from './HEREMap'

class HEREMapAddressPicker extends Component {
    isComponentMounted = false

    state = {
        mapZoom: 7,
        mapCenter: {
            lat: 49.758924,
            lng: 15.546382,
        },
        mapMarkerCenter: null,
        mapMarkerCenterChanged: false,
        mapMarkerCenterSource: null,
        mapAddress: null,
        fieldGPSValue: '',
        fieldPlacesAutocompleteValue: '',
    }

    setMapZoom = zoom => {
        const newState = {
            mapZoom: zoom,
        }

        this.setState(newState)
    }

    setMapCenter = coord => {
        if (coord) {
            const newState = {
                mapCenter: coord,
            }

            this.setState(newState)
        }
    }

    setMarkerCenter = (coord, source) => {
        const mapMarkerCenterChanged = this.state.mapMarkerCenterChanged || source > 0

        if (coord && JSON.stringify(coord) !== JSON.stringify(this.state.mapMarkerCenter)) {
            this.setState(
                {
                    mapMarkerCenter: coord,
                    mapMarkerCenterChanged,
                    mapMarkerCenterSource: source,
                },
                () => {
                    mapMarkerCenterChanged && this.props.handleMarkerPositionChanged && this.props.handleMarkerPositionChanged(coord, source)
                }
            )
        }
    }

    setAddress = address => {
        const mapMarkerCenterSource = this.state.mapMarkerCenterSource

        if (JSON.stringify(address) !== JSON.stringify(this.state.mapAddress)) {
            this.setState(
                {
                    mapAddress: address,
                },
                () => {
                    mapMarkerCenterSource > 0 && this.props.handleAddressChanged && this.props.handleAddressChanged(address)
                }
            )
        }
    }

    setFieldGPSValue = value => {
        const newState = {
            fieldGPSValue: value,
        }

        this.setState(newState)
    }

    setFieldPlacesAutocompleteValue = value => {
        const newState = {
            fieldPlacesAutocompleteValue: value,
        }

        this.setState(newState)
    }

    handleMarkerPositionChanged = coord => {
        this.setMarkerCenter(coord, 3)
    }

    handleAddressChanged = (result, coord) => {
        const resultAddress = result && result.location && result.location.address

        const streetParts = []
        resultAddress && resultAddress.street && streetParts.push(resultAddress.street)
        resultAddress && resultAddress.houseNumber && streetParts.push(resultAddress.houseNumber)

        let company = ''
        const searchedValue = this.state.fieldPlacesAutocompleteValue
        if (searchedValue && resultAddress) {
            let parts = []
            if (resultAddress.street && searchedValue.indexOf(`, ${resultAddress.street}`) > 0) {
                parts = searchedValue.split(`, ${resultAddress.street}`)
            } else if (resultAddress.city && searchedValue.indexOf(`, ${resultAddress.city}`) > 0) {
                parts = searchedValue.split(`, ${resultAddress.city}`)
            }

            if (parts.length > 1) {
                company = parts[0]
            }
        }

        this.setAddress({
            company,
            street: streetParts.join(' '),
            city: resultAddress && resultAddress.city,
            zipcode: resultAddress && resultAddress.postalCode,
            country_code: resultAddress && resultAddress.country,
            gps: coord,
        })
    }

    handleFieldGPSChanged = event => {
        this.setFieldGPSValue(event.target.value)
    }

    handleFieldGPSKeyUp = event => {
        if (event.key === 'Enter') {
            event.preventDefault()
            this.handleFieldGPSButtonClicked()
        }
    }

    handleFieldGPSButtonClicked = () => {
        if (this.state.fieldGPSValue) {
            const lat = parseFloat(this.state.fieldGPSValue.replace(/\s*,.*/, ''), 10)
            const lng = parseFloat(this.state.fieldGPSValue.replace(/.*,\s*/, ''), 10)

            const coord = lat &&
                lng && {
                    lat,
                    lng,
                }

            if (coord) {
                this.setMarkerCenter(coord, 2)
                this.setMapCenter(coord)
                this.setMapZoom(16)
            }
        }
    }

    handleFieldPlacesAutocompleteChanged = value => {
        this.setFieldPlacesAutocompleteValue(value)
    }

    handleFieldPlacesAutocompleteSubmitted = value => {
        geocodeByAddress(value)
            .then(results => getLatLng(results[0]))
            .then(latLng => {
                this.setMarkerCenter(latLng, 1)
                this.setMapCenter(latLng)
                this.setMapZoom(16)
            })
    }

    componentDidMount() {
        this.isComponentMounted = true
    }

    componentWillUnmount() {
        this.isComponentMounted = false
    }

    componentWillReceiveProps(nextProps) {
        if (
            this.isComponentMounted &&
            nextProps.markerCenter &&
            JSON.stringify(this.state.mapMarkerCenter) !== JSON.stringify(nextProps.markerCenter)
        ) {
            this.setMarkerCenter(nextProps.markerCenter, 0)
            this.setMapCenter(nextProps.markerCenter)
            this.setMapZoom(16)
        }
    }

    render() {
        const placesAutocompleteStyles = {
            autocompleteItemActive: {
                backgroundColor: '#f6f6f6',
            },
            googleLogoImage: {
                width: 100,
            },
        }

        return (
            <div className={'address-picker'}>
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group">
                            <label htmlFor="places_autocomplete">
                                <FormattedMessage id="fields.findByAddress" />:
                            </label>
                            {this.props.isScriptLoaded &&
                                this.props.isScriptLoadSucceed && (
                                    <PlacesAutocomplete
                                        inputProps={{
                                            value: this.state.fieldPlacesAutocompleteValue,
                                            onChange: this.handleFieldPlacesAutocompleteChanged,
                                        }}
                                        onSelect={this.handleFieldPlacesAutocompleteSubmitted}
                                        styles={placesAutocompleteStyles}
                                        id="places_autocomplete"
                                    />
                                )}
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label htmlFor="address_picker_search">
                                <FormattedMessage id="fields.findByGPS" />:
                            </label>
                            <div className="input-group m-b-sm">
                                <input
                                    type="text"
                                    id="address_picker_search"
                                    className="form-control"
                                    onChange={this.handleFieldGPSChanged}
                                    onKeyPress={this.handleFieldGPSKeyUp}
                                    placeholder={`${this.props.intl.formatMessage({ id: 'fields.eg' })}: 50.638220, 13.825418`}
                                />
                                <span className="input-group-btn">
                                    <button onClick={this.handleFieldGPSButtonClicked} className="btn btn-default" type="button">
                                        <FormattedMessage id="buttons.find" />
                                    </button>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className={`col-md-12 ${this.props.className}`}>
                        <HEREMap
                            id={this.props.id ? this.props.id : 'mapContainerAddressPicker'}
                            appId={this.props.appId}
                            appCode={this.props.appCode}
                            center={this.state.mapCenter}
                            zoom={this.state.mapZoom}
                            markerCenter={this.state.mapMarkerCenter}
                            className={this.props.className}
                            handleMarkerPositionChanged={this.handleMarkerPositionChanged}
                            handleAddressChanged={this.handleAddressChanged}
                            showPOIs={this.props.showPOIs}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

export default injectIntl(
    scriptLoader(`https://maps.googleapis.com/maps/api/js?key=${config.CFG_GOOGLE_API_KEY}&libraries=places`)(HEREMapAddressPicker)
)
