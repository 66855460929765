import { Observable } from 'rxjs'
import { normalize, schema } from 'normalizr'

import { request, parseAPIError, DownloadRequest } from '../../common/api'
import { trailerSchema } from '../../common/normalizrSchemas'
import { mapNestedRecordsToRecordMap, normalizedEntitiesToRecordMap } from '../../common/helpers'

import * as actionTypes from './actionTypes'
import * as actionCreators from './actionCreators'
import * as tableModelActionTypes from '../../common/table/actionTypes'
import * as tableModelActionCreators from '../../common/table/actionCreators'
import { resolveModelState } from '../../common/table/helpers'
import { Trailer } from './model'
import { TrailerType } from '../trailer_types/model'
import * as trailerTypesActionCreators from '../trailer_types/actionCreators'
import { TrailerCurrentPosition } from '../trailer_current_positions/model'
import * as trailerCurrentPositionsActionCreators from '../trailer_current_positions/actionCreators'
import { Company } from '../companies/model'
import * as companiesActionCreators from '../companies/actionCreators'
import { CompanyCostCenter } from '../company_cost_centers/model'
import * as companyCostCenterActionCreators from '../company_cost_centers/actionCreators'
import { Carrier } from '../carriers/model'
import * as carriersActionCreators from '../carriers/actionCreators'
import { VehicleEvent } from '../vehicle_events/model'
import * as vehiclesActionCreators from '../vehicles/actionCreators'
import { Vehicle } from '../vehicles/model'
import * as usersActionCreators from '../users/actionCreators'
import { User } from '../users/model'

// Fetch

const fetchTrailersEpic = (action$, store) => {
    const tableIdentifier = 'trailers_list'
    const serverSide = true

    const triggeringAction = serverSide
        ? action$.ofType(actionTypes.FETCH, tableModelActionTypes.CONFIGURATION_CHANGED)
        : action$.ofType(actionTypes.FETCH)

    return triggeringAction
        .filter(action => action.type !== tableModelActionTypes.CONFIGURATION_CHANGED || action.payload.tableIdentifier === tableIdentifier)
        .switchMap(action => {
            const modelState = resolveModelState(tableIdentifier, store.getState(), action)

            const requestParams = {
                method: 'GET',
                path: `trailers/list`,
            }

            if (serverSide) {
                requestParams.method = 'POST'
                requestParams.path += `?page=${modelState.getIn(['pagination', 'current']) + 1}`
                requestParams.body = {
                    sorting: modelState.get('sorting').toJS(),
                    filters: modelState.get('filters').toJS(),
                }
            }

            return Observable.concat(
                Observable.of({
                    type: actionTypes.FETCH_STARTED,
                }),
                request(requestParams)
                    .switchMap(ajaxResponse => {
                        const normalizedData = normalize(
                            serverSide ? ajaxResponse.response.data : ajaxResponse.response,
                            new schema.Array(trailerSchema)
                        )

                        let trailers = normalizedEntitiesToRecordMap(normalizedData.entities.trailers, Trailer, normalizedData.result)
                        const trailerTypes = normalizedEntitiesToRecordMap(normalizedData.entities.trailer_types, TrailerType)
                        const trailerCurrentPositions = normalizedEntitiesToRecordMap(
                            normalizedData.entities.trailer_current_positions,
                            TrailerCurrentPosition
                        )
                        const companies = normalizedEntitiesToRecordMap(normalizedData.entities.companies, Company)
                        const companyCostCenters = normalizedEntitiesToRecordMap(normalizedData.entities.company_cost_centers, CompanyCostCenter)
                        const carriers = normalizedEntitiesToRecordMap(normalizedData.entities.carriers, Carrier)
                        const vehicleEvents = normalizedEntitiesToRecordMap(normalizedData.entities.vehicle_events, VehicleEvent)
                        const vehicles = normalizedEntitiesToRecordMap(normalizedData.entities.vehicles, Vehicle)
                        const users = normalizedEntitiesToRecordMap(normalizedData.entities.users, User)

                        trailers = mapNestedRecordsToRecordMap(
                            trailers,
                            {
                                last_finished_vehicle_event: { data: vehicleEvents, recordClass: VehicleEvent },
                            },
                            Trailer
                        )

                        const observables = [
                            Observable.of(trailerTypesActionCreators.fetchTrailerTypesFulfilled(trailerTypes)),
                            Observable.of(trailerCurrentPositionsActionCreators.fetchTrailerCurrentPositionsFulfilled(trailerCurrentPositions)),
                            Observable.of(companiesActionCreators.fetchCompaniesFulfilled(companies)),
                            Observable.of(companyCostCenterActionCreators.fetchCompanyCostCentersFulfilled(companyCostCenters)),
                            Observable.of(carriersActionCreators.fetchCarriersFulfilled(carriers)),
                            Observable.of(vehiclesActionCreators.fetchVehiclesFulfilled(vehicles)),
                            Observable.of(usersActionCreators.fetchUsersFulfilled(users)),
                            Observable.of(actionCreators.fetchTrailersFulfilled(trailers)),
                        ]

                        serverSide &&
                            observables.push(
                                Observable.of(
                                    tableModelActionCreators.updatePagination(
                                        tableIdentifier,
                                        ajaxResponse.response.last_page,
                                        ajaxResponse.response.current_page - 1,
                                        ajaxResponse.response.total
                                    )
                                )
                            )

                        return Observable.concat(...observables)
                    })
                    .catch(error => Observable.of(actionCreators.fetchTrailersRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.FETCH_CANCELLED, actionTypes.FETCH, tableModelActionTypes.CONFIGURATION_CHANGED))
            )
        })
}

// Fetch one

const fetchTrailerEpic = (action$, store) =>
    action$
        .ofType(actionTypes.FETCH_ONE)
        .filter(() => !store.getState().trailers.getIn(['current', 'fetching']))
        .switchMap(action =>
            Observable.concat(
                Observable.of({
                    type: actionTypes.FETCH_ONE_STARTED,
                }),
                request({
                    path: `trailers/${action.payload}`,
                    method: 'GET',
                })
                    .switchMap(ajaxResponse => {
                        const normalizedData = normalize(ajaxResponse.response, trailerSchema)

                        const trailers = normalizedEntitiesToRecordMap(normalizedData.entities.trailers, Trailer)
                        const trailerTypes = normalizedEntitiesToRecordMap(normalizedData.entities.trailer_types, TrailerType)

                        return Observable.concat(
                            Observable.of(actionCreators.fetchTrailersFulfilled(trailers)),
                            Observable.of(actionCreators.fetchTrailerFulfilled()),
                            Observable.of(trailerTypesActionCreators.fetchTrailerTypesFulfilled(trailerTypes))
                        )
                    })
                    .catch(error => Observable.of(actionCreators.fetchTrailersRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.FETCH_ONE_CANCELLED))
            )
        )

// Save

const saveTrailerEpic = (action$, store) =>
    action$
        .ofType(actionTypes.SAVE)
        .filter(() => !store.getState().trailers.getIn(['current', 'saving']))
        .switchMap(action => {
            const values = Object.assign({}, action.payload.values)
            if (action.payload.ignoreDuplicity) {
                values.ignore_duplicity = 1
            }

            let path = `trailers`
            let method = 'POST'

            if (values.id) {
                path = `trailers/${values.id}`
                method = 'PUT'

                delete values.id
            }

            return Observable.concat(
                Observable.of({
                    type: actionTypes.SAVE_STARTED,
                }),
                request({
                    path,
                    method,
                    body: values,
                })
                    .switchMap(ajaxResponse => {
                        if (ajaxResponse.response.duplicity_found) {
                            return Observable.concat(Observable.of(actionCreators.saveTrailerDuplicityFound(ajaxResponse.response.duplicity)))
                        }

                        const normalizedData = normalize(ajaxResponse.response, trailerSchema)

                        const trailers = normalizedEntitiesToRecordMap(normalizedData.entities.trailers, Trailer)
                        const trailer = trailers.valueSeq().first()

                        return Observable.concat(Observable.of(actionCreators.saveTrailerFulfilled(trailer)))
                    })
                    .catch(error => Observable.of(actionCreators.saveTrailerRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.SAVE_CANCELLED))
            )
        })

// Delete

const deleteTrailerEpic = (action$, store) =>
    action$
        .ofType(actionTypes.DELETE)
        .filter(() => !store.getState().trailers.getIn(['deletion', 'inProgress']))
        .switchMap(action =>
            Observable.concat(
                Observable.of({
                    type: actionTypes.DELETE_STARTED,
                }),
                request({
                    path: `trailers/${action.payload}`,
                    method: 'DELETE',
                })
                    .switchMap(() => Observable.of(actionCreators.deleteTrailerFulfilled(action.payload)))
                    .catch(error => Observable.of(actionCreators.deleteTrailerRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.DELETE_CANCELLED))
            )
        )


// Restore

const restoreTrailerEpic = (action$, store) =>
    action$
        .ofType(actionTypes.RESTORE)
        .filter(() => !store.getState().trailers.getIn(['restoring', 'inProgress']))
        .switchMap(action =>
            Observable.concat(
                Observable.of({
                    type: actionTypes.RESTORE_STARTED,
                }),
                request({
                    path: `trailers/${action.payload}/restore`,
                    method: 'GET',
                })
                    .switchMap(ajaxResponse => {
                        const normalizedData = normalize(ajaxResponse.response, trailerSchema)
                        const items = normalizedEntitiesToRecordMap(normalizedData.entities.trailers, Trailer)
                        const item = items.valueSeq().first()
                        return Observable.concat(Observable.of(actionCreators.restoreTrailerFulfilled(item)))
                    })
                    .catch(error => Observable.of(actionCreators.restoreTrailerRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.RESTORE_CANCELLED))
            )
        )


// Export

const exportTrailersEpic = (action$, store) =>
    action$.ofType(actionTypes.EXPORT).switchMap(action => {
        const filters = JSON.stringify(action.payload.filters)
        const sorting = JSON.stringify(action.payload.sorting)
        const token = store.getState().auth.get('accessToken')

        new DownloadRequest({
            url: `trailers/export?filters=${filters}&sorting=${sorting}&token=${token}`,
        }).run()

        return Observable.concat(
            Observable.of({
                type: actionTypes.EXPORT_FULFILLED,
            })
        )
    })

// History

const fetchTrailerHistoryEpic = (action$, store) =>
    action$
        .ofType(actionTypes.FETCH_HISTORY)
        .filter(() => !store.getState().trailers.getIn(['history', 'fetching']))
        .switchMap(action =>
            Observable.concat(
                Observable.of({
                    type: actionTypes.FETCH_HISTORY_STARTED,
                }),
                request({
                    path: `trailers/${action.payload}/history`,
                    method: 'GET',
                })
                    .switchMap(ajaxResponse => Observable.of(actionCreators.fetchTrailerHistoryFulfilled(ajaxResponse.response)))
                    .catch(error => Observable.of(actionCreators.fetchTrailerHistoryRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.FETCH_HISTORY_CANCELLED))
            )
        )

const exportTrailerHistoryEpic = (action$, store) =>
    action$.ofType(actionTypes.EXPORT_HISTORY).switchMap(action => {
        const token = store.getState().auth.get('accessToken')

        new DownloadRequest({
            url: `trailers/${action.payload}/history/export?token=${token}`,
        }).run()

        return Observable.concat(
            Observable.of({
                type: actionTypes.EXPORT_HISTORY_FULFILLED,
            })
        )
    })

// Documents

const fetchTrailerDocumentsEpic = (action$, store) =>
    action$
        .ofType(actionTypes.FETCH_DOCUMENTS)
        .filter(() => !store.getState().trailers.getIn(['documents', 'fetching']))
        .switchMap(action =>
            Observable.concat(
                Observable.of({
                    type: actionTypes.FETCH_DOCUMENTS_STARTED,
                }),
                request({
                    path: `trailers/${action.payload.id}/documents${action.payload.page ? `?page=${action.payload.page}` : ''}`,
                    method: 'GET',
                })
                    .switchMap(ajaxResponse => Observable.of(actionCreators.fetchTrailerDocumentsFulfilled(ajaxResponse.response)))
                    .catch(error => Observable.of(actionCreators.fetchTrailerDocumentsRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.FETCH_DOCUMENTS_CANCELLED))
            )
        )

const exportTrailerDocumentsEpic = (action$, store) =>
    action$.ofType(actionTypes.EXPORT_DOCUMENTS).switchMap(action => {
        const token = store.getState().auth.get('accessToken')

        new DownloadRequest({
            url: `trailers/${action.payload}/documents/export?token=${token}`,
        }).run()

        return Observable.concat(
            Observable.of({
                type: actionTypes.EXPORT_DOCUMENTS_FULFILLED,
            })
        )
    })

// Equipments

const fetchTrailerEquipmentsEpic = (action$, store) =>
    action$
        .ofType(actionTypes.FETCH_EQUIPMENTS)
        .filter(() => !store.getState().trailers.getIn(['equipments', 'fetching']))
        .switchMap(action =>
            Observable.concat(
                Observable.of({
                    type: actionTypes.FETCH_EQUIPMENTS_STARTED,
                }),
                request({
                    path: `trailers/${action.payload.id}/equipment${action.payload.page ? `?page=${action.payload.page}` : ''}`,
                    method: 'GET',
                })
                    .switchMap(ajaxResponse => Observable.of(actionCreators.fetchTrailerEquipmentsFulfilled(ajaxResponse.response)))
                    .catch(error => Observable.of(actionCreators.fetchTrailerEquipmentsRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.FETCH_EQUIPMENTS_CANCELLED))
            )
        )

const exportTrailerEquipmentsEpic = (action$, store) =>
    action$.ofType(actionTypes.EXPORT_EQUIPMENTS).switchMap(action => {
        const token = store.getState().auth.get('accessToken')

        new DownloadRequest({
            url: `trailers/${action.payload}/equipment/export?token=${token}`,
        }).run()

        return Observable.concat(
            Observable.of({
                type: actionTypes.EXPORT_EQUIPMENTS_FULFILLED,
            })
        )
    })

// Events

const fetchTrailerEventsEpic = (action$, store) =>
    action$
        .ofType(actionTypes.FETCH_EVENTS)
        .filter(() => !store.getState().trailers.getIn(['events', 'fetching']))
        .switchMap(action =>
            Observable.concat(
                Observable.of({
                    type: actionTypes.FETCH_EVENTS_STARTED,
                }),
                request({
                    path: `trailers/${action.payload.id}/events${action.payload.page ? `?page=${action.payload.page}` : ''}`,
                    method: 'GET',
                })
                    .switchMap(ajaxResponse => Observable.of(actionCreators.fetchTrailerEventsFulfilled(ajaxResponse.response)))
                    .catch(error => Observable.of(actionCreators.fetchTrailerEventsRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.FETCH_EVENTS_CANCELLED))
            )
        )

const exportTrailerEventsEpic = (action$, store) =>
    action$.ofType(actionTypes.EXPORT_EVENTS).switchMap(action => {
        const token = store.getState().auth.get('accessToken')

        new DownloadRequest({
            url: `trailers/${action.payload.id}/events/export?token=${token}`,
        }).run()

        return Observable.concat(
            Observable.of({
                type: actionTypes.EXPORT_EVENTS_FULFILLED,
            })
        )
    })

export default [
    fetchTrailersEpic,
    fetchTrailerEpic,
    saveTrailerEpic,
    deleteTrailerEpic,
    restoreTrailerEpic,
    exportTrailersEpic,
    fetchTrailerHistoryEpic,
    exportTrailerHistoryEpic,
    fetchTrailerDocumentsEpic,
    exportTrailerDocumentsEpic,
    fetchTrailerEventsEpic,
    exportTrailerEventsEpic,
    fetchTrailerEquipmentsEpic,
    exportTrailerEquipmentsEpic,
]
