import { schema } from 'normalizr'

export const userSchema = new schema.Entity('users')
export const userRoleSchema = new schema.Entity('user_roles')
export const currencySchema = new schema.Entity('currencies')
export const transportTypeSchema = new schema.Entity('transport_types')
export const transportStateSchema = new schema.Entity('transport_states')
export const cargoTypeSchema = new schema.Entity('cargo_types')
export const goodsTypeSchema = new schema.Entity('goods_types')
export const blockedIpSchema = new schema.Entity('blocked_ips')
export const vehicleTypeSchema = new schema.Entity('vehicle_types')
export const packagingTypeSchema = new schema.Entity('packaging_types')
export const driverTimesSchema = new schema.Entity('times')
export const boardDailyNoteSchema = new schema.Entity('board_daily_notes')
export const boardDailyInfoSchema = new schema.Entity('board_daily_info')
export const boardWeeklyInfoSchema = new schema.Entity('board_weekly_info')
export const vehicleFleetboardConfigurationSchema = new schema.Entity('vehicle_fleetboard_configurations')
export const documentTypeSchema = new schema.Entity('document_types')
export const equipmentSchema = new schema.Entity('equipments')
export const paymentMethodSchema = new schema.Entity('payment_methods')
export const importFileSchema = new schema.Entity('import_files')
export const costTypeSchema = new schema.Entity('cost_types')
export const salesTypeSchema = new schema.Entity('sales_types')
export const driversTypeSchema = new schema.Entity('drivers_types')

export const trailerTypeSchema = new schema.Entity('trailer_types', {
    transport_types: [transportTypeSchema],
    vehicle_types: [vehicleTypeSchema],
    cargo_types: [cargoTypeSchema],
    goods_types: [goodsTypeSchema],
})

export const countrySchema = new schema.Entity('countries', {
    currency: currencySchema,
})

export const dietRateSchema = new schema.Entity('diet_rates', {
    country: countrySchema,
    currency: currencySchema,
})


export const borderCrossingSchema = new schema.Entity('border_crossings', {
    country_1: countrySchema,
    country_2: countrySchema,
})

export const carrierSchema = new schema.Entity('carriers', {
    country: countrySchema,
})

export const companySchema = new schema.Entity('companies', {
    country: countrySchema,
})

export const companyCostCenterSchema = new schema.Entity('company_cost_centers', {
    company: companySchema,
})

export const companyBankAccountSchema = new schema.Entity('company_bank_accounts', {
    company: companySchema,
    currency: currencySchema,
})

export const companyNumberingSchema = new schema.Entity('company_numberings', {
    company: companySchema,
})

export const customerSchema = new schema.Entity('customers', {
    country: countrySchema,
    default_currency: currencySchema,
    business_manager: userSchema,
    dispatcher: userSchema,
})

export const customerAddressTypeSchema = new schema.Entity('customer_address_types')

export const customerAddressSchema = new schema.Entity('customer_addresses', {
    country: countrySchema,
    customer: customerSchema,
    type: customerAddressTypeSchema,
})

export const customerContactSchema = new schema.Entity('customer_contacts', {
    customer: customerSchema,
})

export const customerPlanSchema = new schema.Entity('customer_plans', {
    customer: customerSchema,
    company_cost_center: companyCostCenterSchema,
})

export const carrierContactSchema = new schema.Entity('carrier_contacts', {
    carrier: carrierSchema,
})

export const driverSchema = new schema.Entity('drivers', {
    drivers_type: driversTypeSchema,
    dispatcher: userSchema,
    times: driverTimesSchema,
})

export const vehiclePositionSchema = new schema.Entity('vehicle_positions', {
    driver: driverSchema,
})

export const vehicleMessageSchema = new schema.Entity('vehicle_messages')

export const vehicleSchema = new schema.Entity('vehicles', {
    vehicle_type: vehicleTypeSchema,
    vehicle_fleetboard_configuration: vehicleFleetboardConfigurationSchema,
    positions: [vehiclePositionSchema],
    dispatcher: userSchema,
    company_cost_center: companyCostCenterSchema,
    last_driver: driverSchema,
    board_daily_notes: [boardDailyNoteSchema],
    board_daily_info: [boardDailyInfoSchema],
    board_weekly_info: [boardWeeklyInfoSchema],
    messages: [vehicleMessageSchema],
    carrier: carrierSchema,
})

export const trailerCurrentPositionSchema = new schema.Entity('trailer_current_positions', {
    vehicle: vehicleSchema,
    driver: driverSchema,
})

export const trailerSchema = new schema.Entity('trailers', {
    trailer_type: trailerTypeSchema,
    company_cost_center: companyCostCenterSchema,
    trailer_current_position: trailerCurrentPositionSchema,
    carrier: carrierSchema,
})

export const transportCustomerSurchargeSchema = new schema.Entity('transport_customer_surcharges')

export const transportCustomerSchema = new schema.Entity('transport_customers', {
    customer: customerSchema,
    currency: currencySchema,
    surcharges: [transportCustomerSurchargeSchema],
})

export const transportFileSchema = new schema.Entity('transport_files', {
    user: userSchema,
})

export const transportConditionSchema = new schema.Entity('transport_conditions')

export const invoiceItemTypeSchema = new schema.Entity('invoice_item_types')

export const transportPartSchema = new schema.Entity('transport_parts', {
    transport_state: transportStateSchema,
    carrier: carrierSchema,
    carrier_currency: currencySchema,
    loading_country: countrySchema,
    unloading_country: countrySchema,
    transport_conditions: [transportConditionSchema],
})

export const surchargeTypeSchema = new schema.Entity('surcharge_types')

export const transportPointStateSchema = new schema.Entity('transport_point_states')

export const transportPointDataSchema = new schema.Entity('transport_point_data')

export const transportEtaUpdateSchema = new schema.Entity('transport_eta_updates')

export const transportPointFileSchema = new schema.Entity('transport_point_files')

export const transportEventDataSchema = new schema.Entity('transport_event_data')

export const transportEventSchema = new schema.Entity('transport_events', {
    transport_point_files: [transportPointFileSchema],
    transport_event_data: [transportEventDataSchema],
    driver: driverSchema,
    vehicle: vehicleSchema,
})

export const transportNaviEventSchema = new schema.Entity('transport_navi_events', {
    vehicle: vehicleSchema,
})

export const transportPointSchema = new schema.Entity('transport_points', {
    country: countrySchema,
    transport_point_data: [transportPointDataSchema],
    transport_point_state: transportPointStateSchema,
    transport_events: [transportEventSchema],
    transport_navi_events: [transportNaviEventSchema],
    last_eta_update: transportEtaUpdateSchema,
    vehicle: vehicleSchema,
    trailer: trailerSchema,
})

export const transportRouteSchema = new schema.Entity('transport_routes')

export const transportSchema = new schema.Entity('transports', {
    disponent: userSchema,
    transport_state: transportStateSchema,
    transport_type: transportTypeSchema,
    cargo_type: cargoTypeSchema,
    goods_type: goodsTypeSchema,
    vehicle: vehicleSchema,
    dispatcher: userSchema,
    carrier: carrierSchema,
    carrier_currency: currencySchema,
    transport_customers: [transportCustomerSchema],
    transport_points: [transportPointSchema],
    transport_point_start: transportPointSchema,
    transport_point_loading: transportPointSchema,
    transport_point_unloading: transportPointSchema,
    transport_point_actual: transportPointSchema,
    transport_routes: [transportRouteSchema],
    drivers: [driverSchema],
    predeparture_check: [transportEventSchema],
    transport_files: [transportFileSchema],
    transport_parts: [transportPartSchema],
    transport_conditions: [transportConditionSchema],
})

export const vehicleEventSchema = new schema.Entity('vehicle_events', {
    vehicle: vehicleSchema,
    trailer: trailerSchema,
    dispatcher: userSchema,
    driver: driverSchema,
    transport: transportSchema,
    transport_point: transportPointSchema,
})

export const vehicleFuelingProductSchema = new schema.Entity('vehicle_fueling_products', {
    import_file: importFileSchema,
})

export const vehicleFuelingSchema = new schema.Entity('vehicle_fuelings', {
    vehicle: vehicleSchema,
    driver: driverSchema,
    country: countrySchema,
    import_file: importFileSchema,
})

export const transporeonStationSchema = new schema.Entity('transporeon_stations', {
    country: countrySchema,
})

export const transporeonShipmentSchema = new schema.Entity('transporeon_shipments', {
    transporeon_stations: [transporeonStationSchema],
})

export const transporeonTransportSchema = new schema.Entity('transporeon_transports', {
    country: countrySchema,
    start_country: countrySchema,
    end_country: countrySchema,
    price_currency: currencySchema,
    transporeon_shipments: [transporeonShipmentSchema],
})

export const transportLogChangeSchema = new schema.Entity('transport_log_changes')

export const transportLogSchema = new schema.Entity('transport_logs', {
    user: userSchema,
    transport_log_changes: [transportLogChangeSchema],
})

export const driverDocumentSchema = new schema.Entity('driver_documents', {
    driver: driverSchema,
    document_type: documentTypeSchema,
})

export const vehicleDocumentSchema = new schema.Entity('vehicle_documents', {
    vehicle: vehicleSchema,
    document_type: documentTypeSchema,
})

export const trailerDocumentSchema = new schema.Entity('trailer_documents', {
    trailer: trailerSchema,
    document_type: documentTypeSchema,
})

export const driverEquipmentSchema = new schema.Entity('driver_equipments', {
    driver: driverSchema,
    equipment: equipmentSchema,
})

export const driverTimesheetSchema = new schema.Entity('driver_timesheets', {
    driver: driverSchema,
})

export const vehicleEquipmentSchema = new schema.Entity('vehicle_equipments', {
    vehicle: vehicleSchema,
    equipment: equipmentSchema,
})

export const trailerEquipmentSchema = new schema.Entity('trailer_equipments', {
    trailer: trailerSchema,
    equipment: equipmentSchema,
})

export const taxRateSchema = new schema.Entity('tax_rates', {
    country: countrySchema,
})

export const userLoginsSchema = new schema.Entity('user_logins', {
    user: userSchema,
})

export const costSchema = new schema.Entity('costs', {
    cost_type: costTypeSchema,
    currency: currencySchema,
    company_cost_center: companyCostCenterSchema,
    vehicle: vehicleSchema,
    driver: driverSchema,
    user: userSchema,
    trailer: trailerSchema,
})

export const salesSchema = new schema.Entity('sales', {
    sales_type: salesTypeSchema,
    currency: currencySchema,
    company_cost_center: companyCostCenterSchema,
    vehicle: vehicleSchema,
    driver: driverSchema,
    trailer: trailerSchema,
    customer: customerSchema,
})

userSchema.define({
    user_role: userRoleSchema,
})

vehicleSchema.define({
    transports: [transportSchema],
    last_transport_point: transportPointSchema,
    last_position: vehiclePositionSchema,
    vehicle_documents: [vehicleDocumentSchema],
    vehicle_equipments: [vehicleEquipmentSchema],
})

transportEventSchema.define({
    transport_point: transportPointSchema,
})

driverSchema.define({
    driver_documents: [driverDocumentSchema],
    driver_equipments: [driverEquipmentSchema],
})

trailerSchema.define({
    trailer_documents: [trailerDocumentSchema],
    trailer_equipments: [trailerEquipmentSchema],
    last_finished_vehicle_event: vehicleEventSchema,
})

customerSchema.define({
    invoicing_address: customerAddressSchema,
    invoicing_addresses: [customerAddressSchema],
})
