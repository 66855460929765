export const TableModelColumnType = {
    string: 'string',
    integer: 'integer',
    float: 'float',
    date: 'date',
}

export const TableModelSortDirection = {
    ascending: 'ASC',
    descending: 'DESC',
}

export const TableModelColumnFilteringType = {
    stringFromStart: 'stringFromStart',
    string: 'string',
    equal: 'equal',
    bool: 'bool',
    custom: 'custom',
}

export class TableModelColumn {
    type = null
    filteringType = null
    customPath = null
    selector = null

    constructor({ type = TableModelColumnType.string, filteringType = '', customPath = null, selector = null }) {
        this.type = type
        this.filteringType = filteringType
        this.customPath = customPath
        this.selector = selector
    }
}
