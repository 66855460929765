import React, { Component } from 'react'
import { injectIntl } from 'react-intl'

import * as formatters from '../../../common/formatters'
import { DispatchersBox } from '../../../common/colored_box'
import PermissionsCheck from '../../auth/components/PermissionsCheck'
import { PERMS } from '../permissions'
import * as constants from "../../invoicing_orders/constants";
import {OverlayTrigger, Tooltip} from "react-bootstrap";

class SalesListRow extends Component {
    handleClick = e => {
        e && e.preventDefault()
        e && e.stopPropagation()

        this.props.onClick(this.props.data.id)
    }

    handleDelete = e => {
        e && e.preventDefault()
        e && e.stopPropagation()

        this.props.onDelete(this.props.data.id, Boolean(this.props.data.sales_generator_id))
    }

    handleToggle = e => {
        e && e.preventDefault()
        e && e.stopPropagation()

        this.props.onSelectionToggle && this.props.onSelectionToggle(this.props.data)
    }

    handleChangeInvoicingState = (e, invoicingStateId) => {
        e && e.preventDefault()
        e && e.stopPropagation()

        this.props.onChangeInvoicingState && this.props.onChangeInvoicingState(this.props.data, invoicingStateId)
    }

    handleInvoiceClick = (e, invoiceId) => {
        e && e.preventDefault()
        e && e.stopPropagation()

        this.props.onInvoiceClick && this.props.onInvoiceClick(invoiceId)
    }

    render() {
        const sale = this.props.data
        const isSelected = this.props.isSelected
        const isDisabled = sale.invoicing_state_id === constants.INVOICING_STATE_INVOICED


        const tooltipChangeToReady = (
            <Tooltip id="tooltipChangeToReady">
                {`${this.props.intl.formatMessage({ id: 'fields.changeTo' })}: ${this.props.intl.formatMessage({
                    id: `invoicingStates.${constants.INVOICING_STATE_READY}`,
                })}`}
            </Tooltip>
        )

        const tooltipChangeToNotInvoiced = (
            <Tooltip id="tooltipChangeToNotInvoiced">
                {`${this.props.intl.formatMessage({ id: 'fields.changeTo' })}: ${this.props.intl.formatMessage({
                    id: `invoicingStates.${constants.INVOICING_STATE_NOT_INVOICED}`,
                })}`}
            </Tooltip>
        )

        const tooltipChangeToInvoiced = (
            <Tooltip id="tooltipChangeToInvoiced">
                {`${this.props.intl.formatMessage({ id: 'fields.changeTo' })}: ${this.props.intl.formatMessage({
                    id: `invoicingStates.${constants.INVOICING_STATE_INVOICED}`,
                })}`}
            </Tooltip>
        )

        const invoicingStateColors = {
            [constants.INVOICING_STATE_NOT_INVOICED]: {
                name: this.props.intl.formatMessage({ id: `invoicingStates.${constants.INVOICING_STATE_NOT_INVOICED}` }),
                backgroundColor: '#ff0000',
                color: '#ffffff',
            },
            [constants.INVOICING_STATE_READY]: {
                name: this.props.intl.formatMessage({ id: `invoicingStates.${constants.INVOICING_STATE_READY}` }),
                backgroundColor: '#f69833',
                color: '#ffffff',
            },
            [constants.INVOICING_STATE_INVOICED]: {
                name: this.props.intl.formatMessage({ id: `invoicingStates.${constants.INVOICING_STATE_INVOICED}` }),
                backgroundColor: '#22baa0',
                color: '#ffffff',
            },
        }

        return (
            <tr onClick={this.handleClick} className={'row-selectable'}>
                <PermissionsCheck has={[PERMS.CREATE]}>
                    <td className="w-60 text-center">
                        <div>
                            {isSelected && !isDisabled ? (
                                <button onClick={this.handleToggle} className="btn btn-dark far fa-check" disabled={isDisabled} />
                            ) : (
                                <button onClick={this.handleToggle} className="btn btn-default far fa-square" disabled={isDisabled} />
                            )}
                        </div>
                    </td>
                </PermissionsCheck>
                <PermissionsCheck hasAny={[PERMS.UPDATE, PERMS.DELETE]}>
                    <td className="w-60 table-buttons">
                        <React.Fragment>
                            <PermissionsCheck has={[PERMS.UPDATE]}>
                                <button className="far fa-pencil text-gray m-r-xxs" />
                            </PermissionsCheck>
                            <PermissionsCheck has={[PERMS.DELETE]}>
                                <button onClick={this.handleDelete} className="far fa-trash text-gray" />
                            </PermissionsCheck>
                        </React.Fragment>
                    </td>
                </PermissionsCheck>
                <td className="w-120">
                    {sale.date ? formatters.dateFormatter(sale.date) : null}
                    {sale.sales_generator_id && <span className="far fa-repeat m-l-xs" />}
                </td>
                <td className="w-180">{this.props.salesType && this.props.salesType.name}</td>
                <td className="w-120">
                    {invoicingStateColors[sale.invoicing_state_id] && (
                        <span
                            className="label"
                            style={{
                                backgroundColor: invoicingStateColors[sale.invoicing_state_id].backgroundColor,
                                color: invoicingStateColors[sale.invoicing_state_id].color,
                            }}
                        >
                            {invoicingStateColors[sale.invoicing_state_id].name}
                        </span>
                    )}
                    {sale.invoicing_state_id === constants.INVOICING_STATE_NOT_INVOICED && (
                        <PermissionsCheck has={[PERMS.UPDATE]}>
                            <span className="pull-right action-icon">
                                <OverlayTrigger placement="right" overlay={tooltipChangeToReady}>
                                    <a
                                        href="#"
                                        className="text-danger"
                                        onClick={e => {
                                            this.handleChangeInvoicingState(e, constants.INVOICING_STATE_READY)
                                        }}
                                    >
                                        <i className="far fa-lg fa-toggle-off" />
                                    </a>
                                </OverlayTrigger>
                            </span>
                        </PermissionsCheck>
                    )}
                    {sale.invoicing_state_id === constants.INVOICING_STATE_READY && (
                        <PermissionsCheck has={[PERMS.UPDATE]}>
                            <span className="pull-right action-icon">
                                <OverlayTrigger placement="right" overlay={tooltipChangeToNotInvoiced}>
                                    <a
                                        href="#"
                                        className="text-orange"
                                        onClick={e => {
                                            this.handleChangeInvoicingState(e, constants.INVOICING_STATE_NOT_INVOICED)
                                        }}
                                    >
                                        <i className="fas fa-lg fa-toggle-on" />
                                    </a>
                                </OverlayTrigger>
                                <OverlayTrigger placement="right" overlay={tooltipChangeToInvoiced}>
                                    <a
                                        href="#"
                                        className="text-success m-l-xxs"
                                        onClick={e => {
                                            this.handleChangeInvoicingState(e, constants.INVOICING_STATE_INVOICED)
                                        }}
                                    >
                                        <i className="fas fa-lg fa-check" />
                                    </a>
                                </OverlayTrigger>
                            </span>
                        </PermissionsCheck>
                    )}
                    {sale.invoicing_state_id === constants.INVOICING_STATE_INVOICED && sale.invoices.toJS().length === 0 && (
                        <PermissionsCheck has={[PERMS.UPDATE]}>
                            <span className="pull-right action-icon">
                                <OverlayTrigger placement="right" overlay={tooltipChangeToReady}>
                                    <a
                                        href="#"
                                        className="text-danger m-l-xxs"
                                        onClick={e => {
                                            this.handleChangeInvoicingState(e, constants.INVOICING_STATE_READY)
                                        }}
                                    >
                                        <i className="fas fa-lg fa-times" />
                                    </a>
                                </OverlayTrigger>
                            </span>
                        </PermissionsCheck>
                    )}
                    {sale.invoices.toJS().map(invoice => (
                        <small key={`invoice-${sale.id}-${invoice.id}`} className="display-inline wp-100">
                            <a
                                href="#"
                                className="text-gray"
                                onClick={e => {
                                    this.handleInvoiceClick(e, invoice.id)
                                }}
                            >
                                {invoice.number}
                            </a>
                        </small>
                    ))}
                </td>
                <td className="w-300">{this.props.customer && this.props.customer.name}</td>
                <td className="w-max wm-300">{sale.note}</td>
                <td className="w-300">
                    {this.props.companyCostCenter && `${this.props.companyCostCenter.code} - ${this.props.companyCostCenter.name}`}
                </td>
                <td className="w-120">
                    {this.props.vehicle ? (
                        <DispatchersBox dispatcher={this.props.dispatcher}>{this.props.vehicle.registration}</DispatchersBox>
                    ) : null}
                </td>
                <td className="w-120">
                    {this.props.trailer ? (
                        <DispatchersBox dispatcher={this.props.trailer}>{this.props.trailer.registration}</DispatchersBox>
                    ) : null}
                </td>
                <td className="w-120">
                    {this.props.driver ? this.props.driver.getShortFullName() : null} {this.props.user ? this.props.user.getShortFullName() : null}
                </td>
                <td className="w-110 text-right">
                    <strong>
                        {formatters.priceFormatter(sale.price, '0,0.00', this.props.currency ? this.props.currency.iso_code : '')}
                    </strong>
                </td>
                <td className="w-110 text-right">
                    {formatters.priceFormatter(sale.price * sale.currency_rate, '0,0.00')}
                </td>
            </tr>
        )
    }
}

export default injectIntl(SalesListRow)
