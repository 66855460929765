import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import Notifications from 'react-notification-system-redux'
import * as formatters from '../../../../common/formatters'

import PermissionsCheck from '../../../auth/components/PermissionsCheck'

import { PERMS } from '../../permissions'
import { preparePrerequisitesActionCreators } from "../../../../common/prerequisites/actionCreators"
import Dropzone from "react-dropzone"
import * as transportsActionCreators from "../../actionCreators"
import * as selectors from "../../selectors"
import { LoadingOverlay } from "../../../../common/loading_overlay"
import { SelectField } from "../../../../common/form";
import ColoredBox from "../../../../common/colored_box/components/ColoredBox";
import DatePicker from "react-datepicker";
import { convertCSDateToMoment, convertISODateToCS } from "../../../../common/helpers";
import { dateFormatter } from "../../../../common/formatters";

const componentIdentifier = 'transports_import'
const prerequisites = [

]

class TransportsImport extends Component {
    stepNames = {
        1: this.props.intl.formatMessage({ id: 'modules.transportsImport.stepNames.1' }),
        2: this.props.intl.formatMessage({ id: 'modules.transportsImport.stepNames.2' }),
        3: this.props.intl.formatMessage({ id: 'modules.transportsImport.stepNames.3' })
    }

    columnTypes = {
        'vehicle': this.props.intl.formatMessage({ id: 'modules.transportsImport.columnTypes.vehicle' }),
        'circle': this.props.intl.formatMessage({ id: 'modules.transportsImport.columnTypes.circle' }),
        'template': this.props.intl.formatMessage({ id: 'modules.transportsImport.columnTypes.template' }),
        'templateIdentifier': this.props.intl.formatMessage({ id: 'modules.transportsImport.columnTypes.templateIdentifier' }),
        'customer': this.props.intl.formatMessage({ id: 'modules.transportsImport.columnTypes.customer' }),
        'orderNumber': this.props.intl.formatMessage({ id: 'modules.transportsImport.columnTypes.orderNumber' }),
        'transportType': this.props.intl.formatMessage({ id: 'modules.transportsImport.columnTypes.transportType' }),
        'cargoType': this.props.intl.formatMessage({ id: 'modules.transportsImport.columnTypes.cargoType' }),
        'goodsType': this.props.intl.formatMessage({ id: 'modules.transportsImport.columnTypes.goodsType' }),
        'price': this.props.intl.formatMessage({ id: 'modules.transportsImport.columnTypes.price' }),
        'priceToll': this.props.intl.formatMessage({ id: 'modules.transportsImport.columnTypes.priceToll' }),
        'currency': this.props.intl.formatMessage({ id: 'modules.transportsImport.columnTypes.currency' }),
        'loadingPlaceCode': this.props.intl.formatMessage({ id: 'modules.transportsImport.columnTypes.loadingPlaceCode' }),
        'loadingPlace': this.props.intl.formatMessage({ id: 'modules.transportsImport.columnTypes.loadingPlace' }),
        'loadingDate': this.props.intl.formatMessage({ id: 'modules.transportsImport.columnTypes.loadingDate' }),
        'loadingTime': this.props.intl.formatMessage({ id: 'modules.transportsImport.columnTypes.loadingTime' }),
        'loadingDateTime': this.props.intl.formatMessage({ id: 'modules.transportsImport.columnTypes.loadingDateTime' }),
        'loadingCode': this.props.intl.formatMessage({ id: 'modules.transportsImport.columnTypes.loadingCode' }),
        'loadingNote': this.props.intl.formatMessage({ id: 'modules.transportsImport.columnTypes.loadingNote' }),
        'loadingWeight': this.props.intl.formatMessage({ id: 'modules.transportsImport.columnTypes.loadingWeight' }),
        'loadingPcsCount': this.props.intl.formatMessage({ id: 'modules.transportsImport.columnTypes.loadingPcsCount' }),
        'unloadingPlaceCode': this.props.intl.formatMessage({ id: 'modules.transportsImport.columnTypes.unloadingPlaceCode' }),
        'unloadingPlace': this.props.intl.formatMessage({ id: 'modules.transportsImport.columnTypes.unloadingPlace' }),
        'unloadingDate': this.props.intl.formatMessage({ id: 'modules.transportsImport.columnTypes.unloadingDate' }),
        'unloadingTime': this.props.intl.formatMessage({ id: 'modules.transportsImport.columnTypes.unloadingTime' }),
        'unloadingDateTime': this.props.intl.formatMessage({ id: 'modules.transportsImport.columnTypes.unloadingDateTime' }),
        'unloadingCode': this.props.intl.formatMessage({ id: 'modules.transportsImport.columnTypes.unloadingCode' }),
        'unloadingNote': this.props.intl.formatMessage({ id: 'modules.transportsImport.columnTypes.unloadingNote' }),
        'unloadingWeight': this.props.intl.formatMessage({ id: 'modules.transportsImport.columnTypes.unloadingWeight' }),
        'unloadingPcsCount': this.props.intl.formatMessage({ id: 'modules.transportsImport.columnTypes.unloadingPcsCount' }),
    }

    state = {
        step: 1,
        importHeaders: [],
        importFileId: null,
        importFileName: null,
        importItems: [],
        importItemValues: [],
        columnTypes: {},
        defaultDate: null
    }

    importProcessing = false

    onDropFiles = files => {
        files.forEach(file => {
            const reader = new FileReader()
            const fileName = file.name

            reader.onload = e => {
                const loadedFile = {
                    name: fileName,
                    base64: e.target.result,
                }
                this.props.actions.fetchTransportsImportHeaders(loadedFile)
            }

            reader.readAsDataURL(file)
        })
    }

    onBackButtonClick = () => {
        this.setState({
            ...this.state,
            step: this.state.step - 1
        })
    }

    onSummaryButtonClick = () => {
        this.props.actions.fetchTransportsImportItems(this.state.importFileId, this.state.columnTypes)
    }

    setColumnType = (id, value) => {
        this.setState({
            columnTypes: {
                ...this.state.columnTypes,
                [id]: value
            }
        })
    }

    setDefaultDate = value => {
        this.props.actions.fetchTransportsImportItems(this.state.importFileId, this.state.columnTypes, value)
    }

    onImportItemChange = (key, value) => {
        this.setState({
            importItemValues: {
                ...this.state.importItemValues,
                [key]: value
            }
        })
    }

    onImportButtonClick = () => {
        this.importProcessing = true
        this.props.actions.importTransports(this.state.importFileId, this.state.columnTypes, this.state.defaultDate, this.state.importItemValues)
    }

    componentWillMount() {

    }

    componentDidMount() {

    }

    componentWillReceiveProps(nextProps) {
        if (this.props.importInProgress && !nextProps.importInProgress) {
            // Import error
            if (nextProps.importError) {
                this.props.notify(
                    {
                        title: this.props.intl.formatMessage({ id: 'alerts.titles.error' }),
                        message: nextProps.importError,
                        position: 'tc',
                    },
                    'error'
                )

                this.importProcessing = false

                return false;
            }
            else if (this.state.step === 1) {
                const headers = nextProps.importHeaders.valueSeq().toArray()
                const defaultHeaders = nextProps.importDefaultHeaders.toJS()
                const columnTypes = {}

                nextProps.importHeaders.map((headerName, headerId) => {
                    if (defaultHeaders[headerName]) {
                        columnTypes[headerId] = defaultHeaders[headerName]
                    }

                    return false;
                })

                this.setState({
                    ...this.state,
                    step: 2,
                    importHeaders: headers,
                    importFileId: nextProps.importFileId,
                    importFileName: nextProps.importFileName,
                    columnTypes,
                })
            }
            else if (this.state.step >= 2) {
                if (this.importProcessing) {
                    this.props.notify(
                        {
                            title: this.props.intl.formatMessage({ id: 'alerts.titles.success' }),
                            message: this.props.intl.formatMessage({ id: 'alerts.messages.importSuccess' }),
                            position: 'tc',
                        },
                        'success'
                    )

                    this.props.redirect('/transports/')
                }

                const items = nextProps.importItems
                const itemValues = []

                items.map((item, key) => {
                    itemValues[key] = !!(item.loadingAddress && item.unloadingAddress)

                    return false;
                })

                this.setState({
                    ...this.state,
                    step: 3,
                    importItems: items,
                    importItemValues: itemValues,
                    defaultDate: convertISODateToCS(nextProps.defaultDate)
                })
            }
        }
    }

    /* eslint-disable jsx-a11y/anchor-is-valid */
    render() {
        document.title = formatters.titleFormatter(this.props.intl.formatMessage({ id: 'modules.transportsImport.heading' }))

        const columnTypesOptions = Object.keys(this.columnTypes).map(id => ({
            id: id,
            name: this.columnTypes[id],
        }))

        columnTypesOptions.unshift({
            id: null,
            name: '-'
        })

        return (
            <PermissionsCheck has={[PERMS.CREATE]} noPermissionsPage>
                <div className="page-inner">
                    <div id="main-wrapper">
                        <div className="row hp-100">
                            <div className={'col-md-12' + (this.state.step !== 3 ? ' col-xl-6 col-xl-offset-3' : '')}>
                                <LoadingOverlay active={this.props.importInProgress}>
                                    <div className="panel panel-white">
                                        <div className="panel-body panel-padding">
                                            <h4>
                                                <FormattedMessage id="modules.transportsImport.heading" /> - <FormattedMessage id="modules.transportsImport.step" /> {this.state.step}/3 ({this.stepNames[this.state.step]})
                                            </h4>

                                            {this.state.step === 1 && (
                                                <div className="wp-100 pull-left clearfix">
                                                    <p className="m-b-lg">
                                                        Níže prosím nahrajte soubor, ze kterého se mají přepravy naimportovat. Podporované formáty jsou XLS, XLSX a CSV.
                                                    </p>
                                                    <Dropzone onDrop={this.onDropFiles} multiple={false} style={{}} className="dropzone" acceptClassName="dropzone-accept">
                                                        <p className="ready">
                                                            <FormattedMessage id="fields.dropzoneReady" />
                                                        </p>
                                                        <p className="accept">
                                                            <FormattedMessage id="fields.dropzoneAccept" />
                                                        </p>
                                                    </Dropzone>
                                                </div>)}

                                            {this.state.step === 2 && (
                                                <div className="wp-100 pull-left clearfix">
                                                    <p className="m-b-lg">
                                                        Nastavte prosím význam jednotlivých sloupců tak, aby bylo možné přepravy správně naimportovat.
                                                    </p>

                                                    <div className="row">
                                                        <div className="col-sm-6">
                                                            {Object.keys(this.state.importHeaders).map(headerId => {
                                                                let headerName = this.state.importHeaders[headerId]

                                                                return <div key={headerId}>
                                                                    <SelectField
                                                                        id={headerId}
                                                                        label={headerName}
                                                                        readonly={() => false}
                                                                        values={columnTypesOptions}
                                                                        value={this.state.columnTypes[headerId]}
                                                                        onChange={e => { this.setColumnType(headerId, e.value) }}
                                                                    />
                                                                </div>
                                                            })}
                                                        </div>
                                                    </div>
                                                </div>)}

                                            {this.state.step === 3 && (
                                                <div className="wp-100 pull-left clearfix">
                                                    <p className="m-b-lg">
                                                        Níže je přehled přeprav, které jsme v souboru našli. Vyberte prosím ty, které mají být vytvořeny.
                                                    </p>

                                                    {!this.state.importItems.length && (<div className="alert alert-danger">
                                                        <p>
                                                            Nebyla nalezena žádná přeprava.
                                                        </p>
                                                    </div>)}

                                                    {this.state.importItems.length && (<div>
                                                        <div className="row m-b-lg">
                                                            <div className="col-sm-2">
                                                                <div className="form-group">
                                                                    <label htmlFor="defaultDate">
                                                                        Výchozí datum:
                                                                    </label>
                                                                    <DatePicker
                                                                        id="defaultDate"
                                                                        dateFormat="DD.MM.YYYY"
                                                                        shouldCloseOnSelect={true}
                                                                        autoComplete="off"
                                                                        selected={convertCSDateToMoment(this.state.defaultDate)}
                                                                        value={this.state.defaultDate}
                                                                        onChange={value => {
                                                                            this.setDefaultDate(value ? dateFormatter(value) : '')
                                                                        }}
                                                                        onChangeRaw={e => {
                                                                            this.setDefaultDate(e.target.value)
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <table className="table table-striped">
                                                            <thead>
                                                            <tr>
                                                                <th className="w-40"></th>
                                                                <th className="w-max">
                                                                    Zákazník<br />
                                                                    <small>
                                                                        Typ přepravy | Typ nákladu | Typ zboží
                                                                    </small>
                                                                </th>
                                                                <th className="w-140">
                                                                    Šablona
                                                                </th>
                                                                <th className="w-500">
                                                                    Nakládka<br />
                                                                    <small>
                                                                        Čas | Kód | Množství | Hmotnost | Poznámka
                                                                    </small>
                                                                </th>
                                                                <th className="w-500">
                                                                    Vykládka<br />
                                                                    <small>
                                                                        Čas | Kód | Množství | Hmotnost | Poznámka
                                                                    </small>
                                                                </th>
                                                                <th className="w-100 text-center">
                                                                    Vozidlo<br />
                                                                    <small>
                                                                        Dispečer
                                                                    </small>
                                                                </th>
                                                                <th className="w-100 text-right">
                                                                    Cena<br />
                                                                    <small>
                                                                        Dálniční poplatky
                                                                    </small>
                                                                </th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            {this.state.importItems.map((item, key) => {
                                                                return (<tr key={'importItem-'+key}>
                                                                    <td className="text-center">
                                                                        <input
                                                                            type="checkbox"
                                                                            id={'item-'+key}
                                                                            onChange={(e) => { this.onImportItemChange(key, e.target.checked) }}
                                                                            checked={this.state.importItemValues[key]}
                                                                        />
                                                                    </td>
                                                                    <td>
                                                                        <strong>{item.customer ? item.customer.name : '-'}</strong>  {item.orderNumber ? '['+item.orderNumber+']' : ''}<br />
                                                                        <small className="text-gray">
                                                                            {item.transportType ? item.transportType.name : '-'}
                                                                            {item.cargoType ? ' | '+item.cargoType.name : ''}
                                                                            {item.goodsType ? ' | '+item.goodsType.name : ''}
                                                                        </small>
                                                                    </td>
                                                                    <td>
                                                                        {item.isCircle && item.circleTemplate ? item.circleTemplate.template_identifier : null}
                                                                        {!item.isCircle && item.template ? item.template.template_identifier : null}
                                                                    </td>
                                                                    <td>
                                                                        {item.loadingAddress ? (<div>
                                                                            <i className="fas fa-check-circle text-success m-r-xxs"></i>
                                                                            <strong>{item.loadingAddress.country && item.loadingAddress.country.iso_code} {item.loadingAddress.zipcode} {item.loadingAddress.city}</strong>
                                                                            <span className="text-gray"> ({item.loadingPlace})</span>
                                                                        </div>) : (<div>
                                                                            <i className="fas fa-exclamation-triangle text-danger m-r-xxs"></i>
                                                                            <span className="text-danger">Adresa nenalezena</span>
                                                                            <span className="text-gray"> ({item.loadingPlace})</span>
                                                                        </div>)}
                                                                        <small className="text-gray">
                                                                            {item.loadingDateTime ? formatters.datetimeFormatter(item.loadingDateTime) : '-'} | {item.loadingCode ? item.loadingCode : '-'} | {item.loadingPcsCount ? item.loadingPcsCount : '-'} | {item.loadingWeight ? item.loadingWeight : '-'} | {item.loadingNote ? item.loadingNote : '-'}
                                                                        </small>
                                                                    </td>
                                                                    <td>
                                                                        {item.isCircle && (<span className="pull-right">
                                                                            <i className="fas fa-repeat"></i>
                                                                        </span>)}

                                                                        {item.unloadingAddress ? (<div>
                                                                            <i className="fas fa-check-circle text-success m-r-xxs"></i>
                                                                            <strong>{item.unloadingAddress.country && item.unloadingAddress.country.iso_code} {item.unloadingAddress.zipcode} {item.unloadingAddress.city}</strong>
                                                                            <span className="text-gray"> ({item.unloadingPlace})</span>
                                                                        </div>) : (<div>
                                                                            <i className="fas fa-exclamation-triangle text-danger m-r-xxs"></i>
                                                                            <span className="text-danger">Adresa nenalezena</span>
                                                                            <span className="text-gray"> ({item.unloadingPlace})</span>
                                                                        </div>)}
                                                                        <small className="text-gray">
                                                                            {item.unloadingDateTime ? formatters.datetimeFormatter(item.unloadingDateTime) : '-'} | {item.unloadingCode ? item.unloadingCode : '-'} | {item.unloadingPcsCount ? item.unloadingPcsCount : '-'} | {item.unloadingWeight ? item.unloadingWeight : '-'} | {item.unloadingNote ? item.unloadingNote : '-'}
                                                                        </small>
                                                                    </td>
                                                                    <td className="text-center">
                                                                        {item.vehicle ? (
                                                                            <div>
                                                                                <ColoredBox textRGB={item.vehicle.dispatcher && item.vehicle.dispatcher.rgb_text} bgRGB={item.vehicle.dispatcher && item.vehicle.dispatcher.rgb_bg}>
                                                                                    {item.vehicle.registration}
                                                                                </ColoredBox><br />
                                                                                <small className="text-gray">{item.vehicle.dispatcher ? formatters.fullName(item.vehicle.dispatcher.first_name, item.vehicle.dispatcher.last_name) : ''}</small>
                                                                            </div>) : null}
                                                                    </td>
                                                                    <td className="text-right">
                                                                        {item.price ? (
                                                                            <strong>
                                                                                {formatters.priceFormatter(item.price, '0,0', item.currency ? item.currency.iso_code : '')}
                                                                            </strong>) : null}<br />
                                                                        {item.priceToll ? (<small className="text-gray">
                                                                            {formatters.priceFormatter(item.priceToll, '0,0', item.currency ? item.currency.iso_code : '')}
                                                                        </small>) : null}
                                                                    </td>
                                                                </tr>)
                                                            })}
                                                            </tbody>
                                                        </table>
                                                    </div>)}
                                                </div>)}

                                            <div className="pull-left wp-100 btns-form">
                                                {this.state.step > 1 && (<button onClick={this.onBackButtonClick} className="btn btn-addon btn-default">
                                                    <i className="far fa-chevron-left" /> <FormattedMessage id="buttons.back" />
                                                </button>)}

                                                {this.state.step === 2 && (<button onClick={this.onSummaryButtonClick} className="btn btn-addon btn-success pull-right">
                                                    <i className="far fa-chevron-right" /> <FormattedMessage id="buttons.continue" />
                                                </button>)}

                                                {this.state.step === 3 && (<button onClick={this.onImportButtonClick} className="btn btn-addon btn-success pull-right">
                                                    <i className="far fa-check" /> <FormattedMessage id="buttons.import" />
                                                </button>)}
                                            </div>
                                        </div>
                                    </div>
                                </LoadingOverlay>
                            </div>
                        </div>
                    </div>
                </div>
            </PermissionsCheck>
        )
    }
}

function mapStateToProps(state) {
    return {
        importInProgress: selectors.getImportInProgress(state),
        importError: selectors.getImportError(state),
        importFileId: selectors.getImportFileId(state),
        importHeaders: selectors.getImportHeaders(state),
        importDefaultHeaders: selectors.getImportDefaultHeaders(state),
        importItems: selectors.getImportItems(state),
        defaultDate: selectors.getDefaultDate(state),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            ...bindActionCreators(
                {
                    ...transportsActionCreators,
                    ...preparePrerequisitesActionCreators(componentIdentifier, prerequisites)
                },
                dispatch
            ),
        },
        notify: (notification, type) => dispatch(Notifications.show(notification, type)),
        redirect: toURL => dispatch(push(toURL)),
        dispatch,
    }
}

function mergeProps(stateProps, dispatchProps, ownProps) {
    return {
        ...stateProps,
        ...dispatchProps,
        ...ownProps
    }
}

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps,
        mergeProps
    )(TransportsImport)
)
